<div class="ui stackable grid">
  <div class="ui two wide column">
    <div class="ui vertical text menu">
      <div class="header item">#ФИЛЬТР</div>
      <a class="item" (click)="clearFilter()">
        Все клиенты
      </a>
      <a class="item" (click)="applyManagerFilter(currentUser)">
        Мои клиенты
      </a>
    </div>
  </div>
  <div class="ui twelve wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <table class="ui selectable very padded table" [hidden]="!loaded" #clientTable>
      <thead>
      <tr>
        <th>
          <div class="head">Имя клиента</div>
        </th>
        <th>
          <div class="head">Менеджер</div>
        </th>
        <th>
          <div class="head">Сделки</div>
        </th>
        <th>
          <div class="head">Дела</div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let client of clients | clientFilter:filter | orderByColumn:'name:string'">
        <td>
          <a [routerLink]="['/client/card', client.id]">{{client.name}}</a>
        </td>
        <td>
          <a [routerLink]="['/staff/card/', client.manager.id]">{{client.manager.name}}</a>
        </td>
        <td>
          <a [routerLink]="['/deal/list']" [queryParams]="{clientId: client.id}">{{client.dealCount ? client.dealCount : 0}}</a>
        </td>
        <td>
          <a [routerLink]="['/todo/list']" [queryParams]="{clientId: client.id}">0</a>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="ui text-center" *ngIf="clientTable.rows.length === 1 && loaded">
      <h2>Ничего не найдено</h2>
    </div>
  </div>
</div>
