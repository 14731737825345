import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DealPosition } from '../../_models/deal-position.model';
import { Deal } from '../../_models/deal.model';
import { Position } from '../../_models/position.model';
import { DealPositionService } from '../../_services/deal-position.service';
import { DealService } from '../../_services/deal.service';
import { UserService } from '../../_services/user.service';
import { OrderType } from '../../_models/enums/order-type.enum';
import { OrderStatus } from '../../_models/enums/order-status.enum';
import { OrderModeService } from '../order/order-mode.service';
import { Order } from '../../_models/order.model';

@Component({
  selector: 'e2b-deal-position',
  templateUrl: './deal-position.component.html',
  styleUrls: [
    './deal-position.component.less',
    '../../../assets/design/semantic-ui/components/card.min.css',
    '../../../assets/design/semantic-ui/components/table.min.css',
    '../../../assets/design/semantic-ui/components/icon.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/button.min.css'
  ],
  encapsulation: ViewEncapsulation.None
})

export class DealPositionsComponent implements OnInit, OnDestroy {
  addedPosition: any = null;
  @Input()
  dealId: number;
  @Input()
  onSave: EventEmitter<any>;
  @Input()
  onAddPosition: EventEmitter<DealPosition> = new EventEmitter<DealPosition>();
  @Input()
  onRemovePosition: EventEmitter<DealPosition> = new EventEmitter<DealPosition>();
  @Input()
  onSelectPositionEvent: EventEmitter<DealPosition>;
  positionCount = {};
  positionDiscount = {};
  positionPrice = {};
  positionProblems: any = {};
  positions: DealPosition[] = [];
  @Input()
  products: Position[] = [];
  @Input()
  selectPosition: EventEmitter<Position> = new EventEmitter<Position>();
  selectedPosition: DealPosition | boolean;
  @Input()
  showActions = true;
  @Input()
  subManager = false;
  dealDiscount = false;
  discountPercent = 0;

  @Input()
  canEdit = false;

  @Output()
  pickOrder = new EventEmitter<any>();

  constructor(private dealService: DealService,
              private dealPositionService: DealPositionService,
              public readonly orderModeService: OrderModeService,
              public userService: UserService) {
  }

  @Input()
  set setPositions({ positions, orders = [] }) {
    this.positionProblems = {};
    this.positions = positions;
    if (this.positions) {
      this.positions.map(p => {
        this.positionProblems[p.id] = {
          id: p.id,
          problems: []
        };
        if (p.positionValues) {
          p.positionValues.map((positionValue: any) => {
            if (positionValue.field.type === 'PROBLEM') {
              if (positionValue.field.type.indexOf('ENUM(') > -1) {
                positionValue.field.values = positionValue.field.type.substring(0, positionValue.field.type.length - 1).replace('ENUM(', '').split(/,(?![^(\[]*[\])])/g).sort();
              }
              this.positionProblems[p.id].problems.push({ positionId: p.id, ...positionValue });
              this.checkProblems(p.id);
            }
          });
        }

        if (p.positionValues) {
          const personalChanges = p.positionValues.find(pv => pv.field.name === 'Инд изменения 1');
          if (personalChanges && personalChanges.value && (personalChanges.value as Array<any>).length) {
            const personalChangesPrice = (personalChanges.value as Array<any>).reduce((sum, value) => sum + value.price, 0);
            p.personalChangesCost = personalChangesPrice
              ? personalChangesPrice < 7
                ? Math.floor((p.count * p.price) / ((1 / 0.05) + 1))
                : Math.floor((p.count * p.price) / ((1 / 0.1) + 1))
              : 0;
          } else {
            p.personalChangesCost = 0;
          }
        }

        (<Order[]>orders).sort((a, b) => new Date(a.date).getTime() < new Date(b.date).getTime() ? -1 : 1).map(order => {
          order.orderDealPositions.map(orderDealPosition => {
            if (orderDealPosition.dealPosition.id === p.id) {
              if (order.type === OrderType.PRODUCTION) {
                p.production = { id: order.id, status: order.status };
              }
              if (order.type === OrderType.DELIVERY) {
                p.delivery = { id: order.id, status: order.status };
              }
              if (order.type === OrderType.MOUNTING) {
                p.mounting = { id: order.id, status: order.status };
              }
              if (order.type === OrderType.RECLAMATION) {
                p.reclamation = { id: order.id, status: order.status };
              }
            }
          });
        });

      });
    }
  }

  get OrderType() {
    return OrderType;
  }

  get OrderStatus() {
    return OrderStatus;
  }

  addPosition() {
    this.addedPosition = new DealPosition();
  }

  checkProblems(positionId) {
    let isProblem = false;
    this.positionProblems[positionId].problems.map(problem => {
      if (typeof problem.value === 'string' && problem.value.indexOf('РАСПРЕДЕЛИТЬ') > -1) {
        isProblem = true;
      } else if (problem.value && problem.value.name && problem.value.name === 'РАСПРЕДЕЛИТЬ') {
        isProblem = true;
      }
    });
    return isProblem;
  }

  clearPosition() {
    this.addedPosition = null;
  }

  identify(index, obj) {
    return obj ? (obj.id ? obj.id : obj.position.id) : undefined;
  }

  ngOnDestroy() {
    this.selectPosition.unsubscribe();
  }

  ngOnInit() {
    this.selectPosition.subscribe(position => {
      this.addedPosition.position = position;
      this.addedPosition.price = Number(this.addedPosition.position.cost);
      this.addedPosition.count = 1;
    });
  }

  removePositionFromDeal(item: DealPosition) {
    this.onRemovePosition.emit(item);
  }

  save() {
    this.onAddPosition.emit(this.addedPosition);
    this.addedPosition = null;
  }

  savePosition(item: DealPosition) {
    if (this.onSave) {
      this.onSave.emit();
    }
    if (item.id) {
      this.dealPositionService.update<DealPosition>(<DealPosition>{
        id: item.id,
        count: item.count,
        price: item.price,
        deal: { id: this.dealId },
        discountPrice: item.discountPrice,
        discountPercent: item.discountPercent
      }).subscribe(() => {
        this.positionCount = {};
        this.positionPrice = {};
        this.positionDiscount = {};
      });
      if (this.dealId) {
        this.dealService.update({ id: this.dealId, price: this.totalDealSum() } as Deal).subscribe({
          next: () => {
          },
          error: console.error
        });
      }
    } else {
      this.positions.map((p, k) => {
        if (p.position.id === item.position.id) {
          this.positions[k].count = item.count;
          this.positions[k].price = item.price;
          this.positions[k].deal = <Deal>{ id: this.dealId };
          this.positions[k].discountPrice = item.discountPrice;
          this.positions[k].discountPercent = item.discountPercent;
          this.positionCount = {};
          this.positionPrice = {};
          this.positionDiscount = {};
        }
      });
    }
  }

  select(position: DealPosition) {
    this.onSelectPositionEvent.emit(position);
  }

  totalDealSum() {
    let totalSum = 0;
    if (this.positions.length > 0) {
      this.positions.map(pos => {
        totalSum += pos.price * pos.count;
        if (pos.discountPrice) {
          totalSum -= pos.discountPrice;
        }
      });
      if (this.addedPosition && this.addedPosition.price) {
        totalSum += this.addedPosition.price * this.addedPosition.count;
        if (this.addedPosition.discountPrice) {
          totalSum -= this.addedPosition.discountPrice;
        }
      }
    }
    return totalSum;
  }

  totalSum() {
    let totalSum = 0;
    if (this.positions.length > 0) {
      this.positions.map(pos => {
        totalSum += pos.price * pos.count;
      });
      if (this.addedPosition && this.addedPosition.price) {
        totalSum += this.addedPosition.price * this.addedPosition.count;
      }
    }
    return totalSum;
  }

  hasDiscount() {
    let d = 0;
    this.positions.map(p => {
      if (p.discountPrice) {
        d += p.discountPrice;
      }
    });
    return d;
  }

  calcDiscountPercent(item: any) {
    item.discountPercent = item.discountPrice / (item.count * parseFloat(item.price)) * 100;
  }

  calcDiscountPrice(item: any) {
    item.discountPrice = ((item.count * parseFloat(item.price)) / 100) * item.discountPercent;
  }

  async calcDealDiscount(discountPercent: number) {
    await Promise.all(await this.positions.map(async position => {
      if (position.discountPercent !== discountPercent) {
        position.discountPercent = discountPercent;
        position.discountPrice = ((position.count * parseFloat(String(position.price))) / 100) * position.discountPercent;
        await this.savePosition(position);
      }
    }));
    this.dealDiscount = false;
  }

  onOrderIconClick(id: number, item: DealPosition) {
    this.pickOrder.emit({ id, position: { id: item.id } });
  }
}
