<ng-template let-option #staffOptionTemplate>
  {{option.name}}
</ng-template>
<sui-select class="fluid selection mx-auto" [ngClass]="{'error': error}"
            placeholder="Сотрудник"
            [isSearchable]="true"
            [isDisabled]="false"
            [(ngModel)]="selectedStaff"
            [ngModelOptions]="{name: 'staff'}"
            (ngModelChange)="save()"
            [options]="staff"
            labelField="name"
            [optionTemplate]="staffOptionTemplate"
            #selectStaff>
  <sui-select-option *ngFor="let staff of selectStaff.filteredOptions | orderByColumn:'name:string'"
                     [value]="staff">
  </sui-select-option>
</sui-select>
