import { BaseModel } from './base.model';
import { DealPosition } from './deal-position.model';
import { PositionFieldValue } from './position-field-value.model';
import { PositionGroup } from './position-group.model';
import { AssemblyScheme } from './assembly-scheme.model';

export class Position extends BaseModel {
  priceM2: number;
  article: string;
  cost: number;
  priceP1: number;
  priceP2: number;
  priceM1: number;
  priceMN: number;
  name: string;
  type: number;
  deleted: boolean;
  dealPositions: DealPosition[];
  group: PositionGroup;
  values: PositionFieldValue[];
  assemblyScheme: AssemblyScheme;
  megaplanId: number;
}
