import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterEventService } from '../_services/router-event.service';
import { ActionService } from '../action/action.service';

@Component({
  selector: 'e2b-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/container.css',
    './dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, AfterViewChecked {

  actions: any;
  events: any;

  constructor(private actionService: ActionService,
              private routerEventService: RouterEventService,
              private cdRef: ChangeDetectorRef) {

    this.actionService.createMenuEvent.subscribe(action => {
      this.actions.push(action);
    });
    this.actionService.createActionEvent.subscribe(action => {
      this.events.push(action);
    });
    this.actionService.clear.subscribe(() => {
      this.actions = [];
      this.events = [];
    });
    this.routerEventService.onRouterEvent.subscribe(() => {
      this.actions = [];
      this.events = [];
    });
  }

  ngOnInit() {
    this.actions = [];
    this.events = [];
  }

  ngAfterViewChecked() {
    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }

}
