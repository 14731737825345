import { BaseModel } from './base.model';
import { DealField } from './deal-field.model';
import { Deal } from './deal.model';
import { Transition } from './transition.model';

export class DealStatus extends BaseModel {
  requiredFields: DealField[];
  description: string;
  color: string;
  sort: number;
  positive: boolean;
  entry_point: boolean;
  name: string;
  availableFields: DealField[];
  deals: Deal[];
  nextStatuses: DealStatus[];
  nextStatusInverse: DealStatus[];
  previousStatuses: DealStatus[];
  previousStatusInverse: DealStatus[];
  transitions: Transition[];
}
