import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PositionGroup } from '../../_models/position-group.model';
import { Position } from '../../_models/position.model';
import { PositionGroupService } from '../../_services/position-group.service';
import { PositionService } from '../../_services/position.service';

@Component({
  selector: 'e2b-position-add',
  templateUrl: './position-add.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css',
    '../../../assets/design/semantic-ui/components/icon.min.css',
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/button.min.css',
    './position-add.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PositionAddComponent implements OnInit {

  position: Position = new Position();
  positionTypes: [{
    id: 1,
    name: 'Корпусная мебель'
  }, {
    id: 2,
    name: 'Мягкая мебель'
  }, {
    id: 3,
    name: 'Матрас'
  }];
  groups: [PositionGroup];

  constructor(private positionService: PositionService, private router: Router, private positionGroupService: PositionGroupService) {
  }

  ngOnInit() {
    this.positionGroupService.find<PositionGroup>().subscribe(groups => {
      this.groups = groups;
    });
    this.position.type = 0;
  }

  save() {
    if (!this.position.name || this.position.name === '') {
      return false;
    }
    if (!this.position.cost) {
      return false;
    }

    this.position.type = parseInt(this.position.type.toString(), 0);
    this.positionService.create(this.position).subscribe(position => {
      this.router.navigate(['/catalog/list']).catch(console.error);
    }, console.error);
  }
}
