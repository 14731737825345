import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'apiUrl'
})
export class UrlHelperPipe implements PipeTransform {
  transform(url: any, isClient?: boolean): any {
    if (localStorage.getItem('e2b.user.token')) {
      return environment.apiUrl + '/' + url + '?token=' + localStorage.getItem('e2b.user.token');
    }
    if (localStorage.getItem('e2b.lk.client.token')) {
      return environment.apiUrl + '/lk/' + url + '?token=' + localStorage.getItem('e2b.lk.client.token');
    }
  }
}
