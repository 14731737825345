import { BaseModel } from './base.model';

export enum SettingName {
  E2B_SETTING_BRIGADES = 'E2B_SETTING_BRIGADES',
  E2B_SETTING_HEAD_MANAGER_ID = 'E2B_SETTING_HEAD_MANAGER_ID',
  E2B_SETTING_SKIP_DAYS_MOUNTING = 'E2B_SETTING_SKIP_DAYS_MOUNTING',
  E2B_SETTING_HEAD_MOUNTER_ID = 'E2B_SETTING_HEAD_MOUNTER_ID',
  E2B_SETTING_MANAGER_HELPER_BONUS = 'E2B_SETTING_MANAGER_HELPER_BONUS',
  E2B_SETTING_RECLAMATION_RECEIVE_HOURS = 'E2B_SETTING_RECLAMATION_RECEIVE_HOURS',
  E2B_SETTING_RECLAMATION_SETDATE_HOURS = 'E2B_SETTING_RECLAMATION_SETDATE_HOURS',
  E2B_SETTING_RECLAMATION_FINISH_HOURS = 'E2B_SETTING_RECLAMATION_FINISH_HOURS',
  E2B_SETTING_MD = 'E2B_SETTING_MD',
}

export class SettingModel extends BaseModel {
  name: string;
  value: string;
}
