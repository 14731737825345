<div class="ui stackable grid">
  <div class="row">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div class="ui fourteen wide column">
      <table class="ui selectable very padded table" [hidden]="!loaded">
        <thead>
        <tr>
          <th>
            <div class="head">Имя контакта</div>
          </th>
          <th>
            <div class="head">Клиент</div>
          </th>
          <th>
            <div class="head">Телефон</div>
          </th>
          <th>
            <div class="head">Email</div>
          </th>
          <th>
            <div class="head">Адрес</div>
          </th>
          <th>
            <div class="head">Описание</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let contact of contacts">
          <td class="p-2">
            <p>{{contact.name}}</p>
          </td>
          <td class="p-2">
            <p>
              <a [routerLink]="['/client/card/', contact.client.id]">{{contact.client.name}}</a>
            </p>
          </td>
          <td class="p-2">
            <p *ngIf="contact.phone">
              <a href="tel:{{contact.phone}}">{{contact.phone}}</a>
            </p>
          </td>
          <td class="p-2">
            <p *ngIf="contact.email">
              <a href="mailto:{{contact.email}}">{{contact.email}}</a>
            </p>
          </td>
          <td class="p-2">
            <p>{{contact.address}}</p>
          </td>
          <td class="p-2">
            <p *ngIf="contact.description">{{contact.description}}</p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
