<div class="ui secondary doubling stackable menu" style="padding-top: 22px;">
  <a class="item gray" routerLink="/">дэшборд</a>
  <div class="ui dropdown pointing item gray" suiDropdown *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']" [e2bPermission]="['menu', 'client', '/']">
    <div class="text">контрагенты</div>
    <i class="dropdown icon"></i>
    <div class="menu" suiDropdownMenu>
      <div class="item gray" routerLink="/client/list" [ngClass]="{'active': path == '/client/list'}" [e2bPermission]="['menu', 'client', 'client', '/']">клиенты</div>
      <div class="item gray" routerLink="/client/contractor" [ngClass]="{'active': path == '/client/contractor'}" [e2bPermission]="['menu', 'client', 'contractor', '/']">подрядчики</div>
      <div class="item gray" routerLink="/client/contact" [ngClass]="{'active': path == '/client/contact'}" [e2bPermission]="['menu', 'client', 'contact', '/']">контакты</div>
      <div class="item gray" routerLink="/client/person" [ngClass]="{'active': path == '/client/person'}" [e2bPermission]="['menu', 'client', 'person', '/']">плательщики</div>
      <div class="item gray" routerLink="/client/my-company" [ngClass]="{'active': path == '/client/my-company'}" [e2bPermission]="['menu', 'client', 'own', '/']">моя компания</div>
    </div>
  </div>
  <div class="ui dropdown pointing item gray" suiDropdown *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']" [e2bPermission]="['menu', 'deal', '/']">
    <div class="text">сделки</div>
    <i class="dropdown icon"></i>
    <div class="menu" suiDropdownMenu>
      <div class="item gray" [ngClass]="{'active': path == '/deal/list'}" routerLink="/deal/list" [e2bPermission]="['menu', 'deal', 'list', '/']">список</div>
      <div class="item gray" [ngClass]="{'active': path == '/deal/calendar'}" routerLink="/deal/calendar" [e2bPermission]="['menu', 'deal', 'calendar', '/']">календарь</div>
      <div class="item gray" [ngClass]="{'active': path == '/todo/todo-calendar'}" routerLink="/todo/todo-calendar" [e2bPermission]="['menu', 'deal', 'todo', '/']">дела</div>
      <div class="item gray" [ngClass]="{'active': path == '/deal/template'}" routerLink="/deal/template" [e2bPermission]="['menu', 'deal', 'template', '/']">шаблоны договоров</div>
    </div>
  </div>
  <div class="ui dropdown pointing item gray" suiDropdown *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']" [e2bPermission]="['menu', 'catalog', '/']">
    <div class="text">каталог</div>
    <i class="dropdown icon"></i>
    <div class="menu" suiDropdownMenu>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/list'}" routerLink="/catalog/list" [e2bPermission]="['menu', 'catalog', 'position', '/']">товары</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/group/list'}" routerLink="/catalog/group/list" [e2bPermission]="['menu', 'catalog', 'position-group', '/']">группы</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/field/list'}" routerLink="/catalog/field/list" [e2bPermission]="['menu', 'catalog', 'field', '/']">поля</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/material/list'}" routerLink="/catalog/material/list" [e2bPermission]="['menu', 'catalog', 'material', '/']">материалы</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/cloth/list'}" routerLink="/catalog/cloth/list" [e2bPermission]="['menu', 'catalog', 'cloth', '/']">ткани</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/personal/list'}" routerLink="/catalog/personal/list" [e2bPermission]="['menu', 'catalog', 'personal', '/']">индивидуальные изменения</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/problem/list'}" routerLink="/catalog/problem/list" [e2bPermission]="['menu', 'catalog', 'problem', '/']">проблемы</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/reclamation/list'}" routerLink="/catalog/reclamation/list" [e2bPermission]="['menu', 'catalog', 'reclamation', '/']">рекламации</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/template/list'}" routerLink="/catalog/template/list" [e2bPermission]="['menu', 'catalog', 'template', '/']">шаблоны</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/plan/list'}" routerLink="/catalog/plan/list" [e2bPermission]="['menu', 'catalog', 'plan', '/']">планы менеджеров</div>
      <div class="item gray" [ngClass]="{'active': path == '/reference'}" routerLink="/reference" [e2bPermission]="['menu', 'catalog', 'reference', '/']">эталоны</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/deal-step'}" routerLink="/catalog/deal-step" [e2bPermission]="['menu', 'catalog', 'deal-step', '/']">схемы приложения</div>
      <div class="item gray" [ngClass]="{'active': path == '/catalog/assembly-scheme/list'}" routerLink="/catalog/assembly-scheme/list" [e2bPermission]="['menu', 'catalog', 'assembly-scheme', '/']">схемы сборки</div>
    </div>
  </div>
  <div *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']" class="ui dropdown pointing item gray tablet-hide" suiDropdown [e2bPermission]="['menu', 'staff', '/']">
    <div class="text">сотрудники</div>
    <i class="dropdown icon"></i>
    <div class="menu" suiDropdownMenu>
      <div class="item gray" [ngClass]="{'active': path == '/staff/list'}" routerLink="/staff/list" [e2bPermission]="['menu', 'staff', 'list', '/']">сотрудники</div>
      <div class="item gray" [ngClass]="{'active': path == '/staff/group'}" routerLink="/staff/group" [e2bPermission]="['menu', 'staff', 'group', '/']">группы</div>
      <div class="item gray" [ngClass]="{'active': path == '/staff/role/list'}" routerLink="/staff/role/list" [e2bPermission]="['menu', 'staff', 'role', '/']">роли</div>
      <div class="item gray" [ngClass]="{'active': path == '/staff/holiday'}" routerLink="/staff/holiday" [e2bPermission]="['menu', 'staff', 'holiday', '/']">табель выходных</div>
    </div>
  </div>
  <div class="ui dropdown pointing link item gray tablet-hide" suiDropdown *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']" [e2bPermission]="['menu', 'report', '/']">
    <div class="text">отчеты</div>
    <i class="dropdown icon"></i>
    <div class="ui vertical menu" suiDropdownMenu>
      <div class="ui dropdown vertical pointing item gray tablet-hide" suiDropdown [e2bPermission]="['menu', 'report', 'delivery-mounting', '/']">
        <div class="text">доставка, монтаж...</div>
        <i class="angle right icon"></i>
        <div class="ui vertical menu" suiDropdownMenu>
          <div class="item gray" [ngClass]="{'active': path == '/report/gauging'}" routerLink="/report/gauging" [e2bPermission]="['menu', 'report', 'delivery-mounting', 'measure', '/']">замеры</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/delivery'}" routerLink="/report/delivery" [e2bPermission]="['menu', 'report', 'delivery-mounting', 'delivery', '/']">доставки</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/mounting'}" routerLink="/report/mounting" [e2bPermission]="['menu', 'report', 'delivery-mounting', 'mounting', '/']">монтажи</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/money'}" routerLink="/report/money" [e2bPermission]="['menu', 'report', 'delivery-mounting', 'money', '/']">деньги с монтажей</div>
        </div>
      </div>
      <div class="ui dropdown vertical pointing item gray tablet-hide" suiDropdown [e2bPermission]="['menu', 'report', 'finance', '/']">
        <div class="text">финансы</div>
        <i class="angle right icon"></i>
        <div class="ui vertical menu" suiDropdownMenu>
          <div class="item gray" [ngClass]="{'active': path == '/report/pnl'}" routerLink="/report/pnl" [e2bPermission]="['menu', 'report', 'finance', 'pnl', '/']">P&L</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/sale'}" routerLink="/report/sale" [e2bPermission]="['menu', 'report', 'finance', 'sale', '/']">продажи</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/rentability'}" routerLink="/report/rentability" [e2bPermission]="['menu', 'report', 'finance', 'rentability', '/']">себестоимость</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/square'}" routerLink="/report/square" [e2bPermission]="['menu', 'report', 'finance', 'square', '/']">стоимость м2</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/credit'}" routerLink="/report/credit" [e2bPermission]="['menu', 'report', 'finance', 'credit', '/']">сделки в рассрочку</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/bonus'}" routerLink="/report/bonus" [e2bPermission]="['menu', 'report', 'finance', 'bonus', '/']">бонусы менеджеров</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/documents'}" routerLink="/report/documents" [e2bPermission]="['menu', 'report', 'finance', 'documents', '/']">реестр документов</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/documents-autocheck'}" routerLink="/report/documents-autocheck" [e2bPermission]="['menu', 'report', 'finance', 'documents-autocheck', '/']">автосверка документов</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/prognosis'}" routerLink="/report/prognosis" [e2bPermission]="['menu', 'report', 'finance', 'prognosis', '/']">прогноз продаж</div>
        </div>
      </div>
      <div class="item gray" [ngClass]="{'active': path == '/report/manager-activity'}" routerLink="/report/manager-activity" [e2bPermission]="['menu', 'report', 'manager-activity','/']">активность менеджеров</div>
      <div class="item gray" [ngClass]="{'active': path == '/report/incoming-deals'}" routerLink="/report/incoming-deals" [e2bPermission]="['menu', 'report', 'incoming', '/']">входящие сделки</div>
      <div class="item gray" [ngClass]="{'active': path == '/report/forgotten'}" routerLink="/report/forgotten" [e2bPermission]="['menu', 'report', 'forgotten', '/']">забытые сделки</div>
      <div class="item gray" [ngClass]="{'active': path == '/report/problem'}" routerLink="/report/problem" [e2bPermission]="['menu', 'report', 'problem', '/']">проблемы</div>
      <div class="item gray" [ngClass]="{'active': path == '/report/reclamation'}" routerLink="/report/reclamation" [e2bPermission]="['menu', 'report', 'reclamation', '/']">рекламации</div>
      <div class="item gray" [ngClass]="{'active': path == '/report/production'}" routerLink="/report/production" [e2bPermission]="['menu', 'report', 'production', '/']">производство</div>
      <div class="item gray" [ngClass]="{'active': path == '/report/time-status'}" routerLink="/report/time-status" [e2bPermission]="['menu', 'report', 'time', '/']">время статусов сделок</div>
      <div class="item gray" [ngClass]="{'active': path == '/report/assortment'}" routerLink="/report/assortment" [e2bPermission]="['menu', 'report', 'assortment', '/']">ассортимент</div>
      <div class="item gray" [ngClass]="{'active': path == '/report/call'}" routerLink="/report/call" [e2bPermission]="['menu', 'report', 'calls', '/']">телефония</div>
      <div class="item gray" [ngClass]="{'active': path == '/report/history'}" routerLink="/report/history" [e2bPermission]="['menu', 'report', 'history', '/']">история изменения полей</div>
    </div>
  </div>
  <div class="ui dropdown pointing link item gray tablet-hide" suiDropdown *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']" [e2bPermission]="['menu', 'brs', '/']">
    <div class="text">сбр</div>
    <i class="dropdown icon"></i>
    <div class="ui vertical menu" suiDropdownMenu>
      <div class="item gray" routerLink="/brs/task/list" [e2bPermission]="['menu', 'brs', 'task', '/']">заявки</div>
      <div class="item gray" routerLink="/brs/contragent/list" [e2bPermission]="['menu', 'brs', 'contragent', '/']">контрагенты</div>
      <div class="item gray" routerLink="/brs/task-status/list" [e2bPermission]="['menu', 'brs', 'status', '/']">статусы</div>
      <div class="item gray" routerLink="/brs/report" [e2bPermission]="['menu', 'brs', 'report', '/']">отчет БДДС</div>
    </div>
  </div>
  <div class="ui dropdown pointing link item gray tablet-hide" suiDropdown *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']" [e2bPermission]="['menu', 'project', '/']">
    <div class="text">задачи</div>
    <i class="dropdown icon"></i>
    <div class="ui vertical menu" suiDropdownMenu>
      <div class="item gray" routerLink="/project/list" [e2bPermission]="['menu', 'project', 'list', '/']">список задач</div>
      <div class="item gray" routerLink="/project/add" [e2bPermission]="['menu', 'project', 'add', '/']">добавить задачу</div>
    </div>
  </div>
  <a class="item gray tablet-hide" [ngClass]="{'active': path == '/report/current'}" routerLink="/report/current" [e2bPermission]="['production','current','/']" *ngxPermissionsOnly="['E2B.ROLE.PRODUCTION']">текущие заказы</a>
  <a class="item gray tablet-hide" [ngClass]="{'active': path == '/report/catalog'}" routerLink="/report/catalog" [e2bPermission]="['production','archive','/']" *ngxPermissionsOnly="['E2B.ROLE.PRODUCTION']">выполненные заказы</a>

  <div class="ui dropdown pointing item gray tablet-menu" suiDropdown *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']">
    <i class="ellipsis horizontal large icon"></i>
    <div class="menu" suiDropdownMenu>
      <div class="ui dropdown pointing item gray" suiDropdown>
        <div class="text">сотрудники</div>
        <i class="dropdown icon"></i>
        <div class="menu" suiDropdownMenu>
          <div class="item gray" [ngClass]="{'active': path == '/staff/list'}" routerLink="/staff/list" [e2bPermission]="['menu', 'staff', 'list', '/']">сотрудники</div>
          <div class="item gray" [ngClass]="{'active': path == '/staff/group'}" routerLink="/staff/group" [e2bPermission]="['menu', 'staff', 'group', '/']">группы</div>
          <div class="item gray" [ngClass]="{'active': path == '/staff/role/list'}" routerLink="/staff/role/list" [e2bPermission]="['menu', 'staff', 'role', '/']">роли</div>
          <div class="item gray" [ngClass]="{'active': path == '/staff/holiday'}" routerLink="/staff/holiday" [e2bPermission]="['menu', 'staff', 'holiday', '/']">табель выходных</div>
        </div>
      </div>
      <div class="ui dropdown pointing item gray" suiDropdown>
        <div class="text">отчеты</div>
        <i class="dropdown icon"></i>
        <div class="menu" suiDropdownMenu>
          <div class="item gray" [ngClass]="{'active': path == '/report/gauging'}" routerLink="/report/gauging" [e2bPermission]="['menu', 'report', 'delivery-mounting', 'measure', '/']">замеры</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/delivery'}" routerLink="/report/delivery" [e2bPermission]="['menu', 'report', 'delivery-mounting', 'delivery', '/']">доставки</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/mounting'}" routerLink="/report/mounting" [e2bPermission]="['menu', 'report', 'delivery-mounting', 'mounting', '/']">монтажи</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/money'}" routerLink="/report/money" [e2bPermission]="['menu', 'report', 'delivery-mounting', 'money', '/']">деньги с монтажей</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/pnl'}" routerLink="/report/pnl" [e2bPermission]="['menu', 'report', 'finance', 'pnl', '/']">P&L</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/sale'}" routerLink="/report/sale" [e2bPermission]="['menu', 'report', 'finance', 'sale', '/']">продажи</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/rentability'}" routerLink="/report/rentability" [e2bPermission]="['menu', 'report', 'finance', 'rentability', '/']">себестоимость</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/square'}" routerLink="/report/square" [e2bPermission]="['menu', 'report', 'finance', 'square', '/']">стоимость м2</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/credit'}" routerLink="/report/credit" [e2bPermission]="['menu', 'report', 'finance', 'credit', '/']">сделки в рассрочку</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/bonus'}" routerLink="/report/bonus" [e2bPermission]="['menu', 'report', 'finance', 'bonus', '/']">бонусы менеджеров</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/documents'}" routerLink="/report/documents" [e2bPermission]="['menu', 'report', 'finance', 'documents', '/']">реестр документов</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/documents-autocheck'}" routerLink="/report/documents-autocheck" [e2bPermission]="['menu', 'report', 'finance', 'documents-autocheck', '/']">автосверка документов</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/prognosis'}" routerLink="/report/prognosis" [e2bPermission]="['menu', 'report', 'finance', 'prognosis', '/']">прогноз продаж</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/manager-activity'}" routerLink="/report/manager-activity" [e2bPermission]="['menu', 'report', 'manager-activity','/']">активность менеджеров</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/incoming-deals'}" routerLink="/report/incoming-deals" [e2bPermission]="['menu', 'report', 'incoming', '/']">входящие сделки</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/forgotten'}" routerLink="/report/forgotten" [e2bPermission]="['menu', 'report', 'forgotten', '/']">забытые сделки</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/problem'}" routerLink="/report/problem" [e2bPermission]="['menu', 'report', 'problem', '/']">проблемы</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/reclamation'}" routerLink="/report/reclamation" [e2bPermission]="['menu', 'report', 'reclamation', '/']">рекламации</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/production'}" routerLink="/report/production" [e2bPermission]="['menu', 'report', 'production', '/']">производство</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/time-status'}" routerLink="/report/time-status" [e2bPermission]="['menu', 'report', 'time', '/']">время статусов сделок</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/assortment'}" routerLink="/report/assortment" [e2bPermission]="['menu', 'report', 'assortment', '/']">ассортимент</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/call'}" routerLink="/report/call" [e2bPermission]="['menu', 'report', 'calls', '/']">телефония</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/history'}" routerLink="/report/history" [e2bPermission]="['menu', 'report', 'history', '/']">история изменения полей</div>
        </div>
      </div>
      <div class="ui dropdown pointing item gray" suiDropdown>
        <div class="text">сбр</div>
        <i class="dropdown icon"></i>
        <div class="menu" suiDropdownMenu>
          <div class="item gray" routerLink="/brs/task/list" [e2bPermission]="['menu', 'brs', 'task', '/']">заявки</div>
          <div class="item gray" routerLink="/brs/contragent/list" [e2bPermission]="['menu', 'brs', 'contragent', '/']">контрагенты</div>
          <div class="item gray" routerLink="/brs/task-status/list" [e2bPermission]="['menu', 'brs', 'status', '/']">статусы</div>
          <div class="item gray" routerLink="/brs/report" [e2bPermission]="['menu', 'brs', 'report', '/']">отчет БДДС</div>
        </div>
      </div>
      <div class="ui dropdown pointing link item gray" suiDropdown *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']" [e2bPermission]="['menu', 'project', '/']">
        <div class="text">задачи</div>
        <i class="dropdown icon"></i>
        <div class="menu" suiDropdownMenu>
          <div class="item gray" routerLink="/project/list" [e2bPermission]="['menu', 'project', 'list', '/']">список задач</div>
          <div class="item gray" routerLink="/project/add" [e2bPermission]="['menu', 'project', 'add', '/']">добавить задачу</div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui dropdown pointing item gray tablet-menu" suiDropdown *ngxPermissionsOnly="['E2B.ROLE.PRODUCTION']">
    <i class="ellipsis horizontal large icon"></i>
    <div class="menu" suiDropdownMenu>
      <div class="ui dropdown pointing item gray" suiDropdown>
        <div class="text">отчеты</div>
        <i class="dropdown icon"></i>
        <div class="menu" suiDropdownMenu>
          <div class="item gray" [ngClass]="{'active': path == '/report/current'}" routerLink="/report/current" [e2bPermission]="['production','current','/']">текущие заказы</div>
          <div class="item gray" [ngClass]="{'active': path == '/report/catalog'}" routerLink="/report/catalog" [e2bPermission]="['production','archive','/']">выполненные заказы</div>
        </div>
      </div>
    </div>
  </div>
</div>
