import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { FileTemplate } from '../../../_models/file-template.model';
import { FileInterface, FileService } from '../../../_services/file.service';
import { TemplateService } from '../../../_services/template.service';
import { UserService } from '../../../_services/user.service';
import { ActionService } from '../../../action/action.service';

@Component({
  selector: 'e2b-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/header.min.css',
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class TemplateListComponent implements OnInit {
  loaded = false;
  onDelete = new EventEmitter<FileInterface>();
  templates: FileTemplate[] = [];

  constructor(private actionService: ActionService,
              private userService: UserService,
              private templateService: TemplateService,
              private fileService: FileService) {
  }

  add() {
    const m = new FileTemplate();
    this.templates.unshift(m);
  }

  load() {
    this.loaded = false;
    this.templates = [];

    this.templateService.find<FileTemplate>().subscribe(templates => {
      this.templates = templates;
      this.loaded = true;
    });
  }

  ngOnInit() {

    const addEvent = new EventEmitter();

    if (this.userService.checkPermissions(['template', '/'], ['write'])) {
      this.actionService.createActionEvent.emit({
        name: 'Добавить',
        event: addEvent,
        class: 'blue',
      });
    }

    const updateEvent = new EventEmitter();
    this.actionService.createActionEvent.emit({
      name: 'Обновить',
      event: updateEvent,
      class: 'blue',
      icon: 'sync'
    });

    updateEvent.subscribe(() => {
      this.load();
    });

    addEvent.subscribe(() => {
      this.add();
    });

    this.load();
  }

  remove(template) {
    if (!template.id) {
      this.templates.splice(this.templates.indexOf(template), 1);
      return false;
    }
    const yes = confirm('Вы уверены, что хотите удалить шаблон ' + template.name + '?');
    if (!yes) {
      return true;
    }
    this.templateService.deleteById(template.id).subscribe(() => {
      this.templates.splice(this.templates.indexOf(template), 1);
    });
  }

  save(template: FileTemplate) {
    if (!template.name || !template.file) {
      return false;
    }
    if (template.id) {
      this.templateService.update(template).subscribe(() => {
      }, e => {
        console.error(e);
      });
    } else {
      this.templateService.create<FileTemplate>(template).subscribe(created => {
        this.templates[this.templates.indexOf(template)] = created;
      }, e => {
        console.error(e);
      });
    }
  }

  update(template) {
    if (template.files) {
      this.fileService.upload(template.files).then((f: FileInterface[]) => {
        if (f.length === 1) {
          template.file = f[0];
          delete template.files;
          this.save(template);
        }
      });
    } else {
      this.save(template);
    }
  }
}

