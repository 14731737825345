import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Client } from '../../_models/client.model';
import { Deal } from '../../_models/deal.model';

@Component({
  selector: 'e2b-widget',
  templateUrl: './deal-wizard.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/container.min.css',
    '../../../assets/design/semantic-ui/components/step.min.css',
    '../../../assets/design/semantic-ui/components/button.min.css',
    '../../../assets/design/semantic-ui/components/card.min.css',
  ],
  encapsulation: ViewEncapsulation.None
})
export class DealWizardComponent implements OnInit {

  step = 1;
  clientId: number;
  client: Client;
  dealId: number;
  deal: Deal;
  clientSaved = new EventEmitter<Client>();
  dealSaved = new EventEmitter<Deal>();

  constructor() {
    this.clientSaved.subscribe(client => {
      this.client = client;
      this.clientId = client.id;
      this.step = 2;
    }, console.error);
    this.dealSaved.subscribe(deal => {
      this.step = 3;
      this.deal = deal;
      this.dealId = deal.id;
    }, console.error);
  }

  ngOnInit() {

  }

}
