import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Todo } from '../../_models/todo.model';
import { User } from '../../_models/user.model';
import { TodoService } from '../../_services/todo.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';
import { OrderService } from '../../_services/order.service';
import { OrderPositionValueService } from '../../_services/order-position-value.service';
import { OrderStatus } from '../../_models/enums/order-status.enum';
import { Order } from '../../_models/order.model';

@Component({
  selector: 'e2b-dashboard-production',
  templateUrl: './dashboard-production.component.html',
  styleUrls: [
    './dashboard-production.component.scss',
    '../../../assets/design/semantic-ui/components/container.min.css',
    '../../../assets/design/semantic-ui/components/label.min.css',
    '../../../assets/design/semantic-ui/components/dimmer.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardProductionComponent implements OnInit {

  currentUpdate: number | null = null;
  day: string = moment().format('YYYY-MM-DD');
  orders: { new: any[], acceptedWeek: any[], acceptedAfter: any[], sos: any[] } = {
    new: [],
    acceptedWeek: [],
    acceptedAfter: [],
    sos: []
  };
  loaded = true;
  @Input()
  reload: EventEmitter<any>;
  selectUserEvent = new EventEmitter();
  sortingAcceptedAfter = '-readyDate:date';
  sortingAcceptedWeek = '-readyDate:date';
  sortingNew = '-readyDate:date';
  sortingSOS = '-readyDate:date';
  todos: Todo[] = [];
  users: User[];

  constructor(private actionService: ActionService,
              private todoService: TodoService,
              private orderService: OrderService,
              private orderPositionValueService: OrderPositionValueService,
              private userService: UserService) {
  }

  accept(order) {
    this.currentUpdate = order.id;
    this.orderService.productionAccept({ id: order.id }).subscribe(() => {
      this.loadProduction();
    });
  }

  bonusAccepted() {
    let bonus = 0;
    if (!this.orders.acceptedAfter) {
      return bonus;
    }
    this.orders.acceptedAfter.forEach(deal => {
      bonus += deal.priceSum;
    });
    this.orders.acceptedWeek.forEach(deal => {
      bonus += deal.priceSum;
    });
    return bonus;
  }

  bonusOpened() {
    let bonus = 0;
    if (!this.orders.new) {
      return bonus;
    }
    this.orders.new.map(deal => {
      bonus += deal.priceSum;
    });
    return bonus;
  }

  bonusSos() {
    let bonus = 0;
    if (!this.orders.sos) {
      return bonus;
    }
    this.orders.sos.forEach(deal => {
      bonus += deal.priceSum;
    });
    return bonus;
  }

  finish(order) {
    this.currentUpdate = order.id;
    let positionsCounter = 0;
    order.positions.map(orderPosition => {
      this.orderService.productionFinish({ id: orderPosition.id }).subscribe(() => {
        positionsCounter++;
        if (positionsCounter === order.positions.length) {
          this.loadProduction();
        }
      });
    });
  }

  decline(order) {
    this.currentUpdate = order.id;
    const message = prompt('Укажите причину отказа');
    if (!message || message.trim() === '') {
      return false;
    }
    this.orderService.productionDecline({ id: order.id, message }).subscribe(() => {
      this.orders.new.splice(this.orders.new.indexOf(order), 1);
    });
  }

  identify(index, obj) {
    return obj ? obj.id : undefined;
  }

  loadProduction() {
    this.loaded = false;
    this.currentUpdate = null;
    this.orders = {
      new: [],
      acceptedWeek: [],
      acceptedAfter: [],
      sos: []
    };
    this.orderService.productionOrders().subscribe(orders => {
      this.orders = orders;
      this.loaded = true;
    });
  }

  ngOnInit() {
    if (this.userService.checkRoles(['E2B.ROLE.PRODUCTION'])) {
      this.actionService.createActionEvent.emit({
        name: 'Обновить',
        event: this.reload,
        class: 'blue',
        icon: 'sync',
        roles: ['E2B.ROLE.PRODUCTION']
      });
      this.loadProduction();
    }
    this.reload.subscribe(() => {
      this.loadProduction();
    });
  }

  sos(order) {
    const message = window.prompt('Что случилось?');

    if (!message || message.trim() === '') {
      return false;
    }

    this.orderService.productionSos({ id: order.id, message }).subscribe(() => {
      if (this.orders.acceptedWeek.indexOf(order) > -1) {
        this.orders.acceptedWeek.splice(this.orders.acceptedWeek.indexOf(order), 1);
      } else if (this.orders.acceptedAfter.indexOf(order) > -1) {
        this.orders.acceptedAfter.splice(this.orders.acceptedAfter.indexOf(order), 1);
      } else if (this.orders.new.indexOf(order) > -1) {
        this.orders.new.splice(this.orders.new.indexOf(order), 1);
      }
      this.orders.sos.push(order);
    });
  }

  unload(order) {
    this.currentUpdate = order.id;

    let positionsCounter = 0;
    order.positions.map(orderPosition => {
      this.orderService.productionUnload({ id: orderPosition.id }).subscribe(() => {
        positionsCounter++;
        if (positionsCounter === order.positions.length) {
          this.orderService.update<Order>(<Order>{ id: order.id, status: OrderStatus.COMPLETED }).subscribe(() => {
          });
          this.loadProduction();
        }
      });
    });
  }

}
