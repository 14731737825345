import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Client } from '../../_models/client.model';
import { DealPosition } from '../../_models/deal-position.model';
import { DealStatus } from '../../_models/deal-status.model';
import { Deal } from '../../_models/deal.model';
import { Filial } from '../../_models/filial.model';
import { Position } from '../../_models/position.model';
import { UserGroup } from '../../_models/user-group.model';
import { User } from '../../_models/user.model';
import { ClientService } from '../../_services/client.service';
import { DealPositionService } from '../../_services/deal-position.service';
import { DealStatusService } from '../../_services/deal-status.service';
import { DealService } from '../../_services/deal.service';
import { FilialService } from '../../_services/filial.service';
import { PositionService } from '../../_services/position.service';
import { UserGroupService } from '../../_services/user-group.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'e2b-deal-add',
  templateUrl: './deal-add.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css',
    '../../../assets/design/semantic-ui/components/icon.min.css',
    '../../../assets/design/semantic-ui/components/button.min.css',
    './deal-add.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DealAddComponent implements OnInit, OnDestroy {
  @Input()
  clientId: number;
  @Input()
  saveEvent: EventEmitter<Deal>;
  deal: Deal = new Deal();
  employees: User[] = [];
  selectClient: EventEmitter<Client> = new EventEmitter<Client>();
  selectFilial: EventEmitter<Filial> = new EventEmitter<Filial>();
  selectAuditor: EventEmitter<UserGroup[]> = new EventEmitter<UserGroup[]>();
  clients: Client[] = [];
  positions: Position[] = [];
  auditors: UserGroup[] = [];
  filials: Filial[] = [];
  statuses: DealStatus[];
  groups: UserGroup[];
  addDealPosition: EventEmitter<DealPosition> = new EventEmitter<DealPosition>();
  removeDealPosition: EventEmitter<DealPosition> = new EventEmitter<DealPosition>();
  selectManager: EventEmitter<User> = new EventEmitter<User>();
  private employeeSubscribe: Subscription;
  private client: Client;
  loading = false;

  constructor(private clientService: ClientService,
              private positionService: PositionService,
              private dealStatusService: DealStatusService,
              private userService: UserService,
              private userGroupService: UserGroupService,
              private employeeService: UserService,
              private dealService: DealService,
              private filialService: FilialService,
              private dealPositionService: DealPositionService,
              private route: ActivatedRoute,
              private router: Router) {
    this.deal.client = new Client();
    this.deal.positions = [];

    this.route.queryParams.subscribe(params => {
      if (params.clientId) {
        this.clientId = parseInt(params.clientId, 0);
      }
    });

    this.selectClient.subscribe(client => {
      this.deal.client = client;
    });

    this.selectFilial.subscribe(filial => {
      this.deal.filial = filial;
    });

    this.selectManager.subscribe(user => {
      this.deal.manager = user;
    });

    this.addDealPosition.subscribe(position => {
      position.deal = {};
      this.deal.positions.push(position);
    });

    this.selectAuditor.subscribe(groups => {
      this.auditors = groups;
    });

    this.removeDealPosition.subscribe(position => {
      if (position.id) {
        this.dealPositionService.deleteById<DealPosition>(position.id).subscribe(() => {
          this.deal.positions.splice(this.deal.positions.indexOf(position), 1);
        }, console.error);
      } else {
        this.deal.positions.splice(this.deal.positions.indexOf(position), 1);
      }
    });
  }

  ngOnInit() {
    this.clientService.find<Client>({ list: true }).subscribe(clients => {
      this.clients = clients;
      if (this.clientId) {
        clients.map(client => {
          if (client.id === this.clientId) {
            this.client = client;
          }
        });
      }
    }, console.error);
    this.positionService.find<Position>().subscribe(positions => this.positions = positions, console.error);
    this.dealStatusService.find<DealStatus>().subscribe(statuses => this.statuses = statuses, console.error);
    this.userGroupService.find<UserGroup>().subscribe(groups => this.groups = groups, console.error);
    this.filialService.find<Filial>().subscribe(filials => this.filials = filials, console.error);
    const user = this.userService.current();
    this.employeeSubscribe = this.employeeService.find<User>().subscribe(employees => {
      this.employees = employees;
      this.employees.map(employee => {
        if (user.id === employee.id) {
          this.deal.manager = employee;
        }
      });
    }, console.error);
  }

  totalDealSum() {
    let totalSum = 0;
    if (this.deal.positions.length > 0) {
      this.deal.positions.map(pos => {
        totalSum += pos.price * pos.count;
        if (pos.discountPrice) {
          totalSum -= pos.discountPrice;
        }
      });
    }
    return totalSum;
  }

  save() {
    if (this.clientId) {
      this.deal.client.id = this.clientId;
    }
    this.loading = true;
    this.dealService.create<Deal>(this.deal).subscribe(created => {
      this.auditors.map(user => {
        this.dealService.addResponsible(created.id, user).subscribe(() => {
        }, console.error);
      });
      if (this.saveEvent) {
        this.saveEvent.emit(created);
      } else {
        this.router.navigate(['/deal/card/' + created.id]).catch(console.error);
      }
    }, console.error);
  }

  cancel() {
    this.router.navigateByUrl('/deal/list').catch(console.error);
  }

  ngOnDestroy() {
    if (this.addDealPosition) {
      this.addDealPosition.unsubscribe();
    }
    if (this.removeDealPosition) {
      this.removeDealPosition.unsubscribe();
    }
    if (this.selectClient) {
      this.selectClient.unsubscribe();
    }
    if (this.employeeSubscribe) {
      this.employeeSubscribe.unsubscribe();
    }
  }
}

