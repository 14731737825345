import { BaseModel } from './base.model';
import { DealPosition } from './deal-position.model';
import { OrderDealPositionValue } from './order-deal-position-value.model';
import { Order } from './order.model';

export class OrderDealPosition extends BaseModel {
  completed: boolean;
  dealPosition: DealPosition;
  deleted: boolean;
  order: Order;
  orderDealPositionValues: OrderDealPositionValue[];
}
