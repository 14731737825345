import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AssemblyScheme} from '../../../_models/assembly-scheme.model';
import {AssemblySchemeService} from '../../../_services/assembly-scheme.service';
import {UserService} from '../../../_services/user.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'e2b-assembly-scheme-show',
  templateUrl: './assembly-scheme-show.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/header.min.css',
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../../assets/design/semantic-ui/components/label.min.css',
    '../../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class AssemblySchemeShowComponent implements OnInit {

  id: number;

  assemblySheme: AssemblyScheme = new AssemblyScheme();

  loaded = false;

  constructor(public userService: UserService,
              private route: ActivatedRoute,
              private assemblySchemeService: AssemblySchemeService) {
  }

  ngOnInit() {

    this.id = parseInt(this.route.snapshot.params.id, 0);

    this.load();
  }

  trackByFn(index, item) {
    return item.id;
  }

  load() {
    this.assemblySchemeService.findById<AssemblyScheme>(this.id).subscribe(scheme => {
      this.assemblySheme = scheme;
      this.loaded = true;
    });
  }
}
