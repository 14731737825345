import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { BaseModel } from '../../../_models/base.model';
import { OrderDealPositionValue } from '../../../_models/order-deal-position-value.model';
import { OrderDealPosition } from '../../../_models/order-deal-position.model';
import { OrderPositionValueService } from '../../../_services/order-position-value.service';
import { FileInterface, FileService } from '../../../_services/file.service';

@Component({
  selector: 'e2b-order-position-field',
  templateUrl: './order-position-field.component.html',
  styleUrls: ['./order-position-field.component.less']
})
export class OrderPositionFieldComponent implements OnInit {

  @Input()
  orderDealPositionValues: OrderDealPositionValue[];

  @Input()
  currentPosition: OrderDealPosition;

  constructor(private fileService: FileService,
              private orderPositionValueService: OrderPositionValueService) {
  }

  ngOnInit() {
  }

  deleteFile(orderDealPositionValue: OrderDealPositionValue, file: any) {
    const yes = confirm('Вы уверены, что хотите удалить файл ' + file.name + '?');
    if (!yes) {
      return true;
    }

    orderDealPositionValue.value.splice(orderDealPositionValue.value.indexOf(file), 1);
    this.updatePositionValue(orderDealPositionValue);
  }

  onPositionValueChange(orderDealPositionValue: any) {
    if (!this.currentPosition) {
      return;
    }

    if (orderDealPositionValue.orderField.type === 'FILE' && orderDealPositionValue.files) {
      this.fileService.upload(orderDealPositionValue.files).then((f: FileInterface[]) => {
        orderDealPositionValue.value = [...(orderDealPositionValue.value || []), ...f];
        this.updatePositionValue(orderDealPositionValue);
      });
    } else {
      this.updatePositionValue(orderDealPositionValue);
    }
  }

  updatePositionValue(orderDealPositionValue: OrderDealPositionValue) {

    const payload: {
      id?: number;
      orderDealPositionValue: OrderDealPositionValue,
    } = {
      orderDealPositionValue,
    };

    if (orderDealPositionValue.id) {
      payload.id = orderDealPositionValue.id;
      this.orderPositionValueService.update(<BaseModel><unknown>payload).subscribe(() => {
      });
    } else {
      payload.id = this.currentPosition.id;
      this.orderPositionValueService.create<OrderDealPositionValue>(<BaseModel><unknown>payload).subscribe(createdOrderDealPositionValue => {
        orderDealPositionValue.id = createdOrderDealPositionValue.id;
      });
    }
  }
}
