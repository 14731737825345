import { BaseModel } from './base.model';
import { Todo } from './todo.model';

export class TodoType extends BaseModel {
  name: string;
  description: string;
  color: string;
  deleted: boolean;
  systemType: string;
  todos: Todo[];
}
