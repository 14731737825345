import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import * as moment from 'moment';
import { SuiModule } from 'ng2-semantic-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToolTipDirective } from './_directives/tool-tip.directive';
import { LoggedInUserGuard } from './_guards/logged-in-user.guard';
import { PermissionGuard } from './_guards/permission.guard';
import { ApiHttpInterceptor } from './_interceptors/api-http-interceptor';
import { PermissionHttpInterceptor } from './_interceptors/permission-http-interceptor';
import { AddressService } from './_services/address.service';
import { AssemblySchemeService } from './_services/assembly-scheme.service';
import { AssemblyStepService } from './_services/assembly-step.service';
import { BankService } from './_services/bank.service';
import { BudgetService } from './_services/budget.service';
import { CallService } from './_services/call.service';
import { ChatService } from './_services/chat.service';
import { ClientChannelService } from './_services/client-channel.service';
import { ClientTypeService } from './_services/client-type.service';
import { ClientService } from './_services/client.service';
import { CommentService } from './_services/comment.service';
import { ContactService } from './_services/contact.service';
import { ContractorService } from './_services/contractor.service';
import { ControlpointService } from './_services/controlpoint.service';
import { DealFieldValueService } from './_services/deal-field-value.service';
import { DealFieldService } from './_services/deal-field.service';
import { DealPositionValueService } from './_services/deal-position-value.service';
import { DealPositionService } from './_services/deal-position.service';
import { DealStatusService } from './_services/deal-status.service';
import { DealStepService } from './_services/deal-step.service';
import { DealService } from './_services/deal.service';
import { DepartmentService } from './_services/department.service';
import { DirectionService } from './_services/direction.service';
import { FileService } from './_services/file.service';
import { FilialService } from './_services/filial.service';
import { FinanceService } from './_services/finance.service';
import { JobService } from './_services/job.service';
import { NotificationSettingsService } from './_services/notification-settings.service';
import { NotificationService } from './_services/notification.service';
import { OrderFieldService } from './_services/order-field.service';
import { OrderPositionValueService } from './_services/order-position-value.service';
import { OrderPositionService } from './_services/order-position.service';
import { OrderService } from './_services/order.service';
import { OwnService } from './_services/own.service';
import { PersonTypeService } from './_services/person-type.service';
import { PersonService } from './_services/person.service';
import { PlanService } from './_services/plan.service';
import { PositionClothPriceService } from './_services/position-cloth-price.service';
import { PositionClothService } from './_services/position-cloth.service';
import { PositionFieldValueService } from './_services/position-field-value.service';
import { PositionGroupTemplateService } from './_services/position-group-template.service';
import { PositionGroupService } from './_services/position-group.service';
import { PositionMaterialService } from './_services/position-material.service';
import { PositionPersonalService } from './_services/position-personal.service';
import { PositionService } from './_services/position.service';
import { ProblemService } from './_services/problem.service';
import { ProjectCommentService } from './_services/project-comment.service';
import { ReclamationService } from './_services/reclamation.service';
import { ReferenceService } from './_services/reference.service';
import { ReminderService } from './_services/reminder.service';
import { ReportService } from './_services/report.service';
import { RoleService } from './_services/role.service';
import { RouterEventService } from './_services/router-event.service';
import { SaleService } from './_services/sale.service';
import { ServiceService } from './_services/service.service';
import { SettingService } from './_services/setting.service';
import { TaskCommentService } from './_services/task-comment.service';
import { TaskFileService } from './_services/task-file.service';
import { TaskService } from './_services/task.service';
import { TemplateService } from './_services/template.service';
import { TodoCheckpointService } from './_services/todo-checkpoint.service';
import { TodoStatusService } from './_services/todo-status.service';
import { TodoTypeService } from './_services/todo-type.service';
import { TodoService } from './_services/todo.service';
import { UnitService } from './_services/unit.service';
import { UserGroupService } from './_services/user-group.service';
import { UserHolidayService } from './_services/user-holiday.service';
import { UserService } from './_services/user.service';
import { ActionComponent } from './action/action.component';
import { ActionService } from './action/action.service';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthUserComponent } from './auth-user/auth-user.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CatalogModule } from './catalog/catalog.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ClientModule } from './client/client.module';
import { DashboardControlpointComponent } from './dashboard/dashboard-controlpoint/dashboard-controlpoint.component';
import { DashboardDirectorBudgetComponent } from './dashboard/dashboard-director/dashboard-director-budget/dashboard-director-budget.component';
import { DashboardDirectorFinanceComponent } from './dashboard/dashboard-director/dashboard-director-finance/dashboard-director-finance.component';
import { DashboardDirectorSaleComponent } from './dashboard/dashboard-director/dashboard-director-sale/dashboard-director-sale.component';
import { DashboardDirectorServiceComponent } from './dashboard/dashboard-director/dashboard-director-service/dashboard-director-service.component';
import { DashboardDirectorComponent } from './dashboard/dashboard-director/dashboard-director.component';
import { DashboardProductionComponent } from './dashboard/dashboard-production/dashboard-production.component';
import { DashboardSaleComponent } from './dashboard/dashboard-sale/dashboard-sale.component';
import { DashboardWidgetComponent } from './dashboard/dashboard-widget/dashboard-widget.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DealWizardComponent } from './dashboard/deal-wizard/deal-wizard.component';
import { DealModule } from './deal/deal.module';
import { ErrorComponent } from './error/error.component';
import { ErrorService } from './error/error.service';
import { FeedbackComponent } from './feedback/feedback.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { PageUnavailableComponent } from './page-unavailable/page-unavailable';
import { ReferenceShowComponent } from './reference/reference-show/reference-show.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SettingsComponent } from './settings/settings.component';
import { SharedModule } from './shared/shared.module';
import { TodoModule } from './todo/todo.module';
import { UserMenuComponent } from './user/user-menu/user-menu.component';
import { ShowWizardComponent } from './dashboard/show-wizard/show-wizard.component';
import { SalesWizardComponent } from './dashboard/sales-wizard/sales-wizard.component';
import { PersonInfoComponent } from './dashboard/sales-wizard/person-info/person-info.component';
import { DealFieldComponent } from './dashboard/sales-wizard/deal-field/deal-field.component';
import { DealPositionFieldComponent } from './dashboard/sales-wizard/deal-position-field/deal-position-field.component';
import { AppBottomBarComponent } from './app-bottom-bar/app-bottom-bar.component';
import { ActivityPopupComponent } from './app-bottom-bar/activity-popup/activity-popup.component';
import { ActivityComponent } from './activity/activity.component';
import { HighlightFilterPipe } from './_pipes/highlight-filter.pipe';
import { NotificationSettingsTypePipe } from './_pipes/notification-settings-type.pipe';
import { LkShortlinkComponent } from './lk-shortlink/lk-shortlink.component';
import { PolicyComponent } from './policy/policy.component';
import { SupportComponent } from './support/support.component';

registerLocaleData(localeRu, 'ru');
moment.locale('ru');

@NgModule({
  declarations: [
    ActionComponent,
    ActivityComponent,
    ActivityPopupComponent,
    AppBottomBarComponent,
    AppComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppMenuComponent,
    AuthUserComponent,
    BreadcrumbComponent,
    ChangePasswordComponent,
    DashboardComponent,
    DashboardControlpointComponent,
    DashboardDirectorBudgetComponent,
    DashboardDirectorComponent,
    DashboardDirectorFinanceComponent,
    DashboardDirectorSaleComponent,
    DashboardDirectorServiceComponent,
    DashboardProductionComponent,
    DashboardSaleComponent,
    DashboardWidgetComponent,
    DealFieldComponent,
    DealPositionFieldComponent,
    DealWizardComponent,
    ErrorComponent,
    FeedbackComponent,
    HighlightFilterPipe,
    LkShortlinkComponent,
    LoginComponent,
    NotificationSettingsTypePipe,
    LkShortlinkComponent,
    PolicyComponent,
    SupportComponent,
    PageNotFoundComponent,
    PageTitleComponent,
    PageUnavailableComponent,
    PersonInfoComponent,
    ReferenceShowComponent,
    ResetPasswordComponent,
    SalesWizardComponent,
    SettingsComponent,
    ShowWizardComponent,
    ToolTipDirective,
    UserMenuComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    CatalogModule,
    ClientModule,
    CommonModule,
    DealModule,
    FormsModule,
    HttpClientModule,
    HttpClientModule,
    NgProgressHttpModule,
    NgProgressModule,
    NgxPermissionsModule.forRoot(),
    ReactiveFormsModule,
    SharedModule,
    SuiModule,
    TodoModule,
  ],
  providers: [
    ActionService,
    AddressService,
    AssemblySchemeService,
    AssemblyStepService,
    BankService,
    BudgetService,
    CallService,
    ChatService,
    ClientChannelService,
    ClientService,
    ClientTypeService,
    CommentService,
    ContactService,
    ContractorService,
    ControlpointService,
    DashboardSaleComponent,
    DealFieldService,
    DealFieldValueService,
    DealPositionService,
    DealPositionValueService,
    DealService,
    DealStatusService,
    DealStepService,
    DepartmentService,
    DirectionService,
    ErrorService,
    FileService,
    FilialService,
    FinanceService,
    JobService,
    LoggedInUserGuard,
    NotificationService,
    NotificationSettingsService,
    OrderFieldService,
    OrderPositionService,
    OrderPositionValueService,
    OrderService,
    OwnService,
    PermissionGuard,
    PersonService,
    PersonTypeService,
    PlanService,
    PositionClothPriceService,
    PositionClothService,
    PositionFieldValueService,
    PositionGroupService,
    PositionGroupTemplateService,
    PositionMaterialService,
    PositionPersonalService,
    PositionService,
    ProblemService,
    ProjectCommentService,
    ReclamationService,
    ReferenceService,
    ReminderService,
    ReportService,
    RoleService,
    RouterEventService,
    SaleService,
    ServiceService,
    SettingService,
    TaskCommentService,
    TaskFileService,
    TaskService,
    TemplateService,
    Title,
    TodoCheckpointService,
    TodoService,
    TodoStatusService,
    TodoTypeService,
    UnitService,
    UserGroupService,
    UserHolidayService,
    UserService,
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PermissionHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
