import { ViewEncapsulation } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NotificationTypeEnum } from '../_models/enums/notification-type.enum';
import { HighlightPipe } from '../_pipes/highlight.pipe';
import { NotificationService } from '../_services/notification.service';
import { UserService } from '../_services/user.service';
import { ActionService } from '../action/action.service';

@Component({
  selector: 'e2b-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.less',
    '../../assets/design/semantic-ui/components/loader.min.css',
    '../../assets/design/semantic-ui/components/dimmer.min.css',
    '../../assets/design/semantic-ui/components/segment.min.css',
    '../../assets/design/semantic-ui/components/comment.min.css',
    '../../assets/design/semantic-ui/components/grid.min.css',
    '../../assets/design/semantic-ui/components/menu.min.css',
  ],
  providers: [HighlightPipe],
  encapsulation: ViewEncapsulation.None
})
export class ActivityComponent implements OnInit {
  loaded = false;
  searchQuery = '';

  activities: any[] = [
    { id: 1, name: 'СБР', color: '#006ab9', notificationTypes: [NotificationTypeEnum.TASK_STATUS, NotificationTypeEnum.TASK_COMMENT] },
    { id: 2, name: 'Проблемы', color: '#b91b00', notificationTypes: [NotificationTypeEnum.PROBLEM] },
    { id: 3, name: 'Рекламации', color: '#b9607f', notificationTypes: [NotificationTypeEnum.RECLAMATION] },
    { id: 4, name: 'Задачи', color: '#ff8949', notificationTypes: [NotificationTypeEnum.PROJECT_COMMENT, NotificationTypeEnum.PROJECT_STATUS, NotificationTypeEnum.PROJECT_CHECKLIST] },
    { id: 5, name: 'Общие', color: '#7f7f7f', notificationTypes: [NotificationTypeEnum.DEAL_COMMENT, NotificationTypeEnum.WHATSAPP] },
    {
      id: 6,
      name: 'Личные',
      color: '#8e6eb9',
      notificationTypes: [
        NotificationTypeEnum.PERSONAL,
        NotificationTypeEnum.DEAL_REMINDER,
        NotificationTypeEnum.TASK_REMINDER,
        NotificationTypeEnum.PROJECT_CHECKLIST_REMINDER,
        NotificationTypeEnum.PROJECT_REMINDER,
        NotificationTypeEnum.PERSONAL_WHATSAPP
      ]
    },
  ];

  notifications: any[] = [];

  typeFilters: number[] = [1];
  options: {
    type?: NotificationTypeEnum[],
    limit?: number,
    userId?: number[],
    offset?: number,
    from?: string,
    to?: string,
  } = {
    type: [],
    limit: 20,
    userId: [],
    from: localStorage.getItem('e2b.activity.date.from') || moment().startOf('month').format('YYYY-MM-DD'),
    to: localStorage.getItem('e2b.activity.date.to') || moment().endOf('day').format('YYYY-MM-DD')
  };

  users: any[] = [];

  notificationsCount: number;
  selectedPage: number;

  dateFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private readonly route: ActivatedRoute,
              private readonly actionService: ActionService,
              private highlight: HighlightPipe,
              private readonly notificationService: NotificationService,
              private readonly userService: UserService) {
  }

  ngOnInit() {
    if (this.userService.checkRoles(['E2B.ROLE.SUPERADMIN'])) {
      const readAll = new EventEmitter();
      this.actionService.createActionEvent.emit({
        name: 'Прочитать все',
        event: readAll,
        class: 'blue',
        icon: 'envelope open outline'
      });

      readAll.subscribe(() => {
        this.readAll();
      });
    }

    this.dateFilter.subscribe((date) => {
      this.options.from = date.startDate.format('YYYY-MM-DD');
      localStorage.setItem('e2b.activity.date.from', this.options.from);

      this.options.to = date.endDate.format('YYYY-MM-DD');
      localStorage.setItem('e2b.activity.date.to', this.options.to);

      this.loadActivity();
    });

    this.loadSessionData();
    this.loadActivity();
  }

  get NotificationTypeEnum() {
    return NotificationTypeEnum;
  }

  getNotificationTypeProps(notificationType: NotificationTypeEnum) {
    return this.activities.find(activity => activity.notificationTypes.indexOf(notificationType) > -1);
  }

  applyFilter(activity: any) {
    if (this.typeFilters.indexOf(activity.id) > -1) {
      this.typeFilters.splice(this.typeFilters.indexOf(activity.id), 1);
    } else {
      this.typeFilters.push(activity.id);
    }
    localStorage.setItem('e2b.activity.typeFilters', JSON.stringify(this.typeFilters));
    delete this.options.offset;
    this.selectedPage = 1;
    this.loadActivity();
  }

  applyUserFilter(userId: number) {
    if (this.options.userId.indexOf(userId) > -1) {
      this.options.userId.splice(this.options.userId.indexOf(userId), 1);
    } else {
      this.options.userId.push(userId);
    }
    localStorage.setItem('e2b.activity.userIdFilter', JSON.stringify(this.options.userId));
    delete this.options.offset;
    this.selectedPage = 1;
    this.loadActivity();
  }

  loadSessionData() {
    if (localStorage.getItem('e2b.activity.typeFilters')) {
      this.typeFilters = JSON.parse(localStorage.getItem('e2b.activity.typeFilters'));
    }
    if (localStorage.getItem('e2b.activity.userIdFilter')) {
      this.options.userId = JSON.parse(localStorage.getItem('e2b.activity.userIdFilter'));
    }
  }

  loadActivity() {
    this.loaded = false;
    this.options.type = this.typeFilters.map(id => (this.activities.find(activity => activity.id === id) || []).notificationTypes).filter(Boolean).flat();
    this.notificationService.findAll(this.options).subscribe(notifications => {
      this.users = notifications.users;
      this.notifications = notifications.data;
      this.notificationsCount = notifications.count;
      this.loaded = true;
    });
  }

  applySearchQuery(query: string) {
    this.notifications.map(notification => {
      notification.highlightedText = query.trim() && notification.text.toLowerCase().indexOf(query.trim().toLowerCase()) > -1
        ? this.highlight.transform(notification.text, query)
        : '';
    });
  }

  changePage(pageNumber: number) {
    this.options.offset = (pageNumber - 1) * this.options.limit;
    this.loadActivity();
  }

  read(notification: any) {
    if (!notification.read) {
      this.notificationService.update({ id: notification.readId }).subscribe(() => {
        this.loadActivity();
      });
    }
  }

  readAll() {
    this.notifications.map(notification => this.read(notification));
  }
}
