import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ClientType } from '../../../_models/client-type.model';
import { Client } from '../../../_models/client.model';

@Component({
  selector: 'e2b-contractors-add',
  templateUrl: './contractors-add.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../../assets/design/semantic-ui/components/button.min.css',
    './contractors-add.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContractorsAddComponent implements OnInit {
  contractor: Client = new Client();
  types: Array<ClientType> = [];

  constructor() {
  }

  ngOnInit() {
  }

}
