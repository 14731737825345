export enum SmsStatus {
  ENQUEUED = 'ENQUEUED',
  ACCEPTED = 'ACCEPTED',
  DELIVERED = 'DELIVERED',
  NON_DELIVERED = 'NON_DELIVERED',
  SCHEDULED = 'SCHEDULED',
  CANCELED = 'CANCELED',
  WAIT = 'WAIT',
  OVERDUE = 'OVERDUE',
}
