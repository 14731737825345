import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'orderByDate'
})

@Injectable()
export class OrderByDatePipe implements PipeTransform {

  transform(array: Array<any>, args: string, format: string): Array<any> {
    if (typeof args === 'undefined' || typeof array === 'undefined') {
      return [];
    }
    const direction = args[0];
    const column = args.replace('-', '');
    array.sort((a: any, b: any) => {
      if (format) {
        const left = Number(moment(a[column], format).toDate());
        const right = Number(moment(b[column], format).toDate());
        return (direction === '-') ? right - left : left - right;
      } else {
        const left = Number(new Date(a[column]));
        const right = Number(new Date(b[column]));
        return (direction === '-') ? right - left : left - right;
      }
    });
    return array;
  }
}
