import { Component, EventEmitter, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FinanceService } from '../../../_services/finance.service';
import { TaskService } from '../../../_services/task.service';
import { UserService } from '../../../_services/user.service';
import { BrsPointInterface } from './interfaces/brs-point.interface';
import { FinancePointInterface } from './interfaces/finance-point.interface';

@Component({
  selector: 'e2b-dashboard-director-finance',
  templateUrl: './dashboard-director-finance.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/statistic.min.css',
    '../../../../assets/design/semantic-ui/components/card.min.css',
    '../../../../assets/design/semantic-ui/components/image.min.css',
    '../../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../../assets/design/semantic-ui/components/list.min.css',
    './dashboard-director-finance.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardDirectorFinanceComponent implements OnInit {

  loaded = false;

  @Input()
  period: 'day' | 'week' | 'month' = localStorage.getItem('e2b.dashboard.director.period') ? localStorage.getItem('e2b.dashboard.director.period') as 'day' | 'week' | 'month' : 'month';

  @Input()
  day: Moment = localStorage.getItem('e2b.dashboard.director.date') ? moment(localStorage.getItem('e2b.dashboard.director.date'), 'YYYY-MM-DD') : moment();

  widgets = {
    'plan': 'План поступлений',
    'fact': 'Факт поступлений',
    'bankBalance': 'Остатки на счетах',
    'rentability': 'Себестоимость > МД1',
    'debtors': 'Должники',
    'brsCash': 'СБР: Наличные',
    'brsBank': 'СБР: Безнал',
  };

  selectedWidgets: string[] = localStorage.getItem('e2b.dashboard.director.finance') ? JSON.parse(localStorage.getItem('e2b.dashboard.director.finance')) : this.allWidgets;

  brs: {
    cash: {
      day: BrsPointInterface;
      week: BrsPointInterface;
      month: BrsPointInterface;
    };
    bank: {
      day: BrsPointInterface;
      week: BrsPointInterface;
      month: BrsPointInterface;
    }
  } = {
    cash: {
      day: {
        plan: 0,
        fact: 0,
        remain: 0,
        deficit: 0,
      },
      week: {
        plan: 0,
        fact: 0,
        remain: 0,
        deficit: 0,
      },
      month: {
        plan: 0,
        fact: 0,
        remain: 0,
        deficit: 0,
      },
    },
    bank: {
      day: {
        plan: 0,
        fact: 0,
        remain: 0,
        deficit: 0,
      },
      week: {
        plan: 0,
        fact: 0,
        remain: 0,
        deficit: 0,
      },
      month: {
        plan: 0,
        fact: 0,
        remain: 0,
        deficit: 0,
      },
    }
  };

  options: {
    date: string,
  } = {
    date: this.day.format('YYYY-MM-DD')
  };

  data: {
    day: FinancePointInterface,
    week: FinancePointInterface,
    month: FinancePointInterface
  } = {
    day: {
      mountingMoney: {
        plan: 0,
        fact: 0,
      },
      reclamation: {
        plan: 0,
        fact: 0,
        planDetails: []
      },
      prognosis: {
        plan: 0,
        fact: 0,
      },
      balance: {
        cash: 0,
        card: 0,
        bank: 0,
        details: {
          cash: [],
          card: [],
          bank: []
        }
      },
      rentability: {
        count: 0,
        percent: 0
      },
      losses: {
        present: 0,
        previous: 0
      },
      debtors: 0
    },
    week: {
      mountingMoney: {
        plan: 0,
        fact: 0,
      },
      reclamation: {
        plan: 0,
        fact: 0,
        planDetails: []
      },
      prognosis: {
        plan: 0,
        fact: 0,
      },
      balance: {
        cash: 0,
        card: 0,
        bank: 0,
        details: {
          cash: [],
          card: [],
          bank: []
        }
      },
      rentability: {
        count: 0,
        percent: 0
      },
      losses: {
        present: 0,
        previous: 0
      },
      debtors: 0
    },
    month: {
      mountingMoney: {
        plan: 0,
        fact: 0,
      },
      reclamation: {
        plan: 0,
        fact: 0,
        planDetails: []
      },
      prognosis: {
        plan: 0,
        fact: 0,
      },
      balance: {
        cash: 0,
        card: 0,
        bank: 0,
        details: {
          cash: [],
          card: [],
          bank: []
        }
      },
      rentability: {
        count: 0,
        percent: 0
      },
      losses: {
        present: 0,
        previous: 0
      },
      debtors: 0
    }
  };
  innerWidth: number;
  planDetails: FinancePointInterface['reclamation']['planDetails'] = [];
  selectedPlan = false;

  constructor(public userService: UserService,
              private readonly financeService: FinanceService,
              private readonly taskService: TaskService) {
  }

  @Input()
  set dayEvent(event: EventEmitter<any>) {
    event.subscribe(day => {
      this.day = day;
      this.options.date = this.day.format('YYYY-MM-DD');
      this.load();
    });
  }

  @Input()
  set periodEvent(event: EventEmitter<any>) {
    event.subscribe(period => {
      this.period = period;
    });
  }

  get allWidgets(): string[] {
    return Object.keys(this.widgets);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {

    this.innerWidth = window.innerWidth;

    this.load();
  }

  load() {
    this.financeService.find<any>(this.options).subscribe((result: any) => {
      this.data = result;
      this.loaded = true;
    });

    this.taskService.widget(this.options.date).subscribe(widget => {
      this.brs = widget;
    });

  }

  selectWidget(widgetName) {
    if (this.selectedWidgets.includes(widgetName)) {
      const widgetIndex = this.selectedWidgets.indexOf(widgetName);
      this.selectedWidgets.splice(widgetIndex, 1);
    } else {
      this.selectedWidgets.push(widgetName);
    }
    localStorage.setItem('e2b.dashboard.director.finance', JSON.stringify(this.selectedWidgets));
  }

  public getQueryFrom() {
    switch (this.period) {
      case 'day':
        return this.day.clone().startOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().startOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().startOf('month').format('YYYY-MM-DD');
    }
  }

  public selectPlan() {
    if (this.selectedPlan) {
      this.selectedPlan = false;
      this.planDetails = [];
    } else {
      this.selectedPlan = true;
      this.planDetails = this.data[this.period].reclamation.planDetails;
    }
  }

  public getQueryTo() {
    switch (this.period) {
      case 'day':
        return this.day.clone().endOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().endOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().endOf('month').format('YYYY-MM-DD');
    }
  }
}
