<form class="ui reply form mb-3" (ngSubmit)="addComment()">
  <div class="field">
    <e2b-comment-textarea [entityId]="entityId" [commentType]="commentType" [clearEvent]="clearTextEvent"
                          [textInput]="textInputEvent"
                          [userAddEvent]="userAddEvent" [userEmitEvent]="userChangeEvent" [pasteEvent]="pasteEvent"
                          [textEmitEvent]="textChangeEvent" [toWhatsApp]="toWhatsApp"></e2b-comment-textarea>
  </div>
  <div class="d-flex" style="padding: 0 10px; margin-bottom: 10px" *ngIf="comment.files.length">
    <div *ngFor="let file of comment.files" class="d-flex comment-file">
      <e2b-file [file]="file"></e2b-file>
      <i class='ui delete red cursor icon' *ngIf="!commentUploading" (click)="removeCommentFile(file)"></i>
    </div>
  </div>

  <div class="whatsapp-info" *ngIf="commentFilter && canWriteWhatsApp">
    <sui-checkbox class="slider" [(ngModel)]="toWhatsApp" name="whatsappSlider">
      <i [ngClass]="'whatsapp large' + (toWhatsApp ? (mainClientContact ? ' green ' : ' red ') : ' ') + 'icon'"
         style="margin-top: -4px"></i>
      <span [ngClass]="toWhatsApp ? (mainClientContact ? 'color-green' : 'color-red') : ''">WhatsApp</span>
    </sui-checkbox>
    <div *ngIf="toWhatsApp" class="ml-10px">
      <i class="long arrow alternate right large icon"></i>
      <span *ngIf="mainClientContact && mainClientContact.phone">{{mainClientContact.phone | phone}}</span>
      <span *ngIf="mainClientContact && !mainClientContact.phone"
            class="color-red">Не указан номер у основного контакта</span>
      <span *ngIf="!mainClientContact" class="color-red">Не задан основной контакт клиента</span>
    </div>
  </div>

  <button type="submit" [ngClass]="'ui green labeled submit icon' + (commentUploading ? ' loading ' : ' ') + 'button'">
    <i class="paper plane icon"></i> Написать
  </button>
  <div class="ui input">
    <input type="file" id="commentFile" name="file" #fileCommentForm multiple="multiple"
           (change)="changeFiles(fileCommentForm.files)">
    <label for="commentFile">
      <span *ngIf="fileCommentForm.files.length === 0">Прикрепить файл(ы)</span>
      <span *ngIf="fileCommentForm.files.length === 1">{{fileCommentForm.files[0].name}}</span>
      <span *ngIf="fileCommentForm.files.length > 1">{{fileCommentForm.files.length}} файла(ов)</span>
    </label>
  </div>
  <button (click)="clearCommentForm()" class="ui right floated red labeled submit icon button">
    <i class="icon delete"></i> Очистить
  </button>
</form>

<div class="ui secondary pointing menu mb-3" *ngIf="commentFilter && canReadWhatsApp">
  <a class="item" [class.active]="commentFilter === CommentFilter.all" (click)="changeCommentFilter(CommentFilter.all)">
    <i class="list ul large icon"></i>
    Все
  </a>
  <a class="item" [class.active]="commentFilter === CommentFilter.comments"
     (click)="changeCommentFilter(CommentFilter.comments)">
    <i class="comments outline large icon"></i>
    Комментарии + ЛК
  </a>
  <a class="item" [class.active]="commentFilter === CommentFilter.whatsapp"
     (click)="changeCommentFilter(CommentFilter.whatsapp)">
    <i class="whatsapp large icon"></i>
    WhatsApp
  </a>
  <a class="item" [class.active]="commentFilter === CommentFilter.sms" (click)="changeCommentFilter(CommentFilter.sms)">
    <i class="mobile alternate large icon"></i>
    СМС
  </a>
</div>

<div class="ui threaded comments">
  <ng-container *ngFor="let comment of comments | filterComment:commentFilter | orderByColumn:'-created:date'">
    <div [@commentState]="comment.state" class="comment-wrapper">
      <div class="comment" *ngIf="!comment.deleted">
        <a class="avatar">
          <img class="ui circular mini image" *ngIf="comment.user"
               [src]="(comment.user.avatar.path + '/' + comment.user.avatar.name) | secure">
          <img class="ui circular mini image" *ngIf="comment.client"
               [src]="'3C82BF62-82ED-4AEA-A34C-78FD076F23FD/default.png' | secure">
        </a>
        <div class="content">
          <a
            class="author">{{comment.user ? comment.user.name : (comment.client ? comment.client.name : 'Неизвестный пользователь')}}</a>
          <i *ngIf="comment.whatsapp" class="whatsapp alternate green large icon" style="margin: -2px 0 0 5px;"></i>
          <span *ngIf="comment.sms"><i class="mobile alternate icon" style="margin: -2px 0 0 5px;"></i>смс</span>

          <div class="date" *ngIf="comment.created">
            {{comment.created.length == 16 ? comment.created : (comment.created | date:'medium')}}
            <e2b-whatsapp-status *ngIf="comment.status && comment.status !== WhatsAppStatus.INCOMING"
                                 [status]="comment.status"></e2b-whatsapp-status>
            <e2b-sms-status *ngIf="comment.sms && comment.sms.status" [status]="comment.sms.status"></e2b-sms-status>
          </div>
          <div class="text" *ngIf="!currentClient && comment.responsible && comment.responsible.length > 0">
            <a [routerLink]="['/staff/card/', responsible.id]" class="mini cursor"
               *ngFor="let responsible of comment.responsible; last as isLast">@{{ responsible.name }}{{isLast ? '' : ', '}}</a>
          </div>
          <div style="padding: 10px 0">
            <div class="ql-container ql-snow" style="border-width: 0;">
              <div [innerHTML]="comment.text | safeHtml" class="ql-editor ql-comment"></div>
            </div>
          </div>
          <div class="actions" *ngIf="!comment.sms">
            <ng-container *ngIf="comment.client">
              <a class="cursor" *ngIf="comment.client.id === (currentClient && currentClient.id)"
                 (click)="deleteComment(comment)">Удалить</a>
              <a class="cursor"
                 *ngIf="currentUser || (comment.client.id !== (currentClient && currentClient.id)) || comment.whatsapp && comment.client.id"
                 (click)="reply(comment)">
                Ответить
              </a>
            </ng-container>
            <ng-container *ngIf="comment.user && !comment.whatsapp">
              <a class="cursor" *ngIf="comment.user.id === (currentUser && currentUser.id)"
                 (click)="deleteComment(comment)">Удалить</a>
              <a class="cursor" *ngIf="currentClient || (comment.user.id !== (currentUser && currentUser.id))"
                 (click)="reply(comment)">Ответить</a>
            </ng-container>
          </div>
          <div class="d-flex flex-wrap">
            <div class="attach" *ngFor="let file of comment.files">
              <e2b-file *ngIf="file" [file]="file"></e2b-file>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui divider"></div>
  </ng-container>
</div>
