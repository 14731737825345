import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Person } from '../../../_models/person.model';

@Component({
  selector: 'e2b-person-view',
  templateUrl: './person-view.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/header.min.css',
    '../../../../assets/design/semantic-ui/components/image.min.css',
    '../../../../assets/design/semantic-ui/components/card.min.css',
    '../../../../assets/design/semantic-ui/components/icon.min.css',
    '../../../../assets/design/semantic-ui/components/button.min.css',
    '../../../../assets/design/semantic-ui/components/divider.min.css',
    './person-view.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class PersonViewComponent implements OnInit {

  @Input()
  persons: Person[] = [];
  @Input()
  clientId: number;

  constructor() {
  }

  ngOnInit() {

  }

}
