<div class="ui replyTextareaForm form">
  <div class="field" draggable="false">
    <div id="commentReplyTextArea" class="textarea" propValueAccessor="innerHTML" [(ngModel)]="model" contenteditable="true"></div>
    <div class="mt-3">
      <button class="ui green submit icon button" (click)="save()">
        <i class="ui paper plane icon" style="color: white"></i>
        Отправить
      </button>
    </div>
  </div>
</div>
