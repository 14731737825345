import * as moment from 'moment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Deal } from '../../../_models/deal.model';
import { DealService } from '../../../_services/deal.service';
import { ErrorService } from '../../../error/error.service';
import { Order } from '../../../_models/order.model';
import { OrderDealPosition } from '../../../_models/order-deal-position.model';
import { OrderModeService } from '../order-mode.service';
import { OrderPositionService } from '../../../_services/order-position.service';
import { OrderService } from '../../../_services/order.service';
import { OrderStatus } from '../../../_models/enums/order-status.enum';
import { OrderType } from '../../../_models/enums/order-type.enum';
import { User } from '../../../_models/user.model';

@Component({
  selector: 'e2b-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.less']
})
export class OrderCardComponent implements OnInit, OnDestroy {
  addPositionsMode = false;
  canEdit = false;
  currentDate = new Date();
  currentPosition: OrderDealPosition;
  deal: Deal;
  loaded = false;
  order: Order;
  positionsType: number;
  responsible: User[] = [];
  restPositions: any[] = [];

  calculatedCoefficient = {
    id: false,
    data: {
      use: 0, all: 0, mountUse: 0, mountAll: 0
    }
  };

  constructor(private dealService: DealService,
              private errorService: ErrorService,
              private orderService: OrderService,
              private orderPositionService: OrderPositionService,
              public orderModeService: OrderModeService) {
  }

  ngOnInit() {
    this.orderService.findById<Order>(this.orderModeService.currentOrderId).subscribe(order => {
      this.order = order;
      this.order.date = this.order.date ? new Date(this.order.date) : undefined;
      this.deal = this.orderModeService.getDeal();
      this.canEdit = this.order.status === OrderStatus.NEW;
      this.calcRestPositions();
      this.loadResponsible();

      if (this.order.type === OrderType.PRODUCTION) {
        this.positionsType = this.order.orderDealPositions[0].dealPosition.position.type;
      }

      if (this.order.orderDealPositions.length === 1) {
        this.currentPosition = this.order.orderDealPositions[0];
      } else if (this.orderModeService.openPositionId) {
        const openPosition = this.order.orderDealPositions.find(orderPosition => orderPosition.dealPosition.id === this.orderModeService.openPositionId);
        if (openPosition) {
          this.currentPosition = openPosition;
        }
      }

      this.loaded = true;
    });
  }

  ngOnDestroy() {
    if (this.orderModeService.isCardMode) {
      this.orderModeService.clearMode();
    }
  }

  get OrderStatus() {
    return OrderStatus;
  }

  get OrderType() {
    return OrderType;
  }

  addPosition(position: any) {
    position.order = <Order>{ id: this.order.id };
    this.orderPositionService.create<OrderDealPosition>(position).subscribe(orderPosition => {
      this.order.orderDealPositions.push(orderPosition);
      this.updatePositions();
      this.updateOrderCost();
    }, error => this.errorService.pushError(error, 'Ошибка добавления позиции'));
  }

  calcPositionCost() {
    this.orderPositionService.calcCost(this.currentPosition.id).subscribe(cost => {
      this.currentPosition.orderDealPositionValues.map(positionValue => {
        if (positionValue.orderField.name === 'Себестоимость') {
          positionValue.value = cost;
        }
      });
      this.updateOrderCost();
    }, error => this.errorService.pushError(error, `Ошибка расчета себестоимости позиции "${this.currentPosition.dealPosition.position.name}"`));
  }

  calcRestPositions() {
    this.restPositions = this.deal.positions
      .filter(position => !this.order.orderDealPositions.find(orderDealPosition => orderDealPosition.dealPosition.id === position.id));
  }

  deletePosition(orderPosition: OrderDealPosition) {
    const yes = confirm(`Вы уверены, что хотите удалить позицию "${orderPosition.dealPosition.position.name}"?`);
    if (!yes) {
      return true;
    }

    this.orderPositionService.deleteById(orderPosition.id).subscribe(() => {
      this.order.orderDealPositions.splice(this.order.orderDealPositions.indexOf(orderPosition), 1);
      this.updatePositions();
      this.updateOrderCost();
    }, error => this.errorService.pushError(error, 'Ошибка удаления позиции'));
  }

  deleteOrder() {
    const yes = confirm(`Вы уверены, что хотите удалить заказ №${this.order.id}?`);
    if (!yes) {
      return true;
    }

    this.orderService.deleteById(this.order.id).subscribe(() => {
      this.orderModeService.setListMode();
    }, error => this.errorService.pushError(error, 'Ошибка удаления заказа'));
  }

  loadResponsible() {
    const queryParams: any = { type: this.order.type };
    if (this.order.date) {
      queryParams.date = moment(this.order.date).format('YYYY.MM.DD');
    }
    this.orderService.responsible(queryParams).subscribe((responsible: User[]) => {
      this.responsible = responsible;
      const currentResponsible = this.responsible.find(user => user.id === (this.order.responsible && this.order.responsible.id));
      if (!currentResponsible && this.order.responsible) {
        this.responsible.push(this.order.responsible);
      } else {
        this.order.responsible = currentResponsible;
      }
    });
  }

  saveCost(cost: number) {
    this.orderService.update<Order>(<Order>{ id: this.order.id, cost }).subscribe(() => {
    }, error => this.errorService.pushError(error, 'Ошибка изменения себестоимости'));
  }

  saveDate(date: Date) {
    this.orderService.update<Order>(<Order>{ id: this.order.id, date }).subscribe(() => {
      this.loadResponsible();
      if (this.order.type === OrderType.MOUNTING || this.order.type === OrderType.PRODUCTION) {
        this.calculatedCoefficient.id = false;
        this.dealService.coefficient(moment(date).format('DD.MM.YYYY')).subscribe(c => {
          this.calculatedCoefficient.id = true;
          this.calculatedCoefficient.data = c;
        });
      }
    }, error => this.errorService.pushError(error, 'Ошибка изменения даты исплонения'));
  }

  saveResponsible(responsible: User) {
    this.orderService.update<Order>(<Order>{ id: this.order.id, responsible }).subscribe(() => {
      if (this.order.type === OrderType.PRODUCTION) {
        this.updateOrderCost();
      }
    }, error => this.errorService.pushError(error, 'Ошибка изменения ответственного'));
  }

  sendToProduction() {
    this.orderService.update(<Order>{ id: this.order.id, status: OrderStatus.SENT }).subscribe(() => {
      this.order.status = OrderStatus.SENT;
    }, error => this.errorService.pushError(error, 'Ошибка при отправке в производство'));
  }

  setOrderListMode() {
    this.orderModeService.setListMode();
  }

  showPosition(orderPosition: OrderDealPosition) {
    this.currentPosition = orderPosition;
  }

  updatePositions() {
    const currentOrder = this.orderModeService.orders.find(order => order.id === this.order.id);
    if (currentOrder) {
      currentOrder.orderDealPositions = this.order.orderDealPositions;
      this.calcRestPositions();
    }
  }

  updateOrderCost() {
    this.orderService.findById<Order>(this.order.id).subscribe(order => {
      this.order.cost = order.cost;
    });
  }
}
