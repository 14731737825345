import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import '../assets/design/semantic-ui/components/api.js';
import '../assets/design/semantic-ui/components/transition.js';
import { RouterEventService } from './_services/router-event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {

  constructor(private routerEventService: RouterEventService,
              private router: Router) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.routerEventService.onRouterEvent.emit(event);
    });
  }

  ngOnInit() {
  }

}
