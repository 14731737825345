import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'angular-calendar';
import { CalendarWeekHoursViewModule } from 'angular-calendar-week-hours-view';
import { SuiModule } from 'ng2-semantic-ui';
import { LoggedInUserGuard } from '../_guards/logged-in-user.guard';
import { PermissionGuard } from '../_guards/permission.guard';
import { SharedModule } from '../shared/shared.module';
import { TodoCalendarComponent } from './todo-calendar/todo-calendar.component';
import { TodoComponent } from './todo.component';

const routes = [
  {
    path: '',
    redirectTo: 'todo-calendar',
    pathMatch: 'full'
  },
  {
    path: 'todo-calendar',
    component: TodoCalendarComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['todo', '/'],
      title: 'Olissys E2B | Календарь дел',
      breadcrumb: 'Календарь дел'
    }
  }
];

@NgModule({
  imports: [
    CommonModule,
    SuiModule,
    FormsModule,
    SharedModule,
    RouterModule.forChild(routes),
    CalendarModule.forRoot(),
    CalendarWeekHoursViewModule
  ],
  declarations: [
    TodoComponent,
    TodoCalendarComponent
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU'
    }
  ]
})
export class TodoModule {
}
