import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mime'
})
export class FileTypePipe implements PipeTransform {
  transform(type: any): any {
    if (!type) {
      return '';
    }
    let mime = 'file outline';

    const classes = {
      'excel': 'file excel green outline',
      'text': 'file text olive outline',
      'image': 'file image red outline',
      'audio': 'file audio blue outline',
      'video': 'file video blue outline',
      'pdf': 'file pdf red outline',
      'word': 'file word green outline',
      'powerpoint': 'file powerpoint green outline',
      'zip': 'file archive purple outline',
      'rar': 'file archive purple outline',
      'gzip': 'file archive purple outline'
    };
    const reg = Object.keys(classes);

    reg.forEach(function (key) {
      if (type.indexOf(key) !== -1) {
        mime = classes[key];
      }
    });
    return mime;
  }
}
