import { BaseModel } from './base.model';
import { Client } from './client.model';
import { Deal } from './deal.model';
import { WhatsAppStatus } from './enums/whatsapp-status.enum';
import { Project } from './project.model';
import { Task } from './task.model';
import { User } from './user.model';

export class Comment extends BaseModel {
  created: Date;
  text: string;
  deleted: boolean;
  files: any[];
  user: User;
  deal?: Deal;
  task?: Task;
  project?: Project;
  client: Client;
  responsible: User[];
  whatsapp?: boolean;
  status?: WhatsAppStatus;
  state?: string;
}
