import { BaseModel } from './base.model';
import { Task } from './task.model';
import { User } from './user.model';

export class TaskComment extends BaseModel {
  created: Date;
  text: string;
  deleted: boolean;
  files: any[];
  responsible: User[];
  user: User;
  task: Task;
}
