export enum WhatsAppStatus {
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  READ = 'READ',
  NOT_INSTALLED = 'NOT_INSTALLED',
  TOO_LONG_MESSAGE = 'TOO_LONG_MESSAGE',
  TOO_BIG_FILE = 'TOO_BIG_FILE',
  SPAM = 'SPAM',
  EXCEEDED_LIMIT = 'EXCEEDED_LIMIT',
  ERROR = 'ERROR',
  INCOMING = 'INCOMING',
}
