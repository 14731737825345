import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SuiModule } from 'ng2-semantic-ui';
import { LoggedInUserGuard } from '../_guards/logged-in-user.guard';
import { PermissionGuard } from '../_guards/permission.guard';
import { SharedModule } from '../shared/shared.module';
import { ClientAddComponent } from './client-add/client-add.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientFilterPipe } from './client-list/client-list.pipe';
import { ClientShowComponent } from './client-show/client-show.component';
import { ContactsAddComponent } from './contacts/contacts-add/contacts-add.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ContractorsAddComponent } from './contractors/contractors-add/contractors-add.component';
import { ContractorsShowComponent } from './contractors/contractors-show/contractors-show.component';
import { ContractorsComponent } from './contractors/contractors.component';
import { MyCompanyAddComponent } from './my-company/my-company-add/my-company-add.component';
import { MyCompanyShowComponent } from './my-company/my-company-show/my-company-show.component';
import { MyCompanyComponent } from './my-company/my-company.component';
import { PersonAddComponent } from './person/person-add/person-add.component';
import { PersonEditComponent } from './person/person-edit/person-edit.component';
import { PersonShowComponent } from './person/person-show/person-show.component';
import { PersonComponent } from './person/person.component';

const routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  },
  {
    path: 'list',
    component: ClientListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['client', '/'],
      title: 'Olissys E2B | Список клиентов',
      breadcrumb: 'Список клиентов'
    }
  },
  {
    path: 'contractor',
    component: ContractorsComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['contractor', '/'],
      title: 'Olissys E2B | Подрядчики',
      breadcrumb: 'Подрядчики'
    }
  },
  {
    path: 'contractor/card/:id',
    component: ContractorsShowComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['contractor', '/'],
      title: 'Olissys E2B | Карточка подрядчика',
      breadcrumb: 'Карточка подрядчика'
    }
  },
  {
    path: 'contractor/add',
    component: ContractorsAddComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['contractor', '/'],
      title: 'Olissys E2B | Новый подрядчик',
      breadcrumb: 'Новый подрядчик'
    }
  },
  {
    path: 'contact',
    component: ContactsComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['contact', '/'],
      title: 'Olissys E2B | Список контактов',
      breadcrumb: 'Список контактов'
    }
  },
  {
    path: 'contact/add',
    component: ContactsAddComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['contact', '/'],
      title: 'Olissys E2B | Новый контакт',
      breadcrumb: 'Новый контакт'
    }
  },
  {
    path: 'person',
    component: PersonComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['person', '/'],
      title: 'Olissys E2B | Плательщики',
      breadcrumb: 'Плательщики'
    }
  },
  {
    path: 'person/add',
    component: PersonAddComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['person', '/'],
      title: 'Olissys E2B | Новый плательщик',
      breadcrumb: 'Новый плательщик'
    }
  },
  {
    path: 'person/edit/:id',
    component: PersonEditComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['person', '/'],
      title: 'Olissys E2B | Редактирование плательщика',
      breadcrumb: 'Редактирование плательщика'
    }
  },
  {
    path: 'person/card/:id',
    component: PersonShowComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['person', '/'],
      title: 'Olissys E2B | Карточка плательщика',
      breadcrumb: 'Карточка плательщика'
    }
  },
  {
    path: 'my-company',
    component: MyCompanyComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['own', '/'],
      title: 'Olissys E2B | Моя компания',
      breadcrumb: 'Моя компания'
    }
  },
  {
    path: 'my-company/card/:id',
    component: MyCompanyShowComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['own', '/'],
      title: 'Olissys E2B | Карточка организации',
      breadcrumb: 'Карточка компании'
    }
  },
  {
    path: 'my-company/add',
    component: MyCompanyAddComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['own', '/'],
      title: 'Olissys E2B | Новая организация',
      breadcrumb: 'Новая организация'
    }
  },
  {
    path: 'add',
    component: ClientAddComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['client', '/'],
      title: 'Olissys E2B | Добавить клиента',
      breadcrumb: 'Добавить клиента'
    }
  },
  {
    path: 'card/:id',
    component: ClientShowComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['client', '/'],
      title: 'Olissys E2B | Карточка клиента',
      breadcrumb: 'Карточка клиента'
    }
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SuiModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    ClientFilterPipe,
    ContractorsShowComponent,
    ContractorsAddComponent,
    ContactsAddComponent,
    ClientShowComponent,
    ClientListComponent,
    ClientAddComponent,
    ContractorsComponent,
    ContactsComponent,
    PersonComponent,
    PersonEditComponent,
    PersonShowComponent,
    PersonAddComponent,
    MyCompanyComponent,
    MyCompanyAddComponent,
    MyCompanyShowComponent
  ],
  exports: [
    ClientAddComponent
  ]
})
export class ClientModule {
}
