import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable()
export class UserHolidayService extends BaseService {
  baseUrl = '/user-holiday';

  findByUserId(options: { id: number, date: string }): Observable<{
    workdays: number,
    holidays: number,
  }> {
    const params = {};
    if (options) {
      Object.entries({ date: options.date }).map(o => {
        params[o[0]] = o[1];
      });
    }
    return this.http.get<{
      workdays: number,
      holidays: number,
    }>(
      this.baseUrl + '/' + options.id,
      {
        params,
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }

}
