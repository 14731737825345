import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { PositionClothPrice } from '../../_models/position-cloth-price.model';
import { PositionCloth } from '../../_models/position-cloth.model';
import { Position } from '../../_models/position.model';
import { User } from '../../_models/user.model';
import { PositionClothPriceService } from '../../_services/position-cloth-price.service';
import { PositionClothService } from '../../_services/position-cloth.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-cloth-list',
  templateUrl: './cloth-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class ClothListComponent implements OnInit {

  currentUser: User;
  clothes: PositionCloth[] = [];
  positions: Position[] = [];
  loaded = false;
  users: User[] = [];
  selectUser: EventEmitter<User> = new EventEmitter<User>();
  currentMaterial: PositionCloth | boolean = false;

  constructor(private actionService: ActionService,
              private userService: UserService,
              private clothService: PositionClothService,
              private clothPriceService: PositionClothPriceService) {
  }

  ngOnInit() {

    const addEvent = new EventEmitter();

    if (this.userService.checkPermissions(['position-cloth', '/'], ['write'])) {
      this.actionService.createActionEvent.emit({
        name: 'Добавить',
        event: addEvent,
        class: 'blue'
      });
    }

    const updateEvent = new EventEmitter();

    this.actionService.createActionEvent.emit({
      name: 'Обновить',
      event: updateEvent,
      class: 'blue',
      icon: 'sync'
    });

    updateEvent.subscribe(() => {
      this.load();
    });

    this.selectUser.subscribe(user => {
      this.currentUser = user;
      this.load();
    });

    addEvent.subscribe(() => {
      this.add();
    });
    this.load();
  }

  load() {
    this.loaded = false;
    this.clothes = [];

    if (this.users.length === 0) {
      this.userService.softProduction<User>().subscribe(users => {
        this.users = users;
      });
    }

    if (!this.currentUser) {
      this.loaded = true;
      return false;
    }

    this.clothService.find<PositionCloth>({ user: this.currentUser.id }).subscribe(clothes => {
      this.clothService.positions().subscribe(positions => {
        this.positions = positions;
        clothes.map(c => {
          if (!c.prices || c.prices.length === 0) {
            c.prices = [];
            positions.map(p => {
              const price = new PositionClothPrice();
              price.price = 0;
              price.cost = 0;
              price.position = p;
              price.user = <User>{ id: this.currentUser.id };
              c.prices.push(price);
            });
          } else {
            const ids = [];
            c.prices.map(cp => {
              ids.push(cp.position.id);
            });
            positions.map(p => {
              if (ids.indexOf(p.id) === -1) {
                const price = new PositionClothPrice();
                price.price = 0;
                price.cost = 0;
                price.position = p;
                price.user = <User>{ id: this.currentUser.id };
                c.prices.push(price);
              }
            });
          }
          this.clothes.push(c);
        });
        this.loaded = true;
      });
    });
  }

  save(positionCloth: PositionCloth) {
    if (!positionCloth.name) {
      return false;
    }
    if (positionCloth.id) {
      this.clothService.update(positionCloth).subscribe(() => {
      }, e => {
        console.error(e);
      });
    } else {
      this.clothService.create<PositionCloth>(positionCloth).subscribe(created => {
        this.clothes[this.clothes.indexOf(positionCloth)] = created;
      }, e => {
        console.error(e);
      });
    }
  }

  savePrice(price: PositionClothPrice, cloth: PositionCloth) {
    price.cloth = <PositionCloth>{ id: cloth.id };
    if (!price.id) {
      this.clothPriceService.create<PositionClothPrice>(price).subscribe(created => {
        price.id = created.id;
      });
    } else {
      this.clothPriceService.update(price).subscribe(() => {
      });
    }
  }

  add() {
    const m = new PositionCloth();
    m.prices = [];
    this.positions.map(p => {
      const price = new PositionClothPrice();
      price.price = 0;
      price.cost = 0;
      price.position = p;
      m.prices.push(price);
    });
    this.clothes.unshift(m);
  }

  remove(cloth) {
    if (!cloth.id) {
      this.clothes.splice(this.clothes.indexOf(cloth), 1);
      return false;
    }
    const yes = confirm('Вы уверены, что хотите удалить ткань ' + cloth.name + '?');
    if (!yes) {
      return true;
    }
    this.clothService.deleteById(cloth.id).subscribe(() => {
      this.clothes.splice(this.clothes.indexOf(cloth), 1);
    });
  }
}
