import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable()
export class DealFieldService extends BaseService {
  baseUrl = '/deal-field';

  list<T>(options?: { model?: string[] }): Observable<[T]> {
    const params = this.getParams(options);
    return this.http.get<[T]>(
      this.baseUrl + '/list',
      {
        params,
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }

}
