import { Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { Deal } from '../../_models/deal.model';

@Component({
  selector: 'e2b-deal-dropdown',
  templateUrl: './deal.dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class DealDropdownComponent {
  @Input()
  public deals: Deal[];

  @Input()
  public event: EventEmitter<Deal>;

  @Input()
  public error: boolean;

  public selectedDeal: Deal;

  save() {
    this.event.emit(this.selectedDeal);
  }
}
