import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Client } from '../../../_models/client.model';
import { PersonType } from '../../../_models/person-type.model';
import { Person } from '../../../_models/person.model';
import { PersonService } from '../../../_services/person.service';
import { ActionService } from '../../../action/action.service';

@Component({
  selector: 'e2b-person-show',
  templateUrl: './person-show.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../../assets/design/semantic-ui/components/button.min.css',
    '../../../../assets/design/semantic-ui/components/loader.min.css',
    './person-show.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PersonShowComponent implements OnInit {
  id: number;
  person: Person = new Person();
  loaded = false;
  private personSubscribe: Subscription;

  constructor(private personService: PersonService,
              private actionService: ActionService,
              private activatedRoute: ActivatedRoute) {

    this.person.client = new Client();
    this.person.type = new PersonType();

    this.id = this.id = parseInt(this.activatedRoute.snapshot.params.id, 0);
  }

  ngOnInit() {
    this.personSubscribe = this.personService.findById<Person>(this.id).subscribe(person => {
      this.person = person;
      this.loaded = true;
    }, console.error);
    this.actionService.createMenuEvent.emit({ name: 'Редактировать', link: '/client/person/edit/' + this.id, class: 'blue active' });
  }

}
