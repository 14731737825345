import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Problem } from '../../_models/problem.model';
import { Reclamation } from '../../_models/reclamation.model';
import { ReclamationService } from '../../_services/reclamation.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';


@Component({
  selector: 'e2b-reclamation-list',
  templateUrl: './reclamation-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReclamationListComponent implements OnInit {

  currentReclamation: Reclamation | boolean = false;
  loaded = false;
  reclamations: Reclamation[] = [];

  constructor(private actionService: ActionService,
              private userService: UserService,
              private reclamationService: ReclamationService) {
  }

  add() {
    const reclamation = new Reclamation();
    this.reclamations.unshift(reclamation);
  }

  addTo(p: Reclamation) {
    const reclamation = new Reclamation();
    reclamation.parent = <Reclamation>{ id: p.id };
    p.childrens.unshift(reclamation);
  }

  load() {
    this.loaded = false;
    this.reclamations = [];

    this.reclamationService.find<Problem>().subscribe(reclamations => {
      this.reclamations = reclamations;
      this.loaded = true;
    });
  }

  ngOnInit() {

    const addEvent = new EventEmitter();

    if (this.userService.checkPermissions(['reclamation', '/'], ['write'])) {
      this.actionService.createActionEvent.emit({
        name: 'Добавить',
        event: addEvent,
        class: 'blue',
      });
    }

    const updateEvent = new EventEmitter();
    this.actionService.createActionEvent.emit({
      name: 'Обновить',
      event: updateEvent,
      class: 'blue',
      icon: 'sync'
    });

    updateEvent.subscribe(() => {
      this.load();
    });

    addEvent.subscribe(() => {
      this.add();
    });
    this.load();
  }

  remove(reclamation, parent?) {

    const yes = confirm('Вы уверены, что хотите удалить рекламацию ' + reclamation.name + '?');

    if (!yes) {
      return true;
    }

    if (!reclamation.id && !parent) {
      this.reclamations.splice(this.reclamations.indexOf(reclamation), 1);
      return false;
    }

    if (!reclamation.id && parent) {
      parent.childrens.splice(parent.childrens.indexOf(reclamation), 1);
      return false;
    }

    this.reclamationService.deleteById(reclamation.id).subscribe(() => {
      if (!parent) {
        this.reclamations.splice(this.reclamations.indexOf(reclamation), 1);
      } else if (parent) {
        parent.childrens.splice(parent.childrens.indexOf(reclamation), 1);
      }
    });
  }

  save(reclamation: Reclamation) {
    if (!reclamation.name) {
      return false;
    }
    if (reclamation.id) {
      this.reclamationService.update(reclamation).subscribe(() => {
      }, e => {
        console.error(e);
      });
    } else {
      this.reclamationService.create<Reclamation>(reclamation).subscribe(created => {
        this.reclamations[this.reclamations.indexOf(reclamation)] = created;
      }, e => {
        console.error(e);
      });
    }
  }
}
