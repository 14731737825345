import { Injectable } from '@angular/core';
import { Deal } from '../../_models/deal.model';
import { OrderType } from '../../_models/enums/order-type.enum';
import { Order } from '../../_models/order.model';
import { OrderService } from '../../_services/order.service';

enum OrderMode {
  list,
  card,
  add,
}

@Injectable()
export class OrderModeService {
  private deal: Deal;
  mode: OrderMode;
  currentOrderId: number;
  openPositionId: number;
  orderTemplate: Order;
  orders: Order[] = [];

  orderDealPositionFields: any;

  loadingOrders = false;

  _orderStyles = {
    [OrderType.DELIVERY]: {
      icon: 'truck',
      color: '#4b93fd',
    },
    [OrderType.MOUNTING]: {
      icon: 'wrench',
      color: '#ff8d00',
    },
    [OrderType.RECLAMATION]: {
      icon: 'exclamation triangle',
      color: '#db2828',
    },
    [OrderType.MEASUREMENT]: {
      icon: 'pencil',
      color: '#e03997',
    },
    [OrderType.PRODUCTION]: {
      icon: 'industry',
      color: '#6435c9',
    },
  };

  constructor(private orderService: OrderService) {
  }

  get isListMode() {
    return this.mode === OrderMode.list;
  }

  get isCardMode() {
    return this.mode === OrderMode.card;
  }

  get isAddMode() {
    return this.mode === OrderMode.add;
  }

  getOrderStyle(orderType: OrderType) {
    return this._orderStyles[orderType];
  }

  clearMode() {
    this.orders = [];
    this.mode = null;
    this.currentOrderId = null;
    this.orderTemplate = null;
    this.openPositionId = null;
  }

  loadOrders() {
    this.loadingOrders = true;
    this.orderService.findForDeal<Order>(this.deal.id).subscribe(orders => {
      this.orders = orders;
      this.loadingOrders = false;
    });
  }

  setDeal(deal: Deal) {
    this.deal = deal;
  }

  getDeal(): Deal {
    return this.deal;
  }

  setListMode() {
    this.clearMode();
    this.loadOrders();
    this.mode = OrderMode.list;
  }

  setCardMode(orderId: number, openPositionId?: number) {
    if (!this.orders.length) {
      this.loadOrders();
    }
    this.currentOrderId = orderId;
    this.openPositionId = openPositionId;
    this.orderTemplate = null;
    this.mode = OrderMode.card;
  }

  setAddMode(orderTemplate?: Order) {
    this.orderTemplate = orderTemplate;
    this.mode = OrderMode.add;
  }
}
