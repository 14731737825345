<div class="ui grid">
  <div class="sixteen wide column">
    <h2>Настройки</h2>
  </div>
  <div class="ui six wide column">
    <h3>Общие настройки</h3>
    <div class="ui form">
      <div class="field">
        <h5>Старший Менеджер:</h5>
        <e2b-staff-dropdown #selectHeadManager [staff]="managers" [event]="headManagerEvent" [selected]="selectedHeadManager">
        </e2b-staff-dropdown>
      </div>
      <div class="field">
        <h5>Старший Монтажник:</h5>
        <e2b-staff-dropdown #selectHeadMounter [staff]="mounters" [event]="headMounterEvent" [selected]="selectedHeadMounter">
        </e2b-staff-dropdown>
      </div>
      <div class="field">
        <h5>Количество монтажных бригад:</h5>
        <div class="ui input">
          <input type="number" [(ngModel)]="brigades.value" (blur)="save()" min="0">
        </div>
      </div>
      <div class="field">
        <h5>Минимальное количество дней от заказа до монтажа:</h5>
        <div class="ui input">
          <input type="number" [(ngModel)]="skipTillMounting.value" (blur)="save()" min="0">
        </div>
      </div>
      <div class="field">
        <h5>Сумма бонуса помощника менеджера:</h5>
        <div class="ui input">
          <input type="number" [(ngModel)]="managerHelperBonus.value" (blur)="save()" min="0">
        </div>
      </div>
      <div class="field">
        <h5>Норма времени на принятие рекламации (часы):</h5>
        <div class="ui input">
          <input type="number" [(ngModel)]="reclamationReceiveHours.value" (blur)="save()" min="0">
        </div>
      </div>
      <div class="field">
        <h5>Норма времени на назначение даты устранения рекламации (часы):</h5>
        <div class="ui input">
          <input type="number" [(ngModel)]="reclamationSetDateHours.value" (blur)="save()" min="0">
        </div>
      </div>
      <div class="field">
        <h5>Норма времени на устранение рекламации (часы):</h5>
        <div class="ui input">
          <input type="number" [(ngModel)]="reclamationFinishHours.value" (blur)="save()" min="0">
        </div>
      </div>
      <div class="field">
        <h5>Процент превышения МД по сделке:</h5>
        <div class="ui input">
          <input type="number" [(ngModel)]="md.value" (blur)="save()" min="0">
        </div>
      </div>
    </div>
  </div>
  <div class="ten wide column">
    <h3>Персональные настройки</h3>
    <h5>Настройки уведомлений:</h5>
    <table class="ui very basic table">
      <thead>
      <tr>
        <th>
          <div class="head">Категория</div>
        </th>
        <th class="center aligned">
          <div class="head">Информер</div>
        </th>
        <th class="center aligned">
          <div class="head">Лента активности</div>
        </th>
        <th class="center aligned">
          <div class="head">iOS push-уведомления</div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let notificationCategory of notificationSettings | orderByColumn:'type:string'">
        <td>{{notificationCategory.type | notificationSettingsType}}</td>
        <td class="center aligned">
          <sui-checkbox [(ngModel)]="notificationCategory.informer" (ngModelChange)="saveNotification(notificationCategory)"></sui-checkbox>
        </td>
        <td class="center aligned">
          <sui-checkbox [(ngModel)]="notificationCategory.enabled" (ngModelChange)="saveNotification(notificationCategory)"></sui-checkbox>
        </td>
        <td class="center aligned">
          <sui-checkbox [(ngModel)]="notificationCategory.apn" (ngModelChange)="saveNotification(notificationCategory)"></sui-checkbox>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
