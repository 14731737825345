import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectCommentService extends BaseService {
  baseUrl = '/project/comment';

  findByProject<T>(id: number, options: { offset: number, limit: number }): Observable<T> {
    const params = {};
    if (options) {
      Object.entries(options).map(o => {
        params[o[0]] = o[1];
      });
    }

    return this.http.get<T>(this.baseUrl + '/' + id, {
      params,
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

}
