import { Observable } from 'rxjs';
import { BaseService } from './base.service';

export class TaskCommentService extends BaseService {
  baseUrl = '/brs/taskComment';

  findForTask<T>(id: number): Observable<T> {
    return this.http.get<T>(this.baseUrl + '/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
