<div class="ui stackable grid">
  <div class="row">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div class="ui twelve wide column">
      <table class="ui selectable very padded table" [hidden]="!loaded">
        <thead>
        <tr>
          <th>
            <div class="head">Имя компании</div>
          </th>
          <th>
            <div class="head">Контакты</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let client of clients">
          <td>
            <p>
              <a [routerLink]="['/client/card', client.id]">{{client.name}}</a>
            </p>
          </td>
          <td>
            <div [hidden]="client.contacts.length === 0" *ngFor="let contact of client.contacts">
              <div *ngIf="contact.name">{{contact.name}}</div>
              <div *ngIf="contact.email">
                <a href="mailto:{{contact.email}}">{{contact.email}}</a>
              </div>
              <div *ngIf="contact.phone">
                <a href="tel:{{contact.phone}}">{{contact.phone}}</a>
              </div>
              <div *ngIf="contact.address">
                <a *ngIf="contact.address" href="yandex:{{contact.address}}">{{contact.address}}</a>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
