import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Comment } from '../../_models/comment.model';
import { Deal } from '../../_models/deal.model';
import { Task } from '../../_models/task.model';
import { ProjectComment } from '../../_models/project-comment.model';
import { Project } from '../../_models/project.model';
import { TaskComment } from '../../_models/task-comment.model';

@Component({
  selector: 'e2b-comment-reply-textarea',
  templateUrl: './comment.reply.textarea.component.html',
  styleUrls: ['./comment.reply.textarea.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CommentReplyTextareaComponent implements OnInit {

  model = '';
  @Input()
  notificationToReply: any;
  @Input()
  saveEvent: EventEmitter<Comment>;

  constructor() {
  }

  ngOnInit() {
  }

  clearForm() {
    this.model = '';
  }

  save() {
    let comment;

    if (this.notificationToReply.deal) {
      comment = new Comment();
      comment.deal = <Deal>{ id: this.notificationToReply.deal.id };
    }
    if (this.notificationToReply.project) {
      comment = new ProjectComment();
      comment.project = <Project>{ id: this.notificationToReply.project.id };
    }
    if (this.notificationToReply.task) {
      comment = new TaskComment();
      comment.task = <Task>{ id: this.notificationToReply.task.id };
    }

    if (!comment) {
      return;
    }

    comment.text = this.model;
    comment.responsible = [];
    comment.responsible.push(this.notificationToReply.user || this.notificationToReply.client);
    this.saveEvent.emit(comment);
    this.clearForm();
  }
}
