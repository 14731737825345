import { keyframes } from '@angular/animations';
import { animate } from '@angular/animations';
import { transition } from '@angular/animations';
import { style } from '@angular/animations';
import { trigger } from '@angular/animations';
import { OnInit } from '@angular/core';
import { Output } from '@angular/core';
import { Component, EventEmitter, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { ClientContact } from '../_models/client-contact.model';
import { Client } from '../_models/client.model';
import { Comment } from '../_models/comment.model';
import { CommentFilter } from '../_models/enums/comment-filter.enum';
import { CommentType } from '../_models/enums/comment-type.enum';
import { WhatsAppStatus } from '../_models/enums/whatsapp-status.enum';
import { User } from '../_models/user.model';
import { CommentService } from '../_services/comment.service';
import { FileInterface, FileService } from '../_services/file.service';
import { ProjectCommentService } from '../_services/project-comment.service';
import { TaskCommentService } from '../_services/task-comment.service';
import { UserService } from '../_services/user.service';
import { ErrorService } from '../error/error.service';
import { LkClientService } from '../lk/_services/lk-client.service';
import { LkOrderCommentService } from '../lk/_services/lk-order-comment.service';

@Component({
  selector: 'e2b-comment',
  templateUrl: './comment.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/header.min.css',
    '../../assets/design/semantic-ui/components/image.min.css',
    '../../assets/design/semantic-ui/components/icon.min.css',
    '../../assets/design/semantic-ui/components/form.min.css',
    '../../assets/design/semantic-ui/components/button.min.css',
    '../../assets/design/semantic-ui/components/input.min.css',
    '../../assets/design/semantic-ui/components/divider.min.css',
    '../../assets/design/semantic-ui/components/comment.min.css',
    './comment.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('commentState', [
      transition('* => highlighted', [
        style({ backgroundColor: '#eeffe4' }),
        animate('2s 2s', keyframes([
          style({ backgroundColor: 'transparent' }),
        ]))
      ]),
    ])
  ]
})

export class CommentComponent implements OnInit {
  commentUploading = false;
  url: string = environment.apiUrl;
  clientId: number;
  entityId: number;
  commentType: CommentType;
  commentFilter: CommentFilter;
  @Input()
  comments: Comment[];
  @Input()
  mainClientContact: ClientContact;
  @Output()
  addCommentEvent = new EventEmitter<any>();
  @Output()
  setCommentFilterEvent = new EventEmitter<CommentFilter>();
  @ViewChild('fileCommentForm', { static: false })
  fileCommentForm;
  comment: Comment = new Comment();
  clearText: string;
  toWhatsApp = false;
  files: FileList;
  currentUser: User;
  currentClient: Client;
  readme: string;
  textChangeEvent = new EventEmitter<string>();
  userChangeEvent = new EventEmitter<any>();
  clearTextEvent = new EventEmitter<any>();
  userAddEvent = new EventEmitter<any>();
  textInputEvent = new EventEmitter<any>();
  pasteEvent = new EventEmitter<any>();

  commentServiceName: string;

  canReadWhatsApp = false;
  canWriteWhatsApp = false;

  constructor(private dealCommentService: CommentService,
              private sanitizer: DomSanitizer,
              private errorService: ErrorService,
              private fileService: FileService,
              private lkClientService: LkClientService,
              private lkOrderCommentService: LkOrderCommentService,
              private projectCommentService: ProjectCommentService,
              private taskCommentService: TaskCommentService,
              private userService: UserService) {
  }

  @Input()
  set setCommentFilter(commentFilter: CommentFilter) {
    this.commentFilter = commentFilter;
    this.toWhatsApp = this.commentFilter === CommentFilter.whatsapp;
  }

  @Input()
  set setEntity(entity: { id: number, commentType: CommentType }) {
    this.entityId = entity.id;
    this.commentType = entity.commentType;
    this.commentServiceName = this.commentType + 'CommentService';
    this.loadCommentForm(this.clientId, this.entityId);
  }

  ngOnInit(): void {
    this.textChangeEvent.subscribe(data => {
      this.comment.text = data.html;
      if (data.text) {
        this.clearText = data.text.slice(0, -1);
      }
    });

    this.userChangeEvent.subscribe(users => {
      this.comment.responsible = users;
    });

    this.pasteEvent.subscribe(files => {
      const currentDate = moment();
      Object.defineProperty(files[0], 'name', {
        writable: true,
        value: 'img_' + currentDate.format('DD.MM_HH:mm:ss' + '.png')
      });

      this.fileService.upload(files).then((f: FileInterface[]) => {
        this.comment.files = this.comment.files.concat(f);
      });
    });

    this.canReadWhatsApp = this.userService.checkPermissions(['deal', 'whatsapp', 'message', '/']);
    this.canWriteWhatsApp = this.userService.checkPermissions(['deal', 'whatsapp', 'message', '/'], ['write']);
  }

  get CommentFilter() {
    return CommentFilter;
  }

  get CommentType() {
    return CommentType;
  }

  get WhatsAppStatus() {
    return WhatsAppStatus;
  }

  loadCommentForm(clientId, entityId) {
    this.comment = new Comment();
    this.comment.files = [];

    this.comment.client = clientId;
    this.comment.responsible = [];
    if (this.commentType === CommentType.lkOrder) {
      this.currentClient = this.lkClientService.current();
      this.comment.client = <Client>{ id: this.currentClient.id };
      this.comment.deal = entityId;
    } else {
      this.currentUser = this.userService.current();
      this.comment.user = <User>{ id: this.currentUser.id };
      this.comment[this.commentType] = entityId;
    }
  }

  addComment() {
    if (!this.comment.text || this.commentUploading) {
      return false;
    }

    if (this.toWhatsApp && (!this.mainClientContact || !this.clearText)) {
      return false;
    }

    this.commentUploading = true;
    this.fileService.upload(this.files).then((f: FileInterface[]) => {
      this.comment.files = this.comment.files.concat(f);
      if (this.toWhatsApp) {
        delete this.comment.responsible;
        delete this.comment.client;
        this.comment.text = this.clearText;
        (this.comment as any).clientContactId = this.mainClientContact.id;
        (this.comment as any).phone = this.mainClientContact.phone;
      }

      const createMethodName = this.toWhatsApp ? 'sendWhatsAppMessage' : 'create';
      this[this.commentServiceName][createMethodName]<Comment>(this.comment).subscribe(created => {
        this.addCommentEvent.emit(created);
        this.clearCommentForm();
        this.commentUploading = false;
      }, e => {
        this.clearCommentForm();
        this.commentUploading = false;
        console.error(e);
        this.errorService.addError.emit({
          title: `Ошибка при отправке ${this.toWhatsApp ? 'сообщения' : 'комментария'}`,
          message: e.error && e.error.message ? e.error.message : e.message
        });
      });
    });
  }

  clearCommentForm() {
    this.comment.text = '';
    this.clearTextEvent.emit();
    this.comment.responsible = [];
    this.comment.files = [];
    this.fileCommentForm.nativeElement.value = null;
  }

  deleteComment(comment: Comment) {
    comment.deleted = true;
    this[this.commentServiceName].update<Comment>(comment).subscribe(() => {
    }, console.error);
  }

  reply(comment: Comment) {
    if (comment.user) {
      this.userAddEvent.emit(comment.user);
    } else if (comment.client && !comment.whatsapp) {
      (comment.client as any).client = true;
      this.userAddEvent.emit(comment.client);
    }

    this.toWhatsApp = comment.client && comment.whatsapp;

    if (!this.toWhatsApp) {
      this.textInputEvent.emit('<blockquote>' + comment.text + '</blockquote><p>&#8205;</p>');
    }

    $('.ql-editor').trigger('focus');
    setTimeout(function () {
      const div = document.querySelector('.ql-editor[contenteditable]');
      const selection = window.getSelection();
      const range = document.createRange();
      selection.removeAllRanges();
      range.selectNodeContents(div);
      range.collapse(false);
      selection.addRange(range);
      (div as HTMLElement).focus();
    }, 0);
  }

  changeFiles(files) {
    this.files = files;
  }

  removeCommentFile(file: any) {
    this.comment.files.splice(this.comment.files.indexOf(file), 1);
  }

  changeCommentFilter(commentFilter: CommentFilter) {
    this.setCommentFilterEvent.emit(commentFilter);
  }

}
