<form class="ui form">
  <div class="ui stackable grid">
    <div class="ui eight wide column">
      <h4 class="ui dividing header">Информация о плательщике</h4>
      <div class="field" *ngIf="persons.length">
        <label class="fw-bold">Плательщик</label>
        <e2b-person-dropdown [persons]="persons" [selectedPerson]="person" [event]="selectPerson"></e2b-person-dropdown>
      </div>
      <div class="ui inline fields" *ngIf="!person.id">
        <label class="fw-bold">Тип плательщика</label>
        <div class="field" *ngFor="let personType of personTypes">
          <sui-radio-button name="personType" [value]="personType" [(ngModel)]="person.type">{{personType.name}}</sui-radio-button>
        </div>
      </div>
      <div class="field required">
        <label class="fw-bold" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.HUMAN'">Ф.И.О.</label>
        <label class="fw-bold" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.IP' || person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">Название</label>
        <input type="text" name="name" [(ngModel)]="person.name" placeholder="{{person.type.systemType === 'E2B.PERSON.TYPE.HUMAN' ? 'Ф.И.О' : 'Название'}}">
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">Ген. директор</label>
        <input type="text" name="manager" [(ngModel)]="person.manager" placeholder="Ген. директор">
      </div>
      <div [ngClass]="person.type.systemType === 'E2B.PERSON.TYPE.COMPANY' ? 'field required' : 'field'"
           *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.IP' || person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">Юр. адрес</label>
        <input type="text" name="address" [(ngModel)]="person.address" placeholder="Юр. адрес">
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">Почтовый адрес</label>
        <input type="text" name="postAddress" [(ngModel)]="person.postAddress" placeholder="Почтовый адрес">
      </div>
      <div class="field required" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.IP' || person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">ИНН</label>
        <input type="text" name="inn" [(ngModel)]="person.inn" placeholder="ИНН">
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">КПП</label>
        <input type="text" name="kpp" [(ngModel)]="person.kpp" placeholder="КПП">
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.IP' || person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">ОКПО</label>
        <input type="text" name="okpo" [(ngModel)]="person.okpo" placeholder="ОКПО">
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">ОГРН</label>
        <input type="text" name="ogrn" [(ngModel)]="person.ogrn" placeholder="ОГРН">
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.IP'">
        <label class="fw-bold">ГРНИП</label>
        <input type="text" name="grnip" [(ngModel)]="person.grnip" placeholder="ГРНИП">
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.IP'">
        <label class="fw-bold">Дата выдачи ГРНИП</label>
        <input suiDatepicker
               [name]="'grnipDate'"
               [pickerMode]="'date'"
               [pickerFirstDayOfWeek]="1"
               [pickerUseNativeOnMobile]="true"
               [pickerPlacement]="'top left'"
               [(ngModel)]="person.grnipDate"
               [placeholder]="'Дата выдачи ГРНИП'">
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.IP' || person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">Банк</label>
        <div *ngIf="person.bank" class="ui action input">
          <input disabled [(ngModel)]="person.bank.bik" name="bik" type="text">
          <button (click)="person.bank = null" class="ui grey right labeled icon button"
                  style="height: auto !important;">
            <i class="edit icon"></i>
            Изменить
          </button>
        </div>
        <div *ngIf="!person.bank" class="fluid field">
          <ng-template #bankOptionTemplate let-query="query" let-result>
            <div class="mininame">{{result.name}}</div>
            <div class="miniprice">{{result.bik}}</div>
            <div class="minitext">{{result.address}}</div>
          </ng-template>
          <sui-search (resultSelected)="selectBank($event)"
                      [hasIcon]="true"
                      [maxResults]="25"
                      [optionsLookup]="bankSearch"
                      [resultTemplate]="bankOptionTemplate"
                      id="bank"
                      name="bank"
                      optionsField="bik"
                      placeholder="БИК или название банка"
                      style="width: 100%;">
          </sui-search>
        </div>
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.IP' || person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">Расчетный счет</label>
        <input type="text" name="rs" [(ngModel)]="person.rs" placeholder="Расчетный счет">
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.IP' || person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">Корр. счет</label>
        <input type="text" name="ks" [(ngModel)]="person.ks" placeholder="Корр. счет">
      </div>
      <div class="field" *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.IP' || person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <label class="fw-bold">Соединение с 1С</label>
        <sui-select class="fluid selection"
                    placeholder="База 1С"
                    [isDisabled]="false"
                    [(ngModel)]="person.connectionLink"
                    [ngModelOptions]="{name: 'connectionLink'}"
                    [options]="connectionLinks"
                    #selectConnectionLink>
          <sui-select-option *ngFor="let link of selectConnectionLink.filteredOptions"
                             [value]="link">
          </sui-select-option>
        </sui-select>
      </div>

      <ng-container *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.HUMAN'">
        <div class="field">
          <label class="fw-bold">Паспорт: cерия</label>
          <input type="text" name="passportSerie" [(ngModel)]="person.passport.serie" placeholder="Серия паспорта">
        </div>
        <div class="field">
          <label class="fw-bold">Паспорт: номер</label>
          <input type="text" name="passportNumber" [(ngModel)]="person.passport.number" placeholder="Номер паспорта">
        </div>
        <div class="field">
          <label class="fw-bold">Паспорт: кем выдан</label>
          <input type="text" name="passportOrg" [(ngModel)]="person.passport.org" placeholder="Кем выдан">
        </div>
        <div class="field">
          <label class="fw-bold">Паспорт: дата выдачи</label>
          <input suiDatepicker
                 name="passportDate"
                 [pickerMode]="'date'"
                 [pickerFirstDayOfWeek]="1"
                 [pickerUseNativeOnMobile]="true"
                 [pickerPlacement]="'top left'"
                 [(ngModel)]="person.passport.date"
                 placeholder="Дата выдачи">
        </div>
        <div class="field">
          <label class="fw-bold">Паспорт: код подразделения</label>
          <input type="text" name="passportCode" [(ngModel)]="person.passport.code" placeholder="Код подразделения">
        </div>
        <div class="field">
          <label class="fw-bold">Паспорт: адрес регистрации</label>
          <input type="text" name="passportAddress" [(ngModel)]="person.passport.address" placeholder="Адрес регистрации">
        </div>
      </ng-container>
    </div>
    <div class="ui eight wide column">
      <h4 class="ui dividing header">Отгрузка и монтаж</h4>
      <div class="ui inline fields">
        <div class="field">
          <sui-radio-button name="todoStatus" value="delivery" [(ngModel)]="shippingType">Транспортная компания</sui-radio-button>
        </div>
        <div class="field">
          <sui-radio-button name="todoStatus" value="pickup" [(ngModel)]="shippingType">Самовывоз</sui-radio-button>
        </div>
        <div class="field">
          <sui-radio-button name="todoStatus" value="mounting" [(ngModel)]="shippingType">Монтаж</sui-radio-button>
        </div>
      </div>

      <e2b-deal-field *ngIf="fieldsLoaded && shippingType" [deal]="deal" [fields]="fields[shippingType].fields"></e2b-deal-field>

    </div>

    <div class="ui eight wide column">
      <div class="form-block">
        <div class="field">
          <h4 class="ui header" style="margin-bottom: 3px;">Позиции сделки</h4>
          <e2b-deal-position [setPositions]="deal.positions" [products]="positions" [onAddPosition]="addDealPosition" [dealId]="deal.id"
                             [onRemovePosition]="removeDealPosition" [onSelectPositionEvent]="onSelectPosition"></e2b-deal-position>
        </div>
      </div>
    </div>
    <div class="ui eight wide column">
      <div class="form-block" *ngIf="selectedPosition">
        <h4 class="ui dividing header">Реквизиты позиции</h4>
        <h3>
          {{selectedPosition.position.name}}<br/>
          <span class="greytext" *ngIf="selectedPosition.position.group">{{selectedPosition.position.group.name}}</span>
        </h3>

        <div class="field">
          <label class="fw-bold">Обязательные реквизиты</label>
          <div class="ui divider"></div>
          <e2b-sales-wizard-deal-position-field [selectedPosition]="selectedPosition"
                                                [onPositionValueSave]="onPositionValueSave"
                                                [positions]="requiredPositionValues"
                                                [cachedPositionValues]="positionValuesToSave"
                                                [positionValuesLoaded]="positionValuesLoaded"
                                                [required]="true"
          ></e2b-sales-wizard-deal-position-field>
        </div>

        <div class="field mt-3">
          <label class="fw-bold">Опциональные реквизиты</label>
          <div class="ui divider"></div>
          <e2b-sales-wizard-deal-position-field [selectedPosition]="selectedPosition"
                                                [onPositionValueSave]="onPositionValueSave"
                                                [positions]="optionalPositionValues"
                                                [positionValuesLoaded]="positionValuesLoaded"
          ></e2b-sales-wizard-deal-position-field>
        </div>
      </div>
    </div>

    <div class="ui eight wide column">
      <div class="form-block">
        <h4 class="ui dividing header">Оформление договора</h4>
        <div class="ui inline fields">
          <div class="field">
            <sui-radio-button name="todoStatus" value="lk" [(ngModel)]="contractType">Личный кабинет</sui-radio-button>
          </div>
          <div class="field">
            <sui-radio-button name="todoStatus" value="office" [(ngModel)]="contractType">Офис</sui-radio-button>
          </div>
        </div>
        <e2b-deal-field *ngIf="contractFieldsLoaded" [deal]="deal" [fields]="contractFields"></e2b-deal-field>
        <div *ngIf="contractType === 'lk'">
          <button class="ui green button mt-2" [disabled]="!isPersonValid" (click)="finalizeContract('remote')">Завершить оформление</button>
        </div>
        <div *ngIf="contractType === 'office'" class="mt-2">
          <div class="field">
            <label class="fw-bold">Создать шаблон договора</label>
            <div *ngIf="contractUploadedFiles.length" class="d-flex flex-wrap">
              <div *ngFor="let file of contractUploadedFiles" class="attach">
                <e2b-file [file]="file"></e2b-file>
              </div>
            </div>
            <div class="ui left pointing dropdown" suiDropdown>
              <button class="ui blue icon button">
                Шаблоны <i class="chevron right icon"></i>
              </button>
              <div class="menu" suiDropdownMenu>
                <a class="item" *ngFor="let transition of deal.currentStatus.transitions | orderByColumn:'sort:number'; trackBy: identify" [title]="transition.description" [innerText]="transition.name" (click)="performTransition(transition)"></a>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="fw-bold">Сканы паспорта</label>
            <div *ngIf="passportScanUploadedFiles.length" class="d-flex flex-wrap">
              <div *ngFor="let file of passportScanUploadedFiles" class="attach">
                <e2b-file [file]="file"></e2b-file>
              </div>
            </div>
            <div *ngIf="!passportScanUploadedFiles.length" class="ui input">
              <input type="file" id="passportScanFile" name="passportFiles" multiple #passportScanForm (change)="uploadFilesWithComment('passport', passportScanForm.files)">
              <label for="passportScanFile">
                <span *ngIf="!passportScanFiles">Прикрепить файлы</span>
                <span *ngIf="passportScanFiles">Выбрано файлов: {{passportScanFiles.length}}</span>
              </label>
            </div>
          </div>
          <div class="field">
            <label class="fw-bold">Сканы договора</label>
            <div *ngIf="contractScanUploadedFiles.length" class="d-flex flex-wrap">
              <div *ngFor="let file of contractScanUploadedFiles" class="attach">
                <e2b-file [file]="file"></e2b-file>
              </div>
            </div>
            <div *ngIf="!contractScanUploadedFiles.length" class="ui input">
              <input type="file" id="contractScanFile" name="contractFiles" multiple #contractScanForm (change)="uploadFilesWithComment('contract', contractScanForm.files)">
              <label for="contractScanFile">
                <span *ngIf="!contractScanFiles">Прикрепить файлы</span>
                <span *ngIf="contractScanFiles">Выбрано файлов: {{contractScanFiles.length}}</span>
              </label>
            </div>
          </div>
          <div class="ui divider"></div>
          <button class="ui green button mt-2" [disabled]="!isPersonValid" (click)="finalizeContract('office')">Завершить оформление</button>
        </div>
      </div>
    </div>
  </div>
</form>
