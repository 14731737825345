<div class="ui textareaForm form">
  <div class="field" draggable="false">
    <quill-editor name="commentTextarea"
                  placeholder="Текст комментария..."
                  [(ngModel)]="model" (onContentChanged)="textChange($event)"
                  (paste)="onPaste($event)">
    </quill-editor>
    <e2b-staff-dropdown *ngIf="userTrigger && !toWhatsApp && commentType !== CommentType.lkOrder"
                        [class]="'noradius removeTopBorder'"
                        [event]="userChangeEvent"
                        [staff]="users">
    </e2b-staff-dropdown>
    <div class="ui horizontal list">
      <div class="item" *ngFor="let user of selectedUsers">
        <e2b-user-label [user]="user"></e2b-user-label>
      </div>
    </div>
  </div>
</div>
