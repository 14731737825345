<div class="ui breadcrumb" *ngIf="breadcrumbs.length > 1">
      <ng-container *ngFor="let breadcrumb of breadcrumbs; last as isLast">
        <a class="section" style="text-transform: uppercase;" *ngIf="!isLast" [routerLink]="[breadcrumb.url]">
          {{ breadcrumb.label }}
        </a>
        <div class="divider" *ngIf="!isLast"> /</div>
        <div class="section active" style="text-transform: uppercase;" *ngIf="isLast">
          {{ breadcrumb.label }}
        </div>
      </ng-container>
    </div>
  