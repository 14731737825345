import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClientContact } from '../../_models/client-contact.model';
import { ContactService } from '../../_services/contact.service';

// import {ClientContactService} from '../../_services/client-contact.service';

@Component({
  selector: 'e2b-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/table.min.css',
    './contacts.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactsComponent implements OnInit, OnDestroy {

  contacts: Array<ClientContact>;
  loaded = false;
  private listSubscribe: Subscription;

  constructor(private contactService: ContactService) {
  }

  ngOnInit() {
    this.listSubscribe = this.contactService.find<ClientContact>().subscribe(res => {
      this.contacts = res;
      this.loaded = true;
    });

  }

  ngOnDestroy() {
    this.listSubscribe.unsubscribe();
  }

}
