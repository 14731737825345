<div class="ui top pointing dropdown" suiDropdown *ngIf="loaded">
  <i class="large bell green icon" *ngIf="!reminder.id"></i>
  <span *ngIf="reminder.id">
    <div>{{reminderOutput}}</div>
    <div class="greytext">Напоминание</div>
  </span>
  <div class="menu" suiDropdownMenu>
    <div *ngFor="let reminderOffset of reminderOffsets" class="item" (click)="saveReminder(reminderOffset)">
      {{reminderOffset.name}}
    </div>
    <div class="ui divider" *ngIf="reminder.id"></div>
    <div class="item" *ngIf="reminder.id" (click)="deleteReminder()">Удалить напоминание</div>
  </div>
</div>
