<form class="ui form">
  <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
    <div class="ui text loader">Загрузка...</div>
  </sui-dimmer>
  <div class="ui stackable grid">
    <div class="ui seven wide column">
      <h2 *ngIf="!editName">{{client.name}} <i class="ui icon edit small blue pointer" (click)="editNameChange()"></i></h2>
      <div class="field" style="margin-left: 14px;width: auto !important;" *ngIf="editName">
        <div class="ui big action input" style="width: auto !important;">
          <input type="text" [focus]="clientNameFocus" placeholder="Наименование" [ngModelOptions]="{name: 'name'}" [(ngModel)]="client.name">
          <button class="ui primary button" (click)="editNameTrigger()"><i class="ui icon save"></i></button>
        </div>
      </div>
      <sui-message class="error" *ngIf="err">
        <div class="header">
          {{err.name}}
        </div>
        <p>{{err.code}}</p>
      </sui-message>
      <p *ngIf="!editDescription"><span [innerHTML]="client.description"></span> <span class="greytext" *ngIf="!client.description">Дополнительная информация</span> <span style="margin-left: 6px;"><i class="ui icon edit small blue pointer" (click)="editDescription = true"></i></span></p>
      <div class="field" *ngIf="editDescription">
            <textarea class="w-100"
                      *ngIf="editDescription"
                      [(ngModel)]="client.description"
                      name="description"
                      placeholder="Дополнительная информация">
            </textarea>
      </div>
      <button *ngIf="editDescription" class="ui primary button" (click)="editDescriptionTrigger()"><i class="ui icon save"></i> Сохранить</button>
    </div>
    <div class="ui two wide column">
      <h3 class="header">Тип клиента</h3>
      <p class="item" *ngIf="!editClientType">
        <span>{{client.type.name}}</span>
        <span style="margin-left: 6px;"><i class="ui icon edit blue pointer" (click)="editClientType = true"></i></span>
      </p>
      <div class="field" *ngIf="editClientType">
        <e2b-client-type-dropdown [clientTypes]="types" [event]="selectClientType"></e2b-client-type-dropdown>
      </div>
    </div>
    <div class="ui two wide column">
      <h3 class="header">Менеджер</h3>
      <p class="item" *ngIf="!editManager">
        <a [routerLink]="['/staff/card/', client.manager.id]">{{client.manager.name}}</a>
        <span style="margin-left: 6px;"><i class="ui icon edit blue pointer" (click)="editManager = true"></i></span>
      </p>
      <div class="field" *ngIf="editManager">
        <e2b-staff-dropdown [staff]="managers" [event]="selectManager"></e2b-staff-dropdown>
      </div>
    </div>
    <div class="ui ten wide column">
      <h3 class="header">Сделки</h3>
      <table class="ui selectable very padded table">
        <thead>
        <tr>
          <th>
            <div class="head">№ Сделки</div>
          </th>
          <th>
            <div class="head">Статус</div>
          </th>
          <th>
            <div class="head">Заказ</div>
          </th>
          <th>
            <div class="head">Сумма</div>
          </th>
          <th>
            <div class="head">Дела</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let deal of client.deals" role="row">
          <td>
            <a [routerLink]="['/deal/card/', deal.id]">{{deal.name}}</a>
          </td>
          <td>
            {{deal.currentStatus.name}}
          </td>
          <td>
            <p *ngFor="let position of deal.positions">
              {{position.position.name}}<span> * </span><span>{{position.count}}</span> *
              <span>{{position.price | number}} руб.</span>
            </p>
          </td>
          <td>
            <b>{{totalDealSum(deal.positions) | number}} руб.</b>
          </td>
          <td>
            <a [routerLink]="['/todo/list']" [queryParams]="{dealId: deal.id}"></a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="ui text menu">
        <div class="item">
          <button class="ui green basic button" [routerLink]="['/deal/add']" [queryParams]="{clientId:client.id}">+ Добавить сделку</button>
        </div>
      </div>
    </div>
    <div class="ui six wide column">
      <e2b-person-view [persons]="client.persons" [clientId]="client.id"></e2b-person-view>
      <br><br>
      <e2b-contact [saveEvent]="addContact" [contacts]="client.contacts" [clientId]="client.id"></e2b-contact>
    </div>
  </div>
</form>
