import { BaseModel } from './base.model';
import { Deal } from './deal.model';
import { OrderDealPosition } from './order-deal-position.model';
import { OrderFieldValue } from './order-field-value.model';
import { OrderStatus } from './enums/order-status.enum';
import { OrderType } from './enums/order-type.enum';
import { User } from './user.model';

export class Order extends BaseModel {
  auto: boolean;
  cost: number;
  created: Date;
  date?: Date;
  deal: Deal;
  deleted: boolean;
  orderDealPositions: OrderDealPosition[];
  orderFieldValues: OrderFieldValue[];
  positions?: any[] = [];
  responsible: User;
  status: OrderStatus;
  type: OrderType;
  userCreated: User;
}
