import { Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Comment } from '../../../_models/comment.model';
import { Router } from '@angular/router';
import { Client } from '../../../_models/client.model';
import { DealFieldInterface } from '../../../_models/deal-field-interface';
import { DealFieldValue } from '../../../_models/deal-field-value.model';
import { DealField } from '../../../_models/deal-field.model';
import { DealFile } from '../../../_models/deal-file.model';
import { DealPosition } from '../../../_models/deal-position.model';
import { DealStatus } from '../../../_models/deal-status.model';
import { Deal } from '../../../_models/deal.model';
import { PersonType } from '../../../_models/person-type.model';
import { Person } from '../../../_models/person.model';
import { Position } from '../../../_models/position.model';
import { Transition } from '../../../_models/transition.model';
import { User } from '../../../_models/user.model';
import { BankService } from '../../../_services/bank.service';
import { CommentService } from '../../../_services/comment.service';
import { DealFieldService } from '../../../_services/deal-field.service';
import { DealPositionValueService } from '../../../_services/deal-position-value.service';
import { DealPositionService } from '../../../_services/deal-position.service';
import { DealStatusService } from '../../../_services/deal-status.service';
import { DealService } from '../../../_services/deal.service';
import { FileService } from '../../../_services/file.service';
import { FileInterface } from '../../../_services/file.service';
import { PersonTypeService } from '../../../_services/person-type.service';
import { PersonService } from '../../../_services/person.service';
import { PositionGroupService } from '../../../_services/position-group.service';
import { PositionService } from '../../../_services/position.service';
import { UserService } from '../../../_services/user.service';
import * as moment from 'moment';

type shippingType = 'delivery' | 'pickup' | 'mounting' | '';
type contractType = 'office' | 'lk' | '';

const CONTRACT_DEAL_STATUS_ID = 4;
const POSITION_TYPE_MATRESS_ID = 3;
const ORDER_READY_DATE_FIELD_ID = 285;

const PERSON_TYPE_HUMAN_ID = 1;
const PREPAY_DEAL_STATUS_ID = 5;
const REMOTE_REGISTRATION_DEAL_STATUS_ID = 24;

const DEAL_CONTRACT_DATE_FIELD_ID = 148;
const DEAL_CONTRACT_NUMBER_FIELD_ID = 149;

const personRequiredFields: {
  [propName: string]: (keyof Person)[];
} = {
  'E2B.PERSON.TYPE.HUMAN': ['name'],
  'E2B.PERSON.TYPE.COMPANY': ['name', 'address', 'inn'],
  'E2B.PERSON.TYPE.IP': ['name', 'inn']
};
const personFields: {
  [propName: string]: (keyof Person)[];
} = {
  'E2B.PERSON.TYPE.HUMAN': ['name', 'passport'],
  'E2B.PERSON.TYPE.COMPANY': ['name', 'manager', 'address', 'postAddress', 'inn', 'kpp', 'okpo', 'ogrn', 'bank', 'rs', 'ks', 'connectionLink'],
  'E2B.PERSON.TYPE.IP': ['name', 'inn', 'okpo', 'grnip', 'grnipDate', 'bank', 'rs', 'ks', 'connectionLink']
};
const areDifferent = (value1: any, value2: any): boolean => {
  const type1 = value1 === null ? 'null' : typeof value1;
  const type2 = value2 === null ? 'null' : typeof value2;

  if (type1 !== type2) {
    return true;
  }

  if (type1 === 'object') {
    if (value1 instanceof Date && value2 instanceof Date) {
      return value1.getDate() !== value2.getDate();
    }

    if (value1.id && value2.id) {
      return value1.id !== value2.id;
    } else {
      return Object.keys(value1).some(_key => areDifferent(value1[_key], value2[_key]));
    }
  } else {
    return value1 !== value2;
  }
  return false;
};

@Component({
  selector: 'e2b-sales-wizard-person-info',
  templateUrl: './person-info.component.html',
  styleUrls: ['./person-info.component.less']
})
export class PersonInfoComponent implements OnInit {
  fieldsLoaded = false;
  positionValuesLoaded = false;
  @Input()
  deal: Deal;
  @Input()
  onSaveEvent: EventEmitter<any>;

  person = <Person>{ type: <PersonType>{ id: PERSON_TYPE_HUMAN_ID, systemType: 'E2B.PERSON.TYPE.HUMAN' }, passport: {} };
  originalPerson = <Person>{ type: <PersonType>{ id: PERSON_TYPE_HUMAN_ID, systemType: 'E2B.PERSON.TYPE.HUMAN' }, passport: {} };
  personTypes: PersonType[] = [];
  persons: Person[] = [];
  connectionLinks = ['msktip', 'yasno1tip', 'yasno2', 'spbtip', 'iptitlinov_buh', 'ipkazakov_buh', 'yasno2kassa'];

  bankSearch: (query: any) => Promise<unknown>;
  selectPerson = new EventEmitter<Person>();

  shippingType: shippingType = '';
  contractType: contractType = '';

  fields: {
    [propName: string]: {
      ids: number[],
      fields: DealField[]
    }
  } = {
    'delivery': {
      ids: [320, 306],
      fields: []
    },
    'pickup': {
      ids: [306],
      fields: []
    },
    'mounting': {
      ids: [7, 8, 251],
      fields: []
    }
  };

  contractFieldsIds: number[] = [DEAL_CONTRACT_DATE_FIELD_ID, DEAL_CONTRACT_NUMBER_FIELD_ID];
  contractFields: DealField[] = [];
  contractFieldsLoaded = false;

  passportScanFiles: FileList;
  contractScanFiles: FileList;

  contractUploadedFiles: DealFile[] = [];
  passportScanUploadedFiles: DealFile[] = [];
  contractScanUploadedFiles: DealFile[] = [];

  onSelectPosition = new EventEmitter<DealPosition>();
  onPositionValueSave = new EventEmitter<any>();

  requiredPositionFieldsIds: number[] = [];
  optionalPositionFieldsIds: number[] = [192, 214];

  requiredPositionValues: any;
  optionalPositionValues: any;

  positions: Position[] = [];
  addDealPosition = new EventEmitter<DealPosition>();
  removeDealPosition = new EventEmitter<DealPosition>();
  selectedPosition: DealPosition = null;

  constructor(private readonly bankService: BankService,
              private readonly commentService: CommentService,
              private readonly dealService: DealService,
              private readonly dealFieldService: DealFieldService,
              private readonly dealPositionService: DealPositionService,
              private readonly dealPositionValueService: DealPositionValueService,
              private readonly dealStatusService: DealStatusService,
              private readonly fileService: FileService,
              private readonly personService: PersonService,
              private readonly personTypeService: PersonTypeService,
              private readonly positionService: PositionService,
              private readonly positionGroupService: PositionGroupService,
              private readonly router: Router,
              private readonly userService: UserService) {
  }

  get isPersonValid() {
    return personRequiredFields[this.person.type.systemType].every(key => Boolean(this.person[key]));
  }

  get isPersonEdited() {
    return personFields[this.person.type.systemType].some(key => areDifferent(this.person[key], this.originalPerson[key]));
  }

  ngOnInit() {
    this.personTypeService.find<PersonType>().subscribe(personTypes => {
      this.personTypes = personTypes;
      this.person.type = this.personTypes[0];
    });
    this.personService.findByClientId(this.deal.client.id).subscribe(persons => {
      this.persons = persons;
      if (this.deal.person) {
        this.deal.person = this.persons.find(person => person.id === this.deal.person.id);
        this.setPerson(this.deal.person);
      }
    });

    this.positionService.find<Position>().subscribe(positions => this.positions = positions);

    this.addDealPosition.subscribe(position => {
      if (this.deal.id) {
        position.deal = { id: this.deal.id };
        this.dealPositionService.create<DealPosition>(position).subscribe(createdPosition => {
          this.deal.positions.push(createdPosition);
        });
      } else {
        position.deal = {};
        this.deal.positions.push(position);
      }
    });
    this.removeDealPosition.subscribe(position => {
      if (position.id) {
        this.dealPositionService.deleteById<DealPosition>(position.id).subscribe(() => {
          this.deal.positions.splice(this.deal.positions.indexOf(position), 1);
        }, console.error);
      } else {
        this.deal.positions.splice(this.deal.positions.indexOf(position), 1);
      }
    });

    const allFieldsIds = Array.from(new Set(Object.values(this.fields).map(obj => obj.ids).flat()));
    const shippingTypes = Object.keys(this.fields);
    let fieldsCounter = 0;
    allFieldsIds.map(id => this.dealFieldService.findById<DealField>(id).subscribe(field => {
      shippingTypes.map(key => {
        if (this.fields[key].ids.includes(id)) {
          this.fields[key].fields.push(field);
        }
      });
      fieldsCounter++;
      if (fieldsCounter === allFieldsIds.length) {
        this.dealService.fieldFindById<DealFieldInterface[]>(4).subscribe(list => {
          const checkListFields = list.find(dealFieldInterface => dealFieldInterface.name === 'Опросный лист');
          if (checkListFields) {
            this.fields['mounting'].fields.push(...checkListFields.childrens);
          }
          setTimeout(() => this.fieldsLoaded = true, 0);
        });
      }
    }));

    this.contractFieldsIds.map(id => {
      this.dealFieldService.findById<DealField>(id).subscribe(field => {
        this.contractFields.push(field);
        if (this.contractFieldsIds.length === this.contractFields.length) {
          this.contractFieldsLoaded = true;
        }
      });
    });

    this.dealStatusService.findById<DealStatus>(CONTRACT_DEAL_STATUS_ID).subscribe(status => {
      this.requiredPositionFieldsIds = status.requiredFields.filter(field => field.model === 'CRM.FIELD.POSITION').map(field => field.id);
    });

    this.onSelectPosition.subscribe(position => {
      this.selectedPosition = position;
      const extendedRequiredPositionFieldIds = [...this.requiredPositionFieldsIds];
      if (this.selectedPosition.position.type === POSITION_TYPE_MATRESS_ID) {
        extendedRequiredPositionFieldIds.push(ORDER_READY_DATE_FIELD_ID);
      }
      this.positionValuesLoaded = false;

      this.dealPositionValueService.values(this.selectedPosition.id).subscribe(values => {
        this.requiredPositionValues = values.position.filter(p => extendedRequiredPositionFieldIds.includes(p.field.id));
        this.optionalPositionValues = values.position.filter(p => this.optionalPositionFieldsIds.includes(p.field.id));
        this.requiredPositionValues.group = values.group;
        this.optionalPositionValues.group = values.group;
        this.positionValuesLoaded = true;
      });
    });


    this.selectPerson.subscribe(person => {
      this.deal.person = person;
      this.dealService.update(<Deal>{ id: this.deal.id, person: <Person>{ id: this.deal.person.id } }).subscribe(() => {
      });
      this.setPerson(person);
    });
    this.bankSearch = async (query) => {
      return this.bankService.search(query);
    };
  }

  setPerson(person: Person) {
    this.person = person;
    if (this.person.passport.date) {
      this.person.passport.date = new Date(this.person.passport.date);
    }
    this.originalPerson = { ...person };
    if (this.person.passport) {
      this.originalPerson.passport = { ...this.person.passport };
    }
  }

  selectBank(bank) {
    this.person.bank = bank;
  }

  savePerson() {
    if (!this.isPersonValid) {
      return;
    }

    if (this.person.id) {
      if (this.isPersonEdited) {
        this.personService.update(this.person).subscribe(() => {
        });
      }
    } else {
      this.person.client = <Client>{ id: this.deal.client.id };
      this.personService.create<Person>(this.person).subscribe((createdPerson) => {
        this.dealService.update(<Deal>{ id: this.deal.id, person: { id: createdPerson.id } }).subscribe(() => {
        });
      });
    }
  }

  finalizeContract(type: 'office' | 'remote') {
    if (!this.isPersonValid) {
      return;
    }
    this.savePerson();

    const contractDateValue = this.deal.values.find(dealValue => dealValue.field.name === 'Договор: Дата');
    if (!contractDateValue) {
      this.dealService.fieldCreate<DealFieldValue>(<DealFieldValue>{
        field: { id: 148 },
        value: moment().format('DD.MM.YYYY'),
        deal: <Deal>{ id: this.deal.id }
      }).subscribe(() => {
      });
    }

    const dealFutureStatus = type === 'office' ? PREPAY_DEAL_STATUS_ID : REMOTE_REGISTRATION_DEAL_STATUS_ID;
    this.dealService.update(<any>{ id: this.deal.id, currentStatus: <DealStatus>{ id: dealFutureStatus }, oldStatus: { id: this.deal.currentStatus.id } }).subscribe(() => {
      this.router.navigate(['/deal/card/', this.deal.id]);
    });
  }

  identify(index, obj) {
    return obj ? obj.id : undefined;
  }

  changeFiles(filesType: 'contractFiles' | 'passportScanFiles' | 'contractScanFiles', files: FileList) {
    this[filesType] = files;
  }

  uploadFilesWithComment(name: 'passport' | 'contract', files: FileList) {
    this.fileService.upload(files).then((f: FileInterface[]) => {
      const comment = new Comment();
      comment.text = name === 'passport' ? 'Паспорт' : 'Договор';
      comment.files = f;
      comment.user = <User>{ id: this.userService.current().id };
      comment.deal = <Deal>{ id: this.deal.id };
      this.commentService.create<Comment>(comment).subscribe((createdComment) => {
        const key = name === 'passport' ? 'passportScanUploadedFiles' : 'contractScanUploadedFiles';
        this[key] = createdComment.files;
      });
    });
  }

  performTransition(trans: Transition) {
    if (trans.action === 'E2B.TEMPLATE') {
      this.dealService.template(this.deal.id, parseInt(trans.data.toString(), 0)).subscribe((dealFile: any) => {
        this.contractUploadedFiles.push(dealFile.file);
      });
    }
  }
}
