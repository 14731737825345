<div class="ui stackable grid">
  <div class="ui eight wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui form" style="overflow-x: scroll">

        <div class="d-flex justify-content-center align-items-center">
          <button class="ui button" (click)="prevDay();">
            <i class="angle left icon"></i>
          </button>
          &nbsp;&nbsp;
          <h3 class="fw-bold m-0 header">
            {{day.format('MMM, YY г.')}}&nbsp;&nbsp;
          </h3>
          <e2b-staff-simple-dropdown class="fsz-14px" [staff]="users" [selected]="user" [event]="selectUser()"></e2b-staff-simple-dropdown>
          &nbsp;&nbsp;
          <button class="ui button" (click)="nextDay();">
            <i class="angle right icon"></i>
          </button>
        </div>

        <div class="ui label">
          Рабочих дней: {{holiday.workdays}}
        </div>

        <table class="ui very basic selectable celled table">
          <thead>
          <tr>
            <th>
              <div class="head">Название</div>
            </th>
            <th>
              <div class="head">Месяц</div>
            </th>
            <th>
              <div class="head">На день (авто)</div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <b>Продажи</b>
            </td>
            <td>
              <input type="text" [(ngModel)]="data.sale.value" (change)="save(data.sale)">
            </td>
            <td>
              {{ data.sale.value ? ((data.sale.value / holiday.workdays) | number:'1.0-1') : 0}}
            </td>
          </tr>
          </tbody>
          <tbody>
          <tr *ngFor="let type of data.todo | orderByColumn:'name:string'">
            <td>
              Дела: {{type.name}}
            </td>
            <td>
              <input type="text" [(ngModel)]="type.plan.value" (change)="saveTodo(type.id, type.plan)">
            </td>
            <td>
              {{ type.plan.value ? ((type.plan.value / holiday.workdays) | number:'1.0-1') : 0}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
