import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseModel } from '../_models/base.model';
import { ClientFile } from '../_models/client-file.model';
import { DealFile } from '../_models/deal-file.model';
import { DealHistory } from '../_models/deal-history.model';
import { Deal } from '../_models/deal.model';
import { BaseService } from './base.service';
import { FileInterface } from './file.service';
import { SmsTemplate } from '../_models/sms-template.model';
import { Comment } from '../_models/comment.model';

@Injectable()
export class DealService extends BaseService {
  baseUrl = '/deal';

  addFile(dealId: number, file: FileInterface): Observable<DealFile> {
    return this.http.post<DealFile>(this.baseUrl + '/files', {
      id: dealId,
      file: file
    }, { withCredentials: true, headers: { 'Authorization': localStorage.getItem('e2b.user.token') } });
  }

  addClientFile(dealId: number, file: FileInterface): Observable<ClientFile> {
    return this.http.post<ClientFile>(this.baseUrl + '/files/client', {
      id: dealId,
      file: file
    }, { withCredentials: true, headers: { 'Authorization': localStorage.getItem('e2b.user.token') } });
  }

  addResponsible(dealId: number, auditor: any) {
    const body = { deal: { id: dealId } };
    const prop = auditor.users ? 'group' : 'user';
    body[prop] = { id: auditor.id };

    return this.http.post(this.baseUrl + '/responsible',
      body,
      { withCredentials: true, headers: { 'Authorization': localStorage.getItem('e2b.user.token') } });
  }

  archive(options: { from: string, to: string, personId?: number[] }): Observable<any> {
    return this.http.post<any>('/production/archive', { options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  calendar(from: string, to: string, statusId?: number): Observable<any> {
    const fromObject: any = {
      from,
      to
    };
    if (statusId) {
      fromObject.statusId = statusId.toString();
    }
    const params = new HttpParams({
      fromObject: fromObject
    });
    return this.http.get(this.baseUrl + '/calendar', {
      params,
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  calls<T>(id: number): Observable<T[]> {
    return this.http.get<T[]>(this.baseUrl + '/calls/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  coefficient(date): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/coefficient/' + date, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  current(options: { from: string, to: string, personId?: number[] }): Observable<any> {
    return this.http.post<any>('/production/current', { options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  export(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/export/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  export1C(id): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/export1C/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  /**
   * Создание объекта
   * @param {BaseModel} model
   * @returns {Observable<T>}
   */
  fieldCreate<T>(model: BaseModel): Observable<T> {
    return this.http.post<T>(this.baseUrl + '/fields/', model, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  /**
   * Удаление объекта
   * @returns {Observable<T>}
   * @param id
   */
  fieldDeleteById<T>(id: number): Observable<T> {
    return this.http.delete<T>(this.baseUrl + '/fields/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  /**
   * Получение объекта по идентификатору
   * @param {number} id
   * @returns {Observable<T>}
   */
  fieldFindById<T>(id: number): Observable<T> {
    return this.http.get<T>(this.baseUrl + '/fields/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  /**
   * Изменение объекта
   * @param {BaseModel} model
   * @returns {Observable<T>}
   */
  fieldUpdate<T>(model: BaseModel): Observable<T> {
    return this.http.put<T>(this.baseUrl + '/fields/' + model.id, model, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  files(dealId: number): Observable<DealFile[]> {
    return this.http.get<DealFile[]>(this.baseUrl + '/files/' + dealId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  clientFiles(dealId: number): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + '/files/client/' + dealId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  finance(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/finance/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  findByClient<T>(id: number): Observable<T[]> {
    return this.http.get<T[]>(this.baseUrl + '/client/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  getMountingDate(options: { dealId: number, dateCount?: number }): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/mounting/date/', options, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  getShippingDate(options: { dealId: number, dateCount?: number }): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/shipping/date/', options, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  history(dealId: number): Observable<DealHistory[]> {
    return this.http.get<DealHistory[]>(this.baseUrl + '/history/' + dealId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  productionArchiveXlsx(options: any): Observable<any> {
    return this.http.post('/production/archive/xlsx', { options }, {
      withCredentials: true,
      responseType: 'blob',
      headers: {
        'Authorization': localStorage.getItem('e2b.user.token')
      }
    });
  }

  productionXlsx(options: any): Observable<any> {
    return this.http.post('/production/xlsx', { options }, {
      withCredentials: true,
      responseType: 'blob',
      headers: {
        'Authorization': localStorage.getItem('e2b.user.token')
      }
    });
  }

  redirectLk(clientId: number): Observable<any> {
    return this.http.post('/lk/user/login', { clientId }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  removeFile(id: number, type: string, name: string) {
    return this.http.delete(this.baseUrl + '/files/' + type + '/' + name + '/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  removeClientFile(id: number) {
    return this.http.delete(this.baseUrl + '/files/client/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  removeResponsible(auditorId: number) {
    return this.http.delete(this.baseUrl + '/responsible/' + auditorId,
      { withCredentials: true, headers: { 'Authorization': localStorage.getItem('e2b.user.token') } });
  }

  setMountingDate(options: { dealId: number, mountingDate?: string, shippingDate?: string, productionDates: { korpus: string } }): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/mounting/date/set', options, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  template(dealId: number, templateId: number): Observable<DealFile> {
    return this.http.get<DealFile>(this.baseUrl + '/template/' + templateId + '/' + dealId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  smsTemplates(): Observable<SmsTemplate[]> {
    return this.http.get<SmsTemplate[]>(this.baseUrl + '/sms/template', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  sendSms(dealId, templateId, clientContactId): Observable<{ comment: Comment }> {
    const body = {
      template: { id: templateId },
      deal: { id: dealId },
      clientContact: { id: clientContactId }
    };
    return this.http.post<{ comment: Comment }>(this.baseUrl + '/sms', body, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  stateUpdate(dealId: number): Observable<Deal> {
    return this.http.get<Deal>(this.baseUrl + '/stateUpdate/' + dealId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  users(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/users/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  positionField(id: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/position/values/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

}
