import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'e2b-policy',
  template: '',
})
export class PolicyComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    window.location.href = 'https://e2b.zaoblako.ru/assets/docs/policy.pdf';
  }

}
