import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DealPosition } from '../_models/deal-position.model';

import { BaseService } from './base.service';

@Injectable()
export class DealPositionService extends BaseService {
  baseUrl = '/dealPosition';

  add(positions: DealPosition[]) {
    return this.http.post<any>('/dealPosition/add', positions, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  updateValue(id: number, update: { value: any }): Observable<any> {
    return this.http.put<any>('/deal/position/values/' + id, update, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
