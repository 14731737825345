import { Component, ViewEncapsulation } from '@angular/core';
import '../../assets/design/semantic-ui/components/search.js';

@Component({
  selector: 'e2b-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/menu.min.css',
    '../../assets/design/semantic-ui/components/label.min.css',
    '../../assets/design/semantic-ui/components/icon.min.css',
    './app-header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppHeaderComponent {

  constructor() {
  }

}
