import { Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { SuiSearch } from 'ng2-semantic-ui/dist';
import { map } from 'rxjs/operators';
import { ClientContact } from '../../_models/client-contact.model';
import { ClientType } from '../../_models/client-type.model';
import { Client } from '../../_models/client.model';
import { DealFieldValue } from '../../_models/deal-field-value.model';
import { DealField } from '../../_models/deal-field.model';
import { DealPosition } from '../../_models/deal-position.model';
import { DealStatus } from '../../_models/deal-status.model';
import { Deal } from '../../_models/deal.model';
import { Position } from '../../_models/position.model';
import { Responsible } from '../../_models/responsible.model';
import { TodoStatus } from '../../_models/todo-status.model';
import { TodoType } from '../../_models/todo-type.model';
import { Todo } from '../../_models/todo.model';
import { UserGroup } from '../../_models/user-group.model';
import { User } from '../../_models/user.model';
import { ClientTypeService } from '../../_services/client-type.service';
import { ClientService } from '../../_services/client.service';
import { ContactService } from '../../_services/contact.service';
import { DealPositionService } from '../../_services/deal-position.service';
import { DealService } from '../../_services/deal.service';
import { FileInterface, FileService } from '../../_services/file.service';
import { PositionService } from '../../_services/position.service';
import { TodoTypeService } from '../../_services/todo-type.service';
import { TodoService } from '../../_services/todo.service';
import { UserGroupService } from '../../_services/user-group.service';
import { UserService } from '../../_services/user.service';
import { ErrorService } from '../../error/error.service';

const TODO_TYPE_SHOW_ID = 3;
const TODO_STATUS_SUCCESS_ID = 3;
const CLIENT_TYPE_CLIENT_ID = 1;
const POSSIBLE_SALE_DATE_FIELD_ID = 159;
const CHECK_LIST_FIELD_ID = 252;
const AT_MANAGER_WORK__DEAL_STATUS_ID = 2;

@Component({
  selector: 'e2b-show-wizard',
  templateUrl: './show-wizard.component.html',
  styleUrls: ['./show-wizard.component.less',
              '../../../assets/design/semantic-ui/components/form.min.css',
              '../../../assets/design/semantic-ui/components/step.min.css',
              '../../../assets/design/semantic-ui/components/checkbox.min.css',
              '../../../assets/design/semantic-ui/components/button.min.css',
              '../../../assets/design/semantic-ui/components/divider.min.css',
              '../../../assets/design/semantic-ui/components/header.min.css',
              '../../../assets/design/semantic-ui/components/loader.min.css',
              '../../../assets/design/semantic-ui/components/dimmer.min.css',
  ],
  encapsulation: ViewEncapsulation.None
})
export class ShowWizardComponent implements OnInit {
  step = 1;
  loaded = false;
  editDate = false;
  editFiles = false;

  @ViewChild('clientSearchComponent', { static: false }) clientSearchComponent: SuiSearch<any>;

  deal = new Deal();
  client = new Client();
  clientContact = new ClientContact();
  originalClientContact = new ClientContact();
  files: FileList;
  managers: User[];
  groups: any[] = [];
  auditors: any[] = [];
  positions: Position[] = [];
  todoTypes: TodoType[] = [];
  clientSearch: (query: any) => Promise<unknown>;

  currentShowTodo: Todo = new Todo();
  nextCommunication: Todo = new Todo();

  possibleSaleDate: DealFieldValue = <DealFieldValue>{ field: { id: POSSIBLE_SALE_DATE_FIELD_ID } };
  checkListScan: DealFieldValue = <DealFieldValue>{ field: { id: CHECK_LIST_FIELD_ID } };

  selectManager = new EventEmitter<User>();
  selectSubManager = new EventEmitter<User>();
  selectAuditor = new EventEmitter<UserGroup[]>();
  addDealPosition = new EventEmitter<DealPosition>();
  removeDealPosition = new EventEmitter<DealPosition>();

  constructor(private readonly route: ActivatedRoute,
              private readonly clientService: ClientService,
              private readonly contactService: ContactService,
              private readonly clientTypeService: ClientTypeService,
              private readonly dealService: DealService,
              private readonly dealPositionService: DealPositionService,
              private readonly errorService: ErrorService,
              private readonly fileService: FileService,
              private readonly positionService: PositionService,
              private readonly router: Router,
              private readonly todoService: TodoService,
              private readonly todoTypeService: TodoTypeService,
              private readonly userService: UserService,
              private readonly userGroupService: UserGroupService) {
  }

  get isDealValid() {
    return Boolean(this.isClientContactValid && this.deal.manager && this.auditors.length && this.deal.positions.length);
  }

  get isClientContactEdited() {
    return ['name', 'email', 'phone'].some(field => this.clientContact[field] !== this.originalClientContact[field]);
  }

  get isClientContactValid() {
    return ['name', 'email', 'phone'].every(field => this.clientContact[field] && this.clientContact[field].trim());
  }

  get isCurrentShowValid() {
    return Boolean(this.currentShowTodo.from && this.currentShowTodo.to && this.currentShowTodo.finishComment);
  }

  get isNextCommunicationValid() {
    return Boolean(this.nextCommunication.from && this.nextCommunication.to && this.nextCommunication.type.id && this.nextCommunication.name);
  }

  ngOnInit() {
    this.deal.positions = [];
    this.currentShowTodo.type = this.nextCommunication.type = <TodoType>{ id: TODO_TYPE_SHOW_ID };
    this.currentShowTodo.status = <TodoStatus>{ id: TODO_STATUS_SUCCESS_ID };
    this.currentShowTodo.responsible = this.nextCommunication.responsible = <User>{ id: this.userService.current().id };

    this.route.queryParams.subscribe(params => {
      if (params.id) {
        const dealId = parseInt(params.id, 0);
        this.dealService.findById<Deal>(dealId).subscribe(deal => {
          if (deal) {
            this.deal = deal;
            this.client = deal.client;
            const clientMainContact: ClientContact = this.client.contacts.find(contact => contact.isMain) || this.client.contacts[0] || new ClientContact();
            if (clientMainContact.phone) {
              clientMainContact.phone = clientMainContact.phone.replace(/\D/g, '');
            }
            this.clientContact = { ...clientMainContact };
            this.originalClientContact = { ...clientMainContact };
            const possibleSaleDate = this.deal.values.find(value => value.field.id === POSSIBLE_SALE_DATE_FIELD_ID);
            if (possibleSaleDate) {
              this.possibleSaleDate = possibleSaleDate;
              this.possibleSaleDate.value = moment(this.possibleSaleDate.value, 'DD.MM.YYYY').toDate();
            }
            const checkListScan = this.deal.values.find(value => value.field.id === CHECK_LIST_FIELD_ID);
            if (checkListScan) {
              this.checkListScan = checkListScan;
            }
            this.load();
          }
        });
      } else {
        this.load();
      }
    });
  }

  load() {
    this.loaded = false;

    /* Services */
    this.userService.managers<User>().subscribe(managers => {
      this.managers = managers;
      this.deal.manager = this.deal.id
        ? this.managers.find(manager => manager.id === this.deal.manager.id)
        : this.managers.find(manager => manager.id === this.userService.current().id);
      if (this.deal.subManager) {
        this.deal.subManager = this.managers.find(manager => manager.id === this.deal.subManager.id);
      }
    });
    this.userGroupService.find<UserGroup>().subscribe(groups => {
      this.groups = this.groups.concat(groups);
      if (this.deal.responsibles) {
        const groupIds: any = {};
        this.deal.responsibles.map(r => {
          if (r.user) {
            groupIds[r.group.id] = true;
          }
        });
        groups.map(e => {
          if (groupIds[e.id]) {
            this.auditors.push(e);
          }
        });
      }
    });
    this.userService.find<User>().subscribe(employees => {
      this.groups = this.groups.concat(employees);
      if (this.deal.responsibles) {
        const userIds: any = {};
        this.deal.responsibles.map(r => {
          if (r.user) {
            userIds[r.group.id] = true;
          }
        });
        employees.map(e => {
          if (userIds[e.id]) {
            this.auditors.push(e);
          }
        });
      }
    });
    this.positionService.find<Position>().subscribe(positions => this.positions = positions);
    this.todoTypeService.find<TodoType>().subscribe(todoTypes => this.todoTypes = todoTypes);

    /* EventEmitters */
    this.selectManager.subscribe(manager => {
      this.deal.manager = manager;
      if (this.deal.id) {
        this.dealService.update(<Deal>{ id: this.deal.id, manager: <User>{ id: this.deal.manager.id } }).subscribe(() => {
        });
      }
    });
    this.selectSubManager.subscribe(manager => {
      this.deal.subManager = manager;
      if (this.deal.id) {
        this.dealService.update(<Deal>{ id: this.deal.id, subManager: <User>{ id: this.deal.subManager.id } }).subscribe(() => {
        });
      }
    });
    this.selectAuditor.subscribe(groups => {
      this.auditors = groups;
      if (this.deal.id) {
        this.updateAuditors();
      }
    });
    this.addDealPosition.subscribe(position => {
      if (this.deal.id) {
        position.deal = { id: this.deal.id };
        this.dealPositionService.create<DealPosition>(position).subscribe(createdPosition => {
          this.deal.positions.push(createdPosition);
        });
      } else {
        position.deal = {};
        this.deal.positions.push(position);
      }
    });
    this.removeDealPosition.subscribe(position => {
      if (position.id) {
        this.dealPositionService.deleteById<DealPosition>(position.id).subscribe(() => {
          this.deal.positions.splice(this.deal.positions.indexOf(position), 1);
        }, console.error);
      } else {
        this.deal.positions.splice(this.deal.positions.indexOf(position), 1);
      }
    });

    this.clientSearch = async (query) => {
      return new Promise(resolve => {
        this.clientService.search(query).then(results => {
          return resolve(results);
        }).catch(console.error);
      });
    };

    this.loaded = true;
  }

  selectClient(client: any) {
    this.client = client;

    if (client.contact) {
      this.clientContact = client.contact;
      if (this.clientContact.phone) {
        this.clientContact.phone = this.clientContact.phone.replace(/\D/g, '');
      }
      this.originalClientContact = { ...this.clientContact };
    }

    if (this.deal.id) {
      this.dealService.update(<Deal>{ id: this.deal.id, client: <Client>{ id: this.client.id } }).subscribe(() => {
      });
    }
  }

  resetClient() {
    this.client = new Client();
    this.clientContact = new ClientContact();
    this.originalClientContact = new ClientContact();
  }

  onClientContactPhoneChange(phone: number) {
    this.clientContact.phone = phone ? String(phone) : '';
  }

  updateAuditors() {
    const auditorGroupIds = [];
    const auditorUserIds = [];

    const dealResponsibleGroupIds = [];
    const dealResponsibleUserIds = [];

    this.deal.responsibles.map(responsible => {
      if (responsible.group) {
        dealResponsibleGroupIds.push(responsible.group.id);
      }
      if (responsible.user) {
        dealResponsibleUserIds.push(responsible.user.id);
      }
    });

    this.auditors.map(auditor => {
      if (auditor.users) {
        auditorGroupIds.push(auditor.id);
      }
      if (!auditor.users) {
        auditorUserIds.push(auditor.id);
      }
    });

    this.auditors.map((auditor: any) => {
      if ((auditor.users && dealResponsibleGroupIds.indexOf(auditor.id) === -1) || (!auditor.users && dealResponsibleUserIds.indexOf(auditor.id) === -1)) {
        this.dealService.addResponsible(this.deal.id, auditor).subscribe((added: Responsible) => {
          this.deal.responsibles.push(added);
        }, e => {
          this.errorService.addError.emit({
            title: 'Ошибка при сохранении аудиторов',
            message: e.error && e.error.message ? e.error.message : e.message
          });
        });
      }
    });

    this.deal.responsibles.map(responsible => {
      if ((responsible.group && auditorGroupIds.indexOf(responsible.group.id) === -1) || (responsible.user && auditorUserIds.indexOf(responsible.user.id) === -1)) {
        this.dealService.removeResponsible(responsible.id).subscribe(() => {
          this.deal.responsibles.splice(this.deal.responsibles.indexOf(responsible), 1);
        }, e => {
          this.errorService.addError.emit({
            title: 'Ошибка при сохранении аудиторов',
            message: e.error && e.error.message ? e.error.message : e.message
          });
        });
      }
    });
  }

  saveDeal() {
    if (!this.isDealValid) {
      return;
    }

    this.loaded = false;

    if (this.deal.id) {
      if (this.isClientContactEdited) {
        if (this.clientContact.id) {
          this.contactService.update(this.clientContact).subscribe(() => {
          });
        } else {
          this.createClientContact().subscribe(() => {
          });
        }
      }

      if (!this.possibleSaleDate.id && this.possibleSaleDate.value) {
        this.createPossibleSaleDateField().subscribe(() => {
        });
      }

      if (!this.checkListScan.id && this.files && this.files.length) {
        this.createCheckListScanField();
      }

      this.step = 2;
      this.loaded = true;
      return;
    }

    if (this.client.id) {
      if (this.isClientContactEdited) {
        if (this.clientContact.id) {
          this.contactService.update(this.clientContact).subscribe(() => this.createDeal());
        } else {
          this.createClientContact().subscribe(() => this.createDeal());
        }
      } else {
        this.createDeal();
      }
    } else {
      this.client.name = this.clientSearchComponent.query;
      this.client.manager = <User>{ id: this.deal.manager.id };
      this.client.type = new ClientType();
      this.client.type.id = CLIENT_TYPE_CLIENT_ID;

      this.clientService.create<Client>(this.client).subscribe(createdClient => {
        this.client = createdClient;
        this.createClientContact().subscribe(() => this.createDeal());
      });
    }
  }

  createClientContact() {
    this.clientContact.client = <Client>{ id: this.client.id };
    this.clientContact.isMain = true;
    return this.contactService.create<ClientContact>(this.clientContact).pipe(map(createdContact => {
      this.clientContact = createdContact;
      this.originalClientContact = { ...createdContact };
      return createdContact;
    }));
  }

  createDeal() {
    this.deal.client = this.client;
    this.deal.currentStatus = <DealStatus>{ id: AT_MANAGER_WORK__DEAL_STATUS_ID };
    this.dealService.create<Deal>(this.deal).subscribe(createdDeal => {
      this.auditors.map(auditor => {
        this.dealService.addResponsible(createdDeal.id, auditor).subscribe(() => {
        }, console.error);
      });
      this.deal = { ...createdDeal };
      if (this.possibleSaleDate.value) {
        this.createPossibleSaleDateField().subscribe(() => {
        });
      }
      if (this.files && this.files.length) {
        this.createCheckListScanField();
      }
      this.step = 2;
      this.loaded = true;
    });
  }

  createPossibleSaleDateField() {
    return this.dealService.fieldCreate(<DealFieldValue>{
      field: { id: this.possibleSaleDate.field.id },
      value: moment(this.possibleSaleDate.value).format('DD.MM.YYYY'),
      deal: { id: this.deal.id }
    });
  }

  changePossibleSaleDate() {
    if (this.possibleSaleDate.id) {
      this.dealService.fieldUpdate(<DealFieldValue>{
        id: this.possibleSaleDate.id,
        field: { id: this.possibleSaleDate.field.id },
        value: moment(this.possibleSaleDate.value).format('DD.MM.YYYY'),
        deal: { id: this.deal.id }
      }).subscribe(() => {
        this.editDate = false;
      });
    } else if (this.deal.id) {
      this.createPossibleSaleDateField().subscribe(() => {
        this.editDate = false;
      });
    } else {
      this.editDate = false;
    }
  }

  createCheckListScanField() {
    this.fileService.upload(this.files).then((f: FileInterface[]) => {
      this.dealService.fieldCreate(<DealFieldValue>{
        field: <DealField>{ id: this.checkListScan.field.id },
        value: [f[0]],
        deal: <Deal>{ id: this.deal.id }
      }).subscribe(() => {
        this.checkListScan.value = [];
        this.editFiles = false;
      });
    });
  }

  changeFiles(files: FileList) {
    this.files = files;
    if (!this.checkListScan.value) {
      this.checkListScan.value = [];
    }
    this.checkListScan.value.push(files[0]);

    if (this.deal.id) {
      this.addCheckListScan();
    }
  }

  addCheckListScan() {
    this.fileService.upload(this.files).then((f: FileInterface[]) => {
      this.dealService.fieldUpdate(<DealFieldValue>{
        id: this.checkListScan.id,
        field: <DealField>{ id: this.checkListScan.field.id },
        value: [...this.checkListScan.value, f[0]],
        deal: <Deal>{ id: this.deal.id }
      }).subscribe(() => {
        this.editFiles = false;
      });
    });
  }

  removeCheckListScan(file: File) {
    const confirm = window.confirm(`Вы уверены, что хотите удалить файл ${file.name}?`);
    if (confirm) {
      this.checkListScan.value.splice(this.checkListScan.value.indexOf(file), 1);
      this.dealService.fieldUpdate(<DealFieldValue>{
        id: this.checkListScan.id,
        deal: <Deal>{ id: this.deal.id },
        field: <DealField>{ id: this.checkListScan.field.id },
        value: this.checkListScan.value
      }).subscribe(() => {
      });
    }
  }

  saveCommunications() {
    if (this.isCurrentShowValid && this.isNextCommunicationValid) {
      this.loaded = false;
      let communicationsCounter = 0;
      this.currentShowTodo.deal = <Deal>{ id: this.deal.id };
      this.currentShowTodo.name = 'Показ';
      this.todoService.create<Todo>(this.currentShowTodo).subscribe((createdTodo) => {
        this.todoService.finish(createdTodo).subscribe(() => {
          communicationsCounter++;
          if (communicationsCounter === 2) {
            this.loaded = true;
            this.router.navigate(['/deal/card/', this.deal.id]);
          }
        });
      });

      this.nextCommunication.deal = <Deal>{ id: this.deal.id };
      this.todoService.create<Todo>(this.nextCommunication).subscribe(() => {
        communicationsCounter++;
        if (communicationsCounter === 2) {
          this.loaded = true;
          this.router.navigate(['/deal/card/', this.deal.id]);
        }
      });
    }
  }
}
