import { Component, EventEmitter, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Direction } from '../../../_models/direction.model';
import { BudgetService } from '../../../_services/budget.service';
import { DirectionService } from '../../../_services/direction.service';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'e2b-dashboard-director-budget',
  templateUrl: './dashboard-director-budget.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/statistic.min.css',
    '../../../../assets/design/semantic-ui/components/card.min.css',
    '../../../../assets/design/semantic-ui/components/image.min.css',
    '../../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../../assets/design/semantic-ui/components/list.min.css',
    './dashboard-director-budget.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardDirectorBudgetComponent implements OnInit {

  loaded = false;

  @Input()
  period = localStorage.getItem('e2b.dashboard.director.period') ? localStorage.getItem('e2b.dashboard.director.period') : 'month';

  @Input()
  day: Moment = localStorage.getItem('e2b.dashboard.director.date') ? moment(localStorage.getItem('e2b.dashboard.director.date'), 'YYYY-MM-DD') : moment();

  directions: Direction[] = [];

  selectedDirections: Direction[] = localStorage.getItem('e2b.dashboard.director.directions') ? JSON.parse(localStorage.getItem('e2b.dashboard.director.directions')) : [];

  typeChecker = false;

  options: {
    directionIds: number[],
    date: string,
    type: string
  } = {
    directionIds: [],
    date: this.day.format('YYYY-MM-DD'),
    type: localStorage.getItem('e2b.dashboard.director.type') ? localStorage.getItem('e2b.dashboard.director.type') : 'KASS'
  };
  budget: {
    day: [],
    week: [],
    month: []
  } = {
    day: [],
    week: [],
    month: []
  };
  innerWidth: number;

  constructor(public userService: UserService,
              private readonly budgetService: BudgetService,
              private readonly directionService: DirectionService) {
  }

  @Input()
  set dayEvent(event: EventEmitter<any>) {
    event.subscribe(day => {
      this.day = day;
      this.options.date = this.day.format('YYYY-MM-DD');
      this.load();
    });
  }

  @Input()
  set periodEvent(event: EventEmitter<any>) {
    event.subscribe(period => {
      this.period = period;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {

    this.innerWidth = window.innerWidth;

    this.directionService.findFlat<Direction>().subscribe(directions => {
      this.directions = directions;
    });

    this.load();
  }

  load() {

    this.options.directionIds = this.selectedDirections.map(d => d.id);

    this.budget = {
      day: [],
      week: [],
      month: []
    };

    this.budgetService.findBy(this.options).then(result => {
      this.budget = result;
      this.loaded = true;
    });
  }

  selectDirection(d: Direction) {
    let found = false;
    const r = [];
    this.selectedDirections.map((direction, k) => {
      if (direction.id === d.id) {
        found = true;
        this.selectedDirections.splice(k, 1);
      } else {
        r.push(direction);
      }
    });
    if (!found) {
      this.selectedDirections.push(d);
    }
    localStorage.setItem('e2b.dashboard.director.directions', JSON.stringify(this.selectedDirections));
    this.load();
  }

  selectType() {
    if (this.typeChecker && this.options.type === 'SALE') {
      return false;
    }
    if (!this.typeChecker && this.options.type === 'KASS') {
      return false;
    }
    if (this.typeChecker) {
      this.options.type = 'SALE';
    } else {
      this.options.type = 'KASS';
    }

    localStorage.setItem('e2b.dashboard.director.type', this.options.type);

    this.load();

  }

  public getQueryFrom() {
    switch (this.period) {
      case 'day':
        return this.day.clone().startOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().startOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().startOf('month').format('YYYY-MM-DD');
    }
  }

  public getQueryTo() {
    switch (this.period) {
      case 'day':
        return this.day.clone().endOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().endOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().endOf('month').format('YYYY-MM-DD');
    }
  }
}
