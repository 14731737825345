import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationTypeEnum } from '../_models/enums/notification-type.enum';
import { User } from '../_models/user.model';

import { BaseService } from './base.service';
import { FileInterface } from './file.service';

export interface NotificationUnreadedResultInterface {
  id: number;
  created: Date;
  type: NotificationTypeEnum;
  text: string;
  count: number;
  user: {
    id: number;
    name: string;
    email: string;
    avatar: FileInterface
  };
  deal: {
    id: number;
    name: string;
    client: {
      id: number;
      name: string;
    },
    filial: {
      id: number;
      name: string;
      color: string;
    };
    currentStatus: {
      id: number;
      name: string;
      color: string;
    }
  };
  task: {
    id: number;
    contragent: {
      id: number;
      name: string;
    };
    direction: {
      id: number;
      name: string;
    };
    amount: number;
  };
  readId: number;
}

export interface NotificationFindResultInterface extends NotificationUnreadedResultInterface {
  read: boolean;
}

@Injectable()
export class NotificationService extends BaseService {
  baseUrl = '/notification';

  unreaded(options?: {
    type?: NotificationTypeEnum[],
    limit?: number,
    offset?: number
  }): Observable<{ count: number; data: NotificationUnreadedResultInterface[] }> {
    const params = {};
    if (options) {
      Object.entries(options).map(o => {
        params[o[0]] = o[1];
      });
    }
    return this.http.get<{ count: number; data: NotificationUnreadedResultInterface[] }>(this.baseUrl + '/unreaded', {
      params,
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  findAll(options?: {
    type?: NotificationTypeEnum[],
    userId?: number[]
    limit?: number,
    offset?: number,
  }): Observable<{ count: number; data: NotificationFindResultInterface[]; users: User[] }> {
    const params = {};
    if (options) {
      Object.entries(options).map(o => {
        params[o[0]] = o[1];
      });
    }
    return this.http.get<{ count: number; data: NotificationFindResultInterface[]; users: User[] }>(this.baseUrl, {
      params,
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
