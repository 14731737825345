import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FileInterface } from '../../_services/file.service';

@Component({
  selector: 'e2b-user-avatar',
  templateUrl: './user-avatar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class UserAvatarComponent implements OnInit {

  @Input()
  public avatar: FileInterface;

  constructor() {
  }

  ngOnInit() {
  }

}
