import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SuiModule} from 'ng2-semantic-ui';
import {LoggedInUserGuard} from '../_guards/logged-in-user.guard';
import {PermissionGuard} from '../_guards/permission.guard';
import {SharedModule} from '../shared/shared.module';
import {ClothListComponent} from './cloth-list/cloth-list.component';
import {DealFieldListComponent} from './deal-field-list/deal-field-list.component';
import {MaterialListComponent} from './material-list/material-list.component';
import {PersonalListComponent} from './personal-list/personal-list.component';
import {PlanListComponent} from './plan-list/plan-list.component';
import {PositionAddComponent} from './position-add/position-add.component';
import {PositionGroupAddComponent} from './position-group-add/position-group-add.component';
import {PositionGroupListComponent} from './position-group-list/position-group-list.component';
import {PositionGroupTemplateDropdownComponent} from './position-group-template-dropdown/position-group-template-dropdown.component';
import {PositionListComponent} from './position-list/position-list.component';
import {PositionTemplateAddComponent} from './position-template-add/position-template-add.component';
import {PostionGroupTemplateFieldDropdownComponent} from './postion-group-template-field-dropdown/postion-group-template-field-dropdown.component';
import {PositionTemplateListComponent} from './postition-template-list/postition-template-list.component';
import {ProblemListComponent} from './problem-list/problem-list.component';
import {ReclamationListComponent} from './reclamation-list/reclamation-list.component';
import {DealStepListComponent} from './deal-step/deal-step-list/deal-step-list.component';
import {AssemblySchemeListComponent} from './assembly-scheme/assembly-scheme-list/assembly-scheme-list.component';
import {AssemblySchemeShowComponent} from './assembly-scheme/assembly-scheme-show/assembly-scheme-show.component';
import {DealStepEditComponent} from './deal-step/deal-step-edit/deal-step-edit.component';
import {DealStepComponent} from './deal-step/deal-step.component';
import {AssemblySchemeComponent} from './assembly-scheme/assembly-scheme.component';
import {AssemblyStepEditComponent} from './assembly-scheme/assembly-step-edit/assembly-step-edit.component';
import {PositionShowComponent} from './position-show/position-show.component';

const routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  },
  {
    path: 'list',
    component: PositionListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['position', '/'],
      title: 'Olissys E2B | Список товаров',
      breadcrumb: 'Список товаров'
    }
  },
  {
    path: 'position/:id/edit',
    component: PositionShowComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['position', '/'],
      title: 'Olissys E2B | Товар',
      breadcrumb: 'Редактирование товара'
    }
  },
  {
    path: 'add',
    component: PositionAddComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['position', '/'],
      title: 'Olissys E2B | Добавить товар',
      breadcrumb: 'Добавить товар'
    }
  },
  {
    path: 'group/list',
    component: PositionGroupListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['position-group', '/'],
      title: 'Olissys E2B | Список групп',
      breadcrumb: 'Список групп'
    }
  },
  {
    path: 'group/add',
    component: PositionGroupAddComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['position-group', '/'],
      title: 'Olissys E2B | Новая группа товаров',
      breadcrumb: 'Новая группа товаров'
    }
  },
  {
    path: 'template/list',
    component: PositionTemplateListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['position-group-template', '/'],
      title: 'Olissys E2B | Список шаблонов',
      breadcrumb: 'Список шаблонов'
    }
  },
  {
    path: 'template/add',
    component: PositionTemplateAddComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['position-group-template', '/'],
      title: 'Olissys E2B | Новый шаблон',
      breadcrumb: 'Новый шаблон'
    }
  },
  {
    path: 'material/list',
    component: MaterialListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['position-material', '/'],
      title: 'Olissys E2B | Материалы',
      breadcrumb: 'Материалы'
    }
  },
  {
    path: 'cloth/list',
    component: ClothListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['position-cloth', '/'],
      title: 'Olissys E2B | Ткани',
      breadcrumb: 'Ткани'
    }
  },
  {
    path: 'field/list',
    component: DealFieldListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['deal-field', '/'],
      title: 'Olissys E2B | Поля',
      breadcrumb: 'Поля'
    }
  },
  {
    path: 'personal/list',
    component: PersonalListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['position-personal', '/'],
      title: 'Olissys E2B | Индивидуальные изменения',
      breadcrumb: 'Индивидуальные изменения'
    }
  },
  {
    path: 'problem/list',
    component: ProblemListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['problem', '/'],
      title: 'Olissys E2B | Проблемы',
      breadcrumb: 'Проблемы'
    }
  },
  {
    path: 'reclamation/list',
    component: ReclamationListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['reclamation', '/'],
      title: 'Olissys E2B | Рекламации',
      breadcrumb: 'Рекламации'
    }
  },
  {
    path: 'plan/list',
    component: PlanListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['manager-plan', '/'],
      title: 'Olissys E2B | План менеджера',
      breadcrumb: 'План менеджера'
    }
  },
  {
    path: 'deal-step',
    redirectTo: 'deal-step/list'
  },
  {
    path: 'deal-step',
    component: DealStepComponent,
    data: {
      title: 'Olissys E2B | Схемы приложения',
      breadcrumb: 'Схемы приложения'
    },
    children: [{
      path: 'list',
      component: DealStepListComponent,
      canActivate: [LoggedInUserGuard, PermissionGuard],
      data: {
        access: ['deal-step', '/'],
        title: 'Olissys E2B | Схемы приложения',
        breadcrumb: 'Список'
      }
    }, {
      path: ':id/edit',
      component: DealStepEditComponent,
      canActivate: [LoggedInUserGuard, PermissionGuard],
      data: {
        access: ['deal-step', '/'],
        title: 'Olissys E2B | Редактирование схемы приложения',
        breadcrumb: 'Редактирование'
      }
    }]
  },
  {
    path: 'assembly-scheme',
    redirectTo: 'assembly-scheme/list'
  },
  {
    path: 'assembly-scheme',
    component: AssemblySchemeComponent,
    data: {
      title: 'Olissys E2B | Схемы сборки',
      breadcrumb: 'Схемы сборки'
    },
    children: [{
      path: 'list',
      component: AssemblySchemeListComponent,
      canActivate: [LoggedInUserGuard, PermissionGuard],
      data: {
        access: ['assembly-scheme', '/'],
        title: 'Olissys E2B | Схемы сборки',
        breadcrumb: 'Список'
      }
    },
      {
        path: ':id/show',
        component: AssemblySchemeShowComponent,
        canActivate: [LoggedInUserGuard, PermissionGuard],
        data: {
          access: ['assembly-scheme', '/'],
          title: 'Olissys E2B | Схема сборки',
          breadcrumb: 'Карточка'
        }
      },
      {
        path: 'step/:id/edit',
        component: AssemblyStepEditComponent,
        canActivate: [LoggedInUserGuard, PermissionGuard],
        data: {
          access: ['assembly-scheme', '/'],
          title: 'Olissys E2B | Схема сборки',
          breadcrumb: 'Редактирование этапа'
        }
      }]
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SuiModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    PositionListComponent,
    PositionAddComponent,
    PositionShowComponent,
    PositionGroupListComponent,
    PositionTemplateListComponent,
    PositionGroupAddComponent,
    PositionTemplateAddComponent,
    PostionGroupTemplateFieldDropdownComponent,
    PositionGroupTemplateDropdownComponent,
    MaterialListComponent,
    DealFieldListComponent,
    ClothListComponent,
    PersonalListComponent,
    ProblemListComponent,
    ReclamationListComponent,
    PlanListComponent,
    DealStepComponent,
    DealStepListComponent,
    DealStepEditComponent,
    AssemblySchemeListComponent,
    AssemblySchemeShowComponent,
    AssemblyStepEditComponent,
    AssemblySchemeComponent
  ],
  exports: []
})
export class CatalogModule {
}
