<div class="ui fluid container">
  <div style="position: absolute; right: 60px; top: 0;height: 86px;z-index: 999;">
    <div class="ui right aligned basic text menu" *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']">
      <div class="ui right dropdown item" suiDropdown>
        <i class="big cog icon"></i>
        <div class="menu" suiDropdownMenu>
          <a class="item">
            <sui-checkbox [(ngModel)]="showDirector"
                          [isDisabled]="!userService.checkPermissions(['widget', 'director', '/'])"
                          [ngModelOptions]="{updateOn: 'blur'}"
                          (ngModelChange)="changeSetting('user.settings.dashboard.show.director', showDirector)"
                          [name]="'settingsShowDirector'">Директор
            </sui-checkbox>
          </a>
          <a class="item">
            <sui-checkbox [(ngModel)]="showTodo"
                          [isDisabled]="!userService.checkPermissions(['todo', 'type', '/'])"
                          [ngModelOptions]="{updateOn: 'blur'}"
                          (ngModelChange)="changeSetting('user.settings.dashboard.show.todo', showTodo)"
                          [name]="'settingsShowTodo'">Продажи
            </sui-checkbox>
          </a>
          <a class="item">
            <sui-checkbox [(ngModel)]="showMountingCoordinator"
                          [ngModelOptions]="{updateOn: 'blur'}"
                          [isDisabled]="!userService.checkPermissions(['controlpoint', 'summary', '/'])"
                          (ngModelChange)="changeSetting('user.settings.dashboard.show.mounting.coordinator', showMountingCoordinator)"
                          [name]="'settingsShowTodo'">Координатор монтажей
            </sui-checkbox>
          </a>
          <a class="item">
            <sui-checkbox [(ngModel)]="showDeliveryCoordinator"
                          [ngModelOptions]="{updateOn: 'blur'}"
                          [isDisabled]="!userService.checkPermissions(['controlpoint', 'summary', '/'])"
                          (ngModelChange)="changeSetting('user.settings.dashboard.show.delivery.coordinator', showDeliveryCoordinator)"
                          [name]="'settingsShowTodo'">Координатор доставок
            </sui-checkbox>
          </a>
          <a class="item">
            <sui-checkbox [(ngModel)]="showReclamation"
                          [ngModelOptions]="{updateOn: 'blur'}"
                          [isDisabled]="!userService.checkPermissions(['controlpoint', 'summary', '/'])"
                          (ngModelChange)="changeSetting('user.settings.dashboard.show.reclamation', showReclamation)"
                          [name]="'settingsShowTodo'">Менеджер рекламаций
            </sui-checkbox>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="ui stackable grid" *ngIf="showSwitch || checkSwitch()">
    <div class="ui four wide column">
      <div *ngIf="!checkSwitch()">
        <div *ngIf="userService.checkPermissions(['user', 'switch', '/'])">
          <div style="padding-bottom: 10px !important"><b>Переключение пользователя:</b></div>
          <e2b-staff-simple-dropdown [staff]="users" [event]="selectUserEvent"></e2b-staff-simple-dropdown>
        </div>
      </div>
      <div style="min-width: 200px; vertical-align: bottom !important; margin-left: 10px !important;margin-right: 10px !important;" *ngIf="checkSwitch()">
        <a class="cursor" (click)="switchAdmin()"><- Вернуть пользователя</a>
      </div>
    </div>
  </div>
  <br/>
  <div class="ui doubling stackable grid">
    <div class="ui sixteen wide column" *ngIf="showDirector && userService.checkPermissions(['widget', 'director', '/'])">
      <e2b-dashboard-director></e2b-dashboard-director>
    </div>
    <div class="ui sixteen wide column" *ngIf="showTodo && userService.checkPermissions(['todo', 'type', '/'])">
      <e2b-dashboard-sale></e2b-dashboard-sale>
    </div>
    <div class="ui sixteen wide column" *ngIf="showMountingCoordinator && userService.checkPermissions(['controlpoint', 'summary', '/'])">
      <e2b-dashboard-controlpoint [componentLabel]="'КООРДИНАТОР МОНТАЖЕЙ'" [componentName]="'mounting.coordinator'" [userGroupId]="9" [controlpointId]="1"></e2b-dashboard-controlpoint>
    </div>
    <div class="ui sixteen wide column" *ngIf="showDeliveryCoordinator && userService.checkPermissions(['controlpoint', 'summary', '/'])">
      <e2b-dashboard-controlpoint [componentLabel]="'КООРДИНАТОР ДОСТАВОК'" [componentName]="'delivery.coordinator'" [userGroupId]="6" [controlpointId]="2"></e2b-dashboard-controlpoint>
    </div>
    <div class="ui sixteen wide column" *ngIf="showReclamation && userService.checkPermissions(['controlpoint', 'summary', '/'])">
      <e2b-dashboard-controlpoint [componentLabel]="'МЕНЕДЖЕР РЕКЛАМАЦИЙ'" [componentName]="'reclamation'" [userGroupId]="26" [controlpointId]="3"></e2b-dashboard-controlpoint>
    </div>
  </div>
</div>
<div class="clearfix"></div>
<div class="ui fluid container" *ngIf="userService.checkPermissions(['production', 'deals', '/'])">
  <e2b-dashboard-production [reload]="reload"></e2b-dashboard-production>
</div>
