import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { UserGroup } from '../../_models/user-group.model';
import { User } from '../../_models/user.model';
import { PlanService } from '../../_services/plan.service';
import { UserGroupService } from '../../_services/user-group.service';
import { UserHolidayService } from '../../_services/user-holiday.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class PlanListComponent implements OnInit {

  @ViewChild('date', { static: false })
  date: ElementRef;

  day = localStorage.getItem('e2b.staff.plan') ? moment(localStorage.getItem('e2b.staff.plan'), 'YYYY-MM') : moment();

  users: User[] = [];

  user: User = null;

  data: {
    sale: {
      id?: number,
      value: number
    },
    todo: {
      id: number,
      name: string,
      plan: {
        id?: number,
        value: number
      }
    }[]
  } = {
    sale: {
      value: 0
    },
    todo: []
  };
  loaded = false;
  holiday: { workdays: number; holidays: number } = {
    workdays: 0,
    holidays: 0
  };

  constructor(private actionService: ActionService,
              private userService: UserService,
              private userHolidayService: UserHolidayService,
              private userGroupService: UserGroupService,
              private planService: PlanService) {
  }

  add() {

  }

  async load() {

    if (!this.user) {
      this.users.map(user => {
        if (user.id === this.userService.current().id) {
          this.user = user;
        }
      });
    }

    if (this.user) {
      this.planService.find<any>({ from: this.day.clone().format('YYYY-MM'), user: this.user.id }).subscribe((data: any) => {
        this.data = data;
      });
      this.userHolidayService.findByUserId({ id: this.user.id, date: this.day.clone().format('YYYY-MM') }).subscribe(data => {
        this.holiday = data;
      });
    }

    this.loaded = true;
  }

  async ngOnInit() {

    const group = await this.userGroupService.findById<UserGroup>(28).toPromise();

    this.users = group.users;

    const updateEvent = new EventEmitter();

    if (this.userService.checkPermissions(['manager-plan', '/'], ['write'])) {
      this.actionService.createActionEvent.emit({
        name: 'Обновить',
        event: updateEvent,
        class: 'blue',
        icon: 'sync'
      });
    }

    updateEvent.subscribe(() => {
      this.load().catch(console.error);
    });

    this.load().catch(console.error);
  }

  selectUser() {
    const event = new EventEmitter();
    event.subscribe(user => {
      this.user = user;
      this.load().catch(console.error);
    });
    return event;
  }

  save(data) {
    const update: any = {
      type: 'SALE',
      user: { id: this.user.id },
      date: this.day.clone().startOf('month').format('YYYY-MM-DD'),
      value: data.value
    };

    if (data.id) {
      update.id = data.id;
      this.planService.update(update).subscribe(() => {
      });
    } else {
      this.planService.create<any>(update).subscribe(created => {
        data.id = created.id;
      });
    }
  }

  saveTodo(id, data) {
    const update: any = {
      type: 'TODO',
      todoType: id,
      user: { id: this.user.id },
      date: this.day.clone().startOf('month').format('YYYY-MM-DD'),
      value: data.value
    };

    if (data.id) {
      update.id = data.id;
      this.planService.update(update).subscribe(() => {
      });
    } else {
      this.planService.create<any>(update).subscribe(created => {
        data.id = created.id;
      });
    }
  }

  nextDay() {
    this.day = this.day.clone().add(1, 'month');
    localStorage.setItem('e2b.staff.plan', this.day.format('YYYY-MM'));
    this.load().catch(console.error);
  }

  prevDay() {
    this.day = this.day.clone().subtract(1, 'month');
    localStorage.setItem('e2b.staff.plan', this.day.format('YYYY-MM'));
    this.load().catch(console.error);
  }
}
