<h3 class="fw-bold header">Бюджет
  <div class="ui dropdown" suiDropdown style="display: inline !important;" *ngIf="directions.length > 0">
    <i class="blue cog icon"></i>
    <div class="menu" suiDropdownMenu>
      <ng-container *ngFor="let d of directions | orderByColumn:'name:string'">
        <a class="item">
          <div class="field">
            <input type="checkbox" [checked]="options.directionIds.includes(d.id)" (change)="selectDirection(d)"/>
            <label class="fw-bold"> {{d.name}}</label>
          </div>
        </a>
        <ng-container *ngFor="let c of d.childrens | orderByColumn:'name:string'">
          <a class="item" style="margin-left: 10px;">
            <div class="field">
              <input type="checkbox" [checked]="options.directionIds.includes(c.id)" (change)="selectDirection(c)"/>
              <label> {{c.name}}</label>
            </div>
          </a>
          <ng-container *ngFor="let ch of c.childrens | orderByColumn:'name:string'">
            <a class="item" style="margin-left: 20px;">
              <div class="field">
                <input type="checkbox" [checked]="options.directionIds.includes(ch.id)" (change)="selectDirection(ch)"/>
                <label> {{ch.name}}</label>
              </div>
            </a>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="field" style="margin-left: 18px; display: inline !important;">
    <label style="font-size: 14px !important;padding-right: 16px !important;">Касса</label>
    <sui-checkbox class="slider" [(ngModel)]="typeChecker" (ngModelChange)="selectType()">Начисления</sui-checkbox>
  </div>
</h3>
<div class="ui stackable cards" [ngClass]="{'three': innerWidth <= 1280, 'four': innerWidth > 1280, 'six': innerWidth > 1620}">
  <div class="ui coordinator card" *ngFor="let d of budget[period]">
    <div class="content">
      <div class="header">
        {{d.direction.name}} <i class="ui grey bullseye icon"></i> <i class="ui grey calendar outline icon"></i>
      </div>
      <div class="description">
        <div class="ui mini statistic">
          <div class="value">
            <span suiPopup popupInverted popupText="План" class="padded">{{d.plan | humanNumber}}</span>
            <a class="padded" suiPopup popupInverted popupText="Факт" target="_blank" [routerLink]="['/report','pnl']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), directionId: [d.direction.id]}">{{d.fact | humanNumber}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
