import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation } from 'angular-animations';
import * as moment from 'moment';
import { Moment } from 'moment';
import { UserGroup } from '../../_models/user-group.model';
import { User } from '../../_models/user.model';
import { ControlpointService } from '../../_services/controlpoint.service';
import { TodoCheckpointService } from '../../_services/todo-checkpoint.service';
import { UserGroupService } from '../../_services/user-group.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'e2b-dashboard-controlpoint',
  templateUrl: './dashboard-controlpoint.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/statistic.min.css',
    '../../../assets/design/semantic-ui/components/card.min.css',
    '../../../assets/design/semantic-ui/components/list.min.css',
    './dashboard-controlpoint.component.css'],
  animations: [
    fadeInUpOnEnterAnimation({ duration: 200 }),
    fadeOutDownOnLeaveAnimation({ duration: 200 })
  ],
  encapsulation: ViewEncapsulation.None
})
export class DashboardControlpointComponent implements OnInit {

  @Input()
  componentLabel: string;
  @Input()
  componentName: string;
  @Input()
  controlpointId: number;
  data: {
    id: number,
    name: string,
    new: number,
    all: number,
    overdue: number,
    sort: number
  }[] = [];
  @ViewChild('date', { static: false })
  date: ElementRef;
  day: Moment = null;
  deliveryStatus = {
    'Не принят': {
      color: 'grey'
    },
    'Принят': {
      color: 'violet'
    },
    'Загружен': {
      color: 'purple'
    },
    'Начало погрузки': {
      color: 'teal'
    },
    'Доставлено': {
      color: 'green'
    },
    'Выгружен': {
      color: 'olive'
    }
  };
  detailData: {
    id: number,
    name: string,
    created: string,
    filial: {
      id: number,
      name: string,
      color: string,
    },
    client: {
      id: number,
      name: string,
      phone: string
    },
    mountingDate: string,
    mountingDatetime: string,
    mountingUser: User,
    mountingStatus: { name: string, status: string }[],
    deliveryDatetime: string,
    deliveryUser: User,
    deliveryStatus: 'Не принят' | 'Принят' | 'Загружен' | 'Начало погрузки' | 'Доставлено' | 'Выгружен',
    deliveryProblems: string[],
    dealMountingStatus: string,
    step: number,
    positions: {
      id: number,
      name: string,
      step: number,
      problems: [],
      date: string,
      status: string,
      productionDate: string
    }[],
    checkpoint: {
      id: number,
      name: string,
      group: string,
      from: string,
      until: string,
      type: string,
      data: any,
      done: boolean
    }
  }[] = [];
  detailOptions: {
    date?: string,
    userId?: number,
    status?: 'NEW' | 'ALL' | 'OVERDUE';
    controlpointId?: number
  } = {};
  fields: {
    [key: number]: string[]
  } = {
    1: ['Дата монтажа', 'Дата и время монтажа', 'Дата и время доставки'],
    2: ['Дата монтажа'],
    3: ['Дата монтажа', 'Дата и время монтажа', 'Монтажник'],
    4: ['Дата монтажа', 'Позиции'],
    5: ['Дата и время доставки', 'Доставщик', 'Статус доставки'],
    6: ['Дата и время монтажа', 'Монтажник', 'Статус монтажа'],
    7: ['Дата монтажа', 'Монтажник', 'Доставщик', 'Проблема'],
    8: ['Дата монтажа', 'Дата и время монтажа', 'Дата и время доставки'],
    9: ['Дата монтажа', 'Монтажник', 'Доставщик', 'Проблема'],
    10: ['Дата монтажа', 'Дата и время доставки', 'Доставщик'],
    11: [],
    12: [],
  };
  loaded = false;
  mountingStatus = {
    0: {
      color: 'grey',
      code: 0,
      description: 'НЕ ПРИНЯТ'
    },
    1801: {
      color: 'violet',
      code: 1801,
      description: 'ПРИНЯТ'
    },
    1802: {
      color: 'purple',
      code: 1802,
      description: 'ПРИБЫЛ'
    },
    1803: {
      color: 'teal',
      code: 1803,
      description: 'ПЛОЩАДКА ГОТОВА'
    },
    1804: {
      color: 'teal',
      code: 1804,
      description: 'ОСМОТР'
    },
    1805: {
      color: 'green',
      code: 1805,
      description: 'НАЧАЛО МОНТАЖА'
    },
    1806: {
      color: 'brown',
      code: 1806,
      description: 'МОНТАЖ ЗАВЕРШЕН'
    },
    1807: {
      color: 'olive',
      code: 1807,
      description: 'АКТ СДАН'
    },
    1808: {
      color: 'blue',
      code: 1808,
      description: 'ДЕНЬГИ СДАНЫ'
    },
    1810: {
      color: 'blue',
      code: 1810,
      description: 'МОНТАЖ НАЧАЛСЯ'
    },
    1811: {
      color: 'orange',
      code: 1811,
      description: 'СОБРАН КАРКАС'
    },
    1812: {
      color: 'yellow',
      code: 1812,
      description: 'ПРОВЕДЕНА ЭЛЕКТРИКА'
    },
    1813: {
      color: 'teal',
      code: 1813,
      description: 'КАРКАС ЗАКРЕПЛЕН'
    },
    1814: {
      color: 'olive',
      code: 1814,
      description: 'КАРКАС КРОВАТИ'
    },
    1815: {
      color: 'green',
      code: 1815,
      description: 'МОНТАЖ ОКОНЧЕН'
    }
  };
  options: {
    id: number,
    date: string,
    userId: number,
  } = {
    id: null,
    date: null,
    userId: 0
  };
  productionStatus = {
    'НЕ ПРИНЯТ': {
      color: 'grey'
    },
    'ПРИНЯТ': {
      color: 'violet'
    },
    'ГОТОВ': {
      color: 'purple'
    },
    'ОТГРУЖЕН': {
      color: 'olive'
    }
  };
  selectUser: EventEmitter<User> = new EventEmitter<User>();
  selectedControlpoint: {
    id: number,
    name: string,
    new: number,
    all: number,
    overdue: number,
    sort: number
  } = null;
  selectedDate;
  selectedUser: User;
  selectedWidgets: number[];
  @Input()
  userGroupId: number;
  users: User[] = [];

  constructor(private userService: UserService,
              private userGroupService: UserGroupService,
              private controlpointService: ControlpointService,
              private todoCheckpointService: TodoCheckpointService) {
  }

  getData() {

    this.data = [];

    this.controlpointService.summary(this.options).subscribe(data => {
      this.data = data;
      this.selectedWidgets = localStorage.getItem(`e2b.dashboard.controlpoint${this.controlpointId}.widgets`)
        ? JSON.parse(localStorage.getItem(`e2b.dashboard.controlpoint${this.controlpointId}.widgets`))
        : this.data.map(widget => widget.id);
    });

  }

  getIcon(type) {
    if (['COMMENT_PRODUCTION_DEVIATION', 'COMMENT_QUALITY_CONTROL'].indexOf(type) > -1) {
      return 'comment alternate outline';
    } else if (['CALL_CLIENT'].indexOf(type) > -1) {
      return 'phone volume';
    } else {
      return 'edit';
    }
  }

  markAsDone(deal) {
    const comment = prompt('Добавьте комментарий в сделку:');

    if (!comment || comment === '') {
      return false;
    }

    this.todoCheckpointService.done(deal.checkpoint.id, { value: comment }).subscribe(r => {
      this.getData();
      this.onSelectTodo(this.selectedControlpoint, this.detailOptions.status);
    });
  }

  nextDay() {
    this.day = this.day.add(1, 'd');
    this.options.date = this.day.format('YYYY-MM-DD');
    this.detailOptions = {};
    this.selectedControlpoint = null;
    localStorage.setItem('e2b.dashboard.' + this.componentName + '.date', this.options.date);
    this.getData();
  }

  ngOnInit() {
    this.day = localStorage.getItem('e2b.dashboard.' + this.componentName + '.date') ? moment(localStorage.getItem('e2b.dashboard.' + this.componentName + '.date'), 'YYYY-MM-DD') : moment();
    this.options.id = this.controlpointId;
    this.options.date = this.day.format('YYYY-MM-DD');
    this.userGroupService.findById<UserGroup>(this.userGroupId).subscribe(group => {
      this.users = group.users;
      if (this.users.find(u => u.id === this.userService.current().id)) {
        this.options.userId = this.userService.current().id;
        this.onSelectUser(this.users.find(u => u.id === this.userService.current().id));
      } else {
        this.onSelectUser(this.users[0]);
      }

      this.selectUser.subscribe(user => {
        this.onSelectUser(user);
        this.getData();
      });

      this.loaded = true;

      this.getData();
    });
  }

  onDate() {
    this.date.nativeElement.click();
  }

  onSelectDate($event) {
    if (!$event) {
      return false;
    }
    this.day = moment($event);
    this.options.date = this.day.format('YYYY-MM-DD');
    this.detailOptions = {};
    this.selectedControlpoint = null;
    localStorage.setItem('e2b.dashboard.' + this.componentName + '.date', this.options.date);
    this.getData();
  }

  onSelectTodo(cp, status) {

    if (this.selectedControlpoint && this.selectedControlpoint.id === cp.id && this.detailOptions.status === status) {
      this.selectedControlpoint = null;
      this.detailOptions = {};
    } else {
      this.selectedControlpoint = cp;
      this.detailOptions.date = this.options.date;
      this.detailOptions.controlpointId = cp.id;
      this.detailOptions.status = status;
      this.detailOptions.userId = this.options.userId;

      this.controlpointService.findDetail(cp.id, { date: this.options.date, userId: this.detailOptions.userId, status: this.detailOptions.status }).subscribe(data => {
        this.detailData = data;
      });
    }
  }

  onSelectUser(user) {
    this.selectedUser = user;
    this.options.userId = user.id;
    this.selectedControlpoint = null;
    this.detailOptions = {};
  }

  prevDay() {
    this.day = this.day.subtract(1, 'd');
    this.options.date = this.day.format('YYYY-MM-DD');
    this.detailOptions = {};
    this.selectedControlpoint = null;
    localStorage.setItem('e2b.dashboard.' + this.componentName + '.date', this.options.date);
    this.getData();
  }

  selectWidget(widgetId) {
    if (this.selectedWidgets.includes(widgetId)) {
      const widgetIndex = this.selectedWidgets.indexOf(widgetId);
      this.selectedWidgets.splice(widgetIndex, 1);
    } else {
      this.selectedWidgets.push(widgetId);
    }
    this.selectedWidgets = [...this.selectedWidgets];
    localStorage.setItem(`e2b.dashboard.controlpoint${this.controlpointId}.widgets`, JSON.stringify(this.selectedWidgets));
  }
}
