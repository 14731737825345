import { EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  inactiveMinutes = 0;
  userBecomeActive = new EventEmitter<any>();

  inactivityInterval: NodeJS.Timeout;

  constructor() {
    this.inactivityInterval = setInterval(() => this.inactiveMinutes++, 60000);
  }

  resetActivity() {
    if (this.inactiveMinutes > 2) {
      this.userBecomeActive.emit();
    }
    this.inactiveMinutes = 0;
  }
}
