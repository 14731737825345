<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui text menu">
        <div class="item">* Бонус М1 - монтажник, один товар в заказе</div>
        <div class="item">* Бонус М2 - монтажник, много товаров в заказе</div>
        <div class="item">* Бонус П1 - производство</div>
        <div class="right item">
          <span class="fw-bold">Найдено: {{positions.length}} товаров</span>
        </div>
      </div>
      <div class="ui form">
        <table class="ui very basic celled table">
          <thead>
          <tr>
            <th></th>
            <th>
              <div class="head">Название</div>
            </th>
            <th>
              <div class="head">Тип</div>
            </th>
            <th>
              <div class="head">Группа</div>
            </th>
            <th>
              <div class="head">Схема сборки</div>
            </th>
            <th>
              <div class="head">Артикул</div>
            </th>
            <th>
              <div class="head">Цена</div>
            </th>
            <th>
              <div class="head">Бонус менеджера</div>
            </th>
            <th>
              <div class="head">Бонус М1</div>
            </th>
            <th>
              <div class="head">Бонус М2</div>
            </th>
            <th>
              <div class="head">Бонус П1</div>
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngFor="let position of positions | orderByColumn:'name:string'">
          <tr>
            <th>
              <i class="ui list blue cursor icon" [routerLink]="['/catalog', 'position', position.id, 'edit']" title="Изменить"></i>
            </th>
            <td width="20%">
              <div class="field">
                <div class="ui input">
                  <input type="text" [disabled]="!userService.checkPermissions(['position', '/'], ['write'])" [(ngModel)]="position.name" (change)="save(position)">
                </div>
              </div>
            </td>
            <td>
              <div class="field">
                <select [disabled]="!userService.checkPermissions(['position', '/'], ['write'])" [(ngModel)]="position.type" (change)="save(position)">
                  <option [selected]="position.type === 0" value="0">Нет</option>
                  <option [selected]="position.type === 1" value="1">Корпусная мебель</option>
                  <option [selected]="position.type === 2" value="2">Мягкая мебель</option>
                  <option [selected]="position.type === 3" value="3">Матрас</option>
                  <option [selected]="position.type === 4" value="4">Кастомизация</option>
                </select>
              </div>
            </td>
            <td width="10%" class="pointer">
              <div class="ui label w-100 text-center"
                   *ngIf="position.group"
                   [innerText]="position.group.name"></div>
            </td>
            <td width="10%" class="pointer">
              <div class="ui label w-100 text-center"
                   *ngIf="position.assemblyScheme"
                   [innerText]="position.assemblyScheme.name"></div>
            </td>
            <td>
              <div class="field">
                <input type="text" [disabled]="!userService.checkPermissions(['position', '/'], ['write'])" [(ngModel)]="position.article" (change)="save(position)">
              </div>
            </td>
            <td>
              <div class="field">
                <input type="number" [disabled]="!userService.checkPermissions(['position', '/'], ['write'])" [(ngModel)]="position.cost" (change)="save(position)">
              </div>
            </td>
            <td>
              <div class="field">
                <input type="number" [disabled]="!userService.checkPermissions(['position', '/'], ['write'])" [(ngModel)]="position.priceMN" (change)="save(position)">
              </div>
            </td>
            <td>
              <div class="field">
                <input type="number" [disabled]="!userService.checkPermissions(['position', '/'], ['write'])" [(ngModel)]="position.priceM1" (change)="save(position)">
              </div>
            </td>
            <td>
              <div class="field">
                <input type="number" [disabled]="!userService.checkPermissions(['position', '/'], ['write'])" [(ngModel)]="position.priceM2" (change)="save(position)">
              </div>
            </td>
            <td>
              <div class="field">
                <input type="number" [disabled]="!userService.checkPermissions(['position', '/'], ['write'])" [(ngModel)]="position.priceP1" (change)="save(position)">
              </div>
            </td>
            <td>
              <i class="ui red remove cursor icon" (click)="remove(position)"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
