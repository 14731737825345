<ng-template let-option #personOptionTemplate>
  <div class="mininame">{{option.name}}</div>
  <div class="miniprice">{{option.type ? option.type.name : ''}}</div>
  <div class="minitext">{{option.inn}}</div>
</ng-template>
<sui-select class="fluid selection"
            placeholder="Плательщик"
            [isSearchable]="true"
            [isDisabled]="false"
            [(ngModel)]="selectedPerson"
            [ngModelOptions]="{name: 'person'}"
            (ngModelChange)="save()"
            [options]="persons"
            labelField="name"
            [optionTemplate]="personOptionTemplate"
            #selectPerson>
  <sui-select-option *ngFor="let person of selectPerson.filteredOptions | orderByColumn:'name:string'"
                     [value]="person">
  </sui-select-option>
</sui-select>
