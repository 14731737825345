<sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
  <div class="ui text loader">Загрузка...</div>
</sui-dimmer>
<div class="ui fluid basic card" style="margin-top: 0 !important;">
  <div class="content">
    <div class="header">
      <div class="d-flex justify-content-center align-items-center">
        <button class="ui button" (click)="prevDay();">
          <i class="angle left icon"></i>
        </button>
        &nbsp;&nbsp;
        <h3 class="fw-bold m-0 header">
          <input suiDatepicker
                 #date
                 style="width: 0 !important; height: 0 !important; border: 0 !important; padding: 0 !important;"
                 [(ngModel)]="selectedDate"
                 (ngModelChange)="onSelectDate($event)"
                 [pickerMode]="'date'"
                 [pickerUseNativeOnMobile]="true">
          ПРОДАЖИ&nbsp;&nbsp;<a (click)="onDate()">{{day.format('DD MMM, YY г.')}}</a>&nbsp;&nbsp;
        </h3>
        <e2b-staff-simple-dropdown class="fsz-14px" [staff]="users" [selected]="selectedUser" [event]="selectUser"></e2b-staff-simple-dropdown>
        &nbsp;&nbsp;
        <button class="ui button" (click)="nextDay();">
          <i class="angle right icon"></i>
        </button>
        <div class="ui dropdown ml-10px" suiDropdown style="display: inline !important;">
          <i class="blue cog icon"></i>
          <div class="menu" suiDropdownMenu>
            <a class="item" *ngFor="let widgetName of allWidgets">
              <div class="field">
                <input type="checkbox" [checked]="selectedWidgets.includes(widgetName)" (change)="selectWidget(widgetName)"/>
                <label> {{widgets[widgetName]}}</label>
              </div>
            </a>
            <a class="item" *ngFor="let val of data.todos | orderByColumn:'name:string'">
              <div class="field">
                <input type="checkbox" [checked]="selectedTodos.includes(val.id)" (change)="selectTodo(val.id)"/>
                <label> {{val.name}}</label>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui stackable grid">
  <div class="ui five wide column"></div>
  <div class="ui six wide column">
    <div class="ui secondary three item pointing stackable menu">
      <a class="item" (click)="onSelectPeriod('day')" [ngClass]="{'active': period === 'day'}">День</a>
      <a class="item" (click)="onSelectPeriod('week')" [ngClass]="{'active': period === 'week'}">Неделя</a>
      <a class="item" (click)="onSelectPeriod('month')" [ngClass]="{'active': period === 'month'}">Месяц</a>
    </div>
  </div>
  <div class="ui five wide column"></div>
</div>
<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <div class="ui stackable doubling six column grid" style="padding-left: 0;padding-right: 0; margin-left: 0;margin-right: 0;margin-top: 2px;">
      <div class="ui rightBorder column" *ngIf="selectedWidgets.includes('sales')" style="padding-left: 0 !important;padding-right: 0 !important;">
        <h4 class="header">
          <span class="fw-bold" [ngClass]="{'greenHead': summary.summary[period].sum.fact >= summary.summary[period].sum.plan, 'redHead': summary.summary[period].sum.fact < summary.summary[period].sum.plan}">Продажи</span>
        </h4>
        <a class="cursor fw-bold fsz-16px" target="_blank" [routerLink]="['/report', 'sale']" [queryParams]="{'from': getQueryFrom(), 'to': getQueryTo(), 'managerId': this.options.userId}" [ngClass]="{'green': summary.summary[period].sum.fact > summary.summary[period].sum.plan, 'red': summary.summary[period].sum.fact < summary.summary[period].sum.plan}">
          {{summary.summary[period].sum.fact | number}}
        </a>
        <span class="fw-bold greytext fsz-16px"> / {{summary.summary[period].sum.plan | number}}</span>
      </div>
      <div class="ui rightBorder column" *ngIf="selectedWidgets.includes('bonus')">
        <h4 class="header">Бонус</h4>
        <span class="fw-bold fsz-16px" [ngClass]="{'green': summary.summary.bonus.coefficient > 0.9}">
          <a class="cursor" target="_blank" [routerLink]="['/report', 'bonus']" [queryParams]="{'from': getQueryFrom(), 'to': getQueryTo(), 'managerId': this.options.userId}">
            {{summary.summary.bonus.sum | number}}
          </a>  ({{summary.summary.bonus.coefficient | number}}%)</span>
      </div>
      <div class="ui rightBorder column" *ngIf="selectedWidgets.includes('prognosis')">
        <h4 class="header">Прогноз</h4>
        <a class="cursor fw-bold fsz-16px" target="_blank" [routerLink]="['/report', 'prognosis']" [queryParams]="{'from': getQueryFrom(), 'to': getQueryTo(), 'managerId': this.options.userId}" [ngClass]="{'green': summary.summary[period].prognosis > 0}">
          {{summary.summary[period].prognosis | number}}
        </a>
      </div>
      <div class="ui rightBorder column" *ngIf="selectedWidgets.includes('deals')">
        <h4 class="header">Новые сделки</h4>
        <a class="fw-bold fsz-16px cursor" target="_blank" [routerLink]="['/report', 'incoming-deals']" [queryParams]="{'from': getQueryFrom(), 'to': getQueryTo(), 'managerId': this.options.userId}">{{summary.summary[period].count | number}}</a>
      </div>
      <div class="ui rightBorder column" *ngIf="selectedWidgets.includes('prognosisWrong')">
        <h4 class="header">
          <span class="fw-bold" [ngClass]="{'greenHead': summary.summary.notValidPrognosis === 0, 'redHead': summary.summary.notValidPrognosis > 0}">Неверный прогноз</span>
        </h4>
        <a class="fw-bold fsz-16px" target="_blank" [routerLink]="['/report', 'prognosis']" [queryParams]="{'managerId': this.options.userId}" [ngClass]="{'red': summary.summary.notValidPrognosis > 0}">
          {{summary.summary.notValidPrognosis | number}}
        </a>
      </div>
      <div class="ui headPadded column" *ngIf="selectedWidgets.includes('noCommunications')">
        <h4 class="header">
          <span class="fw-bold" [ngClass]="{'greenHead': summary.summary.withoutCommunication === 0, 'redHead': summary.summary.withoutCommunication > 0}">Нет коммуникаций</span>
        </h4>
        <a class="cursor fw-bold fsz-16px" target="_blank" [routerLink]="['/report', 'forgotten']" [queryParams]="{'managerId': this.options.userId, 'isEmpty': true}" [ngClass]="{'red': summary.summary.withoutCommunication > 0}">{{summary.summary.withoutCommunication | number}}</a>
      </div>
    </div>
  </div>
  <div class="ui sixteen wide column">
    <div class="ui stackable centered cards">
      <div class="ui coordinator card" [ngClass]="{'active': selectedTodoType && selectedTodoType.id === val.id}" *ngFor="let val of data.todos | dashboardWidgets:selectedTodos | orderByColumn:'name:string'">
        <div class="content">
          <div class="header" style="font-size: 13px !important; text-overflow: ellipsis !important;padding-bottom: 7px !important;">
            <span class="fw-bold" [ngClass]="{'redHead': val[period].closed < val[period].plan}">{{val.name}}</span>
          </div>
          <div class="description">
            <div class="ui tiny statistic">
              <div class="value">
                <a class="grey padded">{{val[period].plan}}</a>
                <a class="padded" [ngClass]="{'active': detailOptions.todoTypeId && detailOptions.todoTypeId === val.id && detailOptions.type === 'PLANNED'}" (click)="onSelectTodoType(val, 'PLANNED')">{{val[period].planned}}</a>
                <a class="green padded" [ngClass]="{'active': detailOptions.todoTypeId && detailOptions.todoTypeId === val.id && detailOptions.type === 'CLOSED'}" (click)="onSelectTodoType(val, 'CLOSED')">{{val[period].closed}}</a>
                <a class="red padded" [ngClass]="{'active': detailOptions.todoTypeId && detailOptions.todoTypeId === val.id && detailOptions.type === 'EXPIRED'}" (click)="onSelectTodoType(val, 'EXPIRED')">{{val.expired}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui stackable grid" *ngIf="detailOptions.todoTypeId" [@fadeInUpOnEnter] [@fadeOutDownOnLeave]>
  <div class="ui twelve wide column">
    <div style="margin-top: 40px;">
      <table class="ui very padded selectable stackable celled table">
        <thead>
        <tr>
          <th>
            <div class="head">Сделка</div>
          </th>
          <th>
            <div class="head">Дело</div>
          </th>
          <th>
            <div class="head">Клиент</div>
          </th>
          <th>
            <div class="head">Сумма</div>
          </th>
          <th>
            <div class="head">Ориентировочная дата покупки</div>
          </th>
          <th *ngIf="detailOptions.type === 'CLOSED'">
            <div class="head">Комментарий</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let todo of detailData | orderByColumn:'to:date'" [ngClass]="{'dealDone': todo.finished}">
          <td nowrap>
            <div e2b-filial-label [filial]="todo.deal.filial"></div>
            <a class="d-inline-block v-align-top w-50" [routerLink]="['/deal/card/', todo.deal.id]" target="_blank">{{todo.deal.name}}</a>
            <span class="greytext d-block w-100">{{todo.deal.created ? (todo.deal.created | date:'short') : "-"}}</span>
          </td>
          <td [ngClass]="{'alert': (!todo.finished && ((todo.to | dateDiff) > 0))}">
            <span [ngClass]="{'red': (!todo.finished && ((todo.to | dateDiff) > 0))}">{{todo.name}}</span>
            <div class="fw-bold" [ngClass]="{'red': (!todo.finished && ((todo.to | dateDiff) > 0))}">до {{todo.to | date:'dd.MM.yyyy HH:mm'}}</div>
          </td>
          <td nowrap>
            <a [routerLink]="['/client/card', todo.deal.client.id]" target="_blank">{{todo.deal.client.name}}</a><br/>
            <a *ngIf="todo.deal.client.contact" href="tel:{{todo.deal.client.contact.phone}}">{{todo.deal.client.contact.phone|phone}}</a>
          </td>
          <td nowrap>
            {{todo.deal.price|number}} руб.
          </td>
          <td nowrap>
            {{todo.deal.datetime|date:'dd.MM.yyyy'}}
          </td>
          <td *ngIf="todo.finished">
            {{todo.finished | date:'dd.MM.yyyy HH:mm'}}<br/>
            {{todo.finishComment}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <br/>
  </div>
</div>
