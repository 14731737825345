import { BaseModel } from './base.model';
import { Client } from './client.model';

export class Filial extends BaseModel {
  created: Date;
  name: string;
  geo: [[number, number]];
  color: string;
  clients: Client[];
}
