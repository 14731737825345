import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { TodoFindByTypeResult } from './todo.service';

@Injectable()
export class PlanService extends BaseService {
  baseUrl = '/manager-plan';

  summary(managerId: string, date: string): Observable<any> {
    return this.http.get<TodoFindByTypeResult>(
      '/manager-plan/summary/' + managerId,
      {
        params: {
          date
        },
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }

}
