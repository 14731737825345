import { Component, EventEmitter, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Client } from '../../_models/client.model';

@Component({
  selector: 'e2b-client-dropdown',
  templateUrl: './client.dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ClientDropdownComponent {

  @ViewChild('selectClient', { static: false })
  selectClient;

  @Input()
  public clients: Client[];
  @Input()
  public event: EventEmitter<Client>;

  public selectedClient: Client;

  @Input()
  set selected(client: Client) {
    setTimeout(() => {
      this.selectClient.selectedOption = client;
      this.selectedClient = client;
    }, 0);
  }

  select() {
    this.event.emit(this.selectedClient);
  }
}
