import { BaseModel } from './base.model';
import { ClientAddress } from './client-address.model';
import { ClientChannel } from './client-channel.model';
import { ClientContact } from './client-contact.model';
import { ClientType } from './client-type.model';
import { Comment } from './comment.model';
import { Deal } from './deal.model';
import { Filial } from './filial.model';
import { Person } from './person.model';
import { User } from './user.model';

export class Client extends BaseModel {
  type: ClientType;
  name: string;
  description: string;
  deleted: boolean;
  manager: User;
  channel: ClientChannel;
  created: Date;
  filial: Filial;
  users: User[];
  deals: Deal[];
  persons: Person[];
  contacts: ClientContact[];
  addresses: ClientAddress[];
  comments: Comment[];
}
