import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClientContact } from '../_models/client-contact.model';
import { Client } from '../_models/client.model';
import { User } from '../_models/user.model';
import { ContactService } from '../_services/contact.service';
import { UserService } from '../_services/user.service';
import { ErrorService } from '../error/error.service';

@Component({
  selector: 'e2b-contact',
  templateUrl: './contact.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/header.min.css',
    '../../assets/design/semantic-ui/components/image.min.css',
    '../../assets/design/semantic-ui/components/card.min.css',
    '../../assets/design/semantic-ui/components/icon.min.css',
    '../../assets/design/semantic-ui/components/form.min.css',
    '../../assets/design/semantic-ui/components/label.min.css',
    '../../assets/design/semantic-ui/components/button.min.css',
    '../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../assets/design/semantic-ui/components/input.min.css',
    '../../assets/design/semantic-ui/components/divider.min.css',
    '../../assets/design/semantic-ui/components/message.min.css',
    './contact.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ContactComponent implements OnInit {

  @Input()
  contacts: ClientContact[];
  @Input()
  clientId: number;
  @Input()
  saveEvent: EventEmitter<ClientContact>;
  @Input()
  updateEvent: EventEmitter<void>;
  editContact = null;
  contact: ClientContact = null;
  contactForm: FormGroup;
  currentUser: User;

  constructor(private userService: UserService,
              private errorService: ErrorService,
              private contactService: ContactService) {
  }

  ngOnInit() {
    this.currentUser = this.userService.current();
  }

  add() {
    this.contact = new ClientContact();
  }

  update(contact: ClientContact) {
    if (this.clientId) {
      contact.client = new Client();
      contact.client.id = this.clientId;

      if (contact.isMain) {
        const mainContacts = this.contacts.filter(c => c.id !== contact.id && c.isMain);
        let mainContactsCounter = 0;

        mainContacts.map(c => {
          c.isMain = false;
          this.contactService.update<ClientContact>(c).subscribe(() => {
            mainContactsCounter++;
            if (mainContactsCounter === mainContacts.length) {
              this.updateContact(contact);
            }
          }, e => {
            console.error(e);
            this.errorService.pushError(e, 'Ошибка при редактировании контакта');
          });
        });

        if (!mainContacts.length) {
          this.updateContact(contact);
        }
      } else {
        this.updateContact(contact);
      }
    } else {
      this.saveEvent.emit(contact);
      this.editContact = null;
    }
  }

  updateContact(contact) {
    this.contactService.update<ClientContact>(contact).subscribe(() => {
      this.editContact = null;
      if (this.updateEvent) {
        this.updateEvent.emit();
      }
    }, e => {
      console.error(e);
      this.errorService.pushError(e, 'Ошибка при редактировании контакта');
    });
  }

  create() {
    if (this.contact.isMain && this.contacts.find(contact => contact.id !== this.contact.id && contact.isMain)) {
      this.errorService.addError.emit({ title: 'Ошибка при добавлении контакта', message: 'У данного клиента уже есть основной контакт' });
    } else {
      this.saveEvent.emit(this.contact);
      this.contact = null;
    }
  }

  remove(contact: ClientContact) {
    this.contactService.deleteById(contact.id).subscribe(() => {
      this.contacts.splice(this.contacts.indexOf(contact), 1);
      if (this.updateEvent) {
        this.updateEvent.emit();
      }
    }, e => {
      this.errorService.addError.emit({
        title: 'Ошибка при редактировании контакта',
        message: e.error && e.error.message ? e.error.message : e.message
      });
    });
  }
}
