<div class="container">
  <div class="support-form" *ngIf="!sended">
    <h1 class="header">Техподдержка e2b.zaoblako.ru</h1>

    <div class="ui form mt-3">
      <div class="inline fields">
        <div class="four wide field">
          <label class="fw-bold">Ваше имя</label>
        </div>
        <div class="twelve wide field">
          <input type="text" [(ngModel)]="name"/>
        </div>
      </div>
      <div class="inline fields">
        <div class="four wide field">
          <label class="fw-bold">Ваша эл. почта</label>
        </div>
        <div class="twelve wide field">
          <input type="email" [(ngModel)]="email"/>
        </div>
      </div>
      <div class="inline fields">
        <div class="four wide field">
          <label class="fw-bold">Сообщение</label>
        </div>
        <div class="twelve wide field">
          <textarea rows="2" [(ngModel)]="message"></textarea>
        </div>
      </div>
      <div class="mt-2">
        <button class="ui green right floated button" [class.loading]="loading" [disabled]="!isFormValid" (click)="send()">Отправить</button>
      </div>
    </div>

  </div>
  <h1 *ngIf="sended">Ваше сообщение успешно отправлено <i class="green check circle outline icon"></i></h1>
</div>

