import { Observable } from 'rxjs';
import { BaseService } from './base.service';

export class PositionClothService extends BaseService {
  baseUrl = '/position-cloth';

  positions(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/positions', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
