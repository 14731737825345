import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'e2b-lk-shortlink',
  template: '',
})
export class LkShortlinkComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.id) {
        if (!localStorage.getItem('e2b.lk.client.token')) {
          localStorage.setItem('e2b.lk.orderIdRedirect', params.id);
        }

        this.router.navigate(['/lk/order/card', params.id]).catch(console.error);
      } else {
        this.router.navigate(['/lk', params.id]).catch(console.error);
      }
    });
  }

}
