<sui-dimmer class="page inverted" [(isDimmed)]="!loaded" [isClickable]="false">
  <div class="ui text loader">Загрузка...</div>
</sui-dimmer>

<div *ngIf="loaded">
  <div class="ui stackable grid" style="margin-top: 22px !important;">
    <div class="ui eight wide column">
      <h3>Новые</h3>
      <div class="ui divider"></div>
      <div *ngIf="orders.new && orders.new.length === 0">
        <br/>
        <div class="header">Ничего нет</div>
        <br/><br/>
      </div>
      <table class="customTable" *ngIf="orders.new && orders.new.length > 0">
        <thead>
        <tr>
          <th nowrap (click)="sortingNew == 'name:string' ? sortingNew = '-name:string' : sortingNew = 'name:string';">
            <div class="head">
              №
              <i *ngIf="sortingNew === '-name:string'" class="icon sort numeric descending"></i>
              <i *ngIf="sortingNew === 'name:string'" class="icon sort numeric ascending"></i>
              <i *ngIf="sortingNew !== 'name:string' && sortingNew !== '-name:string'" class="icon sort"></i>
            </div>
          </th>
          <th
            (click)="sortingNew == 'client.name:object:string' ? sortingNew = '-client.name:object:string' : sortingNew = 'client.name:object:string';">
            <div class="head">
              Клиент
              <i *ngIf="sortingNew === '-client.name:object:string'" class="icon sort alphabet descending"></i>
              <i *ngIf="sortingNew === 'client.name:object:string'" class="icon sort alphabet ascending"></i>
              <i *ngIf="sortingNew !== 'client.name:object:string' && sortingNew !== '-client.name:object:string'"
                 class="icon sort"></i>
            </div>
          </th>
          <th>
            <div class="head">
              Позиция
            </div>
          </th>
          <th (click)="sortingNew == 'date:date' ? sortingNew = '-date:date' : sortingNew = 'date:date';">
            <div class="head">
              Дата готовности
              <i *ngIf="sortingNew === '-date:date'" class="icon sort alphabet descending"></i>
              <i *ngIf="sortingNew === 'date:date'" class="icon sort alphabet ascending"></i>
              <i *ngIf="sortingNew !== 'date:date' && sortingNew !== '-date:date'" class="icon sort"></i>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let order of orders.new | orderByColumn: sortingNew; trackBy: identify">
          <td>
            <a href="#" [routerLink]="['/deal/order/production/', order.id]">{{order.name}}</a><br/>
            <span class="date" *ngIf="order.projectFinish">{{order.projectFinish}}<br/></span>
            <span class="greytext">+ {{order.priceSum|number}} руб.</span><br/>
            <div class="ui buttons" style="margin-top: 18px;">
              <button class="ui green button" (click)="accept(order)"
                      [ngClass]="{'loading': currentUpdate === order.id}">Принять
              </button>&nbsp;&nbsp;
              <button class="ui red button" (click)="decline(order)"
                      [ngClass]="{'loading': currentUpdate === order.id}">Отказать
              </button>
              <button class="ui red button" (click)="sos(order)">SOS</button>
            </div>
          </td>
          <td>{{order.client.name}}</td>
          <td>
            <div *ngFor="let p of order.positions" class="order-position">
              <div>{{p.position.name}} <span *ngIf="p.count > 1">({{p.count}})</span></div>
              <div class="greytext">{{p.position.type | positionType}}</div>
            </div>
          </td>
          <td>{{order.date | date:'dd.MM.yyyy'}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="6" style="text-align: right;font-size: 16px;">
            БОНУС: <b>{{bonusOpened()|number}} руб.</b>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
    <div class="ui eight wide column">
      <h3>В работе</h3>
      <div class="ui divider"></div>
      <br/>
      <div class="ui green label headspan">На этой неделе и раньше</div>
      <div *ngIf="orders.acceptedWeek && orders.acceptedWeek.length === 0">
        <br/>
        <div class="header">Ничего нет</div>
      </div>
      <table class="customTable" *ngIf="orders.acceptedWeek && orders.acceptedWeek.length > 0">
        <thead>
        <tr>
          <th nowrap
              (click)="sortingAcceptedWeek == 'name:string' ? sortingAcceptedWeek = '-name:string' : sortingAcceptedWeek = 'name:string';">
            <div class="head">
              №
              <i *ngIf="sortingAcceptedWeek === '-name:string'" class="icon sort numeric descending"></i>
              <i *ngIf="sortingAcceptedWeek === 'name:string'" class="icon sort numeric ascending"></i>
              <i *ngIf="sortingAcceptedWeek !== 'name:string' && sortingAcceptedWeek !== '-name:string'"
                 class="icon sort"></i>
            </div>
          </th>
          <th
            (click)="sortingAcceptedWeek == 'client.name:object:string' ? sortingAcceptedWeek = '-client.name:object:string' : sortingAcceptedWeek = 'client.name:object:string';">
            <div class="head">
              Клиент
              <i *ngIf="sortingAcceptedWeek === '-client.name:object:string'" class="icon sort alphabet descending"></i>
              <i *ngIf="sortingAcceptedWeek === 'client.name:object:string'" class="icon sort alphabet ascending"></i>
              <i
                *ngIf="sortingAcceptedWeek !== 'client.name:object:string' && sortingAcceptedWeek !== '-client.name:object:string'"
                class="icon sort"></i>
            </div>
          </th>
          <th>
            <div class="head">
              Позиция
            </div>
          </th>
          <th
            (click)="sortingAcceptedWeek == 'date:date' ? sortingAcceptedWeek = '-date:date' : sortingAcceptedWeek = 'date:date';">
            <div class="head">
              Дата готовности
              <i *ngIf="sortingAcceptedWeek === '-date:date'" class="icon sort alphabet descending"></i>
              <i *ngIf="sortingAcceptedWeek === 'date:date'" class="icon sort alphabet ascending"></i>
              <i *ngIf="sortingAcceptedWeek !== 'date:date' && sortingAcceptedWeek !== '-date:date'"
                 class="icon sort"></i>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let order of orders.acceptedWeek | orderByColumn: sortingAcceptedWeek; trackBy: identify">
          <td>
            <a href="#" [routerLink]="['/deal/order/production/', order.id]">{{order.name}}</a><br/>
            <span class="date" *ngIf="order.projectFinish">{{order.projectFinish}}<br/></span>
            <span class="greytext">+ {{order.priceSum|number}} руб.</span><br/>
            <div class="ui buttons" style="margin-top: 18px;">
              <button class="ui green button" *ngIf="!order.finished"
                      [ngClass]="{'loading': currentUpdate === order.id}" (click)="finish(order)">Завершить
              </button>&nbsp;&nbsp;
              <button class="ui primary button" *ngIf="order.finished"
                      [ngClass]="{'loading': currentUpdate === order.id}" (click)="unload(order)">Отгрузить
              </button>
              <button class="ui red button" (click)="sos(order)">SOS</button>
            </div>
          </td>
          <td>{{order.client.name}}</td>
          <td>
            <div *ngFor="let p of order.positions" class="order-position">
              <div>{{p.position.name}} <span *ngIf="p.count > 1">({{p.count}})</span></div>
              <div class="greytext">{{p.position.type | positionType}}</div>
            </div>
          </td>
          <td>{{order.date | date:'dd.MM.yyyy'}}</td>
        </tr>
        </tbody>
      </table>
      <br/>
      <div class="ui divider"></div>
      <br/>
      <div class="ui grey label headspan">Позже</div>
      <div *ngIf="orders.acceptedAfter && orders.acceptedAfter.length === 0">
        <br/>
        <div class="header">Ничего нет</div>
      </div>
      <table class="customTable" *ngIf="orders.acceptedAfter && orders.acceptedAfter.length > 0">
        <thead>
        <tr>
          <th nowrap
              (click)="sortingAcceptedAfter == 'name:string' ? sortingAcceptedAfter = '-name:string' : sortingAcceptedAfter = 'name:string';">
            <div class="head">№</div>
            <i *ngIf="sortingAcceptedAfter === '-name:string'" class="icon sort numeric descending"></i>
            <i *ngIf="sortingAcceptedAfter === 'name:string'" class="icon sort numeric ascending"></i>
            <i *ngIf="sortingAcceptedAfter !== 'name:string' && sortingAcceptedAfter !== '-name:string'"
               class="icon sort"></i>
          </th>
          <th
            (click)="sortingAcceptedAfter == 'client.name:object:string' ? sortingAcceptedAfter = '-client.name:object:string' : sortingAcceptedAfter = 'client.name:object:string';">
            <div class="head">
              Клиент
              <i *ngIf="sortingAcceptedAfter === '-client.name:object:string'"
                 class="icon sort alphabet descending"></i>
              <i *ngIf="sortingAcceptedAfter === 'client.name:object:string'" class="icon sort alphabet ascending"></i>
              <i
                *ngIf="sortingAcceptedAfter !== 'client.name:object:string' && sortingAcceptedAfter !== '-client.name:object:string'"
                class="icon sort"></i>
            </div>
          </th>
          <th>
            <div class="head">
              Позиция
            </div>
          </th>
          <th
            (click)="sortingAcceptedAfter == 'date:date' ? sortingAcceptedAfter = '-date:date' : sortingAcceptedAfter = 'date:date';">
            <div class="head">
              Дата готовности
              <i *ngIf="sortingAcceptedAfter === '-date:date'" class="icon sort alphabet descending"></i>
              <i *ngIf="sortingAcceptedAfter === 'date:date'" class="icon sort alphabet ascending"></i>
              <i *ngIf="sortingAcceptedAfter !== 'date:date' && sortingAcceptedAfter !== '-date:date'"
                 class="icon sort"></i>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let order of orders.acceptedAfter | orderByColumn: sortingAcceptedAfter; trackBy: identify">
          <td>
            <a href="#" [routerLink]="['/deal/order/production/', order.id]">{{order.name}}</a><br/>
            <span class="date" *ngIf="order.projectFinish">{{order.projectFinish}}<br/></span>
            <span class="greytext">+ {{order.priceSum|number}} руб.</span><br/>
            <div class="ui buttons" style="margin-top: 18px;">
              <button class="ui green button" *ngIf="!order.finished"
                      [ngClass]="{'loading': currentUpdate === order.id}" (click)="finish(order)">Завершить
              </button>&nbsp;&nbsp;
              <button class="ui primary button" *ngIf="order.finished"
                      [ngClass]="{'loading': currentUpdate === order.id}" (click)="unload(order)">Отгрузить
              </button>
              <button class="ui red button" (click)="sos(order)">SOS</button>
            </div>
          </td>
          <td>{{order.client.name}}</td>
          <td>
            <div *ngFor="let p of order.positions" class="order-position">
              <div>{{p.position.name}} <span *ngIf="p.count > 1">({{p.count}})</span></div>
              <div class="greytext">{{p.position.type | positionType}}</div>
            </div>
          </td>
          <td>{{order.date | date:'dd.MM.yyyy'}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="5" style="text-align: right;font-size: 16px;">
            БОНУС:&nbsp;&nbsp;&nbsp;&nbsp;<b>{{bonusAccepted()|number}} руб.</b>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div class="ui stackable grid">
    <div class="ui eight wide column">
      <h3 style="color: red;">SOS</h3>
      <div class="ui divider"></div>
      <div *ngIf="orders.sos && orders.sos.length === 0">
        <br/>
        <div class="header">Ничего нет</div>
        <br/><br/>
      </div>
      <table class="ui very basic selectable celled table" *ngIf="orders.sos && orders.sos.length > 0">
        <thead>
        <tr>
          <th nowrap (click)="sortingSOS == 'name:string' ? sortingSOS = '-name:string' : sortingSOS = 'name:string';">
            <div class="head">№</div>
            <i *ngIf="sortingSOS === '-name:string'" class="icon sort numeric descending"></i>
            <i *ngIf="sortingSOS === 'name:string'" class="icon sort numeric ascending"></i>
            <i *ngIf="sortingSOS !== 'name:string' && sortingSOS !== '-name:string'" class="icon sort"></i>
          </th>
          <th
            (click)="sortingSOS == 'client.name:object:string' ? sortingSOS = '-client.name:object:string' : sortingSOS = 'client.name:object:string';">
            <div class="head">
              Клиент
              <i *ngIf="sortingSOS === '-client.name:object:string'" class="icon sort alphabet descending"></i>
              <i *ngIf="sortingSOS === 'client.name:object:string'" class="icon sort alphabet ascending"></i>
              <i *ngIf="sortingSOS !== 'client.name:object:string' && sortingSOS !== '-client.name:object:string'"
                 class="icon sort"></i>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let order of orders.sos | orderByColumn: sortingSOS; trackBy: identify">
          <td>
            <a href="#" [routerLink]="['/deal/order/production/', order.id]">{{order.name}}</a><br/>
            <span class="greytext">+ {{order.priceSum|number}} руб.</span>
          </td>
          <td>{{order.client.name}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="3" style="text-align: right;font-size: 16px;">
            БОНУС:&nbsp;&nbsp;&nbsp;&nbsp;<b>{{bonusSos()}} руб.</b>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
