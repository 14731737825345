import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../environments/environment';
import { NotificationTypeEnum } from '../_models/enums/notification-type.enum';
import { HighlightPipe } from '../_pipes/highlight.pipe';
import { UserActivityService } from '../_services/user-activity.service';

declare var jQuery: any;

@Component({
  selector: 'e2b-app-bottom-bar',
  templateUrl: './app-bottom-bar.component.html',
  styleUrls: ['./app-bottom-bar.component.less',
    '../../assets/design/semantic-ui/components/input.min.css',
    '../../assets/design/semantic-ui/components/search.min.css',
  ],
  providers: [HighlightPipe],
  encapsulation: ViewEncapsulation.None
})
export class AppBottomBarComponent implements OnInit {
  searchText = '';

  activities: { id: number, name: string, iconClass: string, labelColor: string, iconHighlightColor: string, notificationTypes: NotificationTypeEnum[] }[] = [
    { id: 1, name: 'СБР', iconClass: 'money bill alternate outline', labelColor: 'green', iconHighlightColor: 'green', notificationTypes: [NotificationTypeEnum.TASK_STATUS, NotificationTypeEnum.TASK_COMMENT] },
    { id: 2, name: 'Проблемы', iconClass: 'exclamation circle', labelColor: 'red', iconHighlightColor: 'orange', notificationTypes: [NotificationTypeEnum.PROBLEM] },
    { id: 3, name: 'Рекламации', iconClass: 'wrench', labelColor: 'red', iconHighlightColor: 'orange', notificationTypes: [NotificationTypeEnum.RECLAMATION] },
    {
      id: 4,
      name: 'Задачи',
      iconClass: 'tasks',
      labelColor: 'green',
      iconHighlightColor: 'purple',
      notificationTypes: [NotificationTypeEnum.PROJECT_COMMENT, NotificationTypeEnum.PROJECT_STATUS, NotificationTypeEnum.PROJECT_CHECKLIST]
    },
    {
      id: 5,
      name: 'Общие',
      iconClass: 'list alternate outline',
      labelColor: 'green',
      iconHighlightColor: 'yellow',
      notificationTypes: [NotificationTypeEnum.DEAL_COMMENT, NotificationTypeEnum.WHATSAPP]
    },
    {
      id: 6,
      name: 'Личные',
      iconClass: 'user outline',
      labelColor: 'green',
      iconHighlightColor: 'blue',
      notificationTypes: [
        NotificationTypeEnum.PERSONAL,
        NotificationTypeEnum.DEAL_REMINDER,
        NotificationTypeEnum.TASK_REMINDER,
        NotificationTypeEnum.PROJECT_CHECKLIST_REMINDER,
        NotificationTypeEnum.PROJECT_REMINDER,
        NotificationTypeEnum.PERSONAL_WHATSAPP,
      ]
    },
  ];

  constructor(private highlight: HighlightPipe,
              private userActivityService: UserActivityService) {
  }

  ngOnInit() {
    const self = this;
    jQuery.fn.api.settings.beforeXHR = function (xhr) {
      xhr.setRequestHeader('Authorization', localStorage.getItem('e2b.user.token'));
    };
    jQuery('.ui.category.search').search({
      type: 'category',
      templates: {
        message: function onMessage(message, type) {
          let html = '';
          if (type === 'empty') {
            html = '<div class="message empty"><div class="header">Нет результатов</div><div class="description">Ничего не найдено</div></div>';
          }
          return html;
        },
        category: function special(response) {
          let html = '';
          if (!response) {
            return html;
          }
          Object.keys(response.results).forEach(function (name) {
            html += '<div class="category">';
            html += '<div class="name">' + name + '</div><div class="results">';
            response.results[name].results.forEach(function (result) {
              html += '<div id="' + result.id + '" class="result">';
              html += '<div class="content">';
              html += '<div class="title">' + result.title + '</div>';
              if (result.description) {
                html += '<div class="description">' + result.description + '</div>';
              }
              if (result.price) {
                html += '<div class="price">' + result.price + '</div>';
              }
              html += '</div>';
              html += '</div>';
            });
            html += '</div></div>';
          });
          return html;
        }
      },
      minCharacters: 1,
      searchDelay: 1000,
      apiSettings: {
        url: environment.apiUrl + '/search/query?q={query}',
        onResponse: function onResponse(res) {
          const response = {
            results: {}
          };
          if (res.deal.length > 0) {
            if (response.results['Сделки'] === undefined) {
              response.results['Сделки'] = {
                name: 'Сделки',
                results: []
              };
            }
            res.deal.forEach(function (deal) {
              response.results['Сделки'].results.push({
                id: deal.id,
                description: (deal.client ? (deal.client.name.indexOf(self.searchText) > -1 ?
                  self.highlight.transform(deal.client.name, self.searchText) : deal.client.name) : '') + '<br/>' + (deal.description ? (deal.description.indexOf(self.searchText) > -1 ?
                  self.highlight.transform(deal.description.substr(deal.description.indexOf(self.searchText) - 40, 80), self.searchText) : deal.description) : ''),
                title: deal.name.indexOf(self.searchText) > -1 ? self.highlight.transform(deal.name, self.searchText) : deal.name,
                type: 'deal'
              });
            });
          }
          if (res.project.length > 0) {
            if (response.results['Задачи'] === undefined) {
              response.results['Задачи'] = {
                name: 'Задачи',
                results: []
              };
            }
            res.project.forEach(function (project) {
              response.results['Задачи'].results.push({
                id: project.id,
                description: (project.name ? (project.name.indexOf(self.searchText) > -1 ?
                  self.highlight.transform(project.name, self.searchText) : project.name) : '') + '<br/>' + (project.description ? (project.description.indexOf(self.searchText) > -1 ?
                  self.highlight.transform(project.description.substr(project.description.indexOf(self.searchText) - 40, 80), self.searchText) : project.description) : ''),
                title: project.name.indexOf(self.searchText) > -1 ? self.highlight.transform(project.name, self.searchText) : project.name,
                type: 'project'
              });
            });
          }
          if (res.client.length > 0) {
            if (response.results['Клиенты'] === undefined) {
              response.results['Клиенты'] = {
                name: 'Клиенты',
                results: []
              };
            }
            res.client.forEach(function (client) {
              response.results['Клиенты'].results.push({
                id: client.id,
                title: client.name.indexOf(self.searchText) > -1 ? self.highlight.transform(client.name, self.searchText) : client.name,
                description: client.description ? (client.description.indexOf(self.searchText) > -1 ?
                  self.highlight.transform(client.description.substr(client.description.indexOf(self.searchText) - 40, 80), self.searchText) : client.description) : '',
                type: 'client'
              });
            });
          }
          if (res.person.length > 0) {
            if (response.results['Плательщики'] === undefined) {
              response.results['Плательщики'] = {
                name: 'Плательщики',
                results: []
              };
            }
            res.person.forEach(function (person) {
              response.results['Плательщики'].results.push({
                id: person.id,
                title: person.name.indexOf(self.searchText) > -1 ? self.highlight.transform(person.name, self.searchText) : person.name,
                description: person.client && person.client.description ? (person.client.description.indexOf(self.searchText) > -1 ?
                  self.highlight.transform(person.client.description.substr(person.client.description.indexOf(self.searchText) - 40, 80), self.searchText) : person.client.description) : '',
                type: 'person'
              });
            });
          }
          if (res.contract.length > 0) {
            if (response.results['Договоры'] === undefined) {
              response.results['Договоры'] = {
                name: 'Договоры',
                results: []
              };
            }
            res.contract.forEach(function (contract) {
              response.results['Договоры'].results.push({
                id: contract.id,
                title: contract.name + ' №' + contract.number,
                description: contract.person.client.name,
                type: 'contract'
              });
            });
          }
          if (res.comment.length > 0) {
            if (response.results['Сделки: Комментарии'] === undefined) {
              response.results['Сделки: Комментарии'] = {
                name: 'Сделки: Комментарии',
                results: []
              };
            }
            res.comment.forEach(function (comment) {
              if (comment.deal && comment.text) {
                response.results['Сделки: Комментарии'].results.push({
                  id: comment.deal.id,
                  title: comment.deal.name,
                  description: (comment.deal.client ? comment.deal.client.name : '') + '<br/>' + (comment.text.toString().indexOf(self.searchText) > -1 ?
                    self.highlight.transform(comment.text.substr(comment.text.indexOf(self.searchText) - 40, 80), self.searchText) : comment.text),
                  type: 'comment'
                });
              }
            });
            if (response.results['Сделки: Комментарии'] && response.results['Сделки: Комментарии'].results && response.results['Сделки: Комментарии'].results.length === 0) {
              delete response.results['Сделки: Комментарии'];
            }
          }
          if (res.projectComment.length > 0) {
            if (response.results['Задачи: Комментарии'] === undefined) {
              response.results['Задачи: Комментарии'] = {
                name: 'Задачи: Комментарии',
                results: []
              };
            }
            res.projectComment.forEach(function (comment) {
              if (comment.project && comment.text) {
                response.results['Задачи: Комментарии'].results.push({
                  id: comment.project.id,
                  title: comment.project.name,
                  description: (comment.text.toString().indexOf(self.searchText) > -1 ?
                    self.highlight.transform(comment.text.substr(comment.text.indexOf(self.searchText) - 40, 80), self.searchText) : comment.text),
                  type: 'projectComment'
                });
              }
            });
            if (response.results['Задачи: Комментарии'] && response.results['Задачи: Комментарии'].results && response.results['Задачи: Комментарии'].results.length === 0) {
              delete response.results['Задачи: Комментарии'];
            }
          }
          return response;
        }
      },
      onSelect: function onSelect(result) {
        let url = window.location.protocol + '//' + window.location.host + '/';
        switch (result.type) {
          case 'deal':
            url += 'deal/card/' + result.id;
            break;
          case 'contract':
            url += 'contract/card/' + result.id;
            break;
          case 'client':
            url += 'client/card/' + result.id;
            break;
          case 'project':
            url += 'project/show/' + result.id;
            break;
          case 'person':
            url += 'client/person/card/' + result.id;
            break;
          case 'comment':
            url += 'deal/card/' + result.id;
            break;
          case 'projectComment':
            url += 'project/show/' + result.id;
            break;
        }
        const tabWindowId = window.open('about:blank', '_blank');
        tabWindowId.location.href = url;
        return false;
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick() {
    this.userActivityService.resetActivity();
  }

  @HostListener('document:keypress', ['$event'])
  onKeyPress() {
    this.userActivityService.resetActivity();
  }
}
