import { BaseModel } from './base.model';
import { Direction } from './direction.model';
import { Unit } from './unit.model';

export enum ReferenceType {
  Month = 'MONTH',
  Week = 'WEEK'
}

export class Reference extends BaseModel {
  created: Date;
  deleted: boolean;
  amount: number;
  type: ReferenceType;
  direction: Direction;
  unit: Unit;
}
