import { BaseModel } from './base.model';
import { User } from './user.model';

export class DefaultRoleAccess {
  public static access = {
    routes: {
      user: {
        '/': 'none',
        login: { '/': 'none' },
        change: { '/': 'none' },
        reset: { '/': 'none' },
        check: { '/': 'none' },
        switch: { '/': 'none' },
        logout: { '/': 'none' },
        todos: { '/': 'none' },
        managers: { '/': 'none' },
        mounters: { '/': 'none' },
        production: { '/': 'none' },
        softProduction: { '/': 'none' },
      },
      bitrix: {
        '/': 'none',
        fields: { '/': 'none' },
        position: { '/': 'none' },
        cloth: { '/': 'none' },
        material: { '/': 'none' },
        deal: {
          '/': 'none',
          mounting: { '/': 'none', date: { '/': 'none' } },
          comment: { '/': 'none' },
          reclamation: { '/': 'none' },
        },
      },
      deal: {
        '/': 'none',
        client: { '/': 'none' },
        calls: { '/': 'none' },
        calendar: { '/': 'none' },
        mounting: { '/': 'none', date: { '/': 'none', set: { '/': 'none' } } },
        shipping: { '/': 'none', date: { '/': 'none' } },
        cost: { '/': 'none' },
        coefficient: { '/': 'none' },
        export1C: { '/': 'none' },
        template: { '/': 'none' },
        users: { '/': 'none' },
        todos: { '/': 'none' },
        sos: { '/': 'none' },
        responsible: { '/': 'none' },
        fields: { '/': 'none' },
        position: { '/': 'none', values: { '/': 'none' } },
        finance: { '/': 'none' },
        values: { '/': 'none' },
        history: { '/': 'none' },
        files: { '/': 'none' },
        app: { '/': 'none', comments: { '/': 'none' } },
      },
      userGroup: { '/': 'none' },
      notification: { '/': 'none', unreaded: { '/': 'none' } },
      todoStatus: { '/': 'none' },
      bank: { '/': 'none' },
      todoType: { '/': 'none' },
      todo: {
        '/': 'none',
        overdue: { '/': 'none' },
        summary: { '/': 'none' },
        type: { '/': 'none' },
        detail: { '/': 'none' },
        assign: { '/': 'none' },
        finish: { '/': 'none' },
      },
      department: { '/': 'none' },
      dealStatus: { '/': 'none', relation: { '/': 'none' } },
      dealPosition: { '/': 'none', add: { '/': 'none' } },
      filial: { '/': 'none' },
      position: { '/': 'none' },
      job: { '/': 'none' },
      role: { '/': 'none' },
      call: { '/': 'none' },
      file: { '/': 'none', app: { '/': 'none' } },
      client: { '/': 'none' },
      clientType: { '/': 'none' },
      clientChannel: { '/': 'none' },
      comment: { '/': 'none', deal: { '/': 'none' }, client: { '/': 'none' } },
      person: { '/': 'none', client: { '/': 'none' } },
      personType: { '/': 'none' },
      project: { '/': 'none' },
      contact: { '/': 'none' },
      contractor: { '/': 'none' },
      search: { '/': 'none', query: { '/': 'none' } },
      setting: { '/': 'none' },
      own: { '/': 'none' },
      integration: { '/': 'none' },
      reminder: { '/': 'none', task: { '/': 'none' }, deal: { '/': 'none' } },
      report: {
        '/': 'none',
        adv: { '/': 'none' },
        call: { '/': 'none' },
        assortment: { '/': 'none' },
        gauging: { '/': 'none' },
        delivery: { '/': 'none' },
        incomingDeals: { '/': 'none' },
        managerActivity: { '/': 'none' },
        mounting: { '/': 'none' },
        production: { '/': 'none' },
        problem: { '/': 'none' },
        reclamation: { '/': 'none' },
        sale: { '/': 'none' },
        money: { '/': 'none' },
        square: { '/': 'none' },
        rentability: { '/': 'none' },
        timeStatus: { '/': 'none' },
        credit: { '/': 'none' },
        bonus: { '/': 'none' },
        forgotten: { '/': 'none' },
        pnl: { '/': 'none' },
        documents: { '/': 'none' },
      },
      production: {
        '/': 'none',
        deals: { '/': 'none' },
        deal: { '/': 'none' },
        archive: { '/': 'none', xlsx: { '/': 'none' } },
        xlsx: { '/': 'none' },
        current: { '/': 'none' },
        decline: { '/': 'none' },
        accept: { '/': 'none' },
        finish: { '/': 'none' },
        unload: { '/': 'none' },
      },
      warehouse: { '/': 'none', deal: { '/': 'none' }, value: { '/': 'none' } },
      'position-group': { '/': 'none' },
      'position-group-template': { '/': 'none' },
      'position-field-value': { '/': 'none' },
      'deal-field': { '/': 'none' },
      'deal-position-value': {
        '/': 'none',
        price: { '/': 'none' },
        clothprice: { '/': 'none' },
      },
      chat: { '/': 'none' },
      reference: { '/': 'none' },
      direction: { '/': 'none' },
      unit: { '/': 'none' },
      plan: { '/': 'none' },
      'position-material': { '/': 'none' },
      'position-cloth': { '/': 'none', positions: { '/': 'none' } },
      'position-cloth-price': { '/': 'none' },
      'position-personal': { '/': 'none' },
      todocheckpoint: { '/': 'none', done: { '/': 'none' } },
      controlpoint: { '/': 'none', summary: { '/': 'none' } },
      problem: { '/': 'none' },
      reclamation: { '/': 'none' },
      template: { '/': 'none' },
      'manager-plan': { '/': 'none', summary: { '/': 'none' } },
    },
    dealsAccess: null,
    todoCheckpointsAccess: null,
    fields: {},
  };
}

export enum AccessRightType {
  NONE = 'none',
  READ = 'read',
  WRITE = 'write',
}

export enum MemberAccessType {
  NONE = 'none',
  MEMBER = 'member',
  FULL = 'full',
}

export interface AccessObject {
  routes: {
    widget: any;
    project: any;
    brs: any;
    address: any;
    finance: any;
    service: any;
    sale: any;
    budget: any;
    user: any;
    bitrix: any;
    deal: any;
    userGroup: any;
    notification: any;
    todoStatus: any;
    bank: any;
    todoType: any;
    todo: any;
    department: any;
    dealStatus: any;
    dealPosition: any;
    filial: any;
    position: any;
    job: any;
    role: any;
    call: any;
    file: any;
    client: any;
    clientType: any;
    clientChannel: any;
    comment: any;
    person: any;
    personType: any;
    contact: any;
    contractor: any;
    search: any;
    setting: any;
    own: any;
    menu: any;
    integration: any;
    report: any;
    production: any;
    warehouse: any;
    reminder: any;
    'position-group': any;
    'position-group-template': any;
    'position-field-value': any;
    'deal-field': any;
    'deal-position-value': any;
    chat: any;
    reference: any;
    direction: any;
    unit: any;
    plan: any;
    'position-material': any;
    'position-cloth': any;
    'position-cloth-price': any;
    'position-personal': any;
    todocheckpoint: any;
    controlpoint: any;
    problem: any;
    reclamation: any;
    template: any;
    'manager-plan': any;
  };
  dealsAccess: MemberAccessType;
  taskAccess: MemberAccessType;
  projectAccess: MemberAccessType;
  todoCheckpointsAccess: MemberAccessType;
  fields: { [id: number]: AccessRightType };
};

export class Role extends BaseModel {
  name: string;
  description: string;
  users: User[];
  accessObject: AccessObject;
}
