import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActionService} from '../../../action/action.service';
import {AssemblyScheme} from '../../../_models/assembly-scheme.model';
import {AssemblySchemeService} from '../../../_services/assembly-scheme.service';
import {UserService} from "../../../_services/user.service";

@Component({
  selector: 'e2b-assembly-scheme-list',
  templateUrl: './assembly-scheme-list.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/header.min.css',
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../../assets/design/semantic-ui/components/label.min.css',
    '../../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class AssemblySchemeListComponent implements OnInit {

  assemblyShemes: AssemblyScheme[] = [];
  loaded = false;

  constructor(private actionService: ActionService,
              private userService: UserService,
              private assemblySchemeService: AssemblySchemeService) {
  }

  ngOnInit() {
    this.load();
  }

  trackByFn(index, item) {
    return item.id;
  }

  load() {
    this.assemblySchemeService.find<AssemblyScheme>().subscribe(schemes => {
      this.assemblyShemes = schemes;
      this.loaded = true;
    });
  }

  save(assemblyScheme: AssemblyScheme) {
    const update = {
      id: assemblyScheme.id,
      name: assemblyScheme.name
    };
    this.assemblySchemeService.update(update).subscribe(() => {
    }, (e) => {
      console.error(e);
    });
  }
}
