import {Component} from '@angular/core';

@Component({
  selector: 'e2b-deal-step',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class DealStepComponent {


  constructor() {
  }

}
