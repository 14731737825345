<div class="bottom-menu">
  <div class="d-flex justify-content-end align-items-center container" style="padding: 2px 70px 4px 70px; height: 100%">

    <div class="ui category search">
      <div class="ui left fluid icon input">
        <input id="searchInput" class="prompt" [(ngModel)]="searchText" type="text" placeholder="Поиск...">
        <i class="ui search icon"></i>
      </div>
    </div>

    <div class="activity d-flex">
      <div class="activity-popup" *ngFor="let activity of activities">
        <e2b-activity-popup [activity]="activity"></e2b-activity-popup>
      </div>
    </div>
  </div>
</div>
<div class="bottom-menu-offset"></div>


