import { Observable } from 'rxjs';
import { PositionGroup } from '../_models/position-group.model';
import { BaseService } from './base.service';

export enum DealFieldType {
  DEAL = 'CRM.FIELD.DEAL',
  DELIVERY = 'CRM.FIELD.DELIVERY',
  MOUNTING = 'CRM.FIELD.MOUNTING',
  PRODUCTION = 'CRM.FIELD.PRODUCTION',
  POSITION = 'CRM.FIELD.POSITION',
  PROBLEM = 'CRM.FIELD.PROBLEM',
}

interface DealPositionValueResult {
  field: {
    id: number,
    model: DealFieldType,
    name: string,
    type: string,
    sort: number,
    values?: any,
    users?: any
  };
  id: number;
  value: any;
}

export class DealPositionValueService extends BaseService {
  baseUrl = '/deal-position-value';

  updateClothPrice(id, positionId, value): Observable<{ price: number }> {
    return this.http.post<any>(this.baseUrl + '/clothprice', { id: id, positionId: positionId, value: value }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  updatePersonalPrice(id, value): Observable<{ price: number, percent: number }> {
    return this.http.post<any>(this.baseUrl + '/price', { positionId: id, value: value }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  values(id): Observable<{ group: PositionGroup, position: DealPositionValueResult[], mounting: DealPositionValueResult[], production: DealPositionValueResult[], problem: DealPositionValueResult[], reclamation: DealPositionValueResult }> {
    return this.http.get<any>(this.baseUrl + '/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
