<sui-dimmer class="page inverted" [(isDimmed)]="!loaded" [isClickable]="false">
  <div class="ui text loader">Загрузка...</div>
</sui-dimmer>

<div class="ui grid" *ngIf="loaded">
  <div class="ui sixteen wide column">
    <div class="ui fluid ordered steps">
      <div class="cursor step" [ngClass]="{'active': step === 1}" (click)="step = 1">
        <div class="content">
          <div class="title">Сделка</div>
          <div class="description">Информация о клиенте, участниках и заказе</div>
        </div>
      </div>
      <div class="cursor step" [ngClass]="{'active': step === 2}" (click)="saveDeal()">
        <div class="content">
          <div class="title">Коммуникации</div>
          <div class="description">Итог показа и следующая коммуникация</div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui sixteen wide column" *ngIf="step === 1">
    <form class="ui form">
      <div class="ui stackable grid">
        <div class="ui six wide column">
          <!--Инфо о клиенте-->
          <div>
            <h4 class="ui dividing header">Информация о клиенте</h4>
            <div class="field required">
              <label class="fw-bold">Клиент</label>
              <div *ngIf="!client.id" class="fluid field">
                <ng-template #clientOptionTemplate let-query="query" let-result>
                  <div class="mininame">{{result.name}}</div>
                  <div *ngIf="result.contact" class="miniprice">{{result.contact.phone}}</div>
                  <div *ngIf="result.contact" class="minitext">{{result.contact.email}}</div>
                </ng-template>
                <sui-search (resultSelected)="selectClient($event)"
                            [hasIcon]="true"
                            [maxResults]="25"
                            [optionsLookup]="clientSearch"
                            [resultTemplate]="clientOptionTemplate"
                            id="client"
                            name="client"
                            placeholder="Клиент"
                            style="width: 100%;"
                            #clientSearchComponent>
                </sui-search>
              </div>
              <div *ngIf="client.id">
                <div>
                  <a href="#" [routerLink]="['/client/card', client.id]">{{client.name}}</a>
                </div>
                <button class="ui grey button mt-1" (click)="resetClient()">Изменить</button>
              </div>
            </div>

            <div class="field required">
              <label class="fw-bold">Имя контакта</label>
              <input type="text" name="contactName" placeholder="Имя контакта" [(ngModel)]="clientContact.name">
            </div>
            <div class="field required">
              <label class="fw-bold">Почта</label>
              <input type="email" name="email" placeholder="e-mail" [(ngModel)]="clientContact.email">
            </div>
            <div class="field required">
              <label class="fw-bold">Телефон</label>
              <input type="number" name="phone" placeholder="Телефон" [(ngModel)]="clientContact.phone" (ngModelChange)="onClientContactPhoneChange($event)">
            </div>
          </div>

          <!--Участники-->
          <div class="form-block">
            <h4 class="ui dividing header">Участники</h4>
            <div class="field required">
              <label class="fw-bold">Менеджер</label>
              <e2b-staff-dropdown [staff]="managers" [selected]="deal.manager" [event]="selectManager"></e2b-staff-dropdown>
            </div>
            <div class="field">
              <label class="fw-bold">Доп. менеджер</label>
              <e2b-staff-dropdown [staff]="managers" [selected]="deal.subManager" [event]="selectSubManager"></e2b-staff-dropdown>
            </div>
            <div class="field required">
              <label class="fw-bold">Аудиторы</label>
              <e2b-staff-group-multi-dropdown [event]="selectAuditor" [groups]="groups" [selected]="auditors"></e2b-staff-group-multi-dropdown>
            </div>
          </div>

          <div class="form-block">
            <h4 class="ui dividing header">Дата продажи</h4>
            <div class="field">
              <label class="fw-bold">Предполагаемая дата продажи</label>
              <div [ngClass]="editDate ? 'ui action input' : 'ui action disabled input'">
                <input [disabled]="!editDate" [(ngModel)]="possibleSaleDate.value" autocomplete="off" name="date" pickerMode="date" suiDatepicker>
                <button *ngIf="editDate" (click)="changePossibleSaleDate()" class="ui green button" style="height: auto !important;">Сохранить</button>
                <button *ngIf="!editDate" (click)="editDate = true" class="ui grey button" style="height: auto !important;">Изменить</button>
              </div>
            </div>
          </div>
        </div>
        <div class="ui ten wide column">
          <div class="ui form">
            <div class="field">
              <h4 class="ui header" style="margin-bottom: 3px;">Позиции сделки</h4>
              <e2b-deal-position [setPositions]="deal.positions" [products]="positions" [onAddPosition]="addDealPosition" [dealId]="deal.id"
                                 [onRemovePosition]="removeDealPosition"></e2b-deal-position>
            </div>
            <h4 class="ui dividing header">Скан чек-листа показа</h4>
            <div class="ui field">
              <div *ngIf="!editFiles">
                <div *ngIf="checkListScan.value && checkListScan.value.length">
                  <div class="d-flex flex-wrap">
                    <button class="ui tiny basic button" (click)="editFiles = true;">
                      <i class="plus icon pointer m-0"></i>&nbsp;&nbsp;Добавить файлы
                    </button>
                  </div>
                </div>
                <div *ngIf="!checkListScan.value">
                  <button class="ui tiny basic button" (click)="editFiles = true;">
                    <i class="plus icon pointer m-0"></i>&nbsp;&nbsp;Добавить файлы
                  </button>
                </div>
              </div>
              <div *ngIf="editFiles">
                <div class="ui input">
                  <input type="file" id="file" name="file" #fileFieldForm multiple
                         (change)="changeFiles(fileFieldForm.files)">
                  <label for="file">
                    <span *ngIf="!checkListScan.value">Прикрепить файл(ы)</span>
                    <span *ngIf="checkListScan.value">Выбрано {{checkListScan.value.length}} файл(а)</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui sixteen wide column">
          <button class="ui green button" [disabled]="!isDealValid" (click)="saveDeal()">Далее</button>
        </div>
      </div>
    </form>
  </div>
  <div class="ui sixteen wide column" *ngIf="step === 2">
    <div class="ui form">
      <div class="ui stackable grid">
        <div class="ui eight wide column">
          <h3 class="ui header">Итог показа</h3>
          <div class="two fields">
            <div class="field">
              <label class="fw-bold">Дата начала показа</label>
              <input [(ngModel)]="currentShowTodo.from" autocomplete="off" name="currentShowStartDate" pickerMode="datetime" suiDatepicker [pickerUseNativeOnMobile]="true">
            </div>
            <div class="field">
              <label class="fw-bold">Дата окончания показа</label>
              <input [(ngModel)]="currentShowTodo.to" [pickerMinDate]="currentShowTodo.from" autocomplete="off" name="currentShowFinishDate" pickerMode="time" suiDatepicker [pickerUseNativeOnMobile]="true">
            </div>
          </div>
          <div class="field">
            <label class="fw-bold">Комментарий</label>
            <textarea placeholder="Комментарий к выполнению"
                      name="description"
                      [(ngModel)]="currentShowTodo.finishComment">
            </textarea>
          </div>
          <div class="ui inline fields">
            <div class="field">
              <sui-radio-button name="todoStatus" [value]="3" [(ngModel)]="currentShowTodo.status.id">Завершено успешно</sui-radio-button>
            </div>
            <div class="field">
              <sui-radio-button name="todoStatus" [value]="4" [(ngModel)]="currentShowTodo.status.id">Неуспешно</sui-radio-button>
            </div>
          </div>
        </div>
        <div class="ui eight wide column">
          <h3 class="ui header">Следующая коммуникация</h3>
          <div class="ui field">
            <label class="fw-bold">Тип</label>
            <sui-select class="fluid selection"
                        placeholder="Тип"
                        #selectType
                        [isSearchable]="false"
                        labelField="name"
                        [options]="todoTypes"
                        [(ngModel)]="nextCommunication.type"
                        [ngModelOptions]="{name: 'type'}">
              <sui-select-option *ngFor="let type of selectType.filteredOptions | orderByColumn:'name:string'"
                                 [value]="type">
              </sui-select-option>
            </sui-select>
          </div>
          <div class="two fields">
            <div class="field">
              <label class="fw-bold">Дата начала</label>
              <input [(ngModel)]="nextCommunication.from" autocomplete="off" name="nextCommunicationStartDate" pickerMode="datetime" suiDatepicker [pickerUseNativeOnMobile]="true">
            </div>
            <div class="field">
              <label class="fw-bold">Дата конца</label>
              <input [(ngModel)]="nextCommunication.to" [pickerMinDate]="nextCommunication.from" autocomplete="off" name="nextCommunicationFinishDate" pickerMode="time" suiDatepicker [pickerUseNativeOnMobile]="true">
            </div>
          </div>
          <div class="ui field">
            <label class="fw-bold">Что будем делать?</label>
            <input type="text" [(ngModel)]="nextCommunication.name" name="nextCommunicationName">
          </div>
        </div>
        <div class="ui sixteen wide column">
          <button class="ui green button" [disabled]="!isCurrentShowValid || !isNextCommunicationValid" (click)="saveCommunications()">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</div>
