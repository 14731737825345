import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingModel } from '../_models/setting.model';
import { BaseService } from './base.service';

@Injectable()
export class SettingService extends BaseService {

  baseUrl = '/setting';

  /**
   * Получение объекта по идентификатору
   * @param {number} id
   * @returns {Observable<T>}
   */
  findById<T>(id: number): Observable<T> {
    return this.http.get<T>(this.baseUrl + '/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  find<T>(): Observable<T> {
    return this.http.get<T>(this.baseUrl + '/', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  /**
   * Изменение объекта
   * @param {SettingModel} model
   * @param {number} id
   * @returns {Observable<T>}
   */
  update<T>(model: SettingModel): Observable<T> {
    return this.http.put<T>(this.baseUrl + '/' + model.id, model, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

}
