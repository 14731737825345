<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui form">
        <table class="ui very basic selectable celled table">
          <thead>
          <tr>
            <th>
              <div class="head">Название</div>
            </th>
            <th>
              <div class="head">Артикул</div>
            </th>
            <th>
              <div class="head">16</div>
            </th>
            <th>
              <div class="head">25</div>
            </th>
            <th>
              <div class="head">кромка 16Ф</div>
            </th>
            <th>
              <div class="head">кромка 25</div>
            </th>
            <th>
              <div class="head">наценка, фасад</div>
            </th>
            <th>
              <div class="head">наценка, каркас</div>
            </th>
          </tr>
          </thead>
          <tbody *ngFor="let material of materials | orderByColumn:'article:string'">
          <tr>
            <td width="20%">
              <div class="field">
                <div class="ui input">
                  <input type="text" [disabled]="!userService.checkPermissions(['position-material', '/'], ['write'])" [(ngModel)]="material.name" (change)="save(material)">
                </div>
              </div>
            </td>
            <td width="10%">
              <div class="field">
                <div class="ui input">
                  <input type="text" [disabled]="!userService.checkPermissions(['position-material', '/'], ['write'])" [(ngModel)]="material.article" (change)="save(material)">
                </div>
              </div>
            </td>
            <td>
              <div class="field">
                <input type="text" [disabled]="!userService.checkPermissions(['position-material', '/'], ['write'])" [(ngModel)]="material.price16" (change)="save(material)">
              </div>
            </td>
            <td>
              <div class="field">
                <input type="number" [disabled]="!userService.checkPermissions(['position-material', '/'], ['write'])" [(ngModel)]="material.price25" (change)="save(material)">
              </div>
            </td>
            <td>
              <div class="field">
                <input type="number" [disabled]="!userService.checkPermissions(['position-material', '/'], ['write'])" [(ngModel)]="material.price16k" (change)="save(material)">
              </div>
            </td>
            <td>
              <div class="field">
                <input type="number" [disabled]="!userService.checkPermissions(['position-material', '/'], ['write'])" [(ngModel)]="material.price25k" (change)="save(material)">
              </div>
            </td>
            <td>
              <div class="field">
                <input type="number" [disabled]="!userService.checkPermissions(['position-material', '/'], ['write'])" [(ngModel)]="material.priceFacade" (change)="save(material)">
              </div>
            </td>
            <td>
              <div class="field">
                <input type="number" [disabled]="!userService.checkPermissions(['position-material', '/'], ['write'])" [(ngModel)]="material.priceFrame" (change)="save(material)">
              </div>
            </td>
            <td>
              <i class="ui red remove cursor icon" *ngIf="userService.checkPermissions(['position-material', '/'], ['write'])" (click)="remove(material)"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
