<div class="ui form">
  <ng-container *ngFor="let orderFieldValue of orderFieldValues | orderByColumn: 'orderField.sort:object:number'">
    <div class="inline fields">
      <div class="five wide field">
        <label class="fw-bold">{{orderFieldValue.orderField.name}}</label>
      </div>
      <div [ngClass]="'eleven wide field' + (orderFieldValue.orderField.type === 'STEP' ? ' order-field-value' : '')">
        <div *ngIf="orderFieldValue.orderField.type === 'STEP'">
          <div>{{orderFieldValue.value ? orderFieldValue.value.date : 'Не заполнено'}}</div>
          <div class="d-flex flex-wrap" *ngIf="orderFieldValue.value">
            <div class="attach" *ngFor="let file of orderFieldValue.value.files | orderByColumn:'-date'">
              <e2b-file [file]="file"></e2b-file>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
