<form class="ui form">
  <div class="ui text menu">
    <div class="item">
      Бизнес единица:
    </div>
    <a class="header item" *ngFor="let u of units" [ngClass]="{'active': currentUnit === u}" (click)="selectUnit(u)">{{u.name}}</a>
  </div>
  <div class="ui stackable grid">
    <div class="ui eight wide column">
      <table class="ui very very basic table">
        <thead>
        <tr>
          <th>
            <div class="head">Бюджетная статья</div>
          </th>
          <th>
            <div class="head">Неделя</div>
          </th>
          <th>
            <div class="head">Месяц</div>
          </th>
          <th>
            <div class="head">Процент</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of references.month | keyvalue:ascOrder">
          <td>
            <div class="header">
              {{references.month[item.key].direction.name}}<br/>
              <span class="greytext" *ngIf="references.month[item.key].direction.parent && !references.month[item.key].direction.parent.parent">
                {{references.month[item.key].direction.parent.name}}
              </span>
              <span class="greytext" *ngIf="references.month[item.key].direction.parent && references.month[item.key].direction.parent.parent">
                {{references.month[item.key].direction.parent.parent.name}} -> {{references.month[item.key].direction.parent.name}}
              </span>
            </div>
          </td>
          <td>
            <div class="ui field">
              <input type="number" [(ngModel)]="references.week[item.key].amount" [ngModelOptions]="{name: 'week' + item.key, updateOn: 'blur'}" (ngModelChange)="save(references.week[item.key])"/>
            </div>
          </td>
          <td>
            <div class="ui field">
              <input type="number" [(ngModel)]="references.month[item.key].amount" [ngModelOptions]="{name: 'month' + item.key, updateOn: 'blur'}" (ngModelChange)="save(references.month[item.key])"/>
            </div>
          </td>
          <td>
            <div class="ui field">
              <input type="number" [(ngModel)]="references.month[item.key].percent" [ngModelOptions]="{name: 'percent' + item.key, updateOn: 'blur'}" (ngModelChange)="save(references.month[item.key])"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="ui eight wide column">
      <div class="ui grid">
        <div class="ui six wide column">

        </div>
        <div class="ui ten wide column">

        </div>
      </div>
    </div>
  </div>
</form>
