import { EventEmitter } from '@angular/core';

export interface Error {
  title: string;
  message: any;
}

export class ErrorService {
  addError: EventEmitter<Error> = new EventEmitter();

  pushError(error: any, title: string) {
    this.addError.emit({
      title,
      message: error.error && error.error.message ? error.error.message : error.message
    });
  }
}
