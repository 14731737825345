import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

@Injectable()
export class TodoCheckpointService extends BaseService {
  baseUrl = '/todocheckpoint';

  done(todoCheckpointId: number, currentState: { value: string }) {
    return this.http.put(this.baseUrl + '/done', { todoCheckpointId: todoCheckpointId, currentState }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
