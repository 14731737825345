<div class="field">
  <div class="ui fluid input">
    <input type="text" #drp
           [disabled]="disabled"
           name="drp"
           autocomplete="off"
           readonly
           placeholder="Период">
  </div>
</div>
