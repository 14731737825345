import {Component, EventEmitter, Input, ViewEncapsulation} from '@angular/core';
import {FileInterface} from '../_services/file.service';

@Component({
  selector: 'e2b-file',
  templateUrl: './file.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FileComponent {

  @Input()
  public file: FileInterface;

  @Input()
  public onDelete: EventEmitter<FileInterface>;

  public show = false;


}
