import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable()
export class DealStatusService extends BaseService {
  baseUrl = '/dealStatus';

  relations(statusId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/relation/' + statusId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

}
