import { Component, EventEmitter, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';
import * as moment from 'moment';
import 'semantic-ui-daterangepicker';
import '../../assets/js/daterangepicker.js';

@Component({
  selector: 'e2b-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/input.min.css',
    '../../assets/design/semantic-ui/components/form.min.css',
    './daterangepicker.component.less'
  ],
  encapsulation: ViewEncapsulation.None
})
export class DaterangepickerComponent {
  @ViewChild('drp', { static: false })
  daterangepicker;

  date: any;
  @Input()
  public event: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  disabled: boolean;

  @Input()
  set dates(date) {
    this.date = date;
    this.render();
  }

  render() {
    moment.locale('ru');

    $(() => {
        $(this.daterangepicker.nativeElement).daterangepicker({
          locale: {
            applyLabel: 'Применить',
            cancelLabel: 'Отмена',
            customRangeLabel: 'Задать',
            daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            firstDay: 1
          },
          format: 'D MMM, YYYY',
          autoApply: true,
          alwaysShowCalendars: true,
          showDropdowns: false,
          showWeekNumbers: false,
          opens: 'right',
          ranges: {
            'Вчера': [moment().subtract(1, 'day'), moment().subtract(1, 'day').subtract(3, 'hours')],
            'Сегодня': [moment().startOf('day'), moment().endOf('day').subtract(3, 'hours')],
            'Завтра': [moment().add(1, 'day').startOf('day'), moment().add(1, 'day').endOf('day').subtract(3, 'hours')],
            'Текущая неделя': [moment().startOf('week'), moment().endOf('week')],
            'Текущий месяц': [moment().startOf('month'), moment().endOf('month')],
            'Текущий год': [moment().startOf('year'), moment().endOf('month')]
          },
          startDate: this.date.startDate,
          endDate: this.date.endDate
        }, (start, end) => {
          this.date.startDate = start;
          this.date.endDate = end;
          this.event.emit(this.date);
        });
        setTimeout(() => {
          if (this.date.startDate) {
            $(this.daterangepicker.nativeElement).data('daterangepicker').setStartDate(moment(this.date.startDate, 'YYYY-MM-DD'));
          }
          if (this.date.endDate) {
            $(this.daterangepicker.nativeElement).data('daterangepicker').setEndDate(moment(this.date.endDate, 'YYYY-MM-DD'));
          }
        }, 100);
      }
    );
  }
}
