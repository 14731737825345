import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../_services/user.service';

@Pipe({
  name: 'checkValuePermissionPipe',
  pure: false
})

@Injectable()
export class CheckValuePermissionPipe implements PipeTransform {

  constructor(private userService: UserService) {
  }

  transform(values: any[], args: any) {
    if (!values) {
      return [];
    }
    if (!this.userService.current() && !this.userService.current().id) {
      return [];
    }
    return values.filter(value => {
      return this.userService.current().accessObject.fields[value.field.id] && ['read', 'write'].indexOf(this.userService.current().accessObject.fields[value.field.id]) > -1;
    });
  }
}
