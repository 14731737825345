import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from '../../_models/client.model';
import { LkBaseService } from './lk-base.service';

@Injectable({
  providedIn: 'root'
})
export class LkClientService extends LkBaseService {
  private currentClient: any;
  baseUrl = '/customer';

  current(): any {
    if (!this.currentClient) {
      this.currentClient = JSON.parse(localStorage.getItem('e2b.lk.client.json'));
    }
    return this.currentClient || <any>{};
  }

  fetchCurrent(): Observable<any> {
    return this.http.get<any>('/lk' + this.baseUrl + '/check', { withCredentials: true, headers: this.headers });
  }

  isAuthenticated(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.getToken()) {
        return reject('No token found');
      }
      this.fetchCurrent().subscribe((res: any) => {
        if (res.id) {
          localStorage.setItem('e2b.lk.client.json', JSON.stringify(res));
          this.currentClient = res;
          return resolve();
        }
        return reject();
      }, e => {
        return reject(e);
      });
    });
  }

  private getToken() {
    return localStorage.getItem('e2b.lk.client.token');
  }

  requestSmsCode(credentials) {
    return this.http.post('/lk' + this.baseUrl + '/code', credentials, { withCredentials: true, headers: { 'X-Skip-Interceptor': '' } });
  }

  login(user: Client): Observable<any> {
    return this.http.post('/lk' + this.baseUrl + '/login', user, { withCredentials: true, headers: { 'X-Skip-Interceptor': '' } })
      .pipe(map((res: any) => {
        localStorage.setItem('e2b.lk.client.clientId', res.id.toString());
        localStorage.setItem('e2b.lk.client.token', res.token);
        return res;
      }));
  }

  logout(): Observable<any> {
    return this.http.get('/lk' + this.baseUrl + '/logout', { withCredentials: true, headers: this.headers })
      .pipe(map(res => {
        this.currentClient = null;
        this.clearStorage();
        return res;
      }));
  }

  updatePersonalInfo(client: any) {
    return this.http.put('/lk' + this.baseUrl, client, { withCredentials: true, headers: this.headers });
  }

  clearStorage() {
    localStorage.removeItem('e2b.lk.client.clientId');
    localStorage.removeItem('e2b.lk.client.json');
    localStorage.removeItem('e2b.lk.client.token');
  }
}
