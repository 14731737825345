import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SuiModule } from 'ng2-semantic-ui';
import { DndModule } from 'ngx-drag-drop';
import { LoggedInUserGuard } from '../_guards/logged-in-user.guard';
import { PermissionGuard } from '../_guards/permission.guard';
import { SharedModule } from '../shared/shared.module';
import { DealAddComponent } from './deal-add/deal-add.component';
import { DealCalendarComponent } from './deal-calendar/deal-calendar.component';
import { DealGeographyComponent } from './deal-geography/deal-geography.component';
import { DealListComponent } from './deal-list/deal-list.component';
import { DealFilterPipe } from './deal-list/deal-list.pipe';
import { DealShowResolve } from './deal-show/deal-show-resolve';
import { DealShowComponent } from './deal-show/deal-show.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { OrderModeService } from './order/order-mode.service';
import { OrderComponent } from './order/order.component';
import { ProductionShowComponent } from './production/production.component';
import { TemplateListComponent } from './template/template-list/template-list.component';
import { OrderAddComponent } from './order/order-add/order-add.component';
import { OrderCardComponent } from './order/order-card/order-card.component';
import { OrderFieldComponent } from './order/order-field/order-field.component';
import { OrderPositionFieldComponent } from './order/order-position-field/order-position-field.component';
import { OrderLabelComponent } from './order/order-label/order-label.component';

const routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
  {
    path: 'list',
    component: DealListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['deal', '/'],
      title: 'Olissys E2B | Список сделок',
      breadcrumb: 'Список сделок'
    }
  },
  {
    path: 'geography',
    component: DealGeographyComponent,
    data: {
      title: 'Olissys E2B | География сделок',
      breadcrumb: 'География сделок'
    }
  },
  {
    path: 'calendar',
    component: DealCalendarComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['deal', 'calendar', '/'],
      title: 'Olissys E2B | Календарь сделок',
      breadcrumb: 'Календарь сделок'
    }
  },
  {
    path: 'add',
    component: DealAddComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['deal', '/'],
      title: 'Olissys E2B | Новая сделка',
      breadcrumb: 'Новая сделка'
    }
  },
  {
    path: 'order/production/:id',
    component: ProductionShowComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['production', 'order', '/'],
      title: 'Olissys E2B | Карточка заказа',
      breadcrumb: 'Карточка заказа'
    }
  },
  {
    path: 'card/:id',
    component: DealShowComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['deal', '/'],
      title: 'Olissys E2B | Карточка сделки',
      breadcrumb: 'Карточка сделки'
    },
    resolve: {
      hero: DealShowResolve
    },
  }, {
    path: 'template',
    component: TemplateListComponent,
    canActivate: [LoggedInUserGuard, PermissionGuard],
    data: {
      access: ['template', '/'],
      title: 'Olissys E2B | Шаблоны договоров',
      breadcrumb: 'Шаблоны договоров'
    }
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SuiModule,
    DndModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    ProductionShowComponent,
    DealFilterPipe,
    DealCalendarComponent,
    DealAddComponent,
    DealShowComponent,
    DealListComponent,
    DealGeographyComponent,
    TemplateListComponent,
    OrderAddComponent,
    OrderCardComponent,
    OrderListComponent,
    OrderComponent,
    OrderFieldComponent,
    OrderPositionFieldComponent,
    OrderLabelComponent,
  ],
  providers: [
    DealShowResolve,
    OrderModeService,
  ],
  exports: [
    DealAddComponent
  ]
})
export class DealModule {
}
