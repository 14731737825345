import { Component, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Person } from '../../../_models/person.model';
import { OwnService } from '../../../_services/own.service';

@Component({
  selector: 'e2b-my-company-dropdown',
  templateUrl: './my-company.dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class MyCompanyDropdownComponent implements OnInit {

  @ViewChild('selectPerson', { static: false })
  selectPersonView;
  @Input()
  public persons: Person[] = [];
  @Input()
  public event: EventEmitter<Person>;
  public selectedPerson: Person;

  constructor(private readonly ownService: OwnService) {
  }

  @Input()
  set selected(person: Person) {
    this.selectedPerson = person || null;
  }

  async ngOnInit() {
    this.persons = await this.ownService.find<Person>({ list: true }).toPromise();
    this.updateSelect();
  }

  updateSelect() {
    if (this.selectedPerson && this.persons) {
      this.selectedPerson = this.persons.find(person => person.id === this.selectedPerson.id);
    }
  }

  save() {
    this.event.emit(this.selectedPerson);
  }
}
