import { EventEmitter } from '@angular/core';

export interface Action {
  name: string;
  link: string;
  class: string;
  icon?: string;
  roles?: string[];
}

export interface Event {
  name: string;
  event: EventEmitter<any>;
  class: string;
  icon?: string;
  roles?: string[];
}

export class ActionService {
  createMenuEvent: EventEmitter<Action> = new EventEmitter();
  createActionEvent: EventEmitter<Event> = new EventEmitter();
  clear: EventEmitter<void> = new EventEmitter();
}
