import { Component, EventEmitter, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ServiceService } from '../../../_services/service.service';
import { UserService } from '../../../_services/user.service';

interface ServicePointInterface {
  delivery: {
    plan: number;
    planDetails: {
      id: number;
      name: string;
      created: string;
      currentStatus: {
        id: number;
        name: string;
        color: string;
      },
      filial: {
        id: number;
        name: string;
        color: string;
      },
      client: {
        id: number;
        name: string;
      },
      date: string;
    }[];
    fact: number;
    working: number;
    moved: number;
    moveDetails: {
      id: number;
      name: string;
      currentStatus: {
        id: number;
        name: string;
      },
      filial: {
        id: number;
        name: string;
      },
      client: {
        id: number;
        name: string;
      },
      from: string;
      to: string;
    }[]
  };
  mounting: {
    plan: number;
    planDetails: {
      id: number;
      name: string;
      created: string;
      currentStatus: {
        id: number;
        name: string;
        color: string;
      },
      filial: {
        id: number;
        name: string;
        color: string;
      },
      client: {
        id: number;
        name: string;
      },
      date: string;
    }[];
    fact: number;
    working: number;
    moved: number;
    moveDetails: {
      id: number;
      name: string;
      currentStatus: {
        id: number;
        name: string;
      },
      filial: {
        id: number;
        name: string;
      },
      client: {
        id: number;
        name: string;
      },
      from: string;
      to: string;
    }[]
  };
  reclamation: {
    plan: number;
    planDetails: {
      id: number;
      name: string;
      created: string;
      currentStatus: {
        id: number;
        name: string;
        color: string;
      },
      filial: {
        id: number;
        name: string;
        color: string;
      },
      client: {
        id: number;
        name: string;
      },
      date: string;
    }[];
    fact: number;
    working: number;
    moved: number;
    moveDetails: {
      id: number;
      name: string;
      currentStatus: {
        id: number;
        name: string;
      },
      filial: {
        id: number;
        name: string;
      },
      client: {
        id: number;
        name: string;
      },
      from: string;
      to: string;
    }[]
  };
  reclamationLosses: number;
  problems: {
    present: {
      all: number;
      opened: number;
      cost: number;
    },
    previous: {
      all: number;
      opened: number;
      cost: number;
    }
  };
}

@Component({
  selector: 'e2b-dashboard-director-service',
  templateUrl: './dashboard-director-service.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/statistic.min.css',
    '../../../../assets/design/semantic-ui/components/card.min.css',
    '../../../../assets/design/semantic-ui/components/image.min.css',
    '../../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../../assets/design/semantic-ui/components/list.min.css',
    './dashboard-director-service.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardDirectorServiceComponent implements OnInit {

  loaded = false;

  details: any[] = [];
  planDetails: any[] = [];

  @Input()
  period: 'day' | 'week' | 'month' = localStorage.getItem('e2b.dashboard.director.period') ? localStorage.getItem('e2b.dashboard.director.period') as 'day' | 'week' | 'month' : 'month';

  @Input()
  day: Moment = localStorage.getItem('e2b.dashboard.director.date') ? moment(localStorage.getItem('e2b.dashboard.director.date'), 'YYYY-MM-DD') : moment();

  widgets = {
    'delivery': 'Доставки',
    'mounting': 'Монтажи',
    'reclamation': 'Рекламации',
    'reclamationLosses': 'Рекламации: Потери',
    'problem': 'Проблемы',
    'problemLosses': 'Проблемы: Потери',
  };
  selectedWidgets: string[] = localStorage.getItem('e2b.dashboard.director.service') ? JSON.parse(localStorage.getItem('e2b.dashboard.director.service')) : this.allWidgets;

  options: {
    date: string,
  } = {
    date: this.day.format('YYYY-MM-DD')
  };

  data: {
    day: ServicePointInterface,
    week: ServicePointInterface,
    month: ServicePointInterface
  } = {
    day: {
      delivery: {
        plan: 0,
        fact: 0,
        working: 0,
        moved: 0,
        moveDetails: [],
        planDetails: []
      },
      mounting: {
        plan: 0,
        fact: 0,
        working: 0,
        moved: 0,
        moveDetails: [],
        planDetails: []
      },
      reclamation: {
        plan: 0,
        fact: 0,
        working: 0,
        moved: 0,
        moveDetails: [],
        planDetails: []
      },
      reclamationLosses: 0,
      problems: {
        present: {
          all: 0,
          opened: 0,
          cost: 0,
        },
        previous: {
          all: 0,
          opened: 0,
          cost: 0,
        }
      }
    },
    week: {
      delivery: {
        plan: 0,
        fact: 0,
        working: 0,
        moved: 0,
        moveDetails: [],
        planDetails: []
      },
      mounting: {
        plan: 0,
        fact: 0,
        working: 0,
        moved: 0,
        moveDetails: [],
        planDetails: []
      },
      reclamation: {
        plan: 0,
        fact: 0,
        working: 0,
        moved: 0,
        moveDetails: [],
        planDetails: []
      },
      reclamationLosses: 0,
      problems: {
        present: {
          all: 0,
          opened: 0,
          cost: 0,
        },
        previous: {
          all: 0,
          opened: 0,
          cost: 0,
        }
      }
    },
    month: {
      delivery: {
        plan: 0,
        fact: 0,
        working: 0,
        moved: 0,
        moveDetails: [],
        planDetails: []
      },
      mounting: {
        plan: 0,
        fact: 0,
        working: 0,
        moved: 0,
        moveDetails: [],
        planDetails: []
      },
      reclamation: {
        plan: 0,
        fact: 0,
        working: 0,
        moved: 0,
        moveDetails: [],
        planDetails: []
      },
      reclamationLosses: 0,
      problems: {
        present: {
          all: 0,
          opened: 0,
          cost: 0,
        },
        previous: {
          all: 0,
          opened: 0,
          cost: 0,
        }
      }
    }
  };
  innerWidth: number;
  selectedType: any;
  selectedPlanType: any;

  constructor(public userService: UserService,
              private readonly serviceService: ServiceService) {
  }

  @Input()
  set dayEvent(event: EventEmitter<any>) {
    event.subscribe(day => {
      this.day = day;
      this.options.date = this.day.format('YYYY-MM-DD');
      this.load();
    });
  }

  @Input()
  set periodEvent(event: EventEmitter<any>) {
    event.subscribe(period => {
      this.period = period;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  isSelected(moved, type) {
    if (this.selectedType !== type) {
      return false;
    }
    const ids = [];
    if (moved && moved.length > 0) {
      moved.map(d => {
        ids.push(d.id);
      });
    }
    let result = true;
    this.details.map(d => {
      if (!ids.includes(d.id)) {
        result = false;
      }
    });
    if (this.details.length === 0) {
      result = false;
    }
    if (moved.length === 0) {
      result = false;
    }
    return result;
  }

  isPlanSelected(moved, type) {
    if (this.selectedPlanType !== type) {
      return false;
    }
    const ids = [];
    if (moved && moved.length > 0) {
      moved.map(d => {
        ids.push(d.id);
      });
    }
    let result = true;
    this.planDetails.map(d => {
      if (!ids.includes(d.id)) {
        result = false;
      }
    });
    if (this.planDetails.length === 0) {
      result = false;
    }
    if (moved.length === 0) {
      result = false;
    }
    return result;
  }

  ngOnInit() {

    this.innerWidth = window.innerWidth;

    this.load();
  }

  load() {
    this.serviceService.find<any>(this.options).subscribe((result: any) => {
      this.data = result;
      this.loaded = true;
    });
  }

  get allWidgets(): string[] {
    return Object.keys(this.widgets);
  }

  selectWidget(widgetName) {
    if (this.selectedWidgets.includes(widgetName)) {
      const widgetIndex = this.selectedWidgets.indexOf(widgetName);
      this.selectedWidgets.splice(widgetIndex, 1);
    } else {
      this.selectedWidgets.push(widgetName);
    }
    localStorage.setItem('e2b.dashboard.director.service', JSON.stringify(this.selectedWidgets));
  }

  public getPreviousQueryFrom() {
    switch (this.period) {
      case 'day':
        return this.day.clone().subtract(1, 'week').startOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    }
  }

  public getPreviousQueryTo() {
    switch (this.period) {
      case 'day':
        return this.day.clone().subtract(1, 'week').endOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    }
  }

  public getQueryFrom() {
    switch (this.period) {
      case 'day':
        return this.day.clone().startOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().startOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().startOf('month').format('YYYY-MM-DD');
    }
  }

  public getQueryTo() {
    switch (this.period) {
      case 'day':
        return this.day.clone().endOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().endOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().endOf('month').format('YYYY-MM-DD');
    }
  }

  public selectMoved(moveDetails, type) {
    if (this.selectedType === type) {
      this.selectedType = null;
      this.details = [];
    } else {
      this.details = moveDetails;
      this.selectedType = type;
    }
  }

  public selectPlan(planDetails, type) {
    if (this.selectedPlanType === type) {
      this.selectedPlanType = null;
      this.planDetails = [];
    } else {
      this.planDetails = planDetails;
      this.selectedPlanType = type;
    }
  }
}
