import { BaseModel } from './base.model';
import { PositionGroupTemplate } from './position-group-template.model';
import { Position } from './position.model';

export class PositionGroup extends BaseModel {
  name: string;
  article: string;
  deleted: boolean;
  template: PositionGroupTemplate;
  positions: Position[];
}
