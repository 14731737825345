<div class="ui fluid basic card" style="margin-top: 0 !important;">
  <div class="content">
    <div class="header">
      <div class="d-flex justify-content-center align-items-center">
        <button class="ui button" (click)="prevDay();">
          <i class="angle left icon"></i>
        </button>
        &nbsp;&nbsp;
        <h3 class="fw-bold m-0 header">
          <input suiDatepicker
                 #date
                 style="width: 0 !important; height: 0 !important; border: 0 !important; padding: 0 !important;"
                 [(ngModel)]="selectedDate"
                 (ngModelChange)="onSelectDate($event)"
                 [pickerMode]="'date'"
                 [pickerUseNativeOnMobile]="true">
          БОРД ДИРЕКТОРА&nbsp;&nbsp;<a (click)="onDate()">{{day.format('DD MMM, YY г.')}}</a>&nbsp;&nbsp;
        </h3>
        &nbsp;&nbsp;
        <button class="ui button" (click)="nextDay();">
          <i class="angle right icon"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="ui stackable grid">
  <div class="ui five wide column"></div>
  <div class="ui six wide column">
    <div class="ui secondary stackable three item pointing menu">
      <a class="item" (click)="selectPeriod('day')" [ngClass]="{'active': period === 'day'}">День</a>
      <a class="item" (click)="selectPeriod('week')" [ngClass]="{'active': period === 'week'}">Неделя</a>
      <a class="item" (click)="selectPeriod('month')" [ngClass]="{'active': period === 'month'}">Месяц</a>
    </div>
  </div>
  <div class="ui five wide column"></div>
</div>
<div class="ui stackable grid">
  <div class="ui sixteen wide column" *ngIf="userService.checkPermissions(['budget', '/'])">
    <e2b-dashboard-director-budget [dayEvent]="dayEvent" [periodEvent]="periodEvent"></e2b-dashboard-director-budget>
  </div>
  <div class="ui sixteen wide column" *ngIf="userService.checkPermissions(['finance', '/'])">
    <e2b-dashboard-director-finance [dayEvent]="dayEvent" [periodEvent]="periodEvent"></e2b-dashboard-director-finance>
  </div>
  <div class="ui sixteen wide column" *ngIf="userService.checkPermissions(['sale', '/'])">
    <e2b-dashboard-director-sale [dayEvent]="dayEvent" [periodEvent]="periodEvent"></e2b-dashboard-director-sale>
  </div>
  <div class="ui sixteen wide column" *ngIf="userService.checkPermissions(['service', '/'])">
    <e2b-dashboard-director-service [dayEvent]="dayEvent" [periodEvent]="periodEvent"></e2b-dashboard-director-service>
  </div>
</div>
