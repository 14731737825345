import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable()
export class ReminderService extends BaseService {
  baseUrl = '/reminder';

  findByDeal(dealId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/deal/' + dealId,
      {
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }

  findByTask(taskId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/task/' + taskId,
      {
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }

  findByProject(projectId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/project/' + projectId,
      {
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }

  findByChecklist(checklistId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/checklist/' + checklistId,
      {
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }
}
