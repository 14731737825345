import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClientType } from '../../_models/client-type.model';
import { Client } from '../../_models/client.model';
import { ContractorService } from '../../_services/contractor.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/menu.min.css',
    '../../../assets/design/semantic-ui/components/table.min.css',
    './contractors.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContractorsComponent implements OnInit, OnDestroy {
  contractors: Array<Client>;
  types: Array<ClientType>;
  loaded = false;
  private listSubscribe: Subscription;

  constructor(private contractorService: ContractorService,
              private userService: UserService,
              private actionService: ActionService) {
  }

  ngOnInit() {
    if (this.userService.checkPermissions(['contractor', '/'], ['write'])) {
      this.actionService.createMenuEvent.emit({ name: 'Добавить подрядчика', link: '/client/contractor/add', class: 'blue active' });
    }

    this.listSubscribe = this.contractorService.find<Client>().subscribe(res => {
      this.contractors = res;
      this.loaded = true;
    });

  }

  ngOnDestroy() {
    this.listSubscribe.unsubscribe();
  }

}
