import {Component, EventEmitter, OnInit, ViewEncapsulation} from '@angular/core';
import {PositionGroup} from '../../_models/position-group.model';
import {Position} from '../../_models/position.model';
import {FileService} from '../../_services/file.service';
import {PositionFieldValueService} from '../../_services/position-field-value.service';
import {PositionGroupService} from '../../_services/position-group.service';
import {PositionService} from '../../_services/position.service';
import {UserService} from '../../_services/user.service';
import {Action, ActionService, Event} from '../../action/action.service';

@Component({
  selector: 'e2b-position-list',
  templateUrl: './position-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css',
    './position-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PositionListComponent implements OnInit {

  groups: PositionGroup[] = [];
  positions: Position[] = [];

  types: [{
    id: 1,
    name: 'Корпусная мебель'
  }, {
    id: 2,
    name: 'Мягкая мебель'
  }, {
    id: 3,
    name: 'Матрас'
  }];
  loaded = false;

  constructor(private fileService: FileService,
              private actionService: ActionService,
              private userService: UserService,
              private positionService: PositionService,
              private positionFieldValueService: PositionFieldValueService,
              private positionGroupService: PositionGroupService) {

  }

  ngOnInit() {

    const reload = new EventEmitter();

    if (this.userService.checkPermissions(['position-group', '/'])) {
      this.actionService.createMenuEvent.emit(<Action>{name: 'Группы', link: '/catalog/group/list', class: 'yellow active'});
    }

    if (this.userService.checkPermissions(['position-group-template', '/'])) {
      this.actionService.createMenuEvent.emit(<Action>{name: 'Шаблоны', link: '/catalog/template/list', class: 'green active'});
    }

    if (this.userService.checkPermissions(['position', '/'], ['write'])) {
      this.actionService.createMenuEvent.emit(<Action>{name: 'Добавить товар', link: '/catalog/add', class: 'blue active'});
    }
    this.actionService.createActionEvent.emit(<Event>{name: 'Обновить', event: reload, class: 'blue active'});

    reload.subscribe(() => {
      this.load();
    });

    this.load();

  }

  load() {
    this.positionService.find<Position>().subscribe(positions => {
      this.positions = positions;
      this.positionGroupService.find<PositionGroup>().subscribe(groups => {
        this.groups = groups;
        this.loaded = true;
      });
    });
  }

  save(position: Position) {
    this.positionService.update<Position>(position).subscribe(() => {
    });
  }

  remove(position) {
    const yes = confirm('Вы уверены, что хотите удалить товар ' + position.name + '?');
    if (!yes) {
      return true;
    }
    this.positionService.deleteById(position.id).subscribe(() => {
      this.positions.splice(this.positions.indexOf(position), 1);
    });
  }
}
