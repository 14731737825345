import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Client } from '../../_models/client.model';
import { OwnService } from '../../_services/own.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-my-company',
  templateUrl: './my-company.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/table.min.css',
    './my-company.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class MyCompanyComponent implements OnInit, OnDestroy {
  loaded = false;
  clients: Array<Client>;
  private listSubscribe: Subscription;

  constructor(private actionService: ActionService,
              private userService: UserService,
              private ownService: OwnService) {
  }

  ngOnInit() {
    if (this.userService.checkPermissions(['own', '/'], ['write'])) {
      this.actionService.createMenuEvent.emit({ name: 'Добавить', link: '/client/my-company/add', class: 'blue active' });
    }
    this.listSubscribe = this.ownService.find<Client>().subscribe(res => {
      this.clients = res;
      this.loaded = true;
    });
  }

  ngOnDestroy() {
    this.listSubscribe.unsubscribe();
  }
}
