import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Unit } from '../_models/unit.model';
import { BaseService } from './base.service';

@Injectable()
export class ReferenceService extends BaseService {
  protected baseUrl = '/reference';

  find(unit: Unit): Observable<any> {
    const params = new HttpParams().set('unit', unit.id.toString());
    return this.http.get<any>(
      this.baseUrl,
      {
        params,
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }
}
