<h3 class="header">
  Контакты
</h3>
<div class="ui fluid basic card" *ngFor="let contact of contacts">
  <div class="ui form" *ngIf="editContact === contact" style="padding-bottom: 30px !important;">
    <div class="field">
      <input type="text" name="name" [(ngModel)]="contact.name" placeholder="Название контакта">
    </div>
    <div class="field">
      <input type="text" name="email" [(ngModel)]="contact.email" placeholder="Email">
    </div>
    <div class="field">
      <input type="number" name="phone" [(ngModel)]="contact.phone" placeholder="Телефон">
    </div>
    <div class="field">
      <input type="text" name="address" [(ngModel)]="contact.address" placeholder="Адрес">
    </div>
    <div class="field">
                  <textarea rows="2" name="desc" [(ngModel)]="contact.description"
                            placeholder="Описание контакта"></textarea>
    </div>
    <div class="field">
      <sui-checkbox [(ngModel)]="contact.isMain">Основной контакт</sui-checkbox>
    </div>
    <button class="ui green button" (click)="update(contact)">Сохранить</button>
    <button class="ui red button" (click)="remove(contact)">Удалить</button>
  </div>
  <div class="content" *ngIf="editContact !== contact">
    <i class="right floated edit grey cursor icon" (click)="editContact = contact"></i>
    <i class="right floated check circle green icon" *ngIf="contact.isMain"></i>
    <div class="mini header" *ngIf="contact.name">{{contact.name}}</div>
    <div class="meta" *ngIf="contact.description">
      {{contact.description}}
    </div>
    <div class="meta" *ngIf="contact.address">
      <span class="greytext">{{contact.address}}</span>
    </div>
    <div class="description" *ngIf="contact.email">
      <span>Email: <a href="mailto:{{contact.email}}">{{contact.email}}</a></span>
    </div>
    <div class="description" *ngIf="contact.phone">
      <span>Телефон: <a href="tel:{{contact.phone}}">{{contact.phone | phone}}</a></span>
    </div>
  </div>
  <div class="ui divider"></div>
</div>
<div class="ui message" *ngIf="contact">
  <i class="close icon" (click)="contact = null"></i>
  <div class="ui form">
    <div class="field">
      <input type="text" name="name" [(ngModel)]="contact.name" placeholder="Название контакта">
    </div>
    <div class="field">
      <input type="text" name="email" [(ngModel)]="contact.email" placeholder="Email">
    </div>
    <div class="field">
      <input type="number" name="phone" [(ngModel)]="contact.phone" placeholder="Телефон">
    </div>
    <div class="field">
      <input type="text" name="address" [(ngModel)]="contact.address" placeholder="Адрес">
    </div>
    <div class="field">
      <textarea rows="2" name="desc" [(ngModel)]="contact.description" placeholder="Описание контакта"></textarea>
    </div>
    <div class="field">
      <sui-checkbox [(ngModel)]="contact.isMain">Основной контакт</sui-checkbox>
    </div>
    <button class="ui green button" (click)="create()">Сохранить</button>
  </div>
</div>
<button class="ui basic green button" *ngIf="contact === null" [e2bPermission]="['contact', '/']" accessRightType="write" (click)="add()">+ Добавить</button>
