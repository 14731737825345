import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentPipe'
})

@Injectable()
export class MomentPipe implements PipeTransform {
  transform(date: any, from: string, to: string): any {
    return moment(date, from).format(to);
  }
}
