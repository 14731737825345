import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DealStatus } from '../../_models/deal-status.model';

@Component({
  selector: 'e2b-deal-status-label',
  templateUrl: './deal-status-label.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/label.min.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class DealStatusLabelComponent {
  @Input()
  status: DealStatus;

}
