import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'surname'
})
export class SurnamePipe implements PipeTransform {
  transform(value: string) {
    let businessPrefix = '';
    if (!value) {
      return value;
    }
    const company = value.match(/(["'«])(?:(?=(\\?))\2.)*?(\1|»)/);
    if (company) {
      return company[0];
    } else {
      const businessman = value.match(/(?:ИП |Индивидуальный предприниматель |Индивидуальный Предприниматель )(.+)/);
      if (businessman) {
        value = businessman[1];
        businessPrefix = 'ИП ';
      }
    }
    const split = value.split(' ').filter(l => l !== '');
    if (split.length === 1) {
      return businessPrefix + value;
    } else if (split.length === 2) {
      return businessPrefix + split[0].replace(/ /g, '') + ' ' + split[1][0].toUpperCase().replace(/ /g, '') + '.';
    } else if (split.length === 3) {
      return businessPrefix + split[0].replace(/ /g, '') + ' ' + split[1][0].toUpperCase().replace(/ /g, '') + '.' + ' ' + split[2][0].toUpperCase().replace(/ /g, '') + '.';
    } else {
      return businessPrefix + split[0].replace(/ /g, '') + ' ' + split[1][0].toUpperCase().replace(/ /g, '') + '.';
    }
  }
}
