<h3 class="fw-bold header">ПРОДАЖИ
  <div class="ui dropdown" suiDropdown style="display: inline !important;">
    <i class="blue cog icon"></i>
    <div class="menu" suiDropdownMenu>
      <a class="item" *ngFor="let widgetName of allWidgets">
        <div class="field">
          <input type="checkbox" [checked]="selectedWidgets.includes(widgetName)" (change)="selectWidget(widgetName)"/>
          <label> {{widgets[widgetName]}}</label>
        </div>
      </a>
    </div>
  </div>
</h3>
<div class="ui stackable cards" [ngClass]="{'three': innerWidth <= 1280, 'four': innerWidth > 1280, 'six': innerWidth > 1620}">
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('sale')">
    <div class="content">
      <div class="header">
        Продажи <i class="ui grey bullseye icon"></i> <i class="ui grey calendar outline icon"></i> <i class="ui grey chart line icon"></i>
      </div>
      <div class="description">
        <table class="ui very very basic table" style="overflow-y: auto !important;">
          <tr>
            <td>
              <span class="header">План</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <span class="padded" suiPopup popupInverted popupText="Сумма планов менеджеров">{{data[period].sales.plan | humanNumber}}</span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Факт</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'sale']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), dateModify: 'saleDate'}" [ngClass]="{'red': data[period].sales.fact < data[period].sales.plan}" suiPopup popupInverted popupText="Сумма сделок по дате поступления предоплаты">{{data[period].sales.fact | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Прогноз</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'prognosis']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted popupText="Прогноз продаж по дате ориентировочной покупки">{{data[period].sales.prognosis | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('managers')">
    <div class="content">
      <div class="header">
        Доска менеджеров <i class="ui grey bullseye icon"></i>
      </div>
      <div class="description">
        <div>
          <a class="padded">
            <img class="ui small avatar image" *ngIf="data[period].managers.best.user" [src]="(data[period].managers.best.user.avatar.path + '/' + data[period].managers.best.user.avatar.name) | secure"/> {{data[period].managers.best.user ? (data[period].managers.best.user.name|truncate:12) : ''}}
          </a>
          <a class="padded" *ngIf="data[period].managers.best.user">
            <i class="ui angle up green icon"></i>&nbsp;&nbsp;<span class="fsz-14px">{{data[period].managers.best.planPercent | number:'1.0-0'}}%</span>
          </a>
          <div *ngIf="!data[period].managers.best.user">Нет данных</div>
        </div>
        <div style="margin-top: 10px;">
          <a class="padded">
            <img class="ui small avatar image" *ngIf="data[period].managers.worst.user" [src]="(data[period].managers.worst.user.avatar.path + '/' + data[period].managers.worst.user.avatar.name) | secure"/> {{data[period].managers.worst.user ? (data[period].managers.worst.user.name|truncate:12) : ''}}
          </a>
          <a class="padded" *ngIf="data[period].managers.worst.user">
            <i class="ui angle down red icon"></i>&nbsp;&nbsp;<span class="fsz-14px">{{data[period].managers.worst.planPercent | number:'1.0-0'}}%</span>
          </a>
          <div *ngIf="!data[period].managers.worst.user">Нет данных</div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('meetings')">
    <div class="content">
      <div class="header">
        Встречи <i class="ui grey bullseye icon"></i> <i class="ui grey calendar outline icon"></i> <i class="ui grey chart line icon"></i>
      </div>
      <div class="description">
        <div class="ui mini statistic">
          <div class="value">
            <span class="padded" suiPopup popupInverted popupText="Кол-во встреч по планамы менеджеров">{{data[period].meets.plan | number}}</span>
            <a class="padded" target="_blank" [routerLink]="['/report', 'manager-activity']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), typeId: 1}" [ngClass]="{'green': data[period].meets.fact > data[period].meets.plan, 'red': data[period].meets.fact < data[period].meets.plan}" suiPopup popupInverted popupText="Кол-во проведенных встреч">{{data[period].meets.fact | number}}</a>
            <a class="padded"><i class="ui angle icon" [ngClass]="{'up green': data[period].meets.dynamic.trend === 'up', 'down red': data[period].meets.dynamic.trend === 'down'}"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('deals')">
    <div class="content">
      <div class="header">
        Новые сделки <i class="ui grey chart line icon"></i>
      </div>
      <div class="description">
        <div class="ui mini statistic">
          <div class="value">
            <a class="padded" target="_blank" [routerLink]="['/report', 'incoming-deals']" [queryParams]="{from: getPreviousQueryFrom(), to: getPreviousQueryTo()}" suiPopup popupInverted popupText="Прошлый период: Кол-во новых сделок (кроме СПАМ)">{{data[period].deals.previous | number}}</a>
            <a class="padded" target="_blank" [routerLink]="['/report', 'incoming-deals']" [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" [ngClass]="{'green': data[period].deals.present > data[period].deals.previous, 'red': data[period].deals.present < data[period].deals.previous}" suiPopup popupInverted
               popupText="Текущий период: Кол-во новых сделок (кроме СПАМ)">{{data[period].deals.present | number}}</a>
            <a class="padded"><i class="ui angle icon" [ngClass]="{'up green': data[period].deals.present > data[period].deals.previous, 'down red': data[period].deals.present < data[period].deals.previous}"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('calls')">
    <div class="content">
      <div class="header">
        Звонки <i class="ui grey chart line icon"></i>
      </div>
      <div class="description">
        <div>
          <i class="green angle double down icon"></i>
          <div class="ui mini statistic">
            <div class="value">
              <a class="padded" target="_blank" [routerLink]="['/report', 'call']" [queryParams]="{from: getPreviousQueryFrom(), to: getPreviousQueryTo(), context: 'incoming', status: 'answer'}" suiPopup popupInverted popupText="Прошлый период: Входящие звонки">{{data[period].calls.answered.previous | number}}</a>
              <a class="padded" target="_blank" [routerLink]="['/report', 'call']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), context: 'incoming', status: 'answer'}" [ngClass]="{'red': data[period].calls.answered.present < data[period].calls.answered.previous, 'green': data[period].calls.answered.present > data[period].calls.answered.previous}" suiPopup popupInverted
                 popupText="Текущий период: Входящие звонки">{{data[period].calls.answered.present | number}}</a>
              <a class="padded"><i class="ui angle icon" [ngClass]="{'down red': data[period].calls.answered.present < data[period].calls.answered.previous, 'up green': data[period].calls.answered.present > data[period].calls.answered.previous}"></i></a>
            </div>
          </div>
        </div>
        <div>
          <i class="red angle down icon"></i>
          <div class="ui mini statistic">
            <div class="value">
              <a class="padded" target="_blank" [routerLink]="['/report', 'call']" [queryParams]="{from: getPreviousQueryFrom(), to: getPreviousQueryTo(), context: 'incoming', status: 'noanswer'}" suiPopup popupInverted popupText="Прошлый период: Неотвеченные входящие вызовы">{{data[period].calls.noanswer.previous | number}}</a>
              <a class="padded" target="_blank" [routerLink]="['/report', 'call']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), context: 'incoming', status: 'noanswer'}" [ngClass]="{'red': data[period].calls.noanswer.present > data[period].calls.noanswer.previous, 'green': data[period].calls.noanswer.present < data[period].calls.noanswer.previous}" suiPopup popupInverted
                 popupText="Текущий период: Неотвеченные входящие вызовы">{{data[period].calls.noanswer.present | number}}</a>
              <a class="padded"><i class="ui angle icon" [ngClass]="{'down green': data[period].calls.noanswer.present < data[period].calls.noanswer.previous, 'up red': data[period].calls.noanswer.present > data[period].calls.noanswer.previous}"></i></a>
            </div>
          </div>
        </div>
        <div>
          <i class="red bug icon"></i>
          <div class="ui mini statistic">
            <div class="value">
              <a class="padded" target="_blank" [routerLink]="['/report', 'call']" [queryParams]="{from: getPreviousQueryFrom(), to: getPreviousQueryTo(), context: 'incoming', status: 'spam'}" suiPopup popupInverted popupText="Прошлый период: Входящие звонки, помеченные менеджером как СПАМ">{{data[period].calls.spam.previous | number}}</a>
              <a class="padded" target="_blank" [routerLink]="['/report', 'call']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), context: 'incoming', status: 'spam'}" [ngClass]="{'red': data[period].calls.spam.present > data[period].calls.spam.previous, 'green': data[period].calls.spam.present < data[period].calls.spam.previous}" suiPopup popupInverted
                 popupText="Текущий период: Входящие звонки, помеченные менеджером как СПАМ">{{data[period].calls.spam.present | number}}</a>
              <a class="padded"><i class="ui angle icon" [ngClass]="{'down green': data[period].calls.spam.present < data[period].calls.spam.previous, 'up red': data[period].calls.spam.present > data[period].calls.spam.previous}"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('prognosis')">
    <div class="content">
      <div class="header">
        Неверный прогноз <i class="ui grey calendar outline icon"></i>
      </div>
      <div class="description">
        <div class="ui mini statistic">
          <div class="value">
            <a class="red padded" target="_blank" [routerLink]="['/report', 'prognosis']" [queryParams]="{from: '2010-01-01', to: getEndOfMonth()}" suiPopup popupInverted popupText="Сделки с неверной датой предполагаемой продажи">{{data[period].notValidPrognosis | number}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
