<div class="ui fluid container"
     style="margin:0 !important;padding:0 !important;height: 100% !important;overflow: hidden !important;">
  <div class="ui stackable grid" style="margin:0;padding:0;height: 100% !important;">
    <div class="ui twelve wide column loginBackground" style="margin:0;padding:0;height: 100% !important;">
      <div class="bgpic">
        <div class="bottom">
          <div class="ui white basic segment">
            <h2 class="semi-bold">
              Olissys E2B</h2>
            <p>* Optimal LIving Space SYStem</p>
            <p class="small">
              Все права защищены © 2017-2019 OLISSYS.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="ui loginWrapper four wide column"
         style="background: #d7f7ee;height: 100% !important;margin:0;padding:0;">
      <div class="ui loginFormWrapper container">
        <img src="assets/images/logo.svg" style="width: 100%;" width="180" height="120">
        <form [formGroup]="reactiveLoginForm" (ngSubmit)="onSubmit()" class="ui form" style="padding-top:30px;">
          <div class="field white">
            <label class="text-uppercase semi-bold">Email</label>
            <input formControlName="email" class="form-control" placeholder="email" autofocus required>
          </div>
          <div class="field white">
            <label class="text-uppercase semi-bold">Пароль</label>
            <input formControlName="password" type="password" class="form-control" placeholder="Пароль" required>
          </div>
          <div *ngIf="email.invalid && (email.dirty || email.touched) && submitted"
               class="ui negative message">
            <div *ngIf="email.errors['required']">
              <div class="header">Email обязателен для заполнения.</div>
            </div>
            <div *ngIf="email.errors['email']">
              <div class="header">Некорректный email.</div>
            </div>
          </div>
          <div *ngIf="password.invalid && submitted"
               class="ui negative message">
            <div *ngIf="password.errors['required']">
              <div class="header">Пароль обязателен для заполнения.</div>
            </div>
          </div>
          <div class="ui negative message" *ngIf="error">
            <div class="header">{{error.type}}</div>
            <div class="content">
              {{error.action}}
            </div>
          </div>
          <div class="ui grid" style="margin-top:30px;margin-bottom:30px;">
            <div class="ui eight wide column">
              <div class="field">
                <sui-checkbox formControlName="rememberMe">
                  Запомнить меня
                </sui-checkbox>
              </div>
            </div>
            <div class="ui right aligned eight wide column">
              <a routerLink="/help" class="text-info small">Нужна помощь?</a>
            </div>
          </div>
          <button class="ui fluid primary button m20" type="submit">
            Вход
          </button>
          <a class="text-info small text-center w-100 m20 d-block" [routerLink]="['/reset']">
            Восстановить пароль
          </a>
        </form>

        <div class="tryForm">
          <button class="ui green fluid button" *ngIf="!tryFormVisible && !tryFormSent" (click)="tryFormVisible = true">
            Пробная версия
          </button>

          <form *ngIf="tryFormVisible" [formGroup]="tryForm" (ngSubmit)="onTryRequest()" class="ui form">
            <div class="field">
              <input formControlName="email" class="tryEmailInput" placeholder="Введите email" autofocus required>
              <div class="color-red errors-block">
                <ng-container *ngIf="tryEmail.invalid && tryEmail.touched">
                  <small *ngIf="tryEmail.errors.required">Введите email</small>
                  <small *ngIf="tryEmail.errors.email">Некорректный email</small>
                </ng-container>
                <div *ngIf="tryCRMRequestError">
                  <small>Ошибка запроса. Попробуйте позже</small>
                </div>
              </div>
            </div>
            <button class="ui green fluid button" [class.loading]="tryFormSubmitting" type="submit">
              Попробовать
            </button>
          </form>
          <div class="text-center" *ngIf="tryFormSent">
            <h5>Ваш запрос отправлен <i class="ui circle check outline icon"></i></h5>
            <h5>Подробности высланы на {{tryEmail.value}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
