import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LkBaseService } from './lk-base.service';
import { Comment } from '../../_models/comment.model';

@Injectable({
  providedIn: 'root'
})
export class LkOrderCommentService extends LkBaseService {
  baseUrl = '/order/comment';

  findByOrder(id: number): Observable<Comment[]> {
    return this.http.get<Comment[]>('/lk' + this.baseUrl + '/' + id, { withCredentials: true, headers: this.headers });
  }
}
