import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ClientContact } from '../../../_models/client-contact.model';
import { Client } from '../../../_models/client.model';

@Component({
  selector: 'e2b-contacts-add',
  templateUrl: './contacts-add.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../../assets/design/semantic-ui/components/button.min.css',
    './contacts-add.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactsAddComponent implements OnInit {
  contact: ClientContact = new ClientContact();
  clients: Array<Client> = [];

  constructor() {
  }

  ngOnInit() {
  }

}
