import { Component, EventEmitter, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { User } from '../../_models/user.model';

@Component({
  selector: 'e2b-staff-dropdown',
  templateUrl: './staff.dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class StaffDropdownComponent {

  @ViewChild('selectStaff', { static: false })
  selectStaff;

  public selectedStaff: User;
  @Input()
  public error: boolean;

  @Input()
  public staff: User[];

  @Input()
  public event: EventEmitter<User>;

  @Input()
  class: string;

  @Input()
  disabled = false;

  @Input()
  set selected(user: User) {
    if (user) {
      const _user = this.staff.find(person => person.id === user.id);
      this.selectedStaff = _user;
      setTimeout(() => {
        this.selectStaff.selectedOption = _user;
      }, 0);
    }
  }

  save() {
    this.event.emit(this.selectedStaff);
  }
}
