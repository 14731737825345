import { BaseModel } from './base.model';
import { DealFieldValue } from './deal-field-value.model';
import { DealPositionValue } from './deal-position-value.model';
import { DealStatus } from './deal-status.model';

export class DealField extends BaseModel {
  name: string;
  description: string;
  type: string;
  defaultValue: string;
  model: string;
  sort: number;
  deleted: boolean;
  values: DealFieldValue[];
  positionValues: DealPositionValue[];
  requiredInStatuses: DealStatus[];
  availableInStatuses: DealStatus[];
}
