import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'e2b-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/button.min.css',
    '../../assets/design/semantic-ui/components/grid.min.css',
    '../../assets/design/semantic-ui/components/container.min.css',
    '../../assets/design/semantic-ui/components/segment.min.css',
    '../../assets/design/semantic-ui/components/form.min.css',
    '../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../assets/design/semantic-ui/components/message.min.css',
    '../login/login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  http: HttpClient;
  submitted: boolean;
  userService: UserService;
  error: any;
  email: string;
  key: string;
  password: string;

  constructor(@Inject(FormBuilder) private fb: FormBuilder, http: HttpClient, private router: Router, userService: UserService, private route: ActivatedRoute) {
    this.http = http;
    this.userService = userService;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.email) {
        this.email = params.email;
      }
      if (params.key) {
        this.key = params.key;
      }
    });
    this.initForm();
  }

  initForm() {
    this.changePasswordForm = new FormGroup({
      'newPassword': new FormControl('', [
        Validators.required,
        Validators.min(6)
      ]),
      'comparePassword': new FormControl('', [
        Validators.required,
        Validators.min(6)
      ])
    });
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

  get comparePassword() {
    return this.changePasswordForm.get('comparePassword');
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      this.password = this.newPassword.value;
      this.userService.changePassword(this.email, this.key, this.password).subscribe(() => {
        this.router.navigate(['/login']);
      }, e => {
        this.error = e;
      });
    }
  }
}
