import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

/**
 * Сервис ролей
 * @class RoleService
 */
@Injectable()
export class RoleService extends BaseService {
  baseUrl = '/role';
}
