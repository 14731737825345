import { ChangeDetectorRef } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SuiSearch } from 'ng2-semantic-ui/dist';
import { map } from 'rxjs/operators';
import { ClientContact } from '../../_models/client-contact.model';
import { ClientType } from '../../_models/client-type.model';
import { Client } from '../../_models/client.model';
import { DealFieldValue } from '../../_models/deal-field-value.model';
import { DealField } from '../../_models/deal-field.model';
import { DealPositionValue } from '../../_models/deal-position-value.model';
import { DealStatus } from '../../_models/deal-status.model';
import { Deal } from '../../_models/deal.model';
import { Filial } from '../../_models/filial.model';
import { Person } from '../../_models/person.model';
import { Responsible } from '../../_models/responsible.model';
import { UserGroup } from '../../_models/user-group.model';
import { User } from '../../_models/user.model';
import { ClientTypeService } from '../../_services/client-type.service';
import { ClientService } from '../../_services/client.service';
import { ContactService } from '../../_services/contact.service';
import { DealFieldService } from '../../_services/deal-field.service';
import { DealPositionValueService } from '../../_services/deal-position-value.service';
import { DealPositionService } from '../../_services/deal-position.service';
import { DealStatusService } from '../../_services/deal-status.service';
import { DealService } from '../../_services/deal.service';
import { FileService } from '../../_services/file.service';
import { FilialService } from '../../_services/filial.service';
import { OwnService } from '../../_services/own.service';
import { PositionGroupService } from '../../_services/position-group.service';
import { PositionService } from '../../_services/position.service';
import { TodoTypeService } from '../../_services/todo-type.service';
import { TodoService } from '../../_services/todo.service';
import { UserGroupService } from '../../_services/user-group.service';
import { UserService } from '../../_services/user.service';
import { ErrorService } from '../../error/error.service';

const CONTRACT_DEAL_STATUS_ID = 4;
const CONTRACT_DEAL_STATUS_SORT_ID = 3;
const CLIENT_TYPE_CLIENT_ID = 1;

@Component({
  selector: 'e2b-sales-wizard',
  templateUrl: './sales-wizard.component.html',
  styleUrls: ['./sales-wizard.component.less',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/step.min.css',
    '../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../assets/design/semantic-ui/components/button.min.css',
    '../../../assets/design/semantic-ui/components/divider.min.css',
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/dimmer.min.css',
  ],
  encapsulation: ViewEncapsulation.None
})
export class SalesWizardComponent implements OnInit {
  step = 1;
  loaded = false;
  fieldsLoaded = false;

  @ViewChild('clientSearchComponent', { static: false }) clientSearchComponent: SuiSearch<any>;

  deal = new Deal();
  client = new Client();
  clientContact = new ClientContact();
  originalClientContact = new ClientContact();
  filials: Filial[] = [];
  managers: User[] = [];
  groups: any[] = [];
  auditors: any[] = [];
  organizations: Person[] = [];
  fieldsToSave: any[] = [];
  clientSearch: (query: any) => Promise<unknown>;

  selectFilial = new EventEmitter<Filial>();
  selectOrganization = new EventEmitter<Person>();
  selectManager = new EventEmitter<User>();
  selectSubManager = new EventEmitter<User>();
  selectAuditor = new EventEmitter<UserGroup[]>();
  onPersonSave = new EventEmitter();
  fieldToSaveLater = new EventEmitter();

  fieldIds: number[] = [];
  fields: DealField[] = [];

  constructor(private readonly route: ActivatedRoute,
              private readonly cdRef: ChangeDetectorRef,
              private readonly clientService: ClientService,
              private readonly contactService: ContactService,
              private readonly clientTypeService: ClientTypeService,
              private readonly dealService: DealService,
              private readonly dealFieldService: DealFieldService,
              private readonly dealPositionService: DealPositionService,
              private readonly dealPositionValueService: DealPositionValueService,
              private readonly dealStatusService: DealStatusService,
              private readonly errorService: ErrorService,
              private readonly fileService: FileService,
              private readonly filialService: FilialService,
              private readonly ownService: OwnService,
              private readonly positionService: PositionService,
              private readonly positionGroupService: PositionGroupService,
              private readonly router: Router,
              private readonly todoService: TodoService,
              private readonly todoTypeService: TodoTypeService,
              private readonly userService: UserService,
              private readonly userGroupService: UserGroupService) {
  }

  get isDealValid() {
    return Boolean(this.isClientContactValid && this.deal.manager && this.auditors.length && this.deal.filial && this.deal.organization);
  }

  get isClientContactEdited() {
    return ['name', 'email', 'phone'].some(field => this.clientContact[field] !== this.originalClientContact[field]);
  }

  get isClientContactValid() {
    return ['name', 'email', 'phone'].every(field => this.clientContact[field] && this.clientContact[field].trim());
  }

  ngOnInit() {
    this.deal.positions = [];
    this.route.queryParams.subscribe(params => {
      if (params.id) {
        const dealId = parseInt(params.id, 0);
        this.dealService.findById<Deal>(dealId).subscribe(deal => {
          if (deal) {
            this.deal = deal;
            this.client = deal.client;
            const clientMainContact: ClientContact = this.client.contacts.find(contact => contact.isMain) || this.client.contacts[0] || new ClientContact();
            if (clientMainContact.phone) {
              clientMainContact.phone = clientMainContact.phone.replace(/\D/g, '');
            }
            this.clientContact = { ...clientMainContact };
            this.originalClientContact = { ...clientMainContact };
            this.load();
          }
        });
      } else {
        this.load();
      }
    });
  }

  load() {
    this.loaded = false;

    /* Services */
    this.filialService.find<Filial>().subscribe(filials => {
      this.filials = filials;
      if (this.deal.filial) {
        this.deal.filial = this.filials.find(filial => filial.id === this.deal.filial.id);
      }
    });
    this.userService.managers<User>().subscribe(managers => {
      this.managers = managers;
      this.deal.manager = this.deal.id
        ? this.managers.find(manager => manager.id === this.deal.manager.id)
        : this.managers.find(manager => manager.id === this.userService.current().id);
      if (this.deal.subManager) {
        this.deal.subManager = this.managers.find(manager => manager.id === this.deal.subManager.id);
      }
    });
    this.userGroupService.find<UserGroup>().subscribe(groups => {
      this.groups = this.groups.concat(groups);
      if (this.deal.responsibles) {
        const groupIds: any = {};
        this.deal.responsibles.map(r => {
          if (r.user) {
            groupIds[r.group.id] = true;
          }
        });
        groups.map(e => {
          if (groupIds[e.id]) {
            this.auditors.push(e);
          }
        });
      }
    });
    this.userService.find<User>().subscribe(employees => {
      this.groups = this.groups.concat(employees);
      if (this.deal.responsibles) {
        const userIds: any = {};
        this.deal.responsibles.map(r => {
          if (r.user) {
            userIds[r.group.id] = true;
          }
        });
        employees.map(e => {
          if (userIds[e.id]) {
            this.auditors.push(e);
          }
        });
      }
    });

    this.fieldIds.map(id => this.dealFieldService.findById<DealField>(id).subscribe(field => {
      this.fields.push(field);
      if (this.fieldIds.length === this.fields.length) {
        setTimeout(() => this.fieldsLoaded = true, 0);
      }
    }));

    /* EventEmitters */
    this.selectFilial.subscribe(filial => {
      this.deal.filial = filial;
      if (this.deal.id) {
        this.dealService.update(<Deal>{ id: this.deal.id, filial: <Filial>{ id: this.deal.filial.id } }).subscribe(() => {
        });
      }
    });
    this.selectOrganization.subscribe(organization => {
      this.deal.organization = organization;
      if (this.deal.id) {
        this.dealService.update(<Deal>{ id: this.deal.id, organization: <Person>{ id: this.deal.organization.id } }).subscribe(() => {
        });
      }
    });
    this.selectManager.subscribe(manager => {
      this.deal.manager = manager;
      if (this.deal.id) {
        this.dealService.update(<Deal>{ id: this.deal.id, manager: <User>{ id: this.deal.manager.id } }).subscribe(() => {
        });
      }
    });
    this.selectSubManager.subscribe(manager => {
      this.deal.subManager = manager;
      if (this.deal.id) {
        this.dealService.update(<Deal>{ id: this.deal.id, subManager: <User>{ id: this.deal.subManager.id } }).subscribe(() => {
        });
      }
    });
    this.selectAuditor.subscribe(groups => {
      this.auditors = groups;
      if (this.deal.id) {
        this.updateAuditors();
      }
    });

    this.fieldToSaveLater.subscribe(value => {
      if ([null, undefined].indexOf(value.value) > -1) {
        return;
      }
      const existingValue = this.fieldsToSave.find(fieldValue => fieldValue.field.id === value.field.id);
      if (existingValue) {
        existingValue.value = value.value;
      } else {
        this.fieldsToSave.push(value);
      }
    });

    this.clientSearch = async (query) => {
      return new Promise(resolve => {
        this.clientService.search(query).then(results => {
          return resolve(results);
        }).catch(console.error);
      });
    };

    this.loaded = true;
  }

  updateAuditors() {
    const auditorGroupIds = [];
    const auditorUserIds = [];

    const dealResponsibleGroupIds = [];
    const dealResponsibleUserIds = [];

    this.deal.responsibles.map(responsible => {
      if (responsible.group) {
        dealResponsibleGroupIds.push(responsible.group.id);
      }
      if (responsible.user) {
        dealResponsibleUserIds.push(responsible.user.id);
      }
    });

    this.auditors.map(auditor => {
      if (auditor.users) {
        auditorGroupIds.push(auditor.id);
      }
      if (!auditor.users) {
        auditorUserIds.push(auditor.id);
      }
    });

    this.auditors.map((auditor: any) => {
      if ((auditor.users && dealResponsibleGroupIds.indexOf(auditor.id) === -1) || (!auditor.users && dealResponsibleUserIds.indexOf(auditor.id) === -1)) {
        this.dealService.addResponsible(this.deal.id, auditor).subscribe((added: Responsible) => {
          this.deal.responsibles.push(added);
        }, e => {
          this.errorService.addError.emit({
            title: 'Ошибка при сохранении аудиторов',
            message: e.error && e.error.message ? e.error.message : e.message
          });
        });
      }
    });

    this.deal.responsibles.map(responsible => {
      if ((responsible.group && auditorGroupIds.indexOf(responsible.group.id) === -1) || (responsible.user && auditorUserIds.indexOf(responsible.user.id) === -1)) {
        this.dealService.removeResponsible(responsible.id).subscribe(() => {
          this.deal.responsibles.splice(this.deal.responsibles.indexOf(responsible), 1);
        }, e => {
          this.errorService.addError.emit({
            title: 'Ошибка при сохранении аудиторов',
            message: e.error && e.error.message ? e.error.message : e.message
          });
        });
      }
    });
  }

  selectClient(client: any) {
    this.client = client;

    if (client.contact) {
      this.clientContact = client.contact;
      if (this.clientContact.phone) {
        this.clientContact.phone = this.clientContact.phone.replace(/\D/g, '');
      }
      this.originalClientContact = { ...this.clientContact };
    }

    if (this.deal.id) {
      this.dealService.update(<Deal>{ id: this.deal.id, client: <Client>{ id: this.client.id } }).subscribe(() => {
      });
    }
  }

  resetClient() {
    this.client = new Client();
    this.clientContact = new ClientContact();
    this.originalClientContact = new ClientContact();
  }

  onClientContactPhoneChange(phone: number) {
    this.clientContact.phone = phone ? String(phone) : '';
  }

  saveDeal() {
    if (!this.isDealValid) {
      return;
    }
    this.loaded = false;

    if (this.deal.id) {
      if (this.isClientContactEdited) {
        if (this.clientContact.id) {
          this.contactService.update(this.clientContact).subscribe(() => {
          });
        } else {
          this.createClientContact().subscribe(() => {
          });
        }
      }

      if (this.deal.currentStatus.sort < CONTRACT_DEAL_STATUS_SORT_ID) {
        this.dealService.update(<any>{ id: this.deal.id, currentStatus: <DealStatus>{ id: CONTRACT_DEAL_STATUS_ID }, oldStatus: { id: this.deal.currentStatus.id } }).subscribe(() => {
          this.deal.currentStatus = <DealStatus>{ id: CONTRACT_DEAL_STATUS_ID };
          this.step = 2;
          this.loaded = true;
        });
      } else {
        this.step = 2;
        this.loaded = true;
      }
      return;
    }

    if (this.client.id) {
      if (this.isClientContactEdited) {
        if (this.clientContact.id) {
          this.contactService.update(this.clientContact).subscribe(() => this.createDeal());
        } else {
          this.createClientContact().subscribe(() => this.createDeal());
        }
      } else {
        this.createDeal();
      }
    } else {
      this.client.name = this.clientSearchComponent.query;
      this.client.manager = <User>{ id: this.deal.manager.id };
      this.client.type = new ClientType();
      this.client.type.id = CLIENT_TYPE_CLIENT_ID;

      this.clientService.create<Client>(this.client).subscribe(createdClient => {
        this.client = createdClient;
        this.createClientContact().subscribe(() => this.createDeal());
      });
    }
  }

  createClientContact() {
    this.clientContact.client = <Client>{ id: this.client.id };
    this.clientContact.isMain = true;
    return this.contactService.create<ClientContact>(this.clientContact).pipe(map(createdContact => {
      this.clientContact = createdContact;
      this.originalClientContact = { ...createdContact };
      return createdContact;
    }));
  }

  createDeal() {
    this.deal.client = this.client;
    this.dealService.create<Deal>(this.deal).subscribe(createdDeal => {
      this.deal = { ...createdDeal };
      this.dealService.update(<any>{ id: this.deal.id, currentStatus: <DealStatus>{ id: CONTRACT_DEAL_STATUS_ID }, oldStatus: { id: this.deal.currentStatus.id } }).subscribe(() => {
        this.deal.currentStatus = <DealStatus>{ id: CONTRACT_DEAL_STATUS_ID, sort: CONTRACT_DEAL_STATUS_SORT_ID };
      });
      this.auditors.map(auditor => {
        this.dealService.addResponsible(this.deal.id, auditor).subscribe(() => {
        }, console.error);
      });

      if (this.fieldsToSave.length) {
        this.createDealFields();
      }

      this.dealService.update(<any>{ id: this.deal.id, currentStatus: <DealStatus>{ id: CONTRACT_DEAL_STATUS_ID }, oldStatus: { id: this.deal.currentStatus.id } }).subscribe(() => {
        setTimeout(() => {
          this.dealService.findById<Deal>(this.deal.id).subscribe(deal => {
            this.deal = deal;
            this.step = 2;
            this.loaded = true;
          });
        }, 1000);
      });

    });
  }

  createDealFields() {
    this.fieldsToSave.map(value => {
      this.dealService.fieldCreate<DealFieldValue>(<DealFieldValue>{
        field: { id: value.field.id },
        value: value.value,
        deal: { id: this.deal.id }
      }).subscribe(() => {
      });
    });
  }
}
