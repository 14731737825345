<ng-template let-option #groupTemplateOptionTemplate>
  {{option.name}}
</ng-template>
<sui-select class="fluid selection mx-auto"
            placeholder="Шаблон"
            [isSearchable]="true"
            [isDisabled]="false"
            [(ngModel)]="selectedTemplate"
            [ngModelOptions]="{name: 'template'}"
            (ngModelChange)="save()"
            [options]="templates"
            labelField="name"
            [optionTemplate]="groupTemplateOptionTemplate"
            #selectTemplate>
  <sui-select-option *ngFor="let template of selectTemplate.filteredOptions | orderByColumn:'name:string'"
                     [value]="template">
  </sui-select-option>
</sui-select>
