import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'e2b-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/container.min.css',
    '../../assets/design/semantic-ui/components/grid.min.css',
    './app-footer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppFooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
