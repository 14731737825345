import { SimpleChanges } from '@angular/core';
import { OnChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DealFieldValue } from '../../../_models/deal-field-value.model';
import { DealField } from '../../../_models/deal-field.model';
import { Deal } from '../../../_models/deal.model';
import { AddressService } from '../../../_services/address.service';
import { DealFieldService } from '../../../_services/deal-field.service';
import { DealService } from '../../../_services/deal.service';
import { FileService } from '../../../_services/file.service';
import { FileInterface } from '../../../_services/file.service';
import * as moment from 'moment';
import { UserService } from '../../../_services/user.service';
import { ErrorService } from '../../../error/error.service';

const DEAL_CONTRACT_DATE_FIELD_ID = 148;

@Component({
  selector: 'e2b-deal-field',
  templateUrl: './deal-field.component.html',
  styleUrls: ['./deal-field.component.less']
})
export class DealFieldComponent implements OnInit, OnChanges {
  loaded = false;

  @Input()
  deal: Deal;
  @Input()
  fields: DealField[] = [];
  @Input()
  fieldToSaveLater: EventEmitter<any>;

  mappedValues: any = {};
  originalValues: any = {};
  calculatedCoefficient: any = {
    id: null,
    data: {
      use: 0, all: 0, mountUse: 0, mountAll: 0
    }
  };
  statusError: any = null;

  addressSearch: (query: any) => Promise<unknown>;

  constructor(private readonly addressService: AddressService,
              private readonly dealService: DealService,
              private readonly dealFieldService: DealFieldService,
              private readonly errorService: ErrorService,
              private readonly fileService: FileService,
              public userService: UserService) {
  }

  ngOnInit() {
    this.setMappedValues();
    this.addressSearch = async (query) => {
      if (query && query.length > 4) {
        return new Promise(resolve => {
          this.addressService.search(query).then(results => {
            return resolve(results);
          }).catch(console.error);
        });
      } else {
        return Promise.resolve([]);
      }
    };
    this.loaded = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fields && !changes.fields.firstChange) {
      this.setMappedValues();
    }
  }

  setMappedValues() {
    this.fields.map(f => {
      this.mappedValues[f.id] = {
        deal: {
          id: this.deal.id
        },
        event: new EventEmitter(),
        field: f
      };
      this.mappedValues[f.id].event.subscribe(v => {
        this.mappedValues[f.id].value = v;
        this.onMappedValueBlur(f.id);
      });
    });

    if (!this.deal.id) {
      return;
    }
    const fieldIds = this.fields.map(field => field.id);
    this.deal.values.filter(value => fieldIds.includes(value.field.id)).map(value => {
      if (value.field.type === 'DATE') {
        value.value = moment(value.value, 'DD.MM.YYYY').toDate();
      } else if (value.field.type === 'DATETIME') {
        value.value = moment(value.value, 'DD.MM.YYYY HH:mm:ss').toDate();
      }
      this.originalValues[value.field.id] = {
        value: value.value
      };

      this.mappedValues[value.field.id].id = value.id;
      this.mappedValues[value.field.id].value = value.value;
    });

    if (this.mappedValues[DEAL_CONTRACT_DATE_FIELD_ID] && !this.mappedValues[DEAL_CONTRACT_DATE_FIELD_ID].value) {
      this.mappedValues[DEAL_CONTRACT_DATE_FIELD_ID].value = new Date();
    }
  }

  onMappedValueBlur(id) {
    let value = this.mappedValues[id];
    const regex = RegExp(value.field.name);
    if (this.statusError && this.statusError.length > 0) {
      this.statusError.map(error => {
        if (value.field.name && regex.test(error.name)) {
          this.statusError.splice(this.statusError.indexOf(error), 1);
        }
      });
    }
    if (this.originalValues[id] && value.value === this.originalValues[id].value && !value.files) {
      return false;
    }
    delete value['edit'];
    delete value['event'];
    this.originalValues[value.field.id] = {
      value: value.value
    };
    if (value.field.type === 'FILE') {
      this.fileService.upload(value.files).then((f: FileInterface[]) => {
        if (value.value) {
          value.value = value.value.concat(f);
        } else {
          value.value = f;
        }
        this.saveValue(value);
      });
    } else {
      value = JSON.parse(JSON.stringify(value));
      if (this.mappedValues[id].field.type === 'DATE') {
        value.value = moment(this.mappedValues[id].value).format('DD.MM.YYYY');
        if (value.field.name.indexOf('Дата монтажа') > -1) {
          this.calculatedCoefficient.id = null;
          this.dealService.coefficient(value.value).subscribe(c => {
            this.calculatedCoefficient.id = id;
            this.calculatedCoefficient.data = c;
          });
        }
      } else if (this.mappedValues[id].field.type === 'DATETIME') {
        value.value = moment(this.mappedValues[id].value).format('DD.MM.YYYY HH:mm:ss');
        if (value.field.name.indexOf('Дата и время монтажа') > -1) {
          this.calculatedCoefficient.id = null;
          this.dealService.coefficient(moment(this.mappedValues[id].value).format('DD.MM.YYYY')).subscribe(c => {
            this.calculatedCoefficient.id = id;
            this.calculatedCoefficient.data = c;
          });
        }
      }
      this.saveValue(value);
      this.mappedValues[value.field.id].event = new EventEmitter();
      this.mappedValues[value.field.id].event.subscribe(v => {
        this.mappedValues[value.field.id].value = v;
        this.onMappedValueBlur(value.field.id);
      });
    }
  }

  onPickerChange($event, id) {
    if ($event) {
      this.onMappedValueBlur(id);
    }
  }

  saveValue(value) {
    if ([null, undefined].indexOf(value.value) > -1) {
      return false;
    }

    if (!this.deal.id) {
      return this.fieldToSaveLater.emit(value);
    }
    if (value && !value.id) {
      this.dealService.fieldCreate<DealFieldValue>(<DealFieldValue>{
        field: { id: value.field.id },
        value: value.value,
        deal: value.deal
      }).subscribe(created => {
        if (value.field.type === 'DATE') {
          created.value = moment(value.value, 'DD.MM.YYYY').toDate();
        } else if (value.field.type === 'DATETIME') {
          created.value = moment(value.value, 'DD.MM.YYYY HH:mm:ss').toDate();
        }
        this.mappedValues[value.field.id] = created;
        this.mappedValues[value.field.id].event = new EventEmitter();
      }, e => {
        this.errorService.addError.emit({
          title: 'Ошибка при сохранении значения поля',
          message: e.error && e.error.message ? e.error.message : e.message
        });
      });
    } else if (value.id) {
      this.dealService.fieldUpdate<DealFieldValue>(<DealFieldValue>{
        id: value.id,
        field: { id: value.field.id },
        value: value.value,
        deal: value.deal
      }).subscribe(() => {
      }, e => {
        this.errorService.addError.emit({
          title: 'Ошибка при сохранении значения поля',
          message: e.error && e.error.message ? e.error.message : e.message
        });
      });
    }
  }

  removeValue(value) {
    const confirm = window.confirm(`Вы уверены, что хотите удалить ${value.field.name}?`);
    if (confirm) {
      this.statusError = [];
      this.dealService.fieldDeleteById<DealFieldValue>(value.id).subscribe(() => {
        setTimeout(() => {
          this.mappedValues[value.field.id].value = undefined;
          this.mappedValues[value.field.id].edit = false;
        }, 10);
      }, e => {
        this.errorService.addError.emit({
          title: 'Ошибка при удалении значения поля',
          message: e.error && e.error.message ? e.error.message : e.message
        });
      });
    }
  }

  removeFile(id, file) {
    const confirm = window.confirm(`Вы уверены, что хотите удалить файл ${file.name}?`);
    if (confirm) {
      this.mappedValues[id].value.splice(this.mappedValues[id].value.indexOf(file), 1);
      if (this.mappedValues[id].value.length === 0) {
        this.mappedValues[id].edit = false;
        this.removeValue(this.mappedValues[id]);
      } else {
        this.saveValue(this.mappedValues[id]);
      }
    }
  }

  editMappedValue(id: number): boolean {
    if (this.mappedValues[id].field.type === 'STEP') {
      return false;
    }
    if (this.userService.checkFieldPermission(id, ['write'])) {
      return this.mappedValues[id].edit = true;
    }
    return false;
  }

  addressSelected(event, fieldId) {
    this.mappedValues[fieldId].value = event.fullname;
    this.onMappedValueBlur(fieldId);
  }

}
