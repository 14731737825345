<form class="ui form">
  <div class="ui stackable grid">
    <div class="row" *ngIf="!clientId">
      <div class="three wide column d-flex align-items-center">
        <h4 class="header">Клиент *</h4>
      </div>
      <div class="ten wide column">
        <div class="fluid field">
          <e2b-client-dropdown [clients]="clients" [event]="selectClient"></e2b-client-dropdown>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="clientId">
      <div class="three wide column d-flex align-items-center">
        <h4 class="header">Клиент *</h4>
      </div>
      <div class="ten wide column">
        <div class="fluid field">
          <a href="#" [routerLink]="['/client/card', clientId]">{{client ? client.name : ''}}</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="three wide column d-flex align-items-center">
        <h4 class="header">Филиал *</h4>
      </div>
      <div class="ten wide column">
        <div class="fluid field">
          <e2b-filial-dropdown [event]="selectFilial"></e2b-filial-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="three wide column d-flex align-items-center">
        <h4 class="header">Позиции сделки</h4>
      </div>
      <div class="ten wide column">
        <div class="fluid field">
          <e2b-deal-position [setPositions]="deal.positions" [products]="positions" [onAddPosition]="addDealPosition"
                             [onRemovePosition]="removeDealPosition"></e2b-deal-position>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="three wide column d-flex align-items-center">
        <h4 class="header">Аудиторы</h4>
      </div>
      <div class="ten wide column">
        <div class="fluid field">
          <e2b-staff-group-multi-dropdown [event]="selectAuditor" [groups]="groups"></e2b-staff-group-multi-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="three wide column d-flex align-items-center">
        <h4 class="header">Дополнительная информация</h4>
      </div>
      <div class="ten wide column">
        <div class="field">
          <textarea placeholder="Чего хочет клиент"
                    name="description"
                    [(ngModel)]="deal.description">
          </textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="three wide column d-flex align-items-center">
        <h4 class="header">Менеджер</h4>
      </div>
      <div class="ten wide column">
        <div class="field">
          <e2b-staff-dropdown [staff]="employees" [selected]="deal.manager"
                              [event]="selectManager"></e2b-staff-dropdown>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="buttons mt-2">
  <button class="ui button green" (click)="save()" [ngClass]="{'disabled': loading}">Добавить</button>
  <button class="ui button red" (click)="cancel()" [ngClass]="{'disabled': loading}">Отмена</button>
</div>
