import { BaseModel } from './base.model';
import { Todo } from './todo.model';

export class TodoStatus extends BaseModel {
  name: string;
  description: string;
  color: string;
  systemType: string;
  todos: Todo[];
}
