<div class="ui stackable grid">
  <div class="ui nine wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div *ngIf="loaded">
      <div class="ui form">
        <h3>
          {{position.name}}
        </h3>
        <sui-tabset>
          <div class="ui tabular menu">
            <a class="item" suiTabHeader="1">Общие</a>
            <a class="item" suiTabHeader="2" (activate)="loadFields()">Спецификации</a>
          </div>
          <div class="ui segment" suiTabContent="1">
            <table class="ui very very basic table">
              <tr>
                <td>
                  <h4 class="header">Название</h4>
                </td>
                <td>
                  <div class="field">
                    <div class="ui input">
                      <input type="text" [disabled]="!userService.checkPermissions(['position', '/'], ['write'])" [(ngModel)]="position.name" (change)="save()">
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="header">Тип</h4>
                </td>
                <td>
                  <div class="field">
                    <select [disabled]="!userService.checkPermissions(['position', '/'], ['write'])" [(ngModel)]="position.type" (change)="save()">
                      <option [selected]="position.type === 0" value="0">Нет</option>
                      <option [selected]="position.type === 1" value="1">Корпусная мебель</option>
                      <option [selected]="position.type === 2" value="2">Мягкая мебель</option>
                      <option [selected]="position.type === 3" value="3">Матрас</option>
                      <option [selected]="position.type === 4" value="4">Кастомизация</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="header">Группа</h4>
                </td>
                <td>
                  <div class="field">
                    <ng-template let-option #groupTemplateOptionTemplate>
                      {{option.name}}
                    </ng-template>
                    <sui-select class="fluid selection mx-auto" [ngClass]="{'error': error}"
                                placeholder="Группа"
                                [isSearchable]="true"
                                [(ngModel)]="position.group"
                                [ngModelOptions]="{name: 'group'}"
                                (ngModelChange)="save()"
                                [options]="groups"
                                labelField="name"
                                [optionTemplate]="groupTemplateOptionTemplate"
                                #selectTemplate>
                      <sui-select-option *ngFor="let group of selectTemplate.filteredOptions | orderByColumn:'name:string'"
                                         [value]="group">
                      </sui-select-option>
                    </sui-select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="header">Схема сборки</h4>
                </td>
                <td>
                  <div class="field">
                    <ng-template let-option #assemblyTemplateOptionTemplate>
                      {{option.name}}
                    </ng-template>
                    <sui-select class="fluid selection mx-auto"
                                placeholder="Схема сборки"
                                [isSearchable]="true"
                                [(ngModel)]="position.assemblyScheme"
                                [ngModelOptions]="{name: 'assembly-scheme'}"
                                (ngModelChange)="save()"
                                [options]="assemblySchemes"
                                labelField="name"
                                [optionTemplate]="assemblyTemplateOptionTemplate"
                                #selectAssembly>
                      <sui-select-option *ngFor="let group of selectAssembly.filteredOptions | orderByColumn:'name:string'"
                                         [value]="group">
                      </sui-select-option>
                    </sui-select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="header">Артикул</h4>
                </td>
                <td>
                  <div class="field">
                    <input type="text" [(ngModel)]="position.article" (change)="save()">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="header">Цена</h4>
                </td>
                <td>
                  <div class="field">
                    <input type="number" [(ngModel)]="position.cost" (change)="save()">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="header">Бонус менеджера</h4>
                </td>
                <td>
                  <div class="field">
                    <input type="number" [(ngModel)]="position.priceMN" (change)="save()">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="header">Бонус М1</h4>
                </td>
                <td>
                  <div class="field">
                    <input type="number" [(ngModel)]="position.priceM1" (change)="save()">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="header">Бонус М2</h4>
                </td>
                <td>
                  <div class="field">
                    <input type="number" [(ngModel)]="position.priceM2" (change)="save()">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 class="header">Бонус П1</h4>
                </td>
                <td>
                  <div class="field">
                    <input type="number" [(ngModel)]="position.priceP1" (change)="save()">
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="ui segment" suiTabContent="2">
            <div class="d-flex my-1" *ngFor="let field of ((positionFields | checkFieldPermissionPipe) | orderByColumn:'name:string')">
              <div class="w-50" [innerText]="field.name"></div>
              <div class="ui field w-50">
                <div class="ui action input" *ngIf="field.type !== 'FILE'">
                  <input type="text" *ngIf="field.type === 'TEXT'" [placeholder]="field.name" [(ngModel)]="field.value" (blur)="saveFieldValue(field)">
                  <input type="number" *ngIf="['NUMBER', 'MONEY'].indexOf(field.type) > -1" [placeholder]="field.name" [(ngModel)]="field.value" (blur)="saveFieldValue(field)">
                  <e2b-staff-dropdown [staff]="field.values" [event]="field.event" [selected]="field.value" *ngIf="field.type === 'USER'"></e2b-staff-dropdown>
                  <sui-checkbox [(ngModel)]="field.value"
                                [ngModelOptions]="{updateOn: 'blur'}"
                                *ngIf="field.type === 'BOOLEAN'"
                                (ngModelChange)="saveFieldValue(field)"
                                [name]="field.id"></sui-checkbox>
                  <sui-select class="fluid selection"
                              [name]="field.id"
                              *ngIf="field.type === 'ENUM'"
                              [placeholder]="field.name"
                              [isSearchable]="true"
                              [options]="field.values"
                              [(ngModel)]="field.value"
                              (ngModelChange)="saveFieldValue(field)"
                              [ngModelOptions]="{name: 'value'}"
                              #selectChildren>
                    <sui-select-option *ngFor="let value of selectChildren.filteredOptions"
                                       [value]="value">
                    </sui-select-option>
                  </sui-select>
                  <ng-template let-option #materialOptionTemplate>
                    {{option.name}}
                  </ng-template>
                  <sui-select class="fluid selection"
                              [name]="field.id"
                              *ngIf="field.type === 'MATERIAL'"
                              [placeholder]="field.name"
                              [isSearchable]="true"
                              [options]="field.values"
                              [(ngModel)]="field.value"
                              (ngModelChange)="saveFieldValue(field)"
                              [ngModelOptions]="{name: 'value'}"
                              [optionTemplate]="materialOptionTemplate"
                              #selectMaterial>
                    <sui-select-option *ngFor="let value of selectMaterial.filteredOptions"
                                       [value]="value">
                    </sui-select-option>
                  </sui-select>
                  <ng-template let-option #clothOptionTemplate>
                    {{option.name}}
                  </ng-template>
                  <sui-select class="fluid selection"
                              [name]="field.id"
                              *ngIf="field.type === 'CLOTH'"
                              [placeholder]="field.name"
                              [isSearchable]="true"
                              [options]="field.values"
                              [(ngModel)]="field.value"
                              (ngModelChange)="saveFieldValue(field)"
                              [ngModelOptions]="{name: 'value'}"
                              [optionTemplate]="clothOptionTemplate"
                              #selectCloth>
                    <sui-select-option *ngFor="let value of selectCloth.filteredOptions"
                                       [value]="value">
                    </sui-select-option>
                  </sui-select>
                  <ng-template let-option #personalOptionTemplate>
                    {{option.name}}
                  </ng-template>
                  <sui-multi-select class="fluid selection"
                                    [name]="field.id"
                                    *ngIf="field.type === 'PERSONAL'"
                                    [placeholder]="field.name"
                                    [isSearchable]="true"
                                    [options]="field.values"
                                    [(ngModel)]="field.value"
                                    (ngModelChange)="saveFieldValue(field)"
                                    [ngModelOptions]="{name: 'value'}"
                                    [optionTemplate]="personalOptionTemplate"
                                    #selectPersonal>
                    <sui-select-option *ngFor="let value of selectPersonal.filteredOptions"
                                       [value]="value">
                    </sui-select-option>
                  </sui-multi-select>
                  <e2b-staff-dropdown [staff]="field.values" [event]="field.event" *ngIf="field.type === 'USERS'" [selected]="field.value"></e2b-staff-dropdown>
                  <e2b-staff-dropdown [staff]="field.values" [event]="field.event" *ngIf="field.type === 'USERGROUP'" [selected]="field.value"></e2b-staff-dropdown>
                  <button class="ui red icon button" (click)="removeFieldValue(field)" [disabled]="!field.value">
                    <i class="remove icon"></i>
                  </button>
                </div>
                <div *ngIf="field.type === 'FILE'">
                  <div class="d-flex flex-wrap">
                    <div class="attach" *ngFor="let file of field.value">
                      <i class="ui delete red icon pointer" (click)="removeFile(field, file)"></i>&nbsp;
                      <e2b-file [file]="file"></e2b-file>
                    </div>
                  </div>
                  <div class="ui input">
                    <input type="file" [id]="'file_' + field.id" name="file" #fileField multiple="multiple" (change)="addFiles(field, fileField.files)">
                    <label class="w-100 text-center" [for]="'file_' + field.id">
                      Добавить файл(ы)
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </sui-tabset>
      </div>
    </div>
  </div>
</div>
