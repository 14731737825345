import { Component, EventEmitter, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { PositionGroupTemplate } from '../../_models/position-group-template.model';

@Component({
  selector: 'e2b-position-group-template-dropdown',
  templateUrl: './position-group-template-dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class PositionGroupTemplateDropdownComponent {

  @ViewChild('selectTemplate', { static: false })
  selectTemplate;

  public selectedTemplate: PositionGroupTemplate;

  @Input()
  public templates: PositionGroupTemplate[];

  @Input()
  public event: EventEmitter<PositionGroupTemplate>;

  @Input()
  set selected(template: PositionGroupTemplate) {
    if (template) {
      this.selectedTemplate = template;
      setTimeout(() => {
        this.selectTemplate.selectedOption = template;
      }, 0);
    }
  }

  save() {
    this.event.emit(this.selectedTemplate);
  }
}
