<form class="ui form" style="padding: 0 !important; margin: 0 !important;">
  <div class="ui doubling stackable grid" style="padding: 0 !important; margin: 0 !important;">
    <div class="ui sixteen wide column p-0 m-0">
      <div e2b-filial-label [filial]="deal.filial"></div>
      <div class="d-flex align-items-center">
        <h2 class="ui header" style="display: inline !important; margin-bottom: 0 !important;">
          <a *ngIf="deal.megaplanId" target="_blank"
             href="https://megaplan.olissys.com/deals/{{deal.megaplanId}}/card/">
            <i class="ui cursor paper plane large outline green icon"
               style="float: left; margin-top:12px;font-size: 24px;"></i></a>
          <span class="fw-bold" *ngIf="deal.name" [innerText]="deal.name"></span>
          <span *ngIf="deal.client" [innerText]="', ' + deal.client.name" class="fw-bold"></span>
        </h2>

        <div style="margin-left: 30px" *ngIf="deal.id">
          <e2b-reminder [reminderEntityInfo]="{id: deal.id, name: 'deal'}"></e2b-reminder>
        </div>

        <button [ngClass]="'ui small fit' + (lkLinkCopied ? ' green ' : ' blue ') + 'icon button'"
                style="margin-left: 30px" (click)="copyLkLink()">
          {{lkLinkCopied ? 'Ссылка скопирована' : 'Ссылка клиенту'}}
          <i [ngClass]="(lkLinkCopied ? 'check' : 'copy') + ' icon'"></i>
        </button>
      </div>


      <div class="greytext" style="padding: 8px 0 0 0 !important;" [innerText]="deal.created | date:'medium'"></div>
    </div>
    <div class="ui sixteen wide column"
         style="width: 100% !important; padding: 28px 0 0 0 !important;margin-right: 23px !important;margin-bottom: 10px !important;">
      <div class="ui stackable doubling seven column grid"
           style="border-bottom: 2px solid #fafafa;margin-bottom: 0;margin-top: 0; padding-left: 0;padding-right: 0; margin-left: 0;margin-right: 0;">
        <div class="ui rightBorder column" style="padding-left: 0 !important;">
          <h4 class="header">
            Клиент
            <i *ngIf="editClient" style="font-size:18px;float:right;" class="ui icon delete red pointer"
               (click)="switch('editClient')"></i>
            <i *ngIf="!editClient" style="font-size:18px;float:right;" class="ui icon edit large blue pointer"
               (click)="switch('editClient')"></i>
          </h4>
          <div class="field" *ngIf="editClient">
            <e2b-client-dropdown [clients]="clients" [event]="selectClient"></e2b-client-dropdown>
          </div>
          <div *ngIf="!editClient">
            <a [routerLink]="['/client/card/', deal.client.id]" *ngIf="deal.client.name"
               [innerText]="deal.client.name"></a>
          </div>
        </div>
        <div class="ui rightBorder column">
          <h4 class="header">
            Плательщик
            <i *ngIf="editDealPerson" style="font-size:18px;float:right;" class="ui icon delete red pointer"
               (click)="switch('editDealPerson')"></i>
            <i *ngIf="!editDealPerson" style="font-size:18px;float:right;" class="ui icon edit large blue pointer"
               (click)="switch('editDealPerson')"></i>
          </h4>
          <div class="field" *ngIf="editDealPerson">
            <e2b-person-dropdown [persons]="persons" [event]="selectPerson"></e2b-person-dropdown>
          </div>
          <div *ngIf="!editDealPerson">
            <span class="ui tiny label fw-bold" *ngIf="deal.person" [innerText]="deal.person.name"></span>
            <span class="fw-bold" *ngIf="!deal.person">Не выбран</span>
          </div>
        </div>
        <div class="ui rightBorder column">
          <h4 class="header">
            Организация
            <i *ngIf="editOrg" style="font-size:18px;float:right;" class="ui icon delete red pointer"
               (click)="switch('editOrg')"></i>
            <i *ngIf="!editOrg" style="font-size:18px;float:right;" class="ui icon edit large blue pointer"
               (click)="switch('editOrg')"></i>
          </h4>
          <div class="field" *ngIf="editOrg">
            <e2b-my-company-dropdown [selected]="deal.organization"
                                     [event]="selectOrganizationPerson"></e2b-my-company-dropdown>
          </div>
          <div *ngIf="!editOrg">
            <span class="fw-bold" *ngIf="deal.organization" [innerText]="deal.organization.name"></span>
            <span class="fw-bold" *ngIf="!deal.organization">Не выбрана</span>
          </div>
        </div>
        <div class="ui headPadded column">
          <h4 class="header">
            Филиал
            <i *ngIf="editFilial" style="font-size:18px;float:right;" class="ui icon delete red pointer"
               (click)="switch('editFilial')"></i>
            <i *ngIf="!editFilial" style="font-size:18px;float:right;" class="ui icon edit large blue pointer"
               (click)="switch('editFilial')"></i>
          </h4>
          <div class="field" *ngIf="editFilial">
            <e2b-filial-dropdown [selectedFilial]="deal.filial" [event]="selectFilial"></e2b-filial-dropdown>
          </div>
          <div *ngIf="!editFilial">
            <span class="ui label {{deal.filial.color.replace('#', 'X')}}"
                  *ngIf="deal.filial" [innerText]="deal.filial.name"></span>
            <span class="fw-bold" *ngIf="!deal.filial">Не выбран</span>
          </div>
        </div>
      </div>
    </div>
    <div class="ui sixteen wide column"
         style="width: 100% !important; padding: 28px 0 0 0 !important;margin-right: 23px !important;">
      <div class="ui stackable doubling seven column grid"
           style="border-bottom: 2px solid #fafafa;padding-left: 0;padding-right: 0; margin-left: 0;margin-right: 0;">
        <div class="ui rightBorder column" style="padding-left: 0 !important;padding-right: 0 !important;"
             *ngIf="mountingDatetime && !shippingDatetime">
          <h4 class="header">Дата монтажа</h4>
          <span class="fw-bold">{{ mountingDatetime ? (mountingDatetime.value | date:'dd.MM.yyyy') : '-'}} </span>
        </div>
        <div class="ui rightBorder column" style="padding-left: 0 !important;padding-right: 0 !important;"
             *ngIf="shippingDatetime">
          <h4 class="header">Дата отгрузки</h4>
          <span class="fw-bold">{{ shippingDatetime ? (shippingDatetime.value | date:'dd.MM.yyyy') : '-'}} </span>
        </div>
        <div class="ui rightBorder column">
          <h4 class="header">Сумма</h4>
          <span class="fw-bold">{{deal.positions && deal.positions.length > 0 ? (totalDealSum()| number) : 0}}</span>
        </div>
        <div class="ui rightBorder column">
          <h4 class="header">Баланс</h4>
          <span class="fw-bold {{financeHighlighting}}">{{finance | number}} <span
            *ngIf="financePercent">({{financePercent}}%)</span></span>
          <span class="greytext" *ngIf="financeCredit"><br/>Р {{financeCredit | number}} <span
            *ngIf="financeCreditPercent">(5% - {{financeCreditPercent|number}})</span></span>
        </div>
        <div class="ui rightBorder column">
          <h4 class="header">
            Менеджер
            <i *ngIf="editManager && deal.currentStatus.id === 1" style="font-size:18px;float:right;"
               class="ui icon delete red pointer" (click)="editManager = false"></i>
            <i *ngIf="!editManager && deal.currentStatus.id === 1" style="font-size:18px;float:right;"
               class="ui icon edit large blue pointer" (click)="editManager = true"></i>
            <span *ngxPermissionsOnly="['E2B.ROLE.CONTROL']">
              <i *ngIf="editManager && deal.currentStatus.id !== 1" style="font-size:18px;float:right;"
                 class="ui icon delete red pointer" (click)="editManager = false"></i>
              <i *ngIf="!editManager && deal.currentStatus.id !== 1" style="font-size:18px;float:right;"
                 class="ui icon edit large blue pointer" (click)="editManager = true"></i>
            </span>
          </h4>
          <div *ngIf="!editManager">
            <ng-template let-popup #popupManagerTemplate>
              <div class="header">Менеджер</div>
              <div class="content">
                <div *ngIf="deal.manager" class="ui image label" style="white-space: nowrap;">
                  <e2b-user-avatar [avatar]="deal.manager.avatar"></e2b-user-avatar>
                  <span class="fw-bold">{{deal.manager ? deal.manager.name : ""}}</span>
                </div>
              </div>
            </ng-template>
            <e2b-user-label suiPopup [popupTemplate]="popupManagerTemplate" [user]="deal.manager"></e2b-user-label>
          </div>
          <div class="field" *ngIf="editManager">
            <e2b-staff-dropdown [staff]="employees" [event]="selectManager"></e2b-staff-dropdown>
          </div>
        </div>
        <div class="ui rightBorder column">
          <h4 class="header">
            Доп. Менеджер
            <i *ngIf="editSubManager && user.id === deal.manager.id" style="font-size:18px;float:right;"
               class="ui icon delete red pointer" (click)="editSubManager = false"></i>
            <i *ngIf="!editSubManager && user.id === deal.manager.id" style="font-size:18px;float:right;"
               class="ui icon edit large blue pointer" (click)="editSubManager = true"></i>
            <span *ngxPermissionsOnly="['E2B.ROLE.CONTROL']">
              <i *ngIf="editSubManager && user.id !== deal.manager.id" style="font-size:18px;float:right;"
                 class="ui icon delete red pointer" (click)="editSubManager = false"></i>
              <i *ngIf="!editSubManager && user.id !== deal.manager.id" style="font-size:18px;float:right;"
                 class="ui icon edit large blue pointer" (click)="editSubManager = true"></i>
            </span>
          </h4>
          <div *ngIf="!editSubManager">
            <ng-template let-popup #popupManagerTemplate>
              <div class="header">Доп. Менеджер</div>
              <div class="content">
                <div *ngIf="deal.subManager" class="ui image label" style="white-space: nowrap;">
                  <e2b-user-avatar [avatar]="deal.subManager.avatar"></e2b-user-avatar>
                  <span class="fw-bold">{{deal.subManager ? deal.subManager.name : "Не выбран"}}</span>
                </div>
              </div>
            </ng-template>
            <div *ngIf="!deal.subManager" class="ui label" style="white-space: nowrap;">
              <span class="fw-bold">Не выбран</span>
            </div>
            <e2b-user-label suiPopup [popupTemplate]="popupManagerTemplate" [user]="deal.subManager"></e2b-user-label>
            <i *ngIf="deal.subManager" style="font-size:18px;float:right;" class="ui icon delete red pointer"
               (click)="removeSubManager()"></i>
          </div>
          <div class="field" *ngIf="editSubManager">
            <e2b-staff-dropdown [staff]="employees" [event]="selectSubManager"></e2b-staff-dropdown>
          </div>
        </div>
        <div class="ui rightBorder column">
          <h4 class="header">
            Пом. Менеджера
            <i *ngIf="editManagerHelper && user.id === deal.manager.id" style="font-size:18px;float:right;"
               class="ui icon delete red pointer" (click)="editManagerHelper = false"></i>
            <i *ngIf="!editManagerHelper && user.id === deal.manager.id" style="font-size:18px;float:right;"
               class="ui icon edit large blue pointer" (click)="editManagerHelper = true"></i>
            <span *ngxPermissionsOnly="['E2B.ROLE.CONTROL']">
              <i *ngIf="editManagerHelper && user.id !== deal.manager.id" style="font-size:18px;float:right;"
                 class="ui icon delete red pointer" (click)="editManagerHelper = false"></i>
              <i *ngIf="!editManagerHelper && user.id !== deal.manager.id" style="font-size:18px;float:right;"
                 class="ui icon edit large blue pointer" (click)="editManagerHelper = true"></i>
            </span>
          </h4>
          <div *ngIf="!editManagerHelper">
            <ng-template let-popup #popupManagerTemplate>
              <div class="header">Пом. Менеджера</div>
              <div class="content">
                <div *ngIf="deal.managerHelper" class="ui image label" style="white-space: nowrap;">
                  <e2b-user-avatar [avatar]="deal.managerHelper.avatar"></e2b-user-avatar>
                  <span class="fw-bold">{{deal.managerHelper ? deal.managerHelper.name : "Не выбран"}}</span>
                </div>
              </div>
            </ng-template>
            <div *ngIf="!deal.managerHelper" class="ui label" style="white-space: nowrap;">
              <span class="fw-bold">Не выбран</span>
            </div>
            <e2b-user-label suiPopup [popupTemplate]="popupManagerTemplate"
                            [user]="deal.managerHelper"></e2b-user-label>
            <i *ngIf="deal.managerHelper" style="font-size:18px;float:right;" class="ui icon delete red pointer"
               (click)="removeManagerHelper()"></i>
          </div>
          <div class="field" *ngIf="editManagerHelper">
            <e2b-staff-dropdown [staff]="employees" [event]="selectManagerHelper"></e2b-staff-dropdown>
          </div>
        </div>
        <div class="ui headPadded column">
          <h4 class="header">
            Аудиторы
            <i *ngIf="editAuditors" style="font-size:18px;float:right;" class="ui icon delete red pointer"
               (click)="editAuditors = false"></i>
            <i *ngIf="!editAuditors" style="font-size:18px;float:right;" class="ui icon edit large blue pointer"
               (click)="editAuditors = true"></i>
          </h4>
          <div *ngIf="!editAuditors">
            <div class="ui image labels">
              <div *ngIf="deal.responsibles && deal.responsibles.length === 1">
                <div *ngFor="let responsible of deal.responsibles; trackBy: identify">
                  <div *ngIf="responsible.group"class="ui image label" style="margin-bottom: 10px;">
                    <span class="fw-bold"
                          >{{responsible.group.name | truncate:18}}</span>
                  </div>
                  <e2b-user-label *ngIf="responsible.user" [user]="responsible.user"></e2b-user-label>
                </div>
              </div>
              <div *ngIf="deal.responsibles && deal.responsibles.length > 1">
                <div class="ui image label" style="margin-bottom: 10px;">
                  <ng-template let-popup #popupResponsiblesTemplate>
                    <div class="header">Аудиторы</div>
                    <div class="content">
                      <div class="ui bulleted list">
                        <div class="item" *ngFor="let r of deal.responsibles">
                          <span *ngIf="r.group">{{ r.group.name }}</span>
                          <span *ngIf="r.user">{{ r.user.name }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <span class="fw-bold" suiPopup [popupTemplate]="popupResponsiblesTemplate"
                        [innerText]="'Выбрано ' + deal.responsibles.length"></span>
                </div>
              </div>
              <div *ngIf="deal.responsibles && deal.responsibles.length === 0" class="ui image label"
                   style="margin-bottom: 10px;">
                <span class="fw-bold">Не выбраны</span>
              </div>
            </div>
          </div>
          <div class="field" *ngIf="editAuditors">
            <e2b-staff-group-multi-dropdown [event]="selectAuditors" [selected]="auditors"
                                            [groups]="groups"
                                            placeholder="Аудиторы"></e2b-staff-group-multi-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="ui sixteen wide column" style="width: 100% !important; padding: 38px 0 26px 0 !important;">
      <ul id="breadcrumb">
        <li *ngFor="let status of statusRelation.all"
            [ngClass]="{'active': statusRelation[status.id] && statusRelation[status.id].state === 'active', 'next': statusRelation[status.id] && statusRelation[status.id].state === 'next', 'negative': statusRelation[status.id] && !statusRelation[status.id].positive, 'empty': status.name === ''}">
          <a [ngClass]="{'cursor': statusRelation[status.id] && statusRelation[status.id].state === 'next'}"
             (click)="statusRelation[status.id] && statusRelation[status.id].state === 'next' ? selectStatus(status) : false"><span>{{status.name}}</span></a>
        </li>
      </ul>
    </div>
    <div class="ui sixteen wide column p-0 m-0" *ngIf="deal.id" style="margin-right: 23px !important;">
      <sui-tabset>
        <div class="ui top attached tabular menu">
          <a class="item" suiTabHeader="1">Общие</a>
          <a class="item" suiTabHeader="2" (activate)="loadPositions()">Позиции</a>
          <a class="item" suiTabHeader="3" [(isActive)]="ordersActive" (click)="onOrdersTabClick()" (activate)="loadOrders()" (deactivate)="onOrdersTabDeactivate()">Заказы</a>
          <a class="item" suiTabHeader="4" (activate)="loadTodos()">Дела и поля</a>
          <a class="item" suiTabHeader="5" (activate)="loadHistory()">История</a>
          <a class="item" suiTabHeader="6" (activate)="loadCalls()">Звонки</a>
          <a class="item" suiTabHeader="7" (activate)="loadFiles()">Файлы</a>
          <a class="item" suiTabHeader="8" (activate)="loadFinance()">Финансы</a>
          <div class="right menu"
              >
            <div class="sms-dropdown" *ngIf="[2,4].includes(deal.currentStatus.id)">
              <div suiPopup *ngIf="!mainContact || (mainContact && !mainContact.phone)" class="smsdropdown-tooltip" popupText="Не указан основной контакт или его номер"></div>
              <div suiDropdown class="ui dropdown item"
                   [isDisabled]="!mainContact || (mainContact && !mainContact.phone)">
                Шаблоны СМС <i class="dropdown icon"></i>
                <div class="menu" suiDropdownMenu>
                  <a class="item" *ngFor="let smsTemplate of smsTemplates | orderByColumn:'id:number'; trackBy: identify"
                     [innerText]="smsTemplate.name" (click)="sendSms(smsTemplate)"></a>
                </div>
              </div>
            </div>
            <div class="ui dropdown item" suiDropdown
                 *ngIf="deal.currentStatus && deal.currentStatus.transitions && deal.currentStatus.transitions.length > 0">
              Шаблоны документов <i class="dropdown icon"></i>
              <div class="menu" suiDropdownMenu>
                <a class="item"
                   *ngFor="let transition of deal.currentStatus.transitions | orderByColumn:'sort:number'; trackBy: identify"
                   [title]="transition.description" [innerText]="transition.name"
                   (click)="performTransition(transition)"></a>
              </div>
            </div>
          </div>
        </div>
        <div class="ui bottom attached segment" suiTabContent="1">
          <div class="ui doubling stackable grid">
            <div class="ui nine wide column" style="border-right: 1px solid #fafafa;padding-right:50px;">

              <h3 class="header">Описание: <i class="ui icon edit blue pointer" (click)="editDescriptionChange()"></i>
              </h3>
              <div *ngIf="!editDescription">
                <span [innerHTML]="deal.description"></span>
                <span class="greytext" *ngIf="!deal.description">Дополнительная информация</span>
              </div>
              <div class="field" *ngIf="editDescription">
                <textarea type="text" [focus]="editDescriptionFocus" placeholder="Дополнительная информация"
                          [ngModelOptions]="{name: 'description'}" [(ngModel)]="deal.description"></textarea>
              </div>
              <button *ngIf="editDescription" class="ui primary button" (click)="editDescriptionTrigger()"><i
                class="ui icon save"></i> Сохранить
              </button>

              <div class="ui divider spacer"></div>

              <div class="ui fluid basic card">
                <div class="content">
                  <div class="header">
                    <h3 class="header">
                      Заказ ({{deal.positions ? deal.positions.length : 0}})
                    </h3>
                  </div>
                  <div class="meta">
                  </div>
                </div>
                <e2b-deal-position [showActions]="false" [subManager]="!!deal.subManager" [dealId]="deal.id"
                                   [onAddPosition]="addDealPosition"
                                   [onRemovePosition]="removeDealPosition"
                                   [products]="positions"
                                   [canEdit]="deal.currentStatus.sort < 4"
                                   (pickOrder)="pickOrder($event)"
                                   [setPositions]="{positions: deal.positions, orders: deal.orders}"></e2b-deal-position>
              </div>

              <div class="ui divider spacer"
                   *ngIf="deal.currentStatus && [2, 4, 5, 20].indexOf(deal.currentStatus.id) > -1"></div>

              <h3 class="header">Комментарии</h3>
              <e2b-comment
                [comments]="comments"
                [setEntity]="{id: id, commentType: CommentType.deal}"
                [mainClientContact]="mainContact"
                (addCommentEvent)="addComment($event)"
                [setCommentFilter]="commentFilter"
                (setCommentFilterEvent)="setCommentFilter($event)"
                #commentSection
              >
              </e2b-comment>
            </div>
            <div class="ui seven wide column" style="padding-left: 50px;">
              <e2b-contact [saveEvent]="addContact" [updateEvent]="updateContact" [contacts]="deal.client.contacts"
                           [clientId]="deal.client.id"></e2b-contact>

              <div class="ui divider spacer"></div>

              <e2b-todo-view *ngIf="deal.currentStatus && [1, 2, 4, 5, 6, 20].indexOf(deal.currentStatus.id) > -1"
                             [setDealId]="deal.id" [showDeal]="false" [todos]="todos"></e2b-todo-view>
              <div class="ui divider spacer"
                   *ngIf="deal.currentStatus && [1, 2, 4, 5, 6, 20].indexOf(deal.currentStatus.id) > -1"></div>
              <h3 id="fields" class="header" *ngIf="isCoordinator || isProjection">Поля</h3>
              <table class="ui very very basic fluid table" *ngIf="isCoordinator">
                <tbody
                  *ngFor="let value of ((deal.values | orderByColumn:'field.name:object:string') | checkValuePermissionPipe)">
                <tr *ngIf="coordinatorFields.indexOf(value.field.name) > -1">
                  <td nowrap class="w-30" style="height: 40px;">
                    <span [innerText]="value.field.name"></span>
                    <span>:</span>
                  </td>
                  <td align="left" style="text-align: left !important;height: 40px;">
                    <div class="field" *ngIf="value.field.type === 'TEXT'">
                      {{value.value}}
                    </div>
                    <div class="field" *ngIf="value.field.type === 'ADDRESS'">
                      {{value.value}}
                    </div>
                    <div class="field" *ngIf="value.field.type === 'NUMBER' || value.field.type === 'MONEY'">
                      {{value.value | number}}
                    </div>
                    <div class="field" *ngIf="mappedValues[value.field.id].field.type === 'ENUM'">
                      <div class="ui action input">
                        <sui-select class="fluid selection"
                                    [name]="children.field.id"
                                    [placeholder]="children.field.name"
                                    [disabled]="true"
                                    [options]="value.field.values"
                                    [(ngModel)]="value.value"
                                    [ngModelOptions]="{name: value.field.name}"
                                    #selectChildren>
                          <sui-select-option *ngFor="let value of selectChildren.filteredOptions"
                                             [value]="value">
                          </sui-select-option>
                        </sui-select>
                      </div>
                    </div>
                    <div class="field" *ngIf="value.field.type === 'DATE'">
                      {{value.value | date:'dd.MM.yyyy'}}
                    </div>
                    <div class="field" *ngIf="value.field.type === 'DATETIME'">
                      {{value.value | date:'dd.MM.yyyy HH:mm'}}
                    </div>
                    <div class="field" *ngIf="mappedValues[value.field.id].field.type === 'USER'">
                      <div class="ui image labels">
                        <div *ngFor="let user of value.value; trackBy: identify">
                          <e2b-user-label *ngIf="user" [user]="user"></e2b-user-label>
                        </div>
                      </div>
                    </div>
                    <div class="field" *ngIf="mappedValues[value.field.id].field.type === 'USERS'">
                      <div class="ui image labels">
                        <div *ngFor="let user of value.value; trackBy: identify">
                          <e2b-user-label *ngIf="user" [user]="user"></e2b-user-label>
                        </div>
                      </div>
                    </div>
                    <div class="field" *ngIf="mappedValues[value.field.id].field.type === 'USERGROUP'">
                      <div class="ui image labels">
                        <div *ngFor="let user of value.value; trackBy: identify">
                          <div *ngIf="user" class="ui image label" style="white-space: nowrap;">
                            <e2b-user-avatar [avatar]="user.avatar"></e2b-user-avatar>
                            <span class="fw-bold">{{user ? user.name : ""}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field" *ngIf="mappedValues[value.field.id].field.type === 'FILE'">
                      <div class="ui input">
                        <div class="d-flex flex-wrap">
                          <div [@divState]="file.state" class="attach"
                               *ngFor="let file of value.value | orderByDate:'-created'; trackBy: identify">
                            <e2b-file [file]="file"></e2b-file>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <table class="ui very very basic fluid table" *ngIf="isProjection">
                <tbody
                  *ngFor="let value of (deal.values | orderByColumn:'field.name:object:string') | checkValuePermissionPipe">
                <tr *ngIf="projectionFields.indexOf(value.field.name) > -1 && value.value">
                  <td nowrap class="w-30" style="height: 40px;">
                    <span [innerText]="value.field.name"></span>
                    <span>:</span>
                  </td>
                  <td align="left" style="text-align: left !important;height: 40px;">
                    <div class="field" *ngIf="value.field.type === 'TEXT'">
                      {{value.value}}
                    </div>
                    <div class="field" *ngIf="value.field.type === 'NUMBER' || value.field.type === 'MONEY'">
                      {{value.value | number}}
                    </div>
                    <div class="field" *ngIf="mappedValues[value.field.id].field.type === 'ENUM'">
                      {{value.value}}
                    </div>
                    <div class="field" *ngIf="value.field.type === 'DATE'">
                      {{value.value | date:'dd.MM.yyyy'}}
                    </div>
                    <div class="field" *ngIf="value.field.type === 'DATETIME'">
                      {{value.value | date:'dd.MM.yyyy HH:mm'}}
                    </div>
                    <div class="field" *ngIf="mappedValues[value.field.id].field.type === 'USER'">
                      <div class="ui image labels">
                        <div *ngFor="let user of value.value; trackBy: identify">
                          <e2b-user-label *ngIf="user" [user]="user"></e2b-user-label>
                        </div>
                      </div>
                    </div>
                    <div class="field" *ngIf="mappedValues[value.field.id].field.type === 'USERS'">
                      <div class="ui image labels">
                        <div *ngFor="let user of value.value; trackBy: identify">
                          <e2b-user-label *ngIf="user" [user]="user"></e2b-user-label>
                        </div>
                      </div>
                    </div>
                    <div class="field" *ngIf="mappedValues[value.field.id].field.type === 'USERGROUP'">
                      <div class="ui image labels">
                        <div *ngFor="let user of value.value; trackBy: identify">
                          <div *ngIf="user" class="ui image label" style="white-space: nowrap;">
                            <e2b-user-avatar [avatar]="user.avatar"></e2b-user-avatar>
                            <span class="fw-bold">{{user ? user.name : ""}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field" *ngIf="mappedValues[value.field.id].field.type === 'FILE'">
                      <div class="ui input">
                        <div class="d-flex flex-wrap">
                          <div [@divState]="file.state" class="attach"
                               *ngFor="let file of value.value | orderByDate:'-created'; trackBy: identify">
                            <e2b-file [file]="file"></e2b-file>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <h3 id="requiredFields" class="header" *ngIf="!isCoordinator && !isProjection">Обязательные поля</h3>
              <table class="ui very very basic fluid table">
                <tr *ngFor="let children of (requiredFields | checkValuePermissionPipe) | orderByColumn:'name:string'">
                  <td nowrap class="w-30">
                    <i class="ui icon"
                       [ngClass]="{'green check circle': mappedValues[children.field.id].value, 'red close': !mappedValues[children.field.id].value}"></i>
                    <span [innerText]="children.field.name" class="pointer"
                          (click)="editMappedValue(children.field.id)"></span>
                    <span>:</span>
                  </td>
                  <td align="left" style="text-align: left !important">
                    <div class="field" *ngIf="children.field.type === 'NUMBER' || children.field.type === 'MONEY'">
                      <div class="ui action input">
                        <input type="number" [(ngModel)]="mappedValues[children.field.id].value"
                               [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                               [ngModelOptions]="{updateOn: 'blur'}"
                               (ngModelChange)="onMappedValueBlur(children.field.id)"
                               [placeholder]="children.field.name"
                               [name]="children.field.id"/>
                        <button class="ui fit red icon button"
                                [disabled]="!mappedValues[children.field.id].value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                (click)="removeValue(mappedValues[children.field.id])">
                          <i class="remove icon"></i>
                        </button>
                      </div>
                    </div>
                    <div class="field" *ngIf="children.field.type === 'BOOLEAN'">
                      <sui-checkbox [(ngModel)]="mappedValues[children.field.id].value"
                                    [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                    [ngModelOptions]="{updateOn: 'blur'}"
                                    (ngModelChange)="onMappedValueBlur(children.field.id)"
                                    [name]="children.field.id"></sui-checkbox>
                    </div>
                    <div class="field" *ngIf="children.field.type === 'TEXT'">
                      <div class="ui action input">
                        <input type="text" [(ngModel)]="mappedValues[children.field.id].value"
                               [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                               [ngModelOptions]="{updateOn: 'blur'}"
                               (ngModelChange)="onMappedValueBlur(children.field.id)"
                               [placeholder]="children.field.name"
                               [name]="children.field.id"/>
                        <button class="ui fit red icon button"
                                [disabled]="!mappedValues[children.field.id].value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                (click)="removeValue(mappedValues[children.field.id])">
                          <i class="remove icon"></i>
                        </button>
                      </div>
                    </div>
                    <div class="field" *ngIf="children.field.type === 'ADDRESS'">
                      <div class="ui fluid action input">
                        <ng-template let-result let-query="query" #addressOptionTemplate>
                          <div class="mininame">{{result.name}}</div>
                          <div class="miniprice">{{result.zip}}</div>
                          <div class="minitext">{{result.fullname}}</div>
                        </ng-template>
                        <sui-search style="width: 100%;"
                                    [optionsLookup]="addressSearch"
                                    name="address"
                                    [maxResults]="25"
                                    [hasIcon]="true"
                                    [inputValue]="mappedValues[children.field.id].value ? mappedValues[children.field.id].value : false"
                                    [placeholder]="mappedValues[children.field.id].value ? mappedValues[children.field.id].value : 'Адрес'"
                                    optionsField="fullname"
                                    [resultTemplate]="addressOptionTemplate"
                                    (resultSelected)="addressSelected($event, children.field.id)">
                        </sui-search>
                        <button class="ui fit red icon button"
                                [disabled]="!mappedValues[children.field.id].value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                (click)="removeValue(mappedValues[children.field.id])">
                          <i class="remove icon"></i>
                        </button>
                      </div>
                    </div>
                    <div class="field" *ngIf="children.field.type === 'ENUM'">
                      <div class="ui action input">
                        <sui-select class="fluid selection"
                                    [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                    [name]="children.field.id"
                                    [placeholder]="children.field.name"
                                    [isSearchable]="true"
                                    [options]="children.field.values"
                                    [(ngModel)]="mappedValues[children.field.id].value"
                                    (ngModelChange)="onMappedValueBlur(children.field.id)"
                                    [ngModelOptions]="{name: children.field.name}"
                                    #selectRequiredChildren>
                          <sui-select-option *ngFor="let value of selectRequiredChildren.filteredOptions"
                                             [value]="value">
                          </sui-select-option>
                        </sui-select>
                        <button class="ui red fit icon button"
                                [disabled]="!mappedValues[children.field.id].value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                (click)="removeValue(mappedValues[children.field.id])">
                          <i class="remove icon"></i>
                        </button>
                      </div>
                    </div>
                    <div class="field" *ngIf="children.field.type === 'DATE'">
                      <div class="ui action input">
                        <input suiDatepicker
                               [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                               [name]="children.field.id"
                               [pickerMode]="'date'"
                               [pickerFirstDayOfWeek]="1"
                               [pickerUseNativeOnMobile]="true"
                               [pickerPlacement]="'top left'"
                               (pickerSelectedDateChange)="onPickerChange($event, children.field.id)"
                               [(ngModel)]="mappedValues[children.field.id].value"
                               [placeholder]="children.field.name">
                        <button class="ui fit red icon button" [disabled]="!mappedValues[children.field.id].value"
                                (click)="removeValue(mappedValues[children.field.id])">
                          <i class="remove icon"></i>
                        </button>
                      </div>
                    </div>
                    <div class="field" *ngIf="children.field.type === 'DATETIME'">
                      <div class="ui action input">
                        <input suiDatepicker
                               [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                               [name]="children.field.id"
                               [pickerMode]="'datetime'"
                               [pickerFirstDayOfWeek]="1"
                               [pickerPlacement]="'top left'"
                               [pickerUseNativeOnMobile]="true"
                               (pickerSelectedDateChange)="onPickerChange($event, children.field.id)"
                               [(ngModel)]="mappedValues[children.field.id].value"
                               [placeholder]="children.field.name">
                        <button class="ui fit red icon button"
                                [disabled]="!mappedValues[children.field.id].value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                (click)="removeValue(mappedValues[children.field.id])">
                          <i class="remove icon"></i>
                        </button>
                      </div>
                    </div>
                    <div class="field" *ngIf="children.field.type === 'USER'">
                      <div class="ui action input">
                        <e2b-staff-dropdown [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                            [staff]="mappedValues[children.field.id].field.values"
                                            [event]="mappedValues[children.field.id].event"></e2b-staff-dropdown>
                        <button class="ui fit red icon button"
                                [disabled]="!mappedValues[children.field.id].value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                (click)="removeValue(mappedValues[children.field.id])">
                          <i class="remove icon"></i>
                        </button>
                      </div>
                    </div>
                    <div class="field" *ngIf="children.field.type === 'USERS'">
                      <div class="ui action input">
                        <sui-multi-select class="fluid selection mx-auto"
                                          placeholder="Сотрудники"
                                          [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                          [(ngModel)]="mappedValues[children.field.id].value"
                                          [ngModelOptions]="{name: children.field.name}"
                                          (ngModelChange)="onMappedValueBlur(children.field.id)"
                                          [options]="mappedValues[children.field.id].field.values"
                                          labelField="name"
                                          [isSearchable]="true"
                                          #selectUsers>
                          <sui-select-option *ngFor="let user of selectUsers.filteredOptions"
                                             [value]="user"></sui-select-option>
                        </sui-multi-select>
                        <button class="ui fit red icon button"
                                [disabled]="!mappedValues[children.field.id].value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                (click)="removeValue(mappedValues[children.field.id])">
                          <i class="remove icon"></i>
                        </button>
                      </div>
                    </div>
                    <div class="field" *ngIf="children.field.type === 'USERGROUP'">
                      <div class="ui action input">
                        <e2b-staff-multi-dropdown
                          [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                          style="width: 100% !important;" [staff]="mappedValues[children.field.id].field.values"
                          [selected]="mappedValues[children.field.id].value"
                          [event]="mappedValues[children.field.id].event"></e2b-staff-multi-dropdown>
                        <button class="ui fit red icon button"
                                [disabled]="!mappedValues[children.field.id].value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                (click)="removeValue(mappedValues[children.field.id])">
                          <i class="remove icon"></i>
                        </button>
                      </div>
                    </div>
                    <div class="field" *ngIf="children.field.type === 'FILE'">
                      <div class="ui input">
                        <input type="file" id="file{{children.field.id}}" name="file" #fileFieldForm multiple
                               [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                               (change)="mappedValues[children.field.id].files = fileFieldForm.files; onMappedValueBlur(children.field.id);">
                        <label for="file{{children.field.id}}">
                          <span *ngIf="!mappedValues[children.field.id].value">Прикрепить файл(ы)</span>
                          <span
                            *ngIf="mappedValues[children.field.id].value">Выбрано {{mappedValues[children.field.id].value.length}}
                            файл(а)</span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="ui bottom attached segment" suiTabContent="2">
          <div class="ui fluid basic card">
            <div class="content">
              <div class="header">
                <h3 class="header">
                  Заказ ({{deal.positions ? deal.positions.length : 0}})
                </h3>
              </div>
              <div class="meta">
              </div>
            </div>
            <div class="ui stackable grid">
              <div class="ui eight wide column" style="border-right: 1px solid #fafafa;padding-right:50px;">
                <e2b-deal-position [subManager]="!!deal.subManager" [onSelectPositionEvent]="onSelectPosition"
                                   [dealId]="deal.id"
                                   [canEdit]="deal.currentStatus.sort < 4"
                                   [onAddPosition]="addDealPosition"
                                   (pickOrder)="pickOrder($event)"
                                   [onRemovePosition]="removeDealPosition" [products]="positions"
                                   [setPositions]="{positions: deal.positions, orders: deal.orders}"
                                   [onSave]="onSavePosition"></e2b-deal-position>
              </div>
              <div class="ui eight wide column" style="padding-left: 50px;">
                <span *ngIf="!selectedPosition"><b><- Выберите позицию</b></span>
                <h3 *ngIf="selectedPosition">
                  {{selectedPosition.position.name}}<br/>
                  <span class="greytext" *ngIf="positionValues.group">{{positionValues.group.name}}</span>
                  <div class="ui divider" style="margin-top: 14px !important; margin-bottom: 14px !important;"></div>
                </h3>
                <div class="clearfix"></div>
                <sui-tabset *ngIf="selectedPosition">
                  <div class="ui secondary pointing menu">
                    <a class="item" suiTabHeader="positionSpecification">Спецификация
                      ({{positionValues.position.length}})</a>
                    <a class="item" [isDisabled]="selectedPosition.position.type === null"
                       suiTabHeader="positionProblem">Проблемы ({{positionValues.problem.length}})</a>
                  </div>
                  <div class="ui bottom attached segment" style="padding-top:10px;padding-bottom:10px;"
                       suiTabContent="positionSpecification">
                    <table class="ui very very basic table">
                      <tbody>
                      <tr
                        *ngFor="let children of positionValues.position | orderByColumn:'field.name:object:string' | checkValuePermissionPipe"
                        style="height: 58px">
                        <td style="padding-left: 25px;">
                          {{children.field.name}}
                        </td>
                        <td align="left" style="text-align: left !important">
                          <div class="field"
                               *ngIf="children.field.type === 'NUMBER' || children.field.type === 'MONEY'">
                            <div class="ui action input">
                              <input type="number" [(ngModel)]="children.value"
                                     [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                     [ngModelOptions]="{updateOn: 'blur'}"
                                     (ngModelChange)="onChangePositionValue(children.field.id)"
                                     [placeholder]="children.field.name"
                                     [name]="children.field.id"/>
                              <button class="ui fit red icon button"
                                      [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'BOOLEAN'">
                            <sui-checkbox [(ngModel)]="children.value"
                                          [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                          [ngModelOptions]="{updateOn: 'blur'}"
                                          (ngModelChange)="onChangePositionValue(children.field.id)"
                                          [name]="children.field.id"></sui-checkbox>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'TEXT'">
                            <div class="ui action input">
                              <input type="text" [(ngModel)]="children.value"
                                     [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                     [ngModelOptions]="{updateOn: 'blur'}"
                                     (ngModelChange)="onChangePositionValue(children.field.id)"
                                     [placeholder]="children.field.name"
                                     [name]="children.field.id"/>
                              <button class="ui fit red icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'ENUM'">
                            <div class="ui action input">
                              <sui-select class="fluid selection"
                                          [name]="children.field.id"
                                          [placeholder]="children.field.name"
                                          [isSearchable]="true"
                                          [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                          [options]="children.field.values"
                                          [(ngModel)]="children.value"
                                          (ngModelChange)="onChangePositionValue(children.field.id)"
                                          [ngModelOptions]="{name: children.field.name}"
                                          #selectPositionChildren>
                                <sui-select-option *ngFor="let value of selectPositionChildren.filteredOptions"
                                                   [value]="value">
                                </sui-select-option>
                              </sui-select>
                              <button class="ui red fit icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'DATE'">
                            <div class="ui action input">
                              <input suiDatepicker
                                     *ngIf="userService.checkFieldPermission(children.field.id, ['write'])"
                                     [name]="children.field.id"
                                     [pickerMode]="'date'"
                                     [pickerFirstDayOfWeek]="1"
                                     [pickerUseNativeOnMobile]="true"
                                     [pickerPlacement]="'top left'"
                                     (pickerSelectedDateChange)="onPositionPickerChange($event, children.field.id)"
                                     [(ngModel)]="children.value"
                                     [placeholder]="children.field.name">
                              <input *ngIf="!userService.checkFieldPermission(children.field.id, ['write'])"
                                     [name]="children.field.id"
                                     [value]="children.value | date:'dd.MM.yyyy'"
                                     [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])">
                              <button class="ui fit red icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'DATETIME'">
                            <div class="ui action input">
                              <input suiDatepicker
                                     [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                     [name]="children.field.id"
                                     [pickerMode]="'datetime'"
                                     [pickerFirstDayOfWeek]="1"
                                     [pickerPlacement]="'top left'"
                                     [pickerUseNativeOnMobile]="true"
                                     (pickerSelectedDateChange)="onPositionPickerChange($event, children.field.id)"
                                     [(ngModel)]="mappedValues[children.field.id].value"
                                     [placeholder]="children.field.name">
                              <button class="ui fit red icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'USER'">
                            <div class="ui action input">
                              <e2b-staff-dropdown
                                [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                [staff]="children.field.values" [event]="children.event"></e2b-staff-dropdown>
                              <button class="ui fit red icon button" [disabled]="!children.value"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'USERS'">
                            <div class="ui action input">
                              <sui-select class="fluid selection mx-auto"
                                          placeholder="Сотрудники"
                                          [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                          [(ngModel)]="children.value"
                                          [ngModelOptions]="{name: children.field.name}"
                                          (ngModelChange)="onChangePositionValue(children.field.id)"
                                          [options]="children.field.values"
                                          labelField="name"
                                          [isSearchable]="true"
                                          #selectPositionUsers>
                                <sui-select-option *ngFor="let user of selectPositionUsers.filteredOptions"
                                                   [value]="user"></sui-select-option>
                              </sui-select>
                              <button class="ui fit red icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'MATERIAL'">
                            <div class="ui action input">
                              <sui-select class="fluid selection mx-auto"
                                          placeholder="Материал"
                                          [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                          [(ngModel)]="children.value"
                                          [ngModelOptions]="{name: children.field.name}"
                                          (ngModelChange)="onChangePositionValue(children.field.id)"
                                          [options]="children.field.values"
                                          labelField="name"
                                          [isSearchable]="true"
                                          #selectPositionMaterial>
                                <sui-select-option *ngFor="let m of selectPositionMaterial.filteredOptions"
                                                   [value]="m"></sui-select-option>
                              </sui-select>
                              <button class="ui fit red icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'CLOTH'">
                            <div class="ui action input">
                              <sui-select class="fluid selection mx-auto"
                                          placeholder="Ткань"
                                          [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                          [(ngModel)]="children.value"
                                          [ngModelOptions]="{name: children.field.name}"
                                          (ngModelChange)="onChangePositionValue(children.field.id)"
                                          [options]="children.field.values"
                                          labelField="name"
                                          [isSearchable]="true"
                                          #selectPositionCloth>
                                <sui-select-option *ngFor="let m of selectPositionCloth.filteredOptions"
                                                   [value]="m"></sui-select-option>
                              </sui-select>
                              <button class="ui fit green icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="changeCloth(children.value)">
                                Применить
                              </button>
                              <button class="ui fit red icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'PERSONAL'">
                            <div suiPopup popupHeader="Внимание"
                                 popupText="Стоимость рассчитывается от базовой стоимости в каталоге товаров"
                                 class="ui action input">
                              <sui-multi-select class="fluid selection mx-auto"
                                                placeholder="Инд изменения"
                                                [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                                [(ngModel)]="children.value"
                                                [ngModelOptions]="{name: children.field.name}"
                                                (ngModelChange)="onChangePositionValue(children.field.id)"
                                                [options]="children.field.values"
                                                labelField="name"
                                                [isSearchable]="true"
                                                #selectPositionPersonal>
                                <sui-select-option *ngFor="let m of selectPositionPersonal.filteredOptions"
                                                   [value]="m"></sui-select-option>
                              </sui-multi-select>
                              <button class="ui fit green icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="changePersonal(children.value)">
                                Применить
                              </button>
                              <button class="ui fit red icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'USERGROUP'">
                            <div class="ui action input">
                              <e2b-staff-dropdown
                                [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                                style="width: 100% !important;" [staff]="children.field.values"
                                [selected]="children.value" [event]="children.event"></e2b-staff-dropdown>
                              <button class="ui fit red icon button"
                                      [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                                      (click)="removePositionValue(children.field.id)">
                                <i class="remove icon"></i>
                              </button>
                            </div>
                          </div>
                          <div class="field" *ngIf="children.field.type === 'FILE'">
                            <div class="d-flex flex-wrap">
                              <div class="attach" *ngFor="let file of children.value">
                                <e2b-file [file]="file"></e2b-file>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="ui input"
                                 *ngIf="userService.checkFieldPermission(children.field.id, ['write'])">
                              <input type="file" id="filePosition{{children.field.id}}" name="filePosition"
                                     #fileFieldPositionForm multiple
                                     (change)="children.files = fileFieldPositionForm.files; onChangePositionValue(children.field.id);">
                              <label for="filePosition{{children.field.id}}">
                                <span>Прикрепить файл(ы)</span>
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="ui bottom attached segment" style="padding-top:10px;padding-bottom:10px;"

                       suiTabContent="positionProblem">
                    <table class="ui very very basic table">
                      <tbody>
                      <tr *ngFor="let problem of positionValues.problem | orderByColumn: '-created:date'"
                          style="height: 58px">
                        <td style="padding-left: 15px; max-width: 250px !important;"
                            *ngIf="problem.field.type !== 'FILE'">
                          {{problem.field.name}}<br/>
                          <span class="greytext">{{problem.created | date: 'short'}}</span>
                        </td>
                        <td *ngIf="problem.field.type === 'TEXT'">
                          {{problem.value}}
                        </td>
                        <td *ngIf="problem.field.type === 'PROBLEM'">
                          <div class="" *ngIf="!problem.selected" (click)="problem.selected = true">
                            {{problem.value && problem.value.name ? problem.value.name : problem.value}}<br/>
                            <span class="greytext"
                                  *ngIf="problem.value && problem.value.name">Стоимость: {{problem.value.cost ? (problem.value.cost | number) : 0}}</span><br/>
                            <span class="greytext"
                                  *ngIf="problem.value && problem.value.name">Скидка клиенту: {{problem.value.discount ? (problem.value.discount | number) : 0}}</span><br/><br/>
                            <ng-container *ngIf="problem.value && problem.value.files">
                              <div class="d-flex flex-wrap">
                                <div class="attach" *ngFor="let f of problem.value.files">
                                  <e2b-file [file]="f"></e2b-file>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                          <div *ngIf="problem.selected">
                            <div class="field">
                              <div class="ui action input dropdownProblem">
                                <div class="ui dropdown button" suiDropdown
                                     [ngClass]="{'primary': !problem.value && !problem.value.name, 'green': problem.value && problem.value.name}">
                                  <div
                                    class="text">{{problem.value && problem.value.name ? problem.value.name : 'Выберите источник'}}</div>
                                  <i class="dropdown icon"></i>
                                  <div class="menu" suiDropdownMenu>
                                    <ng-container *ngFor="let v of problem.field.values | orderByColumn:'name:string'">
                                      <div class="item" *ngIf="!v.childrens || v.childrens.length === 0"
                                           (click)="selectProblem(problem, v)">
                                        {{v.name}}
                                      </div>
                                      <div class="item dropdownProblemMenu" suiDropdown
                                           (isOpenChange)="openProblemMenu(v.name)"
                                           *ngIf="v.childrens && v.childrens.length > 0">
                                        <i class="left dropdown icon"></i>
                                        <span class="text fw-bold">{{v.name}}</span>
                                        <div class="left menu" suiDropdownMenu>
                                          <div (click)="selectProblem(problem, c)" class="item"
                                               *ngFor="let c of v.childrens">{{c.name}}</div>
                                        </div>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="fields">
                              <div class="field">
                                <div class="ui labeled input w-80"
                                     *ngIf="problem.field.type === 'PROBLEM' && problem.selected">
                                  <div class="ui label">
                                    Стоимость
                                  </div>
                                  <input type="number" [ngModelOptions]="{name: 'problemValueCost', updateOn: 'blur'}"
                                         [(ngModel)]="problem.value.cost"
                                         (ngModelChange)="selectProblem(problem, problem.value)"
                                         placeholder="Стоимость">
                                </div>
                              </div>
                              <div class="field">
                                <div class="ui labeled input"
                                     *ngIf="problem.field.type === 'PROBLEM' && problem.selected">
                                  <div class="ui label">
                                    Скидка
                                  </div>
                                  <input type="number"
                                         [ngModelOptions]="{name: 'problemValueDiscount', updateOn: 'blur'}"
                                         [(ngModel)]="problem.value.discount"
                                         (ngModelChange)="selectProblem(problem, problem.value)"
                                         placeholder="Скидка клиенту">
                                </div>
                              </div>
                              <div class="field">
                                <button class="ui icon green button" (click)="savePositionProblem(problem)"><i
                                  class="ui save icon"></i></button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngFor="let problem of positionValues.problem">
                        <td colspan="2" *ngIf="problem.field.type === 'FILE'">
                          <div class="d-flex flex-wrap">
                            <div class="attach" *ngFor="let file of problem.value | orderByDate: '-created'">
                              <e2b-file [file]="file"></e2b-file>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="positionValues.problem.length === 0">
                        <td colspan="2" style="padding-left: 25px;"><b>Нет данных</b></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </sui-tabset>
              </div>
            </div>
          </div>
        </div>
        <div class="ui bottom attached segment" suiTabContent="3">
          <e2b-deal-order [deal]="deal"></e2b-deal-order>
        </div>
        <div class="ui bottom attached segment" suiTabContent="4">
          <div class="ui stackable grid">
            <div class="ui nine wide column">
              <e2b-todo-view [setDealId]="deal.id" [showDeal]="false" [todos]="todos"></e2b-todo-view>
            </div>
            <div class="ui seven wide column">
              <div class="d-flex justify-content-end mb-3">
                <button *ngIf="!shippingDates.length" class="ui blue button mr-1" (click)="getShippingDates()">
                  Ближайшая дата отгрузки
                </button>
                <div *ngIf="shippingDates.length > 0" class="mr-1">
                  <ng-template let-option #shippingDateOptionTemplate>
                    <div class="mininame">
                      отгрузка: {{option.shippingDate | momentPipe:'DD.MM.YYYY':'DD MMM YY, ddd'}}</div>
                    <div class="miniprice">
                      производство: {{option.productionDate | momentPipe:'DD.MM.YYYY':'DD MMM YY, ddd'}}</div>
                    <div class="minitext"></div>
                  </ng-template>
                  <sui-select class="selection nearest-date-select"
                              placeholder="Выберите дату отгрузки"
                              [isSearchable]="false"
                              [(ngModel)]="selectedShippingDate"
                              [ngModelOptions]="{name: 'shippingDate'}"
                              (ngModelChange)="selectShippingDate($event)"
                              [options]="shippingDates"
                              [optionTemplate]="shippingDateOptionTemplate"
                              #selectShippingDateSelect>
                    <sui-select-option *ngFor="let status of selectShippingDateSelect.filteredOptions | orderByColumn:'sort:number'"
                                       [value]="status">
                    </sui-select-option>
                  </sui-select>
                </div>

                <button *ngIf="!mountingDates.length" class="ui orange button" (click)="getMountingDates()">
                  Ближайшая дата монтажа
                </button>
                <div *ngIf="mountingDates.length > 0">
                  <ng-template let-option #mountingDateOptionTemplate>
                    <div class="mininame">
                      монтаж: {{option.mountingDate | momentPipe:'DD.MM.YYYY':'DD MMM YY, ddd'}}</div>
                    <div class="miniprice">
                      производство: {{option.productionDate | momentPipe:'DD.MM.YYYY':'DD MMM YY, ddd'}}</div>
                    <div class="minitext"></div>
                  </ng-template>
                  <sui-select class="selection nearest-date-select"
                              placeholder="Выберите дату монтажа"
                              [isSearchable]="false"
                              [(ngModel)]="selectedMountingDate"
                              [ngModelOptions]="{name: 'mountingDate'}"
                              (ngModelChange)="selectMountingDate($event)"
                              [options]="mountingDates"
                              [optionTemplate]="mountingDateOptionTemplate"
                              #selectMountingDateSelect>
                    <sui-select-option *ngFor="let status of selectMountingDateSelect.filteredOptions | orderByColumn:'sort:number'"
                                       [value]="status">
                    </sui-select-option>
                  </sui-select>
                </div>
              </div>

              <sui-message *ngIf="statusError && statusError.length > 0">
                <div class="content">
                  <div class="header">
                    Необходимо заполнить поля
                  </div>
                  <div *ngFor="let value of statusError">
                    <span [innerText]="value.name"></span>
                  </div>
                </div>
              </sui-message>
              <sui-message class="warning" *ngIf="shippingDatesError">
                <div class="header">
                  Ошибка
                </div>
                <p [innerText]="shippingDatesError.message"></p>
              </sui-message>
              <sui-message class="warning" *ngIf="mountingDatesError">
                <div class="header">
                  Ошибка
                </div>
                <p [innerText]="mountingDatesError.message"></p>
              </sui-message>
              <sui-accordion [closeOthers]="false">
                <sui-accordion-panel *ngFor="let field of fields">
                  <div title>
                    <h3 class="header"><i class="dropdown icon"></i> <span class="fw-bold"
                                                                           [innerText]="field.name"></span></h3>
                  </div>
                  <div content>
                    <table class="ui very very basic fluid table">
                      <tr
                        *ngFor="let children of field.childrens | orderByColumn:'name:string' | orderByColumn:'sort:number'; trackBy: identify">
                        <td class="w-30">
                          <span [innerText]="children.name" class="pointer"
                                (click)="editMappedValue(children.id)"></span>
                          <span>:</span><br/>
                          <div class="coefficientPopup" (click)="calculatedCoefficient.id = null"
                               *ngIf="(children.name.indexOf('Дата монтажа') > -1 || children.name.indexOf('Дата и время монтажа') > -1) && calculatedCoefficient.id === children.id">
                            <table class="ui very basic table">
                              <tr>
                                <td><span class="fw-bold">Производство</span></td>
                                <td><span class="fw-bold">{{calculatedCoefficient.data.use}}</span></td>
                                <td>{{calculatedCoefficient.data.all}}</td>
                              </tr>
                              <tr>
                                <td><span class="fw-bold">Монтаж</span></td>
                                <td><span class="fw-bold">{{calculatedCoefficient.data.mountUse}}</span></td>
                                <td>{{calculatedCoefficient.data.mountAll}}</td>
                              </tr>
                            </table>
                          </div>
                        </td>
                        <td align="left" style="text-align: left !important">
                          <div *ngIf="!mappedValues[children.id].edit" class="mappedValue">
                            <div *ngIf="children.type === 'DATE'" (click)="editMappedValue(children.id)">
                              {{mappedValues[children.id].value && (mappedValues[children.id].value | validDate) ? (mappedValues[children.id].value | date:'dd.MM.yyyy') : "+"}}
                            </div>
                            <div
                              *ngIf="children.type === 'STEP' && mappedValues[children.id].value && !mappedValues[children.id].value.date">
                              {{mappedValues[children.id].value}}
                            </div>
                            <div
                              *ngIf="children.type === 'STEP' && mappedValues[children.id].value && mappedValues[children.id].value.date">
                              <div>
                                {{mappedValues[children.id].value.date}}
                              </div>
                              <div class="d-flex flex-wrap">
                                <div class="attach" *ngFor="let f of mappedValues[children.id].value.files">
                                  <e2b-file [file]="f"></e2b-file>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="children.type === 'PROBLEM'" (click)="editMappedValue(children.id)">
                              {{mappedValues[children.id].value && mappedValues[children.id].value.name ? mappedValues[children.id].value.name : mappedValues[children.id].value}}
                              <br/>
                              <span class="greytext"
                                    *ngIf="mappedValues[children.id].value && mappedValues[children.id].value.name">Стоимость: {{mappedValues[children.id].value.cost ? (mappedValues[children.id].value.cost | number) : 0}}</span><br/>
                              <span class="greytext"
                                    *ngIf="mappedValues[children.id].value && mappedValues[children.id].value.name">Скидка клиенту: {{mappedValues[children.id].value.discount ? (mappedValues[children.id].value.discount | number) : 0}}</span>
                            </div>
                            <div *ngIf="children.type === 'MULTIDATE'" (click)="editMappedValue(children.id)">
                              <div class="ui labels" *ngIf="mappedValues[children.id].value">
                                <div class="ui label" *ngFor="let value of mappedValues[children.id].value">
                                  {{value}}
                                </div>
                              </div>
                              <span
                                *ngIf="!mappedValues[children.id].value || (mappedValues[children.id].value && !mappedValues[children.id].value.date)">+</span>
                            </div>
                            <div *ngIf="children.type === 'MULTIDATETIME'" (click)="editMappedValue(children.id)">
                              <div class="ui labels" *ngIf="mappedValues[children.id].value">
                                <div class="ui label" *ngFor="let value of mappedValues[children.id].value">
                                  {{value}}
                                </div>
                              </div>
                              <span *ngIf="!mappedValues[children.id].value">+</span>
                            </div>
                            <div *ngIf="children.type === 'DATETIME'" (click)="editMappedValue(children.id)">
                              {{mappedValues[children.id].value && (mappedValues[children.id].value | validDate) ? (mappedValues[children.id].value | date:'dd.MM.yyyy HH:mm') : "+"}}
                            </div>
                            <div *ngIf="children.type === 'USER'" (click)="editMappedValue(children.id)">
                              <div *ngIf="mappedValues[children.id].value" class="ui image label"
                                   style="margin-bottom: 10px;">
                                <e2b-user-avatar
                                  *ngIf="mappedValues[children.id].value && mappedValues[children.id].value.avatar"
                                  [avatar]="mappedValues[children.id].value.avatar"></e2b-user-avatar>
                                {{mappedValues[children.id].value.name}}
                                <i class="delete icon"></i>
                              </div>
                              <span *ngIf="!mappedValues[children.id].value">+</span>
                            </div>
                            <div *ngIf="children.type === 'USERS'" (click)="editMappedValue(children.id)">
                              <div class="ui labels">
                                <div class="ui image label" *ngFor="let user of mappedValues[children.id].value"
                                     style="margin-bottom: 10px;">
                                  <e2b-user-avatar *ngIf="user.avatar" [avatar]="user.avatar"></e2b-user-avatar>
                                  {{user.name}}
                                  <i class="delete icon"></i>
                                </div>
                              </div>
                              <span *ngIf="!mappedValues[children.id].value">+</span>
                            </div>
                            <div *ngIf="children.type === 'USERGROUP'" (click)="editMappedValue(children.id)"
                                 class="test">
                              <div class="ui labels" *ngIf="mappedValues[children.id].value">
                                <div class="ui image label" *ngFor="let user of mappedValues[children.id].value"
                                     style="margin-bottom: 10px;">
                                  <e2b-user-avatar *ngIf="user.avatar" [avatar]="user.avatar"></e2b-user-avatar>
                                  {{user ? user.name : ''}}
                                  <i class="delete icon"></i>
                                </div>
                              </div>
                              <span
                                *ngIf="!mappedValues[children.id].value || mappedValues[children.id].value.length === 0">+</span>
                            </div>
                            <div *ngIf="children.type === 'USERGROUP_SINGLE'" (click)="editMappedValue(children.id)"
                                 class="test">
                              <div class="ui labels" *ngIf="mappedValues[children.id].value">
                                <div class="ui image label" style="margin-bottom: 10px;">
                                  <e2b-user-avatar *ngIf="mappedValues[children.id].value.avatar"
                                                   [avatar]="mappedValues[children.id].value.avatar"></e2b-user-avatar>
                                  {{mappedValues[children.id].value ? mappedValues[children.id].value.name : ''}}
                                  <i class="delete icon"></i>
                                </div>
                              </div>
                              <span *ngIf="!mappedValues[children.id].value">+</span>
                            </div>
                            <div class="field" *ngIf="children.type === 'BOOLEAN'">
                              <sui-checkbox [(ngModel)]="mappedValues[children.id].value"
                                            [ngModelOptions]="{updateOn: 'blur'}"
                                            (ngModelChange)="onMappedValueBlur(children.id)"
                                            [isDisabled]="!!mappedValues[children.id].value"
                                            [name]="children.id"></sui-checkbox>
                            </div>
                            <div
                              *ngIf="['DATE','DATETIME', 'ADDRESS', 'FILE', 'USER', 'USERGROUP', 'USERS', 'NUMBER', 'MONEY','BOOLEAN','STEP'].indexOf(children.type) === -1"
                              (click)="editMappedValue(children.id)">
                              {{mappedValues[children.id].value && mappedValues[children.id].value.length > 0 ? mappedValues[children.id].value : "+"}}
                            </div>
                            <div *ngIf="['NUMBER', 'MONEY'].indexOf(children.type) > -1"
                                 (click)="editMappedValue(children.id)">
                              {{mappedValues[children.id].value || mappedValues[children.id].value == 0 ? (mappedValues[children.id].value | number) : "+"}}
                            </div>
                            <div *ngIf="['ADDRESS'].indexOf(children.type) > -1" (click)="editMappedValue(children.id)">
                              {{mappedValues[children.id].value ? mappedValues[children.id].value : "+"}}
                            </div>
                            <div
                              *ngIf="children.type === 'FILE' && mappedValues[children.id].value && mappedValues[children.id].value.length">
                              <div class="d-flex flex-wrap">
                                <div class="attach" *ngFor="let file of mappedValues[children.id].value">
                                  <i class='ui delete red icon pointer' (click)="removeFile(children.id, file)"></i>&nbsp;
                                  <e2b-file [file]="file"></e2b-file>
                                </div>
                              </div>
                              <button class="ui tiny basic button" (click)="editMappedValue(children.id)">
                                <i class="plus icon pointer m-0"></i>&nbsp;&nbsp;Добавить файлы
                              </button>
                            </div>
                            <div *ngIf="children.type === 'FILE' && !mappedValues[children.id].value">
                              <button class="ui tiny basic button" (click)="editMappedValue(children.id)">
                                <i class="plus icon pointer m-0"></i>&nbsp;&nbsp;Добавить файлы
                              </button>
                            </div>
                          </div>
                          <div *ngIf="mappedValues[children.id].edit">
                            <div class="field" *ngIf="children.type === 'NUMBER' || children.type === 'MONEY'">
                              <div class="ui action input">
                                <input type="number" [(ngModel)]="mappedValues[children.id].value"
                                       [ngModelOptions]="{updateOn: 'blur'}"
                                       (ngModelChange)="onMappedValueBlur(children.id)"
                                       [placeholder]="children.name"
                                       [name]="children.id"/>
                                <button class="ui red icon button" [disabled]="!mappedValues[children.id].value"
                                        (click)="removeValue(mappedValues[children.id])">
                                  <i class="remove icon"></i>
                                </button>
                              </div>
                            </div>
                            <div class="field" *ngIf="children.type === 'BOOLEAN'">
                              <sui-checkbox [(ngModel)]="mappedValues[children.id].value"
                                            [ngModelOptions]="{updateOn: 'blur'}"
                                            (ngModelChange)="onMappedValueBlur(children.id)"
                                            [name]="children.id"></sui-checkbox>
                            </div>
                            <div class="field" *ngIf="children.type === 'ADDRESS'">
                              <div class="ui action input">
                                <ng-template let-result let-query="query" #addressOptionTemplate2>
                                  <div class="mininame">{{result.name}}</div>
                                  <div class="miniprice">{{result.zip}}</div>
                                  <div class="minitext">{{result.fullname}}</div>
                                </ng-template>
                                <sui-search style="width: 100%;"
                                            [optionsLookup]="addressSearch"
                                            name="address2"
                                            [maxResults]="25"
                                            [placeholder]="mappedValues[children.id].value ? mappedValues[children.id].value : 'Адрес'"
                                            [inputValue]="mappedValues[children.id].value ? mappedValues[children.id].value : false"
                                            searchDelay="500"
                                            optionsField="fullname"
                                            [resultTemplate]="addressOptionTemplate2"
                                            (resultSelected)="addressSelected($event, children.id)">
                                </sui-search>
                                <button class="ui fit red icon button"
                                        [disabled]="!mappedValues[children.id].value || !userService.checkFieldPermission(children.id, ['write'])"
                                        (click)="removeValue(mappedValues[children.id])">
                                  <i class="remove icon"></i>
                                </button>
                              </div>
                            </div>
                            <div class="field" *ngIf="children.type === 'TEXT'">
                              <div class="ui action input">
                                <input type="text" [(ngModel)]="mappedValues[children.id].value"
                                       [ngModelOptions]="{updateOn: 'blur'}"
                                       (ngModelChange)="onMappedValueBlur(children.id)"
                                       [placeholder]="children.name"
                                       [name]="children.id"/>
                                <button class="ui red icon button" [disabled]="!mappedValues[children.id].value"
                                        (click)="removeValue(mappedValues[children.id])">
                                  <i class="remove icon"></i>
                                </button>
                              </div>
                            </div>
                            <div *ngIf="children.type === 'PROBLEM'">
                              <div class="field" *ngIf="mappedValues[children.id].value">
                                <div class="ui action input dropdownProblem">
                                  <div class="ui dropdown button" suiDropdown
                                       [ngClass]="{'primary': !mappedValues[children.id].value && !mappedValues[children.id].value.name, 'green': mappedValues[children.id].value && mappedValues[children.id].value.name}">
                                    <div
                                      class="text">{{mappedValues[children.id].value && mappedValues[children.id].value.name ? mappedValues[children.id].value.name : 'Выберите источник'}}</div>
                                    <i class="dropdown icon"></i>
                                    <div class="menu" suiDropdownMenu>
                                      <ng-container
                                        *ngFor="let v of mappedValues[children.id].field.values | orderByColumn:'name:string'">
                                        <div class="item" *ngIf="!v.childrens || v.childrens.length === 0"
                                             (click)="selectProblem(mappedValues[children.id], v)">
                                          {{v.name}}
                                        </div>
                                        <div class="item dropdownProblemMenu" suiDropdown
                                             (isOpenChange)="openProblemMenu(v.name)"
                                             *ngIf="v.childrens && v.childrens.length > 0">
                                          <i class="left dropdown icon"></i>
                                          <span class="text fw-bold">{{v.name}}</span>
                                          <div class="left menu" suiDropdownMenu>
                                            <div (click)="selectProblem(mappedValues[children.id], c)" class="item"
                                                 *ngFor="let c of v.childrens">{{c.name}}</div>
                                          </div>
                                        </div>
                                      </ng-container>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="fields" *ngIf="mappedValues[children.id].value">
                                <div class="field">
                                  <div class="ui labeled input w-80"
                                       *ngIf="mappedValues[children.id].field.type === 'PROBLEM'">
                                    <div class="ui label">
                                      Стоимость
                                    </div>
                                    <input type="number" [ngModelOptions]="{name: 'problemValueCost', updateOn: 'blur'}"
                                           [(ngModel)]="mappedValues[children.id].value.cost"
                                           (ngModelChange)="selectProblem(mappedValues[children.id], mappedValues[children.id].value)"
                                           placeholder="Стоимость">
                                  </div>
                                </div>
                                <div class="field">
                                  <div class="ui labeled input"
                                       *ngIf="mappedValues[children.id].field.type === 'PROBLEM'">
                                    <div class="ui label">
                                      Скидка
                                    </div>
                                    <input type="number"
                                           [ngModelOptions]="{name: 'problemValueDiscount', updateOn: 'blur'}"
                                           [(ngModel)]="mappedValues[children.id].value.discount"
                                           (ngModelChange)="selectProblem(mappedValues[children.id], mappedValues[children.id].value)"
                                           placeholder="Скидка клиенту">
                                  </div>
                                </div>
                                <div class="field">
                                  <button class="ui icon green button" (click)="saveProblem(mappedValues[children.id])">
                                    <i class="ui save icon"></i></button>
                                </div>
                              </div>
                            </div>
                            <div class="field" *ngIf="children.type === 'ENUM'">
                              <div class="ui action input">
                                <sui-select class="fluid selection"
                                            [name]="children.id"
                                            [placeholder]="children.name"
                                            [isSearchable]="true"
                                            [options]="children.values"
                                            [(ngModel)]="mappedValues[children.id].value"
                                            (ngModelChange)="onMappedValueBlur(children.id)"
                                            [ngModelOptions]="{name: 'value'}"
                                            #selectChildren>
                                  <sui-select-option *ngFor="let value of selectChildren.filteredOptions"
                                                     [value]="value">
                                  </sui-select-option>
                                </sui-select>
                                <button class="ui red icon button" [disabled]="!mappedValues[children.id].value"
                                        (click)="removeValue(mappedValues[children.id])">
                                  <i class="remove icon"></i>
                                </button>
                              </div>
                            </div>
                            <div class="field" *ngIf="children.type === 'DATE'">
                              <div class="ui action input">
                                <input suiDatepicker
                                       [name]="children.id"
                                       [pickerMode]="'date'"
                                       [pickerFirstDayOfWeek]="1"
                                       [pickerUseNativeOnMobile]="true"
                                       [pickerPlacement]="'top left'"
                                       (pickerSelectedDateChange)="onPickerChange($event, children.id)"
                                       [(ngModel)]="mappedValues[children.id].value"
                                       [placeholder]="children.name">
                                <button class="ui red icon button" [disabled]="!mappedValues[children.id].value"
                                        (click)="removeValue(mappedValues[children.id])">
                                  <i class="remove icon"></i>
                                </button>
                              </div>
                            </div>
                            <div class="field" *ngIf="children.type === 'DATETIME'">
                              <div class="ui action input">
                                <input suiDatepicker
                                       [name]="children.id"
                                       [pickerMode]="'datetime'"
                                       [pickerFirstDayOfWeek]="1"
                                       [pickerPlacement]="'top left'"
                                       [pickerUseNativeOnMobile]="true"
                                       (pickerSelectedDateChange)="onPickerChange($event, children.id)"
                                       [(ngModel)]="mappedValues[children.id].value"
                                       [placeholder]="children.name">
                                <button class="ui red icon button" [disabled]="!mappedValues[children.id].value"
                                        (click)="removeValue(mappedValues[children.id])">
                                  <i class="remove icon"></i>
                                </button>
                              </div>
                            </div>
                            <div *ngIf="children.type === 'MULTIDATE'">
                              <i class="icon add cursor">+</i>
                            </div>
                            <div *ngIf="children.type === 'MULTIDATETIME'">
                              <i class="icon add cursor">+</i>
                            </div>
                            <div class="field" *ngIf="children.type === 'USER'">
                              <div class="ui action input">
                                <e2b-staff-dropdown [staff]="children.values"
                                                    [event]="mappedValues[children.id].event"></e2b-staff-dropdown>
                                <button class="ui red icon button" [disabled]="!mappedValues[children.id].value"
                                        (click)="removeValue(mappedValues[children.id])">
                                  <i class="remove icon"></i>
                                </button>
                              </div>
                            </div>
                            <div class="field" *ngIf="children.type === 'USERS'">
                              <div class="ui action input">
                                <sui-multi-select class="selection"
                                                  placeholder="Сотрудники"
                                                  [(ngModel)]="mappedValues[children.id].value"
                                                  [ngModelOptions]="{name: 'auditor'}"
                                                  (ngModelChange)="onMappedValueBlur(children.id)"
                                                  [options]="children.values"
                                                  labelField="name"
                                                  [isSearchable]="true"
                                                  #selectUsers>
                                  <sui-select-option *ngFor="let user of selectUsers.filteredOptions"
                                                     [value]="user"></sui-select-option>
                                </sui-multi-select>
                                <button class="ui red icon button" [disabled]="!mappedValues[children.id].value"
                                        (click)="removeValue(mappedValues[children.id])">
                                  <i class="remove icon"></i>
                                </button>
                              </div>
                            </div>
                            <div class="field" *ngIf="children.type === 'USERGROUP'">
                              <div class="ui action input">
                                <e2b-staff-multi-dropdown [staff]="children.values"
                                                          [selected]="mappedValues[children.id].value"
                                                          [event]="mappedValues[children.id].event"></e2b-staff-multi-dropdown>
                                <button class="ui red icon button" [disabled]="!mappedValues[children.id].value"
                                        (click)="removeValue(mappedValues[children.id])">
                                  <i class="remove icon"></i>
                                </button>
                              </div>
                            </div>
                            <div class="field" *ngIf="children.type === 'USERGROUP_SINGLE'">
                              <div class="ui action input">
                                <e2b-staff-dropdown [staff]="children.values"
                                                    [selected]="mappedValues[children.id].value"
                                                    [event]="mappedValues[children.id].event"></e2b-staff-dropdown>
                                <button class="ui red icon button" [disabled]="!mappedValues[children.id].value"
                                        (click)="removeValue(mappedValues[children.id])">
                                  <i class="remove icon"></i>
                                </button>
                              </div>
                            </div>
                            <div class="field" *ngIf="children.type === 'FILE'">
                              <div class="ui input">
                                <input type="file" id="file{{field.id}}" name="file" #fileFieldForm multiple
                                       (change)="mappedValues[children.id].files = fileFieldForm.files; onMappedValueBlur(children.id);">
                                <label for="file{{field.id}}">
                                  <span *ngIf="!mappedValues[children.id].value">Прикрепить файл(ы)</span>
                                  <span
                                    *ngIf="mappedValues[children.id].value">Выбрано {{mappedValues[children.id].value.length}}
                                    файл(а)</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </sui-accordion-panel>
              </sui-accordion>
            </div>
          </div>
        </div>
        <div class="ui bottom attached segment" suiTabContent="5">
          <div class="ui animated comments">
            <div *ngFor="let h of history | orderByColumn:'-date:date'; trackBy: identify">
              <div class="comment">
                <a class="avatar">
                  <img class="ui circular mini image" [src]="(h.user.avatar.path + '/' + h.user.avatar.name) | secure">
                </a>
                <div class="content">
                  <a class="author" [innerText]="h.user.name"></a>
                  <div class="date" *ngIf="h.date" [innerText]="h.date | date:'medium'">
                  </div>
                  <div class="text">
                    <span *ngIf="h.old_value && h.new_value">
                      Изменил <span [innerText]="h.field"></span> с '<span [innerText]="h.old_value"></span>' на '<span
                      [innerText]="h.new_value"></span>'
                    </span>
                    <span *ngIf="!h.old_value && h.new_value">
                      <span [innerText]="h.field"></span> '<span [innerText]="h.new_value"></span>'
                    </span>
                    <span *ngIf="!h.new_value && h.old_value">
                      <span [innerText]="h.field"></span> '<span [innerText]="h.old_value"></span>'
                    </span>
                    <span *ngIf="!h.new_value && !h.old_value" [innerText]="h.field"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui bottom attached segment" suiTabContent="6">
          <div class="ui stackable grid" style="margin-top: 24px;">
            <div class="ui eight wide column">
              <table class="ui very basic table">
                <thead>
                <tr>
                  <th>
                    <div class="head">
                      Дата
                    </div>
                  </th>
                  <th>
                    <div class="head">

                    </div>
                  </th>
                  <th>
                    <div class="head">

                    </div>
                  </th>
                  <th>
                    <div class="head">
                    </div>
                  </th>
                  <th>
                    <div class="head">
                    </div>
                  </th>
                  <th>
                    <div class="head">
                    </div>
                  </th>
                  <th>
                    <div class="head">
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let call of calls | orderByColumn:'-date:date'; trackBy: identify">
                  <td>
                    {{call.date|date:'short'}}
                  </td>
                  <td>
                    {{call.src}}<br/>
                    <span class="greytext" *ngIf="call.srcUser">{{call.srcUser.name}}</span>
                    <span class="greytext"
                          *ngIf="call.context === 'incoming' && call.clientContact">{{call.clientContact.name}}</span>
                  </td>
                  <td>
                    {{call.dst}}<br/>
                    <span class="greytext" *ngIf="call.dstUser">{{call.dstUser.name}}</span>
                    <span class="greytext"
                          *ngIf="call.context === 'outgoing' && call.clientContact">{{call.clientContact.name}}</span>
                  </td>
                  <td>
                    {{call.answer}}
                  </td>
                  <td>
                    {{call.billsec}} сек.
                  </td>
                  <td>
                    {{call.disposition}}
                  </td>
                  <td>
                    <div class="call-file" *ngIf="call.file">
                      <a target='_blank'
                         href="{{call.file ? (('call/' + call.file.path + '/' + call.file.name) | apiUrl) : '#'}}">Запись
                        разговора</a>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="ui bottom attached segment" suiTabContent="7">
          <div class="ui grid">
            <div class="ten wide column">
              <h4 class="header">Все файлы</h4>
              <div class="d-flex flex-wrap">
                <div [@divState]="file.state" class="attach"
                     *ngFor="let file of files | orderByDate:'-created'; trackBy: identify"
                     style="position: relative;"
                     [dndDraggable]="file"
                     (dndStart)="onFileDragStart()"
                     (dndEnd)="onFileDragEnd()">
                  <e2b-file [file]="file"></e2b-file>
                  <i class='ui delete red cursor icon' style="position: absolute; top: 0; right: 0"
                     (click)="removeDealFile(file)"></i>
                </div>
              </div>
              <form class="ui form" style="clear: both">
                <div class="ui input">
                  <input type="file" id="dealFile" name="dealFile" #fileForm multiple="multiple"
                         (change)="changeFiles(fileForm.files)">
                  <label for="dealFile" style="margin-top: 25px !important;">
                    <span *ngIf="fileForm.files.length === 0">Добавить файл(ы)</span>
                    <span *ngIf="fileForm.files.length === 1" [innerText]="fileForm.files[0].name"></span>
                    <span *ngIf="fileForm.files.length > 1">
                      <span [innerText]="fileForm.files.length"></span><span> файла(ов)</span>
                    </span>
                  </label>
                </div>
              </form>
            </div>
            <div class="six wide column">
              <h4 class="header">На подписание клиенту</h4>
              <section dndDropzone class="dropzone" dndDragoverClass="dragover" (dndDrop)="onFileDrop($event)"
                       [ngStyle]="{'display': fileDragging ? 'flex' : 'none'}">
                <div style="text-align: center; color: #4c4c4c;">
                  <div style="margin-top: 100px">
                    <i class="file pdf outline big icon"></i>
                    <i class="file alternate outline big icon"></i>
                    <i class="file image outline big icon"></i>
                  </div>
                  <div style="margin-top: 10px">Перенесите файл сюда</div>
                </div>
              </section>
              <div class="d-flex flex-wrap">
                <div class="attach" *ngFor="let file of clientFiles | orderByDate:'created'; trackBy: identify"
                     style="position: relative;">
                  <div style="font-size: 12px">
                    <span *ngIf="file.status === FileSigningStatus.NONE"
                          style="color: #7c797c;">Отправлен на подпись {{file.created | date: 'short'}}</span>
                    <span *ngIf="file.status === FileSigningStatus.SIGNED"
                          style="color: #3aa946;">Подписан {{file.signingDate | date: 'short'}}</span>
                    <span *ngIf="file.status === FileSigningStatus.NOT_SIGNED"
                          style="color: #c52f2f">Не подписан {{file.signingDate | date: 'short'}}</span>
                  </div>
                  <e2b-file [file]="file"></e2b-file>
                  <i class="ui delete red cursor icon" style="position: absolute; top: 0; right: 0"
                     (click)="removeClientFile(file)"></i>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="ui bottom attached segment" suiTabContent="8">
          <h3>План / Факт расчетов с поставщиком: {{finances.plan | number}}руб. / {{finances.fact | number}}руб.
            ({{finances.percent | number}}%)</h3>
          <h4 *ngIf="deal.positions && deal.positions.length > 0 && !deal.subManager">
            Бонус менеджера: <b>{{totalBonus() | number}}</b> руб.
          </h4>
          <h4 *ngIf="deal.positions && deal.positions.length > 0 && deal.subManager">
            Бонус менеджеров: <b>{{(totalBonus() / 100 * 70) | number}} / {{(totalBonus() / 100 * 30) | number}}</b>
            руб.
          </h4>
          <div class="ui stackable grid" style="margin-top: 24px;">
            <div class="ui eight wide column">
              <table class="ui very basic table">
                <thead>
                <tr>
                  <th>
                  </th>
                  <th>
                    <div class="head"
                         (click)="documentSortingGroup === 'date:date' ? documentSortingGroup = '-date:date' : documentSortingGroup = 'date:date'">
                      Дата
                      <i *ngIf="documentSortingGroup === '-date:date'" class="icon sort numeric descending"></i>
                      <i *ngIf="documentSortingGroup === 'date:date'" class="icon sort numeric ascending"></i>
                      <i *ngIf="documentSortingGroup !== 'date:date' && documentSortingGroup !== '-date:date'"
                         class="icon sort"></i>
                    </div>
                  </th>
                  <th>
                    <div class="head">
                      Тип
                    </div>
                  </th>
                  <th>
                    <div class="head">
                      БС
                    </div>
                  </th>
                  <th>
                    <div class="head"
                         (click)="documentSortingGroup === 'amount:number' ? documentSortingGroup = '-amount:number' : documentSortingGroup = 'amount:number'">
                      Доход
                      <i *ngIf="documentSortingGroup === '-amount:number'" class="icon sort numeric descending"></i>
                      <i *ngIf="documentSortingGroup === 'amount:number'" class="icon sort numeric ascending"></i>
                      <i *ngIf="documentSortingGroup !== 'amount:number' && documentSortingGroup !== '-amount:number'"
                         class="icon sort"></i>
                    </div>
                  </th>
                  <th>
                    <div class="head"
                         (click)="documentSortingGroup === 'amount:number' ? documentSortingGroup = '-amount:number' : documentSortingGroup = 'amount:number'">
                      Расход
                      <i *ngIf="documentSortingGroup === '-amount:number'" class="icon sort numeric descending"></i>
                      <i *ngIf="documentSortingGroup === 'amount:number'" class="icon sort numeric ascending"></i>
                      <i *ngIf="documentSortingGroup !== 'amount:number' && documentSortingGroup !== '-amount:number'"
                         class="icon sort"></i>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let doc of documents | orderByColumn: documentSortingGroup">
                  <td>
                    <i class="ui circle icon"
                       [ngClass]="{'red minus': ['Расходный кассовый ордер','Списание с расчетного счета','Поступление товаров и услуг'].indexOf(doc.type) > -1, 'green plus': ['Реализация товаров и услуг','Поступление на расчетный счет','Приходный кассовый ордер','Операция по платежной карте'].indexOf(doc.type) > -1}"></i>
                  </td>
                  <td>
                    {{doc.date | date:'short'}}
                  </td>
                  <td>
                    {{doc.type}}<br/>
                    <span class="greytext">{{doc.document}}</span>
                  </td>
                  <td>
                    {{doc.direction.name}}
                  </td>
                  <td>
                    <span
                      *ngIf="['Реализация товаров и услуг','Поступление на расчетный счет','Приходный кассовый ордер','Операция по платежной карте'].indexOf(doc.type) > -1">
                      <span style="font-size: 16px;" class="fw-bold">{{doc.amount | number}}</span>
                    </span>
                  </td>
                  <td>
                    <span
                      *ngIf="['Расходный кассовый ордер','Списание с расчетного счета','Поступление товаров и услуг'].indexOf(doc.type) > -1">
                      <span style="font-size: 16px;" class="fw-bold">{{doc.amount | number}}</span>
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </sui-tabset>
    </div>
  </div>
</form>
