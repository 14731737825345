<div class="ui fluid container" style="margin:0 !important;padding:0;height: 100% !important;overflow: hidden !important;">
  <div class="ui stackable grid" style="margin:0;padding:0;height: 100% !important;">
    <div class="ui twelve wide column loginBackground" style="margin:0;padding:0;height: 100% !important;">
      <div class="bgpic">
        <div class="bottom">
          <div class="ui white basic segment">
            <h2 class="semi-bold">
              Olissys E2B</h2>
            <p>* Optimal LIving Space SYStem</p>
            <p class="small">3
              Все права защищены © 2017-2019 OLISSYS.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="ui loginWrapper four wide column" style="background: #d7f7ee;height: 100% !important;margin:0 !important;padding:0;">
      <div class="ui loginFormWrapper container">
        <img src="assets/images/logo.svg" style="width: 100%;" width="180" height="120">
        <p style="padding-top:30px;">Введите свою почту, вам на нее придет ссылка для сброса пароля</p>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="ui form">
          <div class="field white">
            <label class="text-uppercase semi-bold">Email</label>
            <input formControlName="email" placeholder="Email" required>
          </div>
          <div *ngIf="email.invalid && (email.dirty || email.touched) && submitted"
               class="alert alert-danger">
            <div *ngIf="email.errors['required']">
              Email is required.
            </div>
            <div *ngIf="email.errors['email']">
              Check email!
            </div>
          </div>
          <div *ngIf="error">
            Произошла ошибка, попробуйте позже
          </div>
          <div *ngIf="isSubmit && !error">
            Письмо отправлено, проверьте Вашу почту.
          </div>
          <div class="ui fluid buttons">
            <button class="ui primary button m20 w-50" type="submit">
              Отправить
            </button>
            <a class="ui red button m20 w-50" [routerLink]="['/login']">
              Отмена
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
