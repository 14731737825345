import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DealService } from '../../../_services/deal.service';
import { Order } from '../../../_models/order.model';
import { OrderModeService } from '../order-mode.service';
import { OrderStatus } from '../../../_models/enums/order-status.enum';
import { OrderType } from '../../../_models/enums/order-type.enum';
import { ErrorService } from '../../../error/error.service';
import { OrderService } from '../../../_services/order.service';

@Component({
  selector: 'e2b-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss',
    '../../../../assets/design/semantic-ui/components/card.css',
  ]
})
export class OrderListComponent implements OnInit {
  mountingDates = [];
  selectedMountingDate: any;
  selectedShippingDate: any;
  shippingDates = [];

  generatingOrders = false;

  constructor(private dealService: DealService,
              private errorService: ErrorService,
              private orderService: OrderService,
              public orderModeService: OrderModeService) {
  }

  ngOnInit() {
  }

  get OrderType() {
    return OrderType;
  }

  get OrderStatus() {
    return OrderStatus;
  }

  generateOrders() {
    this.generatingOrders = true;
    this.orderService.generateOrders(this.orderModeService.getDeal().id).subscribe(orders => {
      this.orderModeService.orders = this.orderModeService.orders.concat(orders);
      this.generatingOrders = false;
    }, e => {
      this.generatingOrders = false;
      this.errorService.pushError(e, 'Ошибка автоматического создания заказов');
    });
  }

  getMountingDates() {
    this.dealService.getMountingDate({ dealId: this.orderModeService.getDeal().id, dateCount: 14 }).subscribe(result => {
      this.mountingDates = result;
    }, e => this.errorService.pushError(e, 'Ошибка при получении дат монтажа'));
  }

  getShippingDates() {
    this.dealService.getShippingDate({ dealId: this.orderModeService.getDeal().id, dateCount: 14 }).subscribe(result => {
      this.shippingDates = result;
    }, e => this.errorService.pushError(e, 'Ошибка при получении дат отгрузки'));
  }

  selectMountingDate(mountingDate: any) {
    this.orderModeService.setAddMode(<Order>{
      type: OrderType.MOUNTING,
      date: moment(mountingDate.mountingDate, 'DD.MM.YYYY').toDate()
    });
  }

  selectShippingDate(shippingDate: any) {
    this.orderModeService.setAddMode(<Order>{
      type: OrderType.DELIVERY,
      date: moment(shippingDate.shippingDate, 'DD.MM.YYYY').toDate()
    });
  }

  setAddOrderMode() {
    this.orderModeService.setAddMode();
  }

  showOrderCard(order: Order) {
    this.orderModeService.setCardMode(order.id);
  }
}
