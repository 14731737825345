import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DealField } from '../../_models/deal-field.model';
import { PositionGroupTemplate } from '../../_models/position-group-template.model';
import { PositionGroup } from '../../_models/position-group.model';
import { DealFieldService } from '../../_services/deal-field.service';
import { PositionGroupTemplateService } from '../../_services/position-group-template.service';
import { PositionGroupService } from '../../_services/position-group.service';

@Component({
  selector: 'e2b-position-template-add',
  templateUrl: './position-template-add.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css',
    './position-template-add.component.less'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PositionTemplateAddComponent implements OnInit {

  template: PositionGroupTemplate = new PositionGroupTemplate();
  positionGroups: PositionGroup[] = [];
  dealFields: DealField[] = [];
  error: any = null;

  constructor(private router: Router,
              private positionGroupService: PositionGroupService,
              private positionGroupTemplateService: PositionGroupTemplateService,
              private positionGroupTemplateFieldService: DealFieldService) {
  }

  ngOnInit() {
    this.template.fields = [];
    this.positionGroupService.find<PositionGroup>().subscribe(groups => {
      this.positionGroups = groups;
      this.positionGroupTemplateFieldService.find<DealField>().subscribe(fields => {
        this.dealFields = fields;
      });
    }, console.error);
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url).catch(console.error);
  }

  changeFields(template: PositionGroupTemplate) {
    const event = new EventEmitter();
    event.subscribe(fields => {
      template.fields = fields;
    }, console.error);
    return event;
  }

  cancel() {
    this.navigateTo('/catalog/template/list');
  }

  save() {
    this.positionGroupTemplateService.create(this.template).subscribe(() => {
      this.navigateTo('/catalog/template/list');
    }, console.error);
  }
}
