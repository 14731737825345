<div class="ui fluid container">
  <div class="ui fluid ordered steps">
    <div class="cursor step" [ngClass]="{'active': step === 1}" (click)="step = 1">
      <div class="content">
        <div class="title">Клиент</div>
        <div class="description">Создание или выбор клиента</div>
      </div>
    </div>
    <div class="cursor step" [ngClass]="{'active': step === 2}" (click)="client ? step = 2 : step = 1">
      <div class="content">
        <div class="title">Сделка</div>
        <div class="description">Ввод позиций сделки</div>
      </div>
    </div>
    <div class="cursor step" [ngClass]="{'active': step === 3}" (click)="deal && client ? step = 3 : step = 1">
      <div class="content">
        <div class="title">Дела</div>
        <div class="description">Добавить дела</div>
      </div>
    </div>
  </div>
  <div *ngIf="step === 1">
    <div *ngIf="!clientId">
      <e2b-client-add [saveEvent]="clientSaved"></e2b-client-add>
    </div>
  </div>
  <div *ngIf="step === 2">
    <br/>
    <br/>
    <e2b-deal-add [clientId]="clientId" [saveEvent]="dealSaved"></e2b-deal-add>
  </div>
  <div *ngIf="step === 3">
    <div class="ui stackable grid">
      <div class="row" *ngIf="clientId">
        <div class="three wide column d-flex align-items-center">
          <h4 class="header">Клиент *</h4>
        </div>
        <div class="ten wide column">
          <div class="fluid field">
            <a href="#" target="_blank" [routerLink]="['/client/card', clientId]">{{client ? client.name : ''}}</a>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="dealId">
        <div class="three wide column d-flex align-items-center">
          <h4 class="header">Сделка *</h4>
        </div>
        <div class="ten wide column">
          <div class="fluid field">
            <a href="#" target="_blank" [routerLink]="['/deal/card', dealId]">{{deal ? deal.name : ''}}</a>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <div class="ui nine wide column">
        <e2b-todo-view [setDealId]="dealId" [showDeal]="true" [todos]="[]"></e2b-todo-view>
      </div>
      <br/>
    </div>
    <button class="ui green button mt-2" [routerLink]="['/deal/card', dealId]">Завершить</button>
  </div>
</div>
