import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightFilter'
})
export class HighlightFilterPipe implements PipeTransform {

  transform(notifications: any[], searchQuery: string): any {
    return searchQuery.trim()
      ? notifications.filter(notification => notification.highlightedText)
      : notifications;
  }

}
