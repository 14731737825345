import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '../../../_models/client.model';
import { PersonType } from '../../../_models/person-type.model';
import { Person } from '../../../_models/person.model';
import { BankService } from '../../../_services/bank.service';
import { ClientService } from '../../../_services/client.service';
import { PersonTypeService } from '../../../_services/person-type.service';
import { PersonService } from '../../../_services/person.service';

@Component({
  selector: 'e2b-person-add',
  templateUrl: './person-add.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../../assets/design/semantic-ui/components/search.min.css',
    '../../../../assets/design/semantic-ui/components/input.min.css',
    '../../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../../assets/design/semantic-ui/components/button.min.css',
    './person-add.component.css']
})
export class PersonAddComponent implements OnInit {
  person: Person = new Person();
  clients: Client[] = [];
  types: PersonType[] = [];
  selectClient = new EventEmitter<Client>();
  @Input()
  clientId: number;
  connectionLinks = ['msktip', 'yasno1tip', 'yasno2', 'spbtip', 'iptitlinov_buh', 'ipkazakov_buh', 'yasno2kassa'];
  bankSearch: (query: any) => Promise<unknown>;

  constructor(private personService: PersonService,
              private personTypeService: PersonTypeService,
              private clientService: ClientService,
              private route: ActivatedRoute,
              private bankService: BankService,
              private router: Router) {

    this.person.passport = <any>{};
    this.person.type = new PersonType();
    this.person.client = new Client();

    this.selectClient.subscribe(client => {
      this.person.client = client;
    });

    this.route.queryParams.subscribe(params => {
      if (params.clientId) {
        this.clientId = parseInt(params.clientId, 0);
        this.person.client.id = this.clientId;
      }
    });

  }

  ngOnInit() {

    this.clientService.find<Client>().subscribe(clients => {
      this.clients = clients;
    });

    this.personTypeService.find<PersonType>().subscribe(types => {
      this.types = types;
    });

    this.bankSearch = async (query) => {
      return new Promise(resolve => {
        this.bankService.search(query).then(results => {
          return resolve(results);
        }).catch(console.error);
      });
    };
  }

  selectType(type) {
    this.person = new Person();
    this.person.passport = <any>{};
    this.person.type = type;
    if (this.clientId) {
      this.person.client = new Client();
      this.person.client.id = this.clientId;
    }
  }

  save() {
    this.personService.create<Person>(this.person).subscribe(created => {
      this.router.navigate(['/client/person/card/' + created.id]).catch(console.error);
    });
  }

  bankSelected(bank) {
    this.person.bank = bank;
  }
}
