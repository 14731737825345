import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Comment } from '../_models/comment.model';

@Injectable()
export class CommentService extends BaseService {
  baseUrl = '/comment';

  checkWhatsAppUpdate(id: number, options): Observable<{ messages: Comment[], lastCheckTimestamp: number }> {
    const params = {};
    if (options) {
      Object.entries(options).map(o => {
        params[o[0]] = o[1];
      });
    }

    return this.http.get<{ messages: Comment[], lastCheckTimestamp: number }>('/deal/whatsapp/message/' + id, {
      params,
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  findForDeal<T>(id: number): Observable<T> {
    return this.http.get<T>(this.baseUrl + '/deal/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  findForDealWhatsApp<T>(id: number): Observable<T> {
    return this.http.get<T>('/deal/whatsapp/message/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  findForClient<T>(id: number): Observable<T> {
    return this.http.get<T>(this.baseUrl + '/client/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  personal<T>(id: number): Observable<T> {
    return this.http.get<T>('/app/deal/comments/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  sendWhatsAppMessage(comment) {
    return this.http.post('/deal/whatsapp/message', comment, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
