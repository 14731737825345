<sui-dimmer class="page inverted" [(isDimmed)]="!loaded" [isClickable]="false">
  <div class="ui text loader">Загрузка...</div>
</sui-dimmer>

<div class="ui stackable grid">
  <div class="ui three wide column ui right border">
    <div class="ui vertical fluid secondary stackable menu">
      <div class="header item">#ТИП</div>
      <a *ngFor="let activity of activities" (click)="applyFilter(activity)"
         [ngClass]="{'active': typeFilters.includes(activity.id)}"
         class="ui item">
        <div [innerText]="activity.name"
             [ngStyle]="{'border-left': '3px solid ' + activity.color}"
             class="ui left floated padded text"></div>
        <div class="clearfix"></div>
      </a>
    </div>
    <div class="ui vertical fluid secondary stackable menu" *ngIf="users.length">
      <div class="header item">#СОТРУДНИКИ</div>
      <a *ngFor="let user of users" (click)="applyUserFilter(user.id)"
         [ngClass]="{'active': options.userId.includes(user.id)}"
         class="ui item">
        <div [innerText]="user.name"
             class="ui left floated padded text"></div>
        <div class="clearfix"></div>
      </a>
    </div>
  </div>
  <div class="ui thirteen wide column">
    <div class="ui form w-100">
      <div class="stackable fields">
        <div class="four wide field">
          <label class="fw-bold">Период</label>
          <e2b-daterangepicker [event]="dateFilter" [dates]="{startDate:options.from, endDate: options.to}"></e2b-daterangepicker>
        </div>
        <div class="ten wide field">
          <label class="fw-bold">Поиск по сообщению</label>
          <div class="ui search">
            <div class="ui icon input" style="max-width: 100%;">
              <input class="prompt" type="text" placeholder="Поиск по сообщению..." [(ngModel)]="searchQuery" (ngModelChange)="applySearchQuery($event)">
              <i class="search icon"></i>
            </div>
            <div class="results"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui comments" style="margin-top: 20px !important;">
      <div class="ui vertically divided padded grid">
        <div class="middle aligned row"
             *ngFor="let notification of notifications | highlightFilter:searchQuery"
             [class.read]="notification.read"
             [class.whatsapp-bg]="notification.type === NotificationTypeEnum.WHATSAPP || notification.type === NotificationTypeEnum.PERSONAL_WHATSAPP">
          <div class="four wide column">
            <div *ngIf="notification.deal" class="subject-info">
              <div *ngIf="notification.deal.filial">
                <a class="ui ribbon small label {{notification.deal.filial.color.replace('#', 'X')}}"
                   style="transform: translate(5%);">{{notification.deal.filial.name}}</a>
              </div>
              <a [routerLink]="['/deal/card/', notification.deal.id]" *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']" [innerText]="notification.deal.name.substr(9)"></a>
              <a [routerLink]="['/deal/production/', notification.deal.id]" *ngxPermissionsOnly="['E2B.ROLE.PRODUCTION']" [innerText]="notification.deal.name.substr(9)"></a>
              <span>,&nbsp;</span>
              <span [innerText]="notification.deal.client.name"></span>
              <div class="mt-2">
                <e2b-deal-status-label [status]="notification.deal.currentStatus"></e2b-deal-status-label>
              </div>
            </div>
            <div *ngIf="notification.task" class="subject-info">
              <div>
                <a [routerLink]="['/brs/task/show/', notification.task.id]">Заявка №{{notification.task.id}}</a>
                <span>&nbsp;c {{notification.task.contragent.name}}.</span>
              </div>
              <div><span class="fw-bold">Статья</span>: {{notification.task.direction.name}}.</div>
              <div><span class="fw-bold">Сумма</span>: {{notification.task.amount}}.</div>
            </div>
            <div *ngIf="notification.project" class="subject-info">
              <a [routerLink]="['/project/show/', notification.project.id]" [innerText]="notification.project.name"></a>
              <div>
                <div class="ui label"
                     style="margin-top: 5px; color: white;"
                     [ngStyle]="{'background-color': '#' + notification.project.status.color}"
                     [innerHtml]="notification.project.status.name"></div>
              </div>
            </div>
            <div class="actions mt-3">
              <ng-container *ngIf="notification.deal">
                <a class="reply" *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']" (click)="read(notification)" [routerLink]="['/deal/card/', notification.deal.id]">
                  <i class="share icon"></i>
                  Перейти
                </a>
                <a class="reply" *ngxPermissionsOnly="['E2B.ROLE.PRODUCTION']" (click)="read(notification)" [routerLink]="['/deal/production/', notification.deal.id]">
                  <i class="share icon"></i>
                  Перейти
                </a>
              </ng-container>

              <a class="reply" *ngIf="notification.task" (click)="read(notification)" [routerLink]="['/brs/task/show/', notification.task.id]">
                <i class="share icon"></i>
                Перейти
              </a>
              <a class="reply" *ngIf="notification.project" (click)="read(notification)" [routerLink]="['/project/show/', notification.project.id]">
                <i class="share icon"></i>
                Перейти
              </a>
              <a class="save" *ngIf="!notification.read" (click)="read(notification)">
                <i class="eye icon"></i>
                Прочитать
              </a>
            </div>
          </div>
          <div class="twelve wide column">
            <div class="comment">
              <a class="circular avatar">
                <img class="ui circular mini image" *ngIf="notification.user"
                     [src]="(notification.user && notification.user.avatar ? notification.user.avatar.path + '/' + notification.user.avatar.name : null) | secure">
                <img class="ui circular mini image" *ngIf="notification.client" [src]="'3C82BF62-82ED-4AEA-A34C-78FD076F23FD/default.png' | secure">
              </a>
              <div class="content">
                <a class="author">{{notification.user ? notification.user.name : (notification.client ? notification.client.name : 'Неизвестный пользователь')}}</a>
                <i *ngIf="notification.type === NotificationTypeEnum.WHATSAPP || notification.type === NotificationTypeEnum.PERSONAL_WHATSAPP" class="whatsapp green large icon" style="margin: -2px 0 0 5px;"></i>
                <div class="date" *ngIf="notification.created">
                  {{notification.created | date:'medium'}}
                </div>
                <div class="ui small label" [ngStyle]="{'background-color': getNotificationTypeProps(notification.type).color, 'color': !notification.read ? 'white' : '#dadada'}">
                  {{getNotificationTypeProps(notification.type).name}}
                </div>
                <div class="text" [innerHTML]="notification.highlightedText || notification.text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui segments text-center" *ngIf="notificationsCount > options.limit">
      <div class="ui segment">
        <sui-pagination [collectionSize]="notificationsCount"
                        [pageSize]="options.limit"
                        [maxSize]="10"
                        [(page)]="selectedPage"
                        (pageChange)="changePage($event)">
        </sui-pagination>
      </div>
    </div>
    <h3 class="header text-center mt-3" *ngIf="!notifications.length">Нет уведомлений</h3>
  </div>
</div>
