import { Component, EventEmitter, Input, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'e2b-staff-group-multi-dropdown',
  templateUrl: './staff.group.multi.dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class StaffGroupMultiDropdownComponent {
  @Input()
  public groups: any[];
  @Input()
  public event: EventEmitter<any[]>;
  @Input()
  placeholder = 'Группы';

  @ViewChild('selectStaffMulti', { static: false })
  selectGroup;

  public selectedGroup: any[];

  @Input()
  set selected(staff: any[]) {
    this.selectedGroup = staff;
    setTimeout(() => {
      this.selectGroup.selectedOptions = staff;
    }, 0);
  }

  save() {
    this.event.emit(this.selectedGroup);
  }
}
