<ng-template let-option #filialOptionTemplate>
  {{option.name}}
</ng-template>
<sui-select class="fluid selection mx-auto"
            placeholder="Филиал"
            [isSearchable]="true"
            [isDisabled]="false"
            [(ngModel)]="selectedFilial"
            [ngModelOptions]="{name: 'filial'}"
            (ngModelChange)="save()"
            [options]="filial"
            labelField="name"
            [optionTemplate]="filialOptionTemplate"
            #selectFilial>
  <sui-select-option *ngFor="let filial of selectFilial.filteredOptions"
                     [value]="filial">
  </sui-select-option>
</sui-select>
