import * as moment from 'moment';
import { Component, Input, OnInit } from '@angular/core';
import { Deal } from '../../../_models/deal.model';
import { DealPosition } from '../../../_models/deal-position.model';
import { DealService } from '../../../_services/deal.service';
import { ErrorService } from '../../../error/error.service';
import { Order } from '../../../_models/order.model';
import { OrderField } from '../../../_models/order-field.model';
import { OrderFieldService } from '../../../_services/order-field.service';
import { OrderModeService } from '../order-mode.service';
import { OrderService } from '../../../_services/order.service';
import { OrderType } from '../../../_models/enums/order-type.enum';
import { User } from '../../../_models/user.model';

@Component({
  selector: 'e2b-order-add',
  templateUrl: './order-add.component.html',
  styleUrls: ['./order-add.component.less']
})
export class OrderAddComponent implements OnInit {
  currentDate = new Date();
  currentPosition: any;
  order: Order = new Order();
  orderTypes: string[] = [];
  positionsType: number;
  responsible: User[] = [];

  calculatedCoefficient = {
    id: false,
    data: {
      use: 0, all: 0, mountUse: 0, mountAll: 0
    }
  };

  @Input()
  deal: Deal;

  constructor(private dealService: DealService,
              private errorService: ErrorService,
              private orderModeService: OrderModeService,
              private orderService: OrderService,
              private orderFieldService: OrderFieldService) {
  }

  ngOnInit() {
    this.orderTypes = Object.values(OrderType).filter(orderType => orderType !== OrderType.MEASUREMENT);

    if (this.orderModeService.orderTemplate) {
      this.order = { ...this.order, ...this.orderModeService.orderTemplate };
      if (this.order.type) {
        this.loadOrderFields();
      }

      if (this.order.type && this.order.date) {
        this.loadResponsible();
      }
    }
  }

  get isOrderValid(): boolean {
    let validation = Boolean(this.order.type && this.order.responsible && this.order.positions.length > 0);
    if (this.order.type === OrderType.DELIVERY) {
      validation = Boolean(validation && this.order.cost);
    }

    if (this.order.type !== OrderType.RECLAMATION) {
      validation = Boolean(validation && this.order.date);
    }

    return validation;
  }

  get OrderType() {
    return OrderType;
  }

  createOrder() {
    if (!this.isOrderValid) {
      return;
    }

    this.order.deal = <Deal>{ id: this.deal.id };
    if (this.order.type === OrderType.PRODUCTION) {
      this.order.cost = this.order.positions.reduce((sum, position) => sum + (position.cost * position.count), 0);
    }
    this.orderService.create<Order>(this.order).subscribe(order => {
      this.orderModeService.orders.push(order);
      this.orderModeService.setCardMode(order.id);
    }, error => this.errorService.pushError(error, 'Ошибка при создании заказа'));
  }

  loadOrderFields() {
    this.orderFieldService.find<OrderField>({ orderType: this.order.type }).subscribe(orderFields => {
      const orderDealPositionFields = orderFields.filter(orderField => orderField.model === 'CRM.FIELD.POSITION');

      this.deal.positions.map(position => {
        (<any>position).orderDealPositionValues = orderDealPositionFields.map(orderField => ({ orderField }));
        const manufacturer = position.position.values.find(positionValue => positionValue.field.name === 'Подрядчик');
        if (manufacturer) {
          position.manufacturer = <User>manufacturer.value;
        }
      });
    });
  }

  loadResponsible() {
    const queryParams: any = { type: this.order.type };
    if (this.order.date) {
      queryParams.date = moment(this.order.date).format('YYYY.MM.DD');
    }
    this.orderService.responsible(queryParams).subscribe((responsible: User[]) => {
      this.responsible = responsible;
    });
  }

  onOrderTypeChange() {
    this.currentPosition = null;
    this.order.responsible = null;
    this.order.positions = [];
    this.responsible = [];
    this.loadOrderFields();
    if (this.order.type === OrderType.RECLAMATION || this.order.date) {
      this.loadResponsible();
    }

  }

  setOrderListMode() {
    this.orderModeService.setListMode();
  }

  selectDate(date: Date) {
    this.order.date = date;
    if (this.order.type) {
      if (this.order.type === OrderType.MOUNTING || this.order.type === OrderType.PRODUCTION) {
        this.calculatedCoefficient.id = false;
        this.dealService.coefficient(moment(date).format('DD.MM.YYYY')).subscribe(c => {
          this.calculatedCoefficient.id = true;
          this.calculatedCoefficient.data = c;
        });
      }
      this.loadResponsible();
    }
  }

  selectPosition(position: DealPosition) {
    const positionIndex = this.order.positions.indexOf(position);
    if (positionIndex === -1) {
      if (this.order.type === OrderType.RECLAMATION) {
        this.order.positions = [];
        if (this.currentPosition) {
          this.currentPosition = position;
        }
      } else if (this.order.type === OrderType.PRODUCTION) {
        this.positionsType = position.position.type;
        const responsible = position.manufacturer;
        if (responsible && this.order.positions.every(p => p.manufacturer && (p.manufacturer.id === responsible.id))) {
          this.order.responsible = this.responsible.find(user => user.id === responsible.id);
        } else {
          this.order.responsible = null;
        }
      }
      this.order.positions.push(position);
    } else {
      this.order.positions.splice(positionIndex, 1);
      if (this.currentPosition === position) {
        this.currentPosition = null;
      }
      if (this.order.type === OrderType.PRODUCTION && !this.order.positions.length) {
        this.positionsType = null;
      }
    }
  }

  setCurrentPosition(position: any) {
    this.currentPosition = position;
  }
}
