<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui form">
        <table class="ui very basic celled table">
          <thead>
          <tr>
            <th></th>
            <th>
              <div class="head">Название</div>
            </th>
            <th>
              <div class="head">Этапы</div>
            </th>
          </tr>
          </thead>
          <tbody *ngFor="let scheme of assemblyShemes | orderByColumn:'name:string'">
          <tr>
            <td>
              <i class="ui list blue cursor icon" [routerLink]="['/catalog', 'assembly-scheme', scheme.id, 'show']" title="Перейти в карточку"></i>
            </td>
            <td>
              <input type="text" [disabled]="!userService.checkPermissions(['assembly-scheme', '/'], ['write'])" [(ngModel)]="scheme.name" (ngModelChange)="save(scheme)" [ngModelOptions]="{updateOn: 'blur'}" placeholder="Название">
            </td>
            <td>
              <div class="ui label" *ngFor="let step of scheme.steps | orderByColumn:'percent:number'">
                {{step.name}} ({{step.percent}}%)
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
