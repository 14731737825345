import { Component, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Filial } from '../../_models/filial.model';
import { FilialService } from '../../_services/filial.service';

@Component({
  selector: 'e2b-filial-dropdown',
  templateUrl: './filial.dropdown.component.html',
  styleUrls: ['./filial.dropdown.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class FilialDropdownComponent implements OnInit {

  @ViewChild('selectFilial', { static: false })
  selectFilial;
  @Input()
  public filial: Filial[];
  @Input()
  public event: EventEmitter<Filial>;
  @Input()
  public selectedFilial: Filial;

  constructor(private readonly filialService: FilialService) {

  }

  save() {
    this.event.emit(this.selectedFilial);
  }

  async ngOnInit() {
    if (!this.filial) {
      this.filial = await this.filialService.find<Filial>().toPromise();
    }
  }
}
