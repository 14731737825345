import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'e2b-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: [
    '../../assets/design/semantic-ui/components/button.min.css',
    '../../assets/design/semantic-ui/components/grid.min.css',
    '../../assets/design/semantic-ui/components/container.min.css',
    '../../assets/design/semantic-ui/components/segment.min.css',
    '../../assets/design/semantic-ui/components/form.min.css',
    '../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../assets/design/semantic-ui/components/input.min.css',
    '../../assets/design/semantic-ui/components/message.min.css',
    './login.component.css']
})
export class LoginComponent implements OnInit {
  reactiveLoginForm: FormGroup;
  tryForm: FormGroup;
  http: HttpClient;
  submitted: boolean;
  userService: UserService;
  error: any;

  tryFormVisible = false;
  tryFormSubmitting = false;
  tryFormSent = false;
  tryCRMRequestError = false;

  constructor(@Inject(FormBuilder) private fb: FormBuilder, http: HttpClient, private router: Router, userService: UserService) {
    this.http = http;
    this.userService = userService;
  }

  ngOnInit() {
    this.initForm();
    this.userService.isAuthenticated().then(() => {
      this.router.navigate(['/']).catch(console.error);
    }).catch(console.error);

  }

  initForm() {
    this.reactiveLoginForm = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      'password': new FormControl('', Validators.required),
      'rememberMe': new FormControl('')
    });

    this.tryForm = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.email
      ])
    });
  }

  onSubmit() {
    this.submitted = true;
    this.error = false;
    if (this.reactiveLoginForm.valid) {
      this.userService.login(this.reactiveLoginForm.getRawValue()).then(() => {
        this.router.navigate(['/']).catch(console.error);
      }).catch(err => {
        this.error = {
          type: 'Неверный логин или пароль',
          action: 'Проверьте правильность вводимых данных'
        };
        console.error(err);
      });
    }
  }

  onTryRequest() {
    if (this.tryForm.valid) {
      this.tryFormSubmitting = true;
      this.userService.tryCRM(this.tryForm.getRawValue()).subscribe(() => {
        this.tryFormVisible = false;
        this.tryFormSubmitting = false;
        this.tryFormSent = true;
      }, error => {
        this.tryFormSubmitting = false;
        this.tryCRMRequestError = true;
      });
    }
  }

  get email() {
    return this.reactiveLoginForm.get('email');
  }

  get password() {
    return this.reactiveLoginForm.get('password');
  }

  get tryEmail() {
    return this.tryForm.get('email');
  }

}
