import { BaseModel } from './base.model';
import { Deal } from './deal.model';
import { TodoStatus } from './todo-status.model';
import { TodoType } from './todo-type.model';
import { User } from './user.model';

export class Todo extends BaseModel {
  to: Date;
  description: string;
  created: Date;
  creator: User;
  checked: boolean;
  from: Date;
  name: string;
  finished: Date;
  finishComment: string;
  responsible: User;
  deleted: boolean;
  type: TodoType;
  status: TodoStatus;
  deal: Deal;
}
