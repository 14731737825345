import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

@Injectable()
export class BudgetService extends BaseService {
  baseUrl = '/budget';

  findBy(options: { directionIds: number[], type: string, date: string }): Promise<any> {
    const params = {};
    if (options) {
      Object.entries(options).map(o => {
        params[o[0]] = o[1];
      });
    }
    return this.http.get(this.baseUrl, {
      params,
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    }).toPromise();
  }

}
