import { Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { TodoType } from '../../_models/todo-type.model';
import { Todo } from '../../_models/todo.model';
import { User } from '../../_models/user.model';
import { TodoTypeService } from '../../_services/todo-type.service';
import { TodoService } from '../../_services/todo.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'e2b-todo-calendar',
  templateUrl: './todo-calendar.component.html',
  styleUrls: [
    '../../../../node_modules/angular-calendar-week-hours-view/angular-calendar-week-hours-view.scss',
    '../../../../node_modules/angular-calendar/scss/angular-calendar.scss',
    '../../../assets/design/semantic-ui/components/loader.css',
    './todo-calendar.component.less'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TodoCalendarComponent implements OnInit, OnDestroy {
  selectResponsible: EventEmitter<User> = new EventEmitter<User>();
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  view = 'week';
  responsible: User = this.userService.current();
  id: number = this.responsible.id;
  startWeek: Moment = moment().startOf('w');
  endWeek: Moment = moment().endOf('w');
  events: CalendarEvent<Todo>[] = [];
  todos: Todo[] = [];
  staff: User[] = [];
  types: TodoType[] = [];
  loaded = false;
  todoTypeSubscribe: Subscription;

  constructor(private todoService: TodoService,
              private todoTypeService: TodoTypeService,
              private userService: UserService) {

    this.selectResponsible.subscribe(user => {
      this.events = [];
      this.id = user.id;
      this.loadTodos();
    });
  }

  ngOnInit() {
    this.todoTypeSubscribe = this.todoTypeService.find<TodoType>().subscribe(types => this.types = types);
    this.userService.find<User>().subscribe(users => {
      this.staff = users;
      this.staff.map(employee => {
        if (this.id === employee.id) {
          this.responsible = employee;
        }
      });
    });
    this.loadTodos();
  }

  loadTodos() {
    this.loaded = false;
    this.todoService.find<Todo>({
      from: this.startWeek.format('YYYY-MM-DD'),
      to: this.endWeek.format('YYYY-MM-DD'),
      id: this.id
    }).subscribe(todos => {
      todos.map(todo => {
        let todoCompleted = '';
        const diff = moment(todo.to).diff(moment(todo.from), 'minutes');
        let clientPhone = todo.deal && todo.deal.client && todo.deal.client.contacts.length > 0 ? todo.deal.client.contacts[0].phone : '';
        const todoStatus = ['E2B.TODO.STATUS.SUCCESS', 'E2B.TODO.STATUS.FAIL'].indexOf(todo.status.systemType);
        if (todoStatus > -1) {
          todoCompleted = 'todoCompleted';
        }
        if (!clientPhone) {
          clientPhone = 'нет № тел.';
        }
        this.events.push({
          id: todo.deal.id,
          title:
            '<div class="title fw-bold">' + todo.type.name + '</div>' +
            '<div class="title fw-bold mb-1 ' + todoCompleted + '">' + todo.name + ' c ' + moment(todo.from).format('HH:mm') + ' до ' + moment(todo.to).format('HH:mm') + '</div>' +
            '<a href="/deal/card/' + todo.deal.id + '" class="title link d-block link">' + todo.deal.name + '</a>' +
            '<a href="/client/card/' + todo.deal.client.id + '" class="title link fw-bold">' + todo.deal.client.name + ' </a>' +
            '<a href="tel:' + clientPhone + '" class="title link">(' + clientPhone + ')</a>',
          start: moment(todo.from).toDate(),
          end: diff < 60 ? moment(todo.to).add(60 - diff, 'minutes').toDate() : moment(todo.to).toDate(),
          color: {
            primary: '#fff',
            secondary: todo.type.color
          },
          meta: todo
        });
      });
      this.refresh.next();
      this.loaded = true;
    });
  }

  currentWeek() {
    this.events = [];
    this.startWeek = moment().startOf('w');
    this.endWeek = moment().endOf('w');
    this.loadTodos();
  }

  prevWeek() {
    this.events = [];
    this.startWeek.subtract(1, 'week');
    this.endWeek.subtract(1, 'week');
    this.loadTodos();
  }

  nextWeek() {
    this.events = [];
    this.startWeek.add(1, 'w');
    this.endWeek.add(1, 'w');
    this.loadTodos();
  }

  ngOnDestroy() {
    this.todoTypeSubscribe.unsubscribe();
  }
}
