<ng-template let-option #entityOptionTemplate>
  {{option.name}}
</ng-template>
<sui-multi-select class="fluid selection mx-auto"
                  placeholder="{{name}}"
                  [isSearchable]="true"
                  [isDisabled]="false"
                  [(ngModel)]="selectedEntities"
                  [ngModelOptions]="{name: 'entity'}"
                  (ngModelChange)="save()"
                  [options]="entities"
                  labelField="name"
                  [optionTemplate]="entityOptionTemplate"
                  #selectEntity>
  <sui-select-option *ngFor="let ds of selectEntity.filteredOptions | orderByColumn:'name:string'"
                     [value]="ds">
  </sui-select-option>
</sui-multi-select>
