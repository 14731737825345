import { EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import { Subscriber } from 'rxjs';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { FileInterface } from '../../_services/file.service';
import { LkBaseService } from './lk-base.service';

@Injectable({
  providedIn: 'root'
})
export class LkFileService extends LkBaseService {
  private static loaded = {};
  private static files = {};

  baseUrl = '/file';


  download(path, name): Observable<any> {
    if (!name || !path) {
      return of(false);
    }
    let objectUrl: string = null;
    if (LkFileService.loaded[path]) {
      return new Observable((observer: Subscriber<any>) => {
        if (LkFileService.files[path]) {
          observer.next(URL.createObjectURL(LkFileService.files[path]));
        } else {
          LkFileService.loaded[path].subscribe(r => {
            objectUrl = URL.createObjectURL(r);
            observer.next(objectUrl);
          });
        }
      });
    }
    LkFileService.loaded[path] = new EventEmitter();
    return new Observable((observer: Subscriber<any>) => {
      this.http.get('/lk' + this.baseUrl + '/' + path + '/' + name, {
        withCredentials: true,
        headers: this.headers,
        responseType: 'blob'
      }).subscribe(r => {
        objectUrl = URL.createObjectURL(r);
        LkFileService.loaded[path].emit(r);
        LkFileService.files[path] = r;
        observer.next(objectUrl);
      });
      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }

  upload(files: FileList): Promise<FileInterface[]> {
    const promises = [];
    if (files) {
      for (let iCount = 0; iCount < files.length; iCount++) {
        promises.push(this.getFile(files[iCount]));
      }
    }
    return Promise.all(promises);
  }

  getFile(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return new Promise((resolve, reject) => {
      return this.http.post('/lk' + this.baseUrl, formData, { headers: this.headers }).toPromise().then(data => {
        return resolve(data);
      }).catch(error => {
        return reject(error);
      });
    });
  }
}
