import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientChannel } from '../../../_models/client-channel.model';
import { ClientType } from '../../../_models/client-type.model';
import { Client } from '../../../_models/client.model';
import { Comment } from '../../../_models/comment.model';
import { DealPosition } from '../../../_models/deal-position.model';
import { Deal } from '../../../_models/deal.model';
import { User } from '../../../_models/user.model';
import { ClientChannelService } from '../../../_services/client-channel.service';
import { ClientTypeService } from '../../../_services/client-type.service';
import { ClientService } from '../../../_services/client.service';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'e2b-my-company-show',
  templateUrl: './my-company-show.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../../assets/design/semantic-ui/components/button.min.css',
    '../../../../assets/design/semantic-ui/components/icon.min.css',
    '../../../../assets/design/semantic-ui/components/table.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class MyCompanyShowComponent implements OnInit, OnDestroy {
  private channelSubscribe: Subscription;
  private typeSubscribe: Subscription;
  private managerSubscribe: Subscription;
  private contractorSubscribe: Subscription;
  id: number;
  contractor: Client = new Client();
  comment: Comment = new Comment();
  channels: ClientChannel[] = [];
  managers: User[] = [];
  types: ClientType[] = [];
  editManager = false;
  editContractorType = false;
  editName = false;
  editDescription = false;
  panelOpen = false;
  err: { name: string, code: number } | boolean = false;
  @ViewChild('contractorName', { static: false })
  clientNameElement: ElementRef;
  selectManager: EventEmitter<User> = new EventEmitter<User>();
  clientNameFocus = false;

  constructor(private clientService: ClientService, private route: ActivatedRoute, private clientTypeService: ClientTypeService,
              private clientManagerService: UserService,
              private clientChannelService: ClientChannelService) {
    this.id = parseInt(this.route.snapshot.params.id, 0);
    this.contractor.deals = Array<Deal>();
    this.contractor.type = new ClientType();
    this.contractor.manager = new User();
    this.contractor.channel = new ClientChannel();

    this.selectManager.subscribe(user => {
      this.contractor.manager = user;
      this.clientService.update<Client>(<Client>{ id: this.contractor.id, manager: { id: this.contractor.manager.id } }).subscribe(() => {
        this.err = false;
      }, e => {
        this.err = e.error;
      });
      this.editManager = false;
    });
  }

  ngOnInit() {
    this.contractorSubscribe = this.clientService.findById<Client>(this.id).subscribe(client => {
      this.contractor = client;
    });
    this.channelSubscribe = this.clientChannelService.find<ClientChannel>().subscribe(
      channels => this.channels = channels, console.error);
    this.managerSubscribe = this.clientManagerService.find<User>().subscribe(
      managers => this.managers = managers, console.error);
    this.typeSubscribe = this.clientTypeService.find<ClientType>().subscribe(
      types => this.types = types, console.error);
  }

  editNameChange() {
    this.editName = true;
    this.clientNameFocus = true;
  }

  editNameTrigger() {
    this.clientService.update<Client>(<Client>{ id: this.contractor.id, name: this.contractor.name }).subscribe(() => {
      this.editName = false;
      this.err = false;
    }, e => {
      this.err = e.error;
    });
  }


  // save() {
  //   this.editManager = false;
  //   this.editChannel = false;
  //   this.editType = false;
  //   this.editDescription = false;
  // }

  totalDealSum(positions: DealPosition[]) {
    let total = 0;
    positions.forEach(position => {
      total += position.price * position.count;
      if (position.discountPrice) {
        total -= position.discountPrice;
      }
    });
    return total;
  }

  ngOnDestroy() {
    this.channelSubscribe.unsubscribe();
    this.managerSubscribe.unsubscribe();
    this.typeSubscribe.unsubscribe();
    this.contractorSubscribe.unsubscribe();
  }

}
