import { Injectable } from '@angular/core';

interface DealColumns {
  client: string;
  manager: string;
  description: string;
  nextTodo: string;
  gaugingDate: string;
  gauger: string;
  order: string;
  contract: string;
  mountingDate: string;
  shipmentDate: string;
  deliveryDate: string;
  prepay: string;
  productionSoft: string;
  productionCabinet: string;
  productionMatress: string;
  productionCarriage: string;
  specifications: string;
  constructors: string;
  productionFinishDate: string;
  deliverer: string;
  mounter: string;
  complaintMounter: string;
  complaintMountingDate: string;
  // shipmentDatetime: string;
  // mountingDatetime: string;
  balance: string;
  balancePassed: string;
  actPassed: string;
  costMounting: string;
  costShipment: string;
  costExtraWork: string;
}

@Injectable({
  providedIn: 'root'
})
export class DealListService {
  dealStatusSort = 1;

  columns: DealColumns = {
    client: 'Клиент',
    manager: 'Менеджер',
    description: 'Суть',
    nextTodo: 'Следующее дело',
    gaugingDate: 'Дата замера',
    gauger: 'Замерщик',
    order: 'Заказ',
    contract: 'Договор',
    mountingDate: 'Дата монтажа',
    shipmentDate: 'Дата отгрузки',
    deliveryDate: 'Дата доставки',
    prepay: 'Предоплата, руб.',
    productionSoft: 'Мягкая мебель',
    productionCabinet: 'Корпусная мебель',
    productionMatress: 'Матрасы',
    productionCarriage: 'Кастомизация',
    specifications: 'Спецификации и чертежи',
    constructors: 'Конструктор',
    productionFinishDate: 'Дата готовности',
    deliverer: 'Доставщик',
    mounter: 'Монтажник',
    complaintMounter: 'Монтажник рекламации',
    complaintMountingDate: 'Дата монтажа рекламации',
    // shipmentDatetime: 'Дата и время доставки',
    // mountingDatetime: 'Дата и время монтажа',
    balance: 'Остаток',
    balancePassed: 'Остаток сдан',
    actPassed: 'Акт сдан',
    costMounting: 'Себестоимость: Монтаж',
    costShipment: 'Себестоимость: Доставка',
    costExtraWork: 'Себестоимость: Доп. работы'
  };

  defaultColumns: { [propName: number]: (keyof DealColumns)[] } = {
    0: ['client', 'manager', 'description', 'nextTodo'], // Запрос с сайта
    1: ['client', 'manager', 'description', 'order', 'nextTodo'], // В работе у менеджера
    2: ['client', 'manager', 'order', 'gaugingDate', 'gauger', 'nextTodo'], // Замер
    3: ['client', 'manager', 'order', 'contract', 'mountingDate', 'nextTodo'], // Договор
    4: ['client', 'manager', 'order'], // Удаленное оформление
    5: ['client', 'manager', 'order', 'prepay', 'contract', 'mountingDate', 'nextTodo'], // Предоплата
    6: ['client', 'manager', 'order'], // Контроль менеджеров
    7: ['client', 'mountingDate', 'productionFinishDate', 'productionSoft', 'productionCabinet', 'productionMatress', 'productionCarriage'], // Распределение
    8: ['client', 'mountingDate', 'productionCabinet', 'manager', 'specifications'], // Спецификации и чертежи
    9: ['client', 'mountingDate', 'productionFinishDate', 'productionSoft', 'productionCabinet', 'productionMatress', 'productionCarriage', 'deliverer', 'mounter'], // Производство
    10: ['client', 'order', 'deliverer', 'mounter', 'mountingDate'], // Доставка
    11: ['client', 'order', 'deliverer', 'mounter', 'mountingDate'], // Монтаж
    12: ['client', 'manager', 'mounter', 'mountingDate', 'balance', 'balancePassed', 'actPassed'], // Оплата
    13: ['client', 'manager', 'mounter', 'mountingDate', 'balance', 'balancePassed', 'actPassed'], // Контроль качества
    14: ['client', 'manager', 'order'], // Расчет себестоимости
    15: ['client', 'manager', 'mounter', 'mountingDate', 'balance', 'balancePassed', 'actPassed'], // Закрытие заказа
    16: ['client', 'manager', 'complaintMounter', 'complaintMountingDate'], // Рекламации
    17: ['client', 'manager', 'order'], // Закрытие рекламаций
    18: ['client', 'manager', 'order'], // Отложено
    19: ['client', 'manager', 'order'], // Отказ
    20: ['client', 'manager', 'order'], // Cуд
    21: ['client', 'manager', 'order'], // Ожидание
    22: ['client', 'manager', 'order'], // Спам
    23: ['client', 'manager', 'order'] // SOS
  };

  _selectedColumns: string[] = ['client', 'manager'];

  constructor() {
  }

  setSelectedColumns(dealStatusSort: number) {
    this.dealStatusSort = dealStatusSort;
    this._selectedColumns = localStorage.getItem(`e2b.deal.list.fieldsFilter.${this.dealStatusSort}`)
      ? JSON.parse(localStorage.getItem(`e2b.deal.list.fieldsFilter.${this.dealStatusSort}`))
      : this.defaultColumns[this.dealStatusSort] ? [...this.defaultColumns[this.dealStatusSort]] : ['client', 'manager'];
  }

  get selectedColumns(): string[] {
    return this._selectedColumns;
  }

  get allColumns(): (keyof DealColumns)[] {
    return (<(keyof DealColumns)[]>Object.keys(this.columns))
      .sort((a, b) => this.columns[a] < this.columns[b] ? -1 : 1);
  }

  isColumnSelected(columnName: keyof DealColumns): boolean {
    return this._selectedColumns.includes(columnName);
  }

  selectColumn(columnName: keyof DealColumns) {
    if (this._selectedColumns.includes(columnName)) {
      this.selectedColumns.splice(this.selectedColumns.indexOf(columnName), 1);
    } else {
      this.selectedColumns.push(columnName);
    }
    localStorage.setItem(`e2b.deal.list.fieldsFilter.${this.dealStatusSort}`, JSON.stringify(this.selectedColumns));
  }

  getColumnDisplayName(columnName: string): string {
    return this.columns[columnName];
  }
}
