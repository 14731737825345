import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'e2b-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/form.min.css',
    '../../assets/design/semantic-ui/components/button.min.css',
    '../../assets/design/semantic-ui/components/grid.min.css',
  ],
  encapsulation: ViewEncapsulation.None
})
export class FeedbackComponent {
  post: { subject: string, description: string } = { subject: '', description: '' };

  constructor() {
  }
}
