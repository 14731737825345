import { Pipe, PipeTransform } from '@angular/core';
import { Todo } from '../../_models/todo.model';

@Pipe({
  name: 'todoFilter',
  pure: false
})
export class TodoFilterPipe implements PipeTransform {
  transform(items: Todo[], filter: string[]): Todo[] {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item: Todo) => this.applyFilter(item, filter));
  }

  applyFilter(todo: Todo, filter: string[]): boolean {
    if (!todo.deleted && todo.status && todo.status.systemType && filter.indexOf(todo.status.systemType) > -1) {
      return true;
    } else if (!todo.status) {
      return false;
    } else {
      return false;
    }
  }
}
