import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'e2b-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.less',
    '../../assets/design/semantic-ui/components/header.min.css',
    '../../assets/design/semantic-ui/components/grid.min.css',
    '../../assets/design/semantic-ui/components/button.min.css',
    '../../assets/design/semantic-ui/components/form.min.css',
  ]
})
export class SupportComponent {
  message: string;
  email: string;
  name: string;

  loading = false;
  sended = false;

  constructor(private http: HttpClient) {
  }

  get isFormValid(): boolean {
    return Boolean(this.message && this.message.trim() && this.email && this.email.trim() && this.name && this.name.trim());
  }

  send() {
    if (!this.isFormValid) {
      return;
    }

    this.loading = true;
    this.http.post('/support', { name: this.name, message: this.message, email: this.email }, { headers: { 'X-Skip-Interceptor': '' } }).subscribe(() => {
      this.loading = false;
      this.sended = true;
    }, console.error);
  }

}
