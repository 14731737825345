import { Component, EventEmitter, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { User } from '../../_models/user.model';

@Component({
  selector: 'e2b-staff-simple-dropdown',
  templateUrl: './staff.dropdown.simple.component.html',
  encapsulation: ViewEncapsulation.None
})

export class StaffSimpleDropdownComponent {

  @ViewChild('selectStaff', { static: false })
  selectStaff;

  public selectedStaff: User;
  @Input()
  public error: boolean;

  @Input()
  public staff: User[];
  @Input()
  public event: EventEmitter<User>;

  @Input()
  set selected(staff: User) {
    setTimeout(() => {
      this.selectedStaff = staff;
      this.selectStaff.selectedOption = staff;
    }, 200);
  }

  save() {
    this.event.emit(this.selectedStaff);
  }
}
