import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

@Injectable()
export class BankService extends BaseService {
  baseUrl = '/bank';

  search(query): Promise<any> {
    return this.http.get(this.baseUrl + '?q=' + query, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    }).toPromise();
  }

}
