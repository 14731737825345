import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { AccessRightType } from '../_models/role.model';
import { UserService } from '../_services/user.service';

@Directive({
  selector: '[e2bPermission]'
})
export class PermissionDirective {

  accessTypes: string[] = ['read', 'write'];

  constructor(@Inject(ElementRef) private element: ElementRef, private userService: UserService) {
  }

  @Input()
  set accessRightType(type: AccessRightType) {
    if (type) {
      this.accessTypes = [type];
    }
  }

  @Input()
  set e2bPermission(roles) {
    let accessObj = this.userService.current().accessObject.routes;
    roles.map(r => {
      if (accessObj[r] !== undefined) {
        accessObj = accessObj[r];
      }
    });
    if (typeof accessObj === 'string' && this.accessTypes.indexOf(accessObj) === -1) {
      this.element.nativeElement.parentNode.removeChild(this.element.nativeElement);
    } else if (typeof accessObj !== 'string') {
      this.element.nativeElement.parentNode.removeChild(this.element.nativeElement);
    }
  }
}
