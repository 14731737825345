<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui text menu">
        <div class="right item" *ngIf="positionGroups && positionGroups.length > 0">
          <span class="fw-bold">Кол-во групп: {{positionGroups.length}}</span>
        </div>
      </div>
      <div class="ui form">
        <table class="ui very basic selectable celled table">
          <thead>
          <tr>
            <th>
              <div class="head">Артикул</div>
            </th>
            <th>
              <div class="head">Название</div>
            </th>
            <th>
              <div class="head">Шаблон</div>
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let group of positionGroups | orderByColumn:'name:string'" class="pointer">
            <td width="20%">
              <div class="field">
                <input [disabled]="!userService.checkPermissions(['position-group', '/'], ['write'])" [(ngModel)]="group.article" (change)="update(group)">
              </div>
            </td>
            <td width="20%">
              <div class="field">
                <input [disabled]="!userService.checkPermissions(['position-group', '/'], ['write'])" [(ngModel)]="group.name" (change)="update(group)">
              </div>
            </td>
            <td width="20%" (click)="editGroup(group.id)">
              <div class="ui label" *ngIf="group.template && editedGroup !== group.id && userService.checkPermissions(['position-group', '/'], ['write'])">
                <span [innerText]="group.template.name"></span>&nbsp;&nbsp;&nbsp;
              </div>
              <div class="field" *ngIf="editedGroup === group.id">
                <e2b-position-group-template-dropdown [templates]="templates" [selected]="group.template" [event]="save(group)"></e2b-position-group-template-dropdown>
              </div>
            </td>
            <td width="1%">
              <i *ngIf="editedGroup !== group.id && userService.checkPermissions(['position-group', '/'], ['write'])" class="ui red remove cursor icon" (click)="remove(group)"></i>
              <i *ngIf="editedGroup === group.id && userService.checkPermissions(['position-group', '/'], ['write'])" class="icon green check cursor m-0" (click)="editedGroup = null"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
