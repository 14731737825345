<h3 class="fw-bold header">СЕРВИС
  <div class="ui dropdown" suiDropdown style="display: inline !important;">
    <i class="blue cog icon"></i>
    <div class="menu" suiDropdownMenu>
      <a class="item" *ngFor="let widgetName of allWidgets">
        <div class="field">
          <input type="checkbox" [checked]="selectedWidgets.includes(widgetName)" (change)="selectWidget(widgetName)"/>
          <label> {{widgets[widgetName]}}</label>
        </div>
      </a>
    </div>
  </div>
</h3>
<div class="ui stackable cards" [ngClass]="{'three': innerWidth <= 1280, 'four': innerWidth > 1280, 'six': innerWidth > 1620}">
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('delivery')">
    <div class="content">
      <div class="header">
        Доставки <i class="ui grey chart line icon"></i>
      </div>
      <div class="description">
        <table class="ui very very basic table" style="overflow-y: auto !important;">
          <tr>
            <td>
              <span class="header">План</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" [ngClass]="{'active': isPlanSelected(data[period].delivery.planDetails, 'delivery')}" (click)="selectPlan(data[period].delivery.planDetails, 'delivery')" suiPopup popupInverted popupText="План">{{data[period].delivery.plan | number}}</a>
                  &nbsp;<a target="_blank" [routerLink]="['/report', 'delivery']" [queryParams]="{from: getQueryFrom(), to: getQueryTo()}"><i class="ui small purple share icon"></i></a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Завершено</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'delivery']" [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted popupText="Факт">{{data[period].delivery.fact | number}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">В работе</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'delivery']" [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted popupText="В работе">{{data[period].delivery.working | number}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Перенесено</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" [ngClass]="{'active': isSelected(data[period].delivery.moveDetails, 'delivery')}" (click)="selectMoved(data[period].delivery.moveDetails, 'delivery')" suiPopup popupInverted popupText="Перенесены">{{data[period].delivery.moved | number}}</a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('mounting')">
    <div class="content">
      <div class="header">
        Монтажи <i class="ui grey chart line icon"></i>
      </div>
      <div class="description">
        <table class="ui very very basic table" style="overflow-y: auto !important;">
          <tr>
            <td>
              <span class="header">План</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" [ngClass]="{'active': isPlanSelected(data[period].mounting.planDetails, 'mounting')}" (click)="selectPlan(data[period].mounting.planDetails, 'mounting')" suiPopup popupInverted popupText="План">{{data[period].mounting.plan | number}}</a>
                  &nbsp;<a target="_blank" [routerLink]="['/report', 'mounting']" [queryParams]="{from: getQueryFrom(), to: getQueryTo()}"><i class="ui small purple share icon"></i></a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Завершено</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'mounting']" [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted popupText="Факт">{{data[period].mounting.fact | number}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">В работе</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'mounting']" [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted popupText="В работе">{{data[period].mounting.working | number}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Перенесено</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" [ngClass]="{'active': isSelected(data[period].mounting.moveDetails, 'mounting')}" (click)="selectMoved(data[period].mounting.moveDetails, 'mounting')" suiPopup popupInverted popupText="Перенесены">{{data[period].mounting.moved | number}}</a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('reclamation')">
    <div class="content">
      <div class="header">
        Рекламации <i class="ui grey calendar outline icon"></i>
      </div>
      <div class="description">
        <table class="ui very very basic table" style="overflow-y: auto !important;">
          <tr>
            <td>
              <span class="header">План</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" [ngClass]="{'active': isPlanSelected(data[period].reclamation.planDetails, 'reclamation')}" (click)="selectPlan(data[period].reclamation.planDetails, 'mounting')" suiPopup popupInverted popupText="План">{{data[period].reclamation.plan | number}}</a>
                  &nbsp;<a target="_blank" [routerLink]="['/report', 'reclamation']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), resolve: true}"><i class="ui small purple share icon"></i></a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Завершено</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'reclamation']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), resolve: true}" suiPopup popupInverted popupText="Факт">{{data[period].reclamation.fact | number}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">В работе</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'reclamation']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), resolve: true}" suiPopup popupInverted popupText="В работе">{{data[period].reclamation.working | number}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Перенесено</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" [ngClass]="{'active': isSelected(data[period].reclamation.moveDetails, 'reclamation')}" (click)="selectMoved(data[period].reclamation.moveDetails, 'reclamation')" suiPopup popupInverted popupText="Перенесены">{{data[period].reclamation.moved | number}}</a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('reclamationLosses')">
    <div class="content">
      <div class="header">
        Рекламации: Потери <i class="ui grey calendar outline icon"></i>
      </div>
      <div class="description">
        <div class="ui mini statistic">
          <div class="value">
            <a class="padded" target="_blank" [routerLink]="['/report', 'reclamation']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), resolve: true}" [ngClass]="{'red': data[period].reclamationLosses > 0}">{{data[period].reclamationLosses | number}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('problem')">
    <div class="content">
      <div class="header">
        Проблемы <i class="ui grey chart line icon"></i>
      </div>
      <div class="description">
        <div>
          <i class="green angle double down icon"></i>
          <div class="ui mini statistic">
            <div class="value">
              <a class="padded" target="_blank" [routerLink]="['/report', 'problem']" [queryParams]="{from: getPreviousQueryFrom(), to: getPreviousQueryTo(), dateType: 'created'}" suiPopup popupInverted popupText="Прошлый период: Кол-во новых проблем">{{data[period].problems.previous.all}}</a>
              <a class="padded" target="_blank" [routerLink]="['/report', 'problem']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), dateType: 'created'}" suiPopup popupInverted popupText="Текущий период: Кол-во новых проблем">{{data[period].problems.present.all}}</a>
              <a class="padded"><i class="ui angle icon" [ngClass]="{'up red': data[period].problems.present.all > data[period].problems.previous.all, 'down green': data[period].problems.present.all < data[period].problems.previous.all}"></i></a>
            </div>
          </div>
        </div>
        <div>
          <i class="red angle double down icon"></i>
          <div class="ui mini statistic">
            <div class="value">
              <a class="padded">{{data[period].problems.previous.opened}}</a>
              <a class="padded">{{data[period].problems.present.opened}}</a>
              <a class="padded"><i class="ui angle icon" [ngClass]="{'up red': data[period].problems.present.opened > data[period].problems.previous.opened, 'down green': data[period].problems.present.opened < data[period].problems.previous.opened}"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('problemLosses')">
    <div class="content">
      <div class="header">
        Проблемы: Потери <i class="ui grey chart line icon"></i>
      </div>
      <div class="description">
        <div class="ui mini statistic">
          <div class="value">
            <a class="padded" target="_blank" [routerLink]="['/report', 'problem']" [queryParams]="{from: getPreviousQueryFrom(), to: getPreviousQueryTo()}">{{data[period].problems.previous.cost | humanNumber}}</a>
            <a class="padded" target="_blank" [routerLink]="['/report', 'problem']" [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" [ngClass]="{'red': data[period].problems.present.cost > data[period].problems.previous.cost}">{{data[period].problems.present.cost | humanNumber}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br/>
<div *ngIf="details.length">
  <table class="ui very basic selectable celled table">
    <thead>
    <tr>
      <th>
        <div class="head">
          Сделка
        </div>
      </th>
      <th>
        <div class="head">
          Клиент
        </div>
      </th>
      <th>
        <div class="head">
          Статус
        </div>
      </th>
      <th>
        <div class="head">
          Дата в плане
        </div>
      </th>
      <th>
        <div class="head">
          Текущая дата
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let d of details">
      <td nowrap>
        <div e2b-filial-label [filial]="d.filial"></div>
        <a class="d-inline-block v-align-top w-50" [routerLink]="['/deal/card/', d.id]" target="_blank">{{d.name}}</a>
        <span class="greytext d-block w-100">{{d.created ? (d.created | date:'short') : "-"}}</span>
      </td>
      <td nowrap>
        <a [routerLink]="['/client/card', d.client.id]" target="_blank">{{d.client.name}}</a><br/>
      </td>
      <td>
        <e2b-deal-status-label [status]="d.currentStatus"></e2b-deal-status-label>
      </td>
      <td>
        {{d.from}}
      </td>
      <td>
        {{d.to}}
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div *ngIf="planDetails.length">
  <table class="ui very basic selectable celled table">
    <thead>
    <tr>
      <th>
        <div class="head">
          Сделка
        </div>
      </th>
      <th>
        <div class="head">
          Клиент
        </div>
      </th>
      <th>
        <div class="head">
          Статус
        </div>
      </th>
      <th>
        <div class="head">
          Дата в плане
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let d of planDetails">
      <td nowrap>
        <div e2b-filial-label [filial]="d.filial"></div>
        <a class="d-inline-block v-align-top w-50" [routerLink]="['/deal/card/', d.id]" target="_blank">{{d.name}}</a>
        <span class="greytext d-block w-100">{{d.created ? (d.created | date:'short') : "-"}}</span>
      </td>
      <td nowrap>
        <a [routerLink]="['/client/card', d.client.id]" target="_blank">{{d.client.name}}</a><br/>
      </td>
      <td>
        <e2b-deal-status-label [status]="d.currentStatus"></e2b-deal-status-label>
      </td>
      <td>
        {{d.date}}
      </td>
    </tr>
    </tbody>
  </table>
</div>
