import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { User } from '../_models/user.model';
import { BaseService } from './base.service';

/**
 * Сервис пользователя
 * @class UserService
 */
@Injectable()
export class UserService extends BaseService {

  baseUrl = '/user';
  private currentUser: User;

  /**
   * Проверка аутентификации
   * @returns {Promise<User>}
   */
  isAuthenticated(): Promise<User> {
    return new Promise((resolve, reject) => {
      if (!this.getToken()) {
        return reject('No token found');
      }
      this.http.get(this.baseUrl + '/check', {
        withCredentials: true,
        headers: { 'Authorization': this.getToken() }
      }).subscribe((res: User) => {
        if (res['id']) {
          localStorage.setItem('e2b.user.json', JSON.stringify(res));
          this.currentUser = res;
          return resolve(res);
        }
        return reject();
      }, e => {
        return reject(e);
      });
    });
  }

  /**
   * Получение текущего пользователя
   * @returns {User}
   */
  current(): User {
    if (!this.currentUser) {
      this.currentUser = JSON.parse(localStorage.getItem('e2b.user.json'));
    }
    return this.currentUser;
  }

  /**
   * Вход пользователя
   * @param {User} user {email, password}
   * @returns {Promise<User, any>}
   */
  login(user: User): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      return this.http.post(this.baseUrl + '/login', user, { withCredentials: true }).subscribe(res => {
        localStorage.setItem('e2b.user.userId', res['id']);
        localStorage.setItem('e2b.user.token', res['token']);
        return resolve(this.currentUser);
      }, err => {
        return reject(new Error(err));
      });
    });
  }

  /**
   * Выход
   * @returns {Promise<any>}
   */
  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get('/user/logout', { withCredentials: true, headers: { 'Authorization': this.getToken() } }).subscribe(res => {
        this.currentUser = null;
        this.clearStorage();
        return resolve(res);
      }, err => {
        return reject(err);
      });
    });
  }

  tryCRM(credentials) {
    return this.http.post('/trycrm', credentials, { withCredentials: true });
  }

  /**
   * Очистка данных пользователя
   */
  clearStorage() {
    localStorage.removeItem('e2b.user.userId');
    localStorage.removeItem('e2b.user.json');
    localStorage.removeItem('e2b.user.token');
  }

  /**
   * Получение списка менеджеров
   * @returns {Observable<[User]>}
   */
  managers<T>(): Observable<[T]> {
    return this.http.get<[T]>(this.baseUrl + '/managers', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  /**
   * Получение списка продажников
   * @returns {Observable<[User]>}
   */
  sales<T>(): Observable<[T]> {
    return this.http.get<[T]>(this.baseUrl + '/sales', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  /**
   * Получение списка монтажников
   * @returns {Observable<[User]>}
   */
  mounters<T>(): Observable<[T]> {
    return this.http.get<[T]>(this.baseUrl + '/mounters', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  /**
   * Получение списка сотрудников производства
   * @returns {Observable<[User]>}
   */
  production<T>(): Observable<[T]> {
    return this.http.get<[T]>(this.baseUrl + '/production', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  softProduction<T>(): Observable<[T]> {
    return this.http.get<[T]>(this.baseUrl + '/softProduction', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  /**
   * Переключение пользователя
   * @returns {Observable<[User]>}
   */
  switch(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/switch/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  /**
   * Получение токена
   * @returns {string | null}
   */
  private getToken() {
    return localStorage.getItem('e2b.user.token');
  }

  resetPassword(email): Observable<any> {
    return this.http.post(this.baseUrl + '/reset', { email: email });
  }

  changePassword(email, key, password): Observable<any> {
    return this.http.post(this.baseUrl + '/change', { email: email, key: key, password: password });
  }

  checkPermissions(route: string[], accessTypes: string[] = ['read', 'write']): boolean {
    let accessObj = this.current().accessObject.routes;
    route.map(r => {
      if (accessObj[r] !== undefined) {
        accessObj = accessObj[r];
      }
    });
    if (typeof accessObj === 'string' && accessTypes.indexOf(accessObj) === -1) {
      return false;
    } else if (typeof accessObj !== 'string') {
      return false;
    }
    return true;
  }

  checkFieldPermission(id: number, accessTypes: string[] = ['read', 'write']) {
    const accessObj = this.current().accessObject.fields[id];
    if (!accessObj) {
      return false;
    }
    if (typeof accessObj === 'string' && accessTypes.indexOf(accessObj) === -1) {
      return false;
    } else if (typeof accessObj !== 'string') {
      return false;
    }
    return true;
  }

  checkRoles(roles: string[] = []): boolean {
    return this.currentUser
      ? this.currentUser.roles.map(_role => _role.name).some(role => roles.indexOf(role) > -1)
      : false;
  }

}
