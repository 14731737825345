import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseModel } from '../../_models/base.model';

@Injectable({
  providedIn: 'root'
})
export class LkBaseService {
  protected baseUrl: string;

  get headers() {
    return { 'Authorization': localStorage.getItem('e2b.lk.client.token') || '', 'X-Skip-Interceptor': '' };
  }

  constructor(protected http: HttpClient, protected router: Router) {
  }

  find<T>(): Observable<[T]> {
    return this.http.get<[T]>('/lk' + this.baseUrl, {
      withCredentials: true,
      headers: this.headers
    });
  }

  findById<T>(id: number): Observable<T> {
    return this.http.get<T>('/lk' + this.baseUrl + '/' + id, {
      withCredentials: true,
      headers: this.headers
    });
  }

  create<T>(model: BaseModel): Observable<T> {
    return this.http.post<T>('/lk' + this.baseUrl, model, {
      withCredentials: true,
      headers: this.headers
    });
  }

  update<T>(model: BaseModel): Observable<T> {
    return this.http.put<T>('/lk' + this.baseUrl + '/' + model.id, model, {
      withCredentials: true,
      headers: this.headers
    });
  }
}
