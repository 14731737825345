import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

/**
 * Сервис групп пользователей
 * @class UserGroupService
 */
@Injectable()
export class UserGroupService extends BaseService {
  baseUrl = '/userGroup';
}
