<button class="ui icon button"
        style="height: 40px !important; padding: 1px 10px !important; background: none !important;"
        suiPopup
        popupTrigger="outsideClick"
        [popupTemplate]="popupTemplate"
        [popupPlacement]="'top right'"
        [popupBasic]="true">
  <i class="large icons">
    <i [ngClass]="activity.iconClass + ' ' + (notificationsCounter ? activity.iconHighlightColor : 'dimmed') + ' icon'"
       style="margin-right: 0 !important;"></i>
    <i *ngIf="activity.name === 'Рекламации'"
       [ngClass]="'top left corner ' + (notificationsCounter ? activity.iconHighlightColor : 'dimmed') + ' exclamation icon'"></i>
    <div *ngIf="notificationsCounter > 0" [ngClass]="'floating ui ' + activity.labelColor + ' circular mini label'"
         style="font-style: normal !important; left: 115%; top: -6px !important;">{{notificationsCounter}}</div>
  </i>
</button>

<ng-template let-popup #popupTemplate>
  <div class="popupHeader">
    <div class="header d-flex justify-content-between align-items-center">
      <div>
        <i class="ui sync white icon cursor" [ngClass]="{'loading': !loaded}" (click)="loadNotifications()"></i>
        &nbsp;Уведомления. {{activity.name}}.
      </div>
      <div class="d-flex flex-dir-column align-items-flex-end">
        <div>
          <span class="cursor" (click)="popup.close(); showMore(activity)">
            <i class="external alternate white icon"></i> Подробнее
          </span>
        </div>
        <div *ngIf="notifications.length > 0">
          <span *ngxPermissionsOnly="['E2B.ROLE.SUPERADMIN']" style="font-size: 11px !important;" class="cursor"
                (click)="readAll()">Прочитать все</span>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="ui animated comments" *ngIf="notifications.length > 0">
      <div *ngFor="let notification of notifications | orderByColumn:'-created:datetime'; trackBy:identify">
        <div class="comment popupContent"
             [class.whatsapp-bg]="notification.type === NotificationTypeEnum.WHATSAPP || notification.type === NotificationTypeEnum.PERSONAL_WHATSAPP">
          <a class="circular avatar">
            <img class="ui circular mini image" *ngIf="notification.user"
                 [src]="(notification.user && notification.user.avatar ? notification.user.avatar.path + '/' + notification.user.avatar.name : null) | secure">
            <img class="ui circular mini image" *ngIf="notification.client"
                 [src]="'3C82BF62-82ED-4AEA-A34C-78FD076F23FD/default.png' | secure">
          </a>
          <div class="content">
            <div [ngClass]="'floating ui ' + activity.labelColor + ' circular small label'"
                 style="left: initial !important; top: 20px !important; right: 20px !important;">{{notification.count}}</div>
            <a
              class="author">{{notification.user ? notification.user.name : (notification.client ? notification.client.name : 'Неизвестный пользователь')}}</a>
            <i
              *ngIf="notification.type === NotificationTypeEnum.WHATSAPP || notification.type === NotificationTypeEnum.PERSONAL_WHATSAPP"
              class="whatsapp green large icon" style="margin: -2px 0 0 5px;"></i>
            <div class="date" *ngIf="notification.created">
              {{notification.created | date:'medium'}}
            </div>
            <div *ngIf="notification.deal" class="mt-2">
              <div *ngIf="notification.deal.filial" style="position: relative">
                <a
                  class="ui ribbon tiny label {{notification.deal.filial.color.replace('#', 'X')}}">{{notification.deal.filial.name}}</a>
                <div [class]="'ui tiny label ' + notification.deal.currentStatus.color.replace('#','B')"
                     [innerHtml]="notification.deal.currentStatus.name"></div>
              </div>
              <a [routerLink]="['/deal/card/', notification.deal.id]" *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']"
                 [innerText]="notification.deal.name.substr(9)"></a>
              <a [routerLink]="['/deal/production/', notification.deal.id]"
                 *ngxPermissionsOnly="['E2B.ROLE.PRODUCTION']" [innerText]="notification.deal.name.substr(9)"></a>
              <span>,&nbsp;</span>
              <span [innerText]="notification.deal.client.name"></span>
            </div>
            <div *ngIf="notification.task">
              <div>
                <a [routerLink]="['/brs/task/show/', notification.task.id]">Заявка №{{notification.task.id}}</a>
                <span>&nbsp;c {{notification.task.contragent.name}}.</span>
              </div>
              <div><span class="fw-bold">Статья</span>: {{notification.task.direction.name}}.</div>
              <div><span class="fw-bold">Сумма</span>: {{notification.task.amount}}.</div>
            </div>
            <div *ngIf="notification.project">
              <a [routerLink]="['/project/show/', notification.project.id]" [innerText]="notification.project.name"></a>
              <div>
                <div class="ui tiny label"
                     style="margin-top: 5px; color: white;"
                     [ngStyle]="{'background-color': '#' + notification.project.status.color}"
                     [innerHtml]="notification.project.status.name"></div>
              </div>
            </div>
            <div class="text" [innerHTML]="notification.text"></div>
            <div class="actions">
              <ng-container *ngIf="notification.deal">
                <a class="reply" (click)="read(notification)" *ngxPermissionsExcept="['E2B.ROLE.PRODUCTION']"
                   [routerLink]="['/deal/card/', notification.deal.id]">
                  <i class="share icon"></i>
                  Перейти
                </a>
                <a class="reply" (click)="read(notification)" *ngxPermissionsOnly="['E2B.ROLE.PRODUCTION']"
                   [routerLink]="['/deal/production/', notification.deal.id]">
                  <i class="share icon"></i>
                  Перейти
                </a>
              </ng-container>

              <a class="reply" *ngIf="notification.task" (click)="read(notification)"
                 [routerLink]="['/brs/task/show/', notification.task.id]">
                <i class="share icon"></i>
                Перейти
              </a>
              <a class="reply" *ngIf="notification.project" (click)="read(notification)"
                 [routerLink]="['/project/show/', notification.project.id]">
                <i class="share icon"></i>
                Перейти
              </a>
              <a class="save" (click)="read(notification)">
                <i class="eye icon"></i>
                Прочитать
              </a>
              <a class="save" (click)="showReply(notification)"
                 *ngIf="notification.type === NotificationTypeEnum.DEAL_COMMENT || notification.type === NotificationTypeEnum.PROJECT_COMMENT || notification.type === NotificationTypeEnum.PERSONAL">
                <i class="paper plane icon"></i>
                Ответить
              </a>
            </div>
            <div style="margin-top: 15px;" *ngIf="replyToNotification && replyToNotification.id === notification.id">
              <e2b-comment-reply-textarea [saveEvent]="replySave"
                                          [notificationToReply]="replyToNotification"></e2b-comment-reply-textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding:14px 16px 14px 16px;" *ngIf="notifications.length === 0">Ничего нового...</div>
  </div>
</ng-template>
