import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommentType } from '../../_models/enums/comment-type.enum';
import { User } from '../../_models/user.model';
import { DealService } from '../../_services/deal.service';
import { ProjectService } from '../../_services/project.service';
import { TaskService } from '../../_services/task.service';

@Component({
  selector: 'e2b-comment-textarea',
  templateUrl: './comment.textarea.component.html',
  styleUrls: [
    './comment.textarea.component.css',
    '../../../../node_modules/quill/dist/quill.bubble.css',
    '../../../../node_modules/quill/dist/quill.snow.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class CommentTextareaComponent implements OnInit {

  model = '';
  @Input()
  entityId: number;
  @Input()
  commentType: CommentType;
  @Input()
  toWhatsApp = false;
  @Input()
  textEmitEvent: EventEmitter<string>;
  @Input()
  clearEvent: EventEmitter<any>;
  @Input()
  userEmitEvent: EventEmitter<any>;
  @Input()
  userAddEvent: EventEmitter<any>;
  @Input()
  textInput: EventEmitter<any>;
  @Input()
  pasteEvent: EventEmitter<any>;
  users: User[] = [];
  userChangeEvent: EventEmitter<User> = new EventEmitter<User>();
  userTrigger = false;
  selectedUsers: User[] = [];

  entityServiceName: string;

  constructor(private dealService: DealService,
              private projectService: ProjectService,
              private taskService: TaskService) {
  }

  ngOnInit() {
    this.entityServiceName = this.commentType + 'Service';
    this.selectedUsers = [];
    if (this.entityId && this.commentType !== CommentType.lkOrder) {
      this[this.entityServiceName].users(this.entityId).subscribe(users => {
        this.users = users;
      });
    }

    this.clearEvent.subscribe(() => {
      this.model = '';
      this.selectedUsers = [];
    });

    this.textInput.subscribe(text => {
      this.model = text;
    });

    this.userAddEvent.subscribe(user => {
      this.selectedUsers.push(user);
      this.users = this.users.filter(u => this.selectedUsers.indexOf(u) === -1);
      if (this.userEmitEvent) {
        this.userEmitEvent.emit(this.selectedUsers);
      }
    });

    this.userChangeEvent.subscribe(user => {
      this.model = this.model.replace(/@/g, '');
      this.userTrigger = false;
      this.selectedUsers.push(user);
      this.users = this.users.filter(u => this.selectedUsers.indexOf(u) === -1);
      if (this.userEmitEvent) {
        this.userEmitEvent.emit(this.selectedUsers);
      }
      if (this.textEmitEvent) {
        this.textEmitEvent.emit(this.model);
      }
    });
  }

  get CommentType() {
    return CommentType;
  }

  textChange($event) {
    if (this.commentType !== CommentType.lkOrder && !this.toWhatsApp) {
      const ops = $event.delta.ops;
      if (ops[0] && ops[0].insert === '@' || ops[1] && ops[1].insert === '@') {
        this.userTrigger = true;
        setTimeout(() => {
          $('e2b-staff-dropdown input').trigger('focus');
        }, 0);
      } else {
        this.userTrigger = false;
      }
    }

    if (this.textEmitEvent) {
      this.textEmitEvent.emit($event);
    }
  }

  onPaste(event: ClipboardEvent) {
    const files = this.getPastedImage(event);
    if (files) {
      event.preventDefault();
      this.pasteEvent.emit(files);
    }
  }

  getPastedImage(event: ClipboardEvent): FileList | null {
    return event.clipboardData &&
    event.clipboardData.files &&
    event.clipboardData.files.length === 1 &&
    this.isImageFile(event.clipboardData.files[0])
      ? event.clipboardData.files
      : null;
  }

  isImageFile(file: File): boolean {
    return (file.type.search(/^image\//i) === 0);
  }
}
