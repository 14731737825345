import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

@Injectable()
export class ClientService extends BaseService {
  baseUrl = '/client';

  search(query): Promise<any> {
    return this.http.get(this.baseUrl + '/search?q=' + query, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    }).toPromise();
  }
}
