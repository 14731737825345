import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssemblyStepService } from '../../../_services/assembly-step.service';
import { AssemblyStep } from '../../../_models/assembly-step.model';
import { ErrorService } from '../../../error/error.service';
import { DealFieldService } from '../../../_services/deal-field.service';
import { DealField } from '../../../_models/deal-field.model';
import { UserService } from '../../../_services/user.service';
import { OrderType } from '../../../_models/enums/order-type.enum';
import { OrderFieldService } from '../../../_services/order-field.service';

@Component({
  selector: 'e2b-assembly-step-edit',
  templateUrl: './assembly-step-edit.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/header.min.css',
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../../assets/design/semantic-ui/components/label.min.css',
    '../../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class AssemblyStepEditComponent implements OnInit {

  id: number;

  step: AssemblyStep = new AssemblyStep();

  loaded = false;

  fields: DealField[] = [];
  problemFields: DealField[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private userService: UserService,
              private dealFieldService: DealFieldService,
              private orderFieldService: OrderFieldService,
              private assemblyStepService: AssemblyStepService) {
  }

  ngOnInit() {
    this.id = parseInt(this.route.snapshot.params.id, 0);

    if (!this.userService.checkPermissions(['assembly-step', '/'], ['write'])) {
      this.router.navigateByUrl('/catalog/assembly-scheme/list').catch(console.error);
    }

    this.load().catch(console.error);
  }

  trackByFn(index, item) {
    return item.id;
  }

  async load() {
    this.fields = await this.orderFieldService.list<DealField>({ type: [OrderType.MOUNTING, OrderType.RECLAMATION, OrderType.DELIVERY, OrderType.MEASUREMENT] }).toPromise();

    this.problemFields = await this.dealFieldService.list<DealField>({ model: ['CRM.FIELD.PROBLEM'] }).toPromise();

    this.assemblyStepService.findById<AssemblyStep>(this.id).subscribe(step => {
      this.step = step;

      if (step.field && step.field.id) {
        this.fields.map(f => {
          if (f.id === step.field.id) {
            step.field = f;
          }
        });
      }
      const problemFields = [];
      if (step.problemFields && step.problemFields.length > 0) {
        step.problemFields.map(sp => {
          this.problemFields.map(f => {
            if (sp.id === f.id) {
              sp = f;
              problemFields.push(f);
            }
          });
        });
      }

      step.problemFields = problemFields;

      this.loaded = true;
    });
  }

  selectStepField() {
    const event: EventEmitter<DealField> = new EventEmitter<DealField>();
    event.subscribe(field => {
      this.step.field = field;
      this.save();
    });
    return event;
  }

  selectStepProblem() {
    const event: EventEmitter<DealField[]> = new EventEmitter<DealField[]>();
    event.subscribe(fields => {
      this.step.problemFields = fields;
      this.save();
    });
    return event;
  }

  save() {
    if (this.step.resolution > 2440) {
      this.errorService.addError.emit({
        title: 'Ошибка',
        message: 'Разрешение фото не может быть больше 2440 пикселей'
      });
      return false;
    }
    if (this.step.compression > 100) {
      this.errorService.addError.emit({
        title: 'Ошибка',
        message: 'Компрессия фото не может быть больше 100%'
      });
      return false;
    }
    if (this.step.percent > 100) {
      this.errorService.addError.emit({
        title: 'Ошибка',
        message: 'Процент выполнения не может быть больше 100%'
      });
      return false;
    }
    if (!this.step.field) {
      this.errorService.addError.emit({
        title: 'Ошибка',
        message: 'Нужно указать поле'
      });
      return false;
    }

    const update = {
      id: this.step.id,
      name: this.step.name,
      description: this.step.description,
      photoRequired: this.step.photoRequired,
      percent: this.step.percent,
      compression: this.step.compression,
      resolution: this.step.resolution,
      field: { id: this.step.field.id },
      problemFields: this.step.problemFields
    };

    this.assemblyStepService.update(update).subscribe(() => {
    }, e => {
      console.error(e);
    });
  }
}
