import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'e2b-deals-geography',
  templateUrl: './deal-geography.component.html',
  styleUrls: ['./deal-geography.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DealGeographyComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
