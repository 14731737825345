import { BaseModel } from './base.model';
import { Client } from './client.model';
import { Comment } from './comment.model';
import { DealDecline } from './deal-decline.model';
import { DealFieldValue } from './deal-field-value.model';
import { DealFile } from './deal-file.model';
import { DealPosition } from './deal-position.model';
import { DealStatus } from './deal-status.model';
import { Filial } from './filial.model';
import { Person } from './person.model';
import { Responsible } from './responsible.model';
import { StatusHistory } from './status-history.model';
import { Todo } from './todo.model';
import { User } from './user.model';
import { Order } from './order.model';

export class Deal extends BaseModel {
  auditors: User[];
  client: Client;
  comments: Comment[];
  cost: number;
  created: Date;
  currentStatus: DealStatus;
  declines: DealDecline[];
  deleted: boolean;
  description: string;
  files: DealFile[];
  filial: Filial;
  manager: User;
  managerHelper: User;
  megaplanId: string;
  name: string;
  orders: Order[];
  organization: Person;
  person: Person;
  positions: DealPosition[];
  prepay: number;
  price: number;
  responsibles: Responsible[];
  saleDate: Date;
  statusHistory: StatusHistory[];
  subManager: User;
  todos: Todo[];
  userCreated: User;
  values: DealFieldValue[];
}
