import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PositionGroupTemplate } from '../../_models/position-group-template.model';
import { PositionGroup } from '../../_models/position-group.model';
import { PositionGroupTemplateService } from '../../_services/position-group-template.service';
import { PositionGroupService } from '../../_services/position-group.service';

@Component({
  selector: 'e2b-position-group-add',
  templateUrl: './position-group-add.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css',
    './position-group-add.component.less'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PositionGroupAddComponent implements OnInit {
  group: PositionGroup = new PositionGroup();
  templates: PositionGroupTemplate[] = [];
  error: any = null;

  constructor(private positionGroupService: PositionGroupService,
              private positionGroupTemplateService: PositionGroupTemplateService,
              private router: Router) {
  }

  ngOnInit() {
    this.positionGroupTemplateService.find<PositionGroupTemplate>().subscribe(templates => {
      this.templates = templates;
    }, console.error);
  }

  navigate(url: string) {
    this.router.navigateByUrl(url).catch(console.error);
  }

  save() {
    this.positionGroupService.create(this.group).subscribe(() => {
      this.navigate('/catalog/group/list');
    }, console.error);
  }

  cancel() {
    this.navigate('/catalog/group/list');
  }
}
