import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'orderByColumn'
})

@Injectable()
export class OrderByColumnPipe implements PipeTransform {

  transform<T>(array: T[], args: string): T[] {
    if (typeof args[0] === 'undefined' || typeof array === 'undefined') {
      return [];
    }
    const direction = args[0][0];
    const column = args.split(':')[0].replace('-', '');
    const type = args.split(':')[1];
    const subtype = args.split(':')[2];
    const subtype2 = args.split(':')[3];
    array.sort((a: any, b: any) => {
      if (type === 'date') {
        if (subtype !== undefined) {
          const left = Number(moment(a[column], subtype).toDate());
          const right = Number(moment(b[column], subtype).toDate());
          return (direction === '-') ? right - left : left - right;
        } else {
          const left = Number(new Date(a[column]));
          const right = Number(new Date(b[column]));
          return (direction === '-') ? right - left : left - right;
        }
      } else if (type === 'number') {
        const left = a[column];
        const right = b[column];
        return (direction === '-') ? right - left : left - right;
      } else if (type === 'object') {
        let left: any;
        let right: any;
        if (a[column.split('.')[0]] &&
          !a[column.split('.')[0]][column.split('.')[1]] &&
          b[column.split('.')[0]] &&
          !b[column.split('.')[0]][column.split('.')[1]]) {
          return 0;
        }
        if (a[column.split('.')[0]] &&
          !a[column.split('.')[0]][column.split('.')[1]] &&
          b[column.split('.')[0]] &&
          b[column.split('.')[0]][column.split('.')[1]]) {
          return (direction === '-') ? -1 : 1;
        }
        if (a[column.split('.')[0]] &&
          a[column.split('.')[0]][column.split('.')[1]] &&
          b[column.split('.')[0]] &&
          !b[column.split('.')[0]][column.split('.')[1]]) {
          return (direction === '-') ? 1 : -1;
        }
        if (a[column.split('.')[0]] && a[column.split('.')[0]][column.split('.')[1]] &&
          b[column.split('.')[0]] && b[column.split('.')[0]][column.split('.')[1]]) {
          if (subtype === 'momentDate') {
            left = Number(moment(a[column.split('.')[0]][column.split('.')[1]], 'DD.MM.YYYY').toDate());
            right = Number(moment(b[column.split('.')[0]][column.split('.')[1]], 'DD.MM.YYYY').toDate());
            return (direction === '-') ? right - left : left - right;
          } else if (subtype === 'datetime') {
            left = Number(new Date(a[column.split('.')[0]][column.split('.')[1]]));
            right = Number(new Date(b[column.split('.')[0]][column.split('.')[1]]));
            return (direction === '-') ? right - left : left - right;
          } else if (subtype === 'momentDatetime') {
            left = Number(moment(a[column.split('.')[0]][column.split('.')[1]], 'DD.MM.YYYY HH:mm:ss').toDate());
            right = Number(moment(b[column.split('.')[0]][column.split('.')[1]], 'DD.MM.YYYY HH:mm:ss').toDate());
          } else if (subtype === 'number') {
            left = parseInt(a[column.split('.')[0]][column.split('.')[1]].toString().replace(/^\D+/g, ''), 0);
            right = parseInt(b[column.split('.')[0]][column.split('.')[1]].toString().replace(/^\D+/g, ''), 0);
            return (direction === '-') ? right - left : left - right;
          } else if (subtype === 'boolean') {
            left = a[column.split('.')[0]][column.split('.')[1]];
            right = b[column.split('.')[0]][column.split('.')[1]];
            return (direction === '-') ? ((left === right) ? 0 : left ? -1 : 1) : ((left === right) ? 0 : left ? 1 : -1);
          } else if (subtype === 'object') {
            if (a[column.split('.')[0]] &&
              a[column.split('.')[0]][column.split('.')[1]] &&
              !a[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]] &&
              b[column.split('.')[0]] &&
              b[column.split('.')[0]][column.split('.')[1]] &&
              !b[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]]) {
              return 0;
            }
            if (a[column.split('.')[0]] &&
              a[column.split('.')[0]][column.split('.')[1]] &&
              !a[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]] &&
              b[column.split('.')[0]] &&
              b[column.split('.')[0]][column.split('.')[1]] &&
              b[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]]) {
              return 1;
            }
            if (a[column.split('.')[0]] &&
              a[column.split('.')[0]][column.split('.')[1]] &&
              a[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]] &&
              b[column.split('.')[0]] &&
              b[column.split('.')[0]][column.split('.')[1]] &&
              !b[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]]) {
              return -1;
            }
            if (subtype2 === 'momentDate') {
              left = Number(moment(a[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]], 'DD.MM.YYYY').toDate());
              right = Number(moment(b[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]], 'DD.MM.YYYY').toDate());
              return (direction === '-') ? right - left : left - right;
            } else if (subtype2 === 'datetime') {
              left = Number(new Date(a[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]]));
              right = Number(new Date(b[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]]));
              return (direction === '-') ? right - left : left - right;
            } else if (subtype2 === 'momentDatetime') {
              left = Number(moment(a[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]], 'DD.MM.YYYY HH:mm:ss').toDate());
              right = Number(moment(b[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]], 'DD.MM.YYYY HH:mm:ss').toDate());
            } else if (subtype2 === 'number') {
              left = parseInt(a[column.split('.')[0]][column.split('.')[1][column.split('.')[2]]].toString().replace(/^\D+/g, ''), 0);
              right = parseInt(b[column.split('.')[0]][column.split('.')[1][column.split('.')[2]]].toString().replace(/^\D+/g, ''), 0);
              return (direction === '-') ? right - left : left - right;
            } else {
              left = a[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]];
              right = b[column.split('.')[0]][column.split('.')[1]][column.split('.')[2]];
              return (direction === '-') ? right - left : left - right;
            }
          } else {
            left = a[column.split('.')[0]][column.split('.')[1]].toLowerCase();
            right = b[column.split('.')[0]][column.split('.')[1]].toLowerCase();
            if (left < right) {
              return direction === '-' ? 1 : -1;
            } else if (left > right) {
              return direction === '-' ? -1 : 1;
            } else {
              return 0;
            }
          }
        } else {
          return direction === '-' ? 1 : -1;
        }
        if (left < right) {
          return direction === '-' ? 1 : -1;
        } else if (left > right) {
          return direction === '-' ? -1 : 1;
        } else {
          return 0;
        }
      } else {
        const left = a[column].toLowerCase();
        const right = b[column].toLowerCase();
        if (left < right) {
          return direction === '-' ? 1 : -1;
        } else if (left > right) {
          return direction === '-' ? -1 : 1;
        } else {
          return 0;
        }
      }
    });
    return array;
  }


}
