<h3 class="header">Создание заказа</h3>
<button class="ui blue button" (click)="setOrderListMode()">
  <i class="arrow left icon"></i> К списку заказов
</button>

<div class="ui stackable grid mt-1">
  <div class="eight wide column">
    <div class="ui form">
      <div class="inline fields">
        <div class="five wide required field">
          <label class="fw-bold">Тип заказа</label>
        </div>
        <div class="eleven wide field">
          <ng-template let-orderType #orderTypeOptionTemplate>
            {{orderType | orderTypeName}}
          </ng-template>
          <sui-select class="fluid selection w-100"
                      placeholder="Тип заказа"
                      [(ngModel)]="order.type"
                      (ngModelChange)="onOrderTypeChange()"
                      [options]="orderTypes"
                      [optionTemplate]="orderTypeOptionTemplate"
                      #selectOrderType>
            <sui-select-option *ngFor="let orderType of selectOrderType.filteredOptions" [value]="orderType">
            </sui-select-option>
          </sui-select>
        </div>
      </div>
      <div class="inline fields">
        <div [ngClass]="'five wide field' + (order.type !== OrderType.RECLAMATION ? ' required' : '')">
          <label class="fw-bold">Дата исполнения</label>
        </div>
        <div class="eleven wide field">
          <div [ngClass]="order.type ? 'ui left icon input' : 'ui left icon disabled input'">
            <i class="calendar icon"></i>
            <input [(ngModel)]="order.date" (ngModelChange)="selectDate($event)" [pickerMinDate]="currentDate"
                   autocomplete="off" name="date" [pickerMode]="OrderType.PRODUCTION ? 'date' : 'datetime'" suiDatepicker>
          </div>
        </div>
        <div class="coefficientOrderPopup" (click)="calculatedCoefficient.id = false" *ngIf="calculatedCoefficient.id">
          <table class="ui very basic table">
            <tr>
              <td class="fw-bold">Производство</td>
              <td class="fw-bold">{{calculatedCoefficient.data.use}}</td>
              <td>{{calculatedCoefficient.data.all}}</td>
            </tr>
            <tr>
              <td class="fw-bold">Монтаж</td>
              <td class="fw-bold">{{calculatedCoefficient.data.mountUse}}</td>
              <td>{{calculatedCoefficient.data.mountAll}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="inline fields">
        <div class="five wide required field"
             [ngClass]="order.type === OrderType.PRODUCTION ? 'flex-dir-column align-items-flex-start' : ''">
          <label class="fw-bold">Ответственный</label>
          <div class="mini text greytext" *ngIf="order.type === OrderType.PRODUCTION">
            Заполняется автоматически при выборе позиций
          </div>
        </div>
        <div class="eleven wide field">
          <ng-template let-user #responsibleOptionTemplate>
            <div>{{user.name}}</div>
          </ng-template>
          <sui-select class="fluid selection w-100"
                      placeholder="Ответственный"
                      [(ngModel)]="order.responsible"
                      [options]="responsible"
                      [isDisabled]="!order.date && order.type !== OrderType.RECLAMATION"
                      [optionTemplate]="responsibleOptionTemplate"
                      #selectResponsible>
            <sui-select-option *ngFor="let user of selectResponsible.filteredOptions | orderByColumn: 'name:string'"
                               [value]="user">
            </sui-select-option>
          </sui-select>
        </div>
      </div>
      <div class="inline fields" *ngIf="order.type === OrderType.DELIVERY || order.type === OrderType.RECLAMATION">
        <div [ngClass]="'five wide field' + (order.type === OrderType.DELIVERY ? ' required' : '')">
          <label class="fw-bold">Себестоимость</label>
        </div>
        <div class="eleven wide field">
          <input [(ngModel)]="order.cost" type="number" autocomplete="off" name="cost">
        </div>
      </div>
    </div>

    <h4 class="header">Позиции</h4>
    <table class="ui stackable table">
      <thead>
      <tr>
        <th style="max-width: 20px"></th>
        <th class="fw-bold">Позиция</th>
        <th class="fw-bold">Кол-во</th>
        <th class="fw-bold">Цена</th>
        <th class="fw-bold">Сумма</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let position of deal.positions | orderPositionFilter:order.type:positionsType"
          [class.disabled]="position.disabled || !responsible.length">
        <td style="max-width: 20px">
          <div class="ui checkbox" *ngIf="!position.disabled">
            <input [checked]="order.positions.includes(position)" (click)="selectPosition(position)" type="checkbox">
            <label></label>
          </div>
        </td>
        <td>
          <div>
            <a *ngIf="!position.disabled && order.positions.includes(position); else positionName"
               (click)="setCurrentPosition(position)"
               class="cursor">
              {{position.position.name}}
            </a>
            <ng-template #positionName>{{position.position.name}}</ng-template>
          </div>
          <span *ngIf="position.errorMessage" class="mini text">
            <i class="exclamation circle icon"></i>{{position.errorMessage}}
          </span>
        </td>
        <td>{{position.count}}</td>
        <td>{{position.price}}</td>
        <td>{{position.price * position.count}}</td>
      </tr>
      </tbody>
    </table>
    <div class="mt-3">
      <button class="ui green button" [disabled]="!isOrderValid" (click)="createOrder()">Создать заказ</button>
    </div>
  </div>
  <div class="eight wide column">
    <div *ngIf="currentPosition">
      <h3 class="header">{{currentPosition.position.name}}
        <br>
        <span class="greytext">{{currentPosition.position.type | positionType | uppercase}}</span>
      </h3>
      <div class="ui divider"></div>
      <div class="mt-2">
        <e2b-order-position-field
          [orderDealPositionValues]="currentPosition.orderDealPositionValues"></e2b-order-position-field>
        <h4 *ngIf="!currentPosition.orderDealPositionValues.length" class="header">
          Нет полей для предварительного заполнения по данной позиции
        </h4>
      </div>
    </div>
  </div>
</div>



