import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalTemplate, SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { Subscription } from 'rxjs';
import { Deal } from '../../_models/deal.model';
import { TodoStatus } from '../../_models/todo-status.model';
import { TodoType } from '../../_models/todo-type.model';
import { Todo } from '../../_models/todo.model';
import { User } from '../../_models/user.model';
import { DealService } from '../../_services/deal.service';
import { TodoStatusService } from '../../_services/todo-status.service';
import { TodoTypeService } from '../../_services/todo-type.service';
import { TodoService } from '../../_services/todo.service';
import { UserService } from '../../_services/user.service';

enum DealStatusSort {
  managerControl = 7,
  delayed = 19,
  waiting = 22,
  sos = 24,
}

@Component({
  selector: 'e2b-todo-view',
  templateUrl: './todo.view.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/segment.min.css',
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/menu.min.css',
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/message.min.css',
    '../../../assets/design/semantic-ui/components/card.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css',
    '../../../assets/design/semantic-ui/components/tab.min.css',
    '../../../assets/design/semantic-ui/components/comment.min.css',
    '../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../assets/design/semantic-ui/components/label.min.css',
    '../../../assets/design/semantic-ui/components/button.min.css',
    '../../../assets/design/semantic-ui/components/modal.min.css',
    '../../../assets/design/semantic-ui/components/transition.min.css',
    '../../../assets/design/semantic-ui/components/dimmer.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    './todo.view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TodoViewComponent implements OnInit, OnDestroy {

  added: Todo[] = [];
  currentTodo: Todo;
  @ViewChild('date', { static: false })
  date: ElementRef;
  day: Moment = moment();
  deal: Deal;
  dealId: number;
  dealSubscribe: Subscription;
  deals: Deal[] = [];
  editTodo: Todo = null;
  @ViewChild('selectEditType', { static: false })
  editTypeSelector;
  error = false;
  filter: string[];
  id: number = this.userService.current().id;
  isTodoLoading = false;
  @ViewChild('modalTemplate', { static: false })
  public modalTemplate: ModalTemplate<{ todo: Todo, status: number }, string, string>;
  newTodo: Todo = new Todo();
  overdueTodos: Todo[] = [];
  selectDeal: EventEmitter<Deal> = new EventEmitter<Deal>();
  selectEditUser: EventEmitter<User> = new EventEmitter<User>();
  selectResponsible: EventEmitter<User> = new EventEmitter<User>();
  selectTodoType: EventEmitter<TodoType> = new EventEmitter<TodoType>();
  selectUser: EventEmitter<User> = new EventEmitter<User>();
  @Input()
  showDeal = false;
  showNew = false;
  showOverdueTodos = true;
  statuses: TodoStatus[] = [];
  todoStatusSubscribe: Subscription;
  todoTypeSubscribe: Subscription;
  @Input()
  todos: Todo[] = [];
  types: TodoType[] = [];
  user: User;
  users: User[] = [];

  nextTodo: Todo = new Todo();
  selectNextTodoResponsible = new EventEmitter<User>();
  nextTodoRequired = false;

  constructor(private todoStatusService: TodoStatusService,
              private todoTypeService: TodoTypeService,
              private todoService: TodoService,
              private userService: UserService,
              private dealService: DealService,
              public modalService: SuiModalService) {

    this.newTodo = new Todo();
    this.newTodo.status = new TodoStatus();
    this.newTodo.status.id = 1;
    this.newTodo.deal = new Deal();
    this.newTodo.type = new TodoType();
  }

  @Input()
  set setDealId(id: number) {
    this.dealId = id;
    this.showOverdueTodos = false;
    if (this.dealId) {
      this.dealService.findById<Deal>(this.dealId).subscribe(deal => {
        this.deal = deal;
        this.nextTodoRequired = this.deal.currentStatus.sort < DealStatusSort.managerControl ||
          [DealStatusSort.delayed, DealStatusSort.waiting, DealStatusSort.sos].includes(this.deal.currentStatus.sort);
      });
    }
  }

  get nextTodoValid(): boolean {
    return Boolean(this.nextTodo.type && this.nextTodo.responsible && this.nextTodo.from && this.nextTodo.to && this.nextTodo.name || !this.nextTodoRequired);
  }

  add() {
    this.todoStatusSubscribe = this.todoStatusService.find<TodoStatus>().subscribe(statuses => this.statuses = statuses);
    this.todoTypeSubscribe = this.todoTypeService.find<TodoType>().subscribe(types => this.types = types);
    if (!this.dealId) {
      this.dealSubscribe = this.dealService.find<Deal>().subscribe(deals => this.deals = deals);
    } else if (this.dealId) {
      this.dealService.findById<Deal>(this.dealId).subscribe(deal => this.deal = deal);
    }
    this.showNew = true;
  }

  assign(todo: Todo) {
    this.todoService.assign(todo).subscribe((updated: Todo) => {
      this.todos.map((t, k) => {
        if (t.id === todo.id) {
          this.todos[k] = updated;
        }
      });
    }, console.error);
  }

  checkboxState(e, n: Todo) {
    if (!n.checked) {
      return false;
    }
    this.todoTypeSubscribe = this.todoTypeService.find<TodoType>().subscribe(types => this.types = types);
    const config = new TemplateModalConfig<{ todo: Todo, status: number }, string, string>(this.modalTemplate);

    config.context = { todo: n, status: n.status.id };

    this.modalService
      .open(config)
      .onApprove((result: any) => {
        this.todos.map((t, k) => {
          if (t.id === n.id) {
            if (result.status === t.status.id) {
              setTimeout(() => {
                this.todos[k].checked = false;
              }, 100);
              return false;
            } else {
              result.todo.status.id = result.status;
              if (this.nextTodoRequired) {
                this.nextTodo.deal = <Deal>{ id: this.dealId };
                this.todoService.create<Todo>(this.nextTodo).subscribe(createdTodo => {
                  this.todos.push(createdTodo);
                  this.todoService.finish<Todo>(result.todo).subscribe(updated => {
                    setTimeout(() => {
                      this.todos[k] = updated;
                      this.todos = [...this.todos];
                      this.resetNextTodo();
                    }, 10);
                  });
                });
              } else {
                this.todoService.finish<Todo>(result.todo).subscribe(updated => {
                  setTimeout(() => {
                    this.todos[k] = updated;
                    this.todos = [...this.todos];
                  }, 10);
                });
              }

            }
          }
        });
      }).onDeny(result => {
      this.resetNextTodo();
      this.todos.map((t, k) => {
        if (t.id === n.id) {
          setTimeout(() => {
            this.todos[k].checked = false;
          }, 100);
        }
      });
    });
  }

  createTodo() {
    if ((!this.dealId && !this.newTodo.deal.id) || !this.newTodo.name || !this.newTodo.type) {
      this.error = true;
      return false;
    }
    if (this.dealId) {
      this.newTodo.deal.id = this.dealId;
    }
    if (this.newTodo.to) {
      this.newTodo.to.setDate(this.newTodo.from.getDate());
      this.newTodo.to.setMonth(this.newTodo.from.getMonth());
      this.newTodo.to.setFullYear(this.newTodo.from.getFullYear());
    }

    this.todoService.create<Todo>(this.newTodo).subscribe(r => {
      setTimeout(() => {
        this.todos.push(r);
      }, 100);
      this.newTodo = new Todo();
      this.newTodo.status = new TodoStatus();
      this.newTodo.status.id = 1;
      this.newTodo.deal = new Deal();
      this.newTodo.type = new TodoType();
      this.showNew = false;
    });
  }

  edit(todo) {
    todo.from = moment(todo.from).toDate();
    todo.to = moment(todo.to).toDate();
    this.users.map(user => {
      if (user.id === todo.responsible.id) {
        todo.responsible = user;
      }
    });
    this.editTodo = todo;
    setTimeout(() => {
      this.types.map(type => {
        if (type.id === todo.type.id) {
          todo.type = type;
          this.editTypeSelector.selectedOption = type;
        }
      });
    }, 0);
  }

  getTodos() {
    this.isTodoLoading = true;
    this.todoService.findByUserId<Todo>(this.id, this.day.format('YYYY-MM-DD')).subscribe(todos => {
      this.todos = todos;
      this.isTodoLoading = false;
    });
  }

  nextDay() {
    this.day = this.day.add(1, 'd');
    this.getTodos();
  }

  ngOnDestroy() {
    if (this.todoStatusSubscribe) {
      this.todoStatusSubscribe.unsubscribe();
    }
    if (this.todoTypeSubscribe) {
      this.todoTypeSubscribe.unsubscribe();
    }
    if (this.dealSubscribe) {
      this.dealSubscribe.unsubscribe();
    }
    if (this.selectResponsible) {
      this.selectResponsible.unsubscribe();
    }
    if (this.selectUser) {
      this.selectUser.unsubscribe();
    }
  }

  ngOnInit() {
    this.selectDeal.subscribe(deal => {
      this.newTodo.deal.id = deal.id;
    });

    this.selectTodoType.subscribe(todoType => {
      this.newTodo.type.id = todoType.id;
    });

    this.selectUser.subscribe(user => {
      this.newTodo.responsible = user;
    });

    this.selectEditUser.subscribe(user => {
      this.editTodo.responsible = user;
    });

    this.selectNextTodoResponsible.subscribe(user => {
      this.nextTodo.responsible = user;
    });

    this.showActive();
    this.overdue();

    this.selectResponsible.subscribe(user => {
      this.id = user.id;
      this.getTodos();
      this.overdue();
    });

    this.user = this.userService.current();
    this.userService.find<User>().subscribe(users => {
      this.users = users;
      this.users.map(employee => {
        if (this.user.id === employee.id) {
          this.newTodo.responsible = employee;
          this.nextTodo.responsible = employee;
        }
      });
    });
  }

  onDate() {
    this.date.nativeElement.click();
  }

  onSelectDate($event) {
    this.day = moment($event);
    this.getTodos();
  }

  overdue() {
    this.isTodoLoading = true;
    this.todoService.overdue(this.id).subscribe((todos: Todo[]) => {
      this.overdueTodos = todos;
      this.isTodoLoading = false;
    }, console.error);
  }

  prevDay() {
    this.day = this.day.subtract(1, 'd');
    this.getTodos();
  }

  remove(todo: Todo) {
    const yes = confirm('Вы уверены, что хотите удалить дело ' + todo.name + '?');
    if (!yes) {
      return true;
    }
    todo.deleted = true;
    this.todoService.update(todo).subscribe(() => {
      this.todos.splice(this.todos.indexOf(todo), 1);
      this.overdue();
    });
  }

  saveTodo() {
    if (this.editTodo.to) {
      this.editTodo.to.setDate(this.editTodo.from.getDate());
      this.editTodo.to.setMonth(this.editTodo.from.getMonth());
      this.editTodo.to.setFullYear(this.editTodo.from.getFullYear());
    }
    this.todoService.update<Todo>(this.editTodo).subscribe(() => {
      this.todos.map((todo, k) => {
        if (todo.id === this.editTodo.id) {
          this.editTodo.from = moment(this.editTodo.from).toDate();
          this.editTodo.to = moment(this.editTodo.to).toDate();
          this.todos[k] = this.editTodo;
        }
      });
      this.editTodo = null;
    });
  }

  showActive() {
    this.filter = ['E2B.TODO.STATUS.SCHEDULED', 'E2B.TODO.STATUS.WORKING'];
  }

  showFinished() {
    this.filter = ['E2B.TODO.STATUS.SUCCESS', 'E2B.TODO.STATUS.FAIL'];
  }

  private resetNextTodo() {
    this.nextTodo.name = '';
    this.nextTodo.from = null;
    this.nextTodo.to = null;
    this.nextTodo.type = null;
  }
}
