import { Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { ClientType } from '../../_models/client-type.model';

@Component({
  selector: 'e2b-client-type-dropdown',
  templateUrl: './client-type.dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class ClientTypeDropdownComponent {
  @Input()
  public clientTypes: ClientType[];
  @Input()
  public event: EventEmitter<ClientType>;

  public selectedClientType: ClientType;

  save() {
    this.event.emit(this.selectedClientType);
  }
}
