<script src="person-show.component.ts"></script>
<div class="ui stackable grid">
  <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
    <div class="ui text loader">Загрузка...</div>
  </sui-dimmer>
  <div class="row pb-2">
    <div class="fourteen wide column">
      <h2 class="header">{{person.name}}</h2>
      <h3 class="header mt-0"><a [routerLink]="['/client/card/', person.client.id]">{{person.client.name}}</a></h3>
    </div>
  </div>
  <div class="row">
    <div class="four wide column">
      <div *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
        <h3 class="ui dividing header fw-bold">Организация</h3>
        <h4 class="header mt-0">Ген. директор: {{person.manager}}</h4>
      </div>
      <div *ngIf="['E2B.PERSON.TYPE.HUMAN','E2B.PERSON.TYPE.IP'].indexOf(person.type.systemType) > -1">
        <h3 class="ui dividing header fw-bold">Физическое лицо / ИП</h3>
        <h4 class="header">ФИО: {{person.name}}</h4>
      </div>
    </div>
    <div class="four wide column">
      <div *ngIf="['E2B.PERSON.TYPE.COMPANY','E2B.PERSON.TYPE.IP'].indexOf(person.type.systemType) > -1">
        <h3 class="ui dividing header fw-bold">Реквизиты</h3>
        <h4 class="header mt-0">Юр. адрес: {{person.address}}</h4>
        <h4 class="header mt-0">ИНН: {{person.inn}}</h4>
        <h4 class="header mt-0" *ngIf="['E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1">КПП: {{person.kpp}}</h4>
        <h4 class="header mt-0">OKPO: {{person.okpo}}</h4>
        <h4 class="header mt-0" *ngIf="['E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1">ОГРН: {{person.ogrn}}</h4>
        <h4 class="header mt-0" *ngIf="['E2B.PERSON.TYPE.IP'].indexOf(person.type.systemType) > -1">ГРНИП: {{person.grnip}}</h4>
        <h4 class="header mt-0" *ngIf="['E2B.PERSON.TYPE.IP'].indexOf(person.type.systemType) > -1">Дата выдачи ГРНИП: {{person.grnipDate | date:'short'}}</h4>
        <h4 class="header mt-0">БАНК: {{person.bank ? person.bank.name : '-'}}, {{person.bank ? person.bank.address : '-'}}</h4>
        <h4 class="header mt-0">БИК: {{person.bank ? person.bank.bik : '-'}}</h4>
        <h4 class="header mt-0">Р/С: {{person.rs}}</h4>
        <h4 class="header mt-0">К/С: {{person.ks}}</h4>
      </div>
      <div *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.HUMAN'">
        <h3 class="ui dividing header fw-bold">Паспорт</h3>
        <h4 class="header mt-0">Серия: {{person.passport.serie}}</h4>
        <h4 class="header mt-0">Номер: {{person.passport.number}}</h4>
        <h4 class="header mt-0">Кто выдал: {{person.passport.org}}</h4>
        <h4 class="header mt-0">Дата выдачи: {{person.passport.date| date:'shortDate'}}</h4>
        <h4 class="header mt-0">Код подразделения: {{person.passport.code}}</h4>
        <h4 class="header mt-0">Прописка: {{person.passport.address}}</h4>
      </div>
    </div>
    <!--<div class="four wide column">-->
    <!--<h3 class="ui dividing header fw-bold">Платежные данные</h3>-->
    <!--<h4 class="header">Расчетный счет: </h4>-->
    <!--<h4 class="header mt-0">Банк: </h4>-->
    <!--</div>-->
  </div>
</div>
