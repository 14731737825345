import { BaseModel } from './base.model';
import { DealPositionValue } from './deal-position-value.model';
import { Deal } from './deal.model';
import { Position } from './position.model';
import { User } from './user.model';
import { OrderStatus } from './enums/order-status.enum';

export class DealPosition extends BaseModel {
  cost?: number;
  count: number;
  created: Date;
  deal: Deal;
  delivery?: any;
  disabled?: boolean;
  discountPercent = 0;
  discountPrice = 0;
  errorMessage?: string;
  inProduction: boolean;
  manufacturer?: User;
  mounting?: any;
  personalChangesCost: number;
  position: Position;
  positionValues: DealPositionValue[];
  price: number;
  production?: any;
  reclamation?: any;
}
