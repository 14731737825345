import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContenteditableModule } from 'ng-contenteditable';
import { SuiModule } from 'ng2-semantic-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { QuillModule } from 'ngx-quill';
import { DeferLoadDirective } from '../_directives/defer-load.directive';
import { FocusDirective } from '../_directives/focus.directive';
import { HtmlDirective } from '../_directives/html.directive';
import { PermissionDirective } from '../_directives/permission.directive';
import { CalendarPipe } from '../_pipes/calendar.pipe';
import { CheckFieldPermissionPipe } from '../_pipes/check-field-permission.pipe';
import { CheckValuePermissionPipe } from '../_pipes/check-value-permission.pipe';
import { ChecklistFilterPipe } from '../_pipes/checklist-filter.pipe';
import { DateDiffPipe } from '../_pipes/date-diff.pipe';
import { DealshortPipe } from '../_pipes/dealshort.pipe';
import { FileSizePipe } from '../_pipes/file-size.pipe';
import { FileTypePipe } from '../_pipes/file-type.pipe';
import { FilterCommentPipe } from '../_pipes/filter-comment.pipe';
import { HighlightPipe } from '../_pipes/highlight.pipe';
import { HumanNumberPipe } from '../_pipes/human-number.pipe';
import { HumanPipe } from '../_pipes/human.pipe';
import { MomentPipe } from '../_pipes/moment.pipe';
import { OrderByColumnPipe } from '../_pipes/order-by-column.pipe';
import { OrderByDatePipe } from '../_pipes/order-by-date.pipe';
import { OrderPositionFilterPipe } from '../_pipes/order-position-filter.pipe';
import { OrderTypeNamePipe } from '../_pipes/order-type-name.pipe';
import { PhonePipe } from '../_pipes/phone.pipe';
import { PositionTypePipe } from '../_pipes/position-type.pipe';
import { ProjectStatusPipe } from '../_pipes/project-status.pipe';
import { SafeHtmlPipe } from '../_pipes/safe-html.pipe';
import { SecurePipe } from '../_pipes/secure.pipe';
import { SurnamePipe } from '../_pipes/surname.pipe';
import { TaskStatusPipe } from '../_pipes/task-status.pipe';
import { TaskPipe } from '../_pipes/task.pipe';
import { TruncatePipe } from '../_pipes/truncate.pipe';
import { UrlHelperPipe } from '../_pipes/url-helper.pipe';
import { ValidDatePipe } from '../_pipes/valid-date.pipe';
import { ClothDropdownComponent } from '../catalog/cloth-dropdown/cloth-dropdown.component';
import { DealFieldDropdownComponent } from '../catalog/deal-field-dropdown/deal-field-dropdown.component';
import { MaterialDropdownComponent } from '../catalog/material-dropdown/material-dropdown.component';
import { ClientChannelMultiDropdownComponent } from '../client/client-channel-multi-dropdown/client-channel-multi-dropdown.component';
import { ClientDropdownComponent } from '../client/client-dropdown/client.dropdown.component';
import { ClientTypeDropdownComponent } from '../client/client-type-dropdown/client-type.dropdown.component';
import { MyCompanyDropdownComponent } from '../client/my-company/my-company-dropdown/my-company.dropdown.component';
import { MyCompanyMultiDropdownComponent } from '../client/my-company/my-company-multi-dropdown/my-company.multi.dropdown.component';
import { PersonDropdownComponent } from '../client/person/person-dropdown/person.dropdown.component';
import { PersonMultiDropdownComponent } from '../client/person/person-multi-dropdown/person.multi.dropdown.component';
import { PersonViewComponent } from '../client/person/person-view/person-view.component';
import { CommentReplyTextareaComponent } from '../comment/comment-reply-textarea/comment.reply.textarea.component';
import { CommentTextareaComponent } from '../comment/comment-textarea/comment.textarea.component';
import { CommentComponent } from '../comment/comment.component';
import { WhatsappStatusComponent } from '../comment/whatsapp-status/whatsapp-status.component';
import { ContactComponent } from '../contact/contact.component';
import { DaterangepickerComponent } from '../daterangepicker/daterangepicker.component';
import { DealDropdownComponent } from '../deal/deal-dropdown/deal.dropdown.component';
import { DealPositionMultiDropdownComponent } from '../deal/deal-position-multi-dropdown/deal-position-multi-dropdown.component';
import { DealPositionsComponent } from '../deal/deal-position/deal-position.component';
import { DealChangeStatusDropdownComponent } from '../deal/deal-status-dropdown/deal-change-status.dropdown.component';
import { DealStatusDropdownComponent } from '../deal/deal-status-dropdown/deal-status-dropdown.component';
import { DealStatusMultiDropdownComponent } from '../deal/deal-status-dropdown/deal-status-multi-dropdown.component';
import { DealStatusLabelComponent } from '../deal/deal-status-label/deal-status-label.component';
import { PositionDropdownComponent } from '../deal/position-dropdown/position.dropdown.component';
import { PositionTypeDropdownComponent } from '../deal/position-type-dropdown/position-type.dropdown.component';
import { DirectionMultiDropdownComponent } from '../direction/direction-multi-dropdown/direction.multi.dropdown.component';
import { DocumentMultiDropdownComponent } from '../document/document-multi-dropdown/document.multi.dropdown.component';
import { FileComponent } from '../file/file.component';
import { FilialDropdownComponent } from '../filial/filial-dropdown/filial.dropdown.component';
import { FilialLabelComponent } from '../filial/filial-label/filial.label.component';
import { FilialMultiDropdownComponent } from '../filial/filial-multi-dropdown/filial-multi-dropdown.component';
import { ProductionTypeMultiDropdownComponent } from '../report/production/production-type-multi-dropdown/production-type-multi-dropdown.component';
import { ReportChartComponent } from '../report/report-chart/report-chart.component';
import { ReportSearchButtonComponent } from '../report/report-search-button/report-search-button.component';
import { StaffDropdownComponent } from '../staff/staff-dropdown/staff.dropdown.component';
import { StaffSimpleDropdownComponent } from '../staff/staff-dropdown/staff.dropdown.simple.component';
import { StaffMultiDropdownComponent } from '../staff/staff-dropdown/staff.multi.dropdown.component';
import { StaffGroupMultiDropdownComponent } from '../staff/staff-group/staff-group-dropdown/staff.group.multi.dropdown.component';
import { TodoTypeMultiDropdownComponent } from '../todo/todo-type-multi-dropdown/todo-type-multi-dropdown.component';
import { TodoViewComponent } from '../todo/todo-view/todo.view.component';
import { TodoFilterPipe } from '../todo/todo-view/todo.view.pipe';
import { UnitDropdownComponent } from '../unit/unit-dropdown/unit.dropdown.component';
import { UnitMultiDropdownComponent } from '../unit/unit-multi-dropdown/unit.multi.dropdown.component';
import { UserAvatarComponent } from '../user/user-avatar/user-avatar.component';
import { UserLabelComponent } from '../user/user-label/user-label.component';
import { EntityMultiDropdownComponent } from './dropdowns/entity.multi.dropdown.component';
import { EntitySingleDropdownComponent } from './dropdowns/entity.single.dropdown.component';
import { StringMultiDropdownComponent } from './dropdowns/string.multi.dropdown.component';
import { DashboardWidgetsPipe } from '../_pipes/dashboard-widgets.pipe';
import { ReminderComponent } from './reminder/reminder.component';
import { SmsStatusComponent } from '../comment/sms-status/sms-status.component';

const quillEditorModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['link'],
    ['clean'],
  ]
};

@NgModule({
  imports: [
    CommonModule,
    ContenteditableModule,
    FormsModule,
    QuillModule.forRoot({ modules: quillEditorModules }),
    ReactiveFormsModule,
    RouterModule,
    SuiModule,
    ContenteditableModule,
    NgxPermissionsModule,
  ],
  declarations: [
    CalendarPipe,
    CheckFieldPermissionPipe,
    CheckValuePermissionPipe,
    ChecklistFilterPipe,
    ClientChannelMultiDropdownComponent,
    ClientDropdownComponent,
    ClientTypeDropdownComponent,
    ClothDropdownComponent,
    CommentComponent,
    CommentReplyTextareaComponent,
    CommentTextareaComponent,
    ContactComponent,
    DashboardWidgetsPipe,
    DateDiffPipe,
    DaterangepickerComponent,
    DealChangeStatusDropdownComponent,
    DealDropdownComponent,
    DealFieldDropdownComponent,
    DealPositionMultiDropdownComponent,
    DealPositionsComponent,
    DealStatusDropdownComponent,
    DealStatusDropdownComponent,
    DealStatusLabelComponent,
    DealStatusMultiDropdownComponent,
    DealshortPipe,
    DeferLoadDirective,
    DirectionMultiDropdownComponent,
    DocumentMultiDropdownComponent,
    EntityMultiDropdownComponent,
    EntitySingleDropdownComponent,
    FileComponent,
    FileSizePipe,
    FileTypePipe,
    FilialDropdownComponent,
    FilialLabelComponent,
    FilialMultiDropdownComponent,
    FilterCommentPipe,
    FocusDirective,
    HighlightPipe,
    HtmlDirective,
    HumanNumberPipe,
    HumanPipe,
    DateDiffPipe,
    ContactComponent,
    CommentComponent,
    CommentTextareaComponent,
    CommentReplyTextareaComponent,
    WhatsappStatusComponent,
    SmsStatusComponent,
    StaffDropdownComponent,
    StaffMultiDropdownComponent,
    StaffGroupMultiDropdownComponent,
    DealDropdownComponent,
    ClientDropdownComponent,
    ClientTypeDropdownComponent,
    DealChangeStatusDropdownComponent,
    MaterialDropdownComponent,
    MomentPipe,
    MyCompanyDropdownComponent,
    MyCompanyMultiDropdownComponent,
    OrderByColumnPipe,
    OrderByDatePipe,
    OrderPositionFilterPipe,
    OrderTypeNamePipe,
    PermissionDirective,
    PersonDropdownComponent,
    PersonMultiDropdownComponent,
    PersonViewComponent,
    PhonePipe,
    PositionDropdownComponent,
    PositionTypeDropdownComponent,
    PositionTypePipe,
    ProductionTypeMultiDropdownComponent,
    ProjectStatusPipe,
    ReminderComponent,
    ReportChartComponent,
    ReportSearchButtonComponent,
    SafeHtmlPipe,
    SecurePipe,
    StaffDropdownComponent,
    StaffGroupMultiDropdownComponent,
    StaffMultiDropdownComponent,
    StaffSimpleDropdownComponent,
    StringMultiDropdownComponent,
    SurnamePipe,
    TaskPipe,
    TaskStatusPipe,
    TodoFilterPipe,
    TodoTypeMultiDropdownComponent,
    TodoViewComponent,
    TruncatePipe,
    UnitDropdownComponent,
    UnitMultiDropdownComponent,
    UrlHelperPipe,
    UserAvatarComponent,
    UserLabelComponent,
    ValidDatePipe,
    WhatsappStatusComponent,
  ],
  exports: [
    CalendarPipe,
    HumanPipe,
    HumanNumberPipe,
    DateDiffPipe,
    FocusDirective,
    DeferLoadDirective,
    PermissionDirective,
    StaffDropdownComponent,
    StaffMultiDropdownComponent,
    StaffGroupMultiDropdownComponent,
    SmsStatusComponent,
    DealDropdownComponent,
    DealChangeStatusDropdownComponent,
    CheckFieldPermissionPipe,
    CheckValuePermissionPipe,
    ChecklistFilterPipe,
    ClientChannelMultiDropdownComponent,
    ClientDropdownComponent,
    ClientTypeDropdownComponent,
    ClothDropdownComponent,
    CommentComponent,
    CommentReplyTextareaComponent,
    CommentTextareaComponent,
    ContactComponent,
    DashboardWidgetsPipe,
    DateDiffPipe,
    DaterangepickerComponent,
    DealChangeStatusDropdownComponent,
    DealDropdownComponent,
    DealFieldDropdownComponent,
    DealPositionMultiDropdownComponent,
    DealPositionsComponent,
    DealStatusDropdownComponent,
    DealStatusDropdownComponent,
    DealStatusLabelComponent,
    DealStatusMultiDropdownComponent,
    DealshortPipe,
    DeferLoadDirective,
    DirectionMultiDropdownComponent,
    DocumentMultiDropdownComponent,
    EntityMultiDropdownComponent,
    EntitySingleDropdownComponent,
    FileComponent,
    FileSizePipe,
    FileTypePipe,
    FilialDropdownComponent,
    FilialLabelComponent,
    FilialMultiDropdownComponent,
    FilterCommentPipe,
    FocusDirective,
    HtmlDirective,
    HumanNumberPipe,
    HumanPipe,
    MaterialDropdownComponent,
    MomentPipe,
    MyCompanyDropdownComponent,
    MyCompanyMultiDropdownComponent,
    NgxPermissionsModule,
    OrderByColumnPipe,
    OrderByDatePipe,
    OrderPositionFilterPipe,
    OrderTypeNamePipe,
    PermissionDirective,
    PersonDropdownComponent,
    PersonMultiDropdownComponent,
    PersonViewComponent,
    PhonePipe,
    PositionDropdownComponent,
    PositionTypeDropdownComponent,
    ProductionTypeMultiDropdownComponent,
    PositionTypePipe,
    ProjectStatusPipe,
    ReminderComponent,
    ReportChartComponent,
    ReportSearchButtonComponent,
    SafeHtmlPipe,
    SecurePipe,
    StaffDropdownComponent,
    StaffGroupMultiDropdownComponent,
    StaffMultiDropdownComponent,
    StaffSimpleDropdownComponent,
    StringMultiDropdownComponent,
    SurnamePipe,
    TaskPipe,
    TaskStatusPipe,
    TodoFilterPipe,
    TodoTypeMultiDropdownComponent,
    TodoViewComponent,
    TruncatePipe,
    UnitMultiDropdownComponent,
    UrlHelperPipe,
    UserAvatarComponent,
    UserLabelComponent,
    ValidDatePipe,
    WhatsappStatusComponent,
  ],
  providers: [OrderTypeNamePipe, PositionTypePipe]
})

export class SharedModule {

}
