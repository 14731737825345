<!--<div *ngIf="!loaded" style="position: relative; margin-top: 50px">-->
<!--  <div class="ui active text loader">Загрузка...</div>-->
<!--</div>-->
<div>
  <button class="ui blue button" (click)="setOrderListMode()">
    <i class="arrow left icon"></i> К списку заказов
  </button>
</div>

<div *ngIf="loaded" class="ui stackable grid mt-1">
  <div class="sixteen wide column">
    <div class="d-flex">
      <h3 class="ui header order-header">Заказ №{{order.id}}</h3>
      <e2b-order-label [orderType]="order.type"></e2b-order-label>
    </div>


    <div class="greytext">{{order.created | date:'dd.MM.yyyy, HH:mm'}}</div>
  </div>
  <div class="eight wide column" style="border-right: 1px solid #fafafa;padding-right:50px;">
    <div class="mt-2">
      <h4 class="header">Cтатус:
        <span *ngIf="order.status === OrderStatus.NEW">Новый</span>
        <span *ngIf="order.status === OrderStatus.SENT">Отправлен в производство</span>
        <span *ngIf="order.status === OrderStatus.IN_PROCESS">
          В работе <i class="clock outline icon"></i>
        </span>
        <span *ngIf="order.status === OrderStatus.COMPLETED" class="color-green">
          Исполнен <i class="green check icon"></i>
        </span>
        <span *ngIf="order.status === OrderStatus.REJECTED" class="color-red">
          Отказан <i class="red exclamation circle icon"></i>
        </span>
      </h4>
      <button class="ui blue button" *ngIf="order.status === OrderStatus.NEW && order.type === OrderType.PRODUCTION" (click)="sendToProduction()">Отправить в производство</button>

      <h4 class="header">Позиции</h4>
      <table class="ui stackable table">
        <thead>
        <tr>
          <th class="fw-bold">Позиция</th>
          <th class="fw-bold">Кол-во</th>
          <th class="fw-bold">Цена</th>
          <th class="fw-bold">Сумма</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let orderPosition of order.orderDealPositions">
          <td>
            <a (click)="showPosition(orderPosition)" class="cursor">{{orderPosition.dealPosition.position.name}}</a>
          </td>
          <td>{{orderPosition.dealPosition.count}}</td>
          <td>{{orderPosition.dealPosition.price}}</td>
          <td>{{orderPosition.dealPosition.price * orderPosition.dealPosition.count}}</td>
          <td><i class="red delete icon cursor"
                 *ngIf="!orderPosition.completed && order.status === OrderStatus.NEW && order.orderDealPositions.length !== 1"
                 (click)="deletePosition(orderPosition)"></i></td>
        </tr>
        <ng-container *ngIf="addPositionsMode">
          <tr>
            <div class="fw-bold mt-3 mb-1">Позиции для добавления:</div>
          </tr>
          <tr *ngFor="let position of restPositions | orderPositionFilter:order.type:positionsType"
              [class.disabled]="position.disabled">
            <td>
              <div>{{position.position.name}}</div>
              <div *ngIf="position.errorMessage" class="mini text"><i
                class="exclamation circle icon"></i>{{position.errorMessage}}</div>
            </td>
            <td>{{position.count}}</td>
            <td>{{position.price}}</td>
            <td>{{position.price * position.count}}</td>
            <td>
              <button class="ui green small fit button" *ngIf="!position.disabled" (click)="addPosition(position)">
                <i class="plus icon"></i>Добавить
              </button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>

      <div *ngIf="order.status === OrderStatus.NEW && order.type !== OrderType.RECLAMATION">
        <button class="ui blue button" *ngIf="!addPositionsMode" (click)="addPositionsMode = true;">
          <i class="plus icon"></i>Добавить позиции
        </button>
        <button class="ui red button" *ngIf="addPositionsMode" (click)="addPositionsMode = false;">
          Отмена
        </button>
      </div>

      <h4 class="header order-fields-header">Поля заказа</h4>
      <div class="ui form">
        <div class="inline fields">
          <div class="five wide required field">
            <label class="fw-bold">Ответственный</label>
          </div>
          <div class="eleven wide field">
            <ng-template let-user #responsibleOptionTemplate>
              <div>{{user.name}}</div>
            </ng-template>
            <sui-select class="fluid selection w-100"
                        placeholder="Ответственный"
                        [(ngModel)]="order.responsible"
                        (ngModelChange)="saveResponsible($event)"
                        [options]="responsible"
                        [optionTemplate]="responsibleOptionTemplate"
                        [isDisabled]="!canEdit"
                        #selectResponsible>
              <sui-select-option *ngFor="let user of selectResponsible.filteredOptions | orderByColumn: 'name:string'"
                                 [value]="user">
              </sui-select-option>
            </sui-select>
          </div>
        </div>
        <div class="inline fields">
          <div [ngClass]="'five wide field' + (order.type !== OrderType.RECLAMATION ? ' required' : '')">
            <label class="fw-bold">Дата исполнения</label>
          </div>
          <div class="eleven wide required field">
            <div [ngClass]="canEdit ? 'ui left icon input' : 'ui left icon disabled input'">
              <i class="calendar icon"></i>
              <input [(ngModel)]="order.date"
                     [ngModelOptions]="{updateOn: 'blur'}"
                     (ngModelChange)="saveDate($event)"
                     [pickerMinDate]="currentDate"
                     [pickerMode]="OrderType.PRODUCTION ? 'date' : 'datetime'"
                     autocomplete="off"
                     name="date"
                     suiDatepicker>
            </div>
          </div>
          <div class="coefficientOrderPopup" (click)="calculatedCoefficient.id = false" *ngIf="calculatedCoefficient.id">
            <table class="ui very basic table">
              <tr>
                <td class="fw-bold">Производство</td>
                <td class="fw-bold">{{calculatedCoefficient.data.use}}</td>
                <td>{{calculatedCoefficient.data.all}}</td>
              </tr>
              <tr>
                <td class="fw-bold">Монтаж</td>
                <td class="fw-bold">{{calculatedCoefficient.data.mountUse}}</td>
                <td>{{calculatedCoefficient.data.mountAll}}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="inline fields">
          <div [ngClass]="'five wide field' + (order.type === OrderType.DELIVERY ? ' required' : '')">
            <label class="fw-bold">Себестоимость</label>
          </div>
          <div class="eleven wide required field">
            <input [(ngModel)]="order.cost"
                   [ngModelOptions]="{updateOn: 'blur'}"
                   (ngModelChange)="saveCost($event)"
                   [disabled]="!canEdit"
                   type="number"
                   autocomplete="off"
                   name="cost">
          </div>
        </div>
      </div>
      <div class="mt-2">
        <e2b-order-field [orderFieldValues]="order.orderFieldValues"></e2b-order-field>
      </div>
    </div>
  </div>
  <div class="eight wide column">
    <div *ngIf="currentPosition">
      <div class="d-flex">
        <h3 class="header">{{currentPosition.dealPosition.position.name}}
          <br>
          <span class="greytext">{{currentPosition.dealPosition.position.type | positionType | uppercase}}</span>
        </h3>
        <button class="ui blue button ml-4" *ngIf="order.type === OrderType.PRODUCTION" (click)="calcPositionCost()">Расчитать себестоимость</button>
      </div>

      <div class="ui divider"></div>
      <div class="mt-2">
        <e2b-order-position-field [orderDealPositionValues]="currentPosition.orderDealPositionValues"
                                  [currentPosition]="currentPosition"></e2b-order-position-field>
        <h4 *ngIf="!currentPosition.orderDealPositionValues.length" class="header">Нет полей по данной позиции</h4>
      </div>
    </div>
  </div>
  <div class="sixteen wide column">
    <button *ngIf="order.status === OrderStatus.NEW || order.status === OrderStatus.REJECTED"
            (click)="deleteOrder()"
            class="ui red small fit button">
      <i class="trash icon"></i>Удалить заказ
    </button>
  </div>
</div>



