import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'e2b-dashboard-director',
  templateUrl: './dashboard-director.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/statistic.min.css',
    '../../../assets/design/semantic-ui/components/card.min.css',
    '../../../assets/design/semantic-ui/components/image.min.css',
    '../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../assets/design/semantic-ui/components/list.min.css',
    './dashboard-director.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardDirectorComponent {

  period = localStorage.getItem('e2b.dashboard.director.period') ? localStorage.getItem('e2b.dashboard.director.period') : 'month';

  day: Moment = localStorage.getItem('e2b.dashboard.director.date') ? moment(localStorage.getItem('e2b.dashboard.director.date'), 'YYYY-MM-DD') : moment();

  @ViewChild('date', { static: false })
  date: ElementRef;

  selectedDate;

  dayEvent = new EventEmitter();
  periodEvent = new EventEmitter();

  constructor(public userService: UserService) {
  }

  prevDay() {
    this.day = this.day.subtract(1, 'day');
    localStorage.setItem('e2b.dashboard.director.date', this.day.format('YYYY-MM-DD'));
    this.dayEvent.emit(this.day);
  }

  nextDay() {
    this.day = this.day.add(1, 'day');
    localStorage.setItem('e2b.dashboard.director.date', this.day.format('YYYY-MM-DD'));
    this.dayEvent.emit(this.day);
  }

  selectPeriod(period: string) {
    this.period = period;
    localStorage.setItem('e2b.dashboard.director.period', period);
    this.periodEvent.emit(this.period);
  }

  onDate() {
    this.date.nativeElement.click();
  }

  onSelectDate($event) {
    if (!$event) {
      return false;
    }
    this.day = moment($event);
    localStorage.setItem('e2b.dashboard.director.date', this.day.format('YYYY-MM-DD'));
    this.dayEvent.emit(this.day);
  }
}
