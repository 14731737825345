import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Filial } from '../../_models/filial.model';

@Component({
  selector: 'e2b-filial-label, [e2b-filial-label]',
  templateUrl: './filial.label.component.html',
  styleUrls: ['../../../assets/design/semantic-ui/components/label.min.css'],
  encapsulation: ViewEncapsulation.None,

})
export class FilialLabelComponent {

  @Input()
  public filial: Filial = new Filial();
}
