import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { PositionMaterial } from '../../_models/position-material.model';
import { PositionMaterialService } from '../../_services/position-material.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-material-list',
  templateUrl: './material-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class MaterialListComponent implements OnInit {

  materials: PositionMaterial[] = [];
  loaded = false;
  currentMaterial: PositionMaterial | boolean = false;

  constructor(private actionService: ActionService,
              private userService: UserService,
              private materialService: PositionMaterialService) {
  }

  selectPositionEvent(material) {
    const emit = new EventEmitter();
    emit.subscribe(position => {
      material.position = position;
      this.save(material);
    });
    return emit;
  }

  ngOnInit() {

    const addEvent = new EventEmitter();

    if (this.userService.checkPermissions(['position-material', '/'], ['write'])) {
      this.actionService.createActionEvent.emit({
        name: 'Добавить',
        event: addEvent,
        class: 'blue'
      });
    }

    const updateEvent = new EventEmitter();
    this.actionService.createActionEvent.emit({
      name: 'Обновить',
      event: updateEvent,
      class: 'blue',
      icon: 'sync'
    });

    updateEvent.subscribe(() => {
      this.load();
    });

    addEvent.subscribe(() => {
      this.add();
    });
    this.load();
  }

  load() {
    this.loaded = false;
    this.materials = [];
    this.materialService.find<PositionMaterial>().subscribe(materials => {
      this.materials = materials;
      this.loaded = true;
    }, (err) => {
      this.loaded = true;
      console.error('Component error', err);
    });
  }

  save(positionMaterial: PositionMaterial) {
    if (!positionMaterial.name ||
      !positionMaterial.article ||
      positionMaterial.price16 === undefined ||
      positionMaterial.price25 === undefined ||
      positionMaterial.price16k === undefined ||
      positionMaterial.price25k === undefined) {
      return false;
    }
    if (positionMaterial.id) {
      this.materialService.update(positionMaterial).subscribe(() => {
      }, e => {
        console.error(e);
      });
    } else {
      this.materialService.create<PositionMaterial>(positionMaterial).subscribe(created => {
        this.materials[this.materials.indexOf(positionMaterial)] = created;
      }, e => {
        console.error(e);
      });
    }
  }

  add() {
    const m = new PositionMaterial();
    this.materials.unshift(m);
  }

  remove(material) {
    if (!material.id) {
      this.materials.splice(this.materials.indexOf(material), 1);
      return false;
    }
    const yes = confirm('Вы уверены, что хотите удалить материал ' + material.name + '?');
    if (!yes) {
      return true;
    }
    this.materialService.deleteById(material.id).subscribe(() => {
      this.materials.splice(this.materials.indexOf(material), 1);
    });
  }
}
