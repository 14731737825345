import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../_services/user.service';

@Injectable()
export class PermissionGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const access = route.data.access as Array<string>;
    if (this.userService.current()) {
      let accessObj = this.userService.current().accessObject.routes;
      access.map(r => {
        if (accessObj[r] !== undefined) {
          accessObj = accessObj[r];
        }
      });
      if (typeof accessObj === 'string' && ['read', 'write'].indexOf(accessObj) === -1) {
        this.router.navigate(['/']).catch(console.error);
        return false;
      } else if (typeof accessObj !== 'string') {
        this.router.navigate(['/']).catch(console.error);
        return false;
      }
      return true;
    } else {
      this.router.navigate(['/login']).catch(console.error);
      return false;
    }
  }
}
