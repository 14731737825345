import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { UserService } from '../../_services/user.service';

@Injectable()
export class DealShowResolve implements Resolve<boolean> {

  constructor(private userService: UserService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot): boolean {
    const id = +route.params['id'];
    const currentUser = this.userService.current();
    const roles = currentUser.roles.map(role => role.name);
    if (roles.indexOf('E2B.ROLE.PRODUCTION') > -1) {
      this.router.navigate(['/deal/production/', id]).catch(console.error);
      return false;
    } else {
      return true;
    }
  }
}
