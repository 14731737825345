import { Bank } from './bank.model';
import { BaseModel } from './base.model';
import { ClientContract } from './client-contract.model';
import { Client } from './client.model';
import { Deal } from './deal.model';
import { PersonType } from './person-type.model';

export class Person extends BaseModel {
  ks: string;
  name: string;
  inn: string;
  kpp: string;
  manager: string;
  okpo: string;
  passport: { serie: number, number: number, org: string, date: Date, code: string, address: string };
  ogrn: string;
  grnip: string;
  grnipDate: Date;
  rs: string;
  created: Date;
  address: string;
  postAddress: string;
  deleted: boolean;
  syncId: string;
  ref: string;
  contracts: ClientContract[];
  client: Client;
  type: PersonType;
  bank: Bank;
  deals: Deal;
  connectionLink: string;
}
