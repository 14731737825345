import { Pipe, PipeTransform } from '@angular/core';
import { Deal } from '../../_models/deal.model';

@Pipe({
  name: 'dealFilter'
})
export class DealFilterPipe implements PipeTransform {
  transform(items: Deal[], filter: Deal, length): Deal[] {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item: Deal) => this.applyFilter(item, filter));
  }

  applyFilter(deal: Deal, filter: Deal): boolean {
    for (const field in filter) {
      if (filter[field]) {
        if (typeof filter[field] === 'string') {
          if (deal[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1) {
            return false;
          }
        } else if (typeof filter[field] === 'number') {
          if (deal[field] !== filter[field]) {
            return false;
          }
        } else if (typeof filter[field] === 'object' && filter[field]['id']) {
          if (deal[field] && deal[field].id !== filter[field].id) {
            return false;
          } else if (!deal[field]) {
            return false;
          }
        }
      }
    }
    return true;
  }
}
