import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'e2b-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css', '../../assets/design/semantic-ui/components/button.min.css'],
  encapsulation: ViewEncapsulation.None
})

export class ActionComponent {
  @Input() public actions: any;
  @Input() public events: any;
}
