import { BaseModel } from './base.model';

export class Reclamation extends BaseModel {
  childrens: Reclamation[];
  cost: number;
  deleted: boolean;
  id: number;
  name: string;
  parent: Reclamation;
}
