import { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

const types = {
  0: 'Нет типа',
  1: 'Корпусная мебель',
  2: 'Мягкая мебель',
  3: 'Матрас',
  4: 'Кастомизация',
};

@Pipe({
  name: 'positionType'
})
export class PositionTypePipe implements PipeTransform {
  transform(type: number): string {
    return types[type] || '';
  }
}
