<div class="ui form" *ngIf="loaded && fields.length">
  <ng-container *ngFor="let field of fields | orderByColumn:'name:string' | orderByColumn:'sort:number'">
    <div class="field">
      <label class="fw-bold">{{field.name}}</label>
      <div class="ui input" *ngIf="field.type === 'FILE' && mappedValues[field.id].edit">
        <input type="file" id="file{{field.id}}" name="file" #fileFieldForm multiple
               [disabled]="!userService.checkFieldPermission(field.id, ['write'])"
               (change)="mappedValues[field.id].files = fileFieldForm.files; onMappedValueBlur(field.id);">
        <label for="file{{field.id}}">
          <span *ngIf="!mappedValues[field.id].value">Прикрепить файл(ы)</span>
          <span *ngIf="mappedValues[field.id].value">Выбрано {{mappedValues[field.id].value.length}} файл(а)</span>
        </label>
      </div>
      <div *ngIf="field.type === 'FILE' && mappedValues[field.id].value && !mappedValues[field.id].edit">
        <div class="d-flex flex-wrap">
          <div class="attach" *ngFor="let file of mappedValues[field.id].value">
            <i class='ui delete red icon pointer' (click)="removeFile(field.id, file)"></i>&nbsp;
            <e2b-file [file]="file"></e2b-file>
          </div>
        </div>
        <button class="ui tiny basic button" (click)="editMappedValue(field.id)">
          <i class="plus icon pointer m-0"></i>&nbsp;&nbsp;Добавить файлы
        </button>
      </div>
      <div *ngIf="field.type === 'FILE' && !mappedValues[field.id].value && !mappedValues[field.id].edit">
        <button class="ui tiny basic button" (click)="editMappedValue(field.id)">
          <i class="plus icon pointer m-0"></i>&nbsp;&nbsp;Добавить файлы
        </button>
      </div>
      <div class="ui action input" *ngIf="field.type === 'DATE'">
        <input suiDatepicker
               [disabled]="!userService.checkFieldPermission(field.id, ['write'])"
               [name]="field.id"
               [pickerMode]="'date'"
               [pickerFirstDayOfWeek]="1"
               [pickerUseNativeOnMobile]="true"
               [pickerPlacement]="'top left'"
               (pickerSelectedDateChange)="onPickerChange($event, field.id)"
               [(ngModel)]="mappedValues[field.id].value"
               [placeholder]="field.name">
        <button class="ui fit red icon button" [disabled]="!mappedValues[field.id].value" (click)="removeValue(mappedValues[field.id])">
          <i class="remove icon"></i>
        </button>
      </div>
      <div class="ui action input" *ngIf="field.type === 'NUMBER' || field.type === 'MONEY'">
        <input type="number" [(ngModel)]="mappedValues[field.id].value"
               [disabled]="!userService.checkFieldPermission(field.id, ['write'])"
               [ngModelOptions]="{updateOn: 'blur'}"
               (ngModelChange)="onMappedValueBlur(field.id)"
               [placeholder]="field.name"
               [name]="field.id"/>
        <button class="ui fit red icon button" [disabled]="!mappedValues[field.id].value || !userService.checkFieldPermission(field.id, ['write'])" (click)="removeValue(mappedValues[field.id])">
          <i class="remove icon"></i>
        </button>
      </div>
      <div class="ui action input" *ngIf="field.type === 'ENUM'">
        <sui-select class="fluid selection"
                    [isDisabled]="!userService.checkFieldPermission(field.id, ['write'])"
                    [name]="field.id"
                    [placeholder]="field.name"
                    [isSearchable]="true"
                    [options]="field.values"
                    [(ngModel)]="mappedValues[field.id].value"
                    (ngModelChange)="onMappedValueBlur(field.id)"
                    [ngModelOptions]="{name: field.name}"
                    #selectRequiredChildren>
          <sui-select-option *ngFor="let value of selectRequiredChildren.filteredOptions"
                             [value]="value">
          </sui-select-option>
        </sui-select>
        <button class="ui red fit icon button" [disabled]="!mappedValues[field.id].value || !userService.checkFieldPermission(field.id, ['write'])"
                (click)="removeValue(mappedValues[field.id])">
          <i class="remove icon"></i>
        </button>
      </div>
      <div class="ui action input" *ngIf="field.type === 'TEXT'">
        <input type="text" [(ngModel)]="mappedValues[field.id].value"
               [ngModelOptions]="{updateOn: 'blur'}"
               (ngModelChange)="onMappedValueBlur(field.id)"
               [placeholder]="field.name"
               [name]="field.id"/>
        <button class="ui red fit icon button" [disabled]="!mappedValues[field.id].value"
                (click)="removeValue(mappedValues[field.id])">
          <i class="remove icon"></i>
        </button>
      </div>
      <div class="ui action input" *ngIf="field.type === 'ADDRESS'">
        <ng-template let-result let-query="query" #addressOptionTemplate>
          <div class="mininame">{{result.name}}</div>
          <div class="miniprice">{{result.zip}}</div>
          <div class="minitext">{{result.fullname}}</div>
        </ng-template>
        <sui-search *ngIf="mappedValues[field.id].edit"
                    style="width: 100%;"
                    [optionsLookup]="addressSearch"
                    name="address"
                    [maxResults]="25"
                    [placeholder]="mappedValues[field.id].value ? mappedValues[field.id].value : 'Адрес'"
                    searchDelay="1000"
                    optionsField="fullname"
                    [resultTemplate]="addressOptionTemplate"
                    (resultSelected)="addressSelected($event, field.id)">
        </sui-search>
        <input *ngIf="!mappedValues[field.id].edit" [(ngModel)]="mappedValues[field.id].value" type="text" name="address" (click)="editMappedValue(field.id)">
        <button class="ui fit red icon button" [disabled]="!mappedValues[field.id].value || !userService.checkFieldPermission(field.id, ['write'])"
                (click)="removeValue(mappedValues[field.id])">
          <i class="remove icon"></i>
        </button>
      </div>
    </div>
  </ng-container>
</div>
