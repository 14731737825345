import { BaseModel } from './base.model';
import { Client } from './client.model';

export class ClientContact extends BaseModel {
  name: string;
  description: string;
  address: string;
  email: string;
  phone: string;
  isMain: boolean;
  client: Client;
}
