import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { BaseModel } from '../../_models/base.model';
import { Deal } from '../../_models/deal.model';
import { Project } from '../../_models/project.model';
import { Reminder } from '../../_models/reminder.model';
import * as moment from 'moment';
import { ReminderService } from '../../_services/reminder.service';
import { Task } from 'src/app/_models/task.model';

enum ReminderOffset {
  fiveMin,
  oneHour,
  tomorrowMorning,
  weekLater
}

@Component({
  selector: 'e2b-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.less']
})
export class ReminderComponent implements OnInit {
  loaded = false;

  reminderOffsets = [
    { type: ReminderOffset.fiveMin, name: 'через 5 минут' },
    { type: ReminderOffset.oneHour, name: 'через час' },
    { type: ReminderOffset.tomorrowMorning, name: 'завтра с утра' },
    { type: ReminderOffset.weekLater, name: 'через неделю' },
  ];
  reminder: Reminder = new Reminder();
  reminderOutput: string;

  @Input()
  reminderEntityInfo: { id: number, name: 'task' | 'deal' | 'project' | 'checklist' };

  constructor(private readonly reminderService: ReminderService) {
  }

  ngOnInit() {
    const findMethodName = 'findBy' + this.reminderEntityInfo.name[0].toUpperCase() + this.reminderEntityInfo.name.substr(1);

    this.reminderService[findMethodName](this.reminderEntityInfo.id).subscribe(reminder => {
      if (reminder) {
        this.setReminder(reminder);
      }
      this.loaded = true;
    });
  }

  setReminder(reminder: Reminder) {
    this.reminder = reminder;
    this.reminderOutput = moment(reminder.date).calendar();
  }

  saveReminder(reminderOffset) {
    let date;
    if (reminderOffset.type === ReminderOffset.fiveMin) {
      date = moment().add('5', 'm').toDate();
    } else if (reminderOffset.type === ReminderOffset.oneHour) {
      date = moment().add('1', 'h').toDate();
    } else if (reminderOffset.type === ReminderOffset.tomorrowMorning) {
      date = moment().add('1', 'days').startOf('day').add('9', 'h').toDate();
    } else if (reminderOffset.type === ReminderOffset.weekLater) {
      date = moment().add('7', 'days').startOf('day').add('9', 'h').toDate();
    }

    if (this.reminder.id) {
      this.reminderService.update<Reminder>(<Reminder>{ id: this.reminder.id, date }).subscribe(() => {
        this.reminder.date = date;
        this.setReminder(this.reminder);
      });
    } else {
      this.reminderService.create<Reminder>(<Reminder>{ [this.reminderEntityInfo.name]: { id: this.reminderEntityInfo.id }, date: date })
        .subscribe(reminder => this.setReminder(reminder));
    }

  }

  deleteReminder() {
    this.reminderService.deleteById(this.reminder.id).subscribe(() => {
      this.reminder = new Reminder();
      this.reminderOutput = '';
    });
  }
}
