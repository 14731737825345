import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { OrderFieldValue } from '../../../_models/order-field-value.model';

@Component({
  selector: 'e2b-order-field',
  templateUrl: './order-field.component.html',
  styleUrls: ['./order-field.component.less']
})
export class OrderFieldComponent implements OnInit {

  @Input()
  orderFieldValues: OrderFieldValue[];

  constructor() { }

  ngOnInit() {
  }

}
