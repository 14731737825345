<ng-template let-option #tmplGroupOptionTemplate>
  <span [innerText]="option.name"></span>
</ng-template>
<sui-multi-select class="fluid selection mx-auto"
                  [placeholder]="placeholder"
                  [isSearchable]="true"
                  [isDisabled]="isDisabled"
                  [(ngModel)]="selected"
                  [ngModelOptions]="{name: 'group'}"
                  (ngModelChange)="save()"
                  [options]="array"
                  labelField="name"
                  [optionTemplate]="tmplGroupOptionTemplate"
                  #selectGroup>
  <sui-select-option *ngFor="let group of selectGroup.filteredOptions | orderByColumn:'name:string'"
                     [value]="group">
  </sui-select-option>
</sui-multi-select>
