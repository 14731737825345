import { Component, EventEmitter, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { User } from '../../../_models/user.model';
import { SaleService } from '../../../_services/sale.service';
import { UserService } from '../../../_services/user.service';

interface SalePointInterface {
  sales: {
    plan: number;
    fact: number;
    prognosis: number;
    dynamic: {
      trend: 'up' | 'down';
      previous: number;
    }
  };
  managers: {
    best: {
      user: User;
      sum: number;
      planPercent: number;
    },
    worst: {
      user: User;
      sum: number;
      planPercent: number;
    }
  };
  meets: {
    plan: number;
    fact: number;
    dynamic: {
      trend: 'up' | 'down';
      previous: number;
    }
  };
  deals: {
    present: number;
    previous: number;
  };
  calls: {
    answered: {
      present: number;
      previous: number;
    },
    noanswer: {
      present: number;
      previous: number;
    },
    spam: {
      present: number;
      previous: number;
    }
  };
  notValidPrognosis: number;
}


@Component({
  selector: 'e2b-dashboard-director-sale',
  templateUrl: './dashboard-director-sale.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/statistic.min.css',
    '../../../../assets/design/semantic-ui/components/card.min.css',
    '../../../../assets/design/semantic-ui/components/image.min.css',
    '../../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../../assets/design/semantic-ui/components/list.min.css',
    './dashboard-director-sale.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardDirectorSaleComponent implements OnInit {

  loaded = false;

  innerWidth: number;

  @Input()
  period: 'day' | 'week' | 'month' = localStorage.getItem('e2b.dashboard.director.period') ? localStorage.getItem('e2b.dashboard.director.period') as 'day' | 'week' | 'month' : 'month';

  @Input()
  day: Moment = localStorage.getItem('e2b.dashboard.director.date') ? moment(localStorage.getItem('e2b.dashboard.director.date'), 'YYYY-MM-DD') : moment();

  widgets = {
    'sale': 'Продажи',
    'managers': 'Доска менеджеров',
    'meetings': 'Встречи',
    'deals': 'Новые сделки',
    'calls': 'Звонки',
    'prognosis': 'Неверный прогноз',
  };
  selectedWidgets: string[] = localStorage.getItem('e2b.dashboard.director.sales') ? JSON.parse(localStorage.getItem('e2b.dashboard.director.sales')) : this.allWidgets;

  options: {
    date: string,
  } = {
    date: this.day.format('YYYY-MM-DD')
  };

  data: {
    day: SalePointInterface,
    week: SalePointInterface,
    month: SalePointInterface
  } = {
    day: {
      sales: {
        plan: 0,
        fact: 0,
        prognosis: 0,
        dynamic: {
          trend: null,
          previous: 0
        }
      },
      managers: {
        best: {
          user: null,
          sum: 0,
          planPercent: 0,
        },
        worst: {
          user: null,
          sum: 0,
          planPercent: 0
        }
      },
      meets: {
        plan: 0,
        fact: 0,
        dynamic: {
          trend: null,
          previous: 0
        }
      },
      deals: {
        present: 0,
        previous: 0,
      },
      calls: {
        answered: {
          present: 0,
          previous: 0
        },
        noanswer: {
          present: 0,
          previous: 0
        },
        spam: {
          present: 0,
          previous: 0
        }
      },
      notValidPrognosis: 0
    },
    week: {
      sales: {
        plan: 0,
        fact: 0,
        prognosis: 0,
        dynamic: {
          trend: null,
          previous: 0
        }
      },
      managers: {
        best: {
          user: null,
          sum: 0,
          planPercent: 0,
        },
        worst: {
          user: null,
          sum: 0,
          planPercent: 0
        }
      },
      meets: {
        plan: 0,
        fact: 0,
        dynamic: {
          trend: null,
          previous: 0
        }
      },
      deals: {
        present: 0,
        previous: 0,
      },
      calls: {
        answered: {
          present: 0,
          previous: 0
        },
        noanswer: {
          present: 0,
          previous: 0
        },
        spam: {
          present: 0,
          previous: 0
        }
      },
      notValidPrognosis: 0
    },
    month: {
      sales: {
        plan: 0,
        fact: 0,
        prognosis: 0,
        dynamic: {
          trend: null,
          previous: 0
        }
      },
      managers: {
        best: {
          user: null,
          sum: 0,
          planPercent: 0,
        },
        worst: {
          user: null,
          sum: 0,
          planPercent: 0
        }
      },
      meets: {
        plan: 0,
        fact: 0,
        dynamic: {
          trend: null,
          previous: 0
        }
      },
      deals: {
        present: 0,
        previous: 0,
      },
      calls: {
        answered: {
          present: 0,
          previous: 0
        },
        noanswer: {
          present: 0,
          previous: 0
        },
        spam: {
          present: 0,
          previous: 0
        }
      },
      notValidPrognosis: 0
    }
  };

  constructor(public userService: UserService,
              private readonly saleService: SaleService) {
  }

  @Input()
  set dayEvent(event: EventEmitter<any>) {
    event.subscribe(day => {
      this.day = day;
      this.options.date = this.day.format('YYYY-MM-DD');
      this.load();
    });
  }

  @Input()
  set periodEvent(event: EventEmitter<any>) {
    event.subscribe(period => {
      this.period = period;
    });
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }


  ngOnInit() {

    this.innerWidth = window.innerWidth;

    this.load();
  }

  load() {
    this.saleService.find<any>(this.options).subscribe((result: any) => {
      this.data = result;
      this.loaded = true;
    });
  }

  get allWidgets(): string[] {
    return Object.keys(this.widgets);
  }

  selectWidget(widgetName) {
    if (this.selectedWidgets.includes(widgetName)) {
      const widgetIndex = this.selectedWidgets.indexOf(widgetName);
      this.selectedWidgets.splice(widgetIndex, 1);
    } else {
      this.selectedWidgets.push(widgetName);
    }
    localStorage.setItem('e2b.dashboard.director.sales', JSON.stringify(this.selectedWidgets));
  }

  public getPreviousQueryFrom() {
    switch (this.period) {
      case 'day':
        return this.day.clone().subtract(1, 'week').startOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    }
  }

  public getPreviousQueryTo() {
    switch (this.period) {
      case 'day':
        return this.day.clone().subtract(1, 'week').endOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    }
  }

  public getQueryFrom() {
    switch (this.period) {
      case 'day':
        return this.day.clone().startOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().startOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().startOf('month').format('YYYY-MM-DD');
    }
  }

  public getQueryTo() {
    switch (this.period) {
      case 'day':
        return this.day.clone().endOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().endOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().endOf('month').format('YYYY-MM-DD');
    }
  }

  public getEndOfMonth() {
    return this.day.clone().endOf('month').format('YYYY-MM-DD');
  }
}
