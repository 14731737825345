<div class="ui stackable grid">
  <div class="row">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div class="ui two wide column">
      <div class="ui vertical text menu">
        <div class="header item">#ФИЛЬТР</div>
        <a class="item" *ngFor="let type of types">{{type.name}}</a>
        <a class="item">Тип подрядчика</a>
      </div>
    </div>
    <div class="ui twelve wide column">
      <table class="ui selectable very padded table" [hidden]="!loaded">
        <thead>
        <tr>
          <th>
            <div class="head">Имя подрядчика</div>
          </th>
          <th>
            <div class="head">Контакты</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let contractor of contractors" role="row">
          <td>
            <a [routerLink]="['/client/contractor/card', contractor.id]">{{contractor.name}}</a>
          </td>
          <td>
            <div [hidden]="contractor.contacts.length === 0"
                 *ngFor="let contact of contractor.contacts">
              <div *ngIf="contact.name">{{contact.name}}</div>
              <div *ngIf="contact.email">
                <a href="mailto:{{contact.email}}">{{contact.email}}</a>
              </div>
              <div *ngIf="contact.phone">
                <a href="tel:{{contact.phone}}">{{contact.phone}}</a>
              </div>
              <div *ngIf="contact.address">
                <a *ngIf="contact.address" href="yandex:{{contact.address}}">{{contact.address}}</a>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
