declare var jQuery: any;

import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Position } from '../../_models/position.model';

@Component({
  selector: 'e2b-deal-position-dropdown',
  templateUrl: './position.dropdown.component.html',
  styleUrls: ['../../../assets/design/semantic-ui/components/input.min.css',
    '../../../assets/design/semantic-ui/components/search.min.css',
    './position.dropdown.component.css'],
})

export class PositionDropdownComponent implements OnInit {
  @Input()
  event: EventEmitter<Position>;

  source: Position[] = [];

  public selectedPosition: Position;

  @Input()
  set positions(positions) {
    jQuery('#dealPositionSearch').search({
      source: positions,
      searchFields: [
        'name'
      ],
      fullTextSearch: 'exact',
      maxResults: 50,
      templates: {
        message: function onMessage(message, type) {
          let html = '';
          if (type === 'empty') {
            html = '<div class="message empty"><div class="header">Нет результатов</div><div class="description">Ничего не найдено</div></div>';
          }
          return html;
        },
        standard: function (response) {
          let html = '';
          response.results.map(r => {
            html += '<a id="' + r.id + '" class="result">';
            html += '<div class="content" style="padding: 5px 0 !important">';
            html += '<div class="title cursor">' + r.name + '</div>';
            html += '<div class="miniprice cursor">' + (r.article ? r.article : '') + '</div><div class="minitext cursor">' + r.cost + '</div>';
            html += '</div></a>';
          });
          return html;
        }
      },
      onSelect: (result) => {
        this.select(result);
      }
    });
  }

  ngOnInit() {

  }

  select($event) {
    this.selectedPosition = $event;
    this.event.emit(this.selectedPosition);
  }
}
