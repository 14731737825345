import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable()
export class DirectionService extends BaseService {
  baseUrl = '/direction';

  findFlat<T>(): Observable<T[]> {
    return this.http.get<T[]>(this.baseUrl + '/flat', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  findAll<T>(): Observable<T[]> {
    return this.http.get<T[]>(this.baseUrl + '/all', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
