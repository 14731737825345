<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <div class="ui form">
      <div class="ui four fields">
        <div class="field">
          <e2b-staff-dropdown [staff]="users" [event]="selectUser"></e2b-staff-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="ui sixteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui form" style="overflow-x: scroll">
        <table class="ui very basic selectable celled table">
          <thead>
          <tr>
            <th>
              <div class="head">Название</div>
            </th>
            <th *ngFor="let p of positions | orderByColumn:'name:string'">
              <div class="head">{{p.name}}</div>
            </th>
          </tr>
          </thead>
          <tbody *ngFor="let cloth of clothes | orderByColumn:'article:string'">
          <tr>
            <td width="20%">
              <div class="field">
                <div class="ui input">
                  <input type="text" [disabled]="!userService.checkPermissions(['position-cloth', '/'], ['write'])" [(ngModel)]="cloth.name" (change)="save(cloth)">
                </div>
              </div>
            </td>
            <td *ngFor="let price of cloth.prices | orderByColumn:'position.name:object:string'">
              <div class="field">
                <div class="ui right labeled input">
                  <input type="text" [disabled]="!userService.checkPermissions(['position-cloth-price', '/'], ['write'])" [(ngModel)]="price.cost" (change)="savePrice(price, cloth)">
                  <div class="ui label">
                    СС
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="ui right labeled input">
                  <input type="text" [disabled]="!userService.checkPermissions(['position-cloth-price', '/'], ['write'])" [(ngModel)]="price.price" (change)="savePrice(price, cloth)">
                  <div class="ui label">
                    Цена
                  </div>
                </div>
              </div>
            </td>
            <td>
              <i class="ui red remove cursor icon" *ngIf="userService.checkPermissions(['position-cloth', '/'], ['write'])" (click)="remove(cloth)"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
