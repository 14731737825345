<ng-template let-option #clientTypeOptionTemplate>
  <div>{{option.name}}</div>
</ng-template>
<sui-select class="fluid selection mx-auto"
            placeholder="Тип клиента"
            [isSearchable]="true"
            [isDisabled]="false"
            [(ngModel)]="selectedClientType"
            [ngModelOptions]="{name: 'clientType'}"
            (ngModelChange)="save()"
            [options]="clientTypes"
            labelField="name"
            [optionTemplate]="clientTypeOptionTemplate"
            #selectClientType>
  <sui-select-option *ngFor="let clientType of selectClientType.filteredOptions | orderByColumn:'name:string'"
                     [value]="clientType">
  </sui-select-option>
</sui-select>
