<div class="ui stackable grid">
  <div class="ui eight wide column">
    <h3>Шаблоны договоров</h3>
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui form">
        <table class="ui very basic selectable celled table">
          <thead>
          <tr>
            <th>
              <div class="head">Название</div>
            </th>
            <th>
              <div class="head">Файл</div>
            </th>
          </tr>
          </thead>
          <tbody *ngFor="let p of templates | orderByColumn:'name:string'">
          <tr>
            <td width="50%">
              <div class="field">
                <div class="ui input">
                  <input type="text" [(ngModel)]="p.name" (change)="save(p)">
                </div>
              </div>
            </td>
            <td>
              <div class="field">
                <div *ngIf="p.file">
                  <e2b-file [file]="p.file" [onDelete]="onDelete"></e2b-file>
                </div>
                <div class="ui input">
                  <input type="file" id="file{{p.id}}" name="file" #fileFieldForm (change)="p.files = fileFieldForm.files; update(p);">
                  <label for="file{{p.id}}">
                    <span *ngIf="!p.files">Прикрепить файл</span>
                    <span *ngIf="p.files">Файл выбран: {{p.files[0].name}}</span>
                  </label>
                </div>
              </div>
            </td>
            <td>
              <i class="ui red remove cursor icon" (click)="remove(p)"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
