export enum NotificationTypeEnum {
  DEAL_COMMENT = 'DEAL_COMMENT',
  PROBLEM = 'PROBLEM',
  RECLAMATION = 'RECLAMATION',
  TASK_COMMENT = 'TASK_COMMENT',
  TASK_STATUS = 'TASK_STATUS',
  DEAL_REMINDER = 'DEAL_REMINDER',
  TASK_REMINDER = 'TASK_REMINDER',
  PERSONAL = 'PERSONAL',
  PROJECT_COMMENT = 'PROJECT_COMMENT',
  PROJECT_STATUS = 'PROJECT_STATUS',
  PROJECT_REMINDER = 'PROJECT_REMINDER',
  PROJECT_CHECKLIST = 'PROJECT_CHECKLIST',
  PROJECT_CHECKLIST_REMINDER = 'PROJECT_CHECKLIST_REMINDER',
  WHATSAPP = 'WHATSAPP',
  PERSONAL_WHATSAPP = 'PERSONAL_WHATSAPP'
}
