import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateDiff'
})

@Injectable()
export class DateDiffPipe implements PipeTransform {
  transform(date: string): any {
    return moment().diff(moment(date), 'minutes');
  }
}
