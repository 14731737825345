import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { SuiModalService } from 'ng2-semantic-ui';
import { SuiSelect } from 'ng2-semantic-ui/dist';
import { DndDropEvent } from 'ngx-drag-drop';
import { interval, Subscription } from 'rxjs';
import { Call } from '../../_models/call.model';
import { ClientContact } from '../../_models/client-contact.model';
import { ClientFile } from '../../_models/client-file.model';
import { Client } from '../../_models/client.model';
import { Comment } from '../../_models/comment.model';
import { DealFieldInterface } from '../../_models/deal-field-interface';
import { DealFieldValue } from '../../_models/deal-field-value.model';
import { DealFile } from '../../_models/deal-file.model';
import { DealHistory } from '../../_models/deal-history.model';
import { DealPositionValue } from '../../_models/deal-position-value.model';
import { DealPosition } from '../../_models/deal-position.model';
import { DealStatus } from '../../_models/deal-status.model';
import { Deal } from '../../_models/deal.model';
import { CommentFilter } from '../../_models/enums/comment-filter.enum';
import { CommentType } from '../../_models/enums/comment-type.enum';
import { FileSigningStatus } from '../../_models/enums/file-signing-status.enum';
import { Filial } from '../../_models/filial.model';
import { Person } from '../../_models/person.model';
import { PositionGroup } from '../../_models/position-group.model';
import { Position } from '../../_models/position.model';
import { Responsible } from '../../_models/responsible.model';
import { Todo } from '../../_models/todo.model';
import { Transition } from '../../_models/transition.model';
import { UserGroup } from '../../_models/user-group.model';
import { User } from '../../_models/user.model';
import { AddressService } from '../../_services/address.service';
import { ClientService } from '../../_services/client.service';
import { CommentService } from '../../_services/comment.service';
import { ContactService } from '../../_services/contact.service';
import { DealFieldValueService } from '../../_services/deal-field-value.service';
import { DealPositionValueService } from '../../_services/deal-position-value.service';
import { DealPositionService } from '../../_services/deal-position.service';
import { DealStatusService } from '../../_services/deal-status.service';
import { DealService } from '../../_services/deal.service';
import { FileInterface, FileService } from '../../_services/file.service';
import { NotificationService } from '../../_services/notification.service';
import { PersonService } from '../../_services/person.service';
import { PositionService } from '../../_services/position.service';
import { TodoService } from '../../_services/todo.service';
import { UserActivityService } from '../../_services/user-activity.service';
import { UserGroupService } from '../../_services/user-group.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';
import { ErrorService } from '../../error/error.service';
import { OrderModeService } from '../order/order-mode.service';
import { OrderType } from '../../_models/enums/order-type.enum';
import { SmsTemplate } from '../../_models/sms-template.model';

@Component({
  selector: 'e2b-deal-show',
  templateUrl: './deal-show.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/card.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/accordion.min.css',
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/tab.min.css',
    '../../../assets/design/semantic-ui/components/list.min.css',
    '../../../assets/design/semantic-ui/components/message.min.css',
    '../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../assets/design/semantic-ui/components/segment.min.css',
    '../../../assets/design/semantic-ui/components/icon.min.css',
    '../../../assets/design/semantic-ui/components/button.min.css',
    '../../../assets/design/semantic-ui/components/feed.min.css',
    '../../../assets/design/semantic-ui/components/table.min.css',
    './deal-show.component.scss'],
  animations: [
    trigger('divState', [
      state('in', style({ backgroundColor: 'red', transform: 'translateX(0)' })),

      transition('void => highlighted', [
        animate(2000, keyframes([
          style({ opacity: 0, transform: 'translateX(-100%)', offset: 0 }),
          style({ backgroundColor: '#eeffe4', opacity: 1, transform: 'translateX(15px)', offset: 0.3 }),
          style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 })
        ]))
      ]),
      transition('* => void', [
        animate(300, keyframes([
          style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
          style({ opacity: 1, transform: 'translateX(-15px)', offset: 0.7 }),
          style({ opacity: 0, transform: 'translateX(100%)', offset: 1.0 })
        ]))
      ])
    ])
  ]
})
export class DealShowComponent implements OnInit, OnDestroy {
  activeTabComment = true;
  activeTabFile = false;
  activeTabHistory = false;
  addContact = new EventEmitter<ClientContact>();
  updateContact = new EventEmitter<void>();
  addDealPosition: EventEmitter<DealPosition> = new EventEmitter<DealPosition>();
  auditors: any[] = [];
  calculatedCoefficient: any = {
    id: null,
    data: {
      use: 0, all: 0, mountUse: 0, mountAll: 0
    }
  };
  calls: Call[] = [];
  clients: Client[] = [];
  comments: Comment[] = [];
  coordinatorFields = [
    'Производство: Мягкая мебель',
    'Производство: Корпусная мебель',
    'Производство: Матрас',
    'Производство: Кастомизация',
    'Производство: Заказ принят (корпусная)',
    'Производство: Заказ готов (корпусная)',
    'Производство: Заказ отгружен (корпусная)',
    'Производство: Заказ принят (мягкая)',
    'Производство: Заказ готов (мягкая)',
    'Производство: Заказ отгружен (мягкая)',
    'Производство: Заказ принят (Матрас)',
    'Производство: Заказ готов (Матрас)',
    'Производство: Заказ отгружен (Матрас)',
    'Производство: Дата готовности заказа',
    'Производство: Заказ готов (кастомизация)',
    'Производство: Заказ принят (кастомизация)',
    'Производство: Заказ отгружен (кастомизация)',
    'Доставка и монтаж: Доставщик',
    'Доставка и монтаж: Монтажник',
    'Доставка и монтаж: Дата и время доставки',
    'Доставка и монтаж: Дата монтажа',
    'Доставка и монтаж: Дата монтажа 2',
    'Доставка и монтаж: Дата монтажа 3',
    'Доставка и монтаж: Дата и время монтажа',
    'Доставка и монтаж: Дата и время монтажа 2',
    'Доставка и монтаж: Дата и время монтажа 3'];
  deal: Deal = new Deal();
  dealCurrentPosition: number = null;
  documentSortingGroup = 'date:date';
  documents: any = [];
  editAuditors = false;
  editClient = false;
  editDealPerson = false;
  editDealStatus = false;
  editDescription = false;
  editDescriptionFocus = false;
  editFilial = false;
  editManager = false;
  editManagerHelper = false;
  editOrg = false;
  editSubManager = false;
  employees: User[] = [];
  err = false;
  fields: DealFieldInterface[];

  @ViewChild('selectAddress', { static: true })
  selectAddress: SuiSelect<any, any>;

  public files: DealFile[] = [];
  public clientFiles: ClientFile[] = [];
  finance: number = null;
  financeCredit: number;
  financeCreditPercent: number;
  financeHighlighting = '';
  financePercent: number = null;
  finances: {
    plan: number;
    fact: number;
    percent: number;
  } = { plan: 0, fact: 0, percent: 0 };
  groups: any[] = [];
  history: DealHistory[] = [];
  id: number;
  initialValues: string;
  isCoordinator = false;
  isProjection = false;
  mainContact: ClientContact = new ClientContact();
  mappedValues: any = {};
  mountingDateOptions = [];
  mountingDates = [];
  mountingDatesError = null;
  mountingDatetime: any = null;
  shippingDatetime: any = null;
  mountingUser: any = null;
  navigationSubscription: Subscription;
  newReclamation: any = null;
  onSelectPosition: EventEmitter<DealPosition> = new EventEmitter<DealPosition>();
  onSavePosition: EventEmitter<any> = new EventEmitter<any>();
  openedProblemMenu: any = null;
  originalValues: any = {};
  persons: Person[] = [];
  positionSpecification: any = {};
  positionValues: {
    group: PositionGroup | null,
    position: any[],
    mounting: any[],
    production: any[],
    problem: any[],
    reclamation: any,
  } = {
    group: null,
    position: [],
    mounting: [],
    production: [],
    problem: [],
    reclamation: {},
  };
  positions: Position[] = [];
  projectionFields = [
    'Доставка и монтаж: Дата монтажа',
    'Доставка и монтаж: Дата монтажа 2',
    'Доставка и монтаж: Дата монтажа 3',
    'Доставка и монтаж: Дата и время монтажа',
    'Доставка и монтаж: Дата и время монтажа 2',
    'Доставка и монтаж: Дата и время монтажа 3',
    'Производство: Дата готовности заказа',
    'Производство: Мягкая мебель',
    'Производство: Корпусная мебель',
    'Производство: Матрас',
    'Производство: Кастомизация',
    'Спецификация: Материал фасад',
    'Спецификация: Материал каркаса',
    'Спецификация: Индивидуальные изменения',
    'Проектирование: Спецификации и чертежи'
  ];
  removeDealPosition: EventEmitter<DealPosition> = new EventEmitter<DealPosition>();
  requiredFields: any[] = [];
  addressSearch: (query: any) => Promise<unknown>;
  savingReclamation = false;
  selectAuditors: EventEmitter<UserGroup[]> = new EventEmitter<UserGroup[]>();
  selectClient: EventEmitter<Client> = new EventEmitter<Client>();
  selectFilial: EventEmitter<Filial> = new EventEmitter<Filial>();
  selectManager: EventEmitter<User> = new EventEmitter<User>();
  selectManagerHelper: EventEmitter<User> = new EventEmitter<User>();
  selectOrganizationPerson: EventEmitter<Person> = new EventEmitter<Person>();
  selectPerson: EventEmitter<Person> = new EventEmitter<Person>();
  selectSubManager: EventEmitter<User> = new EventEmitter<User>();
  selectedMountingDate = null;
  selectedPosition: DealPosition & any = null;
  selectedShippingDate = null;
  shippingDates = [];
  shippingDatesError = null;
  state = 'normal';
  statusError: any = null;
  statusRelation: any = {};
  statuses: any[];
  todos: Todo[] = [];
  user: any = {};

  @ViewChild('addressSearch', { static: true })
  addressSearchInput;

  @ViewChild('addressSearch2', { static: true })
  addressSearchInput2;

  private dealSubscribe: Subscription;
  private employeeSubscribe: Subscription;
  @ViewChild('commentSection', { static: false })
  private commentSection;
  @ViewChild('fileForm', { static: false })
  private fileForm;
  @ViewChild('fileReclamationFieldForm', { static: false })
  private fileReclamationFieldForm;
  @ViewChild('fileReclamationOtherFieldForm', { static: false })
  private fileReclamationOtherFieldForm;
  @ViewChild('selectAuditor', { static: false })
  private selectAuditor;
  private todoSubscribe: Subscription;
  searchResults: any[] = [];
  fileDragging = false;
  lkLinkCopied = false;

  commentFilter: CommentFilter = CommentFilter.all;
  whatsAppCheckTimestamp: number;
  whatsAppCheckInterval: Subscription;
  userBecomeActiveSubscription: Subscription;
  ordersActive = false;

  smsTemplates: SmsTemplate[] = [];

  constructor(private actionService: ActionService,
              private addressService: AddressService,
              private clientService: ClientService,
              private commentService: CommentService,
              private contactService: ContactService,
              private dealFieldValueService: DealFieldValueService,
              private dealPositionService: DealPositionService,
              private dealPositionValueService: DealPositionValueService,
              private dealService: DealService,
              private dealStatusService: DealStatusService,
              private employeeService: UserService,
              private errorService: ErrorService,
              private fileService: FileService,
              private modalService: SuiModalService,
              private notificationService: NotificationService,
              private orderModeService: OrderModeService,
              private personService: PersonService,
              private positionService: PositionService,
              private ref: ChangeDetectorRef,
              private router: Router,
              private titleService: Title,
              private todoService: TodoService,
              private userActivityService: UserActivityService,
              private userGroupService: UserGroupService,
              private userService: UserService,
              private route: ActivatedRoute) {
    ref.detach();
    setInterval(() => {
      if (!this.ref['destroyed']) {
        this.ref.detectChanges();
      }
    }, 3);

  }

  get CommentType() {
    return CommentType;
  }

  auditorsChanged() {
    const auditorGroupIds = [];
    const auditorUserIds = [];

    const dealResponsibleGroupIds = [];
    const dealResponsibleUserIds = [];

    this.deal.responsibles.map(responsible => {
      if (responsible.group) {
        dealResponsibleGroupIds.push(responsible.group.id);
      }
      if (responsible.user) {
        dealResponsibleUserIds.push(responsible.user.id);
      }
    });

    this.auditors.map(auditor => {
      if (auditor.users) {
        auditorGroupIds.push(auditor.id);
      }
      if (!auditor.users) {
        auditorUserIds.push(auditor.id);
      }
    });

    this.auditors.map((auditor: any) => {
      if ((auditor.users && dealResponsibleGroupIds.indexOf(auditor.id) === -1) || (!auditor.users && dealResponsibleUserIds.indexOf(auditor.id) === -1)) {
        this.dealService.addResponsible(this.deal.id, auditor).subscribe((added: Responsible) => {
          this.deal.responsibles.push(added);
        }, e => {
          this.errorService.addError.emit({
            title: 'Ошибка при сохранении аудиторов',
            message: e.error && e.error.message ? e.error.message : e.message
          });
        });
      }
    });

    this.deal.responsibles.map(responsible => {
      if ((responsible.group && auditorGroupIds.indexOf(responsible.group.id) === -1) || (responsible.user && auditorUserIds.indexOf(responsible.user.id) === -1)) {
        this.dealService.removeResponsible(responsible.id).subscribe(() => {
          this.deal.responsibles.splice(this.deal.responsibles.indexOf(responsible), 1);
        }, e => {
          this.errorService.addError.emit({
            title: 'Ошибка при сохранении аудиторов',
            message: e.error && e.error.message ? e.error.message : e.message
          });
        });
      }
    });
    this.editAuditors = false;
  }

  calcFinance() {
    if (!this.deal.values) {
      return false;
    }
    let price = 0;
    const o1 = this.deal.values.find(value => value.field.name === 'Финансы: Остаток');
    const o2 = this.deal.values.find(value => value.field.name === 'Договор: Предоплата');
    const o3 = this.deal.values.find(value => value.field.name === 'Договор: Рассрочка, руб.');
    const o4 = o3 ? (o3.value / 100 * 5) : 0;
    if (this.deal.positions) {
      this.deal.positions.map(position => {
        price += (position.price * position.count);
        if (position.discountPrice) {
          price -= position.discountPrice;
        }

        if (position.positionValues) {
          const personalChanges = position.positionValues.find(pv => pv.field.name === 'Инд изменения 1');
          if (personalChanges && personalChanges.value && (personalChanges.value as Array<any>).length) {
            const personalChangesPrice = (personalChanges.value as Array<any>).reduce((sum, value) => sum + value.price, 0);
            position.personalChangesCost = personalChangesPrice
              ? personalChangesPrice < 7
                ? Math.floor((position.count * position.price) / ((1 / 0.05) + 1))
                : Math.floor((position.count * position.price) / ((1 / 0.1) + 1))
              : 0;
          } else {
            position.personalChangesCost = 0;
          }
        }

      });
    }
    this.finance = price - ((o1 ? o1.value : 0) + (o2 ? o2.value : 0));
    if (o3 && o4) {
      this.finance = this.finance - o3.value;
      this.financeCredit = o3.value;
      this.financeCreditPercent = o4;
    }
    if (this.finance > 0 && this.finance < price) {
      this.financePercent = +((this.finance / price) * 100).toFixed();
      this.financeHighlighting = this.financePercent > 35 ? 'finance-red' : 'finance-green';
    }
  }

  changeCloth(value) {
    if (this.selectedPosition.position.type === 2) {
      this.dealPositionValueService.updateClothPrice(this.selectedPosition.id, this.selectedPosition.position.id, value).subscribe(r => {
        if (r.price) {
          this.selectedPosition.price = r.price;
          const dealPosition = this.deal.positions.find(p => p.id === this.selectedPosition.id);
          if (dealPosition.discountPercent) {
            this.selectedPosition.discountPrice = (this.selectedPosition.price / 100) * dealPosition.discountPercent;
            this.selectedPosition.discountPercent = dealPosition.discountPercent;
            dealPosition.discountPrice = this.selectedPosition.discountPrice;
          }
          this.dealPositionService.update(
            <DealPosition>{
              id: this.selectedPosition.id,
              price: this.selectedPosition.price,
              discountPrice: this.selectedPosition.discountPrice,
              discountPercent: this.selectedPosition.discountPercent
            }).subscribe(() => {
          });
          this.dealService.update({ id: this.deal.id, price: this.totalDealSum() } as Deal).subscribe({
            next: () => {
            },
            error: console.error
          });
        }
      });
    }
  }

  changeFiles(files) {
    this.fileService.upload(files).then((f: FileInterface[]) => {
      f.map(file => {
        this.dealService.addFile(this.deal.id, file).subscribe(dealFile => {
          this.files.unshift(dealFile);
          this.activeTabFile = true;
        });
      });
      this.fileForm.nativeElement.value = '';
    });
  }

  changePersonal(value) {
    if (this.selectedPosition.position.type === 1) {
      this.dealPositionValueService.updatePersonalPrice(this.selectedPosition.position.id, value).subscribe(r => {
        if (r.price) {
          this.selectedPosition.price = r.price;
          const dealPosition = this.deal.positions.find(p => p.id === this.selectedPosition.id);
          dealPosition.price = r.price;
          if (dealPosition.discountPercent) {
            this.selectedPosition.discountPrice = (this.selectedPosition.price / 100) * dealPosition.discountPercent;
            this.selectedPosition.discountPercent = dealPosition.discountPercent;
            dealPosition.discountPrice = this.selectedPosition.discountPrice;
          }
          this.dealPositionService.update(
            <DealPosition>{
              id: this.selectedPosition.id,
              price: this.selectedPosition.price,
              discountPrice: this.selectedPosition.discountPrice,
              discountPercent: this.selectedPosition.discountPercent
            }).subscribe(() => {
          });
          this.dealService.update({ id: this.deal.id, price: this.totalDealSum() } as Deal).subscribe({
            next: () => {
            },
            error: console.error
          });
        }
      });
    }
  }

  editDescriptionChange() {
    this.editDescription = !this.editDescription;
    this.editDescriptionFocus = true;
  }

  editDescriptionTrigger() {
    this.dealService.update<Deal>(<Deal>{ id: this.deal.id, description: this.deal.description }).subscribe(() => {
      this.editDescription = false;
      this.editDescriptionFocus = false;
    }, e => {
      this.errorService.addError.emit({
        title: 'Ошибка при сохранении описания',
        message: e.error && e.error.message ? e.error.message : e.message
      });
    });
  }

  editMappedValue(id: number): boolean {
    if (this.mappedValues[id].field.type === 'STEP') {
      return false;
    }
    if (this.mappedValues[id].field.type === 'ADDRESS' && this.mappedValues[id].value) {
      if (this.addressSearchInput) {
        this.addressSearchInput.query = this.mappedValues[id].value;
      }
      if (this.addressSearchInput2) {
        this.addressSearchInput2.query = this.mappedValues[id].value;
      }
    }
    if (this.userService.checkFieldPermission(id, ['write'])) {
      return this.mappedValues[id].edit = true;
    }
    return false;
  }

  fetchDeal() {
    this.auditors = [];
    this.groups = [];

    this.dealSubscribe = this.dealService.findById<Deal>(this.id).subscribe(deal => {
      this.deal = deal;
      /**
       * Check status
       */
      if (deal.currentStatus && [1, 2, 4, 5, 6, 20].indexOf(deal.currentStatus.id) > -1) {
        this.loadTodos();
      }

      this.loadPositions();

      this.dealStatusService.relations(this.deal.currentStatus.id).subscribe(statusRelation => {
        this.statusRelation = statusRelation;
      });

      this.setTitle(`${deal.name ? deal.name.substr(9) : 'Olissys E2B'}`);

      this.mainContact = this.deal.client.contacts.find(contact => contact.isMain);
      this.loadComments();

      this.employeeSubscribe = this.employeeService.find<User>().subscribe(employees => {
        this.employees = employees;
        this.groups = this.groups.concat(employees);
        const userIds: any = {};
        deal.responsibles.map(r => {
          if (r.user) {
            userIds[r.user.id] = true;
          }
        });
        employees.map(e => {
          if (userIds[e.id]) {
            this.auditors.push(e);
          }
        });
      });

      this.fetchFields();

      this.personService.findByClientId(deal.client.id).subscribe(persons => {
        this.persons = persons;
      });

      this.userGroupService.find<UserGroup>().subscribe(groups => {
        this.groups = this.groups.concat(groups);
        const groupIds: any = {};
        deal.responsibles.map(r => {
          if (r.group) {
            groupIds[r.group.id] = true;
          }
        });
        groups.map(e => {
          if (groupIds[e.id]) {
            this.auditors.push(e);
          }
        });
      });

      this.notificationService.update({ id: this.deal.id }).subscribe(() => {
      }, console.error);

    }, () => {
      this.router.navigate(['/deal/list']).catch(console.error);
    });
  }

  fetchFields() {
    this.dealService.fieldFindById<DealFieldInterface[]>(this.deal.currentStatus.id).subscribe(fields => {
      this.fields = fields;
      fields.map(f => {
        f.childrens.map(c => {
          this.mappedValues[c.id] = {
            deal: {
              id: this.id
            },
            event: new EventEmitter(),
            field: c
          };
          this.mappedValues[c.id].event.subscribe(v => {
            this.mappedValues[c.id].value = v;
            this.onMappedValueBlur(c.id);
          });
        });
      });
      this.mountingDatetime = this.deal.values.find(value => value.field.name === 'Доставка и монтаж: Дата монтажа');
      this.shippingDatetime = this.deal.values.find(value => value.field.name === 'Доставка и монтаж: Дата отгрузки');
      this.calcFinance();

      this.deal.values.map((value: any) => {
        if (value.field.type === 'DATE') {
          value.value = moment(value.value, 'DD.MM.YYYY').toDate();
        } else if (value.field.type === 'DATETIME') {
          value.value = moment(value.value, 'DD.MM.YYYY HH:mm:ss').toDate();
        } else if (value.field.type === 'USER') {
          value.value = JSON.parse(<string>value.value);
        } else if (value.field.type === 'USERS') {
          value.value = JSON.parse(<string>value.value);
        } else if (value.field.type === 'USERGROUP') {
          value.value = value.value ? JSON.parse(<string>value.value) : [];
        } else if (value.field.type === 'USERGROUP_SINGLE') {
          value.value = value.value ? JSON.parse(<string>value.value) : [];
        }
        this.originalValues[value.field.id] = {
          value: value.value
        };

        if (!this.mappedValues[value.field.id]) {
          this.mappedValues[value.field.id] = {
            deal: {
              id: this.id
            },
            event: new EventEmitter(),
            field: value.field
          };
        }

        if (value.field.name === 'Доставка и монтаж: Координатор') {
          value.value.map(user => {
            if (user.id === this.userService.current().id) {
              this.isCoordinator = true;
            }
          });
        } else if (value.field.name === 'Проектирование: Конструкторы') {
          value.value.map(user => {
            if (user.id === this.userService.current().id) {
              this.isProjection = true;
            }
          });
        } else if (value.field.name === 'Доставка и монтаж: Монтажник') {
          value.value.map(user => {
            this.mountingUser = user;
          });
        }

        this.mappedValues[value.field.id].id = value.id;
        this.mappedValues[value.field.id].deal = { id: this.id };
        this.mappedValues[value.field.id].value = value.value;
        this.mappedValues[value.field.id].event = new EventEmitter();

        this.mappedValues[value.field.id].event.subscribe(v => {
          this.mappedValues[value.field.id].value = v;
          this.onMappedValueBlur(value.field.id);
        });
      });
      this.requiredFields = [];
      Object.keys(this.mappedValues).map(id => {
        this.deal.currentStatus.requiredFields.map(field => {
          if (id === field.id.toString()) {
            this.requiredFields.push({
              id: this.mappedValues[field.id].id,
              name: field.name,
              type: 'DEAL',
              field: {
                id: field.id,
                name: field.name,
                sort: this.mappedValues[field.id].field.sort,
                type: this.mappedValues[field.id].field.type,
                values: this.mappedValues[field.id].field.values
              },
              value: this.mappedValues[field.id].value,
              deal: this.mappedValues[field.id].deal,
              event: this.mappedValues[field.id].event
            });
          }
        });
      });
      this.requiredFields.sort((left, right) => {
        if (left.name < right.name) {
          return -1;
        }
        if (left.name > right.name) {
          return 1;
        }
        return 0;
      });
    });
  }

  getMountingDates() {
    this.dealService.getMountingDate({ dealId: this.deal.id, dateCount: 14 }).subscribe(result => {
      this.mountingDates = result;
      this.mountingDatesError = null;
    }, e => {
      console.log('Error', e);
      this.mountingDatesError = e.error;
    });
  }

  getShippingDates() {
    this.dealService.getShippingDate({ dealId: this.deal.id, dateCount: 14 }).subscribe(result => {
      this.shippingDates = result;
      this.shippingDatesError = null;
    }, e => {
      console.log('Error', e);
      this.shippingDatesError = e.error;
    });
  }

  identify(index, obj) {
    return obj ? obj.id : undefined;
  }

  loadCalls() {
    this.calls = [];
    this.files = [];
    this.clientFiles = [];
    this.history = [];
    this.dealService.calls<Call>(this.deal.id).subscribe(calls => {
      this.calls = calls;
    });
  }

  loadClients() {
    this.clientService.find<Client>({ list: true }).subscribe(clients => {
      this.clients = clients;
    });
  }

  loadComments() {
    this.comments = [];
    this.files = [];
    this.clientFiles = [];
    this.history = [];

    this.commentService.findForDeal<Comment[]>(this.id).subscribe(comments => {
      this.comments = this.comments.concat(comments);
    });

    if (this.mainContact && this.mainContact.id && this.userService.checkPermissions(['deal', 'whatsapp', 'message', '/'])) {
      this.loadWhatsAppComments();
    }
  }

  loadWhatsAppComments() {
    this.commentService.findForDealWhatsApp<{ lastCheckTimestamp: number, messages: Comment[] }>(this.mainContact.id).subscribe(res => {
      this.comments = this.comments.concat(res.messages);
      this.whatsAppCheckTimestamp = res.lastCheckTimestamp;
    });
  }

  initWhatsAppCheckInterval() {
    if (this.whatsAppCheckInterval) {
      this.whatsAppCheckInterval.unsubscribe();
    }

    this.whatsAppCheckInterval = interval(5000).subscribe(() => {
      if (this.commentSection.commentFilter === CommentFilter.whatsapp && this.userActivityService.inactiveMinutes < 3) {
        this.loadUpdatedWhatsAppMessages();
      }
    });
  }

  loadUpdatedWhatsAppMessages() {
    this.commentService.checkWhatsAppUpdate(this.mainContact.id, { lastCheckTimestamp: this.whatsAppCheckTimestamp }).subscribe(res => {
      this.whatsAppCheckTimestamp = res.lastCheckTimestamp;
      if (res.messages.length) {
        const whatsappMessages = this.comments.filter(comment => comment.whatsapp);
        res.messages.map(message => {
          const messageToUpdate = whatsappMessages.find(m => m.id === message.id);
          if (messageToUpdate) {
            messageToUpdate.status = message.status;
            messageToUpdate.created = message.created;
          } else {
            message.state = 'highlighted';
            this.comments.unshift(message);
            setTimeout(() => message.state = '', 4000);
          }
        });
        this.comments = [...this.comments];
      }
    });
  }

  setCommentFilter(commentFilter: CommentFilter) {
    this.commentFilter = commentFilter;
    if (this.commentFilter === CommentFilter.whatsapp) {
      this.initWhatsAppCheckInterval();
    } else {
      if (this.whatsAppCheckInterval) {
        this.whatsAppCheckInterval.unsubscribe();
      }
    }
  }

  addComment(comment: Comment) {
    this.comments = [comment, ...this.comments];
  }

  loadFiles() {
    this.files = [];
    this.clientFiles = [];
    this.history = [];
    this.dealService.files(this.deal.id).subscribe(files => {
      this.files = files;
    });
    this.dealService.clientFiles(this.deal.id).subscribe(files => {
      this.clientFiles = files;
    });
  }

  loadFinance() {
    if (this.deal && this.deal.values) {
      let r = 0;
      this.deal.values.map((value: any) => {
        if (['Себестоимость: Диван', 'Себестоимость: Матрас', 'Себестоимость: Корпусная мебель', 'Себестоимость: Доп производство', 'Себестоимость: Комплектующие'].indexOf(value.field.name) > -1) {
          r += parseFloat(value.value);
        }
      });
      this.finances.plan = r;
    }
    this.dealService.finance(this.id).subscribe(r => {
      let r1 = 0;
      r.map(d => {
        if (['Расходный кассовый ордер', 'Списание с расчетного счета'].indexOf(d.type) > -1) {
          r1 += d.amount;
        }
      });
      this.finances.fact = r1;
      this.finances.percent = this.finances.fact && this.finances.plan > 0 ? (this.finances.fact / this.finances.plan) * 100 : 0;
      this.documents = r;
    });
  }

  loadHistory() {
    this.files = [];
    this.history = [];
    this.dealService.history(this.deal.id).subscribe(history => {
      this.history = history;
    });
  }

  loadPersons() {
    this.personService.findByClientId(this.deal.client.id).subscribe(persons => {
      this.persons = persons;
    });
  }

  loadPositions() {
    if (this.positions.length === 0) {
      this.positionService.find<Position>().subscribe(positions => this.positions = positions, console.error);
    }
    if (this.addDealPosition.observers.length === 0) {
      this.addDealPosition.subscribe(position => {
        position.deal = {
          id: this.id
        };
        this.dealPositionService.create<DealPosition>(position).subscribe(update => {
          if (!this.deal.positions) {
            this.deal.positions = [];
          }
          this.deal.positions.push(update);
        }, e => {
          console.error(e);
          this.errorService.addError.emit({
            title: 'Ошибка при добавлении товара',
            message: e.error && e.error.message ? e.error.message : e.message
          });
          this.deal.positions.splice(this.deal.positions.indexOf(position), 1);
        });
      });
    }
    if (this.removeDealPosition.observers.length === 0) {
      this.removeDealPosition.subscribe(position => {
        const confirm = window.confirm(`Вы уверены, что хотите удалить ${position.position.name}?`);
        if (confirm) {
          this.dealPositionService.deleteById<DealPosition>(position.id).subscribe(() => {
            this.deal.positions.splice(this.deal.positions.indexOf(position), 1);
          }, e => {
            this.errorService.addError.emit({
              title: 'Ошибка при удалении позиции',
              message: e.error && e.error.message ? e.error.message : e.message
            });
          });
        }
      });
    }
  }

  loadTodos() {
    if (!this.todos.length) {
      this.todoSubscribe = this.todoService.findByDealId<Todo>(this.id).subscribe(todos => {
        this.todos = todos;
      });
    }
  }

  ngOnDestroy() {
    if (this.titleService) {
      this.titleService.setTitle('Olissys E2B');
    }
    if (this.dealSubscribe) {
      this.dealSubscribe.unsubscribe();
    }
    if (this.todoSubscribe) {
      this.todoSubscribe.unsubscribe();
    }
    if (this.employeeSubscribe) {
      this.employeeSubscribe.unsubscribe();
    }
    if (this.addDealPosition) {
      this.addDealPosition.unsubscribe();
    }
    if (this.removeDealPosition) {
      this.removeDealPosition.unsubscribe();
    }
    if (this.selectManager) {
      this.selectManager.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.whatsAppCheckInterval) {
      this.whatsAppCheckInterval.unsubscribe();
    }
    if (this.userBecomeActiveSubscription) {
      this.userBecomeActiveSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.addressSearch = async (query) => {
      if (query && query.length > 3) {
        return new Promise(resolve => {
          this.addressService.search(query).then(results => {
            return resolve(results);
          }).catch(console.error);
        });
      } else {
        return Promise.resolve([]);
      }
    };

    this.mountingDatesError = null;

    this.user = this.userService.current();

    this.dealService.smsTemplates().subscribe(smsTemplates => {
      this.smsTemplates = smsTemplates;
    });

    this.route.params.subscribe(params => {
      this.id = parseInt(params.id, 0);

      this.deal.client = new Client();
      this.deal.manager = new User();
      this.deal.currentStatus = new DealStatus();
      this.deal.responsibles = Array<Responsible>();
      this.deal.client.contacts = Array<ClientContact>();
      this.mainContact = null;
      this.commentFilter = CommentFilter.all;
      this.todos = [];

      this.selectAuditors.subscribe(auditors => {
        this.auditors = auditors;
        this.auditorsChanged();
      });

      this.onSavePosition.subscribe(() => {
        this.calcFinance();
      });

      this.addContact.subscribe(contact => {
        contact.client = new Client();
        contact.client.id = this.deal.client.id;
        this.contactService.create<ClientContact>(contact).subscribe(() => {
          setTimeout(() => {
            this.fetchDeal();
          }, 500);
        }, e => {
          this.errorService.addError.emit({
            title: 'Ошибка при добавлении контакта',
            message: e.error && e.error.message ? e.error.message : e.message
          });
        });
      });
      this.updateContact.subscribe(() => {
        this.deal.client.contacts = [...this.deal.client.contacts];
        this.mainContact = this.deal.client.contacts.find(contact => contact.isMain);
      });
      this.selectedPosition = null;
      this.dealCurrentPosition = null;
      this.onSelectPosition.subscribe((position) => {

        this.positions.map(dp => {
          if (dp.id === position.id) {
            position = dp;
          }
        });

        this.positionValues = {
          group: null,
          position: [],
          mounting: [],
          production: [],
          problem: [],
          reclamation: {},
        };
        const reclamationValue: any = {};
        this.newReclamation = null;
        this.dealPositionValueService.values(position.id).subscribe(values => {
          if (values.reclamation.value) {
            reclamationValue.id = values.reclamation.id;
            reclamationValue.field = values.reclamation.field;
            reclamationValue.value = [];
            values.reclamation.value.map((v, k) => {
              const copyValue = JSON.parse(JSON.stringify(v));
              if (copyValue.resolveDate) {
                copyValue.resolveDate = moment(copyValue.resolveDate).clone().toDate();
              }
              if (copyValue.responsible && copyValue.responsible.id) {
                values.reclamation.field.users.map(user => {
                  if (copyValue.responsible && user.id === copyValue.responsible.id) {
                    copyValue.responsible = user;
                  }
                });
              }
              if (copyValue.reclamation && copyValue.reclamation.id) {
                const cost = copyValue.reclamation.cost;
                values.reclamation.field.values.map(value => {
                  if (copyValue.reclamation && copyValue.reclamation.id && value.id === copyValue.reclamation.id) {
                    copyValue.reclamation = value;
                    copyValue.reclamation.cost = cost;
                  }
                });
              }
              reclamationValue.value.push(copyValue);
            });
          }

          this.positionValues = {
            group: values.group,
            problem: values.problem,
            production: values.production,
            mounting: values.mounting,
            position: [],
            reclamation: reclamationValue
          };

          this.initialValues = JSON.stringify(reclamationValue.value);

          position.checkedValues = false;

          values.position.map((p: any) => {

            if (p.field.name === 'Дата готовности заказа' && p.value) {
              position.readyDate = p.value;
            }
            if (p.field.name === 'Подрядчик' && p.value) {
              position.employeer = p.value;
            }

            if (p.field.type === 'USER') {
              if (p.value) {
                p.field.values.map(m => {
                  if (p.value.id === m.id) {
                    p.value = m;
                  }
                });
              }
              p.event = new EventEmitter();
              p.event.subscribe(v => {
                p.value = v;
                this.onChangePositionValue(p.field.id);
              });
            } else if (p.field.type === 'MATERIAL') {
              if (p.value) {
                p.field.values.map(m => {
                  if (p.value.id === m.id) {
                    p.value = m;
                  }
                });
              }
            } else if (p.field.type === 'CLOTH') {
              if (p.value) {
                p.field.values.map(m => {
                  if (p.value.id === m.id) {
                    p.value = m;
                  }
                });
              }
            } else if (p.field.type === 'USERGROUP') {
              if (p.value) {
                p.field.values.map(m => {
                  if (p.value.id === m.id) {
                    p.value = m;
                  }
                });
              }
              p.event = new EventEmitter();
              p.event.subscribe(v => {
                p.value = v;
                this.onChangePositionValue(p.field.id);
              });
            } else if (p.field.type === 'PERSONAL') {
              const personal = [];
              if (p.value) {
                p.field.values.map(m => {
                  p.value.map(v => {
                    if (v.id === m.id) {
                      personal.push(m);
                    }
                  });
                });
                p.value = personal;
              }
            } else if (p.field.type === 'DATE' && p.value) {
              p.value = moment(p.value, 'DD.MM.YYYY').toDate();
            } else if (p.field.type === 'DATETIME' && p.value) {
              p.value = moment(p.value, 'DD.MM.YYYY HH:mm:ss').toDate();
            }
            this.positionValues.position.push(p);
          });

          if (position.readyDate && position.employeer) {
            position.checkedValues = true;
          }

          this.selectedPosition = position;
        });
      });

      this.selectManager.subscribe(user => {
        const deal: any = <Deal>{ id: this.deal.id, manager: { id: user.id } };
        const delegateTodo = confirm('Делегировать все дела другому менеджеру?');
        if (delegateTodo) {
          deal.delegateTodos = true;
        }
        this.dealService.update<Deal>(deal).subscribe(() => {
          this.deal.manager = user;

          if (delegateTodo) {
            this.loadTodos();
          }

          this.err = false;
        }, e => {
          this.err = e.error;
        });
        this.editManager = false;
      });

      this.selectSubManager.subscribe(user => {
        this.deal.subManager = user;
        this.dealService.update<Deal>(<Deal>{
          id: this.deal.id,
          subManager: { id: this.deal.subManager.id }
        }).subscribe(() => {
          this.err = false;
        }, e => {
          this.err = e.error;
        });
        this.editSubManager = false;
      });

      this.selectManagerHelper.subscribe(user => {
        this.deal.managerHelper = user;
        this.dealService.update<Deal>(<Deal>{
          id: this.deal.id,
          managerHelper: { id: this.deal.managerHelper.id }
        }).subscribe(() => {
          this.err = false;
        }, e => {
          this.err = e.error;
        });
        this.editManagerHelper = false;
      });

      this.selectClient.subscribe(client => {
        this.deal.client = client;
        this.dealService.update<Deal>(<Deal>{ id: this.deal.id, client: { id: this.deal.client.id } }).subscribe(() => {
          this.err = false;
        }, e => {
          this.err = e.error;
        });
        this.editClient = false;
      });

      this.selectPerson.subscribe(person => {
        this.deal.person = person;
        this.dealService.update<Deal>(<Deal>{ id: this.deal.id, person: { id: this.deal.person.id } }).subscribe(() => {
          this.err = false;
        }, e => {
          this.err = e.error;
        });
        this.editDealPerson = false;
      });

      this.selectFilial.subscribe(filial => {
        this.deal.filial = filial;
        this.dealService.update<Deal>(<Deal>{ id: this.deal.id, filial: { id: this.deal.filial.id } }).subscribe(() => {
          this.err = false;
        }, e => {
          this.err = e.error;
        });
        this.editFilial = false;
      });

      this.selectOrganizationPerson.subscribe(person => {
        this.deal.organization = person;
        this.dealService.update<Deal>(<Deal>{
          id: this.deal.id,
          organization: { id: this.deal.organization.id }
        }).subscribe(() => {
          this.err = false;
        }, e => {
          this.err = e.error;
        });
        this.editOrg = false;
      });

      const removeEvent = new EventEmitter();
      this.actionService.createActionEvent.emit({
        name: 'Удалить сделку',
        event: removeEvent,
        class: 'red',
        icon: 'minus square',
        roles: ['E2B.ROLE.SUPERADMIN']
      });

      const exportEvent = new EventEmitter();
      this.actionService.createActionEvent.emit({
        name: 'В Мегаплан',
        event: exportEvent,
        class: 'green',
        icon: 'share square',
      });

      const export1CEvent = new EventEmitter();
      this.actionService.createActionEvent.emit({
        name: 'В 1C',
        event: export1CEvent,
        class: 'blue',
        icon: 'share square',
      });

      const openWizardSalesEvent = new EventEmitter();
      this.actionService.createActionEvent.emit({
        name: 'Продажи',
        event: openWizardSalesEvent,
        class: 'blue',
        icon: 'plus square',
      });

      const openWizardShowEvent = new EventEmitter();
      this.actionService.createActionEvent.emit({
        name: 'Показ',
        event: openWizardShowEvent,
        class: 'blue',
        icon: 'plus square',
      });

      const updateEvent = new EventEmitter();
      this.actionService.createActionEvent.emit({
        name: 'Обновить',
        event: updateEvent,
        class: 'blue',
        icon: 'sync'
      });

      if (this.userService.checkPermissions(['deal', 'stateUpdate', '/'])) {

        const updateStateEvent = new EventEmitter();

        this.actionService.createActionEvent.emit({
          name: 'Обновить кэш',
          event: updateStateEvent,
          class: 'blue',
          icon: 'sync'
        });

        updateStateEvent.subscribe(() => {
          this.dealService.stateUpdate(this.deal.id).subscribe(deal => {
            this.fetchDeal();
          });
        });
      }

      if (this.userService.checkPermissions(['lk', 'user', 'login', '/'])) {
        const redirectLk = new EventEmitter();

        this.actionService.createActionEvent.emit({
          name: 'В ЛК клиента',
          event: redirectLk,
          class: 'blue',
          icon: 'share square'
        });

        redirectLk.subscribe(() => {
          this.dealService.redirectLk(this.deal.client.id).subscribe(res => {
            localStorage.setItem('e2b.lk.client.token', res.token);
            window.open(`https://e2b.zaoblako.ru/lk/order/card/${this.deal.id}`, '_blank');
          });
        });
      }

      removeEvent.subscribe(() => {
        const yes = confirm('Вы уверены, что хотите удалить сделку ' + this.deal.name + '?');
        if (!yes) {
          return true;
        }
        this.dealService.deleteById(this.deal.id).subscribe(() => {
          this.router.navigate(['/deal/list']).catch(console.error);
        });
      });
      updateEvent.subscribe(() => {
        this.fetchDeal();
      });
      exportEvent.subscribe(() => {
        this.dealService.export(this.id).subscribe(() => {
        }, e => {
          this.errorService.addError.emit({
            title: 'Ошибка при выгрузке в Мегаплан',
            message: e.error && e.error.message ? e.error.message : e.message
          });
        });
      });

      let isExportingTo1C = false;
      export1CEvent.subscribe(() => {
        if (isExportingTo1C) {
          return;
        }
        isExportingTo1C = true;
        this.dealService.export1C(this.id).subscribe(() => {
          isExportingTo1C = false;
        }, e => {
          this.errorService.addError.emit({
            title: 'Ошибка при выгрузке в 1С',
            message: e.error && e.error.message ? e.error.message : e.message
          });
        });
      });

      openWizardSalesEvent.subscribe(() => {
        this.router.navigate(['sales-wizard'], { queryParams: { id: this.id } }).catch(console.error);
      });

      openWizardShowEvent.subscribe(() => {
        this.router.navigate(['show-wizard'], { queryParams: { id: this.id } }).catch(console.error);
      });

      this.userBecomeActiveSubscription = this.userActivityService.userBecomeActive.subscribe(() => {
        if (this.whatsAppCheckTimestamp && this.commentFilter === CommentFilter.whatsapp) {
          this.loadUpdatedWhatsAppMessages();
          this.initWhatsAppCheckInterval();
        }
      });

      this.fetchDeal();
    });
  }

  onChangePositionValue(fieldId) {
    const update: any = {
      field: {
        id: fieldId
      }
    };
    this.positionValues.position.map((p, l) => {
      if (p.field.id === fieldId) {
        update.id = p.id;
        update.dealPosition = p.dealPosition;
        if (p.field.type === 'FILE') {
          this.fileService.upload(p.files).then((f: FileInterface[]) => {
            if (p.value) {
              p.value = p.value.concat(f);
            } else {
              p.value = f;
            }
            this.savePositionValue(p, l);
          });
        } else if (p.field.type === 'DATE') {
          update.value = moment(p.value).format('DD.MM.YYYY');
          this.savePositionValue(update, l);
        } else if (p.field.type === 'DATETIME') {
          update.value = moment(p.value).format('DD.MM.YYYY HH:mm:ss');
          this.savePositionValue(update, l);
        } else {
          update.value = p.value;
          this.savePositionValue(update, l);
        }
      }
    });
  }

  onMappedValueBlur(id) {
    let value = this.mappedValues[id];
    const regex = RegExp(value.field.name);
    if (this.statusError && this.statusError.length > 0) {
      this.statusError.map(error => {
        if (value.field.name && regex.test(error.name)) {
          this.statusError.splice(this.statusError.indexOf(error), 1);
        }
      });
    }
    if (this.originalValues[id] && value.value === this.originalValues[id].value && !value.files) {
      return false;
    }
    delete value['edit'];
    delete value['event'];
    this.originalValues[value.field.id] = {
      value: value.value
    };
    if (value.field.type === 'FILE') {
      this.fileService.upload(value.files).then((f: FileInterface[]) => {
        if (value.value) {
          value.value = value.value.concat(f);
        } else {
          value.value = f;
        }
        this.saveValue(value);
      });
    } else {
      value = JSON.parse(JSON.stringify(value));
      if (this.mappedValues[id].field.type === 'DATE') {
        value.value = moment(this.mappedValues[id].value).format('DD.MM.YYYY');
        if (value.field.name.indexOf('Дата монтажа') > -1) {
          this.calculatedCoefficient.id = null;
          this.dealService.coefficient(value.value).subscribe(c => {
            this.calculatedCoefficient.id = id;
            this.calculatedCoefficient.data = c;
          });
        }
      } else if (this.mappedValues[id].field.type === 'DATETIME') {
        value.value = moment(this.mappedValues[id].value).format('DD.MM.YYYY HH:mm:ss');
        if (value.field.name.indexOf('Дата и время монтажа') > -1) {
          this.calculatedCoefficient.id = null;
          this.dealService.coefficient(moment(this.mappedValues[id].value).format('DD.MM.YYYY')).subscribe(c => {
            this.calculatedCoefficient.id = id;
            this.calculatedCoefficient.data = c;
          });
        }
      }
      this.saveValue(value);
      this.mappedValues[value.field.id].event = new EventEmitter();
      this.mappedValues[value.field.id].event.subscribe(v => {
        this.mappedValues[value.field.id].value = v;
        this.onMappedValueBlur(value.field.id);
      });
    }
  }

  onPickerChange($event, id) {
    if ($event) {
      this.onMappedValueBlur(id);
    }
  }

  onPositionPickerChange($event, fieldId) {
    if ($event) {
      this.onChangePositionValue(fieldId);
    }
  }

  openProblemMenu(name) {
    const $dropdownProblem = $('.dropdownProblem');
    const htmlElementJQuery = $dropdownProblem.find('.menu .dropdownProblemMenu .left.menu');
    htmlElementJQuery.each(function () {
      const text = $(this).prev('span').html();
      if (text !== name) {
        $(this).removeClass('visible').addClass('hidden');
      }
    });
  }

  performTransition(trans: Transition) {
    if (trans.action === 'E2B.TEMPLATE') {
      this.activeTabFile = true;
      this.dealService.template(this.deal.id, parseInt(trans.data.toString(), 0)).subscribe((dealFile: any) => {
        dealFile.state = 'highlighted';
        this.files.push(dealFile);
      });
    }
  }

  reclamationUserEvent(reclamation) {
    const eventEmitter = new EventEmitter();
    eventEmitter.subscribe(user => {
      reclamation.responsible = user;
      this.saveReclamation();
    });
    return eventEmitter;
  }

  removeDealFile(dealFile: any) {
    const yes = confirm('Вы уверены, что хотите удалить файл ' + dealFile.name + '?');
    if (!yes) {
      return true;
    }
    this.dealService.removeFile(dealFile.id, dealFile.model, dealFile.name).subscribe(() => {
      this.files.splice(this.files.indexOf(dealFile), 1);
    });
  }

  removeClientFile(dealFile: any) {
    const yes = confirm('Вы уверены, что хотите удалить файл ' + dealFile.name + '?');
    if (!yes) {
      return true;
    }
    this.dealService.removeClientFile(dealFile.id).subscribe(() => {
      this.clientFiles.splice(this.clientFiles.indexOf(dealFile), 1);
    });
  }

  removeFile(id, file) {
    const confirm = window.confirm(`Вы уверены, что хотите удалить файл ${file.name}?`);
    if (confirm) {
      this.mappedValues[id].value.splice(this.mappedValues[id].value.indexOf(file), 1);
      this.saveValue(this.mappedValues[id]);
    }
  }

  removeManagerHelper() {
    this.deal.managerHelper = null;
    this.dealService.update<Deal>(<Deal>{ id: this.deal.id, managerHelper: null }).subscribe(() => {
      this.err = false;
    }, e => {
      this.err = e.error;
    });
  }

  removePositionValue(fieldId) {
    this.positionValues.position.map((p, k) => {
      if (p.field.id === fieldId && p.id) {
        this.dealPositionValueService.deleteById(p.id).subscribe(() => {
          this.positionValues.position[k].value = undefined;

          if (p.field.name === 'Дата готовности заказа') {
            this.selectedPosition.checkedValues = false;
          }
          if (p.field.name === 'Подрядчик') {
            this.selectedPosition.checkedValues = false;
          }

          setTimeout(() => {
            this.fetchDeal();
          }, 1000);
        });
      }
    });
  }

  removeSubManager() {
    this.deal.subManager = null;
    this.dealService.update<Deal>(<Deal>{ id: this.deal.id, subManager: null }).subscribe(() => {
      this.err = false;
    }, e => {
      this.err = e.error;
    });
  }

  removeValue(value) {
    const confirm = window.confirm(`Вы уверены, что хотите удалить ${value.field.name}?`);
    if (confirm) {
      this.statusError = [];
      this.dealService.fieldDeleteById<DealFieldValue>(value.id).subscribe(() => {
        setTimeout(() => {
          this.mappedValues[value.field.id].value = undefined;
          this.mappedValues[value.field.id].edit = false;

          setTimeout(() => {
            this.fetchDeal();
          }, 1000);

        }, 10);
        this.fetchDeal();
      }, e => {
        this.errorService.addError.emit({
          title: 'Ошибка при удалении значения поля',
          message: e.error && e.error.message ? e.error.message : e.message
        });
      });
    }
  }

  savePositionProblem(problem) {
    const update = {
      id: problem.id,
      value: problem.value
    };
    problem.selected = false;
    this.dealPositionValueService.update(update).subscribe(() => {
      this.openedProblemMenu = null;
      return true;
    });
  }

  savePositionValue(value, k) {
    if (!value.id) {
      this.dealPositionValueService.create<DealPositionValue>(<DealPositionValue>{
        dealPosition: { id: value.dealPosition },
        field: { id: value.field.id },
        value: value.value
      }).subscribe(created => {
        this.positionValues.position[k].id = created.id;
        this.positionValues.position[k].created = created.created;
        setTimeout(() => {
          this.fetchDeal();
        }, 1000);
      });
    } else {
      this.dealPositionValueService.update<DealPositionValue>(<DealPositionValue>{
        id: value.id,
        value: value.value
      }).subscribe(() => {
        setTimeout(() => {
          this.fetchDeal();
        }, 1000);
      });
    }
  }

  saveProblem(problem) {
    this.saveValue(problem);
    problem.selected = false;
    this.mappedValues[problem.field.id].edit = false;
  }

  saveReclamation() {
    if (this.savingReclamation) {
      return false;
    }
    this.savingReclamation = true;
    if (!this.positionValues.reclamation.value) {
      this.positionValues.reclamation.value = [];
    }
    setTimeout(async () => {
      if (this.initialValues === JSON.stringify(this.positionValues.reclamation.value)) {
        this.savingReclamation = false;
        return false;
      }
      const update: any = {
        field: this.positionValues.reclamation.field,
        dealPosition: { id: this.selectedPosition.id },
        value: []
      };
      await Promise.all(await this.positionValues.reclamation.value.map(async reclamation => {
        if (reclamation.problemPhotosInput && reclamation.problemPhotosInput.length > 0) {
          if (!reclamation.problemPhotos) {
            reclamation.problemPhotos = [];
          }
          await this.fileService.upload(reclamation.problemPhotosInput).then((f: FileInterface[]) => {
            f.map(file => {
              reclamation.problemPhotos.push(file);
            });
            this.fileReclamationFieldForm.nativeElement.value = '';
          });
        }
        if (reclamation.otherFilesInput && reclamation.otherFilesInput.length > 0) {
          if (!reclamation.otherFiles) {
            reclamation.otherFiles = [];
          }
          await this.fileService.upload(reclamation.otherFilesInput).then((f: FileInterface[]) => {
            f.map(file => {
              reclamation.otherFiles.push(file);
            });
            this.fileReclamationOtherFieldForm.nativeElement.value = '';
          });
        }
        await update.value.push({
          dealPositionId: reclamation.dealPositionId,
          created: reclamation.created,
          description: reclamation.description,
          taskDescription: reclamation.taskDescription,
          resolveDate: reclamation.resolveDate ? moment(reclamation.resolveDate).clone().toISOString() : null,
          resolveDateSet: reclamation.resolveDateSet ? moment(reclamation.resolveDateSet).clone().toISOString() : null,
          responsible: reclamation.responsible ? {
            id: reclamation.responsible.id,
            name: reclamation.responsible.name,
            email: reclamation.responsible.email
          } : reclamation.responsible,
          receivedCall: reclamation.receivedCall,
          reclamation: reclamation.reclamation,
          mountingAccept: reclamation.mountingAccept,
          mountingArrived: reclamation.mountingArrived,
          finished: reclamation.finished,
          problemPhotos: reclamation.problemPhotos,
          otherFiles: reclamation.otherFiles
        });
      }));
      if (this.positionValues.reclamation.id) {
        update.id = this.positionValues.reclamation.id;
        await this.dealPositionValueService.update(update).subscribe(() => {
          this.initialValues = JSON.stringify(this.positionValues.reclamation.value);
          this.savingReclamation = false;
        });
      } else {
        await this.dealPositionValueService.create<DealPositionValue>(update).subscribe((created) => {
          this.positionValues.reclamation.id = created.id;
          this.initialValues = JSON.stringify(this.positionValues.reclamation.value);
          this.savingReclamation = false;
        });
      }
    }, 200);
  }

  saveValue(value) {
    if ([null, undefined].indexOf(value.value) > -1) {
      return false;
    }
    if (value && !value.id) {
      this.dealService.fieldCreate<DealFieldValue>(<DealFieldValue>{
        field: { id: value.field.id },
        value: value.value,
        deal: value.deal
      }).subscribe(created => {
        if (value.field.type === 'DATE') {
          created.value = moment(value.value, 'DD.MM.YYYY').toDate();
        } else if (value.field.type === 'DATETIME') {
          created.value = moment(value.value, 'DD.MM.YYYY HH:mm:ss').toDate();
        }
        this.mappedValues[value.field.id] = created;
        this.mappedValues[value.field.id].event = new EventEmitter();
      }, e => {
        this.errorService.addError.emit({
          title: 'Ошибка при сохранении значения поля',
          message: e.error && e.error.message ? e.error.message : e.message
        });
      });
    } else if (value.id) {
      this.dealService.fieldUpdate<DealFieldValue>(<DealFieldValue>{
        id: value.id,
        field: { id: value.field.id },
        value: value.value,
        deal: value.deal
      }).subscribe(() => {
      }, e => {
        this.errorService.addError.emit({
          title: 'Ошибка при сохранении значения поля',
          message: e.error && e.error.message ? e.error.message : e.message
        });
      });
    }
    this.calcFinance();
  }

  selectProblem(problem, value) {
    if (!this.mappedValues[problem.field.id]) {
      this.mappedValues[problem.field.id] = {
        deal: {
          id: this.id
        },
        field: problem.field
      };
    }
    if (value && value.name) {
      if (this.mappedValues[problem.field.id].value) {
        problem.value = this.mappedValues[problem.field.id].value;
      } else {
        problem.value = value;
      }

      problem.value.name = value.name;
      this.mappedValues[problem.field.id].value = problem.value;
    }

    $('.ui.dropdownProblem .menu.visible').removeClass('visible');
    $('.ui.dropdownProblem .ui.dropdown.button').removeClass('active');
  }

  selectStatus(dealStatus: DealStatus) {
    if (this.editDealStatus) {
      return;
    }

    this.editDealStatus = true;
    let update = true;
    const values: any = [];
    const required = {};

    if (dealStatus.name === 'ПРОИЗВОДСТВО') {
      const positionsWithoutProductionOrders = this.deal.positions
        .filter(dealPosition => {
          return dealPosition.position.type > 0 && dealPosition.position.type < 5 &&
            !this.deal.orders.find(order => order.type === OrderType.PRODUCTION && order.orderDealPositions
              .find(orderPosition => dealPosition.id === orderPosition.dealPosition.id));
        });

      if (positionsWithoutProductionOrders.length) {
        const positionsListString = positionsWithoutProductionOrders.map(dealPosition => `"${dealPosition.position.name}"`).join(', ');
        return this.errorService.addError.emit({
          title: 'Ошибка перевода сделки в статус "Производство"',
          message: `Для позиций ${positionsListString} не созданы заказы на производство`
        });
      }
    }

    this.deal.currentStatus.requiredFields.map(field => {
      if (field.model === 'CRM.FIELD.DEAL') {
        if (this.mappedValues[field.id] && [null, undefined].indexOf(this.mappedValues[field.id].value) > -1) {
          update = false;
          values.push({
            type: 'DEAL',
            field: field
          });
        } else if (!this.mappedValues[field.id]) {
          update = false;
          values.push({
            type: 'DEAL',
            field: field
          });
        }
      } else if (field.model === 'CRM.FIELD.POSITION') {
        required[field.id] = field;
      }
    });

    this.dealService.positionField(this.id).subscribe(definedFields => {
      const positionFields = {};
      this.deal.positions.filter(p => !!p.position.type).map(position => {
        const definedValues = {};
        positionFields[position.id] = {
          position: { id: position.id, name: position.position.name },
          fields: []
        };
        position.positionValues.map(value => {
          if (required[value.field.id] && [null, undefined].includes(value.value)) {
            positionFields[position.id].fields.push(value.field);
            update = false;
          } else if (required[value.field.id] && ![null, undefined].includes(value.value)) {
            definedValues[value.field.id] = value.value;
          }
        });
        position.position.values.map(value => {
          if (required[value.field.id] && !positionFields[position.id].fields.find(f => f.id === value.field.id) && [null, undefined].includes(value.value)) {
            positionFields[position.id].fields.push(value.field);
            update = false;
          } else if (required[value.field.id] && ![null, undefined].includes(value.value)) {
            definedValues[value.field.id] = value.value;
          }
        });
        Object.keys(required).map(fieldId => {
          if (definedFields[position.id] && definedFields[position.id].includes(required[fieldId].id) && [null, undefined].includes(definedValues[required[fieldId].id])) {
            positionFields[position.id].fields.push(required[fieldId]);
            update = false;
          }
        });
      });
      Object.keys(positionFields).map(positionId => {
        if (positionFields[positionId].fields.length > 0) {
          values.push({
            type: 'POSITION',
            position: positionFields[positionId].position,
            fields: positionFields[positionId].fields
          });
        }
      });
      if (!dealStatus.positive) {
        update = true;
      }
      if (!update) {
        if (values.filter(v => v.type === 'DEAL').length > 0) {
          this.errorService.addError.emit({
            title: 'Не заполнены поля в сделке',
            message: values.filter(v => v.type === 'DEAL').map(v => v.field.name).join(', ')
          });
        }
        if (values.filter(v => v.type === 'POSITION').length > 0) {
          values.filter(v => v.type === 'POSITION').map(v => {
            this.errorService.addError.emit({
              title: 'Не заполнены поля в позиции ' + v.position.name,
              message: v.fields.map(f => f.name).join(', ')
            });
          });
        }
        this.editDealStatus = false;
      } else {
        this.dealService.update<Deal>(<any>{
          id: this.deal.id,
          currentStatus: { id: dealStatus.id },
          oldStatus: { id: this.deal.currentStatus.id }
        }).subscribe(() => {
          setTimeout(() => {
            this.dealStatusService.findById<DealStatus>(dealStatus.id).subscribe(status => {
              this.deal.currentStatus = status;
              this.editDealStatus = false;
              this.fetchDeal();
            });
          }, 500);
        }, e => {
          this.errorService.addError.emit({
            title: 'Ошибка при сохранении статуса',
            message: e.error && e.error.message ? e.error.message : e.message
          });
        });
      }
    });
  }

  selectMountingDate(date: { mountingDate: string, productionDate: string }) {

    const yes = confirm('Вы уверены, что хотите поставить "Дату монтажа": ' + date.mountingDate + ' ?');

    if (!yes) {
      return false;
    }

    this.dealService.setMountingDate({
      dealId: this.deal.id,
      mountingDate: date.mountingDate,
      productionDates: { korpus: date.productionDate }
    }).subscribe(r => {
      this.mountingDates = [];
      this.selectedMountingDate = null;
    }, err => {
      console.error(err);
    });
  }

  selectShippingDate(date: { shippingDate: string, productionDate: string }) {

    const yes = confirm('Вы уверены, что хотите поставить "Дату отгрузки": ' + date.shippingDate + ' ?');

    if (!yes) {
      return false;
    }

    this.dealService.setMountingDate({
      dealId: this.deal.id,
      shippingDate: date.shippingDate,
      productionDates: { korpus: date.productionDate }
    }).subscribe(r => {
      this.shippingDates = [];
      this.selectedShippingDate = null;
    }, err => {
      console.error(err);
    });
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  sendSms(template: SmsTemplate) {
    const yes = confirm(`Отправить СМС по шаблону '${template.name}' клиенту ${this.mainContact.name} (${this.mainContact.phone})?`);
    if (!yes) {
      return true;
    }
    this.dealService.sendSms(this.deal.id, template.id, this.mainContact.id).subscribe(res => {
      this.comments = [res.comment, ...this.comments];
    });
  }

  switch(variable) {
    this[variable] = !this[variable];
    if (variable === 'editClient' && this[variable]) {
      this.loadClients();
    } else if (variable === 'editDealPerson' && this[variable]) {
      this.loadPersons();
    }
  }

  totalBonus() {
    let total = 0;
    if (this.deal.positions.length > 0) {
      this.deal.positions.map(pos => {
        total += pos.position.priceMN * pos.count;
      });
    }
    return total;
  }

  totalDealSum() {
    let totalSum = 0;
    if (this.deal.positions.length > 0) {
      this.deal.positions.map(p => {
        totalSum += p.price * p.count;
        if (p.discountPrice) {
          totalSum -= p.discountPrice;
        }
      });
    }
    return totalSum;
  }

  totalSum() {
    let totalSum = 0;
    if (this.deal.positions.length > 0) {
      this.deal.positions.map(pos => {
        totalSum += pos.price * pos.count;
      });
    }
    return totalSum;
  }

  hasDiscount() {
    let d = 0;
    this.deal.positions.map(p => {
      if (p.discountPrice) {
        d += p.discountPrice;
      }
    });
    return d;
  }

  addressSelected(event, fieldId) {
    this.mappedValues[fieldId].value = event.fullname;
    this.onMappedValueBlur(fieldId);
    this.searchResults = [];
  }

  public onAddressFocus(fieldId) {
    this.selectAddress.writeValue(this.mappedValues[fieldId].value);
  }

  public async sendToProduction(selectedPosition: any) {

    const yes: boolean = confirm(`Вы уверены, что хотите отправить ${this.selectedPosition.position.name} в производство?`);
    if (!yes) {
      return false;
    }

    await this.dealPositionService.update({
      id: this.selectedPosition.id,
      inProduction: true
    } as DealPosition).subscribe(async () => {
      this.selectedPosition.inProduction = true;
      setTimeout(() => {
        this.fetchDeal();
      }, 2000);
    });

  }

  public async removeFromProduction(selectedPosition: any) {

    const yes: boolean = confirm(`Вы уверены, что хотите убрать ${this.selectedPosition.position.name} из производства?`);
    if (!yes) {
      return false;
    }

    await this.dealPositionService.update({
      id: this.selectedPosition.id,
      inProduction: false
    } as DealPosition).subscribe(async () => {
      this.selectedPosition.inProduction = false;
      setTimeout(() => {
        this.fetchDeal();
      }, 2000);
    });
  }

  get FileSigningStatus() {
    return FileSigningStatus;
  }

  onFileDragStart() {
    this.fileDragging = true;
  }

  onFileDragEnd() {
    this.fileDragging = false;
  }

  onFileDrop(event: DndDropEvent) {
    this.dealService.addClientFile(this.deal.id, event.data).subscribe(clientFile => {
      this.clientFiles.push(clientFile);
    });
  }

  copyLkLink() {
    navigator.clipboard.writeText(`https://e2b.zaoblako.ru/~/${this.deal.id}`).then(() => {
      this.lkLinkCopied = true;
    });
  }

  loadOrders() {
    if (!this.ordersActive) {
      this.orderModeService.setListMode();
    }
  }

  pickOrder(order: any) {
    this.orderModeService.setCardMode(order.id, order.position.id);
    this.ordersActive = true;
  }

  onOrdersTabClick() {
    if (this.ordersActive) {
      this.orderModeService.setListMode();
    }
  }

  onOrdersTabDeactivate() {
    this.orderModeService.clearMode();
  }
}
