import { Component, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '../../../_models/client.model';
import { PersonType } from '../../../_models/person-type.model';
import { Person } from '../../../_models/person.model';
import { BankService } from '../../../_services/bank.service';
import { ClientService } from '../../../_services/client.service';
import { PersonTypeService } from '../../../_services/person-type.service';
import { PersonService } from '../../../_services/person.service';

@Component({
  selector: 'e2b-person-edit',
  templateUrl: './person-edit.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../../assets/design/semantic-ui/components/search.min.css',
    '../../../../assets/design/semantic-ui/components/input.min.css',
    '../../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../../assets/design/semantic-ui/components/button.min.css',
    './person-edit.component.css']
})
export class PersonEditComponent implements OnInit {
  id: number;
  person: Person = new Person();
  client: Client;
  clients: Client[];
  types: PersonType[] = [];
  selectClient = new EventEmitter<Client>();
  @Input()
  clientId: number;
  connectionLinks = ['msktip', 'yasno1tip', 'yasno2', 'spbtip', 'iptitlinov_buh', 'ipkazakov_buh', 'yasno2kassa'];
  bankSearch: (query: any) => Promise<unknown>;

  constructor(private personService: PersonService,
              private personTypeService: PersonTypeService,
              private clientService: ClientService,
              private route: ActivatedRoute,
              private bankService: BankService,
              private router: Router) {

    this.selectClient.subscribe(client => {
      this.person.client = client;
    });

    this.id = this.id = parseInt(this.route.snapshot.params.id, 0);
  }

  ngOnInit() {
    this.clientService.find<Client>().subscribe(clients => {
      this.clients = clients;
      this.personTypeService.find<PersonType>().subscribe(types => {
        this.types = types;
        this.personService.findById<Person>(this.id).subscribe(person => {
          this.person = person;
          this.clients.map(client => {
            if (person.client.id === client.id) {
              this.client = client;
            }
          });
          this.selectType(person.type);
        });
      });
    });

    this.bankSearch = async (query) => {
      return new Promise(resolve => {
        this.bankService.search(query).then(results => {
          return resolve(results);
        }).catch(console.error);
      });
    };
  }

  selectType(type) {
    this.person.type = type;
  }

  save() {
    this.personService.update<Person>(this.person).subscribe(() => {
      this.router.navigate(['/client/person/card/' + this.person.id]).catch(console.error);
    });
  }

  bankSelected(bank) {
    this.person.bank = bank;
  }
}
