<form class="ui form">
  <div class="ui grid">
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Имя контакта&nbsp;<sup style="color: red">*</sup></h4>
      </div>
      <div class="ui six wide column">
        <div class="field required">
          <input type="text"
                 name="name"
                 [(ngModel)]="contact.name"
                 placeholder="Имя"
                 required>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Клиент&nbsp;<sup style="color: red">*</sup></h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <sui-select class="selection"
                      [(ngModel)]="contact.client"
                      placeholder="Клиент"
                      #select
                      labelField="name"
                      [ngModelOptions]="{name: 'client'}">
            <sui-select-option *ngFor="let client of clients"
                               [value]="client.name">
            </sui-select-option>
          </sui-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Телефон</h4>
      </div>
      <div class="ui six wide column">
        <div class="field">
          <input type="number"
                 name="phone"
                 [(ngModel)]="contact.phone"
                 placeholder="Телефон">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Email</h4>
      </div>
      <div class="ui six wide column">
        <div class="field">
          <input type="number"
                 [(ngModel)]="contact.email"
                 name="email"
                 placeholder="Email">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Адрес</h4>
      </div>
      <div class="ui six wide column">
        <div class="field">
          <input type="number"
                 [(ngModel)]="contact.address"
                 name="address"
                 placeholder="Адрес">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Описание</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <textarea [(ngModel)]="contact.description" name="description" placeholder="Описание"></textarea>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="buttons mt-2">
  <button class="ui button green">Сохранить</button>
  <button class="ui button red" routerLink="/contacts/list">Отмена</button>
</div>
