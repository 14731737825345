import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { Order } from '../_models/order.model';

@Injectable()
export class OrderService extends BaseService {
  baseUrl = '/order';

  findForDeal<T>(dealId: number): Observable<T[]> {
    return this.http.get<T[]>(this.baseUrl + '/deal/' + dealId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  generateOrders(dealId: number): Observable<Order[]> {
    return this.http.post<Order[]>(this.baseUrl + '/generate', { deal: { id: dealId } }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  productionOrders(): Observable<any> {
    return this.http.get<any>('/production/orders', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  productionOrder(id: number) {
    return this.http.get<any[]>('/production/order/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  productionAccept(update: any): Observable<any> {
    return this.http.post<any>('/production/accept', update, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  productionDecline(update: any): Observable<any> {
    return this.http.post<any>('/production/decline', update, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  productionFinish(update: any): Observable<any> {
    return this.http.post<any>('/production/finish', update, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  productionUnload(update: any): Observable<any> {
    return this.http.post<any>('/production/unload', update, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  productionSos(update: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + '/sos', update, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  responsible<T>(options: any): Observable<T[]> {
    const params = this.getParams(options);

    return this.http.get<T[]>(this.baseUrl + '/responsible', {
      params,
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
