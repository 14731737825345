<div class="ui stackable grid">
  <div class="ui fourteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <table class="ui selectable very padded table" [hidden]="!loaded">
      <thead>
      <tr>
        <th>
          <div class="head">Наименование</div>
        </th>
        <th>
          <div class="head">Тип</div>
        </th>
        <th>
          <div class="head">ИНН</div>
        </th>
        <th>
          <div class="head">Клиент</div>
        </th>
        <th>
          <div class="head">Контакты</div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let person of persons">
        <td>
          <a [routerLink]="['/client/person/card/', person.id]">{{person.name}}</a>
        </td>
        <td>
          {{person.type.name}}
        </td>
        <td>
          <p>{{person.inn}}</p>
        </td>
        <td>
          <a [routerLink]="['/client/card/', person.client.id]">{{person.client.name}}</a>
        </td>
        <td>
          <div [hidden]="person.contacts.length === 0" *ngFor="let contact of person.contacts">
            <div *ngIf="contact.name">{{contact.name}}</div>
            <div *ngIf="contact.email">
              <a href="mailto:{{contact.email}}">{{contact.email}}</a>
            </div>
            <div *ngIf="contact.phone">
              <a href="tel:{{contact.phone}}">{{contact.phone}}</a>
            </div>
            <div *ngIf="contact.address">
              <a *ngIf="contact.address" href="yandex:{{contact.address}}">{{contact.address}}</a>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
