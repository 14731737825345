<ng-template let-option #staffOptionTemplate>
  <div class="mininame">{{option.name}}</div>
  <div class="miniprice">{{option.email}}</div>
  <div class="minitext">{{option.job ? option.job.name : ''}}</div>
</ng-template>
<sui-multi-select class="fluid selection mx-auto"
                  placeholder="Сотрудники"
                  [isSearchable]="true"
                  [isDisabled]="disabled"
                  [(ngModel)]="selectedStaff"
                  [ngModelOptions]="{name: 'staff'}"
                  (ngModelChange)="save()"
                  [options]="staff"
                  labelField="name"
                  [optionTemplate]="staffOptionTemplate"
                  #selectStaffMulti>
  <sui-select-option *ngFor="let staff of selectStaffMulti.filteredOptions | orderByColumn:'name:string'"
                     [value]="staff">
  </sui-select-option>
</sui-multi-select>
