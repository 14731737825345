<div class="ui fluid container" style="margin:0 !important;padding:0;height: 100% !important;overflow: hidden !important;">
  <div class="ui stackable grid" style="margin:0;padding:0;height: 100% !important;">
    <div class="ui twelve wide column loginBackground" style="margin:0;padding:0;height: 100% !important;">
      <div class="bgpic">
        <div class="bottom">
          <div class="ui white basic segment">
            <h2 class="semi-bold">
              Olissys E2B</h2>
            <p>* Optimal LIving Space SYStem</p>
            <p class="small">
              Все права защищены © 2017-2019 OLISSYS.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="ui loginWrapper four wide column" style="background: #d7f7ee;height: 100% !important;margin:0 !important;padding:0;">
      <div class="ui loginFormWrapper container">
        <img src="assets/images/logo.svg" style="width: 100%;" width="180" height="120">
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" class="ui form" style="padding-top:30px;">
          <div class="field white">
            <label class="text-uppercase semi-bold">Введите новый пароль</label>
            <input formControlName="newPassword" type="password" class="form-control" required>
            <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched) && submitted"
                 class="alert alert-danger">
              <div *ngIf="newPassword.errors['required']">
                Password is required.
              </div>
            </div>
          </div>
          <div class="field white">
            <label class="text-uppercase semi-bold">Введите повторно</label>
            <input formControlName="comparePassword" type="password" class="form-control" required>
            <div *ngIf="comparePassword.invalid && (comparePassword.dirty || comparePassword.touched) && submitted"
                 class="alert alert-danger">
              <div *ngIf="comparePassword.errors['required']">
                Password is required.
              </div>
            </div>
          </div>
          <div class="ui negative message" *ngIf="error">
            <div class="header">{{error.type}}</div>
            <div class="content">
              {{error.action}}
            </div>
          </div>
          <button class="ui fluid primary button m20" [ngClass]="{'disabled': newPassword.value === '' && comparePassword.value === '' || newPassword.value != comparePassword.value}" type="submit">
            Сохранить
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
