import { BaseModel } from './base.model';

export enum PositionPersonalType {
  PRICE = 'PRICE',
  PERCENT = 'PERCENT'
}

export class PositionPersonal extends BaseModel {
  name: string;
  created: Date;
  price: number;
}
