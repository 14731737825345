<span [attr.data-tooltip]="statusInfo[status]">
    <i class="check icon" *ngIf="status === WhatsAppStatus.SENT"></i>
    <i class="icons" *ngIf="status === WhatsAppStatus.DELIVERED">
      <i class="check icon"></i>
      <i class="check icon" style="margin-left: 3px"></i>
    </i>
    <i class="icons" *ngIf="status === WhatsAppStatus.READ">
      <i class="check inverted blue icon"></i>
      <i class="check inverted blue icon" style="margin-left: 3px"></i>
    </i>
    <i class="exclamation circle red icon"
       *ngIf="status === WhatsAppStatus.SPAM ||
       status === WhatsAppStatus.TOO_LONG_MESSAGE ||
       status === WhatsAppStatus.TOO_BIG_FILE ||
       status === WhatsAppStatus.NOT_INSTALLED ||
       status === WhatsAppStatus.EXCEEDED_LIMIT">
    </i>
</span>



