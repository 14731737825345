import { Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { PositionGroupTemplate } from '../../_models/position-group-template.model';
import { PositionGroup } from '../../_models/position-group.model';
import { PositionGroupTemplateService } from '../../_services/position-group-template.service';
import { PositionGroupService } from '../../_services/position-group.service';
import { UserService } from '../../_services/user.service';
import { Action, ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-group-list',
  templateUrl: './position-group-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/label.min.css',
    './position-group-list.component.less'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PositionGroupListComponent implements OnInit {
  positionGroups: PositionGroup[];
  templates: PositionGroupTemplate[];
  loaded = false;
  editedGroup: number | null;
  count = 1;
  @ViewChild('selectTemplate', { static: false })
  selectTemplate;

  constructor(private actionService: ActionService,
              private positionGroupService: PositionGroupService,
              private userService: UserService,
              private positionGroupTemplateService: PositionGroupTemplateService) {
  }

  ngOnInit() {

    if (this.userService.checkPermissions(['position-group-template', '/'])) {
      this.actionService.createMenuEvent.emit(<Action>{ name: 'Шаблоны', link: '/catalog/template/list', class: 'yellow active' });
    }
    if (this.userService.checkPermissions(['position', '/'])) {
      this.actionService.createMenuEvent.emit(<Action>{ name: 'Товары', link: '/catalog/list', class: 'green active' });
    }
    if (this.userService.checkPermissions(['position-group', '/'], ['write'])) {
      this.actionService.createMenuEvent.emit(<Action>{ name: 'Добавить группу', link: '/catalog/group/add', class: 'blue active' });
    }

    this.positionGroupService.find<PositionGroup>().subscribe(groups => {
      this.positionGroups = groups;
      this.positionGroupTemplateService.find<PositionGroupTemplate>().subscribe(templates => {
        this.templates = templates;
        this.loaded = true;
      }, console.error);
    }, console.error);
  }

  editGroup(id: number) {
    this.editedGroup = id;
  }

  save(group: PositionGroup) {
    const event = new EventEmitter();
    event.subscribe(template => {
      group.template = template;
      this.update(group);
    }, console.error);
    return event;
  }

  update(group: PositionGroup) {
    this.positionGroupService.update<PositionGroup>(group).subscribe(() => {
      this.editedGroup = null;
    }, console.error);
  }

  remove(group) {
    const yes = confirm('Вы уверены, что хотите удалить группу ' + group.name + '?');
    if (yes) {
      this.positionGroupService.deleteById(group.id).subscribe(() => {
        this.positionGroups.splice(this.positionGroups.indexOf(group), 1);
      }, console.error);
    }
  }
}
