import { Component, EventEmitter, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { User } from '../../_models/user.model';

@Component({
  selector: 'e2b-staff-multi-dropdown',
  templateUrl: './staff.multi.dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class StaffMultiDropdownComponent {
  @Input()
  public staff: User[];
  @Input()
  public event: EventEmitter<User[]>;

  @Input()
  disabled = false;

  @ViewChild('selectStaffMulti', { static: false })
  selectStaff;

  public selectedStaff: User[];

  @Input()
  set selected(staff: User[]) {
    if (staff) {
      this.selectedStaff = staff;
      setTimeout(() => {
        this.selectStaff.selectedOptions = staff;
      }, 0);
    }
  }

  save() {
    this.event.emit(this.selectedStaff);
  }
}
