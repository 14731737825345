import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectFile } from '../_models/project-file';
import { ProjectHistory } from '../_models/project-history';
import { ProjectStatus } from '../_models/project-status.model';
import { Project } from '../_models/project.model';
import { User } from '../_models/user.model';
import { BaseService } from './base.service';
import { FileInterface } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService {
  baseUrl = '/project';


  addToFavorite(project: Project): Observable<Project> {
    return this.http.post<Project>(this.baseUrl + '/favorite', project, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  removeFromFavorite(projectId: number): Observable<Project> {
    return this.http.delete<Project>(this.baseUrl + '/favorite/' + projectId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  files(projectId: number): Observable<ProjectFile[]> {
    return this.http.get<ProjectFile[]>(this.baseUrl + '/files/' + projectId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  addFile(projectId: number, file: FileInterface): Observable<ProjectFile> {
    return this.http.post<ProjectFile>(this.baseUrl + '/files', {
      id: projectId,
      file: file
    }, { withCredentials: true, headers: { 'Authorization': localStorage.getItem('e2b.user.token') } });
  }

  removeFile(projectId: number) {
    return this.http.delete(this.baseUrl + '/files/' + projectId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  history(projectId): Observable<ProjectHistory[]> {
    return this.http.get<ProjectHistory[]>(this.baseUrl + '/history/' + projectId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  statuses(): Observable<ProjectStatus[]> {
    return this.http.get<ProjectStatus[]>('/project-status', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  children(projectId, options): Observable<{ employees: User[], projects: Project[], count: number }> {
    const params = {};
    if (options) {
      Object.entries(options).map(o => {
        params[o[0]] = o[1];
      });
    }

    return this.http.get<{ employees: User[], projects: Project[], count: number }>(this.baseUrl + '/children/' + projectId, {
      params,
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  users(id: number): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + '/users/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
