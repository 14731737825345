<sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
  <div class="ui text loader">Загрузка...</div>
</sui-dimmer>
<div class="d-flex justify-content-center mb-3" [ngClass]="{'vis-hidden': !loaded}">
  <e2b-staff-simple-dropdown [staff]="staff" [selected]="responsible" [event]="selectResponsible" class="w-20 mr-10px"></e2b-staff-simple-dropdown>
  <div class="ui buttons w-30">
    <div
      class="ui button blue"
      mwlCalendarPreviousView
      [view]="view"
      [(viewDate)]="viewDate"
      (click)="prevWeek()">
      <i class="angle left icon"></i>
      Пред. неделя
    </div>
    <div
      class="ui button"
      mwlCalendarToday
      [(viewDate)]="viewDate"
      (click)="currentWeek()">
      Текущая неделя
    </div>
    <div
      class="ui button blue"
      mwlCalendarNextView
      [view]="view"
      [(viewDate)]="viewDate"
      (click)="nextWeek()">
      След. неделя
      <i class="angle right icon"></i>
    </div>
  </div>
</div>
<div *ngIf="loaded">
  <div *ngIf="!events || events.length == 0" class="text-center fsz-18px fw-bold">
    Нет дел на период с {{startWeek.format("DD MMM YYYY г.")}} по {{endWeek.format("DD MMM YYYY г.")}}
  </div>
  <div [ngSwitch]="view">
    <iq-calendar-week-hours-view
      *ngSwitchCase="'week'"
      [hidden]="!events || events.length == 0"
      [viewDate]="viewDate"
      [events]="events"
      [weekStartsOn]="1"
      [hourSegments]="1"
      [hourSegmentHeight]="140"
      [dayStartHour]="0"
      [dayEndHour]="23"
      [refresh]="refresh">
    </iq-calendar-week-hours-view>
  </div>
</div>
