import { AccessToken } from './access-token.model';
import { BaseModel } from './base.model';
import { Client } from './client.model';
import { Comment } from './comment.model';
import { DealDecline } from './deal-decline.model';
import { Deal } from './deal.model';
import { Department } from './department.model';
import { Job } from './job.model';
import { Request } from './request.model';
import { AccessObject, Role } from './role.model';
import { UserGroup } from './user-group.model';
import { UserPasswordRequest } from './user-password-request.model';

export class CarInterface {
  model: string;
  number: string;
}

export class User extends BaseModel {
  accessObject: AccessObject;
  auditedDeals: Deal[];
  avatar: any;
  birthday: Date;
  car: CarInterface;
  clients: Client[];
  coefficient: number;
  comments: Comment[];
  created: Date;
  createdDeals: Deal[];
  declines: DealDecline[];
  deleted: boolean;
  department: Department;
  email: string;
  groups: UserGroup[];
  job: Job;
  managedClients: Client[];
  managedDeals: Deal[];
  multiplier: number;
  name: string;
  password: string;
  passwordRequests: UserPasswordRequest[];
  phone: string;
  phones: number[];
  problemLimit: number;
  problemSumLimit: number;
  requests: Request[];
  roles: Role[];
  tokens: AccessToken[];
}
