<ng-template let-option #dealOptionTemplate>
  <div class="mininame">{{option.name}}</div>
  <div class="miniprice">{{option.client.name}}</div>
  <div class="minitext"></div>
</ng-template>
<sui-select class="fluid selection mx-auto" [ngClass]="{'error': error}"
            placeholder="Сделка"
            [isSearchable]="true"
            [isDisabled]="false"
            [(ngModel)]="selectedDeal"
            [ngModelOptions]="{name: 'deal'}"
            (ngModelChange)="save()"
            [options]="deals"
            labelField="name"
            [optionTemplate]="dealOptionTemplate"
            #selectDeal>
  <sui-select-option *ngFor="let deal of selectDeal.filteredOptions | orderByColumn:'-id:number'" [value]="deal">
  </sui-select-option>
</sui-select>
