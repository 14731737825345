import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInUserGuard } from './_guards/logged-in-user.guard';
import { PermissionGuard } from './_guards/permission.guard';
import { ActivityComponent } from './activity/activity.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DashboardWidgetComponent } from './dashboard/dashboard-widget/dashboard-widget.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DealWizardComponent } from './dashboard/deal-wizard/deal-wizard.component';
import { SalesWizardComponent } from './dashboard/sales-wizard/sales-wizard.component';
import { ShowWizardComponent } from './dashboard/show-wizard/show-wizard.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { LkShortlinkComponent } from './lk-shortlink/lk-shortlink.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageUnavailableComponent } from './page-unavailable/page-unavailable';
import { ReferenceShowComponent } from './reference/reference-show/reference-show.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SettingsComponent } from './settings/settings.component';
import { PolicyComponent } from './policy/policy.component';
import { SupportComponent } from './support/support.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    data: {
      title: 'Olissys E2B | Дэшборд',
      breadcrumb: 'Дэшборд'
    }, children: [{
      path: '',
      component: DashboardWidgetComponent,
      canActivate: [LoggedInUserGuard],
      data: {
        title: 'Olissys E2B | Дэшборд',
        breadcrumb: 'Рабочий стол'
      }
    }, {
      path: 'dealWizard',
      component: DealWizardComponent,
      canActivate: [LoggedInUserGuard, PermissionGuard],
      data: {
        access: ['deal', '/'],
        title: 'Olissys E2B | Добавить сделку',
        breadcrumb: 'Визард сделки',
      }
    }, {
      path: 'show-wizard',
      component: ShowWizardComponent,
      canActivate: [LoggedInUserGuard, PermissionGuard],
      data: {
        access: ['deal', '/'],
        title: 'Olissys E2B | Показ',
        breadcrumb: 'Визард показа',
      }
    }, {
      path: 'sales-wizard',
      component: SalesWizardComponent,
      canActivate: [LoggedInUserGuard, PermissionGuard],
      data: {
        access: ['deal', '/'],
        title: 'Olissys E2B | Продажи',
        breadcrumb: 'Визард продаж',
      }
    }, {
      path: 'feedback',
      component: FeedbackComponent,
      data: {
        title: 'Olissys E2B | Обратная связь',
        breadcrumb: 'Обратная связь'
      }
    }, {
      path: 'settings',
      canActivate: [LoggedInUserGuard, PermissionGuard],
      component: SettingsComponent,
      data: {
        access: ['setting', '/'],
        title: 'Olissys E2B | Настройки',
        breadcrumb: 'Настройки'
      }
    }, {
      path: 'activity',
      canActivate: [LoggedInUserGuard],
      component: ActivityComponent,
      data: {
        title: 'Olissys E2B | Лента активности',
        breadcrumb: 'Лента активности'
      }
    }, {
      path: 'client',
      canActivate: [PermissionGuard],
      data: {
        access: ['client', '/'],
        title: 'Olissys E2B | Клиенты',
        breadcrumb: 'Клиенты'
      },
      loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
      runGuardsAndResolvers: 'always'
    }, {
      path: 'catalog',
      data: {
        title: 'Olissys E2B | Каталог',
        breadcrumb: 'Каталог'
      },
      loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule)
    }, {
      path: 'reference',
      canActivate: [PermissionGuard, LoggedInUserGuard],
      data: {
        access: ['reference', '/'],
        title: 'Olissys E2B | Эталон',
        breadcrumb: 'Эталон'
      },
      component: ReferenceShowComponent
    }, {
      path: 'deal',
      data: {
        title: 'Olissys E2B | Сделки',
        breadcrumb: 'Сделки'
      },
      loadChildren: () => import('./deal/deal.module').then(m => m.DealModule),
      runGuardsAndResolvers: 'always'
    }, {
      path: 'todo',
      canActivate: [PermissionGuard],
      data: {
        access: ['todo', '/'],
        title: 'Olissys E2B | Дела',
        breadcrumb: 'Дела'
      },
      loadChildren: () => import('./todo/todo.module').then(m => m.TodoModule)
    }, {
      path: 'staff',
      canActivate: [PermissionGuard],
      data: {
        access: ['user', '/'],
        title: 'Olissys E2B | Сотрудники',
        breadcrumb: 'Сотрудники'
      },
      loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule)
    }, {
      path: 'report',
      data: {
        title: 'Olissys E2B | Отчеты',
        breadcrumb: 'Отчеты'
      },
      loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
    }, {
      path: 'brs',
      data: {
        title: 'Olissys E2B | СБР',
        breadcrumb: 'СБР'
      },
      loadChildren: () => import('./brs/brs.module').then(m => m.BrsModule)
    }, {
      path: 'project',
      data: {
        title: 'Olissys E2B | Задачи',
        breadcrumb: 'Задачи'
      },
      loadChildren: () => import('./project/project.module').then(m => m.ProjectModule)
    }]
  }, {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Olissys E2B | Вход'
    }
  }, {
    path: 'reset',
    component: ResetPasswordComponent,
    data: {
      title: 'Olissys E2B | Сброс пароля'
    }
  }, {
    path: 'change',
    component: ChangePasswordComponent,
    data: {
      title: 'Olissys E2B | Новый пароль'
    }
  },
  {
    path: 'policy',
    component: PolicyComponent,
    data: {
      title: 'Olissys E2B | Политика конфиденциальности'
    }
  },
  {
    path: 'support',
    component: SupportComponent,
    data: {
      title: 'Olissys E2B | Техподдержка'
    }
  },
  {
    path: 'lk',
    data: {
      title: 'Olissys | Личный кабинет',
    },
    loadChildren: () => import('./lk/lk.module').then(m => m.LkModule)
  }, {
    path: '~/:id',
    component: LkShortlinkComponent
  }, {
    path: 'sorry',
    component: PageUnavailableComponent
  }, {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
