import { Observable } from 'rxjs';
import { User } from '../_models/user.model';
import { BaseService } from './base.service';

export class TaskService extends BaseService {
  baseUrl = '/brs/task';

  widget(date: string) {
    return this.http.get<any>(this.baseUrl + '/widget?date=' + date, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  export1c(id) {
    return this.http.put<any>(this.baseUrl + '/export1c/' + id, {}, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  exportGroup1c(array: { ids: number[] }) {
    return this.http.put<any>(this.baseUrl + '/export1c/', array, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  users(id: number): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + '/users/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
