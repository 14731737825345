import { Component, ElementRef, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation } from 'angular-animations';
import * as moment from 'moment';
import { Moment } from 'moment';
import { User } from '../../_models/user.model';
import { PlanService } from '../../_services/plan.service';
import { TodoFindByTypeResult, TodoService } from '../../_services/todo.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'e2b-dashboard-sale',
  templateUrl: './dashboard-sale.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/statistic.min.css',
    '../../../assets/design/semantic-ui/components/card.min.css',
    '../../../assets/design/semantic-ui/components/list.min.css',
    './dashboard-sale.component.css'],
  animations: [
    fadeInUpOnEnterAnimation({ duration: 200 }),
    fadeOutDownOnLeaveAnimation({ duration: 200 })
  ],
  encapsulation: ViewEncapsulation.None
})
export class DashboardSaleComponent implements OnInit {

  data: TodoFindByTypeResult = {
    todos: []
  };
  @ViewChild('date', { static: false })
  date: ElementRef;
  day: Moment = null;
  detailData: any[] = [];
  detailOptions: {
    todoTypeId: number,
    type: 'PLANNED' | 'CLOSED' | 'EXPIRED',
    period: 'day' | 'week' | 'month'
  } = {
    todoTypeId: null,
    type: null,
    period: null
  };
  loaded = false;
  options: {
    date: string,
    userId: number,
  } = {
    date: null,
    userId: 0
  };
  period: 'day' | 'week' | 'month' = localStorage.getItem('e2b.dashboard.sale.period') ? <'day' | 'week' | 'month'>localStorage.getItem('e2b.dashboard.sale.period') : 'day';
  selectUser: EventEmitter<User> = new EventEmitter<User>();
  selectedDate;
  selectedUser: User;
  summary: {
    period?: string,
    summary: {
      bonus: {
        sum: number,
        coefficient: number
      },
      day: {
        sum: {
          plan: number,
          fact: number
        },
        prognosis: number,
        count: number
      },
      week: {
        sum: {
          plan: number,
          fact: number
        },
        prognosis: number,
        count: number
      },
      month: {
        sum: {
          plan: number,
          fact: number
        },
        prognosis: number,
        count: number
      },
      withoutCommunication: number,
      notValidPrognosis: number
    }
  } = {
    period: null,
    summary: {
      bonus: {
        sum: 0,
        coefficient: 0
      },
      withoutCommunication: 0,
      notValidPrognosis: 0,
      day: {
        sum: {
          plan: 0,
          fact: 0
        },
        prognosis: 0,
        count: 0
      },
      week: {
        sum: {
          plan: 0,
          fact: 0
        },
        prognosis: 0,
        count: 0
      },
      month: {
        sum: {
          plan: 0,
          fact: 0
        },
        prognosis: 0,
        count: 0
      }
    }
  };
  users: User[] = [];

  widgets = {
    'sales': 'Продажи',
    'bonus': 'Бонус',
    'prognosis': 'Прогноз',
    'deals': 'Новые сделки',
    'prognosisWrong': 'Неверный прогноз',
    'noCommunications': 'Нет коммуникаций',
  };
  selectedTodos: number[];
  selectedWidgets: string[] = localStorage.getItem('e2b.dashboard.sale.widgets') ? JSON.parse(localStorage.getItem('e2b.dashboard.sale.widgets')) : this.allWidgets;

  constructor(private userService: UserService,
              private planService: PlanService,
              private todoService: TodoService) {
  }

  getData() {

    this.data = {
      todos: []
    };

    this.summary = {
      period: null,
      summary: {
        bonus: {
          sum: 0,
          coefficient: 0
        },
        withoutCommunication: 0,
        notValidPrognosis: 0,
        day: {
          sum: {
            plan: 0,
            fact: 0
          },
          prognosis: 0,
          count: 0
        },
        week: {
          sum: {
            plan: 0,
            fact: 0
          },
          prognosis: 0,
          count: 0
        },
        month: {
          sum: {
            plan: 0,
            fact: 0
          },
          prognosis: 0,
          count: 0
        }
      }
    };


    this.planService.summary(this.options.userId.toString(), this.options.date).subscribe(summary => {
      this.summary = summary;
    });

    this.todoService.findByType(this.options.userId.toString(), this.options.date).subscribe(data => {
      this.data = data;
      this.selectedTodos = localStorage.getItem('e2b.dashboard.sale.todos')
        ? JSON.parse(localStorage.getItem('e2b.dashboard.sale.todos'))
        : this.data.todos.map(todo => todo.id);
    });
  }

  nextDay() {
    this.day = this.day.add(1, 'd');
    this.options.date = this.day.format('YYYY-MM-DD');
    localStorage.setItem('e2b.dashboard.sale.date', this.options.date);
    this.getData();
  }

  ngOnInit() {
    this.day = localStorage.getItem('e2b.dashboard.sale.date') ? moment(localStorage.getItem('e2b.dashboard.sale.date'), 'YYYY-MM-DD') : moment();
    this.options.date = this.day.format('YYYY-MM-DD');
    this.userService.sales<User>().subscribe(users => {
      this.users = users;
      if (this.users.find(u => u.id === this.userService.current().id)) {
        this.options.userId = this.userService.current().id;
        this.onSelectUser(this.users.find(u => u.id === this.userService.current().id));
      } else {
        this.onSelectUser(this.users[0]);
      }

      this.selectUser.subscribe(user => {
        this.onSelectUser(user);
        this.getData();
      });

      this.loaded = true;

      this.getData();
    });
  }

  get allWidgets(): string[] {
    return Object.keys(this.widgets);
  }

  selectWidget(widgetName) {
    if (this.selectedWidgets.includes(widgetName)) {
      const widgetIndex = this.selectedWidgets.indexOf(widgetName);
      this.selectedWidgets.splice(widgetIndex, 1);
    } else {
      this.selectedWidgets.push(widgetName);
    }
    localStorage.setItem('e2b.dashboard.sale.widgets', JSON.stringify(this.selectedWidgets));
  }

  selectTodo(todoId: number) {
    if (this.selectedTodos.includes(todoId)) {
      const todoIndex = this.selectedTodos.indexOf(todoId);
      this.selectedTodos.splice(todoIndex, 1);
    } else {
      this.selectedTodos.push(todoId);
    }
    this.selectedTodos = [...this.selectedTodos];
    localStorage.setItem('e2b.dashboard.sale.todos', JSON.stringify(this.selectedTodos));
  }

  onDate() {
    this.date.nativeElement.click();
  }

  onSelectDate($event) {
    if (!$event) {
      return false;
    }
    this.day = moment($event);
    this.options.date = this.day.format('YYYY-MM-DD');
    localStorage.setItem('e2b.dashboard.sale.date', this.options.date);
    this.getData();
  }

  onSelectPeriod(period: 'day' | 'week' | 'month') {
    this.period = period;
    localStorage.setItem('e2b.dashboard.sale.period', period);
    this.detailOptions = {
      todoTypeId: null,
      type: null,
      period: null
    };
  }

  onSelectTodoType(val: any, type: 'PLANNED' | 'CLOSED' | 'EXPIRED') {

    this.detailData = [];

    if (this.detailOptions.todoTypeId && this.detailOptions.todoTypeId === val.id && this.detailOptions.type === type) {
      this.detailOptions = {
        todoTypeId: null,
        type: null,
        period: null
      };
    } else {
      this.detailOptions.todoTypeId = val.id;
      this.detailOptions.type = type;
      this.detailOptions.period = this.period;

      this.todoService.todoDetail(this.detailOptions.type, this.detailOptions.period, this.detailOptions.todoTypeId, this.options.date, this.options.userId).subscribe(data => {
        this.detailData = data;
      });
    }
  }

  onSelectUser(user) {
    this.selectedUser = user;
    this.options.userId = user.id;
    this.detailOptions = {
      todoTypeId: null,
      type: null,
      period: null
    };
  }

  prevDay() {
    this.day = this.day.subtract(1, 'd');
    this.options.date = this.day.format('YYYY-MM-DD');
    localStorage.setItem('e2b.dashboard.sale.date', this.options.date);
    this.getData();
  }

  public getQueryFrom() {
    switch (this.period) {
      case 'day':
        return this.day.clone().startOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().startOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().startOf('month').format('YYYY-MM-DD');
    }
  }

  public getQueryTo() {
    switch (this.period) {
      case 'day':
        return this.day.clone().endOf('day').format('YYYY-MM-DD');
      case 'week':
        return this.day.clone().endOf('week').format('YYYY-MM-DD');
      case 'month':
        return this.day.clone().endOf('month').format('YYYY-MM-DD');
    }
  }
}
