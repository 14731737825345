<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui form">
        <h3>{{assemblySheme.name}}</h3>
        <table class="ui very basic celled table">
          <thead>
          <tr>
            <th>
              <div class="head">Название</div>
            </th>
            <th>
              <div class="head">Описание</div>
            </th>
            <th>
              <div class="head">Процент</div>
            </th>
            <th>
              <div class="head">Фото</div>
            </th>
            <th>
              <div class="head">Поле сделки</div>
            </th>
            <th>
              <div class="head">Компрессия фото</div>
            </th>
            <th>
              <div class="head">Разрешение фото</div>
            </th>
            <th>
              <div class="head">Проблемы</div>
            </th>
          </tr>
          </thead>
          <tbody *ngFor="let step of assemblySheme.steps | orderByColumn:'percent:number'">
          <tr>
            <td>
              <a *ngIf="userService.checkPermissions(['assembly-step', '/'], ['write'])" [routerLink]="['/catalog','assembly-scheme', 'step',  step.id, 'edit']">{{step.name}}</a>
              <span *ngIf="!userService.checkPermissions(['assembly-step', '/'], ['write'])">{{step.name}}</span>
            </td>
            <td>
              {{step.description}}
            </td>
            <td>
              {{step.percent}}
            </td>
            <td>
              <i class="ui green check icon" *ngIf="step.photoRequired"></i>
            </td>
            <td>
              {{step.field ? step.field.name : '-'}}
            </td>
            <td>
              {{step.compression}}
            </td>
            <td>
              {{step.resolution}}
            </td>
            <td>
              <div *ngIf="step.problemFields">
                <div class="ui label" *ngFor="let problem of step.problemFields">{{problem.name}}</div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
