import {Component} from '@angular/core';

@Component({
  selector: 'e2b-assembly-scheme',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class AssemblySchemeComponent {


  constructor() {
  }

}
