<form class="ui fluid form">
  <div class="ui stackable grid">
    <div class="ui eight wide column">
      <div class="ui stackable grid">
        <div class="ui four wide column d-flex align-items-center" style="border-right: 1px solid #fafafa;">
          <h4 class="header">Название клиента <sup style="color: red">*</sup></h4>
        </div>
        <div class="ui twelve wide column" style="padding-left: 40px;">
          <div class="field required">
            <div class="ui fluid input" [ngClass]="{'error': error && (!client.name || errorMessage)}">
              <input type="text" name="name" [(ngModel)]="client.name" placeholder="Название" required>
            </div>
          </div>
        </div>
        <div class="ui four wide column" style="border-right: 1px solid #fafafa;">
          <h4 class="header">Тип клиента <sup style="color: red">*</sup></h4>
        </div>
        <div class="ui twelve wide column" style="padding-left: 40px;">
          <div class="field">
            <sui-select class="selection" [ngClass]="{'error': error && !client.type}"
                        [(ngModel)]="client.type"
                        placeholder="Тип"
                        #selectClientType
                        labelField="name"
                        [ngModelOptions]="{standalone: true}">
              <sui-select-option *ngFor="let type of types" [value]="type">
              </sui-select-option>
            </sui-select>
          </div>
        </div>
        <div class="four wide column d-flex align-items-center" style="border-right: 1px solid #fafafa;">
          <h4 class="header">Менеджер <sup style="color: red">*</sup></h4>
        </div>
        <div class="twelve wide column" style="padding-left: 40px;">
          <div class="field">
            <e2b-staff-dropdown [error]="error && !client.manager.id" [staff]="managers" [selected]="client.manager" [event]="selectManager"></e2b-staff-dropdown>
          </div>
        </div>
        <div class="four wide column d-flex align-items-center" style="border-right: 1px solid #fafafa;">
          <h4 class="header">Дополнительная информация</h4>
        </div>
        <div class="twelve wide column" style="padding-left: 40px;">
          <div class="field">
            <textarea [(ngModel)]="client.description" name="description" placeholder="Дополнительная информация"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="ui eight wide column">
          <sui-message *ngIf="error && errorMessage">
            <div class="header">
              Ошибка
            </div>
            <p>
              Клиент с таким названием уже существует
            </p>
          </sui-message>
        </div>
      </div>
    </div>
    <div class="ui four wide column" style="padding-left: 40px;">
      <e2b-contact [contacts]="client.contacts" [saveEvent]="addContact"></e2b-contact>
    </div>
  </div>
</form>
<div class="buttons mt-2">
  <button class="ui button green" (click)="save()">Добавить</button>
  <button routerLink="/client/list" class="ui button red">Отмена</button>
</div>
