<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui text menu">
        <div class="right item" *ngIf="templates && templates.length > 0">
          <span class="fw-bold">Кол-во шаблонов: {{templates.length}}</span>
        </div>
      </div>
      <div class="ui form">
        <table class="ui very basic selectable celled table">
          <thead>
          <tr>
            <th>
              <div class="head">Название</div>
            </th>
            <th>
              <div class="head">Группы</div>
            </th>
            <th>
              <div class="head">Поля</div>
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let template of templates | orderByColumn:'name:string'" (click)="editTemplate(template.id)" class="pointer">
            <td width="20%">
              <div class="field">
                <input [disabled]="!userService.checkPermissions(['position-group-template', '/'], ['write'])" [(ngModel)]="template.name" (change)="save(template)">
              </div>
            </td>
            <td width="40%">
              <div *ngIf="editedTemplate !== template.id">
                <div class="ui label my-1" *ngFor="let tGroup of template.groups | orderByColumn:'name:string'">
                  <span *ngIf="tGroup && tGroup.name" [innerText]="tGroup.name"></span>
                </div>
              </div>
              <div *ngIf="editedTemplate === template.id">
                <e2b-postion-group-template-field-dropdown
                  [isDisabled]="!userService.checkPermissions(['position-group-template', '/'], ['write'])"
                  #selectGroup [placeholder]="'Группы'"
                  [array]="positionGroups"
                  [selectedGroups]="template.groups"
                  [event]="changeGroups(template)">
                </e2b-postion-group-template-field-dropdown>
              </div>
            </td>
            <td width="40%">
              <div *ngIf="editedTemplate !== template.id">
                <div class="ui label my-1" *ngFor="let dealField of template.fields | orderByColumn:'name:string'">
                  <span *ngIf="dealField && dealField.name" [innerText]="dealField.name"></span>
                </div>
              </div>
              <div *ngIf="editedTemplate === template.id">
                <e2b-postion-group-template-field-dropdown
                  [isDisabled]="!userService.checkPermissions(['position-group-template', '/'], ['write'])"
                  #selectField [placeholder]="'Поля'"
                  [array]="dealFields"
                  [selectedFields]="template.fields"
                  [event]="changeFields(template)">
                </e2b-postion-group-template-field-dropdown>
              </div>
            </td>
            <td width="5%">
              <i *ngIf="editedTemplate !== template.id && userService.checkPermissions(['position-group-template', '/'], ['write'])" class="ui red remove cursor icon m-0" (click)="remove(template)"></i>
              <i *ngIf="editedTemplate === template.id && userService.checkPermissions(['position-group-template', '/'], ['write'])" class="icon green check m-0" (click)="save(template)"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
