<span [attr.data-tooltip]="statusInfo[status]">
    <i class="clock outline icon"
       *ngIf="status === SmsStatus.ENQUEUED || status === SmsStatus.WAIT || status === SmsStatus.SCHEDULED"></i>
    <i class="check icon" *ngIf="status === SmsStatus.ACCEPTED"></i>
    <i class="icons" *ngIf="status === SmsStatus.DELIVERED">
      <i class="check inverted blue icon"></i>
      <i class="check inverted blue icon" style="margin-left: 3px"></i>
    </i>
    <i class="exclamation circle red icon"
       *ngIf="status === SmsStatus.NON_DELIVERED || status === SmsStatus.CANCELED || status === SmsStatus.OVERDUE">
    </i>
</span>



