import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DealStatus } from '../../_models/deal-status.model';
import { Deal } from '../../_models/deal.model';
import { UserGroup } from '../../_models/user-group.model';
import { DealStatusService } from '../../_services/deal-status.service';
import { DealService } from '../../_services/deal.service';
import { ActionService } from '../../action/action.service';

interface CalendarDeal {
  id: number;
  name: string;
  currentStatus: { id: number; name: string; color: string };
  filial: { id: number; name: string };
  totalSum: number;
  positions: Array<{ id: number; name: string; price: number; count: number }>;
  properties: {
    agt: boolean;
    alvic: boolean;
    smart: boolean;
    fil: boolean;
    custom: boolean;
  };
  manager: { id: number; name: string };
  client: { id: number; name: string };
  mounting: boolean;
  coefficient: number;
}


@Component({
  selector: 'e2b-deal-calendar',
  templateUrl: './deal-calendar.component.html',
  styleUrls: ['../../../assets/design/semantic-ui/components/loader.min.css',
              '../../../assets/design/semantic-ui/components/table.min.css',
              './deal-calendar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DealCalendarComponent implements OnInit, OnDestroy {

  dealStatuses: DealStatus[] = [];
  filter = new Deal();
  filteredList = 0;
  filtered: any = {};
  loaded = false;
  coefficients: any = {};
  teamCount = 0;
  startOfWeek = localStorage.getItem('e2b.deal-calendar.start') ? moment(localStorage.getItem('e2b.deal-calendar.start')) : moment().startOf('week');
  userGroup: UserGroup;
  private dealSubscribe: Subscription;
  private dealStatusesSubscribe: Subscription;
  week: string[];
  data: {
    production: {
      suppliers: Array<{
        name: string;
        dates: {
          [date: string]: {
            deals: CalendarDeal[];
            coefficient: number;
            maxCoefficient: number;
          }
        }
      }>;
      dates: {
        [date: string]: {
          coefficient: number;
          maxCoefficient: number;
        }
      };
    };
    mounting: {
      dates: {
        [date: string]: {
          deals: CalendarDeal[];
          total: number;
          max: number;
        }
      }
    };
    max: {
      maxMounts: number;
      suppliers: {
        [name: string]: number;
      };
    };
  };

  constructor(private dealService: DealService,
              private dealStatusService: DealStatusService,
              private actionService: ActionService) {
  }

  ngOnInit() {

    this.setWeek();

    this.actionService.createMenuEvent.emit({ name: 'Добавить сделку', link: '/deal/add', class: 'blue' });

    this.dealStatusesSubscribe = this.dealStatusService.find<DealStatus>().subscribe((dealStatuses: DealStatus[]) => {

      dealStatuses.forEach((s, k) => {
        dealStatuses[k].color = s.color.replace('#', 'X');
      });

      this.dealStatuses = dealStatuses;

      if (localStorage.getItem('e2b.deal-calendar.filter')) {
        this.filter.currentStatus = JSON.parse(localStorage.getItem('e2b.deal-calendar.filter'));
      }

      this.loadPeriod();
    });
  }

  loadPeriod() {

    this.data = {
      production: {
        suppliers: [],
        dates: {}
      },
      mounting: {
        dates: {}
      },
      max: {
        suppliers: {},
        maxMounts: 0
      }
    };

    this.week.map(w => {
      this.data.mounting.dates[w] = {
        deals: [],
        total: 0,
        max: 0
      };
    });

    this.loaded = false;

    const options: any = {
      from: this.startOfWeek.format('YYYY-MM-DD'),
      to: this.startOfWeek.clone().endOf('week').format('YYYY-MM-DD')
    };

    if (this.filter.currentStatus && this.filter.currentStatus.id) {
      options.statusId = this.filter.currentStatus.id;
    }

    this.dealSubscribe = this.dealService.calendar(options.from, options.to, options.statusId).subscribe((result) => {
      this.data = result;
      this.loaded = true;
    });
  }

  applyStatusFilter(filter) {

    this.filter.currentStatus = filter;

    localStorage.setItem('e2b.deal-calendar.filter', JSON.stringify(filter));

    this.loadPeriod();
  }

  clearFilter() {

    this.filter = new Deal();

    this.filter.currentStatus = new DealStatus();

    localStorage.removeItem('e2b.deal-calendar.filter');

    this.loadPeriod();
  }

  nextWeek() {

    this.startOfWeek = this.startOfWeek.add(7, 'day');

    localStorage.setItem('e2b.deal-calendar.start', this.startOfWeek.format('YYYY-MM-DD'));

    this.setWeek();

    this.loadPeriod();
  }

  prevWeek() {

    this.startOfWeek = this.startOfWeek.subtract(7, 'day');

    localStorage.setItem('e2b.deal-calendar.start', this.startOfWeek.format('YYYY-MM-DD'));

    this.setWeek();

    this.loadPeriod();
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  setWeek() {
    this.week = [
      this.startOfWeek.clone().format('DD.MM.YYYY'),
      this.startOfWeek.clone().add(1, 'day').format('DD.MM.YYYY'),
      this.startOfWeek.clone().add(2, 'day').format('DD.MM.YYYY'),
      this.startOfWeek.clone().add(3, 'day').format('DD.MM.YYYY'),
      this.startOfWeek.clone().add(4, 'day').format('DD.MM.YYYY'),
      this.startOfWeek.clone().add(5, 'day').format('DD.MM.YYYY'),
      this.startOfWeek.clone().add(6, 'day').format('DD.MM.YYYY'),
    ];
  }

  notWeekend(day) {
    return [6, 7].indexOf(moment(day, 'DD.MM.YYYY').isoWeekday()) === -1;
  }

  supplierSum() {
    let r = 0;
    if (!this.data || Object.keys(this.data.max.suppliers).length === 0) {
      return false;
    }
    Object.keys(this.data.max.suppliers).map(s => {
      r += this.data.max.suppliers[s];
    });
    return r;
  }

  ngOnDestroy() {
    if (this.dealSubscribe) {
      this.dealSubscribe.unsubscribe();
    }
    if (this.dealStatusesSubscribe) {
      this.dealStatusesSubscribe.unsubscribe();
    }
  }

}
