import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseModel } from '../_models/base.model';
import { Observable } from 'rxjs';

@Injectable()
export class OrderPositionService extends BaseService {
  baseUrl = '/order-position';

  calcCost<T>(positionId): Observable<number> {
    return this.http.post<number>(this.baseUrl + '/calculate', { id: positionId }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
