import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'e2b-unavailable',
  templateUrl: './page-unavailable.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/container.min.css',
    '../../assets/design/semantic-ui/components/grid.min.css',
  ],
  encapsulation: ViewEncapsulation.None
})
export class PageUnavailableComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
