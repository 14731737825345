<form class="ui form">
  <div class="ui stackable grid">
    <div class="ui thirteen wide column grid">
      <div class="row">
        <h2 class="editable" *ngIf="!editName" (click)="editNameChange()">{{contractor.name}}</h2>
        <div class="field" style="width: auto !important;" *ngIf="editName">
          <div class="ui big action input" style="width: auto !important;">
            <input type="text" [focus]="contractorNameFocus" placeholder="Наименование"
                   [ngModelOptions]="{name: 'name'}" [(ngModel)]="contractor.name">
            <button class="ui primary button" (click)="editNameTrigger()"><i class="ui icon save"></i></button>
          </div>
        </div>
        <sui-message class="error" *ngIf="err">
          <div class="header">
            {{err.name}}
          </div>
          <p>{{err.code}}</p>
        </sui-message>
      </div>
      <div class="row">
        <div class="ui four wide column">
          <h3 class="header">Тип клиента</h3>
          <p class="item" *ngIf="!editContractorType">
            <span>{{contractor.type.name}}</span>
            <i class="ui icon edit blue pointer" (click)="editContractorType = true"></i>
          </p>
          <div class="field" *ngIf="editContractorType">
            <e2b-client-type-dropdown [clientTypes]="types" [event]="selectClientType"></e2b-client-type-dropdown>
          </div>
        </div>
        <div class="ui four wide column">
          <h3 class="header">Ответственный</h3>
          <p class="item" *ngIf="!editManager">
            <a [routerLink]="['/staff/card/', contractor.manager.id]">{{contractor.manager.name}}</a>
            <i class="ui icon edit blue pointer" (click)="editManager = true"></i>
          </p>
          <div class="field" *ngIf="editManager">
            <e2b-staff-dropdown [staff]="managers" [event]="selectManager"></e2b-staff-dropdown>
          </div>
        </div>
        <!--<div class="ui four wide column">-->
        <!--<h3 class="header">Канал привлечения</h3>-->
        <!--<p class="item" *ngIf="!editChannel" (click)="edit()">{{contractor.channel.name}}</p>-->
        <!--<div class="field" *ngIf="editChannel">-->
        <!--<sui-select class="selection mx-auto"-->
        <!--placeholder="Канал привлечения"-->
        <!--[isSearchable]="false"-->
        <!--[isDisabled]="false"-->
        <!--[options]="channels"-->
        <!--[(ngModel)]="contractor.channel"-->
        <!--[ngModelOptions]="{name: 'channel'}"-->
        <!--labelField="name"-->
        <!--#selectChannel>-->
        <!--<sui-select-option *ngFor="let channel of selectChannel.filteredOptions"-->
        <!--[value]="channel" (click)="save()">-->
        <!--</sui-select-option>-->
        <!--</sui-select>-->
        <!--</div>-->
        <!--</div>-->
      </div>
      <div class="row">
        <div class="fifteen wide column">
          <p *ngIf="!editDescription">{{contractor.description}}</p>
          <div class="field" *ngIf="editDescription">
          <textarea class="w-100"
                    *ngIf="editDescription"
                    [ngModel]="contractor.description"
                    name="description"
                    placeholder="Дополнительная информация">
          </textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="fifteen wide column">
          <h3 class="header w-100 text-center">Сделки</h3>
          <table class="ui selectable very padded table">
            <thead>
            <tr>
              <th>
                <div class="head">№ Сделки</div>
              </th>
              <th>
                <div class="head">Статус</div>
              </th>
              <th>
                <div class="head">Заказ</div>
              </th>
              <th>
                <div class="head">Сумма</div>
              </th>
              <th>
                <div class="head">Дела</div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let deal of contractor.deals" role="row">
              <td>
                <p>
                  <a [routerLink]="['/deal/card/', deal.id]">{{deal.name}}</a>
                </p>
              </td>
              <td>
                <p>{{deal.currentStatus.name}}</p>
              </td>
              <td>
                <p *ngFor="let position of deal.positions">
                  {{position.position.name}}<span> * </span><span>{{position.count}}</span> *
                  <span>{{position.price | number}} руб.</span>
                </p>
              </td>
              <td>
                <b>{{totalDealSum(deal.positions)| number}} руб.</b>
              </td>
              <td>
                <p>
                  <a [routerLink]="['/todo/list']" [queryParams]="{dealId: deal.id}">
                    <!-- Кол-во Дел -->
                  </a>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="ui three wide column grid">
      <div class="row" *ngIf="contractor.contacts">
        <h3 class="text-center w-100 d-flex justify-content-between">
          Контакты клиента <i [routerLink]="['/client/contacts/add']" [queryParams]="contractor.id"
                              class="icon plus circle"></i>
        </h3>
        <sui-accordion [closeOthers]="true" class="w-100">
          <sui-accordion-panel [isOpen]="false" [isDisabled]="false" *ngFor="let contact of contractor.contacts">
            <div title>
              <i class="dropdown icon" (click)="panelOpen = !panelOpen"></i>
              {{contact.name}}
            </div>
            <div content>
              <p *ngIf="contact.email">Email: <a href="mailto:{{contact.email}}">{{contact.email}}</a></p>
              <p *ngIf="contact.phone">Телефон: <a href="tel:{{contact.phone}}">{{contact.phone}}</a></p>
              <p *ngIf="contact.address">Адрес: {{contact.address}}</p>
              <p *ngIf="contact.description">Дополнительная информация: {{contact.description}}</p>
            </div>
          </sui-accordion-panel>
        </sui-accordion>
      </div>
    </div>
  </div>
</form>
