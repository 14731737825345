import { BaseModel } from './base.model';
import { PositionCloth } from './position-cloth.model';
import { Position } from './position.model';
import { User } from './user.model';

export class PositionClothPrice extends BaseModel {
  price: number;
  cost: number;
  cloth: PositionCloth;
  position: Position;
  user: User;
  F;
}
