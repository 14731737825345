import { Pipe, PipeTransform } from '@angular/core';
import { NotificationSettingsTypeEnum } from '../_models/enums/notification-settings-type.enum';

const notificationSettingsNames = {
  [NotificationSettingsTypeEnum.COMMENT]: 'Общий комментарий к сделке',
  [NotificationSettingsTypeEnum.MOUNTING_PROBLEM]: 'Проблема на монтаже',
  [NotificationSettingsTypeEnum.RECLAMATION]: 'Новая рекламация',
  [NotificationSettingsTypeEnum.WHATSAPP]: 'WhatsApp',
};

@Pipe({
  name: 'notificationSettingsType'
})
export class NotificationSettingsTypePipe implements PipeTransform {

  transform(notificationSettingsType: NotificationSettingsTypeEnum): string {
    return notificationSettingsNames[notificationSettingsType] || '';
  }

}
