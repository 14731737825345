<ng-template let-option #dealFieldOptionTemplate>
  {{option.name}}
</ng-template>
<sui-select class="fluid selection mx-auto"
            placeholder="Поле"
            [isSearchable]="true"
            [isDisabled]="false"
            [(ngModel)]="selectedField"
            [ngModelOptions]="{name: 'field'}"
            (ngModelChange)="save()"
            [options]="dealFields"
            labelField="name"
            [optionTemplate]="dealFieldOptionTemplate"
            #selectDealField>
  <sui-select-option *ngFor="let m of selectDealField.filteredOptions | orderByColumn:'name:string'"
                     [value]="m">
  </sui-select-option>
</sui-select>
