import { Pipe, PipeTransform } from '@angular/core';
import { DealPosition } from '../_models/deal-position.model';
import { OrderType } from '../_models/enums/order-type.enum';
import { OrderModeService } from '../deal/order/order-mode.service';
import { OrderTypeNamePipe } from './order-type-name.pipe';
import { PositionTypePipe } from './position-type.pipe';

@Pipe({
  name: 'orderPositionFilter'
})
export class OrderPositionFilterPipe implements PipeTransform {
  constructor(private orderModeService: OrderModeService,
              private positionTypePipe: PositionTypePipe,
              private orderTypeNamePipe: OrderTypeNamePipe) {
  }

  transform(positions: DealPosition[], orderType: OrderType, positionsType: number): DealPosition[] {
    return positions.map(position => {
      delete position.disabled;
      delete position.errorMessage;
      const hasType = position.position.type > 0 && position.position.type < 5;

      let availableForOrderType = Boolean(orderType);
      let availableForProduction = true;
      if (orderType === OrderType.MOUNTING) {
        availableForOrderType = position.position.type === 1;
      }
      if (orderType === OrderType.PRODUCTION && positionsType) {
        availableForProduction = position.position.type === positionsType;
      }

      if (!availableForProduction) {
        position.errorMessage = `Тип позиции не соответствует типу позиций заказа: '${this.positionTypePipe.transform(positionsType)}'`;
      }
      if (!hasType) {
        position.errorMessage = 'Нельзя оформить заказ на данный тип позиции';
      }
      if (orderType && !availableForOrderType) {
        position.errorMessage = 'Позиция недоступна для данного типа заказа';
      }

      if (orderType && orderType !== OrderType.RECLAMATION) {
        const sameTypeOrders = this.orderModeService.orders.filter(order => order.type === orderType);
        for (const order of sameTypeOrders) {
          const containedInSomeOrder = order.orderDealPositions.some(orderDealPosition => orderDealPosition.dealPosition.id === position.id);
          if (containedInSomeOrder) {
            availableForOrderType = false;
            position.errorMessage = `Позиция уже содержится в заказе №${order.id}(${this.orderTypeNamePipe.transform(order.type)})`;
            break;
          }
        }
      }

      if (!hasType || !availableForOrderType || !availableForProduction) {
        position.disabled = true;
      }
      return position;
    });
  }
}
