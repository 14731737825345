import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { PositionPersonal } from '../../_models/position-personal.model';
import { PositionPersonalService } from '../../_services/position-personal.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-personal-list',
  templateUrl: './personal-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class PersonalListComponent implements OnInit {

  positionPersonals: PositionPersonal[] = [];
  loaded = false;
  currentPersonal: PositionPersonal | boolean = false;

  constructor(private actionService: ActionService,
              private userService: UserService,
              private positionPersonalService: PositionPersonalService) {
  }

  ngOnInit() {

    const addEvent = new EventEmitter();

    if (this.userService.checkPermissions(['position-personal', '/'], ['write'])) {
      this.actionService.createActionEvent.emit({
        name: 'Добавить',
        event: addEvent,
        class: 'blue'
      });
    }

    const updateEvent = new EventEmitter();

    this.actionService.createActionEvent.emit({
      name: 'Обновить',
      event: updateEvent,
      class: 'blue',
      icon: 'sync'
    });

    updateEvent.subscribe(() => {
      this.load();
    });

    addEvent.subscribe(() => {
      this.add();
    });
    this.load();
  }

  load() {
    this.loaded = false;
    this.positionPersonals = [];

    this.positionPersonalService.find<PositionPersonal>().subscribe(pp => {
      this.positionPersonals = pp;
      this.loaded = true;
    });
  }

  save(positionPersonal: PositionPersonal) {
    if (!positionPersonal.name) {
      return false;
    }
    if (positionPersonal.id) {
      this.positionPersonalService.update(positionPersonal).subscribe(() => {
      }, e => {
        console.error(e);
      });
    } else {
      this.positionPersonalService.create<PositionPersonal>(positionPersonal).subscribe(created => {
        this.positionPersonals[this.positionPersonals.indexOf(positionPersonal)] = created;
      }, e => {
        console.error(e);
      });
    }
  }

  add() {
    const m = new PositionPersonal();
    this.positionPersonals.unshift(m);
  }

  remove(positionPersonal) {
    if (!positionPersonal.id) {
      this.positionPersonals.splice(this.positionPersonals.indexOf(positionPersonal), 1);
      return false;
    }
    const yes = confirm('Вы уверены, что хотите удалить индивидуальные изменения ' + positionPersonal.name + '?');
    if (!yes) {
      return true;
    }
    this.positionPersonalService.deleteById(positionPersonal.id).subscribe(() => {
      this.positionPersonals.splice(this.positionPersonals.indexOf(positionPersonal), 1);
    });
  }
}
