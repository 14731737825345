import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Person } from '../../_models/person.model';
import { PersonService } from '../../_services/person.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-person',
  templateUrl: './person.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/table.min.css',
    './person.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PersonComponent implements OnInit, OnDestroy {
  loaded = false;
  persons: Person[] = [];
  private listSubscribe: Subscription;

  constructor(private actionService: ActionService,
              private userService: UserService,
              private personService: PersonService) {
  }

  ngOnInit() {
    if (this.userService.checkPermissions(['person', '/'], ['write'])) {
      this.actionService.createMenuEvent.emit({ name: 'Добавить плательщика', link: '/client/person/add', class: 'blue active' });
    }
    this.listSubscribe = this.personService.find<Person>().subscribe(persons => {
      this.persons = persons;
      this.loaded = true;
    });
  }

  ngOnDestroy() {
    this.listSubscribe.unsubscribe();
  }
}
