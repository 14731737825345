<sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
  <div class="ui text loader">Загрузка...</div>
</sui-dimmer>
<div class="ui fluid basic card" style="margin-top: 0 !important;">
  <div class="content">
    <div class="header">
      <div class="d-flex justify-content-center align-items-center">
        <button class="ui button" (click)="prevDay();">
          <i class="angle left icon"></i>
        </button>
        &nbsp;&nbsp;
        <h3 class="fw-bold m-0 header">
          <input suiDatepicker
                 #date
                 style="width: 0 !important; height: 0 !important; border: 0 !important; padding: 0 !important;"
                 [(ngModel)]="selectedDate"
                 (ngModelChange)="onSelectDate($event)"
                 [pickerMode]="'date'"
                 [pickerUseNativeOnMobile]="true">
          {{componentLabel}}&nbsp;&nbsp;<a (click)="onDate()">{{day.format('DD MMM, YY г.')}}</a>&nbsp;&nbsp;
        </h3>
        <e2b-staff-simple-dropdown class="fsz-14px" [staff]="users" [selected]="selectedUser" [event]="selectUser"></e2b-staff-simple-dropdown>
        &nbsp;&nbsp;
        <button class="ui button" (click)="nextDay();">
          <i class="angle right icon"></i>
        </button>
        <div class="ui dropdown ml-10px" suiDropdown style="display: inline !important;">
          <i class="blue cog icon"></i>
          <div class="menu" suiDropdownMenu>
            <a class="item" *ngFor="let widget of data | orderByColumn:'sort:number'">
              <div class="field">
                <input type="checkbox" [checked]="selectedWidgets.includes(widget.id)" (change)="selectWidget(widget.id)"/>
                <label> {{widget.name}}</label>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui stackable centered cards">
  <div class="ui coordinator card" [ngClass]="{'active': selectedControlpoint && selectedControlpoint.id === val.id}" *ngFor="let val of data | dashboardWidgets:selectedWidgets | orderByColumn:'sort:number'">
    <div class="content">
      <div class="header" style="font-size: 13px !important; text-overflow: ellipsis !important;padding-bottom: 7px !important;">
        {{val.name}}
      </div>
      <div class="description">
        <div class="ui tiny statistic">
          <div class="value">
            <a class="padded" [ngClass]="{'blue': val.new > 0, 'grey': val.new === 0, 'active': selectedControlpoint && selectedControlpoint.id === val.id && detailOptions.status === 'NEW'}" (click)="onSelectTodo(val, 'NEW')">{{val.new}}</a>
            <a class="padded" [ngClass]="{'green': val.all > 0, 'grey': val.all === 0, 'active': selectedControlpoint && selectedControlpoint.id === val.id && detailOptions.status === 'ALL'}" (click)="onSelectTodo(val, 'ALL')">{{val.all}}</a>
            <a class="red padded" [ngClass]="{'active': selectedControlpoint && selectedControlpoint.id === val.id && detailOptions.status === 'OVERDUE'}" *ngIf="val.overdue > 0" (click)="onSelectTodo(val, 'OVERDUE')">{{val.overdue}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ui stackable grid" *ngIf="this.detailOptions.controlpointId" [@fadeInUpOnEnter] [@fadeOutDownOnLeave]>
  <div class="ui twelve wide column">
    <div style="margin-top: 40px;">
      <table class="ui very padded selectable stackable celled table">
        <thead>
        <tr>
          <th>
            <div class="head">Сделка</div>
          </th>
          <th>
            <div class="head">Дело</div>
          </th>
          <th>
            <div class="head">Клиент</div>
          </th>
          <th *ngFor="let field of fields[this.detailOptions.controlpointId]">
            <div class="head">{{field}}</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let deal of detailData | orderByColumn:'checkpoint.until:object:date' | orderByColumn:'-checkpoint.done:object:boolean'" [ngClass]="{'dealDone': deal.checkpoint.done}">
          <td nowrap>
            <div e2b-filial-label [filial]="deal.filial"></div>
            <a class="d-inline-block v-align-top w-50" [routerLink]="['/deal/card/', deal.id]" target="_blank">{{deal.name}}</a>
            <span class="greytext d-block w-100">{{deal.created ? (deal.created | date:'short') : "-"}}</span>
          </td>
          <td [ngClass]="{'alert': (!deal.checkpoint.done && ((deal.checkpoint.until | dateDiff) > 0))}">
            <i style="float:left;" class="ui green {{getIcon(deal.checkpoint.type)}} icon"></i>
            <div *ngIf="deal.checkpoint.group" [ngClass]="{'red': (!deal.checkpoint.done && ((deal.checkpoint.until | dateDiff) > 0))}">
              {{deal.checkpoint.group}}
            </div>
            <span [ngClass]="{'red': (!deal.checkpoint.done && ((deal.checkpoint.until | dateDiff) > 0))}">{{deal.checkpoint.name}}</span>
            <div class="fw-bold" [ngClass]="{'red': (!deal.checkpoint.done && ((deal.checkpoint.until | dateDiff) > 0))}">до {{deal.checkpoint.until | date:'dd.MM.yyyy HH:mm'}}</div>
            <button class="ui primary button" (click)="markAsDone(deal)" style="float:right" *ngIf="['COMMENT_PRODUCTION_DEVIATION', 'COMMENT_QUALITY_CONTROL'].indexOf(deal.checkpoint.type) > -1 && !deal.checkpoint.done">Выполнить</button>
          </td>
          <td nowrap>
            <a [routerLink]="['/client/card', deal.client.id]" target="_blank">{{deal.client.name}}</a><br/>
            <a href="tel:{{deal.client.phone}}">{{deal.client.phone|phone}}</a>
          </td>
          <td *ngFor="let field of fields[this.detailOptions.controlpointId]">
            <span *ngIf="field === 'Дата монтажа'">{{deal.mountingDate ? (deal.mountingDate | date:'dd.MM.yyyy') : ''}}</span>
            <span *ngIf="field === 'Дата и время монтажа'">{{deal.mountingDatetime ? (deal.mountingDatetime | date:'dd.MM.yyyy HH:mm') : ''}}</span>
            <span *ngIf="field === 'Дата и время доставки'">{{deal.deliveryDatetime ? (deal.deliveryDatetime | date:'dd.MM.yyyy HH:mm') : ''}}</span>
            <span *ngIf="field === 'Доставщик'">{{deal.deliveryUser ? deal.deliveryUser.name : '-'}}</span>
            <span *ngIf="field === 'Монтажник'">{{deal.mountingUser ? deal.mountingUser.name : '-'}}</span>
            <div *ngIf="field === 'Статус доставки'">
              <div [class]="'ui rounded ' + deliveryStatus[deal.deliveryStatus].color + ' label'" [innerHTML]="deal.deliveryStatus.toUpperCase()"></div>
            </div>
            <div *ngIf="field === 'Статус монтажа'">
              <div>
                <div [class]="'ui rounded ' + mountingStatus[deal.step].color + ' label'" [innerHTML]="mountingStatus[deal.step].description"></div>
              </div>
              <div class="ui horizontal list" style="vertical-align: top;" *ngIf="deal.step > 0">
                <ng-template *ngFor="let position of deal.positions">
                  <div class="item" style="margin: 0 0.1em; vertical-align: top;" *ngIf="position.type === 1">
                    <div class="content">
                      <div [class]="'ui rounded ' + mountingStatus[position.step].color + ' label'">
                        <p class="mb-1" [innerText]="position.name | truncate : 15 : false"></p>
                        <p class="mb-1 fw-bold" [innerText]="mountingStatus[position.step].description"></p>
                        <p class="mb-1" [innerText]="position.date"></p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <span *ngIf="field === 'Проблема'">{{deal.problem}}</span>
            <div *ngIf="field === 'Позиции'">
              <div class="ui horizontal list" style="vertical-align: top;">
                <div *ngFor="let position of deal.positions" class="item" style="margin: 0 0.1em; vertical-align: top;">
                  <div class="content">
                    <div [class]="'ui rounded ' + productionStatus[position.status].color + ' label'">
                      <p class="mb-1" [innerText]="position.name | truncate : 15 : false"></p>
                      <p class="mb-1 fw-bold" [innerText]="position.status"></p>
                      <p class="mb-1" [innerText]="position.productionDate ? position.productionDate : ''"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <br/>
  </div>
</div>
