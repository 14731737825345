<form class="ui form" (submit)="save()">
  <div class="ui grid">
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Название <sup style="color: red">*</sup></h4>
      </div>
      <div class="six wide column">
        <div class="field required">
          <input id="name" type="text" name="name" [(ngModel)]="group.name" placeholder="Название" required>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Артикул <sup style="color: red">*</sup></h4>
      </div>
      <div class="six wide column required">
        <input type="text" name="article" placeholder="Артикул" [(ngModel)]="group.article" required>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Шаблон</h4>
      </div>
      <div class="six wide column">
        <ng-template let-option #groupOptionTemplate>
          {{option.name}}
        </ng-template>
        <sui-select class="fluid selection mx-auto" [ngClass]="{'error': error}"
                    placeholder="Шаблон"
                    [isSearchable]="true"
                    [isDisabled]="false"
                    [(ngModel)]="group.template"
                    [ngModelOptions]="{name: 'template'}"
                    [options]="templates"
                    labelField="name"
                    [optionTemplate]="groupOptionTemplate"
                    #selectTemplate>
          <!--(ngModelChange)="save()"-->
          <sui-select-option *ngFor="let template of selectTemplate.filteredOptions | orderByColumn:'name:string'"
                             [value]="template">
          </sui-select-option>
        </sui-select>
      </div>
    </div>
  </div>
</form>
<div class="mt-2">
  <button type="submit" (click)="save()" class="ui button green"
          [disabled]="(!group.name || group.name === '') && (!group.article || group.article === '')">
    Добавить
  </button>
  <button (click)="cancel()" class="ui button red">Отмена</button>
</div>
