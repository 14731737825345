<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
  </div>
  <div class="ui six wide column" *ngIf="loaded">
    <div class="ui form">
      <table class="ui very very basic table">
        <tr>
          <td>
            <h4 class="header">Название</h4>
          </td>
          <td>
            <div class="field">
              <input type="text" [(ngModel)]="dealStep.name" (ngModelChange)="save()" [ngModelOptions]="{updateOn: 'blur'}">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Описание</h4>
          </td>
          <td>
            <div class="field">
              <input type="text" [(ngModel)]="dealStep.description" (ngModelChange)="save()" [ngModelOptions]="{updateOn: 'blur'}">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Процент</h4>
          </td>
          <td>
            <div class="field">
              <input type="number" step="1" max="100" [(ngModel)]="dealStep.percent" (ngModelChange)="save()" [ngModelOptions]="{updateOn: 'blur'}">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Тип</h4>
          </td>
          <td>
            <div class="field">
              <select [(ngModel)]="dealStep.appView" (change)="save()">
                <option *ngFor="let o of appViews" [selected]="dealStep.appView === o.name" value="{{o.name}}">{{o.translation}}</option>
              </select>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Фото</h4>
          </td>
          <td>
            <div class="field">
              <sui-checkbox [(ngModel)]="dealStep.photoRequired" (ngModelChange)="save()"></sui-checkbox>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Начало позиций</h4>
          </td>
          <td>
            <div class="field">
              <sui-checkbox [(ngModel)]="dealStep.assemblyStarts" (ngModelChange)="save()"></sui-checkbox>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Конец позиций</h4>
          </td>
          <td>
            <div class="field">
              <sui-checkbox [(ngModel)]="dealStep.assemblyEnds" (ngModelChange)="save()"></sui-checkbox>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Поле сделки</h4>
          </td>
          <td>
            <div class="field">
              <e2b-deal-field-dropdown [dealFields]="fields" [selected]="dealStep.field" [event]="selectStepField()"></e2b-deal-field-dropdown>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Компрессия фото, %</h4>
          </td>
          <td>
            <div class="field">
              <input type="number" step="1" max="100" [(ngModel)]="dealStep.compression" (ngModelChange)="save()" [ngModelOptions]="{updateOn: 'blur'}">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Разрешение фото</h4>
          </td>
          <td>
            <div class="field">
              <input type="number" step="1" max="2440" [(ngModel)]="dealStep.resolution" (ngModelChange)="save()" [ngModelOptions]="{updateOn: 'blur'}">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Проблемы</h4>
          </td>
          <td>
            <div class="field">
              <e2b-entity-multi-dropdown [selected]="dealStep.problemFields" [entities]="problemFields" [event]="selectStepProblem()" [name]="'Поля проблем'"></e2b-entity-multi-dropdown>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
