import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientChannel } from '../../_models/client-channel.model';
import { ClientContact } from '../../_models/client-contact.model';
import { ClientType } from '../../_models/client-type.model';
import { Client } from '../../_models/client.model';
import { Comment } from '../../_models/comment.model';
import { DealPosition } from '../../_models/deal-position.model';
import { Deal } from '../../_models/deal.model';
import { User } from '../../_models/user.model';
import { ClientChannelService } from '../../_services/client-channel.service';
import { ClientTypeService } from '../../_services/client-type.service';
import { ClientService } from '../../_services/client.service';
import { CommentService } from '../../_services/comment.service';
import { ContactService } from '../../_services/contact.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-client-show',
  templateUrl: './client-show.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/icon.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css',
    '../../../assets/design/semantic-ui/components/button.min.css',
    '../../../assets/design/semantic-ui/components/table.min.css',
    '../../../assets/design/semantic-ui/components/menu.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    './client-show.component.css'
  ],
  encapsulation: ViewEncapsulation.None
})

export class ClientShowComponent implements OnInit, OnDestroy {
  private channelSubscribe: Subscription;
  private typeSubscribe: Subscription;
  private managerSubscribe: Subscription;
  private clientSubscribe: Subscription;
  id: number;
  client: Client = new Client();
  comment: Comment = new Comment();
  comments: Comment[] = [];
  channels: ClientChannel[] = [];
  managers: User[] = [];
  types: ClientType[] = [];
  loaded = false;
  editManager = false;
  editChannel = false;
  editClientType = false;
  editName = false;
  editDescription = false;
  panelOpen = false;
  err: { name: string, code: number } | boolean = false;
  @ViewChild('clientName', { static: false })
  clientNameElement: ElementRef;
  addContact = new EventEmitter<ClientContact>();
  selectManager: EventEmitter<User> = new EventEmitter<User>();
  selectClientType: EventEmitter<ClientType> = new EventEmitter<ClientType>();
  clientNameFocus = false;

  constructor(private route: ActivatedRoute,
              private clientService: ClientService,
              private contactService: ContactService,
              private commentService: CommentService,
              private actionService: ActionService,
              private clientTypeService: ClientTypeService,
              private clientManagerService: UserService,
              private router: Router,
              private clientChannelService: ClientChannelService,
              private titleService: Title) {

    this.id = parseInt(this.route.snapshot.params.id, 0);
    this.client.deals = Array<Deal>();
    this.client.type = new ClientType();
    this.client.manager = new User();
    this.client.channel = new ClientChannel();

    this.selectManager.subscribe(user => {
      this.client.manager = user;
      this.clientService.update<Client>(<Client>{ id: this.client.id, manager: { id: this.client.manager.id } }).subscribe(() => {
        this.err = false;
      }, e => {
        this.err = e.error;
      });
      this.editManager = false;
    });

    this.addContact.subscribe((contact: ClientContact) => {
      contact.client = new Client();
      contact.client.id = this.client.id;
      this.contactService.create<ClientContact>(contact).subscribe(created => {
        this.client.contacts.push(created);
      }, console.error);
    });

    this.selectClientType.subscribe(clientType => {
      this.client.type = clientType;
      this.clientService.update<Client>(<Client>{ id: this.client.id, type: { id: this.client.type.id } }).subscribe(() => {
        this.err = false;
      }, e => {
        this.err = e.error;
      });
      this.editClientType = false;
    });

  }

  ngOnInit() {
    const removeEvent = new EventEmitter();
    this.actionService.createActionEvent.emit({ name: 'Удалить клиента', event: removeEvent, class: 'red', roles: ['E2B.ROLE.SUPERADMIN'] });
    removeEvent.subscribe(() => {
      const yes = confirm('Вы уверены, что хотите удалить клиента ' + this.client.name + ' и все его сделки?');
      if (!yes) {
        return true;
      }
      this.clientService.deleteById(this.id).subscribe(() => {
        this.router.navigate(['/client/list']).catch(console.error);
      });
    });
    this.clientSubscribe = this.clientService.findById<Client>(this.id).subscribe(client => {
      this.client = client;
      this.setTitle(`${this.client.name ? this.client.name : 'Olissys E2B'}`);
      this.loaded = true;
    });
    this.commentService.findForClient<Comment[]>(this.id).subscribe(comments => {
      this.comments = comments;
    });
    this.channelSubscribe = this.clientChannelService.find<ClientChannel>().subscribe(
      channels => this.channels = channels, console.error);
    this.managerSubscribe = this.clientManagerService.find<User>().subscribe(
      managers => this.managers = managers, console.error);
    this.typeSubscribe = this.clientTypeService.find<ClientType>().subscribe(
      types => this.types = types, console.error);
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  editNameChange() {
    this.editName = true;
    this.clientNameFocus = true;
  }

  editNameTrigger() {
    this.clientService.update<Client>(<Client>{ id: this.client.id, name: this.client.name }).subscribe(() => {
      this.editName = false;
      this.err = false;
    }, e => {
      this.err = e.error;
    });
  }

  editDescriptionTrigger() {
    this.clientService.update<Client>(<Client>{ id: this.client.id, description: this.client.description }).subscribe(() => {
      this.editDescription = false;
      this.err = false;
    }, e => {
      this.err = e.error;
    });
  }


  // save() {
  //   this.editManager = false;
  //   this.editChannel = false;
  //   this.editClientType = false;
  //   this.editDescription = false;
  // }

  remove() {
  }

  totalDealSum(positions: DealPosition[]) {
    let total = 0;
    positions.forEach(position => {
      total += position.price * position.count;
      if (position.discountPrice) {
        total -= position.discountPrice;
      }
    });
    return total;
  }

  ngOnDestroy() {
    if (this.titleService) {
      this.setTitle('Olissys E2B');
    }
    if (this.channelSubscribe) {
      this.channelSubscribe.unsubscribe();
    }
    if (this.managerSubscribe) {
      this.managerSubscribe.unsubscribe();
    }
    if (this.typeSubscribe) {
      this.typeSubscribe.unsubscribe();
    }
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }
}
