<div class="ui stackable grid">
  <div class="row">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div class="ui three wide column ui right border">
      <div class="ui vertical fluid secondary menu">
        <a class="header item" (click)="applyManagerFilter()"
           [ngClass]="{'active': managerFilter && managerFilter.manager}">#МОИ СДЕЛКИ</a>
        <div class="header item">#ФИЛИАЛ</div>
        <a *ngFor="let filial of filials | orderByColumn:'name'; trackBy:identify" class="ui item"
           (click)="applyFilialFilter(filial)"
           [ngClass]="{'active': filialFilter && filialFilter.filial && filialFilter.filial.id === filial.id}">
          <div class="ui left floated padded text {{filial.color.replace('#', 'XBorder')}}"
               style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" [innerText]="filial.name"></div>
          <div class="clearfix"></div>
        </a>
        <div class="header item">#СТАТУС</div>
        <a *ngFor="let dealStatus of dealStatuses | orderByColumn:'sort:number'; trackBy:identify" class="ui item"
           (click)="applyStatusFilter(dealStatus)"
           [ngClass]="{'active': filter.currentStatus && filter.currentStatus.id === dealStatus.id}">
          <div class="ui left floated padded text {{dealStatus.color.replace('#','X')}}"
               style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
               [innerText]="dealStatus.name"></div>
          <div class="ui mini right floated text">{{dealStatus.dealCount}}</div>
          <div class="clearfix"></div>
        </a>
      </div>
    </div>
    <div class="ui thirteen wide column" style="overflow-x: scroll">
      <!--NEW-->
      <table class="ui selectable very padded table" *ngIf="true" [hidden]="!loaded || deals.length === 0">
        <thead>
        <tr>
          <th class="p-0 text-center">
            <div class="head">
              <div class="ui dropdown" suiDropdown autoClose="disabled">
                <div class="ui basic icon button m-0">
                  <i class="filter grey icon"></i>
                </div>
                <div class="menu" suiDropdownMenu>
                  <div *ngFor="let columnName of dealListService.allColumns" class="item">
                    <div class="ui checkbox" (click)="dealListService.selectColumn(columnName)">
                      <input [checked]="dealListService.isColumnSelected(columnName)"
                             [name]="columnName" type="checkbox">
                      <label class="cursor" [innerText]="dealListService.getColumnDisplayName(columnName)"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </th>
          <th (click)="sorting == 'name:number' ? sorting = '-name:number' : sorting = 'name:number';">
            <div class="head">
              № Сделки
              <i *ngIf="sorting === '-name:number'" class="icon sort alphabet descending"></i>
              <i *ngIf="sorting === 'name:number'" class="icon sort alphabet ascending"></i>
              <i *ngIf="sorting !== 'name:number' && sorting !== '-name:number'" class="icon sort"></i>
            </div>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('client')"
              (click)="sorting == 'client.name:object:string' ? sorting = '-client.name:object:string' : sorting = 'client.name:object:string';">
            <div class="head">
              Клиент
              <i *ngIf="sorting === '-client.name:object:string'" class="icon sort alphabet descending"></i>
              <i *ngIf="sorting === 'client.name:object:string'" class="icon sort alphabet ascending"></i>
              <i *ngIf="sorting !== 'client.name:object:string' && sorting !== '-client.name:object:string'"
                 class="icon sort"></i>
            </div>
          </th>
          <th *ngIf="dealListService.isColumnSelected('manager')"
              (click)="sorting == 'manager.name:object:string' ? sorting = '-manager.name:object:string' : sorting = 'manager.name:object:string';">
            <div class="head">
              Менеджер
              <i *ngIf="sorting === '-manager.name:object:string'" class="icon sort alphabet descending"></i>
              <i *ngIf="sorting === 'manager.name:object:string'" class="icon sort alphabet ascending"></i>
              <i *ngIf="sorting !== 'manager.name:object:string' && sorting !== '-manager.name:object:string'"
                 class="icon sort"></i>
            </div>
          </th>
          <th *ngIf="dealListService.isColumnSelected('description')">
            <div class="head">Суть</div>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('nextTodo')"
              (click)="sorting == 'nextTodo.from:object:datetime' ? sorting = '-nextTodo.from:object:datetime' : sorting = 'nextTodo.from:object:datetime';">
            <div class="head">
              Следующее дело
              <i *ngIf="sorting === '-nextTodo.from:object:datetime'" class="icon sort numeric descending"></i>
              <i *ngIf="sorting === 'nextTodo.from:object:datetime'" class="icon sort numeric ascending"></i>
              <i *ngIf="sorting !== 'nextTodo.from:object:datetime' && sorting !== '-nextTodo.from:object:datetime'"
                 class="icon sort"></i>
            </div>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('gaugingDate')"
              (click)="sorting == 'gauging.gaugingDate:object:momentDateTime' ? sorting = '-gauging.gaugingDate:object:momentDateTime' : sorting = 'gauging.gaugingDate:object:momentDateTime'">
            <div class="head">Дата замера</div>
            <i *ngIf="sorting === '-gauging.gaugingDate:object:momentDateTime'"
               class="icon sort numeric descending"></i>
            <i *ngIf="sorting === 'gauging.gaugingDate:object:momentDateTime'" class="icon sort numeric ascending"></i>
            <i
              *ngIf="sorting !== 'gauging.gaugingDate:object:momentDateTime' && sorting !== '-gauging.gaugingDate:object:momentDateTime'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('gauger')"
              (click)="sorting == 'gauging.gauger:object:string' ? sorting = '-gauging.gauger:object:string' : sorting = 'gauging.gauger:object:string';">
            <div class="head">Замерщик</div>
            <i *ngIf="sorting === '-gauging.gauger:object:string'" class="icon sort alphabet descending"></i>
            <i *ngIf="sorting === 'gauging.gauger:object:string'" class="icon sort alphabet ascending"></i>
            <i *ngIf="sorting !== 'gauging.gauger:object:string' && sorting !== '-gauging.gauger:object:string'"
               class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('order')"
              (click)="sorting == 'totalPrice:number' ? sorting = '-totalPrice:number' : sorting = 'totalPrice:number';">
            <div class="head">
              Заказ
              <i *ngIf="sorting === '-totalPrice:number'" class="icon sort numeric descending"></i>
              <i *ngIf="sorting === 'totalPrice:number'" class="icon sort numeric ascending"></i>
              <i *ngIf="sorting !== 'totalPrice:number' && sorting !== '-totalPrice:number'" class="icon sort"></i>
            </div>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('contract')"
              (click)="sorting == 'contract.name:object:string' ? sorting = '-contract.name:object:string' : sorting = 'contract.name:object:string';">
            <div class="head">Договор</div>
            <i *ngIf="sorting === '-contract.name:object:string'" class="icon sort alphabet descending"></i>
            <i *ngIf="sorting === 'contract.name:object:string'" class="icon sort alphabet ascending"></i>
            <i *ngIf="sorting !== 'contract.name:object:string' && sorting !== '-contract.name:object:string'"
               class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('deliveryDate')"
              (click)="sorting === 'deliveryAndMounting.deliveryDate:object:momentDate' ? sorting = '-deliveryAndMounting.deliveryDate:object:momentDate' : sorting = 'deliveryAndMounting.deliveryDate:object:momentDate';">
            <div class="head">Дата Доставки</div>
            <i *ngIf="sorting === '-deliveryAndMounting.deliveryDate:object:momentDate'"
               class="icon sort numeric descending"></i>
            <i *ngIf="sorting === 'deliveryAndMounting.deliveryDate:object:momentDate'"
               class="icon sort numeric ascending"></i>
            <i
              *ngIf="sorting !== 'deliveryAndMounting.deliveryDate:object:momentDate' && sorting !== '-deliveryAndMounting.deliveryDate:object:momentDate'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('shipmentDate')"
              (click)="sorting === 'deliveryAndMounting.shipmentDate:object:momentDate' ? sorting = '-deliveryAndMounting.shipmentDate:object:momentDate' : sorting = 'deliveryAndMounting.shipmentDate:object:momentDate';">
            <div class="head">Дата отгрузки</div>
            <i *ngIf="sorting === '-deliveryAndMounting.shipmentDate:object:momentDate'"
               class="icon sort numeric descending"></i>
            <i *ngIf="sorting === 'deliveryAndMounting.shipmentDate:object:momentDate'"
               class="icon sort numeric ascending"></i>
            <i
              *ngIf="sorting !== 'deliveryAndMounting.shipmentDate:object:momentDate' && sorting !== '-deliveryAndMounting.shipmentDate:object:momentDate'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('mountingDate')"
              (click)="sorting === 'deliveryAndMounting.mountingDate:object:momentDate' ? sorting = '-deliveryAndMounting.mountingDate:object:momentDate' : sorting = 'deliveryAndMounting.mountingDate:object:momentDate';">
            <div class="head">Дата монтажа</div>
            <i *ngIf="sorting === '-deliveryAndMounting.mountingDate:object:momentDate'"
               class="icon sort numeric descending"></i>
            <i *ngIf="sorting === 'deliveryAndMounting.mountingDate:object:momentDate'"
               class="icon sort numeric ascending"></i>
            <i
              *ngIf="sorting !== 'deliveryAndMounting.mountingDate:object:momentDate' && sorting !== '-deliveryAndMounting.mountingDate:object:momentDate'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('prepay')"
              (click)="sorting == 'contract.prepay:object:number' ? sorting = '-contract.prepay:object:number' : sorting = 'contract.prepay:object:number';">
            <div class="head">Предоплата, руб.</div>
            <i *ngIf="sorting === '-contract.prepay:object:number'" class="icon sort numeric descending"></i>
            <i *ngIf="sorting === 'contract.prepay:object:number'" class="icon sort numeric ascending"></i>
            <i *ngIf="sorting !== 'contract.prepay:object:number' && sorting !== '-contract.prepay:object:number'"
               class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('productionSoft')"
              (click)="sorting == 'production.soft:object:string' ? sorting = '-production.soft:object:string' : sorting = 'production.soft:object:string';">
            <div class="head">Мягкая мебель</div>
            <i *ngIf="sorting === '-production.soft:object:string'" class="icon sort alphabet descending"></i>
            <i *ngIf="sorting === 'production.soft:object:string'" class="icon sort alphabet ascending"></i>
            <i *ngIf="sorting !== 'production.soft:object:string' && sorting !== '-production.soft:object:string'"
               class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('productionCabinet')"
              (click)="sorting == 'production.cabinet:object:string' ? sorting = '-production.cabinet:object:string' : sorting = 'production.cabinet:object:string';">
            <div class="head">Корпусная мебель</div>
            <i *ngIf="sorting === '-production.cabinet:object:string'" class="icon sort alphabet descending"></i>
            <i *ngIf="sorting === 'production.cabinet:object:string'" class="icon sort alphabet ascending"></i>
            <i
              *ngIf="sorting !== 'production.cabinet:object:string' && sorting !== '-production.cabinet:object:string'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('productionMatress')"
              (click)="sorting == 'production.matress:object:string' ? sorting = '-production.matress:object:string' : sorting = 'production.matress:object:string';">
            <div class="head">Матрасы</div>
            <i *ngIf="sorting === '-production.matress:object:string'" class="icon sort alphabet descending"></i>
            <i *ngIf="sorting === 'production.matress:object:string'" class="icon sort alphabet ascending"></i>
            <i
              *ngIf="sorting !== 'production.matress:object:string' && sorting !== '-production.matress:object:string'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('productionCarriage')"
              (click)="sorting == 'production.carriage:object:string' ? sorting = '-production.carriage:object:string' : sorting = 'production.carriage:object:string';">
            <div class="head">Кастомизация</div>
            <i *ngIf="sorting === '-production.carriage:object:string'" class="icon sort alphabet descending"></i>
            <i *ngIf="sorting === 'production.carriage:object:string'" class="icon sort alphabet ascending"></i>
            <i
              *ngIf="sorting !== 'production.carriage:object:string' && sorting !== '-production.carriage:object:string'"
              class="icon sort"></i>
          </th>
          <th nowrap *ngIf="dealListService.isColumnSelected('specifications')">
            <div class="head">Спецификации и чертежи</div>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('constructors')"
              (click)="sorting == 'planning.constructors:object:string' ? sorting = '-planning.constructors:object:string' : sorting = 'planning.constructors:object:string';">
            <div class="head">Конструктор</div>
            <i *ngIf="sorting === '-planning.constructors:object:string'" class="icon sort alphabet descending"></i>
            <i *ngIf="sorting === 'planning.constructors:object:string'" class="icon sort alphabet ascending"></i>
            <i
              *ngIf="sorting !== 'planning.constructors:object:string' && sorting !== '-planning.constructors:object:string'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('productionFinishDate')"
              (click)="sorting === 'production.finishDate:object:momentDate' ? sorting = '-production.finishDate:object:momentDate' : sorting = 'production.finishDate:object:momentDate';">
            <div class="head">Дата готовности заказа</div>
            <i *ngIf="sorting === '-production.finishDate:object:momentDate'" class="icon sort numeric descending"></i>
            <i *ngIf="sorting === 'production.finishDate:object:momentDate'" class="icon sort numeric ascending"></i>
            <i
              *ngIf="sorting !== 'production.finishDate:object:momentDate' && sorting !== '-production.finishDate:object:momentDate'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('deliverer')"
              (click)="sorting == 'deliveryAndMounting.deliverer:object:string' ? sorting = '-deliveryAndMounting.deliverer:object:string' : sorting = 'deliveryAndMounting.deliverer:object:string';">
            <div class="head">Доставщик</div>
            <i *ngIf="sorting === '-deliveryAndMounting.deliverer:object:string'"
               class="icon sort alphabet descending"></i>
            <i *ngIf="sorting === 'deliveryAndMounting.deliverer:object:string'"
               class="icon sort alphabet ascending"></i>
            <i
              *ngIf="sorting !== 'deliveryAndMounting.deliverer:object:string' && sorting !== '-deliveryAndMounting.deliverer:object:string'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('mounter')"
              (click)="sorting == 'deliveryAndMounting.mounter:object:string' ? sorting = '-deliveryAndMounting.mounter:object:string' : sorting = 'deliveryAndMounting.mounter:object:string';">
            <div class="head">Монтажник</div>
            <i *ngIf="sorting === '-deliveryAndMounting.mounter:object:string'"
               class="icon sort alphabet descending"></i>
            <i *ngIf="sorting === 'deliveryAndMounting.mounter:object:string'" class="icon sort alphabet ascending"></i>
            <i
              *ngIf="sorting !== 'deliveryAndMounting.mounter:object:string' && sorting !== '-deliveryAndMounting.mounter:object:string'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('complaintMounter')"
              (click)="sorting == 'complaint.mounter:object:string' ? sorting = '-complaint.mounter:object:string' : sorting = 'complaint.mounter:object:string';">
            <div class="head">Монтажник рекламации</div>
            <i *ngIf="sorting === '-complaint.mounter:object:string'" class="icon sort alphabet descending"></i>
            <i *ngIf="sorting === 'complaint.mounter:object:string'" class="icon sort alphabet ascending"></i>
            <i *ngIf="sorting !== 'complaint.mounter:object:string' && sorting !== '-complaint.mounter:object:string'"
               class="icon sort"></i>
          </th>
          <th nowrap *ngIf="dealListService.isColumnSelected('complaintMountingDate')"
              (click)="sorting == 'complaint.mountingDate:object:momentDate' ? sorting = '-complaint.mountingDate:object:momentDate' : sorting = 'complaint.mountingDate:object:momentDate';">
            <div class="head">Дата монтажа рекламации</div>
            <i *ngIf="sorting === '-complaint.mountingDate:object:momentDate'" class="icon sort numeric descending"></i>
            <i *ngIf="sorting === 'complaint.mountingDate:object:momentDate'" class="icon sort numeric ascending"></i>
            <i
              *ngIf="sorting !== 'complaint.mountingDate:object:momentDate' && sorting !== '-complaint.mountingDate:object:momentDate'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('balance')"
              (click)="sorting == 'finance.balance:object:number' ? sorting = '-finance.balance:object:number' : sorting = 'finance.balance:object:number';">
            <div class="head">Остаток</div>
            <i *ngIf="sorting === '-finance.balance:object:number'" class="icon sort numeric descending"></i>
            <i *ngIf="sorting === 'finance.balance:object:number'" class="icon sort numeric ascending"></i>
            <i *ngIf="sorting !== 'finance.balance:object:number' && sorting !== '-finance.balance:object:number'"
               class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('balancePassed')"
              (click)="sorting == 'finance.balancePassed:object:momentDateTime' ? sorting = '-finance.balancePassed:object:momentDateTime' : sorting = 'finance.balancePassed:object:momentDateTime';">
            <div class="head">Остаток сдан</div>
            <i *ngIf="sorting === '-finance.balancePassed:object:momentDateTime'"
               class="icon sort numeric descending"></i>
            <i *ngIf="sorting === 'finance.balancePassed:object:momentDateTime'"
               class="icon sort numeric ascending"></i>
            <i
              *ngIf="sorting !== 'finance.balancePassed:object:momentDateTime' && sorting !== '-finance.balancePassed:object:momentDateTime'"
              class="icon sort"></i>
          </th>
          <th nowrap
              *ngIf="dealListService.isColumnSelected('actPassed')"
              (click)="sorting == 'finance.actPassed:object:momentDateTime' ? sorting = '-finance.actPassed:object:momentDateTime' : sorting = 'finance.actPassed:object:momentDateTime';">
            <div class="head">Акт сдан</div>
            <i *ngIf="sorting === '-finance.actPassed:object:momentDateTime'" class="icon sort numeric descending"></i>
            <i *ngIf="sorting === 'finance.actPassed:object:momentDateTime'" class="icon sort numeric ascending"></i>
            <i
              *ngIf="sorting !== 'finance.actPassed:object:momentDateTime' && sorting !== '-finance.actPassed:object:momentDateTime'"
              class="icon sort"></i>
          </th>
          <th
            *ngIf="dealListService.isColumnSelected('costMounting')"
            (click)="sorting == 'cost.mounting:object:number' ? sorting = '-cost.mounting:object:number' : sorting = 'cost.mounting:object:number';">
            <div class="head">
              Себестоимость: Монтаж
              <i *ngIf="sorting === '-cost.mounting:object:number'" class="icon sort numeric descending"></i>
              <i *ngIf="sorting === 'cost.mounting:object:number'" class="icon sort numeric ascending"></i>
              <i *ngIf="sorting !== 'cost.mounting:object:number' && sorting !== '-cost.mounting:object:number'"
                 class="icon sort"></i>
            </div>
          </th>
          <th
            *ngIf="dealListService.isColumnSelected('costShipment')"
            (click)="sorting == 'cost.shipment:object:number' ? sorting = '-cost.shipment:object:number' : sorting = 'cost.shipment:object:number';">
            <div class="head">
              Себестоимость: Доставка
              <i *ngIf="sorting === '-cost.shipment:object:number'" class="icon sort numeric descending"></i>
              <i *ngIf="sorting === 'cost.shipment:object:number'" class="icon sort numeric ascending"></i>
              <i *ngIf="sorting !== 'cost.shipment:object:number' && sorting !== '-cost.shipment:object:number'"
                 class="icon sort"></i>
            </div>
          </th>
          <th
            *ngIf="dealListService.isColumnSelected('costExtraWork')"
            (click)="sorting == 'cost.extraWork:object:number' ? sorting = '-cost.extraWork:object:number' : sorting = 'cost.extraWork:object:number';">
            <div class="head">
              Себестоимость: Доп. работы
              <i *ngIf="sorting === '-cost.extraWork:object:number'" class="icon sort numeric descending"></i>
              <i *ngIf="sorting === 'cost.extraWork:object:number'" class="icon sort numeric ascending"></i>
              <i *ngIf="sorting !== 'cost.extraWork:object:number' && sorting !== '-cost.extraWork:object:number'"
                 class="icon sort"></i>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let deal of (deals | dealFilter:managerFilter | dealFilter:filialFilter | orderByColumn:sorting); trackBy:identify">
          <td></td>
          <td nowrap>
            <div e2b-filial-label [filial]="deal.filial"></div>
            <a [routerLink]="['/deal/card/', deal.id]" [innerText]="deal.name.substr(9)"></a>
            <br/>
            <span class="greytext" [innerText]="deal.created | date:'medium'"></span>
          </td>
          <td nowrap *ngIf="dealListService.isColumnSelected('client')">
            <a [routerLink]="['/client/card/', deal.client.id]" [innerText]="deal.client.name"></a>
          </td>
          <td nowrap *ngIf="dealListService.isColumnSelected('manager')">
            <a [routerLink]="['/staff/card/', deal.manager.id]" [innerText]="deal.manager.name"></a>
          </td>
          <td width="20%" *ngIf="dealListService.isColumnSelected('description')">
            <span [innerHTML]="deal.description"></span>
          </td>
          <td *ngIf="dealListService.isColumnSelected('nextTodo')">
            <p class="mb-0" *ngIf="deal.nextTodo">
              <span [innerText]="deal.nextTodo.name"></span> <span *ngIf="deal.nextTodo.responsible"
                                                                   [innerText]="' (' + deal.nextTodo.responsible.name + ')'"></span><br>
              <span [innerText]="deal.nextTodo.from | date:'short'"></span>
            </p>
          </td>
          <td *ngIf="dealListService.isColumnSelected('gaugingDate')">
            <span *ngIf="deal.gauging.gaugingDate" [innerText]="deal.gauging.gaugingDate"></span>
          </td>
          <td *ngIf="dealListService.isColumnSelected('gauger')">
            <span *ngIf="deal.gauging.gauger" [innerText]="deal.gauging.gauger"></span>
          </td>
          <td *ngIf="dealListService.isColumnSelected('order')">
            <p *ngFor="let position of deal.positions">
              <span [innerText]="position.position ? position.position.name : ''"></span><span> x </span><span
              [innerText]="position.count"></span> x
              <span [innerText]="position.price | number"></span><span> руб.</span>
            </p>
            <p class="fw-bold" *ngIf="deal.positions.length > 0">
              Итого: <span [innerText]="deal.totalPrice | number"></span><span>&nbsp;руб.</span>
            </p>
          </td>
          <td *ngIf="dealListService.isColumnSelected('contract')">
            <span *ngIf="deal.contract.name" [innerText]="deal.contract.name"></span><br>
            <span class="greytext" *ngIf="deal.contract.date" [innerText]="deal.contract.date"></span>
          </td>
          <td *ngIf="dealListService.isColumnSelected('deliveryDate')">
            <div *ngFor="let date of deal.deliveryData.dates">{{date}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('shipmentDate')">
            <span *ngIf="deal.deliveryData.shipmentDate" [innerText]="deal.deliveryData.shipmentDate"></span>
          </td>
          <td *ngIf="dealListService.isColumnSelected('mountingDate')">
            <div *ngFor="let date of deal.mountingData.dates">{{date}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('prepay')">
            <span *ngIf="deal.contract.prepay" [innerText]="deal.contract.prepay"></span>
          </td>
          <td *ngIf="dealListService.isColumnSelected('productionSoft')">
            <div *ngFor="let name of deal.productionData.soft">{{name}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('productionCabinet')">
            <div *ngFor="let name of deal.productionData.cabinet">{{name}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('productionMatress')">
            <div *ngFor="let name of deal.productionData.matress">{{name}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('productionCarriage')">
            <div *ngFor="let name of deal.productionData.carriage">{{name}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('specifications')">
            <div *ngIf="deal.planning.specifications && deal.planning.specifications.length > 0">
              <div *ngFor="let file of deal.planning.specifications">
                <e2b-file [file]="file"></e2b-file>
              </div>
            </div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('constructors')">
            <span *ngIf="deal.planning.constructors" [innerText]="deal.planning.constructors"></span>
          </td>
          <td *ngIf="dealListService.isColumnSelected('productionFinishDate')">
            <div *ngFor="let date of deal.productionData.dates">{{date}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('deliverer')">
            <div *ngFor="let name of deal.deliveryData.deliverers">{{name}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('mounter')">
            <div *ngFor="let name of deal.mountingData.mounters">{{name}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('complaintMounter')">
            <div *ngFor="let name of deal.reclamationData.mounters">{{name}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('complaintMountingDate')">
            <div *ngFor="let date of deal.reclamationData.dates">{{date}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('balance')">
            <span *ngIf="deal.finance.balance">{{deal.finance.balance | number}}</span>
          </td>
          <td *ngIf="dealListService.isColumnSelected('balancePassed')">
            <span *ngIf="deal.finance.balancePassed" [innerText]="deal.finance.balancePassed"></span>
          </td>
          <td *ngIf="dealListService.isColumnSelected('actPassed')">
            <span *ngIf="deal.finance.actPassed" [innerText]="deal.finance.actPassed"></span>
          </td>
          <td *ngIf="dealListService.isColumnSelected('costMounting')">
            <div *ngFor="let cost of deal.mountingData.costs">{{cost | number}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('costShipment')">
            <div *ngFor="let cost of deal.deliveryData.costs">{{cost | number}}</div>
          </td>
          <td *ngIf="dealListService.isColumnSelected('costExtraWork')">
            <span *ngIf="deal.cost.extraWork" [innerText]="deal.cost.extraWork | number"></span>
          </td>
        </tr>
        </tbody>
      </table>
      <div style="position: relative; margin-top: 50px">
        <div [ngClass]="'ui' + (moreDealsLoading ? ' active ' : ' ') + 'text loader'">Загрузка...</div>
      </div>
      <div class="ui text-center" *ngIf="deals.length === 0 && loaded">
        <h2>Ничего не найдено</h2>
      </div>
    </div>
  </div>
</div>
