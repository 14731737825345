import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dashboardWidgets'
})
export class DashboardWidgetsPipe implements PipeTransform {
  transform(widgets: { id: number }[], ids: number[]): any {
    return widgets.filter(todo => ids.includes(todo.id));
  }
}
