import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccessObject, AccessRightType, MemberAccessType } from '../_models/role.model';
import { UserService } from '../_services/user.service';
import { ErrorService } from '../error/error.service';
import { LkClientService } from '../lk/_services/lk-client.service';

@Injectable()
export class PermissionHttpInterceptor implements HttpInterceptor {

  public accessLevels = {
    routes: { [AccessRightType.NONE]: 0, [AccessRightType.READ]: 1, [AccessRightType.WRITE]: 2 },
    dealsAccess: { [MemberAccessType.NONE]: 0, [MemberAccessType.MEMBER]: 1, [MemberAccessType.FULL]: 2 },
    todoCheckpointsAccess: { [MemberAccessType.NONE]: 0, [MemberAccessType.MEMBER]: 1, [MemberAccessType.FULL]: 2 },
    fields: { [AccessRightType.NONE]: 0, [AccessRightType.READ]: 1, [AccessRightType.WRITE]: 2 },
  };
  private baseUrl = '/api/v1/';

  constructor(private readonly userService: UserService, private errorService: ErrorService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.userService.current()) {
      if (req.headers.has('X-Skip-Interceptor')) {
        req = req.clone({ headers: req.headers.delete('X-Skip-Interceptor') });
      } else {
        const accessRight = this.checkAccessByUrl(this.userService.current().accessObject, req);
        if (!accessRight) {
          // this.errorService.addError.emit({ title: 'Нет прав', message: 'Нет прав для выполнения HTTP запроса ' + req.method + ' ' + req.url });
          return throwError({ status: 403, message: 'Нет прав для выполнения HTTP запроса ' + req.method + ' ' + req.url });
        }
      }
    }
    return next.handle(req)
      .pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }), catchError((err: any, caught) => {
          if (err.status === 401 || err.status === 403) {
            // this.errorService.addError.emit({ title: 'Нет прав', message: 'Нет прав для выполнения HTTP запроса ' + req.method + ' ' + req.url });
            return throwError({ status: 403, message: 'Нет прав для выполнения HTTP запроса ' + req.method + ' ' + req.url, error: err });
          } else {
            return throwError(err);
          }
        })
      );
  }

  public checkAccessByUrl = (
    accessObject: AccessObject,
    req: HttpRequest<any>,
  ): boolean => {
    const accessLevelNeeded = (req.method === 'GET') ? 'read' : 'write';
    if (!accessObject.routes) {
      console.error('[PermissionInterceptor]: checkAccessByUrl: Access denied: No routes in accessObject.');
      return false;
    }
    if (!req.url || !req.url.split(this.baseUrl) || !req.url.split(this.baseUrl)[1]) {
      console.error('[PermissionInterceptor]: checkAccessByUrl: Access denied: wrong url');
      return false;
    }
    const accessRoutes = accessObject.routes;
    const accessUrl = req.url.split(this.baseUrl)[1].split('?')[0];
    let urlArray = accessUrl.split('/');
    if (urlArray.length === 0) {
      console.error('[PermissionInterceptor]: checkAccessByUrl: Access denied: wrong url');
      return false;
    }
    urlArray = urlArray.reverse();
    let foundAccess = 'none';
    let currentLevel = accessRoutes;
    while (urlArray.length > 0) {
      const attr = urlArray.pop();
      const attrExists = currentLevel[attr];
      if (!attrExists) {
        break;
      } else if (typeof attrExists === 'string') {
        foundAccess = attrExists;
        break;
      } else {
        const currentAccess = attrExists['/'];
        if (currentAccess && typeof currentAccess === 'string') {
          foundAccess = currentAccess;
        }
        currentLevel = attrExists;
      }
    }
    if (this.accessLevels.routes[foundAccess] >= this.accessLevels.routes[accessLevelNeeded]) {
      return true;
    } else {
      return false;
    }
  };
}
