import { Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DealField } from '../../_models/deal-field.model';
import { PositionGroupTemplate } from '../../_models/position-group-template.model';
import { PositionGroup } from '../../_models/position-group.model';
import { DealFieldService } from '../../_services/deal-field.service';
import { PositionGroupTemplateService } from '../../_services/position-group-template.service';
import { PositionGroupService } from '../../_services/position-group.service';
import { UserService } from '../../_services/user.service';
import { Action, ActionService, Event } from '../../action/action.service';

@Component({
  selector: 'e2b-template-list',
  templateUrl: './postition-template-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/label.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    './postition-template-list.component.less'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PositionTemplateListComponent implements OnInit {
  templates: PositionGroupTemplate[] = [];
  positionGroups: PositionGroup[] = [];
  dealFields: DealField[] = [];
  loaded = false;
  editedTemplate: number | null = null;
  @ViewChild('selectGroup', { static: false })
  selectGroup;
  @ViewChild('selectField', { static: false })
  selectField;

  constructor(private actionService: ActionService,
              private positionGroupService: PositionGroupService,
              private userService: UserService,
              private positionGroupTemplateFieldService: DealFieldService,
              private positionGroupTemplateService: PositionGroupTemplateService) {
  }

  ngOnInit() {

    const reload = new EventEmitter();

    this.actionService.createActionEvent.emit(<Event>{ name: 'Обновить', event: reload, class: 'blue' });
    if (this.userService.checkPermissions(['position-group', '/'])) {
      this.actionService.createMenuEvent.emit(<Action>{ name: 'Группы', link: '/catalog/group/list', class: 'yellow active' });
    }
    if (this.userService.checkPermissions(['position', '/'])) {
      this.actionService.createMenuEvent.emit(<Action>{ name: 'Товары', link: '/catalog/list', class: 'green active' });
    }
    if (this.userService.checkPermissions(['position-group-template', '/'], ['write'])) {
      this.actionService.createMenuEvent.emit(<Action>{ name: 'Добавить шaблон', link: '/catalog/template/add', class: 'blue active' });
    }

    reload.subscribe(() => {
      this.load();
    });

    this.load();

  }

  load() {
    this.loaded = false;
    this.positionGroupTemplateService.find<PositionGroupTemplate>().subscribe(templates => {
      this.templates = templates;
      this.positionGroupService.find<PositionGroup>().subscribe(groups => {
        this.positionGroups = groups;
        this.positionGroupTemplateFieldService.find<DealField>().subscribe(fields => {
          this.dealFields = fields;
          this.loaded = true;
        });
      });
    });
  }

  changeGroups(template: PositionGroupTemplate) {
    const event = new EventEmitter();
    event.subscribe(groups => {
      template.groups = groups;
    }, console.error);
    return event;
  }

  changeFields(template: PositionGroupTemplate) {
    const event = new EventEmitter();
    event.subscribe(fields => {
      template.fields = fields;
    }, console.error);
    return event;
  }

  editTemplate(templateId: number) {
    this.editedTemplate = templateId;
  }

  remove(template: PositionGroupTemplate) {
    const yes = confirm('Вы уверены, что хотите удалить шаблон ' + template.name + '?');
    if (yes) {
      this.positionGroupTemplateService.deleteById(template.id).subscribe(() => {
        this.templates.splice(this.templates.indexOf(template), 1);
      }, console.error);
    }
  }

  save(template: PositionGroupTemplate) {
    this.positionGroupTemplateService.update(template).subscribe(() => {
      this.editedTemplate = null;
    }, console.error);
  }
}
