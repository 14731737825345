import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterEventService } from '../_services/router-event.service';

@Component({
  selector: 'e2b-main-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/menu.min.css',
    '../../assets/design/semantic-ui/components/icon.min.css',
    '../../assets/design/semantic-ui/components/dropdown.min.css',
    './app-menu.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AppMenuComponent implements OnInit {

  public path: string;

  constructor(private routerEventService: RouterEventService) {
    this.path = window.location.pathname;
    this.routerEventService.onRouterEvent.subscribe(() => {
      this.path = window.location.pathname;
    });
  }

  ngOnInit() {
    this.path = window.location.pathname;
  }

}
