<div class="ui dropdown" suiDropdown style="margin-top: 12px;">
  <img class="ui avatar image" [src]="(currentUser.avatar.path + '/' + currentUser.avatar.name) | secure"/>
  <div class="menu" suiDropdownMenu style="z-index: 9999">
    <a [e2bPermission]="['setting', '/']" class="item" [routerLink]="['/settings']">
      <i class="icon cogs"></i>
      Настройки
    </a>
    <a class="item" [routerLink]="['/feedback']">
      <i class="icon comment"></i>
      Обратная связь
    </a>
    <a class="item">
      <i class="icon question circle"></i>
      Помощь
    </a>
    <a class="item" (click)="logout()">
      <i class="icon power off"></i>
      Выход
    </a>
  </div>
</div>
