import {Component, EventEmitter, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {DealField} from '../../_models/deal-field.model';

@Component({
  selector: 'e2b-deal-field-dropdown',
  templateUrl: './deal-field-dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class DealFieldDropdownComponent {

  @ViewChild('selectDealField', {static: false})
  selectField;

  public selectedField: DealField;

  @Input()
  public dealFields: DealField[];

  @Input()
  public event: EventEmitter<DealField>;

  @Input()
  set selected(field: DealField) {
    if (field) {

      setTimeout(() => {
        this.selectField.selectedOption = field;
        this.selectedField = field;
      }, 0);
    }
  }

  save() {
    this.event.emit(this.selectedField);
  }
}
