import { BaseModel } from './base.model';
import { Client } from './client.model';

export class ClientType extends BaseModel {
  created: Date;
  description: string;
  id: number;
  name: string;
  systemType: string;
  clients: Client[] = [];
}
