<h3 class="fw-bold header">ФИНАНСЫ
  <div class="ui dropdown" suiDropdown style="display: inline !important;">
    <i class="blue cog icon"></i>
    <div class="menu" suiDropdownMenu>
      <a class="item" *ngFor="let widgetName of allWidgets">
        <div class="field">
          <input type="checkbox" [checked]="selectedWidgets.includes(widgetName)" (change)="selectWidget(widgetName)"/>
          <label> {{widgets[widgetName]}}</label>
        </div>
      </a>
    </div>
  </div>
</h3>
<div class="ui stackable cards" [ngClass]="{'three': innerWidth <= 1280, 'four': innerWidth > 1280, 'six': innerWidth > 1620}">
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('plan')">
    <div class="content">
      <div class="header">
        План поступлений <i class="ui grey bullseye icon"></i>
      </div>
      <div class="description">
        <table class="ui very very basic table" style="overflow-y: auto !important;">
          <tr>
            <td>
              <span class="header">Продажи</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'prognosis']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupText="65% от суммы сделок по полю 'Ориентировочная дата покупки'"
                     popupInverted>{{data[period].prognosis.plan | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Монтажи</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'money']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup
                     popupText="План: Сумма по максимальной дате монтажа"
                     popupInverted>{{data[period].mountingMoney.plan | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Рекламации</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" suiPopup
                     (click)="selectPlan()"
                     popupText="План: Сумма по дате устранения рекламации"
                     popupInverted>{{data[period].reclamation.plan | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Итого</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <span class="padded">{{(data[period].prognosis.plan + data[period].reclamation.plan + data[period].mountingMoney.plan) | humanNumber}}</span>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('fact')">
    <div class="content">
      <div class="header">
        Факт поступлений <i class="ui grey calendar outline icon"></i>
      </div>
      <div class="description">
        <table class="ui very very basic table" style="overflow-y: auto !important;">
          <tr>
            <td>
              <span class="header">Продажи</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" suiPopup
                     target="_blank" [routerLink]="['/report', 'sale']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}"
                     popupText="Факт (сумма предоплаты) по дате предоплаты"
                     popupInverted>{{data[period].prognosis.fact | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Монтажи</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/report', 'money']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup
                     popupText="Факт: Сумма остатка по дате 'Остаток сдан'"
                     popupInverted>{{data[period].mountingMoney.fact | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Рекламации</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" suiPopup [routerLink]="['/report', 'reclamation']" [queryParams]="{from: getQueryFrom(), to: getQueryTo(), resolve: true}"
                     popupText="Факт: Сумма по дате устранения рекламации"
                     popupInverted>{{data[period].reclamation.fact | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Итого</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <span class="padded">{{(data[period].prognosis.fact + data[period].reclamation.fact + data[period].mountingMoney.fact) | humanNumber}}</span>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('bankBalance')">
    <div class="content">
      <div class="header">
        Остатки на счетах <i class="ui grey calendar outline icon"></i>
      </div>
      <div class="description">
        <table class="ui very basic table" style="overflow-y: auto !important;">
          <tr>
            <td>
              <ng-template let-popup #popupBalanceCashTemplate>
                <div class="content">
                  <table class="ui very very basic table">
                    <tr *ngFor="let b of data[period].balance.details.cash">
                      <td style="color: whitesmoke !important;" nowrap>
                        {{b.person_name}}<br/>
                        <span style="color: #b7b1b1;font-size: 10px !important">{{b.description}}</span>
                      </td>
                      <td nowrap>
                        <span style="color: whitesmoke;" class="fw-bold">{{b.amount | number}}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </ng-template>
              <span class="header" suiPopup popupInverted [popupPlacement]="'left'"
                    [popupTemplate]="popupBalanceCashTemplate">Касса</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value" suiPopup popupInverted
                     popupText="Деньги в кассе">{{data[period].balance.cash | humanNumber}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <ng-template let-popup #popupBalanceBankTemplate>
                <div class="content">
                  <table class="ui very very basic table">
                    <tr *ngFor="let b of data[period].balance.details.bank">
                      <td style="color: whitesmoke !important;" nowrap>
                        {{b.person_name}}<br/>
                        <span style="color: #b7b1b1;font-size: 10px !important">{{b.description}}</span>
                      </td>
                      <td nowrap>
                        <span style="color: whitesmoke;" class="fw-bold">{{b.amount | number}}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </ng-template>
              <span class="header" suiPopup popupInverted [popupPlacement]="'left'"
                    [popupTemplate]="popupBalanceBankTemplate">Банк</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value" suiPopup popupInverted
                     popupText="Деньги на р/с">{{data[period].balance.bank | humanNumber}}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <ng-template let-popup #popupBalanceCardTemplate>
                <div class="content">
                  <table class="ui very very basic table">
                    <tr *ngFor="let b of data[period].balance.details.card">
                      <td style="color: whitesmoke !important;" nowrap>
                        {{b.person_name}}<br/>
                        <span style="color: #b7b1b1;font-size: 10px !important">{{b.description}}</span>
                      </td>
                      <td nowrap>
                        <span style="color: whitesmoke;" class="fw-bold">{{b.amount | number}}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </ng-template>
              <span class="header" suiPopup popupInverted [popupPlacement]="'left'"
                    [popupTemplate]="popupBalanceCardTemplate">В пути</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value" suiPopup popupInverted
                     popupText="Деньги от эквайринг, не зачисленные на р/с">{{data[period].balance.card | humanNumber}}</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('rentability')">
    <div class="content">
      <div class="header">
        Себестоимость > МД1 <i class="ui grey calendar outline icon"></i>
      </div>
      <div class="description">
        <div class="ui mini statistic">
          <div class="value">
            <a class="padded" target="_blank" [routerLink]="['/report', 'rentability']"
               [queryParams]="{from: getQueryFrom(), to: getQueryTo(), overhead: true}" suiPopup popupInverted
               popupText="Кол-во сделок с превышением МД">{{data[period].rentability.count | number}}</a>
            > {{data[period].rentability.percent}}%
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('debtors')">
    <div class="content">
      <div class="header">
        Должники <i class="ui grey calendar outline icon"></i>
      </div>
      <div class="description">
        <div class="ui mini statistic">
          <div class="value">
            <a class="padded" target="_blank" [routerLink]="['/report', 'money']"
               [queryParams]="{from: getQueryFrom(), to: getQueryTo(), debtors: true}" suiPopup popupInverted
               popupText="Сумма сделок с должниками">{{data[period].debtors | number}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('brsCash')">
    <div class="content">
      <div class="header">
        СБР: Наличные <i class="ui grey calendar outline icon"></i>
      </div>
      <div class="description" style="margin-top: 0 !important;">
        <table class="ui very very basic table" style="overflow-y: auto !important;">
          <tr>
            <td>
              <span class="header">План</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/brs', 'report']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted
                     popupText="План платежей">{{brs.cash[period].plan | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Факт</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/brs', 'report']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted
                     popupText="Факт платежей">{{brs.cash[period].fact | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Остаток</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/brs', 'report']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted
                     popupText="Остаток">{{brs.cash[period].remain | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Баланс</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/brs', 'report']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted
                     popupText="Дефицит">{{brs.cash[period].deficit | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="ui coordinator card" *ngIf="selectedWidgets.includes('brsBank')">
    <div class="content">
      <div class="header">
        СБР: Безнал <i class="ui grey calendar outline icon"></i>
      </div>
      <div class="description" style="margin-top: 0 !important;">
        <table class="ui very very basic table" style="overflow-y: auto !important;">
          <tr>
            <td>
              <span class="header">План</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/brs', 'report']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted
                     popupText="План платежей">{{brs.bank[period].plan | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Факт</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/brs', 'report']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted
                     popupText="Факт платежей">{{brs.bank[period].fact | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Остаток</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/brs', 'report']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted
                     popupText="Остаток">{{brs.bank[period].remain | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span class="header">Баланс</span>
            </td>
            <td>
              <div class="ui mini statistic">
                <div class="value">
                  <a class="padded" target="_blank" [routerLink]="['/brs', 'report']"
                     [queryParams]="{from: getQueryFrom(), to: getQueryTo()}" suiPopup popupInverted
                     popupText="Дефицит">{{brs.bank[period].deficit | humanNumber}}</a>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<br/>
<div *ngIf="planDetails.length">
  <table class="ui very basic selectable celled table">
    <thead>
    <tr>
      <th>
        <div class="head">
          Сделка
        </div>
      </th>
      <th>
        <div class="head">
          Клиент
        </div>
      </th>
      <th>
        <div class="head">
          Статус
        </div>
      </th>
      <th>
        <div class="head">
          Дата в плане
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let d of planDetails">
      <td nowrap>
        <div e2b-filial-label [filial]="d.filial"></div>
        <a class="d-inline-block v-align-top w-50" [routerLink]="['/deal/card/', d.id]" target="_blank">{{d.name}}</a>
        <span class="greytext d-block w-100">{{d.created ? (d.created | date:'short') : "-"}}</span>
      </td>
      <td nowrap>
        <a [routerLink]="['/client/card', d.client.id]" target="_blank">{{d.client.name}}</a><br/>
      </td>
      <td>
        <e2b-deal-status-label [status]="d.currentStatus"></e2b-deal-status-label>
      </td>
      <td>
        {{d.date}}
      </td>
    </tr>
    </tbody>
  </table>
</div>
