import { ChangeDetectorRef } from '@angular/core';
import { OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { DealPositionValue } from '../../../_models/deal-position-value.model';
import { DealPosition } from '../../../_models/deal-position.model';
import { PositionGroup } from '../../../_models/position-group.model';
import { DealPositionValueService } from '../../../_services/deal-position-value.service';
import * as moment from 'moment';
import { FileService } from '../../../_services/file.service';
import { FileInterface } from '../../../_services/file.service';
import { UserService } from '../../../_services/user.service';

@Component({
  selector: 'e2b-sales-wizard-deal-position-field',
  templateUrl: './deal-position-field.component.html',
  styleUrls: ['./deal-position-field.component.less',
    '../../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../../assets/design/semantic-ui/components/dimmer.min.css'
  ]
})
export class DealPositionFieldComponent implements OnChanges {
  positionValues: {
    group: PositionGroup | null,
    position: any[]
  } = {
    group: null,
    position: [],
  };

  @Input()
  selectedPosition: DealPosition;
  @Input()
  positions: any;
  @Input()
  onPositionValueSave: EventEmitter<any>;
  @Input()
  cachedPositionValues: any;
  @Input()
  positionValuesLoaded: boolean;
  @Input()
  required: boolean;

  constructor(private readonly dealPositionValueService: DealPositionValueService,
              private readonly cdRef: ChangeDetectorRef,
              private readonly fileService: FileService,
              private readonly userService: UserService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.positions && changes.positions.currentValue) {
      this.loadPositionValues();
    }
  }

  loadPositionValues() {
    this.positionValues.position = [];
    this.setPositionValues(this.positions);
  }

  setPositionValues(positions: any[]) {
    positions.map((p: any) => {
      if (p.field.type === 'USER') {
        if (p.value) {
          p.field.values.map(m => {
            if (p.value.id === m.id) {
              p.value = m;
            }
          });
        }
        p.event = new EventEmitter();
        p.event.subscribe(v => {
          p.value = v;
          this.onChangePositionValue(p.field.id);
        });
      } else if (p.field.type === 'MATERIAL') {
        if (p.value) {
          p.field.values.map(m => {
            if (p.value.id === m.id) {
              p.value = m;
            }
          });
        }
      } else if (p.field.type === 'CLOTH') {
        if (p.value) {
          p.field.values.map(m => {
            if (p.value.id === m.id) {
              p.value = m;
            }
          });
        }
      } else if (p.field.type === 'USERGROUP') {
        if (p.value) {
          p.field.values.map(m => {
            if (p.value.id === m.id) {
              p.value = m;
            }
          });
        }
        p.event = new EventEmitter();
        p.event.subscribe(v => {
          p.value = v;
          this.onChangePositionValue(p.field.id);
        });
      } else if (p.field.type === 'PERSONAL') {
        const personal = [];
        if (p.value) {
          p.field.values.map(m => {
            p.value.map(v => {
              if (v.id === m.id) {
                personal.push(m);
              }
            });
          });
          p.value = personal;
        }
      } else if (p.field.type === 'DATE' && p.value) {
        p.value = moment(p.value, 'DD.MM.YYYY').toDate();
        p.originalValue = moment(p.value, 'DD.MM.YYYY').toDate();
      } else if (p.field.type === 'DATETIME' && p.value) {
        p.value = moment(p.value, 'DD.MM.YYYY HH:mm:ss').toDate();
        p.originalValue = moment(p.value, 'DD.MM.YYYY HH:mm:ss').toDate();
      }
      this.positionValues.position.push(p);
    });
  }

  onChangePositionValue(fieldId) {
    const update: any = {
      field: {
        id: fieldId
      }
    };
    this.positionValues.position.map((p, l) => {
      if (p.field.id === fieldId) {

        if ((p.field.type === 'DATE' || p.field.type === 'DATETIME') && p.value && p.originalValue && p.value.getTime() === p.originalValue.getTime()) {
          return false;
        }

        update.id = p.id;
        update.dealPosition = p.dealPosition;

        if (p.field.type === 'FILE') {
          this.fileService.upload(p.files).then((f: FileInterface[]) => {
            if (p.value) {
              p.value = p.value.concat(f);
            } else {
              p.value = f;
            }
            this.savePositionValue(p, l);
          });
        } else if (p.field.type === 'DATE') {
          update.value = moment(p.value).format('DD.MM.YYYY');
          this.savePositionValue(update, l);
        } else if (p.field.type === 'DATETIME') {
          update.value = moment(p.value).format('DD.MM.YYYY HH:mm:ss');
          this.savePositionValue(update, l);
        } else {
          update.value = p.value;
          this.savePositionValue(update, l);
        }
      }
    });
  }

  onPositionPickerChange($event, fieldId) {
    if ($event) {
      this.onChangePositionValue(fieldId);
    }
  }

  savePositionValue(value, index) {
    if (!this.selectedPosition.id) {
      this.onPositionValueSave.emit({ value, index });
      return;
    }
    if (!value.id) {
      this.dealPositionValueService.create<DealPositionValue>(<DealPositionValue>{
        dealPosition: { id: value.dealPosition },
        field: { id: value.field.id },
        value: value.value
      }).subscribe(created => {
        this.positionValues.position[index].id = created.id;
        this.positionValues.position[index].created = created.created;
      });
    } else {
      this.dealPositionValueService.update<DealPositionValue>(<DealPositionValue>{
        id: value.id,
        value: value.value
      }).subscribe(() => {
      });
    }
  }

  removePositionValue(fieldId) {
    this.positionValues.position.map((p, k) => {
      if (p.field.id === fieldId && p.id) {
        this.dealPositionValueService.deleteById(p.id).subscribe(() => {
          this.positionValues.position[k].value = undefined;
        });
      }
    });
  }
}
