<h3 class="header">
  Плательщики
</h3>
<div class="ui fluid basic card" *ngFor="let person of persons">
  <div class="content">
    <div class="mini header" *ngIf="person.name">
      <a [routerLink]="['/client/person/card',person.id]">{{person.name}}</a>
    </div>
    <div class="meta" *ngIf="person.type && person.type.name">
      {{person.type ? person.type.name : ''}}
    </div>
    <div class="meta" *ngIf="person.address">
      <span class="greytext">{{person.address}}</span>
    </div>
    <div class="description" *ngIf="person.passport && person.passport.address">
      {{person.passport ? person.passport.address : ''}}
    </div>
    <div class="description" *ngIf="person.inn">
      {{person.inn}}
    </div>
  </div>
  <div class="ui divider"></div>
</div>
<button class="ui basic green button" [routerLink]="['/client/person/add']" [queryParams]="{clientId: clientId}">+ Добавить</button>
