<div class="ui grid">
  <div class="ui six wide column">
    <form class="ui form">
      <h2>Написать разработчикам</h2>
      <div class="field">
        <label>Тема</label>
        <input type="text" name="subject" [(ngModel)]="post.subject"/>
      </div>
      <div class="field">
        <label>Описание</label>
        <textarea name="description" [(ngModel)]="post.description"></textarea>
      </div>
      <button class="ui primary button disabled" type="submit">Отправить</button>
    </form>
  </div>
</div>
