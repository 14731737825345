import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { ClientContact } from '../../../_models/client-contact.model';
import { ClientType } from '../../../_models/client-type.model';
import { Client } from '../../../_models/client.model';

import { User } from '../../../_models/user.model';
import { ClientTypeService } from '../../../_services/client-type.service';
import { ClientService } from '../../../_services/client.service';
import { ContactService } from '../../../_services/contact.service';
import { UserService } from '../../../_services/user.service';

declare var jQuery: any;

@Component({
  selector: 'e2b-client-add',
  templateUrl: './my-company-add.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../../assets/design/semantic-ui/components/input.min.css',
    '../../../../assets/design/semantic-ui/components/message.min.css',
    '../../../../assets/design/semantic-ui/components/button.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class MyCompanyAddComponent implements OnInit, OnDestroy {

  @Input()
  saveEvent: EventEmitter<Client>;
  client: Client = new Client();
  managers: User[] = [];
  types: ClientType[] = [];
  selectManager: EventEmitter<User> = new EventEmitter<User>();
  addContact = new EventEmitter<ClientContact>();
  @ViewChild('selectClientFilial', { static: false })
  private selectClientFilial;
  @ViewChild('selectClientType', { static: false })
  private selectClientType;
  private clientTypeSubscribe: Subscription;
  private managerSubscribe: Subscription;
  public error = false;
  public errorMessage: any;

  constructor(private router: Router,
              private contactService: ContactService,
              private clientTypeService: ClientTypeService,
              private userService: UserService,
              private clientService: ClientService) {
    this.client.manager = new User();
    this.client.type = new ClientType();
    this.client.type.id = 1;
    this.client.contacts = [];
  }

  ngOnInit() {
    this.addContact.subscribe(contact => {
      if (this.client.contacts.find(c => {
        return c.name === contact.name;
      })) {
        this.client.contacts.map((c, k) => {
          if (c.name === contact.name) {
            this.client.contacts[k] = contact;
          }
        });
      } else {
        this.client.contacts.push(contact);
      }
    });
    this.selectManager.subscribe(manager => {
      this.client.manager = manager;
    });

    this.clientTypeSubscribe = this.clientTypeService.find<ClientType>().subscribe(types => {
      this.types = types;
      setTimeout(() => {
        types.map(type => {
          if (type.systemType === 'E2B.CLIENT.TYPE.OWN') {
            this.client.type = type;
          }
        });
      }, 0);
    }, console.error);

    const user = this.userService.current();
    this.managerSubscribe = this.userService.managers<User>().subscribe(managers => {
      this.managers = managers;
      this.managers.map(employee => {
        if (user.id === employee.id) {
          this.client.manager = employee;
        }
      });
    }, console.error);
  }

  save() {

    if (!this.client.name || !this.client.manager || !this.client.type) {
      this.error = true;
      return false;
    }
    this.clientService.create<Client>(this.client).toPromise().catch(e => {
      this.error = true;
      this.errorMessage = e;
      return of(false);
    }).then((created: Client) => {
      if (!created) {
        return false;
      }
      this.client.contacts.map(contact => {
        if (contact.id) {
          return false;
        }
        contact.client = new Client();
        contact.client.id = created.id;
        this.contactService.create<ClientContact>(contact).subscribe(() => {
        }, console.error);
      });
      if (this.saveEvent) {
        this.saveEvent.emit(created);
      } else {
        this.router.navigate(['/client/card/' + created.id]).catch(console.error);
      }
    });
  }

  ngOnDestroy() {
    if (this.clientTypeSubscribe) {
      this.clientTypeSubscribe.unsubscribe();
    }
    if (this.managerSubscribe) {
      this.managerSubscribe.unsubscribe();
    }
  }

}
