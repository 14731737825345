import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DealStep} from '../../../_models/deal-step.model';
import {DealStepService} from '../../../_services/deal-step.service';
import {UserService} from '../../../_services/user.service';
import {E2BAppViewType} from '../../../_models/enums/app-view-type.enum';

@Component({
  selector: 'e2b-deal-step-list',
  templateUrl: './deal-step-list.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/header.min.css',
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class DealStepListComponent implements OnInit {


  appviews = {
    [E2BAppViewType.DELIVERYMAN]: 'Доставщик',
    [E2BAppViewType.MOUNTER]: 'Монтажник',
    [E2BAppViewType.MEASURER]: 'Замерщик',
    [E2BAppViewType.RECLAMATION]: 'Рекламация'
  };

  steps: DealStep[] = [];

  loaded = false;

  constructor(public userService: UserService,
              private dealStepService: DealStepService) {
  }

  ngOnInit() {
    this.load().catch(console.error);
  }

  trackByFn(index, item) {
    return item.id;
  }

  async load() {
    this.loaded = false;

    let dealSteps = await this.dealStepService.find<DealStep>().toPromise();

    this.steps = dealSteps;

    dealSteps = null;

    this.loaded = true;
  }
}
