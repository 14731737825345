<form class="ui form" (submit)="save()">
  <div class="ui grid">
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Название <sup style="color: red">*</sup></h4>
      </div>
      <div class="six wide column">
        <div class="field required">
          <input id="name" type="text" name="name" [(ngModel)]="position.name" placeholder="Название" required>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Артикул</h4>
      </div>
      <div class="six wide column required">
        <input type="text" name="article" placeholder="Артикул" [(ngModel)]="position.article" required>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Тип</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <select name="type" [(ngModel)]="position.type">
            <option [selected]="position.type === 0" value="0">Нет</option>
            <option [selected]="position.type === 1" value="1">Корпусная мебель</option>
            <option [selected]="position.type == 2" value="2">Мягкая мебель</option>
            <option [selected]="position.type == 3" value="3">Матрас</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Группа</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <select name="type" [(ngModel)]="position.group">
            <option *ngFor="let group of groups" [selected]="position.group === group" [value]="group.id">{{group.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Цена <sup style="color: red">*</sup></h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="number" name="cost" [(ngModel)]="position.cost">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Цена М1</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="number" name="priceM1" [(ngModel)]="position.priceM1">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Цена М2</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="number" name="priceM2" [(ngModel)]="position.priceM2">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Цена П1</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="number" name="priceP1" [(ngModel)]="position.priceP1">
        </div>
      </div>
    </div>
  </div>
</form>
<div class="buttons mt-2">
  <button type="submit" (click)="save()" [disabled]="(!position.name || position.name === '') || !position.cost" class="ui button green">Добавить</button>
  <button routerLink="/catalog/list" class="ui button red">Отмена</button>
</div>
