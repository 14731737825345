<div class="content">
  <div class="description">
    <table class="ui table">
      <thead>
      <tr>
        <th *ngIf="canEdit"></th>
        <th>
          <div class="head">Позиция</div>
        </th>
        <th style="width: 75px">
          <div class="head">Кол-во</div>
        </th>
        <th style="width: 75px">
          <div class="head">Цена</div>
        </th>
        <th>
          <div class="head">Сумма</div>
        </th>
        <th>
          <div class="head" style="white-space: nowrap">Наценка</div>
        </th>
        <th>
          <div class="head">Скидка</div>
        </th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody *ngFor="let item of positions | orderByColumn:'id:number'; trackBy: identify"
             (mouseover)="selectedPosition = item"
             (mouseleave)="selectedPosition = null">
      <tr style="height: 58px">
        <td *ngIf="canEdit">
          <i class="ui red icon delete pointer" [e2bPermission]="['dealPosition', '/']" accessRightType="write"
             (click)="removePositionFromDeal(item)"></i>
        </td>
        <td>
          <span
            *ngIf="!onSelectPositionEvent || !userService.checkPermissions(['deal-position-value', '/'])">{{item.position.name}}</span>
          <a class="cursor" (click)="select(item)"
             *ngIf="onSelectPositionEvent && userService.checkPermissions(['deal-position-value', '/'])">{{item.position.name}}</a>
        </td>
        <td>
          <p *ngIf="!positionCount[item.position.id]"
             (click)="userService.checkPermissions(['dealPosition', '/'], ['write']) ? positionCount[item.position.id] = true : false;">
            {{item.count | number}}
          </p>
          <div class="ui input" *ngIf="positionCount[item.position.id]">
            <input type="number"
                   name="p{{item.position.id}}"
                   [(ngModel)]="item.count"
                   [ngModelOptions]="{updateOn: 'blur'}"
                   (ngModelChange)="calcDiscountPrice(item); savePosition(item)">
          </div>
        </td>
        <td>
          <p *ngIf="!positionPrice[item.position.id]"
             (click)="userService.checkPermissions(['dealPosition', '/'], ['write']) ? positionPrice[item.position.id] = true : false">
            {{item.price | number}}
          </p>
          <div class="ui input" *ngIf="positionPrice[item.position.id]">
            <input name="p{{item.position.id}}" type="number" [(ngModel)]="item.price"
                   [ngModelOptions]="{updateOn: 'blur'}"
                   (ngModelChange)="calcDiscountPrice(item); savePosition(item)">
          </div>
        </td>
        <td>{{item.count * item.price | number}}</td>
        <td>{{item.personalChangesCost}}</td>
        <td>
          <p
            (click)="userService.checkPermissions(['dealPosition', '/'], ['write']) ? positionDiscount[item.position.id] = true : false;">
            {{item.discountPrice | number}}
          </p>
        </td>
        <td>
          <p
            (click)="userService.checkPermissions(['dealPosition', '/'], ['write']) ? positionDiscount[item.position.id] = true : false;">
            <span *ngIf="item.discountPrice > 0" class="greytext">{{item.discountPercent | number:'1.0-1'}}%</span>
          </p>
        </td>
        <td>
          <div style="vertical-align: middle;">
            <i
              *ngIf="positionProblems[item.id] && positionProblems[item.id].problems.length > 0 && checkProblems(item.id)"
              class="icon exclamation triangle pointer" style="color: red; font-size: 1.2em;"
              title="Есть нераспределенные проблемы"></i>
            <i
              *ngIf="positionProblems[item.id] && positionProblems[item.id].problems.length > 0 && !checkProblems(item.id)"
              class="icon exclamation triangle pointer" style="color: green; font-size: 1.2em;"
              title="Все проблемы распределены"></i>
          </div>
        </td>
        <td>
          <div class="d-flex">
            <div *ngIf="item.production"
                 [attr.data-tooltip]="item.production.status === OrderStatus.IN_PROCESS ? 'Принято в производство' : (item.production.status === OrderStatus.COMPLETED ? 'Производство завершено' : (item.production.status === OrderStatus.REJECTED ? 'Отказано в производстве' : 'Производство:  ожидание принятия'))">
              <i class="small icons cursor" (click)="onOrderIconClick(item.production.id, item)">
                <i
                  [ngClass]="(item.production.status === OrderStatus.IN_PROCESS
                  ?' yellow'
                  : (item.production.status === OrderStatus.COMPLETED
                    ? ' green'
                    : (item.production.status === OrderStatus.REJECTED ? ' red' : ' grey'))) + ' big circle outline icon'">
                </i>
                <i [ngClass]="orderModeService.getOrderStyle(OrderType.PRODUCTION).icon + ' icon status-icon'"
                   [ngStyle]="{'color': orderModeService.getOrderStyle(OrderType.PRODUCTION).color}">
                </i>
              </i>
            </div>
            <div *ngIf="item.delivery"
                 [attr.data-tooltip]="item.delivery.status === OrderStatus.IN_PROCESS ? 'В процессе доставки' : (item.delivery.status === OrderStatus.COMPLETED ? 'Товар доставлен' : 'Доставка:  ожидание принятия')">
              <i class="small icons cursor" (click)="onOrderIconClick(item.delivery.id, item)">
                <i
                  [ngClass]="(item.delivery.status === OrderStatus.IN_PROCESS
                  ?' yellow'
                  : (item.delivery.status === OrderStatus.COMPLETED ? ' green' : ' grey')) + ' big circle outline icon'">
                </i>
                <i [ngClass]="orderModeService.getOrderStyle(OrderType.DELIVERY).icon + ' icon status-icon'"
                   [ngStyle]="{'color': orderModeService.getOrderStyle(OrderType.DELIVERY).color}">
                </i>
              </i>
            </div>
            <div *ngIf="item.mounting"
                 [attr.data-tooltip]="item.mounting.status === OrderStatus.IN_PROCESS ? 'Монтаж в работе' : (item.mounting.status === OrderStatus.COMPLETED ? 'Монтаж завершен' : 'Монтаж: ожидание принятия')">
              <i class="small icons cursor" (click)="onOrderIconClick(item.mounting.id, item)">
                <i
                  [ngClass]="(item.mounting.status === OrderStatus.IN_PROCESS
                  ?' yellow'
                  : (item.mounting.status === OrderStatus.COMPLETED ? ' green' : ' grey')) + ' big circle outline icon'">
                </i>
                <i [ngClass]="orderModeService.getOrderStyle(OrderType.MOUNTING).icon + ' icon status-icon'"
                   [ngStyle]="{'color': orderModeService.getOrderStyle(OrderType.MOUNTING).color}">
                </i>
              </i>
            </div>
            <div *ngIf="item.reclamation"
                 [attr.data-tooltip]="item.reclamation.status === OrderStatus.IN_PROCESS ? 'Рекламация в работе' : (item.reclamation.status === OrderStatus.COMPLETED ? 'Рекламация завершена' : 'Рекламация:  ожидание принятия')">
              <i class="small icons cursor" (click)="onOrderIconClick(item.reclamation.id, item)">
                <i
                  [ngClass]="(item.reclamation.status === OrderStatus.IN_PROCESS
                  ?' yellow'
                  : (item.reclamation.status === OrderStatus.COMPLETED ? ' green' : ' grey')) + ' big circle outline icon'">
                </i>
                <i [ngClass]="orderModeService.getOrderStyle(OrderType.RECLAMATION).icon + ' icon status-icon'"
                   [ngStyle]="{'color': orderModeService.getOrderStyle(OrderType.RECLAMATION).color}">
                </i>
              </i>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="positionDiscount[item.position.id]">
        <td colspan="6">
          <div class="two fields" *ngIf="positionDiscount[item.position.id]">
            <div class="field">
              <div class="ui right labeled input">
                <input type="number"
                       name="p{{item.position.id}}"
                       [(ngModel)]="item.discountPrice"
                       placeholder="Сумма"
                       [ngModelOptions]="{updateOn: 'blur'}"
                       (ngModelChange)="calcDiscountPercent(item); savePosition(item)">
                <div class="ui label">руб.</div>
              </div>
            </div>
            <div class="field w-50">
              <div class="ui right labeled input">
                <input type="number"
                       name="p{{item.position.id}}"
                       [(ngModel)]="item.discountPercent"
                       placeholder="Процент"
                       [ngModelOptions]="{updateOn: 'blur'}"
                       (ngModelChange)="calcDiscountPrice(item); savePosition(item)">
                <div class="ui label">%</div>
              </div>
            </div>
          </div>
        </td>
        <td>
          <i class="ui red icon delete pointer" (click)="positionDiscount = {};"></i>&nbsp;&nbsp;
        </td>
      </tr>
      </tbody>
      <tbody>
      <tr *ngIf="addedPosition">
        <td *ngIf="canEdit">
          <div style="position: absolute; margin-left: -20px;">
            <i *ngIf="selectedPosition === addedPosition" class="ui red icon delete pointer"></i>
          </div>
        </td>
        <td>
          <e2b-deal-position-dropdown [positions]="products" [event]="selectPosition"></e2b-deal-position-dropdown>
        </td>
        <td>
          <div class="field">
            <input type="number"
                   placeholder="Кол-во"
                   [(ngModel)]="addedPosition.count"
                   name="positionCount">
          </div>
        </td>
        <td>
          <div class="field">
            <input type="number"
                   placeholder="Цена"
                   [(ngModel)]="addedPosition.price"
                   name="positionPrice">
          </div>
        </td>
        <td nowrap>
          {{addedPosition.count * addedPosition.price | number}}
          <button *ngIf="addedPosition && addedPosition.position" class="ui basic green button" (click)="save()"
                  [e2bPermission]="['dealPosition', '/']" accessRightType="write"
                  title="Добавить позицию" style="margin-right: 0; margin-left: 5%">+
          </button>
        </td>
      </tr>
      <tr *ngIf="addedPosition">
        <td colspan="4">
          <button class="ui fluid basic red button" (click)="clearPosition()">Отмена</button>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td *ngIf="canEdit"></td>
        <td></td>
        <td colspan="6" class="text-right" *ngIf="positions && positions.length > 0">
          <table class="ui very basic table">
            <tr>
              <td>Итого:</td>
              <td>
                <b>{{totalDealSum() | number}}</b> руб.
              </td>
            </tr>
            <tr>
              <td>
                Скидка:
              </td>
              <td>
                <div *ngIf="!dealDiscount">
                  <span *ngIf="canEdit && hasDiscount() !== 0">
                    <a class="fw-bold" (click)="dealDiscount = true">{{hasDiscount()|number}}</a> руб.&nbsp;&nbsp;
                  </span>
                  <span class="fw-bold" *ngIf="!canEdit">{{hasDiscount()|number}} руб.&nbsp;&nbsp;</span>
                  <span class="greytext"
                        *ngIf="!canEdit || hasDiscount() !== 0">{{((hasDiscount() / totalSum()) * 100) | number:'1.0-1'}}
                    %</span>
                </div>
                <div *ngIf="hasDiscount() === 0 && !dealDiscount && canEdit">
                  <i class="ui square add icon" (click)="dealDiscount = true"></i>
                </div>
                <div *ngIf="dealDiscount">
                  <div class="field">
                    <div class="ui right labeled input">
                      <input type="number"
                             [(ngModel)]="discountPercent"
                             placeholder="Процент"
                             [ngModelOptions]="{updateOn: 'blur'}"
                             (ngModelChange)="calcDealDiscount(discountPercent)">
                      <div class="ui label">
                        %
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
  <button class="ui basic primary button" style="margin-top: 20px;" [e2bPermission]="['dealPosition', '/']"
          accessRightType="write" *ngIf="!addedPosition" (click)="addPosition()">+ Добавить позицию
  </button>
</div>
