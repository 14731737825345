import { KeyValue } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Direction } from '../../_models/direction.model';
import { Reference, ReferenceType } from '../../_models/reference.model';
import { Unit } from '../../_models/unit.model';
import { DirectionService } from '../../_services/direction.service';
import { ReferenceService } from '../../_services/reference.service';
import { UnitService } from '../../_services/unit.service';

@Component({
  selector: 'e2b-reference-show',
  templateUrl: './reference-show.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/icon.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css',
    '../../../assets/design/semantic-ui/components/button.min.css',
    '../../../assets/design/semantic-ui/components/table.min.css',
    '../../../assets/design/semantic-ui/components/menu.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css'
  ],
  encapsulation: ViewEncapsulation.None
})

export class ReferenceShowComponent implements OnInit {
  references: {
    week: any,
    month: any
  } = {
    week: {},
    month: {}
  };
  directions: Direction[];
  units: Unit[];
  currentUnit: Unit;

  constructor(private referenceService: ReferenceService,
              private directionService: DirectionService,
              private unitService: UnitService) {

  }

  public ascOrder(a: KeyValue<number, any>, b: KeyValue<number, any>): number {
    return a.value.direction.name.localeCompare(b.value.direction.name);
  }

  async ngOnInit() {

    this.directions = await this.directionService.findAll<Direction>().toPromise();

    this.units = await this.unitService.find<Unit>().toPromise();

    this.units.map(u => {
      if (u.code === '000000001') {
        this.currentUnit = u;
      }
    });

    return await this.load().catch(console.error);
  }

  async load() {
    const references = await this.referenceService.find(this.currentUnit).toPromise();

    if (references.week) {
      this.references.week = references.week;
    }
    if (references.month) {
      this.references.month = references.month;
    }
    this.directions.map(d => {
      if (!this.references.week[d.id]) {
        this.references.week[d.id] = {
          amount: null,
          type: ReferenceType.Week,
          direction: d,
          unit: this.currentUnit
        };
      }
      if (!this.references.month[d.id]) {
        this.references.month[d.id] = {
          amount: null,
          type: ReferenceType.Month,
          direction: d,
          unit: this.currentUnit
        };
      }
    });
  }

  save(reference: Reference) {
    if (!reference.id) {
      this.referenceService.create(reference).toPromise().then(created => {
        if (reference.type === ReferenceType.Month) {
          this.references.month[reference.direction.id] = created;
        } else if (reference.type === ReferenceType.Week) {
          this.references.week[reference.direction.id] = created;
        }
      });
    } else {
      this.referenceService.update(reference).toPromise().catch(console.error);
    }
  }

  selectUnit(unit: Unit) {
    this.currentUnit = unit;
    this.load().catch(console.error);
  }

}
