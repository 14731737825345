import {Component, EventEmitter, OnInit, ViewEncapsulation} from '@angular/core';
import * as moment from 'moment';
import {Todo} from '../../_models/todo.model';
import {User} from '../../_models/user.model';
import {DealService} from '../../_services/deal.service';
import {TodoService} from '../../_services/todo.service';
import {UserService} from '../../_services/user.service';
import {ActionService} from '../../action/action.service';

@Component({
  selector: 'e2b-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/container.min.css',
    '../../../assets/design/semantic-ui/components/label.min.css',
    '../../../assets/design/semantic-ui/components/divider.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../assets/design/semantic-ui/components/grid.min.css',
    './dashboard-widget.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardWidgetComponent implements OnInit {

  currentUpdate: number | null = null;
  day: string = moment().format('YYYY-MM-DD');
  loaded = false;
  reload = new EventEmitter();
  selectUserEvent = new EventEmitter();
  showDeliveryCoordinator: boolean = localStorage.getItem('user.settings.dashboard.show.delivery.coordinator') ? (localStorage.getItem('user.settings.dashboard.show.delivery.coordinator') === 'true') : true;
  showMountingCoordinator: boolean = localStorage.getItem('user.settings.dashboard.show.mounting.coordinator') ? (localStorage.getItem('user.settings.dashboard.show.mounting.coordinator') === 'true') : true;
  showReclamation: boolean = localStorage.getItem('user.settings.dashboard.show.reclamation') ? (localStorage.getItem('user.settings.dashboard.show.reclamation') === 'true') : true;
  showSwitch = false;
  showTodo: boolean = localStorage.getItem('user.settings.dashboard.show.todo') ? (localStorage.getItem('user.settings.dashboard.show.todo') === 'true') : true;
  showDirector: boolean = localStorage.getItem('user.settings.dashboard.show.todo') ? (localStorage.getItem('user.settings.dashboard.show.director') === 'true') : true;
  todos: Todo[] = [];
  users: User[];

  constructor(private actionService: ActionService,
              private todoService: TodoService,
              private dealService: DealService,
              public userService: UserService) {
  }

  changeSetting(name, value) {
    localStorage.setItem(name, value ? 'true' : 'false');
  }

  checkSwitch() {
    return localStorage.getItem('e2b.user.tmp.userId');
  }

  identify(index, obj) {
    return obj ? obj.id : undefined;
  }

  ngOnInit() {
    const megaplan = new EventEmitter();
    const sbr = new EventEmitter();
    const switchEvent = new EventEmitter();
    const user: User = this.userService.current();
    const userRoleNames = user.roles.map(role => role.name);
    if (userRoleNames.indexOf('E2B.ROLE.PRODUCTION') === -1) {
      if (this.userService.checkPermissions(['deal', '/'], ['write'])) {
        this.actionService.createMenuEvent.emit({
          name: 'Добавить сделку',
          link: '/dealWizard',
          class: 'blue',
          icon: 'plus square',
        });

        this.actionService.createMenuEvent.emit({
          name: 'Продажи',
          link: '/sales-wizard',
          class: 'blue',
          icon: 'plus square',
        });

        this.actionService.createMenuEvent.emit({
          name: 'Показ',
          link: '/show-wizard',
          class: 'blue',
          icon: 'plus square',
        });
      }
      this.actionService.createActionEvent.emit({
        name: 'Мегаплан',
        event: megaplan,
        class: 'green',
        icon: 'share square',
      });
      this.actionService.createActionEvent.emit({
        name: 'СБР',
        event: sbr,
        class: 'blue',
        icon: 'share square',
      });
      this.todoService.findByUserId<Todo>(this.userService.current().id, this.day).subscribe(todos => {
        this.todos = todos;
        this.loaded = true;
      });
      this.userService.production<User>().subscribe(users => {
        this.users = users;
      });
      this.selectUserEvent.subscribe(selected => {
        localStorage.setItem('e2b.user.tmp.userId', localStorage.getItem('e2b.user.userId'));
        localStorage.setItem('e2b.user.tmp.json', localStorage.getItem('e2b.user.json'));
        localStorage.setItem('e2b.user.tmp.token', localStorage.getItem('e2b.user.token'));
        this.userService.switch(selected.id).subscribe({
          next: (response) => {
            localStorage.setItem('e2b.user.userId', response.user.id);
            localStorage.setItem('e2b.user.json', JSON.stringify(response.user));
            localStorage.setItem('e2b.user.token', response.token);
            window.location.reload();
          },
          error: err => {
            console.error(err);
          }
        });
      });
    }
    if (this.userService.checkPermissions(['user', 'switch', '/'])) {
      this.actionService.createActionEvent.emit({
        name: 'Переключить пользователя',
        class: 'blue',
        icon: 'exchange',
        roles: ['E2B.ROLE.SWITCH'],
        event: switchEvent
      });
      switchEvent.subscribe(() => {
        this.showSwitch = !this.showSwitch;
      });
    }
    megaplan.subscribe(() => {
      window.open('https://megaplan.olissys.com', '_target');
    });
    sbr.subscribe(() => {
      window.open('http://195.208.37.56:8888', '_target');
    });
  }

  switchAdmin() {
    localStorage.setItem('e2b.user.userId', localStorage.getItem('e2b.user.tmp.userId'));
    localStorage.setItem('e2b.user.json', localStorage.getItem('e2b.user.tmp.json'));
    localStorage.setItem('e2b.user.token', localStorage.getItem('e2b.user.tmp.token'));
    localStorage.removeItem('e2b.user.tmp.userId');
    localStorage.removeItem('e2b.user.tmp.json');
    localStorage.removeItem('e2b.user.tmp.token');
    window.location.reload();
  }
}
