import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

/**
 * Сервис отчетов
 * @class ReportService
 */
@Injectable()
export class ReportService extends BaseService {

  baseUrl = '/report';

  assortment(options: any) {
    return this.http.post(this.baseUrl + '/assortment', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  balance(options: { from: string, to: string }) {
    return this.http.post(this.baseUrl + '/balance', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  bonus(options: { from: string, to: string, personId?: number[], filialId?: number[], managerId?: number[] }) {
    return this.http.post(this.baseUrl + '/bonus', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  call(options: { from: string, to: string, managerId?: string[], statusId?: string[], directionId?: string[] }) {
    return this.http.post(this.baseUrl + '/call', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  credit(options: { from: string, to: string, personId?: number[], filialId?: number[], managerId?: number[] }) {
    return this.http.post(this.baseUrl + '/credit', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  delivery(options: { from: string, to: string, managerId?: number[], statusId?: number[], filialId?: number[] }) {
    return this.http.post(this.baseUrl + '/delivery', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  documents(options: { from: string, to: string, personId?: string[], unitId?: string[], directionId?: string[], typeId?: string[], cardPayment?: boolean }) {
    return this.http.post(this.baseUrl + '/documents', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  forgotten(options: { from: string, to: string, managerId?: number[], filialId?: number[] }) {
    return this.http.post(this.baseUrl + '/forgotten', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  gauging(options: { from: string, to: string, managerId?: number[], statusId?: number[], filialId?: number[] }) {
    return this.http.post(this.baseUrl + '/gauging', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  managerActivity(options: { from: string, to: string, typeId?: number[], managerId?: number[], statusId?: number[], filialId?: number[] }) {
    return this.http.post(this.baseUrl + '/managerActivity', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  money(options: { from: string, to: string, managerId?: number[], filialId?: number[] }) {
    return this.http.post(this.baseUrl + '/money', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  mounting(options: { from: string, to: string, managerId?: number[], statusId?: number[], filialId?: number[] }) {
    return this.http.post(this.baseUrl + '/mounting', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  pnl(options: { from: string, to: string, personId?: string[], unitId?: string[] }) {
    return this.http.post(this.baseUrl + '/pnl', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  problems(options: { from: string, to: string, groupId?: string[] }) {
    return this.http.post(this.baseUrl + '/problem', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  production(options: { from: string, to: string, typeId?: number[], managerId?: number[], statusId?: number[], filialId?: number[] }) {
    return this.http.post(this.baseUrl + '/production', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  reclamation(options: {
    from: string;
    to: string;
    dateType: string;
    sourceId?: number[];
    managerId?: number[];
    onlyOverNorm?: boolean;
  }) {
    return this.http.post(this.baseUrl + '/reclamation', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  rentability(options: { from: string, to: string, managerId?: number[], filialId?: number[] }) {
    return this.http.post(this.baseUrl + '/rentability', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  sale(options: { from: string, to: string, channelId?: number[], managerId?: number[], filialId?: number[], dateModify?: string }) {
    return this.http.post(this.baseUrl + '/sale', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  square(options: { from: string, to: string, managerId?: number[], filialId?: number[] }) {
    return this.http.post(this.baseUrl + '/square', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  timeStatus(options: { from: string, to: string, statusId?: number }) {
    return this.http.post(this.baseUrl + '/timeStatus', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  incomingDeals(options: { from: any, to: any, managerIds?: number[], adSourceIds?: number[], statusIds?: number[] }) {
    return this.http.post(this.baseUrl + '/incomingDeals', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  prognosis(options: { filialId: any[]; from: string; to: string; managerId: any[] }) {
    return this.http.post(this.baseUrl + '/prognosis', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  history(options: { typeId: any[]; from: string; to: string; managerId: any[] }) {
    return this.http.post(this.baseUrl + '/history', { options: options }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  public compare<T>(options: any) {
    return this.http.post('/integration/compare', options, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
