import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { OrderType } from '../../../_models/enums/order-type.enum';
import { OrderModeService } from '../order-mode.service';

@Component({
  selector: 'e2b-order-label',
  templateUrl: './order-label.component.html',
  styleUrls: ['./order-label.component.less']
})
export class OrderLabelComponent implements OnInit {

  @Input()
  orderType: OrderType;
  constructor(public readonly orderModeService: OrderModeService) { }

  ngOnInit() {
  }

}
