import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Client } from '../../_models/client.model';
import { User } from '../../_models/user.model';
import { ClientService } from '../../_services/client.service';
import { UserService } from '../../_services/user.service';
import { Action, ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/grid.min.css',
    '../../../assets/design/semantic-ui/components/menu.min.css',
    '../../../assets/design/semantic-ui/components/table.min.css',
    './client-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ClientListComponent implements OnInit, OnDestroy {
  private listSubscribe: Subscription;
  clients: Array<Client>;
  filter: Client = new Client();
  currentUser: User;
  loaded = false;

  constructor(private clientService: ClientService, private actionService: ActionService, protected userService: UserService) {
    this.filter.manager = new User();
  }

  ngOnInit() {
    this.currentUser = this.userService.current();

    if (this.userService.checkPermissions(['client', '/'], ['write'])) {
      this.actionService.createMenuEvent.emit(<Action>{ name: 'Добавить клиента', link: '/client/add', class: 'blue' });
    }

    this.listSubscribe = this.clientService.find<Client>().subscribe(res => {
      this.clients = res;
      this.loaded = true;
    }, console.error);
  }

  ngOnDestroy() {
    this.listSubscribe.unsubscribe();
  }

  applyManagerFilter(filter) {
    this.filter.manager = filter;
  }

  clearFilter() {
    this.filter = new Client();
    this.filter.manager = new User();
  }

}
