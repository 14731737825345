import { Pipe, PipeTransform } from '@angular/core';
import { Client } from '../../_models/client.model';

@Pipe({
  name: 'clientFilter',
  pure: false
})
export class ClientFilterPipe implements PipeTransform {
  transform(items: Client[], filter: Client): Client[] {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item: Client) => this.applyFilter(item, filter));
  }

  applyFilter(client: Client, filter: Client): boolean {
    for (const field in filter) {
      if (filter[field]) {
        if (typeof filter[field] === 'string') {
          if (client[field].toLowerCase().indexOf(filter[field].toLowerCase()) === -1) {
            return false;
          }
        } else if (typeof filter[field] === 'number') {
          if (client[field] !== filter[field]) {
            return false;
          }
        } else if (typeof filter[field] === 'object' && filter[field]['id']) {
          if (client[field] && client[field].id !== filter[field].id) {
            return false;
          } else if (!client[field]) {
            return false;
          }
        }
      }
    }
    return true;
  }
}
