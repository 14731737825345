import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanNumber'
})
export class HumanNumberPipe implements PipeTransform {
  transform(num: any, fixed = 0): string {
    if (!num) {
      return num;
    }
    if (num === 0) {
      return '0';
    }
    fixed = (!fixed || fixed < 0) ? 0 : fixed;
    const b = (num).toPrecision(2).split('e'),
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3),
      c = k < 1 ? num.toFixed(fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed),
      d = c < 0 ? c : Math.abs(c);
    return d + ['', ' к', ' м', ' мд', ' тр'][k];
  }
}
