import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Person } from '../_models/person.model';
import { BaseService } from './base.service';

@Injectable()
export class PersonService extends BaseService {
  baseUrl = '/person';

  findByClientId(id): Observable<Person[]> {
    return this.http.get<Person[]>(this.baseUrl + '/client/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
