import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../_services/user.service';

@Pipe({
  name: 'checkFieldPermissionPipe',
  pure: false
})

@Injectable()
export class CheckFieldPermissionPipe implements PipeTransform {

  constructor(private userService: UserService) {
  }

  transform(fields: any[], args: any) {
    if (!this.userService.current()) {
      return [];
    }
    return fields.filter(field => {
      return this.userService.current().accessObject.fields[field.id] && ['read', 'write'].indexOf(this.userService.current().accessObject.fields[field.id]) > -1;
    });
  }
}
