<form class="ui form">
  <div class="ui stackable grid">
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Тип плательщика</h4>
      </div>
      <div class="six wide column">
        <div class="inline fields">
          <div class="field" *ngFor="let type of types">
            <div class="ui radio checkbox">
              <input type="radio" name="type" [checked]="person.type === type" (change)="selectType(type)"/>
              <label>{{type.name}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person && person.type && person.type.id">
      <div class="ui two wide column">
        <h4 class="header" *ngIf="person.type && ['E2B.PERSON.TYPE.IP','E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1">Название <sup style="color: red">*</sup></h4>
        <h4 class="header" *ngIf="person.type && ['E2B.PERSON.TYPE.HUMAN'].indexOf(person.type.systemType) > -1">Ф.И.О. <sup style="color: red">*</sup></h4>
      </div>
      <div class="ui six wide column">
        <div class="field required">
          <input type="text" name="name" [(ngModel)]="person.name" placeholder="{{person.type && ['E2B.PERSON.TYPE.IP','E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1 ? 'Название' : 'Ф.И.О.'}}" required>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!clientId">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Клиент</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <e2b-client-dropdown [clients]="clients" [event]="selectClient"></e2b-client-dropdown>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Ген. директор</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" [(ngModel)]="person.manager" name="manager" class="form-control" placeholder="Ген. директор">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && ['E2B.PERSON.TYPE.IP','E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Юр. адрес <sup *ngIf="person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'" style="color: red">*</sup></h4>
      </div>
      <div class="six wide column">
        <div class="field required">
          <input type="text" [(ngModel)]="person.address" name="address" class="form-control" placeholder="Адрес">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Почтовый. адрес</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" [(ngModel)]="person.postAddress" name="postAddress" class="form-control" placeholder="Адрес">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && ['E2B.PERSON.TYPE.IP','E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">ИНН <sup style="color: red">*</sup></h4>
      </div>
      <div class="six wide column">
        <div class="field required">
          <input type="text" [(ngModel)]="person.inn" name="inn" placeholder="ИНН" required>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">КПП</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" [(ngModel)]="person.kpp" name="kpp" placeholder="КПП">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && ['E2B.PERSON.TYPE.IP','E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">ОКПО</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" name="okpo" [(ngModel)]="person.okpo" placeholder="ОКПО">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.COMPANY'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">ОГРН</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" name="ogrn" [(ngModel)]="person.ogrn" placeholder="ОГРН">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.IP'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">ГРНИП</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" name="grnip" [(ngModel)]="person.grnip" placeholder="ГРНИП">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.IP'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Дата выдачи ГРНИП</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input suiDatepicker
                 [name]="'grnipDate'"
                 [pickerMode]="'date'"
                 [pickerFirstDayOfWeek]="1"
                 [pickerUseNativeOnMobile]="true"
                 [pickerPlacement]="'top left'"
                 [(ngModel)]="person.grnipDate"
                 [placeholder]="'Дата выдачи ГРНИП'">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && ['E2B.PERSON.TYPE.IP','E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Банк</h4>
      </div>
      <div class="ui six wide column">
        <div class="fluid field">
          <ng-template let-result let-query="query" #bankOptionTemplate>
            <div class="mininame">{{result.name}}</div>
            <div class="miniprice">{{result.bik}}</div>
            <div class="minitext">{{result.address}}</div>
          </ng-template>
          <sui-search style="width: 100%;"
                      [optionsLookup]="bankSearch"
                      name="bank"
                      [maxResults]="25"
                      [hasIcon]="true"
                      placeholder="БИК или название банка"
                      optionsField="name"
                      [resultTemplate]="bankOptionTemplate"
                      (resultSelected)="bankSelected($event)">
          </sui-search>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && ['E2B.PERSON.TYPE.IP','E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Расчетный счет</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" name="rs" [(ngModel)]="person.rs" placeholder="Расчетный счет">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && ['E2B.PERSON.TYPE.IP','E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Корр. счет</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" name="ks" [(ngModel)]="person.ks" placeholder="Корр. счет">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && ['E2B.PERSON.TYPE.IP','E2B.PERSON.TYPE.COMPANY'].indexOf(person.type.systemType) > -1">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Соединение с 1С</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <sui-select class="fluid selection"
                      placeholder="База 1С"
                      [isDisabled]="false"
                      [(ngModel)]="person.connectionLink"
                      [ngModelOptions]="{name: 'connectionLink'}"
                      [options]="connectionLinks"
                      #selectConnectionLink>
            <sui-select-option *ngFor="let link of selectConnectionLink.filteredOptions"
                               [value]="link">
            </sui-select-option>
          </sui-select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.HUMAN'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Паспорт: серия</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" name="passportSerie" [(ngModel)]="person.passport.serie" placeholder="Серия паспорта">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.HUMAN'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Паспорт: номер</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" name="passportNumber" [(ngModel)]="person.passport.number" placeholder="Номер паспорта">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.HUMAN'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Паспорт: кто выдал</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" name="passportOrg" [(ngModel)]="person.passport.org" placeholder="Кто выдал">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.HUMAN'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Паспорт: дата выдачи</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input suiDatepicker
                 name="passportDate"
                 [pickerMode]="'date'"
                 [pickerFirstDayOfWeek]="1"
                 [pickerUseNativeOnMobile]="true"
                 [pickerPlacement]="'top left'"
                 [(ngModel)]="person.passport.date"
                 placeholder="Дата выдачи">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.HUMAN'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Паспорт: код подразделения</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" name="passportCode" [(ngModel)]="person.passport.code" placeholder="Код подразделения">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="person.type && person.type.systemType === 'E2B.PERSON.TYPE.HUMAN'">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Паспорт: прописка</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <input type="text" name="passportAddress" [(ngModel)]="person.passport.address" placeholder="Прописка">
        </div>
      </div>
    </div>
  </div>
</form>
<div class="buttons mt-2">
  <button class="ui button green" (click)="save()">Добавить</button>
  <button routerLink="/client/person" class="ui button red">Отмена</button>
</div>
