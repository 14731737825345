import {Component, EventEmitter, OnInit, ViewEncapsulation} from '@angular/core';
import {PositionFieldValue} from '../../_models/position-field-value.model';
import {PositionGroup} from '../../_models/position-group.model';
import {Position} from '../../_models/position.model';
import {FileInterface, FileService} from '../../_services/file.service';
import {PersonService} from '../../_services/person.service';
import {PositionFieldValueService} from '../../_services/position-field-value.service';
import {PositionGroupService} from '../../_services/position-group.service';
import {PositionService} from '../../_services/position.service';
import {UserService} from '../../_services/user.service';
import {ActionService} from '../../action/action.service';
import {AssemblySchemeService} from '../../_services/assembly-scheme.service';
import {AssemblyScheme} from '../../_models/assembly-scheme.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'e2b-position-show',
  templateUrl: './position-show.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../assets/design/semantic-ui/components/tab.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class PositionShowComponent implements OnInit {

  groups: PositionGroup[] = [];

  position: Position = new Position();

  types: [{
    id: 1,
    name: 'Корпусная мебель'
  }, {
    id: 2,
    name: 'Мягкая мебель'
  }, {
    id: 3,
    name: 'Матрас'
  }];

  id: number;

  loaded = false;

  isFieldsLoading = false;

  positionFields: any = [];

  assemblySchemes: AssemblyScheme[];

  constructor(private fileService: FileService,
              private actionService: ActionService,
              private personService: PersonService,
              private userService: UserService,
              private route: ActivatedRoute,
              private router: Router,
              private assemblySchemeService: AssemblySchemeService,
              private positionService: PositionService,
              private positionFieldValueService: PositionFieldValueService,
              private positionGroupService: PositionGroupService) {
  }

  ngOnInit() {

    if (!this.userService.checkPermissions(['position', '/'], ['write'])) {
      this.router.navigateByUrl('/catalog/list').catch(console.error);
    }

    this.id = parseInt(this.route.snapshot.params.id, 0);

    this.load();
  }

  load() {
    this.positionService.findById<Position>(this.id).subscribe(position => {
      this.position = position;

      this.positionGroupService.find<PositionGroup>().subscribe(groups => {
        this.groups = groups;

        if (position.group) {
          groups.map(group => {
            if (position.group.id === group.id) {
              position.group = group;
            }
          });
        }

        this.assemblySchemeService.find<AssemblyScheme>().subscribe(assemblySchemes => {
          this.assemblySchemes = assemblySchemes;

          if (position.assemblyScheme) {
            assemblySchemes.map(assemblyScheme => {
              if (position.assemblyScheme.id === assemblyScheme.id) {
                position.assemblyScheme = assemblyScheme;
              }
            });
          }

          this.loaded = true;
        });

      });
    });
  }

  save() {

    if (!this.userService.checkPermissions(['position', '/'], ['write'])) {
      return false;
    }

    this.positionService.update<Position>(this.position).subscribe(() => {
    });
  }

  loadFields() {

    if (this.positionFields && this.positionFields.length > 0) {
      this.isFieldsLoading = false;
      return false;
    }

    this.isFieldsLoading = true;
    this.positionGroupService.findById(this.position.group.id).subscribe((group: PositionGroup) => {

      this.position.group.template = group.template;

      this.positionFieldValueService.findById(this.position.id).subscribe((values: PositionFieldValue[]) => {

        const v = {};

        values.map(val => {
          v[val.field.id] = val;
        });

        this.isFieldsLoading = false;

        this.positionFields = group.template.fields.map(field => {
          if (v[field.id]) {
            const t: any = {...field, ...{position: this.position}, ...{value: v[field.id].value, valueId: v[field.id].id}, event: new EventEmitter()};
            if (field.type === 'USER') {
              t.value = field.values.find(fv => {
                return fv.id === t.value.id;
              });
            } else if (field.type === 'USERS') {
              t.value = field.values.find(fv => {
                return fv.id === t.value.id;
              });
            } else if (field.type === 'USERGROUP') {
              t.value = field.values.find(fv => {
                return fv.id === t.value.id;
              });
            } else if (field.type === 'MATERIAL') {
              t.value = field.values.find(fv => {
                return fv.id === t.value.id;
              });
            } else if (field.type === 'CLOTH') {
              t.value = field.values.find(fv => {
                return fv.id === t.value.id;
              });
            } else if (field.type === 'PERSONAL') {
              const value = [...t.value];
              t.value = [];
              field.values.filter(fv => {
                value.map(ve => {
                  if (fv.id === ve.id) {
                    t.value.push(fv);
                  }
                });
              });
            }
            t.event.subscribe(vv => {
              t.value = vv;
              this.saveFieldValue(t);
            });
            return t;
          } else {
            const t: any = {...field, ...{position: this.position}, event: new EventEmitter()};
            t.event.subscribe(vv => {
              t.value = vv;
              this.saveFieldValue(t);
            });
            return t;
          }
        });
      });
    });
  }

  saveFieldValue(field: any) {
    if (!field.value) {
      return false;
    }
    if (!field.valueId) {
      this.positionFieldValueService.create<PositionFieldValue>(<PositionFieldValue>{
        field: {id: field.id},
        value: field.value,
        position: field.position.id
      }).subscribe(() => {
        this.positionFields.map((f, k) => {
          if (f.id === field.id) {
            this.positionFields[k] = field;
          }
        });
      }, console.error);
    } else {
      this.positionFieldValueService.update<PositionFieldValue>(<PositionFieldValue>{
        id: field.valueId,
        field: {id: field.id},
        value: field.value,
        position: field.position.id
      }).subscribe(() => {
        this.positionFields.map((f, k) => {
          if (f.id === field.id) {
            this.positionFields[k] = field;
          }
        });
      }, console.error);
    }
  }

  removeFile(field, file) {
    const yes = confirm(`Вы уверены, что хотите удалить ${file.name} из поля ${field.name}?`);
    if (yes) {
      field.value.splice(field.value.indexOf(file), 1);
      this.saveFieldValue(field);
    }
  }

  removeFieldValue(field) {
    const yes = confirm(`Вы уверены, что хотите удалить данные из поля ${field.name}?`);
    if (yes) {
      if (field.valueId) {
        this.positionFieldValueService.deleteById(field.valueId).subscribe(() => {
          delete field.value;
          delete field.valueId;
        }, console.error);
      } else {
        delete field.value;
      }
    }
  }

  addFiles(field, files) {
    this.fileService.upload(files).then((f: FileInterface[]) => {
      if (field.value) {
        field.value = field.value.concat(f);
      } else {
        field.value = f;
      }
      this.saveFieldValue(field);
    });
  }
}
