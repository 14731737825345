import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../_services/user.service';


@Component({
  selector: 'e2b-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/button.min.css',
    '../../assets/design/semantic-ui/components/grid.min.css',
    '../../assets/design/semantic-ui/components/container.min.css',
    '../../assets/design/semantic-ui/components/segment.min.css',
    '../../assets/design/semantic-ui/components/form.min.css',
    '../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../assets/design/semantic-ui/components/message.min.css',
    '../login/login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  http: HttpClient;
  submitted: boolean;
  userService: UserService;
  error: any;
  isSubmit = false;

  constructor(@Inject(FormBuilder) private fb: FormBuilder, http: HttpClient, userService: UserService) {
    this.http = http;
    this.userService = userService;
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.resetPasswordForm = new FormGroup({
      'email': new FormControl('', [
        Validators.required,
        Validators.email
      ])
    });
  }

  get email() {
    return this.resetPasswordForm.get('email');
  }

  onSubmit() {
    this.userService.resetPassword(this.email.value).subscribe(() => {
      },
      e => {
        this.error = e;
      });
    this.isSubmit = true;
  }
}
