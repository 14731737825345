import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Comment } from '../../_models/comment.model';
import { DealPosition } from '../../_models/deal-position.model';
import { CommentType } from '../../_models/enums/comment-type.enum';
import { CommentService } from '../../_services/comment.service';
import { OrderService } from '../../_services/order.service';
import { OrderStatus } from '../../_models/enums/order-status.enum';
import { Order } from '../../_models/order.model';

@Component({
  selector: 'e2b-production',
  templateUrl: './production.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/dimmer.min.css',
    './production.component.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ProductionShowComponent implements OnInit {

  id: number;
  order: any = {
    positions: [],
    projectConstruction: [],
    client: {},
    projectSpecifications: [],
    contract: {},
    problems: {},
    currentStatus: {}
  };
  statusUpdated = true;
  currentPosition: DealPosition = null;
  comments: Comment[] = [];
  loaded = false;

  constructor(private route: ActivatedRoute,
              private orderService: OrderService,
              private router: Router,
              private commentService: CommentService) {

    this.id = parseInt(this.route.snapshot.params.id, 0);
  }

  ngOnInit() {
    this.loadOrder();
  }

  get CommentType() {
    return CommentType;
  }

  get OrderStatus() {
    return OrderStatus;
  }

  loadOrder() {
    this.orderService.productionOrder(this.id).subscribe((order: any) => {
      this.order = order;
      this.commentService.personal<Comment[]>(this.order.deal.id).subscribe(comments => {
        this.comments = comments;
      });
      this.loaded = true;
    }, () => {
      this.router.navigate(['/']).catch(e => {
        console.log(e);
      });
    });
  }

  accept() {
    this.statusUpdated = false;
    const acceptDate = moment().format('DD.MM.YYYY HH:mm:ss');
    this.orderService.productionAccept({ id: this.order.id }).subscribe(() => {
      this.order.positions.productionAccept = acceptDate;
      this.statusUpdated = true;
    });
  }

  finish(position) {
    this.statusUpdated = false;
    const finishDate = moment().format('DD.MM.YYYY HH:mm:ss');

    this.orderService.productionFinish({ id: position.id }).subscribe(() => {
      position.productionFinish = finishDate;
      this.statusUpdated = true;
    });
  }

  unload(position) {
    this.statusUpdated = false;
    const unloadDate = moment().format('DD.MM.YYYY HH:mm:ss');

    this.orderService.productionUnload({ id: position.id }).subscribe(() => {
      position.productionUnload = unloadDate;
      if (this.order.positions.every(orderPosition => orderPosition.productionUnload)) {
        this.orderService.update<Order>(<Order>{ id: this.order.id, status: OrderStatus.COMPLETED }).subscribe(() => {
        });
      }
      this.statusUpdated = true;
    });
  }

  sos(order) {
    const message = window.prompt('Что случилось?');

    if (!message || message.trim() === '') {
      return false;
    }

    this.orderService.productionSos({ id: order.id, message }).subscribe(() => {
      this.loadOrder();
    });
  }
}
