import {Injectable, Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'validDate'
})

@Injectable()
export class ValidDatePipe implements PipeTransform {
  transform(date: string): any {
    return moment(date).isValid();
  }
}
