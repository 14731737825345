import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from '../../_models/user.model';

@Component({
  selector: 'e2b-user-label',
  templateUrl: './user-label.component.html',
  encapsulation: ViewEncapsulation.None
})
export class UserLabelComponent implements OnInit {

  @Input()
  public user: User;

  constructor() {
  }

  ngOnInit() {
  }

}
