import { Component, Input } from '@angular/core';
import { SmsStatus } from '../../_models/enums/sms-status';

@Component({
  selector: 'e2b-sms-status',
  templateUrl: './sms-status.component.html',
  styleUrls: ['./sms-status.component.less']
})
export class SmsStatusComponent {

  @Input()
  status: SmsStatus;

  statusInfo = {
    [SmsStatus.ENQUEUED]: 'В очереди на отправление',
    [SmsStatus.ACCEPTED]: 'Принято к отправлению',
    [SmsStatus.DELIVERED]: 'Доставлено',
    [SmsStatus.NON_DELIVERED]: 'Отклонено',
    [SmsStatus.SCHEDULED]: 'Запланировано',
    [SmsStatus.CANCELED]: 'Отменено',
    [SmsStatus.WAIT]: 'Ожидает отправки',
    [SmsStatus.OVERDUE]: 'Просрочено',
  };

  constructor() {
  }

  get SmsStatus() {
    return SmsStatus;
  }

}
