import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[focus]'
})
export class FocusDirective implements OnInit {

  setFocus: boolean;

  constructor(@Inject(ElementRef) private element: ElementRef) {
  }

  @Input()
  set focus(focus) {
    this.setFocus = focus;
    this.ngOnInit();
  }

  ngOnInit() {
    if (this.setFocus) {
      this.element.nativeElement.focus();
    }
  }
}
