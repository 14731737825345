<div id="pageHeader" class="page-header" style="width: 100%; padding: 0 !important;margin: 0 !important;">
  <div class="page-header-wrapper" style="padding-bottom: 10px;">
    <div class="ui stackable grid">
      <div class="ui ten wide column">
        <e2b-main-menu></e2b-main-menu>
      </div>
      <div class="ui two wide right floated column">
        <div class="ui secondary menu">
          <div class="item">
            <e2b-auth-user></e2b-auth-user>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
