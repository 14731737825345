<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
  </div>
  <div class="ui six wide column" *ngIf="loaded">
    <div class="ui form">
      <table class="ui very very basic table">
        <tbody>
        <tr>
          <td>
            <h4 class="header">Название</h4>
          </td>
          <td>
            <div class="field">
              <input type="text" [(ngModel)]="step.name" (ngModelChange)="save()" [ngModelOptions]="{updateOn: 'blur'}">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Описание</h4>
          </td>
          <td>
            <div class="field">
              <input type="text" [(ngModel)]="step.description" (ngModelChange)="save()" [ngModelOptions]="{updateOn: 'blur'}">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Процент</h4>
          </td>
          <td>
            <div class="field">
              <input type="number" step="1" max="100" [(ngModel)]="step.percent" (ngModelChange)="save()" [ngModelOptions]="{updateOn: 'blur'}">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Фото</h4>
          </td>
          <td>
            <div class="field">
              <sui-checkbox [(ngModel)]="step.photoRequired" (ngModelChange)="save()"></sui-checkbox>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Поле сделки</h4>
          </td>
          <td>
            <div class="field">
              <e2b-deal-field-dropdown [dealFields]="fields" [selected]="step.field" [event]="selectStepField()"></e2b-deal-field-dropdown>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Компрессия фото, %</h4>
          </td>
          <td>
            <div class="field">
              <input type="number" step="1" max="100" [(ngModel)]="step.compression" (ngModelChange)="save()" [ngModelOptions]="{updateOn: 'blur'}">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Разрешение фото</h4>
          </td>
          <td>
            <div class="field">
              <input type="number" step="1" max="2440" [(ngModel)]="step.resolution" (ngModelChange)="save()" [ngModelOptions]="{updateOn: 'blur'}">
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <h4 class="header">Проблемы</h4>
          </td>
          <td>
            <div class="field">
              <e2b-entity-multi-dropdown [selected]="step.problemFields" [entities]="problemFields" [event]="selectStepProblem()" [name]="'Поля проблем'"></e2b-entity-multi-dropdown>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
