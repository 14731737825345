import { EventEmitter } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationTypeEnum } from '../../_models/enums/notification-type.enum';
import { ProjectComment } from '../../_models/project-comment.model';
import { CommentService } from '../../_services/comment.service';
import { NotificationUnreadedResultInterface } from '../../_services/notification.service';
import { NotificationService } from '../../_services/notification.service';
import { interval } from 'rxjs';
import { ProjectCommentService } from '../../_services/project-comment.service';
import { UserActivityService } from '../../_services/user-activity.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'e2b-activity-popup',
  templateUrl: './activity-popup.component.html',
  styleUrls: ['./activity-popup.component.less',
    '../../../assets/design/semantic-ui/components/popup.min.css',
    '../../../assets/design/semantic-ui/components/comment.min.css',
  ],
  encapsulation: ViewEncapsulation.None
})

export class ActivityPopupComponent implements OnInit {
  loaded = false;

  @Input()
  activity: { id: number, name: string, iconClass: string, labelColor: string, iconHighlightColor: string, notificationTypes: NotificationTypeEnum[] };

  notifications: NotificationUnreadedResultInterface[] = [];
  notificationsCounter: number;

  replyToNotification: any;
  replySave = new EventEmitter<Comment>();

  reloadInterval: Subscription;

  constructor(private readonly notificationService: NotificationService,
              private readonly commentService: CommentService,
              private readonly projectCommentService: ProjectCommentService,
              private readonly router: Router,
              private readonly userActivityService: UserActivityService,
              private readonly userService: UserService) {
  }

  ngOnInit() {
    this.replySave.subscribe(comment => {
      this.read(this.replyToNotification);
      comment.user = this.userService.current();
      if (comment.deal) {
        this.commentService.create<Comment>(comment).subscribe(() => {
          this.replyToNotification = null;
        });
      }

      if (comment.project) {
        this.projectCommentService.create<ProjectComment>(comment).subscribe(() => {
          this.replyToNotification = null;
        });
      }
    });


    setTimeout(() => {
      this.loadNotifications();
    }, 3000);

    this.initReloadInterval();

    this.userActivityService.userBecomeActive.subscribe(() => {
      this.reloadInterval.unsubscribe();
      this.loadNotifications();
      this.initReloadInterval();
    });
  }

  get NotificationTypeEnum() {
    return NotificationTypeEnum;
  }

  initReloadInterval() {
    this.reloadInterval = interval(300000).subscribe(() => {
      if (this.userActivityService.inactiveMinutes < 3) {
        this.loadNotifications();
      }
    });
  }

  loadNotifications() {
    this.loaded = false;
    this.notificationService.unreaded({ type: this.activity.notificationTypes }).subscribe(notifications => {
      this.notifications = notifications.data;
      this.notificationsCounter = notifications.count;
      this.loaded = true;
    });
  }

  read(notification: NotificationUnreadedResultInterface) {
    this.notificationService.update({ id: notification.readId }).subscribe(() => {
      this.notifications.splice(this.notifications.indexOf(notification), 1);
      this.notificationsCounter -= notification.count;
    });
  }

  readAll() {
    this.notifications.map(notification => this.read(notification));
  }

  identify(index, obj) {
    return obj ? obj.id : undefined;
  }

  showMore(activity: { id: number }) {
    localStorage.setItem('e2b.activity.typeFilters', JSON.stringify([activity.id]));
    localStorage.removeItem('e2b.activity.userIdFilter');
    this.router.navigate(['/activity']);
  }

  showReply(notification) {
    if (this.replyToNotification === notification) {
      this.replyToNotification = null;
    } else {
      this.replyToNotification = notification;
    }
  }
}
