<sui-dimmer class="page inverted" [(isDimmed)]="!loaded" [isClickable]="false">
  <div class="ui text loader">Загрузка...</div>
</sui-dimmer>
<div *ngIf="loaded" class="ui segment">
  <div class="ui stackable grid">
    <div class="ui sixteen wide column">
      <h2 class="ui header" style="display: inline !important;">
        <a *ngIf="order.megaplanId" target="_blank"
           href="https://megaplan.olissys.com/deals/{{order.megaplanId}}/card/"><i
          class="ui cursor paper plane large outline green icon"
          style="vertical-align: top; margin-top:12px;font-size: 24px;"></i></a>
        <span *ngIf="order.filial && order.filial.name"
              class="ui large label {{order.filial.color.replace('#', 'X')}}">{{order.filial ? order.filial.name : ''}}
        </span>
        {{order.name}} для клиента {{order.client.name}}
      </h2>
      <div class="ui right floated buttons">
        <button class="ui green button" *ngIf="order.status === OrderStatus.NEW"
                [ngClass]="{'loading': !statusUpdated}" (click)="accept()">Принять
        </button>
        <button (click)="sos(order)" *ngIf="order.currentStatus.id !== 21 && order.status !== OrderStatus.COMPLETED"
                [ngClass]="{'loading': !statusUpdated}"
                class="ui red button">SOS
        </button>
      </div>

      <br/>
      <br/>
    </div>
  </div>
  <div class="ui stackable grid">
    <div class="ui four wide column">
      <h4 class="ui dividing header mb-0">Информация</h4>
      <table class="ui very basic selectable celled table mt-0">
        <tbody>
        <tr *ngIf="order.client">
          <td>
            <span class="fw-bold">Клиент</span>
          </td>
          <td>
            {{order.client.name}}<br/>
            <a href="mailto:{{order.client.email}}">{{order.client.email}}</a><br/>
          </td>
        </tr>
        <tr *ngIf="order.manager">
          <td>
            <span class="fw-bold">Менеджер</span>
          </td>
          <td>
            <span>
                {{order.manager.name}}<br/>
                <a href="mailto:{{order.manager.email}}">{{order.manager.email}}</a><br/>
            </span>
          </td>
        </tr>
        <tr *ngIf="order.payForm">
          <td>
            <span class="fw-bold">Форма оплаты</span>
          </td>
          <td>
            {{order.payForm}}
          </td>
        </tr>
        <tr *ngIf="order.organization">
          <td>
            <span class="fw-bold">Организация</span>
          </td>
          <td>
            <span [innerText]="order.organization.name"></span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="ui twelve wide column">
      <h4 class="ui dividing header mb-0">Заказ</h4>
      <table class="ui very basic selectable celled table w-100 mt-0">
        <thead>
        <tr>
          <th>
            <div class="head">Товар или услуга</div>
          </th>
          <th>
            <div class="head">Готовность</div>
          </th>
          <th>
            <div class="head">Статус</div>
          </th>
          <th>
            <div class="head">Кол-во</div>
          </th>
          <th>
            <div class="head">Бонус</div>
          </th>
          <th>
            <div class="head">Действия</div>
          </th>
        </tr>
        </thead>
        <tbody *ngFor="let position of order.positions">
        <tr>
          <td>
            <a class="cursor" (click)="currentPosition = position">{{position.position.name}}</a><br/>
            <span class="greytext" *ngIf="position.position.type === 1">Корпусная</span>
            <span class="greytext" *ngIf="position.position.type === 2">Мягкая</span>
            <span class="greytext" *ngIf="position.position.type === 3">Матрас</span>
            <span class="greytext" *ngIf="position.position.type === 4">Кастомизация</span>
          </td>
          <td>
            {{position.readyDate|date:'dd.MM.yyyy'}}
          </td>
          <td>
            <div *ngIf="position.productionAccept !== ''">
              Принят: <span class="fw-bold">{{position.productionAccept}}</span>
            </div>
            <div *ngIf="position.productionFinish">
              Завершен: <span class="fw-bold">{{position.productionFinish}}</span>
            </div>
            <div *ngIf="position.productionUnloaded">
              Отгружен: <span class="fw-bold">{{position.productionUnloaded}}</span>
            </div>
          </td>
          <td>{{position.count}}</td>
          <td><span class="fw-bold">{{position.priceP1 ? (position.priceP1|number) : 0}}</span> руб.</td>
          <td>
            <button class="ui green button" [ngClass]="{'loading': !statusUpdated}"
                    *ngIf="position.productionAccept !== '' && position.productionFinish === ''"
                    (click)="finish(position)">Завершить
            </button>
            <button [ngClass]="{'loading': !statusUpdated}" class="ui blue button"
                    *ngIf="position.productionFinish !== '' && position.productionUnloaded === ''"
                    (click)="unload(position)">Отгрузить
            </button>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="4" style="text-align: right;padding-right: 30px;">
            <span class="fw-bold">Итого бонус:&nbsp;&nbsp;&nbsp;&nbsp;{{order.priceP1sum|number}} руб.</span>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div class="ui doubling equal width stackable grid">
    <div class="column" *ngIf="order.isProduction">
      <div>
        <h4 class="ui dividing header">Проектирование</h4>
        <table class="ui very basic selectable celled table">
          <tr *ngIf="order.projectConstruction.length > 0">
            <td>
              <span class="fw-bold">Конструкторы</span>
            </td>
            <td>
            <span *ngFor="let user of order.projectConstruction">
              <span>
                <a href="{{user.url}}" target="_blank">{{user.name}}</a><br/>
                <a href="mailto:{{user.email}}">{{user.email}}</a><br/>
              </span>
            </span>
            </td>
          </tr>
          <tr *ngIf="order.projectDifficulty">
            <td>
              <span class="fw-bold">Сложность</span>
            </td>
            <td>
              {{order.projectDifficulty}}
            </td>
          </tr>
          <tr *ngIf="order.projectPrice">
            <td>
              <span class="fw-bold">Рассчетная стоимость (Бонус)</span>
            </td>
            <td>
              {{order.projectPrice}}
            </td>
          </tr>
          <tr *ngIf="order.projectSquare && order.isProduction">
            <td>
              <span class="fw-bold">Квадратура</span>
            </td>
            <td>
              {{order.projectSquare}}
            </td>
          </tr>
          <tr *ngIf="order.projectSpecifications.length > 0" class="notprint">
            <td>
              <span class="fw-bold">Спецификации и чертежи</span>
            </td>
            <td>
              <div class="d-flex flex-wrap">
                <div *ngFor="let file of order.projectSpecifications" class="attach">
                  <e2b-file [file]="file"></e2b-file>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="column">
      <h4 class="ui dividing header">Спецификации</h4>
      <div *ngIf="!currentPosition">Выберите позицию сделки</div>
      <h3 *ngIf="currentPosition">{{currentPosition.position.name}}</h3>
      <table class="ui very basic selectable celled table" *ngIf="currentPosition">
        <tr *ngIf="currentPosition.values.indIzmeneniya">
          <td>
            <span class="fw-bold">Индивидуальные изменения</span>
          </td>
          <td>
            {{currentPosition.values.indIzmeneniya}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values['indIzmeneniya-']">
          <td>
            <span class="fw-bold">Индивидуальные изменения</span>
          </td>
          <td>
            {{currentPosition.values['indIzmeneniya-']}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.materialKarkasa">
          <td>
            <span class="fw-bold">Материал каркаса</span>
          </td>
          <td>
            {{currentPosition.values.materialKarkasa.name}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.materialFasada">
          <td>
            <span class="fw-bold">Материал фасада</span>
          </td>
          <td>
            {{currentPosition.values.materialFasada.name}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.materialDivana">
          <td>
            <span class="fw-bold">Материал дивана</span>
          </td>
          <td>
            {{currentPosition.values.materialDivana.name}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.razmerDivana">
          <td>
            <span class="fw-bold">Размер дивана</span>
          </td>
          <td>
            {{currentPosition.values.razmerDivana}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.cvetMaterialaMyagkaya">
          <td>
            <span class="fw-bold">Цвет материала</span>
          </td>
          <td>
            {{currentPosition.values.cvetMaterialaMyagkaya}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.postavshchikTkani">
          <td>
            <span class="fw-bold">Поставщик ткани</span>
          </td>
          <td>
            {{currentPosition.values.postavshchikTkani}}
          </td>
        </tr>
        <tr *ngIf="order.specificationModelMatress && order.isMattressProduction">
          <td>
            <span class="fw-bold">Модель матраса</span>
          </td>
          <td>
            {{order.specificationModelMatress}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.razmerMatrasa">
          <td>
            <span class="fw-bold">Размер матраса</span>
          </td>
          <td>
            {{currentPosition.values.razmerMatrasa}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.tipKaretnoyStyazhki">
          <td>
            <span class="fw-bold">Тип каретной стяжки</span>
          </td>
          <td>
            {{currentPosition.values.tipKaretnoyStyazhki}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.razmerKaretnoyStyazhki">
          <td>
            <span class="fw-bold">Размер каретной стяжки</span>
          </td>
          <td>
            {{currentPosition.values.razmerKaretnoyStyazhki}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.materialKaretnoyStyazhki">
          <td>
            <span class="fw-bold">Материал каретной стяжки</span>
          </td>
          <td>
            {{currentPosition.values.materialKaretnoyStyazhki}}
          </td>
        </tr>
        <tr *ngIf="currentPosition.values.cvetMaterialaKastomizaciya">
          <td>
            <span class="fw-bold">Цвет материала (кастомизация)</span>
          </td>
          <td>
            {{currentPosition.values.cvetMaterialaKastomizaciya}}
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="ui stackable grid w-100">
    <div class="ui eight wide column">
      <h2>Комментарии</h2>
      <e2b-comment [comments]="comments"
                   [setEntity]="{id: order.deal.id, commentType: CommentType.deal}"></e2b-comment>
    </div>
  </div>
</div>
