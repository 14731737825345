<div>
  <h3 class="header">Список заказов</h3>

  <div class="d-flex">
    <button class="ui green button mr-2" (click)="setAddOrderMode()">
      <i class="plus icon"></i>
      Добавить заказ
    </button>

    <button class="ui blue button"
            [class.loading]="generatingOrders"
            (click)="generateOrders()">
      Автоматическое создание заказов
    </button>
  </div>

</div>

<div class="orders-wrapper">
  <div class="ui card"
       *ngFor="let order of orderModeService.orders | orderByColumn:'date:date'"
       [ngStyle]="{'border-color': orderModeService.getOrderStyle(order.type).color + '80'}">
    <div class="content">
      <div class="right floated meta">
        <e2b-order-label [orderType]="order.type"></e2b-order-label>
      </div>
      <div class="header">
        <a (click)="showOrderCard(order)">Заказ №{{order.id}}</a>
      </div>
      <div class="meta">
        <span class="category">{{order.created | date:'dd.MM.yyyy, HH:mm'}}</span>
      </div>
    </div>
    <div class="content">
      <div class="description">
        <div>
          <span class="fw-bold">Дата исполнения: </span>
          <ng-container *ngIf="order.date">
            <span *ngIf="order.type === OrderType.PRODUCTION">{{order.date | date:'dd.MM.yyyy'}}</span>
            <span *ngIf="order.type !== OrderType.PRODUCTION">{{order.date | date:'dd.MM.yyyy HH:mm'}}</span>
          </ng-container>
          <span *ngIf="!order.date">не указана</span>
        </div>
        <div class="mt-3">
          <span class="fw-bold">Ответственный: </span>
          {{order.responsible ? (order.responsible.name) : 'не указан'}}
        </div>
      </div>
    </div>
    <div class="extra content">
      <div class="right floated">
        <span *ngIf="order.status === OrderStatus.NEW">Новый</span>
        <span *ngIf="order.status === OrderStatus.SENT">Отправлен в производство</span>
        <span *ngIf="order.status === OrderStatus.IN_PROCESS">
          В работе <i class="clock outline icon"></i>
        </span>
        <span *ngIf="order.status === OrderStatus.COMPLETED" class="color-green">
          Исполнен <i class="green check icon"></i>
        </span>
        <span *ngIf="order.status === OrderStatus.REJECTED" class="color-red">
          Отказан <i class="red exclamation circle icon"></i>
        </span>
      </div>
    </div>
  </div>
  <h4 class="header mt-2" *ngIf="!orderModeService.orders.length">нет заказов</h4>
</div>

