import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Todo } from '../_models/todo.model';
import { BaseService } from './base.service';

export interface TodoFindByTypeResult {
  id?: number;
  name?: string;
  period?: string;
  todos: {
    id: number,
    name: string,
    expired: number,
    day: {
      plan: number,
      planned: number,
      closed: number,
    },
    week: {
      plan: number,
      planned: number,
      closed: number,
    },
    month: {
      plan: number,
      planned: number,
      closed: number,
    },
  }[];
}

@Injectable()
export class TodoService extends BaseService {
  baseUrl = '/todo';

  assign(todo: Todo) {
    return this.http.put(this.baseUrl + '/assign/' + todo.id, { id: todo.id }, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  findByDealId<T>(id: number): Observable<T[]> {
    return this.http.get<T[]>('/deal/todos/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  findByType(managerId: string, date: string): Observable<TodoFindByTypeResult> {
    return this.http.get<TodoFindByTypeResult>(
      '/todo/type/' + managerId,
      {
        params: {
          date: date
        },
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }

  findByUserId<T>(id: number, day: string): Observable<T[]> {
    return this.http.get<T[]>(
      '/user/todos/' + id,
      {
        params: {
          day: day
        },
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }

  finish<T>(todo: Todo): Observable<T> {
    return this.http.put<T>(this.baseUrl + '/finish/' + todo.id, todo, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  overdue<T>(id: number): Observable<T[]> {
    return this.http.get<T[]>('/todo/overdue/' + id, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  todoDetail(type: 'PLANNED' | 'CLOSED' | 'EXPIRED', period: 'day' | 'week' | 'month', todoTypeId: number, date: string, managerId: number): Observable<any[]> {
    return this.http.get<any[]>(
      '/todo/detail/' + managerId,
      {
        params: {
          date,
          type,
          period,
          todoTypeId: todoTypeId.toString()
        },
        withCredentials: true,
        headers: {
          'Authorization': localStorage.getItem('e2b.user.token')
        }
      });
  }
}
