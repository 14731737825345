<div class="ui form">
  <ng-container *ngFor="let orderDealPositionValue of orderDealPositionValues | orderByColumn: 'orderField.sort:object:number'">
    <div class="inline fields">
      <div class="five wide field">
        <label class="fw-bold">{{orderDealPositionValue.orderField.name}}</label>
      </div>
      <div [ngClass]="'eleven wide field' + (orderDealPositionValue.orderField.type === 'STEP' ? ' order-field-value' : '')">
        <div *ngIf="orderDealPositionValue.orderField.type === 'STEP'">
          <div>{{orderDealPositionValue.value ? orderDealPositionValue.value.date : 'Не заполнено'}}</div>
          <div *ngIf="orderDealPositionValue.value" class="d-flex flex-wrap">
            <div class="attach" *ngFor="let file of orderDealPositionValue.value.files | orderByColumn:'-date:date'">
              <e2b-file [file]="file"></e2b-file>
            </div>
          </div>
        </div>
        <div *ngIf="orderDealPositionValue.orderField.type === 'FILE'">
          <div *ngIf="orderDealPositionValue.value" class="d-flex flex-wrap">
            <div class="attach" *ngFor="let file of orderDealPositionValue.value | orderByColumn:'-date:date'">
              <i class="ui delete red cursor icon delete-file-icon" (click)="deleteFile(orderDealPositionValue, file)"></i>
              <e2b-file [file]="file"></e2b-file>
            </div>
          </div>
          <div class="ui input">
            <input type="file" id="filePosition{{orderDealPositionValue.orderField.id}}" name="filePosition" #fileFieldPositionForm multiple
                   (change)="orderDealPositionValue.files = fileFieldPositionForm.files; onPositionValueChange(orderDealPositionValue);">
            <label for="filePosition{{orderDealPositionValue.orderField.id}}">
              <span>Прикрепить файл(ы)</span>
            </label>
          </div>
        </div>
        <div *ngIf="orderDealPositionValue.orderField.type === 'DATETIME'">
          <div>{{orderDealPositionValue.value ? orderDealPositionValue.value : 'Не заполнено'}}</div>
        </div>
        <div *ngIf="orderDealPositionValue.orderField.type === 'NUMBER'">
          <input type="number"
                 [(ngModel)]="orderDealPositionValue.value"
                 [ngModelOptions]="{updateOn: 'blur'}"
                 (ngModelChange)="onPositionValueChange(orderDealPositionValue)">
        </div>
        <div *ngIf="orderDealPositionValue.orderField.type === 'TEXT'" class="w-100">
          <textarea [(ngModel)]="orderDealPositionValue.value"
                    [ngModelOptions]="{updateOn: 'blur'}"
                    (ngModelChange)="onPositionValueChange(orderDealPositionValue)"
                    rows="3">
          </textarea>
        </div>
        <div *ngIf="orderDealPositionValue.orderField.type === 'PROBLEM_TYPE'" class="w-100">
          <ng-template let-option #sourceOptionTemplate>
            <div [ngClass]="{'groupChildren': option.children, 'groupParent': !option.children}">
              {{option.name}}
            </div>
          </ng-template>
          <sui-select class="fluid selection"
                      placeholder="Источник"
                      [(ngModel)]="orderDealPositionValue.value"
                      (ngModelChange)="onPositionValueChange(orderDealPositionValue)"
                      [options]="orderDealPositionValue.orderField.options"
                      labelField="name"
                      [optionTemplate]="sourceOptionTemplate"
                      #selectSource>
            <sui-select-option *ngFor="let source of selectSource.filteredOptions"
                               [value]="source">
            </sui-select-option>
          </sui-select>
        </div>
      </div>
    </div>
  </ng-container>
</div>

