import { BaseModel } from './base.model';
import { Deal } from './deal.model';
import { NotificationTypeEnum } from './enums/notification-type.enum';
import { ProjectChecklist } from './project-checklist.model';
import { Project } from './project.model';
import { Task } from './task.model';
import { User } from './user.model';

export class Reminder extends BaseModel {
  created: Date;
  date: Date;
  job_id: string;
  notification_type: NotificationTypeEnum;
  deleted: boolean;
  deal?: Deal;
  task?: Task;
  project?: Project;
  checklist?: ProjectChecklist;
  user: User;
}
