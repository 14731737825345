<div class="ui footer container mb-1">
  <div class="ui grid">
    <div class="ui twelve wide column">
      <p>
        <span>Копирайт &copy; 2017-2020 </span>
        <span>OLISSYS</span>.
        <span>Все права защищены. </span>
        <span>
          <a href="#">Условия использования</a>
          <span> | </span>
          <a href="#">Политика конфиденциальности</a>
        </span>
      </p>
    </div>
    <div class="ui four wide column">
      <p>
        <a target="_blank" href="https://e2b.zaoblako.ru">
          Платформа E2B
        </a>
      </p>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
