import { Observable } from 'rxjs';
import { TaskFile } from '../_models/task-file.model';
import { BaseService } from './base.service';

export class TaskFileService extends BaseService {
  baseUrl = '/brs/taskFile';

  template() {
    return this.http.get<any>(this.baseUrl + '/template', {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  generate(taskId: number, templateId: number): Observable<TaskFile> {
    return this.http.get<TaskFile>(this.baseUrl + '/generate/' + templateId + '/' + taskId, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }
}
