<div class="ui fluid container" style="padding: 0 !important;margin: 0 !important;">
  <e2b-app-header></e2b-app-header>
  <ng-progress [color]="'whitesmoke'"></ng-progress>
  <div class="crumbs">
    <div class="ui stackable grid align-items-center" style="margin: 0;padding: 0;">
      <div class="ui six wide column" style="margin:0;padding:1px 0 3px 0;line-height: 40px;">
        <breadcrumb></breadcrumb>
      </div>
      <div class="ui ten wide column" style="height:100%;vertical-align: bottom !important;">
        <e2b-action [actions]="actions" [events]="events"></e2b-action>
      </div>
    </div>
  </div>
  <e2b-error></e2b-error>
  <div class="main container-fluid">
    <div class="ui stackable grid" style="margin: 0;">
      <div class="ui sixteen wide column" style="padding-top: 30px;">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <e2b-app-footer></e2b-app-footer>
  <e2b-app-bottom-bar></e2b-app-bottom-bar>
</div>
