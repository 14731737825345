<div class="e2b-actions">
  <div class="actionBlock" *ngFor="let action of actions; last as isLast" [ngClass]="{'mr-10px': !isLast && actions && actions.length > 0}">
    <a [class]="'e2b-action ' + action.class" [routerLink]=[action.link]>
      <i *ngIf="action.icon" [class]="'ui icon cursor ' + action.class + ' ' + action.icon"></i>
      <span [innerText]="action.name"></span>
    </a>
  </div>
  <div class="actionBlock" *ngFor="let event of events; last as isLast; first as isFirst" [ngClass]="{'mr-10px': !isLast && events && events.length > 0, 'ml-10px': isFirst}">
    <a [class]="'e2b-action ' + event.class" (click)="event.event.emit()">
      <i *ngIf="event.icon" [class]="'ui icon cursor ' + event.class + ' ' + event.icon"></i>
      <span [innerText]="event.name"></span>
    </a>
  </div>
</div>
