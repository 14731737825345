import { BaseModel } from './base.model';

export class PositionMaterial extends BaseModel {
  created: Date;
  name: string;
  article: string;
  price16: number;
  price25: number;
  price16k: number;
  price25k: number;
  priceFacade: number;
  priceFrame: number;
}
