import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Deal } from '../../_models/deal.model';
import { OrderModeService } from './order-mode.service';

@Component({
  selector: 'e2b-deal-order',
  templateUrl: './order.component.html',
  styles: [`
    .orders-container {
      min-height: 800px;
    }`
  ]
})
export class OrderComponent implements OnInit {

  @Input()
  deal: Deal;

  constructor(public orderModeService: OrderModeService) {
  }

  ngOnInit() {
    this.orderModeService.setDeal(this.deal);
  }

}
