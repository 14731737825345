import { Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { Person } from '../../../_models/person.model';

@Component({
  selector: 'e2b-person-dropdown',
  templateUrl: './person.dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})

export class PersonDropdownComponent {
  @Input()
  public persons: Person[];
  @Input()
  public event: EventEmitter<Person>;
  @Input()
  public selectedPerson: Person;

  save() {
    this.event.emit(this.selectedPerson);
  }
}
