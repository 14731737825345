import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { WhatsAppStatus } from '../../_models/enums/whatsapp-status.enum';

@Component({
  selector: 'e2b-whatsapp-status',
  templateUrl: './whatsapp-status.component.html',
  styleUrls: ['./whatsapp-status.component.less']
})
export class WhatsappStatusComponent {

  @Input()
  status: WhatsAppStatus;

  statusInfo = {
    [WhatsAppStatus.SENT]: 'Отправлено',
    [WhatsAppStatus.DELIVERED]: 'Доставлено',
    [WhatsAppStatus.READ]: 'Прочитано',
    [WhatsAppStatus.NOT_INSTALLED]: 'У клиента нет WhatsApp аккаунта с этим номером телефона',
    [WhatsAppStatus.TOO_LONG_MESSAGE]: 'Слишком длинное сообщение',
    [WhatsAppStatus.TOO_BIG_FILE]: 'Слишком большой файл',
    [WhatsAppStatus.SPAM]: 'Сообщение помечено как спам',
    [WhatsAppStatus.EXCEEDED_LIMIT]: 'Превышен лимит сообщений',
    [WhatsAppStatus.ERROR]: 'Ошибка',
  };

  get WhatsAppStatus() {
    return WhatsAppStatus;
  }
}
