import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { ActionService } from '../../../action/action.service';
import { DealStep } from '../../../_models/deal-step.model';
import { DealStepService } from '../../../_services/deal-step.service';
import { DealFieldService } from '../../../_services/deal-field.service';
import { DealField } from '../../../_models/deal-field.model';
import { E2BAppViewType } from '../../../_models/enums/app-view-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from '../../../error/error.service';
import { UserService } from '../../../_services/user.service';
import { OrderFieldService } from '../../../_services/order-field.service';
import { OrderType } from '../../../_models/enums/order-type.enum';

@Component({
  selector: 'e2b-deal-step-edit',
  templateUrl: './deal-step-edit.component.html',
  styleUrls: [
    '../../../../assets/design/semantic-ui/components/header.min.css',
    '../../../../assets/design/semantic-ui/components/form.min.css',
    '../../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../../assets/design/semantic-ui/components/checkbox.min.css',
    '../../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class DealStepEditComponent implements OnInit {

  appViews = [{
    name: E2BAppViewType.DELIVERYMAN,
    translation: 'Доставщик'
  }, {
    name: E2BAppViewType.MEASURER,
    translation: 'Замерщик'
  }, {
    name: E2BAppViewType.MOUNTER,
    translation: 'Монтажник'
  }, {
    name: E2BAppViewType.RECLAMATION,
    translation: 'Рекламации'
  }];

  dealStep: DealStep = new DealStep();

  fields: DealField[] = [];

  problemFields: DealField[] = [];

  loaded = false;

  id: number;

  constructor(private actionService: ActionService,
              private errorService: ErrorService,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private dealFieldService: DealFieldService,
              private orderFieldService: OrderFieldService,
              private dealStepService: DealStepService) {
  }

  ngOnInit() {
    this.id = parseInt(this.route.snapshot.params.id, 0);

    if (!this.userService.checkPermissions(['deal-step', '/'], ['write'])) {
      this.router.navigateByUrl('/catalog/deal-step/list').catch(console.error);
    }

    this.load().catch(console.error);
  }

  trackByFn(index, item) {
    return item.id;
  }

  async load() {
    this.loaded = false;

    this.fields = await this.orderFieldService.list<DealField>({type: [OrderType.MOUNTING, OrderType.RECLAMATION, OrderType.DELIVERY, OrderType.MEASUREMENT]}).toPromise();

    this.problemFields = await this.dealFieldService.list<DealField>({model: ['CRM.FIELD.PROBLEM']}).toPromise();

    const step = await this.dealStepService.findById<DealStep>(this.id).toPromise();

    if (step.field && step.field.id) {
      this.fields.map(f => {
        if (f.id === step.field.id) {
          step.field = f;
        }
      });
    }
    const problemFields = [];
    if (step.problemFields && step.problemFields.length > 0) {
      step.problemFields.map(sp => {
        this.problemFields.map(f => {
          if (sp.id === f.id) {
            sp = f;
            problemFields.push(f);
          }
        });
      });
    }

    step.problemFields = problemFields;

    this.dealStep = step;

    this.loaded = true;
  }

  selectStepField() {
    const event: EventEmitter<DealField> = new EventEmitter<DealField>();
    event.subscribe(field => {
      this.dealStep.field = field;
      this.save();
    });
    return event;
  }

  save() {
    if (this.dealStep.resolution > 2440) {
      this.errorService.addError.emit({
        title: 'Ошибка',
        message: 'Разрешение фото не может быть больше 2440 пикселей'
      });
      return false;
    }
    if (this.dealStep.compression > 100) {
      this.errorService.addError.emit({
        title: 'Ошибка',
        message: 'Компрессия фото не может быть больше 100%'
      });
      return false;
    }
    if (this.dealStep.percent > 100) {
      this.errorService.addError.emit({
        title: 'Ошибка',
        message: 'Процент выполнения не может быть больше 100%'
      });
      return false;
    }
    if (!this.dealStep.field) {
      this.errorService.addError.emit({
        title: 'Ошибка',
        message: 'Нужно указать поле'
      });
      return false;
    }


    this.dealStepService.update<DealStep>(this.dealStep).toPromise().then(() => {
    }).catch(err => {
      if (err.error && err.error.message.indexOf('duplicate key value violates unique constraint') > -1) {
        this.errorService.addError.emit({
          title: 'Ошибка',
          message: 'Это поле уже привязано к другому этапу'
        });
      }
    });
  }

  selectStepProblem() {
    const event: EventEmitter<DealField[]> = new EventEmitter<DealField[]>();
    event.subscribe(fields => {
      this.dealStep.problemFields = fields;
      this.save();
    });
    return event;
  }
}
