import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { RouterEventService } from '../_services/router-event.service';

interface IBreadcrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'breadcrumb',
  template: `<div class="ui breadcrumb" *ngIf="breadcrumbs.length > 1">
      <ng-container *ngFor="let breadcrumb of breadcrumbs; last as isLast">
        <a class="section" style="text-transform: uppercase;" *ngIf="!isLast" [routerLink]="[breadcrumb.url]">
          {{ breadcrumb.label }}
        </a>
        <div class="divider" *ngIf="!isLast"> /</div>
        <div class="section active" style="text-transform: uppercase;" *ngIf="isLast">
          {{ breadcrumb.label }}
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['../../assets/design/semantic-ui/components/breadcrumb.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnDestroy {

  public breadcrumbs: IBreadcrumb[];
  public name: string;
  private subscribe: Subscription;
  private routerSubscribe: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
              private routerEventService: RouterEventService) {

    this.breadcrumbs = [];

    this.subscribe = this.routerEventService.onRouterEvent.subscribe(() => {
      this.getBreadcrumbs();
    });

    this.routerSubscribe = routerEventService.onRouterEvent.pipe(
      map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })).pipe(filter((route) => {
        return route.outlet === 'primary';
      })).pipe(mergeMap((route) => route.data))
      .subscribe(event => {
        this.name = event['breadcrumb'];
      });
  }

  private getBreadcrumbs() {
    let currentRoute = this.activatedRoute.root;
    let url = '';
    this.breadcrumbs = [];
    const loop = () => {
      const childrenRoutes = currentRoute.children;
      let breadCrumbLabel = '';
      childrenRoutes.forEach(route => {
        currentRoute = route;
        if (route.outlet !== PRIMARY_OUTLET) {
          return;
        }
        const hasData = (route.routeConfig && route.routeConfig.data);
        const hasDynamicBreadcrumb = route.snapshot.params.hasOwnProperty('breadcrumb');
        if (hasData || hasDynamicBreadcrumb) {
          if (hasDynamicBreadcrumb) {
            breadCrumbLabel = route.snapshot.params['breadcrumb'].replace(/_/g, ' ');
          } else if (route.snapshot.data.hasOwnProperty('breadcrumb')) {
            breadCrumbLabel = route.snapshot.data['breadcrumb'];
          }
          const routeURL = route.snapshot.url.map(function (segment) {
            return segment.path;
          }).join('/');
          url += '/' + routeURL;
          if (routeURL.length === 0) {
            route.snapshot.params = {};
          }
          const breadcrumb = {
            label: breadCrumbLabel,
            params: route.snapshot.params,
            url: url
          };
          this.breadcrumbs.push(breadcrumb);
        }
      });
    };
    while (currentRoute.children.length > 0) {
      loop();
    }
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
    this.routerSubscribe.unsubscribe();
  }
}
