<div class="ui stackable grid">
  <div class="ui eight wide column">
    <h3>Рекламации</h3>
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui form">
        <table class="ui very basic selectable celled table">
          <thead>
          <tr>
            <th>
              <div class="head">Название</div>
            </th>
            <th>
              <div class="head">Стоимость</div>
            </th>
          </tr>
          </thead>
          <tbody *ngFor="let p of reclamations | orderByColumn:'name:string'">
          <tr>
            <td width="80%">
              <div class="field">
                <div class="ui input">
                  <input type="text" class="fw-bold" [(ngModel)]="p.name" (change)="save(p)">
                </div>
              </div>
            </td>
            <td>
              <div class="field">
                <div class="ui input">
                  <input type="text" [(ngModel)]="p.cost" (change)="save(p)">
                </div>
              </div>
            </td>
            <td>
              <i class="ui green add cursor icon" (click)="addTo(p)"></i>
              <i class="ui red remove cursor icon" (click)="remove(p)"></i>
            </td>
          </tr>
          <tr *ngFor="let c of p.childrens | orderByColumn:'name:string'">
            <td width="80%">
              <div class="field" style="margin-left: 30px;">
                <div class="ui input">
                  <input type="text" style="font-style: italic !important;" [(ngModel)]="c.name" (change)="save(c)">
                </div>
              </div>
            </td>
            <td>
              <div class="field">
                <div class="ui input">
                  <input type="text" [(ngModel)]="c.cost" (change)="save(c)">
                </div>
              </div>
            </td>
            <td>
              <i class="ui red remove cursor icon" (click)="remove(c, p)"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
