<div class="ui stackable grid">
  <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
    <div class="ui text loader">Загрузка...</div>
  </sui-dimmer>
  <div class="row">
    <div class="ui three wide column ui right border">
      <div class="ui vertical fluid secondary menu">
        <div class="header item">#ФИЛЬТР</div>
        <a class="ui item" (click)="clearFilter()" [ngClass]="{'active': !filter.currentStatus}">
          <div class="ui left floated padded text Xblack" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" (click)="clearFilter()">ВСЕ СДЕЛКИ</div>
          <div class="ui mini right floated text"></div>
          <div class="clearfix"></div>
        </a>
        <a *ngFor="let dealStatus of dealStatuses" class="ui item" (click)="applyStatusFilter(dealStatus)" [ngClass]="{'active': filter.currentStatus && filter.currentStatus.id === dealStatus.id}">
          <div class="ui left floated padded text {{dealStatus.color.replace('#','X')}}" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{dealStatus.name}}</div>
          <div class="ui mini right floated text"></div>
          <div class="clearfix"></div>
        </a>
      </div>
    </div>
    <div class="ui thirteen wide column" *ngIf="loaded">
      <div class="ui header fsz-18px text-center mb-3">
        <a (click)="prevWeek()">
          <i class="chevron left icon" style="cursor: pointer"></i>
        </a>
        <span class="fw-bold">{{startOfWeek.format('DD MMM YY')}} г.</span> &mdash;
        <span class="fw-bold">{{startOfWeek.clone().add(7, 'days').format('DD MMM YY')}} г.</span>
        <a (click)="nextWeek()">
          <i class="chevron right icon" style="cursor: pointer"></i>
        </a>
      </div>
      <table class="ui very basic column table" [ngClass]="{'seven': (data.mounting.dates | keyvalue).length > 5 || (data.production.dates | keyvalue).length > 5, 'five': (data.mounting.dates | keyvalue).length <= 5 && (data.production.dates | keyvalue).length <= 5}">
        <thead>
        <tr>
          <th *ngFor="let day of week" [ngClass]="{'red': (data.mounting.dates[day] ? (data.mounting.dates[day].total >= data.mounting.dates[day].max) || (data.production.dates[day] ? (data.production.dates[day].coefficient >= data.production.dates[day].maxCoefficient) : false) : false)}">
            <div class="head" *ngIf="notWeekend(day)">{{day | momentPipe:'DD.MM.YYYY':'DD MMM, dd'}}</div>
            <div class="head" *ngIf="!notWeekend(day)">{{day | momentPipe:'DD.MM.YYYY':'DD,dd'}}</div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td *ngFor="let day of week" [ngClass]="{'smaller': !data.mounting.dates[day]}">
            <table class="ui very basic table" *ngIf="data.production.dates[day] || notWeekend(day)">
              <tr>
                <td style="width: 70%;">
                  <span class="fw-bold">Производство</span>
                </td>
                <td style="width: 15%;">
                  <span class="fw-bold">{{data.production.dates[day] ? data.production.dates[day].coefficient : 0}}</span>
                </td>
                <td style="width: 15%;">
                  <span class="fw-bold">{{data.production.dates[day] ? data.production.dates[day].maxCoefficient : supplierSum()}}</span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td *ngFor="let day of week" [ngClass]="{'smaller': !data.mounting.dates[day]}">
            <table class="ui very basic table" *ngIf="data.mounting.dates[day] || notWeekend(day)">
              <tr>
                <td style="width: 70%;">
                  <span class="fw-bold">Монтажи</span>
                </td>
                <td style="width: 15%;">
                  <span class="fw-bold">{{data.mounting.dates[day] ? data.mounting.dates[day].total : 0}}</span>
                </td>
                <td style="width: 15%;">
                  <span class="fw-bold">{{ data.mounting.dates[day] ? data.mounting.dates[day].max : data.max.maxMounts}}</span>
                </td>
              </tr>
            </table>
            <div *ngIf="data.mounting.dates[day]">
              <div class="deal-card {{deal.currentStatus.color.replace('#','B')}}" *ngFor="let deal of data.mounting.dates[day].deals">
                <div class="d-flex justify-content-between">
                  <div class="deal-card-field fw-bold">
                    <a [routerLink]="['/deal/card/', deal.id]">{{deal.name | dealshort}}</a>
                  </div>
                  <div class="deal-card-field w-50 text-right" style="font-size: 14px;">
                    <span class="fw-bold">{{deal.totalSum | number}}</span>
                  </div>
                </div>
                <div class="deal-card-field">
                  <div e2b-filial-label [filial]="deal.filial"></div>
                </div>
                <div style="clear: both;"></div>
                <div class="deal-card-field">
                  <span>{{deal.manager.name}}</span>
                  <span *ngIf="!deal.manager">-</span>
                </div>
                <div style="clear: both;" class="deal-card-field">
                  <a [routerLink]="['/client/card', deal.client.id]">
                    <span>{{deal.client.name | surname}}</span>
                  </a>
                </div>
                <div class="deal-card-field">
                  {{deal.currentStatus.name}}
                </div>
                <div style="margin-top: 4px" *ngIf="!isEmptyObject(deal.properties)">
                  <div style="margin-right: 4px !important;float: left !important;" class="fw-bold" *ngIf="deal.properties.agt">
                    <a class="ui grey circular label">AGT</a>
                  </div>
                  <div style="margin-right: 4px !important;float: left !important;" class="fw-bold" *ngIf="deal.properties.alvic">
                    <a class="ui purple circular label">ALVIC</a>
                  </div>
                  <div style="margin-right: 4px !important;float: left !important;" class="fw-bold" *ngIf="deal.properties.smart">
                    <a class="ui brown circular label">SMART</a>
                  </div>
                  <div style="margin-right: 4px !important;float: left !important;" class="fw-bold" *ngIf="deal.properties.fil">
                    <a class="ui green circular label">ФИЛ</a>
                  </div>
                  <div style="margin-right: 4px !important;float: left !important;" class="fw-bold" *ngIf="deal.properties.custom">
                    <a class="ui blue circular label">КАСТ</a>
                  </div>
                </div>
                <div style="position: absolute; bottom: 6px; right: 6px;" class="fw-bold" *ngIf="deal.coefficient > 0">
                  <a class="ui orange circular label">{{deal.coefficient}}</a>
                </div>
                <div style="clear: both;"></div>
                <div class="ui flowing popup right hidden">
                  <p class="ui header" style="padding-top: 20px;">Позиции:</p>
                  <table class="ui very very basic table" *ngIf="deal.positions && deal.positions.length > 0">
                    <thead>
                    <tr>
                      <th style="width: 45%">
                        <div class="head">Позиция</div>
                      </th>
                      <th style="width: 16.7%">
                        <div class="head">Кол-во</div>
                      </th>
                      <th style="width: 16.7%">
                        <div class="head">Цена</div>
                      </th>
                      <th>
                        <div class="head">Сумма</div>
                      </th>
                    </tr>
                    </thead>
                    <tbody *ngFor="let item of deal.positions | orderByColumn:'id:number'">
                    <tr style="height: 58px">
                      <td>
                        {{item.name}}
                      </td>
                      <td>
                        {{item.count | number}}
                      </td>
                      <td style="width: 16.7%">
                        {{item.price | number}}
                      </td>
                      <td>{{item.discountPrice ? (((item.count * item.price) - item.discountPrice) | number) : ((item.count * item.price) | number)}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colspan="4" class="text-right">
                        Итого: <b>{{deal.positions && deal.positions.length > 0 ? (deal.totalSum | number) : 0}}</b> руб.
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngFor="let supplier of data.production.suppliers">
          <td *ngFor="let day of week" [ngClass]="{'smaller': !data.production.dates[day]}">
            <table class="ui very basic table" *ngIf="supplier.dates[day] || notWeekend(day)">
              <tr>
                <td style="width: 70%;">
                  {{supplier.name}}
                </td>
                <td style="width: 15%;">
                  <span class="fw-bold">{{supplier.dates[day] ? supplier.dates[day].coefficient : 0}}</span>
                </td>
                <td style="width: 15%;">
                  <span class="fw-bold">{{ supplier.dates[day] ? supplier.dates[day].maxCoefficient : data.max.suppliers[supplier.name]}}</span>
                </td>
              </tr>
            </table>
            <div *ngIf="supplier.dates[day]">
              <div class="deal-card {{deal.currentStatus.color.replace('#','B')}}" *ngFor="let deal of supplier.dates[day].deals">
                <a class="ui red right mini corner label" *ngIf="!deal.mounting">
                  <i class="minus icon"></i>
                </a>
                <div class="d-flex justify-content-between">
                  <div class="deal-card-field w-50 fw-bold">
                    <a [routerLink]="['/deal/card/', deal.id]">{{deal.name | dealshort}}</a>
                  </div>
                  <div class="deal-card-field w-50 text-right" style="font-size: 14px;">
                    <span class="fw-bold">{{deal.totalSum | number}}</span>
                  </div>
                </div>
                <div class="deal-card-field">
                  <div e2b-filial-label [filial]="deal.filial"></div>
                </div>
                <div style="clear: both;"></div>
                <div class="deal-card-field">
                  <span>{{deal.manager.name}}</span>
                  <span *ngIf="!deal.manager">-</span>
                </div>
                <div style="clear: both;" class="deal-card-field">
                  <a [routerLink]="['/client/card', deal.client.id]">
                    <span>{{deal.client.name | surname}}</span>
                  </a>
                </div>
                <div class="deal-card-field">
                  {{deal.currentStatus.name}}
                </div>
                <div style="margin-top: 4px" *ngIf="!isEmptyObject(deal.properties)">
                  <div style="margin-right: 4px !important;float: left !important;" class="fw-bold" *ngIf="deal.properties.agt">
                    <a class="ui grey circular label">AGT</a>
                  </div>
                  <div style="margin-right: 4px !important;float: left !important;" class="fw-bold" *ngIf="deal.properties.alvic">
                    <a class="ui purple circular label">ALVIC</a>
                  </div>
                  <div style="margin-right: 4px !important;float: left !important;" class="fw-bold" *ngIf="deal.properties.smart">
                    <a class="ui brown circular label">SMART</a>
                  </div>
                  <div style="margin-right: 4px !important;float: left !important;" class="fw-bold" *ngIf="deal.properties.fil">
                    <a class="ui green circular label">ФИЛ</a>
                  </div>
                  <div style="margin-right: 4px !important;float: left !important;" class="fw-bold" *ngIf="deal.properties.custom">
                    <a class="ui blue circular label">КАСТ</a>
                  </div>
                </div>
                <div style="position: absolute; bottom: 6px; right: 6px;" class="fw-bold" *ngIf="deal.coefficient > 0">
                  <a class="ui orange circular label">{{deal.coefficient}}</a>
                </div>
                <div style="clear: both;"></div>
                <div class="ui flowing popup right hidden">
                  <p class="ui header" style="padding-top: 20px;">Позиции:</p>
                  <table class="ui very very basic table" *ngIf="deal.positions && deal.positions.length > 0">
                    <thead>
                    <tr>
                      <th style="width: 45%">
                        <div class="head">Позиция</div>
                      </th>
                      <th style="width: 16.7%">
                        <div class="head">Кол-во</div>
                      </th>
                      <th style="width: 16.7%">
                        <div class="head">Цена</div>
                      </th>
                      <th>
                        <div class="head">Сумма</div>
                      </th>
                    </tr>
                    </thead>
                    <tbody *ngFor="let item of deal.positions | orderByColumn:'id:number'">
                    <tr style="height: 58px">
                      <td>
                        {{item.name}}
                      </td>
                      <td>
                        {{item.count | number}}
                      </td>
                      <td style="width: 16.7%">
                        {{item.price | number}}
                      </td>
                      <td>{{item.discountPrice ? (((item.count * item.price) - item.discountPrice) | number) : ((item.count * item.price) | number)}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colspan="4" class="text-right">
                        Итого: <b>{{deal.positions && deal.positions.length > 0 ? (deal.totalSum | number) : 0}}</b> руб.
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
