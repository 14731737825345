<ng-template let-option let-query="query" #clientOptionTemplate>
  <div class="mininame">{{option.name}}</div>
  <div class="miniprice">{{option.description}}</div>
  <div class="minitext"></div>
</ng-template>
<sui-search class="searchbox fluid" style="width: 100% !important;" placeholder="Поиск клиента..."
            [options]="clients"
            [searchDelay]="100"
            optionsField="name"
            #selectClient
            [maxResults]="50"
            [placeholder]="selectedClient ? selectedClient.name : ''"
            [resultTemplate]="clientOptionTemplate"
            (resultSelected)="selectedClient = $event; select();"></sui-search>
