<sui-dimmer class="inverted" [(isDimmed)]="!positionValuesLoaded" [isClickable]="false">
  <div class="ui text loader">Загрузка...</div>
</sui-dimmer>

<form class="ui form" *ngIf="positionValues.position.length && positionValuesLoaded">
  <ng-container *ngFor="let children of positionValues.position | checkValuePermissionPipe | orderByColumn:'field.name:object:string'">
    <div class="field">
      <label class="fw-bold">
        <i class="ui icon" *ngIf="required" [ngClass]="{'green check circle': children.value, 'red close': !children.value}"></i>
        {{children.field.name}}
      </label>
      <div class="ui action input" *ngIf="children.field.type === 'NUMBER' || children.field.type === 'MONEY'">
        <input type="number" [(ngModel)]="children.value"
               [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
               [ngModelOptions]="{updateOn: 'blur'}"
               (ngModelChange)="onChangePositionValue(children.field.id)"
               [placeholder]="children.field.name"
               [name]="children.field.id"/>
        <button class="ui fit red icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div class="ui action input" *ngIf="children.field.type === 'ENUM'">
        <sui-select class="fluid selection"
                    [name]="children.field.id"
                    [placeholder]="children.field.name"
                    [isSearchable]="true"
                    [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                    [options]="children.field.values"
                    [(ngModel)]="children.value"
                    (ngModelChange)="onChangePositionValue(children.field.id)"
                    [ngModelOptions]="{name: children.field.name}"
                    #selectPositionChildren>
          <sui-select-option *ngFor="let value of selectPositionChildren.filteredOptions"
                             [value]="value">
          </sui-select-option>
        </sui-select>
        <button class="ui red fit icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])"
                (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div class="ui action input" *ngIf="children.field.type === 'TEXT'">
        <input type="text" [(ngModel)]="children.value"
               [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
               [ngModelOptions]="{updateOn: 'blur'}"
               (ngModelChange)="onChangePositionValue(children.field.id)"
               [placeholder]="children.field.name"
               [name]="children.field.id"/>
        <button class="ui fit red icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div *ngIf="children.field.type === 'BOOLEAN'">
        <sui-checkbox [(ngModel)]="children.value"
                      [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                      [ngModelOptions]="{updateOn: 'blur'}"
                      (ngModelChange)="onChangePositionValue(children.field.id)"
                      [name]="children.field.id"></sui-checkbox>
      </div>

      <div class="ui action input" *ngIf="children.field.type === 'MATERIAL'">
        <sui-select class="fluid selection mx-auto"
                    placeholder="Материал"
                    [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                    [(ngModel)]="children.value"
                    [ngModelOptions]="{name: children.field.name}"
                    (ngModelChange)="onChangePositionValue(children.field.id)"
                    [options]="children.field.values"
                    labelField="name"
                    [isSearchable]="true"
                    #selectPositionMaterial>
          <sui-select-option *ngFor="let m of selectPositionMaterial.filteredOptions"
                             [value]="m"></sui-select-option>
        </sui-select>
        <button class="ui fit red icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div class="ui action input" *ngIf="children.field.type === 'CLOTH'">
        <sui-select class="fluid selection mx-auto"
                    placeholder="Ткань"
                    [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                    [(ngModel)]="children.value"
                    [ngModelOptions]="{name: children.field.name}"
                    (ngModelChange)="onChangePositionValue(children.field.id)"
                    [options]="children.field.values"
                    labelField="name"
                    [isSearchable]="true"
                    #selectPositionCloth>
          <sui-select-option *ngFor="let m of selectPositionCloth.filteredOptions"
                             [value]="m"></sui-select-option>
        </sui-select>
        <button class="ui fit green icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="changeCloth(children.value)">
          Применить
        </button>
        <button class="ui fit red icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div suiPopup
           popupHeader="Внимание"
           popupText="Стоимость рассчитывается от базовой стоимости в каталоге товаров"
           class="ui action input"
           *ngIf="children.field.type === 'PERSONAL'">
        <sui-multi-select class="fluid selection mx-auto"
                          placeholder="Инд изменения"
                          [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                          [(ngModel)]="children.value"
                          [ngModelOptions]="{name: children.field.name}"
                          (ngModelChange)="onChangePositionValue(children.field.id)"
                          [options]="children.field.values"
                          labelField="name"
                          [isSearchable]="true"
                          #selectPositionPersonal>
          <sui-select-option *ngFor="let m of selectPositionPersonal.filteredOptions"
                             [value]="m"></sui-select-option>
        </sui-multi-select>
        <button class="ui fit green icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="changePersonal(children.value)">
          Применить
        </button>
        <button class="ui fit red icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div class="ui action input" *ngIf="children.field.type === 'USER'">
        <e2b-staff-dropdown [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])" [staff]="children.field.values" [event]="children.event"></e2b-staff-dropdown>
        <button class="ui fit red icon button" [disabled]="!children.value" (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div class="ui action input" *ngIf="children.field.type === 'USERS'">
        <sui-select class="fluid selection mx-auto"
                    placeholder="Сотрудники"
                    [isDisabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
                    [(ngModel)]="children.value"
                    [ngModelOptions]="{name: children.field.name}"
                    (ngModelChange)="onChangePositionValue(children.field.id)"
                    [options]="children.field.values"
                    labelField="name"
                    [isSearchable]="true"
                    #selectPositionUsers>
          <sui-select-option *ngFor="let user of selectPositionUsers.filteredOptions"
                             [value]="user"></sui-select-option>
        </sui-select>
        <button class="ui fit red icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div class="ui action input" *ngIf="children.field.type === 'DATE'">
        <input suiDatepicker
               *ngIf="userService.checkFieldPermission(children.field.id, ['write'])"
               [name]="children.field.id"
               [pickerMode]="'date'"
               [pickerFirstDayOfWeek]="1"
               [pickerUseNativeOnMobile]="true"
               [pickerPlacement]="'top left'"
               (pickerSelectedDateChange)="onPositionPickerChange($event, children.field.id)"
               [(ngModel)]="children.value"
               [placeholder]="children.field.name">
        <input *ngIf="!userService.checkFieldPermission(children.field.id, ['write'])"
               [name]="children.field.id"
               [value]="children.value | date:'dd.MM.yyyy'"
               [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])">
        <button class="ui fit red icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div class="ui action input" *ngIf="children.field.type === 'DATETIME'">
        <input suiDatepicker
               [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])"
               [name]="children.field.id"
               [pickerMode]="'datetime'"
               [pickerFirstDayOfWeek]="1"
               [pickerPlacement]="'top left'"
               [pickerUseNativeOnMobile]="true"
               (pickerSelectedDateChange)="onPositionPickerChange($event, children.field.id)"
               [(ngModel)]="children.value"
               [placeholder]="children.field.name">
        <button class="ui fit red icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div class="ui action input" *ngIf="children.field.type === 'USERGROUP'">
        <e2b-staff-dropdown [disabled]="!userService.checkFieldPermission(children.field.id, ['write'])" style="width: 100% !important;" [staff]="children.field.values" [selected]="children.value" [event]="children.event"></e2b-staff-dropdown>
        <button class="ui fit red icon button" [disabled]="!children.value || !userService.checkFieldPermission(children.field.id, ['write'])" (click)="removePositionValue(children.field.id)">
          <i class="remove icon"></i>
        </button>
      </div>

      <div *ngIf="children.field.type === 'FILE'">
        <div class="d-flex flex-wrap">
          <div class="attach" *ngFor="let file of children.value">
            <e2b-file [file]="file"></e2b-file>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="ui input" *ngIf="userService.checkFieldPermission(children.field.id, ['write'])">
          <input type="file" id="filePosition{{children.field.id}}" name="filePosition" #fileFieldPositionForm multiple
                 (change)="children.files = fileFieldPositionForm.files; onChangePositionValue(children.field.id);">
          <label for="filePosition{{children.field.id}}">
            <span>Прикрепить файл(ы)</span>
          </label>
        </div>
      </div>
    </div>
  </ng-container>
</form>
<h4 *ngIf="!positionValues.position.length" class="mt-0">нет реквизитов</h4>
