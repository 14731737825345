import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes'
})
export class FileSizePipe implements PipeTransform {
  transform(bytes: any): any {
    if (bytes >= 1000000000) {
      bytes = (bytes / 1000000000).toFixed(2) + ' Гб';
    } else if (bytes >= 1000000) {
      bytes = (bytes / 1000000).toFixed(2) + ' Мб';
    } else if (bytes >= 1000) {
      bytes = (bytes / 1000).toFixed(2) + ' Кб';
    } else if (bytes > 1) {
      bytes = bytes + ' байт(а)';
    } else if (bytes === 1) {
      bytes = bytes + ' байт';
    } else {
      bytes = '0 byte';
    }
    return bytes;
  }
}
