import { BaseModel } from './base.model';
import { DealField } from './deal-field.model';
import { PositionGroup } from './position-group.model';

export class PositionGroupTemplate extends BaseModel {
  name: string;
  deleted: boolean;
  groups: PositionGroup[];
  fields: DealField[];
}
