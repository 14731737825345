<ng-template let-context let-modal="modal" #modalTemplate>
  <div class="header">
    {{context.todo.name}}
    <img *ngIf="context.todo.responsible" class="ui avatar image" style="float: right"
         [src]="(context.todo.responsible.avatar.path + '/' + context.todo.responsible.avatar.name) | secure"
         title="{{context.todo.responsible.name}}"/>
    <div class="todo-text todo-modal">{{context.todo.description}}</div>
    <div class="todo-date todo-modal">
      <div [class]="'ui tiny label ' + context.todo.type.color.replace('#','B')" [innerText]="context.todo.type.name"></div>
      <span *ngIf="context.todo.from">{{context.todo.from | date:'d MMM HH:mm'}}</span><span *ngIf="context.todo.to"> - {{context.todo.to | date:'HH:mm'}}</span>
    </div>
  </div>
  <div class="content">
    <div class="ui form">
      <div class="ui field">
        <label>
          <textarea rows="3" name="comment" [(ngModel)]="context.todo.finishComment"
                    placeholder="Комментарий к выполнению... (обязательно!)"></textarea>
        </label>
      </div>
      <div class="ui inline fields">
        <div class="field">
          <sui-radio-button name="example" value="3" [(ngModel)]="context.status">Завершено успешно</sui-radio-button>
        </div>
        <div class="field">
          <sui-radio-button name="example" value="4" [(ngModel)]="context.status">Неуспешно</sui-radio-button>
        </div>
      </div>
    </div>
  </div>

  <div class="content" *ngIf="nextTodoRequired">
    <form class="ui form">
      <h3 class="ui header">Следующее дело</h3>
      <div class="ui field">
        <label class="fw-bold">Тип</label>
        <sui-select class="fluid selection"
                    placeholder="Тип"
                    #selectType
                    [isSearchable]="false"
                    labelField="name"
                    [options]="types"
                    [(ngModel)]="nextTodo.type"
                    [ngModelOptions]="{name: 'type'}">
          <sui-select-option *ngFor="let type of selectType.filteredOptions | orderByColumn:'name:string'"
                             [value]="type">
          </sui-select-option>
        </sui-select>
      </div>
      <div class="ui field">
        <label class="fw-bold">Ответственный</label>
        <e2b-staff-dropdown [staff]="users" [selected]="nextTodo.responsible"
                            [event]="selectNextTodoResponsible"></e2b-staff-dropdown>
      </div>
      <div class="two fields">
        <div class="field">
          <label class="fw-bold">Дата начала</label>
          <input [(ngModel)]="nextTodo.from" autocomplete="off" name="nextTodoStartDate" pickerMode="datetime" suiDatepicker [pickerUseNativeOnMobile]="true">
        </div>
        <div class="field">
          <label class="fw-bold">Дата конца</label>
          <input [(ngModel)]="nextTodo.to" [pickerMinDate]="nextTodo.from" autocomplete="off" name="nextTodoFinishDate" pickerMode="time" suiDatepicker [pickerUseNativeOnMobile]="true">
        </div>
      </div>
      <div class="ui field">
        <label class="fw-bold">Что будем делать?</label>
        <input type="text" [(ngModel)]="nextTodo.name" name="nextTodoName">
      </div>
    </form>
  </div>

  <div class="actions">
    <button class="ui red button" (click)="modal.deny(context)">Отмена</button>
    <button (click)="modal.approve(context)" [disabled]="!context.todo.finishComment || !nextTodoValid" autofocus class="ui green button">ОК</button>
  </div>
</ng-template>
<div class="ui fluid basic card" (mouseleave)="currentTodo = null">
  <div class="content">
    <div class="header">
      <h3 class="fw-bold header" *ngIf="dealId">Дела ({{todos ? (todos | todoFilter:filter).length : 0}})</h3>
      <div class="d-flex justify-content-between align-items-center" *ngIf="!dealId">
        <button class="ui button" (click)="prevDay();">
          <i class="angle left icon"></i>
        </button>
        <h3 class="fw-bold m-0 header">
          <input suiDatepicker
                 #date
                 style="width: 0 !important; height: 0 !important; border: 0 !important; padding: 0 !important;"
                 [(ngModel)]="selectedDate"
                 (ngModelChange)="onSelectDate($event)"
                 [pickerMode]="'date'"
                 [pickerUseNativeOnMobile]="true">
          Дела на <a (click)="onDate()">{{day.format('DD MMM, YY г.')}}</a> ({{todos ? (todos | todoFilter:filter).length : 0}})
        </h3>
        <e2b-staff-simple-dropdown class="fsz-14px" [staff]="users" [selected]="newTodo.responsible"
                                   [event]="selectResponsible"></e2b-staff-simple-dropdown>
        <button class="ui button" (click)="nextDay();">
          <i class="angle right icon"></i>
        </button>
      </div>
    </div>
    <div class="meta">
    </div>
  </div>
  <div>
    <sui-tabset>
      <div class="ui secondary pointing menu">
        <a class="item" (activate)="showActive()" suiTabHeader="active">
          Активные <span>({{todos && todos.length > 0 ? (todos | todoFilter:['E2B.TODO.STATUS.SCHEDULED', 'E2B.TODO.STATUS.WORKING']).length : 0}})</span>
        </a>
        <a class="item" (activate)="showFinished()" suiTabHeader="finished">
          Завершено <span>({{todos && todos.length > 0 ? (todos | todoFilter:['E2B.TODO.STATUS.SUCCESS', 'E2B.TODO.STATUS.FAIL']).length : 0}})</span>
        </a>
        <a class="item red" (activate)="overdueTodos" suiTabHeader="overdue" *ngIf="showOverdueTodos">
          <span class="fw-bold" style="color: #db2828;">Просроченные ({{overdueTodos && overdueTodos.length > 0 ? overdueTodos.length : 0}})</span>
        </a>
      </div>
      <div class="ui segment" suiTabContent="active" [ngClass]="{'loading': isTodoLoading}">
        <div class="ui form">
          <div class="ui grouped stackable fields">
            <div *ngFor="let todo of todos | orderByDate:'from:date' | todoFilter:filter">
              <div class="todo field" (mouseover)="currentTodo = todo">
                <div class="ui stackable grid">
                  <div class="ui six wide column">
                    <div class="todo-description">
                      <sui-checkbox class="todo-checkbox"
                                    [(ngModel)]="todo.checked"
                                    [isReadonly]="todo.responsible && user.id !== todo.responsible.id"
                                    (ngModelChange)="checkboxState($event, todo)">
                        {{todo.name}}
                      </sui-checkbox>
                      <div class="todo-date">
                        <span *ngIf="todo.from">{{todo.from | date:'d MMM HH:mm'}}</span><span *ngIf="todo.to"> - {{todo.to | date:'d MMM HH:mm'}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="ui seven wide column">
                    <div class="todo-deal" *ngIf="showDeal">
                      <a class="todo-deal-link" target="_blank" [routerLink]="['/deal/card', todo.deal.id]"
                         [title]="todo.deal.name">
                        <i class="big blue briefcase icon"></i>
                      </a>
                      <a class="todo-client-link" target="_blank" [routerLink]="['/client/card', todo.deal.client.id]"
                         [title]="todo.deal.client.name">
                        <i class="big green address card outline icon"></i>
                      </a>
                    </div>
                    <div [class]="'ui tiny label ' + todo.type.color.replace('#','B')" [innerText]="todo.type.name"></div>
                    <e2b-user-label [user]="todo.responsible"></e2b-user-label>
                  </div>
                  <div class="ui three wide column">
                    <div class="ui two buttons todo-action " *ngIf="currentTodo === todo">
                      <button class="ui green basic icon button" (click)="edit(todo)"><i class="ui edit icon"></i>
                      </button>
                      <button class="ui red basic icon button" (click)="remove(todo)" *ngxPermissionsOnly="'E2B.DELETE.TODO'"><i class="ui delete icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ui segment" suiTabContent="finished" [ngClass]="{'loading': isTodoLoading}">
        <div class="ui form">
          <div class="ui grouped stackable fields">
            <div class="field" *ngFor="let todo of todos | orderByDate:'-from' | todoFilter:filter">
              <div class="ui stackable grid" (mouseover)="currentTodo = todo">
                <div class="ui five wide column">
                  <div class="todo-description">
                    <sui-checkbox [(ngModel)]="todo.checked" [isDisabled]="true" class="todo-checkbox">
                      {{todo.name}}
                    </sui-checkbox>
                    <div class="todo-date" *ngIf="todo.finished">
                      {{todo.finished | date:'d MMM HH:mm'}}
                    </div>
                  </div>
                </div>
                <div class="ui eight wide column">
                  <div class="todo-deal" *ngIf="showDeal">
                    <a class="todo-deal-link" target="_blank" [routerLink]="['/deal/card', todo.deal.id]"
                       [title]="todo.deal.name">
                      <i class="big blue briefcase icon"></i>
                    </a>
                    <a class="todo-client-link" target="_blank" [routerLink]="['/client/card', todo.deal.client.id]"
                       [title]="todo.deal.client.name">
                      <i class="big green address card outline icon"></i>
                    </a>
                  </div>
                  <div [class]="'ui tiny label ' + todo.type.color.replace('#','B')" [innerText]="todo.type.name"></div>
                  <e2b-user-label [user]="todo.responsible"></e2b-user-label>
                  <div class="todo-text">{{todo.status.name}}<br/>{{todo.finishComment}}</div>
                  <div class="todo-date">
                    <span *ngIf="todo.from">{{todo.from | date:'d MMM HH:mm'}}</span><span *ngIf="todo.to"> - {{todo.to | date:'d MMM HH:mm'}}</span>
                  </div>
                </div>
                <div class="ui three wide column">
                  <div class="todo-action" *ngIf="!todo.responsible">
                    <button class="ui green basic button" (click)="assign(todo)">Делать</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ui segment" suiTabContent="overdue" [ngClass]="{'loading': isTodoLoading}">
        <div class="ui form">
          <div class="ui grouped stackable fields">
            <div class="field" *ngFor="let todo of overdueTodos | orderByColumn:'from:date'">
              <div class="ui stackable grid">
                <div class="ui six wide column">
                  <div class="todo-description">
                    <sui-checkbox [(ngModel)]="todo.checked" [isDisabled]="true" class="todo-checkbox">
                      {{todo.name}}
                    </sui-checkbox>
                    <div class="todo-date overdueTodo">
                      <span *ngIf="todo.from">{{todo.from | date:'d MMM HH:mm'}}</span><span *ngIf="todo.to"> - {{todo.to | date:'d MMM HH:mm'}}</span>
                    </div>
                  </div>
                </div>
                <div class="ui seven wide column">
                  <div class="todo-deal" *ngIf="showDeal">
                    <a class="todo-deal-link" target="_blank" [routerLink]="['/deal/card', todo.deal.id]"
                       [title]="todo.deal.name">
                      <i class="big blue briefcase icon"></i>
                    </a>
                    <a class="todo-client-link" target="_blank" [routerLink]="['/client/card', todo.deal.client.id]"
                       [title]="todo.deal.client.name">
                      <i class="big green address card outline icon"></i>
                    </a>
                  </div>
                  <div [class]="'ui tiny label ' + todo.type.color.replace('#','B')" [innerText]="todo.type.name"></div>
                  <e2b-user-label [user]="todo.responsible"></e2b-user-label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </sui-tabset>
  </div>
</div>
<div class="extra content">
  <div class="ui message" [hidden]="!showNew">
    <i class="close icon" (click)="showNew = false"></i>
    <div class="todo">
      <div class="ui form">
        <div class="field">
          <ng-template let-option #todoTypeOptionTemplate>
            {{option.name}}
          </ng-template>
          <sui-select class="fluid selection" [ngClass]="{'error': error && !newTodo.type}"
                      placeholder="Тип"
                      #selectType
                      [isSearchable]="false"
                      labelField="name"
                      [options]="types"
                      [optionTemplate]="todoTypeOptionTemplate"
                      [(ngModel)]="newTodo.type"
                      [ngModelOptions]="{name: 'type'}">
            <sui-select-option *ngFor="let type of selectType.filteredOptions | orderByColumn:'name:string'"
                               [value]="type">
            </sui-select-option>
          </sui-select>
        </div>
        <div class="field" *ngIf="!dealId">
          <e2b-deal-dropdown [deals]="deals" [error]="error && !newTodo.deal" [event]="selectDeal"></e2b-deal-dropdown>
        </div>
        <div class="field" *ngIf="dealId">
          <a href="#" target="_blank" [routerLink]="['/deal/card', dealId]">{{deal ? deal.name : ''}}</a>
        </div>
        <div class="field">
          <div class="ui fluid input" [ngClass]="{'error': error && !newTodo.name}">
            <input type="text" name="name" [(ngModel)]="newTodo.name" placeholder="Что будем делать?">
          </div>
        </div>
        <div class="field">
          <e2b-staff-dropdown [staff]="users" [selected]="newTodo.responsible"
                              [event]="selectUser"></e2b-staff-dropdown>
        </div>
        <div class="fields">
          <div class="field w-50">
            <div class="ui input">
              <input suiDatepicker
                     name="from"
                     placeholder="Начало"
                     [ngModelOptions]="{name: 'from'}"
                     [pickerFirstDayOfWeek]="1"
                     [pickerPlacement]="'top left'"
                     [pickerUseNativeOnMobile]="true"
                     [pickerMode]="'datetime'"
                     [(ngModel)]="newTodo.from">
            </div>
          </div>
          <div class="field w-50">
            <div class="ui input" [ngClass]="{'disabled': !newTodo.from}">
              <input suiDatepicker
                     name="to"
                     placeholder="Конец"
                     [ngModelOptions]="{name: 'to'}"
                     [pickerFirstDayOfWeek]="1"
                     [pickerPlacement]="'top left'"
                     [pickerUseNativeOnMobile]="true"
                     [pickerMode]="'time'"
                     [pickerMinDate]="newTodo.from"
                     [(ngModel)]="newTodo.to">
            </div>
          </div>
        </div>
        <button class="ui green button" (click)="createTodo()" [ngClass]="{'disabled': !newTodo.to || !newTodo.type || !newTodo.responsible}">Сохранить</button>
      </div>
    </div>
  </div>
  <div class="ui message" *ngIf="editTodo">
    <i class="close icon" (click)="editTodo = null"></i>
    <div class="todo">
      <div class="ui form">
        <div class="field">
          <ng-template let-option #todoTypeOptionTemplate>
            {{option.name}}
          </ng-template>
          <sui-select class="fluid selection" [ngClass]="{'error': error && !editTodo.type}"
                      placeholder="Тип"
                      #selectEditType
                      [isSearchable]="false"
                      labelField="name"
                      [options]="types"
                      [optionTemplate]="todoTypeOptionTemplate"
                      [(ngModel)]="editTodo.type"
                      [ngModelOptions]="{name: 'type'}">
            <sui-select-option *ngFor="let type of selectType.filteredOptions | orderByColumn:'name:string'"
                               [value]="type">
            </sui-select-option>
          </sui-select>
        </div>
        <div class="field">
          <div class="ui fluid input" [ngClass]="{'error': error && !editTodo.name}">
            <input type="text" name="name" [(ngModel)]="editTodo.name" placeholder="Что будем делать?">
          </div>
        </div>
        <div class="field">
          <e2b-staff-dropdown [staff]="users" [selected]="editTodo.responsible"
                              [event]="selectEditUser"></e2b-staff-dropdown>
        </div>
        <div class="fields">
          <div class="field w-50" [ngClass]="{'disabled': editTodo.from}">
            <input placeholder="Начало"
                   suiDatepicker
                   [(ngModel)]="editTodo.from"
                   [pickerInitialDate]="editTodo.from"
                   [pickerUseNativeOnMobile]="true">
          </div>
          <div class="field w-50">
            <div class="ui input" [ngClass]="{'disabled': !editTodo.from || editTodo.to}">
              <input placeholder="Конец"
                     suiDatepicker
                     [pickerInitialDate]="editTodo.to"
                     [pickerMode]="'time'"
                     [(ngModel)]="editTodo.to"
                     [pickerUseNativeOnMobile]="true">
            </div>
          </div>
        </div>
        <button class="ui green button" (click)="saveTodo()" [ngClass]="{'disabled': !editTodo.to}">Сохранить</button>
      </div>
    </div>
  </div>
  <br/>
  <button class="ui basic green button" [disabled]="showNew" (click)="add()">+ Добавить дело</button>
</div>
