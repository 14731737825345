import { Component, EventEmitter, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { DealField } from '../../_models/deal-field.model';
import { PositionGroup } from '../../_models/position-group.model';

@Component({
  selector: 'e2b-postion-group-template-field-dropdown',
  templateUrl: './postion-group-template-field-dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PostionGroupTemplateFieldDropdownComponent {
  @Input()
  public isDisabled = false;
  @Input()
  public placeholder: string;
  @Input()
  public array: PositionGroup[] | DealField[];
  @Input()
  public event: EventEmitter<PositionGroup[] | DealField[]>;

  @ViewChild('selectGroup', { static: false })
  selectGroup;

  public selected: PositionGroup[] | DealField[];

  @Input()
  set selectedGroups(groups: PositionGroup[]) {
    if (groups) {
      this.selected = groups;
      setTimeout(() => {
        this.selectGroup.selectedOptions = groups;
      }, 0);
    }
  }

  @Input()
  set selectedFields(fields: DealField[]) {
    if (fields) {
      this.selected = fields;
      setTimeout(() => {
        this.selectGroup.selectedOptions = fields;
      }, 0);
    }
  }

  save() {
    this.event.emit(this.selected);
  }
}
