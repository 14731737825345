import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { DealField } from '../../_models/deal-field.model';
import { DealStatus } from '../../_models/deal-status.model';
import { UserGroup } from '../../_models/user-group.model';
import { User } from '../../_models/user.model';
import { DealFieldService } from '../../_services/deal-field.service';
import { DealStatusService } from '../../_services/deal-status.service';
import { UserGroupService } from '../../_services/user-group.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';

@Component({
  selector: 'e2b-deal-field-list',
  templateUrl: './deal-field-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/form.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../../assets/design/semantic-ui/components/input.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class DealFieldListComponent implements OnInit {

  newValue: any = '';
  fields: DealField[] = [];
  loaded = false;
  editField: number | boolean = false;
  statuses: DealStatus[] = [];
  typeRus = {
    BOOLEAN: 'Да/Нет',
    NUMBER: 'Число',
    FILE: 'Файлы',
    TEXT: 'Текст',
    DATE: 'Дата',
    DATETIME: 'Дата и время',
    MONEY: 'Деньги',
    ENUM: 'Список',
    USERGROUP: 'Пользователи из групп (фильтр)',
    USER: 'Пользователь (фильтр)',
    MATERIAL: 'Материал из справочника',
    CLOTH: 'Ткань из справочника',
    PERSONAL: 'Индивидуальные изменения',
    STEP: 'Этап выполнения'
  };
  modelRus = {
    'CRM.FIELD.DEAL': 'Сделка',
    'CRM.FIELD.DELIVERY': 'Доставка',
    'CRM.FIELD.MOUNTING': 'Монтаж',
    'CRM.FIELD.MEASURE': 'Замер',
    'CRM.FIELD.PRODUCTION': 'Производство',
    'CRM.FIELD.POSITION': 'Товар',
    'CRM.FIELD.PROBLEM': 'Проблема'
  };
  models = ['CRM.FIELD.DEAL', 'CRM.FIELD.DELIVERY', 'CRM.FIELD.MOUNTING', 'CRM.FIELD.PRODUCTION', 'CRM.FIELD.POSITION', 'CRM.FIELD.MEASURE', 'CRM.FIELD.PROBLEM'];
  types = ['BOOLEAN', 'NUMBER', 'FILE', 'TEXT', 'DATE', 'DATETIME', 'MONEY', 'ENUM', 'USERGROUP', 'USER', 'MATERIAL', 'CLOTH', 'PERSONAL', 'STEP'];
  groups: UserGroup[] = [];
  users: User[] = [];

  constructor(private actionService: ActionService,
              private dealFieldService: DealFieldService,
              private dealStatusService: DealStatusService,
              private userGroupService: UserGroupService,
              private userService: UserService) {
  }

  ngOnInit() {
    const addEvent = new EventEmitter();

    if (this.userService.checkPermissions(['deal-field', '/'], ['write'])) {
      this.actionService.createActionEvent.emit({
        name: 'Добавить',
        event: addEvent,
        class: 'green',
        roles: ['E2B.ROLE.SUPERADMIN', 'E2B.ROLE.MANAGER', 'E2B.ROLE.ADMIN']
      });
    }

    const updateEvent = new EventEmitter();
    this.actionService.createActionEvent.emit({
      name: 'Обновить',
      event: updateEvent,
      class: 'blue',
      icon: 'sync'
    });

    updateEvent.subscribe(() => {
      this.editField = false;
      this.load();
    });

    addEvent.subscribe(() => {
      this.add();
    });

    this.dealStatusService.find<DealStatus>().subscribe(statuses => {
      this.statuses = statuses;
    });

    this.userGroupService.find<UserGroup>().subscribe(groups => {
      this.groups = groups;
    });
    this.userService.find<User>().subscribe(users => {
      this.users = users;
    });

    this.load();
  }

  trackByFn(index, item) {
    return item.id;
  }

  load() {
    this.loaded = false;
    this.fields = [];
    this.dealFieldService.find<DealField>().subscribe(fields => {
      this.fields = fields;
      this.loaded = true;
    });
  }

  save(field: DealField) {
    if (!field.type || !field.name) {
      return false;
    }
    if (field.id) {
      const update: any = { id: field.id, name: field.name, type: field.type, sort: field.sort };
      this.update(update);
    } else {
      this.dealFieldService.create<DealField>(field).subscribe(created => {
        this.fields[0].id = created.id;
        this.editField = created.id;
      }, e => {
        console.error(e);
      });
    }
  }

  add() {
    const m = new DealField();
    m.requiredInStatuses = [];
    m.availableInStatuses = [];
    this.fields.unshift(m);
    this.editField = m.id;
  }

  edit(field: any) {
    if (!this.userService.checkPermissions(['deal-field', '/'], ['write'])) {
      return false;
    }
    if (this.editField === field.id) {
      this.editField = false;
      return false;
    }
    field.availableInStatuses.map((a, k) => {
      this.statuses.map(status => {
        if (a.id === status.id) {
          field.availableInStatuses[k] = status;
        }
      });
    });
    field.requiredInStatuses.map((a, k) => {
      this.statuses.map(status => {
        if (a.id === status.id) {
          field.requiredInStatuses[k] = status;
        }
      });
    });
    if (field.values) {
      field.values.map((v, k) => {
        if (field.type === 'USERGROUP') {
          this.groups.map(o => {
            if (v.id === o.id) {
              field.values[k] = o;
            }
          });
        } else if (field.type === 'USER') {
          this.users.map(o => {
            if (v.id === o.id) {
              field.values[k] = o;
            }
          });
        }
      });
    }
    this.editField = field.id;
  }

  onUsergroupChange(evt, field) {
    const update: any = { id: this.fields[this.fields.indexOf(field)].id };
    if (field.type === 'USERGROUP') {
      update.type = 'USERGROUP(' + evt.map(v => v.id).join(',') + ')';
    }
    this.update(update);
  }

  onEnumChange(evt, field) {
    const update: any = {};
    update.id = field.id;
    if (field.type === 'ENUM') {
      update.type = 'ENUM(' + evt.join(',') + ')';
    }
    this.update(update);
  }

  onAvailStatusChange($event, field) {
    const update: any = { id: field.id };
    update.availableInStatuses = $event.map(s => {
      return { id: s.id };
    });
    this.update(update);
  }

  onReqStatusChange($event, field) {
    const update: any = { id: field.id };
    update.requiredInStatuses = $event.map(s => {
      return { id: s.id };
    });
    this.update(update);
  }

  onUserChange(evt, field) {
    const update: any = {};
    update.id = field.id;
    if (field.type === 'USER') {
      update.type = 'USER(' + evt.map(v => v.id).join(',') + ')';
    }
    this.update(update);
  }

  remove(field) {
    if (!field.id) {
      this.fields.splice(this.fields.indexOf(field), 1);
      return false;
    }
    const yes = confirm('Вы уверены, что хотите удалить поле ' + field.name + '?');
    if (!yes) {
      return true;
    }
    this.dealFieldService.deleteById(field.id).subscribe(() => {
      this.fields.splice(this.fields.indexOf(field), 1);
    });
  }

  onTypeChange(field) {
    const update: any = { id: field.id, name: field.name, sort: field.sort, requiredInStatuses: [], availableInStatuses: [] };
    if (field.type === 'USER') {
      update.type = 'USER()';
      field.values = [];
    } else if (field.type === 'USERGROUP') {
      update.type = 'USERGROUP()';
      field.values = [];
    } else if (field.type === 'ENUM') {
      update.type = 'ENUM()';
      field.values = [];
    } else {
      update.type = field.type;
    }
    this.save(update);
  }

  addValue(field, elem) {
    const update: any = {};
    update.id = field.id;
    elem.selectedOptions.push(this.newValue);
    field.values.push(this.newValue);
    if (field.type === 'ENUM') {
      update.type = 'ENUM(' + field.values.join(',') + ')';
    }
    this.update(update);
    this.newValue = '';
  }

  update(field: DealField) {
    this.dealFieldService.update(field).subscribe(() => {
    }, console.error);
  }

  onModelChange($event, field) {
    const update: any = { id: field.id, model: $event };
    this.update(update);
  }
}
