import {Component, EventEmitter, Input, ViewChild, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'e2b-entity-multi-dropdown',
    templateUrl: './entity.multi.dropdown.component.html',
    styleUrls: ['../../../assets/design/semantic-ui/components/input.min.css'],
    encapsulation: ViewEncapsulation.None
})
export class EntityMultiDropdownComponent {

    @ViewChild('selectEntity', {static: false})
    selectEntity;

    @Input()
    public entities: any[];

    @Input()
    public name: string;

    @Input()
    public event: EventEmitter<any[]>;

    public selectedEntities: string[];

    @Input()
    set selected(s) {
        if (s) {
            setTimeout(() => {
                this.selectEntity.selectedOptions = s;
                this.selectedEntities = s;
            }, 100);
        }
    }

    save() {
        this.event.emit(this.selectedEntities);
    }

}
