<form class="ui form" (submit)="save()">
  <div class="ui grid">
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Название <sup style="color: red">*</sup></h4>
      </div>
      <div class="six wide column">
        <div class="field required">
          <input id="name" type="text" name="name" [(ngModel)]="template.name" placeholder="Название" required>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Поля</h4>
      </div>
      <div class="six wide column">
        <e2b-postion-group-template-field-dropdown
          #selectField [placeholder]="'Поля'"
          [array]="dealFields"
          [selectedFields]="template.fields"
          [event]="changeFields(template)">
        </e2b-postion-group-template-field-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Группы</h4>
      </div>
      <div class="six wide column">
        <ng-template let-option #groupOptionTemplate>
          {{option.name}}
        </ng-template>
        <sui-multi-select class="fluid selection mx-auto" [ngClass]="{'error': error}"
                          placeholder="Группы"
                          [isSearchable]="true"
                          [isDisabled]="false"
                          [(ngModel)]="template.groups"
                          [ngModelOptions]="{name: 'template'}"
                          [options]="positionGroups"
                          labelField="name"
                          [optionTemplate]="groupOptionTemplate"
                          #selectTemplateGroup>
          <sui-select-option *ngFor="let template of selectTemplateGroup.filteredOptions | orderByColumn:'name:string'"
                             [value]="template">
          </sui-select-option>
        </sui-multi-select>
      </div>
    </div>
  </div>
</form>
<div class="mt-2">
  <button type="submit" (click)="save()" class="ui button green"
          [disabled]="(!template.name || template.name === '')">
    Добавить
  </button>
  <button (click)="cancel()" class="ui button red">Отмена</button>
</div>
