import { BaseModel } from './base.model';

export class Problem extends BaseModel {
  childrens: Problem[];
  cost: number;
  deleted: boolean;
  discount: number;
  id: number;
  name: string;
  parent: Problem;
}
