import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable()
export class ControlpointService extends BaseService {
  baseUrl = '/controlpoint';

  findDetail(controlpointId: number, options: { date: string, userId: number, status: string }): Observable<any> {
    const params = {};
    if (options) {
      Object.entries(options).map(o => {
        params[o[0]] = o[1];
      });
    }

    return this.http.get(this.baseUrl + '/' + controlpointId, {
      params,
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

  summary(options: { id: number, date: string, userId: number }): Observable<any> {

    return this.http.post(this.baseUrl + '/summary', options, {
      withCredentials: true,
      headers: { 'Authorization': localStorage.getItem('e2b.user.token') }
    });
  }

}
