<div class="ui stackable grid">
  <div class="ui sixteen wide column">
    <sui-dimmer class="inverted" [(isDimmed)]="!loaded" [isClickable]="false">
      <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>
    <div [hidden]="!loaded">
      <div class="ui form">
        <table class="ui very basic celled table">
          <thead>
          <tr>
            <th>
              <div class="head">Название поля</div>
            </th>
            <th>
              <div class="head">Тип поля</div>
            </th>
            <th>
              <div class="head">Значения</div>
            </th>
            <th>
              <div class="head">Доступно в статусах</div>
            </th>
            <th>
              <div class="head">Обязательно в статусах</div>
            </th>
            <th>
              <div class="head">Объект</div>
            </th>
            <th>
              <div class="head">Сортировка</div>
            </th>
          </tr>
          </thead>
          <tbody *ngFor="let field of fields | orderByColumn:'name:string'">
          <tr>
            <td width="20%">
              <div class="field">
                <div class="ui input" *ngIf="editField === field.id">
                  <input type="text" [disabled]="!userService.checkPermissions(['deal-field', '/'], ['write'])" [(ngModel)]="field.name" (change)="save(field)">
                </div>
                <a class="cursor" *ngIf="editField !== field.id" (click)="edit(field)" [innerText]="field.name"></a>
              </div>
            </td>
            <td width="10%">
              <div class="field">
                <div class="ui input" *ngIf="editField === field.id && userService.checkPermissions(['deal-field', '/'], ['write'])">
                  <sui-select class="selection" [(ngModel)]="field.type" [options]="types" #selectType (ngModelChange)="onTypeChange(field)">
                    <sui-select-option *ngFor="let option of selectType.filteredOptions" [value]="option"></sui-select-option>
                  </sui-select>
                </div>
                <span *ngIf="editField !== field.id" [innerText]="typeRus[field.type]"></span>
              </div>
            </td>
            <td width="20%">
              <div class="field" *ngIf="editField === field.id && userService.checkPermissions(['deal-field', '/'], ['write'])" [hidden]="!field.id">
                <div *ngIf="field.type === 'ENUM'">
                  <sui-multi-select (ngModelChange)="onEnumChange($event, field)" class="selection" [(ngModel)]="field.values" [options]="field.values" #selectEnum>
                    <sui-select-option *ngFor="let option of selectEnum.filteredOptions" [value]="option"></sui-select-option>
                  </sui-multi-select>
                  <br/>
                  <div class="ui input" style="margin-top: 10px;margin-bottom: 10px;">
                    <input type="text" [(ngModel)]="newValue">
                  </div>
                  <button class="ui blue button" (click)="addValue(field, selectEnum)">Добавить</button>
                </div>
                <div class="ui input" *ngIf="field.type === 'USERGROUP'">
                  <sui-multi-select [isSearchable]="true" (ngModelChange)="onUsergroupChange($event, field)" class="multiple search selection" [(ngModel)]="field.values" labelField="name" [options]="groups" #selectOption>
                    <sui-select-option *ngFor="let option of selectOption.filteredOptions | orderByColumn:'name:string'" [value]="option"></sui-select-option>
                  </sui-multi-select>
                </div>
                <div class="ui input" *ngIf="field.type === 'USER'">
                  <sui-multi-select (ngModelChange)="onUserChange($event, field)" class="selection" [(ngModel)]="field.values" labelField="name" [options]="users" #selectOption>
                    <sui-select-option *ngFor="let option of selectOption.filteredOptions | orderByColumn:'name:string'" [value]="option"></sui-select-option>
                  </sui-multi-select>
                </div>
              </div>
              <div class="field" *ngIf="editField !== field.id && userService.checkPermissions(['deal-field', '/'], ['write'])">
                <div *ngIf="field.type === 'ENUM'">
                  <div class="ui label" *ngFor="let s of field.values" style="margin-bottom: 5px !important;" [innerText]="s"></div>&nbsp;
                </div>
                <div *ngIf="field.type === 'USERGROUP'">
                  <div class="ui label" *ngFor="let s of field.values" style="margin-bottom: 5px !important;" [innerText]="s.name"></div>&nbsp;
                </div>
                <div *ngIf="field.type === 'USER'">
                  <div class="ui label" *ngFor="let s of field.values" style="margin-bottom: 5px !important;" [innerText]="s.name"></div>&nbsp;
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="editField !== field.id">
                <div class="ui label {{s.color.replace('#','X')}}" *ngFor="let s of field.availableInStatuses" style="margin-bottom: 5px !important;" [innerText]="s.name"></div>&nbsp;
              </div>
              <div class="field" *ngIf="editField === field.id && userService.checkPermissions(['deal-field', '/'], ['write'])" [hidden]="!field.id">
                <sui-multi-select class="selection" [options]="statuses" [(ngModel)]="field.availableInStatuses" (ngModelChange)="onAvailStatusChange($event, field)" labelField="name" #multiSelect>
                  <sui-select-option *ngFor="let option of multiSelect.filteredOptions" [value]="option"></sui-select-option>
                </sui-multi-select>
              </div>
            </td>
            <td>
              <div *ngIf="editField !== field.id">
                <div class="ui label {{s.color.replace('#','X')}}" *ngFor="let s of field.requiredInStatuses trackBy: trackByFn;" style="margin-bottom: 5px !important;" [innerText]="s.name"></div>&nbsp;
              </div>
              <div class="field" *ngIf="editField === field.id && userService.checkPermissions(['deal-field', '/'], ['write'])" [hidden]="!field.id">
                <sui-multi-select class="selection" [options]="statuses" [(ngModel)]="field.requiredInStatuses" (ngModelChange)="onReqStatusChange($event, field)" labelField="name" #multiSelect2>
                  <sui-select-option *ngFor="let option of multiSelect2.filteredOptions" [value]="option"></sui-select-option>
                </sui-multi-select>
              </div>
            </td>
            <td nowrap>
              <div *ngIf="editField !== field.id ">
                {{modelRus[field.model]}}
              </div>
              <div class="field" *ngIf="editField === field.id" [hidden]="!field.id">
                <sui-select class="selection" [options]="models" [(ngModel)]="field.model" (ngModelChange)="onModelChange($event, field)" #multiSelect>
                  <sui-select-option *ngFor="let option of multiSelect.filteredOptions" [value]="option"></sui-select-option>
                </sui-select>
              </div>
            </td>
            <td width="4%">
              <div class="field" *ngIf="editField === field.id" [hidden]="!field.id">
                <input type="number" [(ngModel)]="field.sort" (change)="save(field)">
              </div>
              <span *ngIf="editField !== field.id">{{ field.sort }}</span>
            </td>
            <td>
              <i class="ui red remove cursor icon" *ngIf="userService.checkPermissions(['deal-field', '/'], ['write'])" (click)="remove(field)"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
