<form class="ui form">
  <div class="ui grid">
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Тип подрядчика</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <sui-select class="selection"
                      [(ngModel)]="contractor.type"
                      placeholder="Тип"
                      #select
                      labelField="name"
                      [ngModelOptions]="{standalone: true}">
            <sui-select-option *ngFor="let type of types" [value]="type">
            </sui-select-option>
          </sui-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Название <sup style="color: red">*</sup></h4>
      </div>
      <div class="six wide column">
        <div class="field required">
          <input type="text" name="name" [(ngModel)]="contractor.name" placeholder="Название" required>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Дополнительная информация</h4>
      </div>
      <div class="six wide column">
        <div class="field">
          <textarea [(ngModel)]="contractor.description" name="description" placeholder="Дополнительная информация"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Email <sup style="color: red">*</sup></h4>
      </div>
      <div class="six wide column">
        <input type="email" placeholder="Email" required>
      </div>
    </div>
    <div class="row">
      <div class="two wide column d-flex align-items-center">
        <h4 class="header">Телефон <sup style="color: red">*</sup></h4>
      </div>
      <div class="six wide column">
        <input type="tel" placeholder="Телефон" required>
      </div>
    </div>
  </div>
</form>
<div class="buttons mt-2">
  <button class="ui button green">Добавить</button>
  <button routerLink="/client/contractor" class="ui button red">Отмена</button>
</div>
