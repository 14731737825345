import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../_models/user.model';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'e2b-auth-user',
  templateUrl: './auth-user.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/image.min.css',
    '../../assets/design/semantic-ui/components/menu.min.css',
    '../../assets/design/semantic-ui/components/dropdown.min.css',
    '../../assets/design/semantic-ui/components/icon.min.css',
    './auth-user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AuthUserComponent implements OnInit {

  currentUser: User;

  constructor(protected userService: UserService, protected router: Router) {
  }

  ngOnInit() {
    this.currentUser = this.userService.current();
  }

  logout() {
    this.userService.logout().then((res) => {
      this.router.navigate(['/login']).catch(console.error);
      return false;
    }).catch(console.error);
  }

}
