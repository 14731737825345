<ng-template let-option #staffGroupOptionTemplate>
  {{option.name}}
</ng-template>
<sui-multi-select class="fluid selection mx-auto"
                  [placeholder]="placeholder"
                  [isSearchable]="true"
                  [isDisabled]="false"
                  [(ngModel)]="selectedGroup"
                  [ngModelOptions]="{name: 'staffGroup'}"
                  (ngModelChange)="save()"
                  [options]="groups"
                  labelField="name"
                  [optionTemplate]="staffGroupOptionTemplate"
                  #selectStaffMulti>
  <sui-select-option *ngFor="let group of selectStaffMulti.filteredOptions | orderByColumn:'name:string'"
                     [value]="group">
  </sui-select-option>
</sui-multi-select>
