import { Pipe, PipeTransform } from '@angular/core';
import { OrderType } from '../_models/enums/order-type.enum';

const TypeName = {
  [OrderType.DELIVERY]: 'Доставка',
  [OrderType.MEASUREMENT]: 'Замер',
  [OrderType.MOUNTING]: 'Монтаж',
  [OrderType.PRODUCTION]: 'Производство',
  [OrderType.RECLAMATION]: 'Рекламация',
};

@Pipe({
  name: 'orderTypeName'
})
export class OrderTypeNamePipe implements PipeTransform {
  transform(orderType: OrderType): string {
    return TypeName[orderType] || '';
  }
}
