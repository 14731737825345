import { HostListener } from '@angular/core';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DealPosition } from '../../_models/deal-position.model';
import { DealStatus } from '../../_models/deal-status.model';
import { Deal } from '../../_models/deal.model';
import { Filial } from '../../_models/filial.model';
import { DealStatusService } from '../../_services/deal-status.service';
import { DealService } from '../../_services/deal.service';
import { FilialService } from '../../_services/filial.service';
import { UserService } from '../../_services/user.service';
import { ActionService } from '../../action/action.service';
import { DealListService } from './deal-list.service';
import { OrderType } from '../../_models/enums/order-type.enum';
import { Order } from '../../_models/order.model';

@Component({
  selector: 'e2b-deals-list',
  templateUrl: './deal-list.component.html',
  styleUrls: [
    '../../../assets/design/semantic-ui/components/header.min.css',
    '../../../assets/design/semantic-ui/components/loader.min.css',
    '../../../assets/design/semantic-ui/components/menu.min.css',
    '../../../assets/design/semantic-ui/components/label.min.css',
    '../../../assets/design/semantic-ui/components/dimmer.min.css',
    '../../../assets/design/semantic-ui/components/checkbox.min.css',
    './deal-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DealListComponent implements OnInit, OnDestroy {
  dealStatuses: Array<DealStatus>;
  deals: Deal[] = [];
  loaded = false;
  filter: Deal = new Deal();
  private listSubscribe: Subscription;
  private dealStatusesSubscribe: Subscription;
  sorting = '-created:date';
  managerFilter: any = null;
  filialFilter: any = null;
  filials: Filial[] = [];

  options = {
    limit: 20,
    offset: 0,
  };
  dealsCounter = 0;
  moreDealsLoading = false;

  constructor(private route: ActivatedRoute,
              private actionService: ActionService,
              private dealStatusService: DealStatusService,
              private userService: UserService,
              private filialService: FilialService,
              private dealService: DealService,
              public  dealListService: DealListService) {
    this.filter.currentStatus = <DealStatus>{ id: 1 };

  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollEnded = document.documentElement.scrollHeight - document.documentElement.scrollTop <= document.documentElement.clientHeight;
    if (scrollEnded && this.dealsCounter > this.deals.length) {
      this.options.offset += this.options.limit;
      this.moreDealsLoading = true;
      this.loadDeals();
    }
  }

  ngOnInit() {
    if (this.userService.checkPermissions(['deal', '/'], ['write'])) {
      this.actionService.createMenuEvent.emit({ name: 'Добавить сделку', icon: 'plus square', link: '/deal/add', class: 'blue' });
    }
    this.filialService.find<Filial>().subscribe(filials => {
      this.filials = filials;
    });
    this.loadDealStatuses(true);
  }

  identify(index, obj) {
    return obj ? obj.id : undefined;
  }

  loadDealStatuses(reload) {
    const options: any = {};
    if (localStorage.getItem('e2b.deal.list.filter')) {
      this.filter.currentStatus = JSON.parse(localStorage.getItem('e2b.deal.list.filter')).currentStatus;
      this.dealListService.setSelectedColumns(this.filter.currentStatus.sort);
    }
    this.managerFilter = JSON.parse(localStorage.getItem('e2b.deal.list.manager'));
    if (this.managerFilter && this.managerFilter.manager) {
      options.id = this.managerFilter.manager.id;
    }
    this.filialFilter = JSON.parse(localStorage.getItem('e2b.deal.list.filial'));
    if (this.filialFilter && this.filialFilter.filial) {
      options.filial = this.filialFilter.filial.id;
    }

    this.loaded = false;
    this.dealStatusesSubscribe = this.dealStatusService.find<DealStatus>(options).subscribe(res => {
      res.forEach((s, k) => {
        res[k].color = s.color.replace('#', 'X');
      });
      this.dealStatuses = res;
      if (reload) {
        this.deals = [];
        this.options.offset = 0;
        this.loadDeals();
      } else {
        this.loaded = true;
      }
    });
  }

  loadDeals() {
    this.listSubscribe = this.dealService.find<any>({ statusId: this.filter.currentStatus.id, ...this.options }).subscribe((res: any) => {
      this.dealsCounter = res.count;
      res.deals.map((deal: any, k) => {
        deal.contract = {};
        deal.mountingData = { dates: [], mounters: [], costs: [] };
        deal.deliveryData = { dates: [], deliverers: [], costs: [] };
        deal.gauging = {};
        deal.planning = {};
        deal.productionData = { cabinet: [], soft: [], matress: [], carriage: [], dates: [] };
        deal.finance = {};
        deal.reclamationData = { mounters: [], dates: [] };
        deal.cost = {};

        if (deal.mounting) {
          deal.mounting.map(order => {
            deal.mountingData.dates.push(order.dates.join(', '));
            deal.mountingData.mounters.push(order.responsible);
            deal.mountingData.costs.push(order.cost);
          });
        }
        if (deal.delivery) {
          deal.delivery.map(order => {
            deal.deliveryData.dates.push(order.dates.join(', '));
            deal.deliveryData.deliverers.push(order.responsible);
            deal.deliveryData.costs.push(order.cost);
          });
        }
        if (deal.production) {
          deal.production.map(order => {
            if (order.type === 1) {
              deal.productionData.cabinet.push(order.responsible);
            }
            if (order.type === 2) {
              deal.productionData.soft.push(order.responsible);
            }
            if (order.type === 3) {
              deal.productionData.matress.push(order.responsible);
            }
            if (order.type === 4) {
              deal.productionData.carriage.push(order.responsible);
            }
            deal.productionData.dates.push(order.dates.join(', '));
          });
        }
        if (deal.reclamation) {
          deal.reclamation.map(order => {
            deal.reclamationData.mounters.push(order.responsible);
            deal.reclamationData.dates.push(order.dates.join(', '));
          });
        }

        if (deal.values.length > 0) {
          deal.values.map(value => {
            switch (value.field.name) {
              case 'Договор: Ориентировочная дата покупки':
                deal.contract.orientPurchaseDate = value.value;
                break;
              case 'Договор: Номер':
                deal.contract.name = value.value;
                break;
              case 'Договор: Дата':
                deal.contract.date = value.value;
                break;
              case 'Договор: Предоплата':
                deal.contract.prepay = value.value;
                break;
              case 'Доставка и монтаж: Дата отгрузки':
                deal.deliveryData.shipmentDate = value.value;
                break;
              case 'ЗАМЕР: Замерщик':
                deal.gauging.gauger = value.value && value.value.length > 0 ? value.value[0].name : '';
                break;
              case 'ЗАМЕР: Дата замера':
                deal.gauging.gaugingDate = value.value;
                break;
              case 'ЗАМЕР: Дата завершения замера':
                deal.gauging.gaugingFinishDate = value.value;
                break;
              case 'Проектирование: Конструкторы':
                deal.planning.constructors = value.value && value.value.length > 0 ? value.value[0].name : '';
                break;
              case 'Проектирование: Спецификации и чертежи':
                deal.planning.specifications = value.value;
                break;
              case 'Финансы: Остаток':
                deal.finance.balance = value.value;
                break;
              case 'Финансы: Остаток сдан':
                deal.finance.balancePassed = value.value;
                break;
              case 'Финансы: Акт сдан':
                deal.finance.actPassed = value.value;
                break;
              case 'Себестоимость: Доп работы':
                deal.cost.extraWork = value.value;
                break;
            }
          });
          res.deals[k] = deal;
        }

        deal.totalPrice = this.totalDealSum(deal.positions);
        if (deal.todos.length > 0) {
          let curDiff: any = false;
          deal.todos.map(todo => {
            if (!todo.checked) {
              if (!curDiff) {
                curDiff = moment(todo.from);
                deal.nextTodo = todo;
              } else if (moment(todo.from).isSameOrBefore(curDiff)) {
                curDiff = moment(todo.from);
                deal.nextTodo = todo;
              }
            }
          });
        }
      });
      this.deals = this.deals.concat(res.deals);
      this.loaded = true;
      this.moreDealsLoading = false;
    }, console.error);
  }

  totalDealSum(positions: DealPosition[]) {
    let totalSum = 0;
    if (positions.length > 0) {
      positions.forEach(pos => {
        totalSum += pos.price * pos.count;
        if (pos.discountPrice) {
          totalSum -= pos.discountPrice;
        }
      });
    }
    return totalSum;
  }

  applyStatusFilter(filter) {
    if (filter.id !== this.filter.currentStatus.id) {
      this.filter.currentStatus = filter;
      localStorage.setItem('e2b.deal.list.filter', JSON.stringify(this.filter));
      this.loadDealStatuses(true);
    }
  }

  applyManagerFilter() {
    if (this.managerFilter) {
      this.managerFilter = null;
      localStorage.setItem('e2b.deal.list.manager', JSON.stringify(this.managerFilter));
    } else {
      this.managerFilter = { manager: this.userService.current() };
      localStorage.setItem('e2b.deal.list.manager', JSON.stringify(this.managerFilter));
    }
    this.loadDealStatuses(false);
  }

  applyFilialFilter(filial) {
    if (this.filialFilter && this.filialFilter.filial.id === filial.id) {
      this.filialFilter = null;
      localStorage.setItem('e2b.deal.list.filial', JSON.stringify(this.filialFilter));
    } else {
      this.filialFilter = { filial: filial };
      localStorage.setItem('e2b.deal.list.filial', JSON.stringify(this.filialFilter));
    }
    this.loadDealStatuses(false);
  }

  ngOnDestroy() {
    if (this.listSubscribe) {
      this.listSubscribe.unsubscribe();
    }
    if (this.dealStatusesSubscribe) {
      this.dealStatusesSubscribe.unsubscribe();
    }
  }
}
