import { Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NotificationSettings } from '../_models/notification-settings.model';
import { SettingModel, SettingName } from '../_models/setting.model';
import { User } from '../_models/user.model';
import { NotificationSettingsService } from '../_services/notification-settings.service';
import { SettingService } from '../_services/setting.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'e2b-settings',
  templateUrl: './settings.component.html',
  styleUrls: [
    '../../assets/design/semantic-ui/components/form.min.css',
    '../../assets/design/semantic-ui/components/button.min.css',
    '../../assets/design/semantic-ui/components/grid.min.css',
    '../../assets/design/semantic-ui/components/checkbox.min.css',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsComponent implements OnInit {
  public brigades: SettingModel = new SettingModel();
  public headManagerEvent: EventEmitter<User> = new EventEmitter<User>();
  public headMounterEvent: EventEmitter<User> = new EventEmitter<User>();
  public managerHelperBonus: SettingModel = new SettingModel();
  public managers: User[] = [];
  public mounters: User[] = [];
  public reclamationFinishHours: SettingModel = new SettingModel();
  public reclamationReceiveHours: SettingModel = new SettingModel();
  public reclamationSetDateHours: SettingModel = new SettingModel();
  @ViewChild('selectHeadManager', { static: false })
  public selectHeadManager;
  @ViewChild('selectHeadMounter', { static: false })
  public selectHeadMounter;
  public selectedHeadManager: User;
  public selectedHeadMounter: User;
  public settings: SettingModel[];
  public skipTillMounting: SettingModel = new SettingModel();
  public md: SettingModel = new SettingModel();

  notificationSettings: NotificationSettings[] = [];

  constructor(private readonly settingService: SettingService,
              private readonly notificationSettingsService: NotificationSettingsService,
              private readonly userService: UserService) {
  }

  public changeHeadManager(manager: User) {
    this.selectedHeadManager = manager;
    this.selectHeadManager.selectedOption = manager;
  }

  public changeHeadMounter(mounter: User) {
    this.selectedHeadMounter = mounter;
    this.selectHeadMounter.selectedOption = mounter;
  }

  public getSettings() {
    this.settingService.find<SettingModel[]>().subscribe(settings => {
      settings.map(setting => {
        if (setting.name === SettingName.E2B_SETTING_HEAD_MANAGER_ID) {
          this.userService.managers<User>().subscribe(managers => {
            this.managers = managers;
            this.changeHeadManager(managers.find(u => u.id === +setting.value));
          });
        } else if (setting.name === SettingName.E2B_SETTING_HEAD_MOUNTER_ID) {
          this.userService.mounters<User>().subscribe(mounters => {
            this.mounters = mounters;
            this.changeHeadMounter(mounters.find(u => u.id === +setting.value));
          });
        } else if (setting.name === SettingName.E2B_SETTING_BRIGADES) {
          this.brigades = setting;
        } else if (setting.name === SettingName.E2B_SETTING_SKIP_DAYS_MOUNTING) {
          this.skipTillMounting = setting;
        } else if (setting.name === SettingName.E2B_SETTING_MANAGER_HELPER_BONUS) {
          this.managerHelperBonus = setting;
        } else if (setting.name === SettingName.E2B_SETTING_RECLAMATION_RECEIVE_HOURS) {
          this.reclamationReceiveHours = setting;
        } else if (setting.name === SettingName.E2B_SETTING_RECLAMATION_SETDATE_HOURS) {
          this.reclamationSetDateHours = setting;
        } else if (setting.name === SettingName.E2B_SETTING_RECLAMATION_FINISH_HOURS) {
          this.reclamationFinishHours = setting;
        } else if (setting.name === SettingName.E2B_SETTING_MD) {
          this.md = setting;
        }
      });
      this.settings = settings;
    });
  }

  getNotificationSettings() {
    this.notificationSettingsService.find<NotificationSettings>().subscribe(notificationSettings => {
      this.notificationSettings = notificationSettings;
    });
  }

  public ngOnInit(): void {
    this.getSettings();
    this.getNotificationSettings();
    this.headManagerEvent.subscribe(user => {
      this.changeHeadManager(user);
      this.settings.map(setting => {
        if (setting.name === 'E2B_SETTING_HEAD_MANAGER_ID') {
          setting.value = this.selectedHeadManager.id.toString();
          this.settingService.update(setting).toPromise().catch(console.error);
        }
      });
    });
    this.headMounterEvent.subscribe(user => {
      this.changeHeadMounter(user);
      this.settings.map(setting => {
        if (setting.name === 'E2B_SETTING_HEAD_MOUNTER_ID') {
          setting.value = this.selectedHeadMounter.id.toString();
          this.settingService.update(setting).toPromise().catch(console.error);
        }
      });
    });
  }

  public save() {
    this.settings.map(setting => {
      if (setting.name === SettingName.E2B_SETTING_BRIGADES) {
        setting.value = this.brigades.value;
        this.settingService.update(setting).toPromise().catch(console.error);
      } else if (setting.name === SettingName.E2B_SETTING_SKIP_DAYS_MOUNTING) {
        setting.value = this.skipTillMounting.value;
        this.settingService.update(setting).toPromise().catch(console.error);
      } else if (setting.name === SettingName.E2B_SETTING_MANAGER_HELPER_BONUS) {
        setting.value = this.managerHelperBonus.value;
        this.settingService.update(setting).toPromise().catch(console.error);
      } else if (setting.name === SettingName.E2B_SETTING_RECLAMATION_RECEIVE_HOURS) {
        setting.value = this.reclamationReceiveHours.value;
        this.settingService.update(setting).toPromise().catch(console.error);
      } else if (setting.name === SettingName.E2B_SETTING_RECLAMATION_SETDATE_HOURS) {
        setting.value = this.reclamationSetDateHours.value;
        this.settingService.update(setting).toPromise().catch(console.error);
      } else if (setting.name === SettingName.E2B_SETTING_RECLAMATION_FINISH_HOURS) {
        setting.value = this.reclamationFinishHours.value;
        this.settingService.update(setting).toPromise().catch(console.error);
      } else if (setting.name === SettingName.E2B_SETTING_MD) {
        setting.value = this.md.value;
        this.settingService.update(setting).toPromise().catch(console.error);
      }
    });
  }

  saveNotification(notificationSettings: NotificationSettings) {
    if (notificationSettings.id) {
      this.notificationSettingsService.update(notificationSettings).subscribe(() => {
      });
    } else {
      this.notificationSettingsService.create(notificationSettings).subscribe(() => {
        this.getNotificationSettings();
      });
    }
  }
}
