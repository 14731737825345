import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ErrorService} from './error.service';

@Component({
  selector: 'e2b-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css', '../../assets/design/semantic-ui/components/message.min.css'],
  encapsulation: ViewEncapsulation.None
})

export class ErrorComponent {
  @Input() public errors: any = [];

  constructor(private readonly errorService: ErrorService) {
    this.errorService.addError.subscribe(error => {
      this.errors.push(error);
      setTimeout(() => {
        const el = document.getElementById('pageHeader');
        el.scrollIntoView({
          behavior: 'smooth'
        });
      }, 200);
    });
  }

  close(error) {
    this.errors.splice(this.errors.indexOf(error), 1);
  }
}
