<sui-dimmer class="page inverted" [(isDimmed)]="!loaded" [isClickable]="false">
  <div class="ui text loader">Загрузка...</div>
</sui-dimmer>

<div class="ui grid" *ngIf="loaded">
  <div class="ui sixteen wide column">
    <div class="ui fluid ordered steps">
      <div class="cursor step" [ngClass]="{'active': step === 1}" (click)="step = 1">
        <div class="content">
          <div class="title">Сделка</div>
          <div class="description">Информация о клиенте, участниках и заказе</div>
        </div>
      </div>
      <div class="cursor step" [ngClass]="{'active': step === 2}" (click)="saveDeal()">
        <div class="content">
          <div class="title">Договор</div>
          <div class="description">Плательщик, отгрузка и монтаж, оформление договора</div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui sixteen wide column" *ngIf="step === 1">
    <form class="ui form">
      <div class="ui stackable grid">
        <div class="ui eight wide column">
          <!--Инфо о клиенте-->
          <div>
            <h4 class="ui dividing header">Информация о клиенте</h4>
            <div class="field required">
              <label class="fw-bold">Клиент</label>
              <div *ngIf="!client.id" class="fluid field">
                <ng-template #clientOptionTemplate let-query="query" let-result>
                  <div class="mininame">{{result.name}}</div>
                  <div *ngIf="result.contact" class="miniprice">{{result.contact.phone}}</div>
                  <div *ngIf="result.contact" class="minitext">{{result.contact.email}}</div>
                </ng-template>
                <sui-search (resultSelected)="selectClient($event)"
                            [hasIcon]="true"
                            [maxResults]="25"
                            [optionsLookup]="clientSearch"
                            [resultTemplate]="clientOptionTemplate"
                            id="client"
                            name="client"
                            placeholder="Клиент"
                            style="width: 100%;"
                            #clientSearchComponent>
                </sui-search>
              </div>
              <div *ngIf="client.id">
                <div>
                  <a href="#" [routerLink]="['/client/card', client.id]">{{client.name}}</a>
                </div>
                <button class="ui grey button mt-1" (click)="resetClient()">Изменить</button>
              </div>
            </div>

            <div class="field required">
              <label class="fw-bold">Имя контакта</label>
              <input type="text" name="contactName" placeholder="Имя контакта" [(ngModel)]="clientContact.name">
            </div>
            <div class="field required">
              <label class="fw-bold">Почта</label>
              <input type="email" name="email" placeholder="e-mail" [(ngModel)]="clientContact.email">
            </div>
            <div class="field required">
              <label class="fw-bold">Телефон</label>
              <input type="number" name="phone" placeholder="Телефон" [(ngModel)]="clientContact.phone" (ngModelChange)="onClientContactPhoneChange($event)">
            </div>
          </div>

          <!--Филиал-->
          <div class="form-block">
            <h4 class="ui dividing header">Филиал</h4>
            <div class="field required">
              <label class="fw-bold">Филиал</label>
              <e2b-filial-dropdown [selectedFilial]="deal.filial" [event]="selectFilial" [filial]="filials"></e2b-filial-dropdown>
            </div>
            <div class="field required">
              <label class="fw-bold">Моя организация</label>
              <e2b-my-company-dropdown [selected]="deal.organization" [event]="selectOrganization"></e2b-my-company-dropdown>
            </div>
          </div>

          <!--Участники-->

        </div>
        <div class="ui eight wide column">
          <div class="ui form">
            <h4 class="ui dividing header">Участники</h4>
            <div class="field required">
              <label class="fw-bold">Менеджер</label>
              <e2b-staff-dropdown [staff]="managers" [selected]="deal.manager" [event]="selectManager"></e2b-staff-dropdown>
            </div>
            <div class="field">
              <label class="fw-bold">Доп. менеджер</label>
              <e2b-staff-dropdown [staff]="managers" [selected]="deal.subManager" [event]="selectSubManager"></e2b-staff-dropdown>
            </div>
            <div class="field required">
              <label class="fw-bold">Аудиторы</label>
              <e2b-staff-group-multi-dropdown [event]="selectAuditor" [groups]="groups" [selected]="auditors"></e2b-staff-group-multi-dropdown>
            </div>

          </div>
          <div class="form-block" *ngIf="fields.length">
            <h4 class="ui dividing header">Поля сделки</h4>
            <e2b-deal-field [fields]="fields" [deal]="deal" [fieldToSaveLater]="fieldToSaveLater" *ngIf="fieldsLoaded"></e2b-deal-field>
          </div>
        </div>
        <div class="ui sixteen wide column">
          <button class="ui green button" [disabled]="!isDealValid" (click)="saveDeal()">Далее</button>
        </div>
      </div>
    </form>
  </div>
  <div class="ui sixteen wide column" *ngIf="step === 2">
    <e2b-sales-wizard-person-info [deal]="deal" [onSaveEvent]="onPersonSave"></e2b-sales-wizard-person-info>
  </div>
</div>
