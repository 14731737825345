import { BaseModel } from './base.model';
import { Project } from './project.model';
import { User } from './user.model';

export class ProjectComment extends BaseModel {
  created: Date;
  type: string;
  user: User;
  text: string;
  responsible: User[];
  project: Project;
  files: any[];
}
