import { Pipe, PipeTransform } from '@angular/core';
import { CommentFilter } from '../_models/enums/comment-filter.enum';

@Pipe({
  name: 'filterComment'
})
export class FilterCommentPipe implements PipeTransform {

  transform(comments: any, commentFilter: CommentFilter): any {
    if (commentFilter === CommentFilter.all || !commentFilter) {
      return comments;
    } else if (commentFilter === CommentFilter.whatsapp) {
      return comments.filter(comment => comment.whatsapp);
    } else if (commentFilter === CommentFilter.comments) {
      return comments.filter(comment => !comment.whatsapp && !comment.sms);
    } else if (commentFilter === CommentFilter.sms) {
      return comments.filter(comment => comment.sms);
    }
  }
}
